import Vue from "vue";
import {
  SET_AUTH_RESET_PASSWORD,
  SET_AUTH_STATE,
  SET_AUTH_USER_EMAIL,
  SET_AUTH_USER_GET_INFO,
  SET_AUTH_USER_UPDATE_INFO,
  SET_AUTH_LOGOUT,
  SET_AUTH_SIGNUP_LOCATION_NAME,
  FETCHING_AUTH_SHARE_ACTIVITY,
  FETCHING_AUTH_SHARE_INACTIVITY,
  SET_AUTH_USER_AVATAR,
  FETCHING_AUTH_AVATAR,
  SET_AUTH_RESET_STATE,
  SET_WEB_VIEW_MODE,
  SET_CONTROLLER_ID,
  SET_UPDATE_TEMPLATE,
  SET_PATH,
  SET_DASHBOARD_ID,
  SET_EZLO_PROTECT
} from "./mutationTypes";
import InitialState from "./state";

export default {
  [SET_AUTH_RESET_STATE](state) {
    for (let prop in state) {
      Vue.set(state, prop, InitialState()[prop]);
    }
  },
  [SET_AUTH_STATE](state, payload) {
    console.log("SET_AUTH_STATE"); // eslint-disable-line
    Vue.set(state, "authState", payload);
  },
  [SET_AUTH_USER_EMAIL](state, payload) {
    console.log("SET_AUTH_USER_EMAIL:", payload); // eslint-disable-line
    Vue.set(state, "user", {
      ...state.user,
      email: payload
    });
  },
  [SET_AUTH_SIGNUP_LOCATION_NAME](state, payload) {
    console.log("SET_AUTH_SIGNUP_LOCATION_NAME:", payload); // eslint-disable-line
    Vue.set(state, "user", {
      ...state.user,
      locationName: payload
    });
  },
  [SET_AUTH_RESET_PASSWORD](state, payload) {
    console.log("SET_AUTH_RESET_PASSWORD:", payload); // eslint-disable-line
    Vue.set(state, "user", {
      ...state.user,
      isResetPassword: payload
    });
  },
  [SET_WEB_VIEW_MODE](state, payload) {
    console.log("SET_WEB_VIEW_MODE:", payload); // eslint-disable-line
    Vue.set(state, "isWebView", payload);
  },
  [SET_PATH](state, payload) {
    console.log("SET_PATH:", payload); // eslint-disable-line
    Vue.set(state, "path", payload);
  },
  [SET_CONTROLLER_ID](state, payload) {
    console.log("SET_CONTROLLER_ID:", payload); // eslint-disable-line
    Vue.set(state, "controllerId", payload);
  },
  [SET_DASHBOARD_ID](state, payload) {
    console.log("SET_DASHBOARD_ID:", payload); // eslint-disable-line
    Vue.set(state, "dashboardId", payload);
  },
  [FETCHING_AUTH_SHARE_ACTIVITY](state, payload) {
    console.log("FETCHING_AUTH_SHARE_ACTIVITY:", payload); // eslint-disable-line
    Vue.set(state, "fetchingShareActivity", payload);
  },
  [FETCHING_AUTH_SHARE_INACTIVITY](state, payload) {
    Vue.set(state, "fetchingShareInactivity", payload);
  },
  [FETCHING_AUTH_AVATAR](state, payload) {
    Vue.set(state, "fetchingAvatar", payload);
  },
  [SET_AUTH_LOGOUT](state) {
    const initialState = new InitialState();
    Object.keys(initialState).forEach(key => {
      state[key] = initialState[key];
    });
  },
  [SET_AUTH_USER_AVATAR](state, payload) {
    console.log("### SET_AUTH_USER_AVATAR:", payload); // eslint-disable-line
    const { avatarUrl, PK_Account, baseUrl = "", url = "" } = payload;

    let { avatar } = state.user;
    if (avatar) {
      if (Array.isArray(avatar)) {
        let item = avatar.find(item => item.PK_Account === PK_Account);
        if (item) {
          Vue.set(item, "avatarUrl", avatarUrl);
          Vue.set(item, "PK_Account", PK_Account);
          Vue.set(item, "baseUrl", baseUrl);
          Vue.set(item, "url", url);
        } else {
          avatar.push(payload);
        }
      }
    } else {
      state.user.avatar = [avatar];
    }
  },
  [SET_AUTH_USER_GET_INFO](state, payload) {
    console.log("SET_AUTH_USER_GET_INFO:", payload); // eslint-disable-line
    Vue.set(state, "user", {
      ...state.user,
      username: payload.username,
      password: payload.password,
      firstName: payload.firstName,
      lastName: payload.lastName,
      email: payload.email,
      phoneNumber: payload.phoneNumber
    });
  },
  [SET_AUTH_USER_UPDATE_INFO](state) {
    console.log("SET_AUTH_USER_UPDATE_INFO:"); // eslint-disable-line
    Vue.set(state, "user", state.user);
  },
  [SET_UPDATE_TEMPLATE](state, payload) {
    console.log("SET_UPDATE_TEMPLATE:"); // eslint-disable-line
    Vue.set(state, "updateTemplate", payload);
  },
  [SET_EZLO_PROTECT](state, payload) {
    console.log("SET_EZLO_PROTECT:"); // eslint-disable-line
    Vue.set(state, "hasEzloProtectSubscription", payload);
  }
};
