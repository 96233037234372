<template>
  <StackLayout @tap="select">
    <GridLayout
      columns="auto, *"
      rows="56"
      width="100%"
      class="color-image-selector"
    >
      <ColorEllipse row="0" col="0" :color="getColor(color)" v-if="!image" />
      <IconHTxtiBtn
        row="0"
        col="1"
        class="color-image-selector__item-text"
        verticalAlignment="center"
        alignText="left"
        headerSize="h3"
        :icon1Src="image ? 'icons/Actions/ic_actions_img_56.svg' : ''"
        icon2Src="icons/Controls/ic_controls_arrow-right_56.svg"
        :text="text"
      />
    </GridLayout>
    <Separator size="xl" />
  </StackLayout>
</template>

<script>
import { colorHexA } from "@/helpers/utils";
import { ColorPicker } from "../../helpers/helperLibrary";

export default {
  name: "ColorImageSelector",
  props: {
    text: {
      type: String,
      default: "ColorImageSelector"
    },
    color: {
      type: String,
      default: "#ffffff"
    },
    useBackgroundImage: {
      type: Boolean,
      default: false
    },
    imageSelect: {
      type: Boolean,
      default: false
    },
    image: {
      type: String,
      default: ""
    },
    themeType: {
      type: String,
      default: "dashboard",
      validator(value) {
        return ["dashboard", "tile"].includes(value);
      }
    }
  },
  data() {
    return {
      picker: null,
      colorItemKey: null
    };
  },
  computed: {
    hasBackgroundImage() {
      return false;
    },
    getTileBgImage() {
      return this.hasBackgroundImage()
        ? "icons/Actions/ic_actions_img_56.svg"
        : null;
    }
  },
  methods: {
    getColor(color) {
      return process.env.VUE_APP_MODE === "web" ? colorHexA(color) : color;
    },
    /**
     * @key: "font_color" / "icon_color"
     */
    select() {
      if (this.imageSelect) {
        this.selectBackground();
      } else {
        this.selectColor();
      }
    },
    selectColor() {
      const oldColorHex = this.color;
      if (process.env.VUE_APP_PLATFORM !== "web") {
        if (!this.picker) {
          this.picker = new ColorPicker();
        }
        this.picker
          .show(oldColorHex, "ARGB")
          .then(result => {
            const color = new colorModule.Color(result);
            const colorKeyObj = { [key]: color ? color.hex : undefined };
            this.$set(this.themeOptions.tile, "designProperties", {
              ...this.themeOptions.tile.designProperties,
              ...colorKeyObj
            });
          })
          .catch(err => {
            console.log(`Error handle select color:${err}`); // eslint-disable-line
          });
      } else {
        this.navigateTo("ColorSelect", {
          props: {
            title: this.text,
            colorHex:
              process.env.VUE_APP_MODE === "web"
                ? colorHexA(oldColorHex)
                : oldColorHex,
            callbackChangeColor: value => {
              console.log("#### colorSelect callbackChangeColor:", value); // eslint-disable-line
            },
            callbackDone: this.changeColor
          }
        });
      }
    },
    changeColor(color) {
      const { aHex } = color;
      const colorKeyObj = { ["color"]: aHex || undefined, raw: { ...color } };
      this.$emit("change", colorKeyObj);
    },
    selectBackground() {
      const options = {
        background_color: this.color,
        use_background_image: this.useBackgroundImage,
        background_image: this.image
      };
      this.navigateTo("Background", {
        props: {
          bgType: this.themeType,
          backgroundOptions: options || {},
          callbackDone: this.changeColor
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~/assets/styles/variables.scss";

.color-image-selector {
  margin-left: $margin-s;
  &__item-text {
    padding-left: 0;
    grid-column: 2;
    /deep/ & * {
      padding-left: 0;
    }
  }
}
</style>
