export async function parseHttpResponse(response, responseType = "") {
  const statusCode = response.status;
  let data = null;
  switch (responseType) {
    case "string":
      data = await response.text();
      break;
    case "json":
      data = await response.json();
      break;
    default:
      data = statusCode !== 200 ? await response.text() : await response.json();
      break;
  }
  return { statusCode, data };
}
