import { isEmpty } from "./utils";
import { cloneDeep, merge } from "lodash";

// region Converters to UI tiles
const { VUE_APP_MODE } = process.env;
const convertCellDefault = (
  index,
  { target_entity: { object_id, type }, title, design_properties, theme },
  previewMode
) =>
  unknownTile(
    index,
    {
      id: object_id,
      title: title || type,
      type,
      design_properties,
      theme
    },
    previewMode
  );

const convertTheme = options => {
  const { default: general } = cloneDeep(options);
  const theme = { general, ...options };
  if (theme && theme.general) {
    delete theme.general.empty_tile;
  }
  delete theme.default;
  return theme;
};

const convertDeviceCell = (
  index,
  cell,
  { devices, dashboardId, dashboards, previewMode, theme }
) => {
  const {
    target_entity: { object_id }
  } = cell;
  const { styleSettings } = dashboards.find(({ id }) => id === dashboardId);
  const device = devices.find(({ id }) => id === object_id);
  let design_properties = { ...cell.design_properties };
  if (styleSettings) {
    design_properties = {
      ...design_properties,
      general: styleSettings.general
    };
  }
  return device
    ? deviceTile(
        index,
        { ...device, design_properties, theme: { ...theme.data.tile } },
        previewMode
      )
    : convertCellDefault(
        index,
        { ...cell, theme: { ...theme.data.tile } },
        previewMode
      );
};

const convertDashboardCell = (
  index,
  cell,
  { dashboardId, dashboards, theme }
) => {
  const {
    target_entity: { object_id }
  } = cell;
  //the dashboard that is parent
  const dashboard = dashboards.find(({ id }) => id === dashboardId);
  //the dashboard that is child
  const cellDashboard = dashboards.find(({ id }) => id === object_id);

  const design_properties = {
    ...cell.design_properties,
    ...dashboard.styleSettings.general
  };
  return cellDashboard
    ? dashboardTile(index, {
        title: cellDashboard.name,
        id: object_id,
        design_properties,
        theme: { ...theme.data.tile }
      })
    : convertCellDefault(index, { ...cell, theme: { ...theme.data.tile } });
};

const convertUrlCell = (index, cell, { dashboardId, theme }) => {
  return urlTile(index, {
    id: dashboardId,
    design_properties: { ...cell.design_properties },
    theme: { ...theme.data.tile },
    title: cell.title
  });
};
const convertLinkCell = (index, cell, { dashboardId, theme }) => {
  return linkTile(index, {
    id: dashboardId,
    design_properties: { ...cell.design_properties },
    theme: { ...theme.data.tile },
    target_entity: { ...cell.target_entity },
    title: cell.title,
    subType: cell.subType,
    text: cell.text
  });
};

const convertDateTimeCell = (index, cell, { dashboardId, tileSize, theme }) => {
  const {
    target_entity: { sub_type }
  } = cell;
  return datetimeTile(index, {
    id: dashboardId,
    design_properties: { ...cell.design_properties },
    theme: { ...theme.data.tile },
    title: cell.title,
    subType: sub_type,
    tileSize
  });
};
const convertHouseModeCell = (
  index,
  cell,
  { dashboardId, tileSize, theme, houseModes }
) => {
  const {
    target_entity: { sub_type }
  } = cell;
  const { icon } = houseModes.find(item => item.subType === sub_type);
  return houseModeTile(index, {
    id: dashboardId,
    design_properties: { ...cell.design_properties },
    theme: { ...theme.data.tile },
    title: cell.title,
    subType: sub_type,
    icon,
    tileSize
  });
};

const convertPanicCell = (
  index,
  cell,
  { dashboardId, tileSize, panicTypes, theme }
) => {
  const {
    target_entity: { sub_type }
  } = cell;
  const { subTypeIcon: icon } = panicTypes.find(
    item => item.subType === sub_type
  );
  return panicTile(index, {
    id: dashboardId,
    design_properties: { ...cell.design_properties },
    theme: { ...theme.data.tile },
    title: cell.title,
    subType: sub_type,
    icon,
    tileSize
  });
};

const convertSceneCell = (index, cell, { scenes, previewMode, theme }) => {
  const {
    target_entity: { object_id }
  } = cell;
  const scene = scenes.find(({ id }) => id === object_id);
  const design_properties = { ...cell.design_properties };
  return scene
    ? sceneTile(
        index,
        {
          title: scene.name,
          id: scene.id,
          design_properties,
          theme: { ...theme.data.tile },
          loading: scene.loading
        },
        previewMode
      )
    : convertCellDefault(index, { ...cell, theme: { ...theme.data.tile } });
};
// endregion

// region Formats factory

const converters = {};

const registerCellConverter = (type, fn) => {
  converters[type] = fn;
};

const getCellTemplate = (index, cell, data) => {
  const {
    target_entity: { type }
  } = cell;
  const fn = converters[type] || convertCellDefault;
  return fn && fn(index, cell, data);
};

registerCellConverter("device", convertDeviceCell);
registerCellConverter("dashboard", convertDashboardCell);
registerCellConverter("url", convertUrlCell);
registerCellConverter("scene", convertSceneCell);
registerCellConverter("datetime", convertDateTimeCell);
registerCellConverter("link", convertLinkCell);
registerCellConverter("houseMode", convertHouseModeCell);
registerCellConverter("panic", convertPanicCell);
// endregion

export function createTemplateFromJson(
  json,
  dashboardId,
  tiles,
  styleSettings, // general dasboard settings
  {
    devices,
    dashboards,
    scenes,
    defaultDesignProperties,
    houseModes,
    panicTypes,
    theme
  },
  previewMode = false
) {
  const template = {
    rowCount: json.rows,
    colCount: json.columns,
    id: json.id,
    rowHeight: json.rowHeight ? json.rowHeight : 130,
    children: []
  };
  const cells = tiles.reduce((res, item) => {
    res[item.cell_id] = item;
    return res;
  }, {});
  json.layout.forEach((layout, index) => {
    template.children.push({
      comp: "GridLayout",
      style: { cursor: previewMode ? "default" : "pointer" },
      attrs: {
        row: layout.rowStart,
        col: layout.colStart,
        rowSpan: layout.rowEnd - layout.rowStart + 1,
        colSpan: layout.colEnd - layout.colStart + 1
      },
      on: {},
      children:
        cells[index] === undefined
          ? [
              previewMode
                ? noTile(index)
                : emptyTile({ index, styleSettings, theme })
            ]
          : [
              getCellTemplate(index, cells[index], {
                dashboardId,
                devices,
                dashboards,
                scenes,
                defaultDesignProperties,
                styleSettings,
                theme,
                houseModes,
                panicTypes,
                previewMode,
                tileSize:
                  (layout.rowEnd - layout.rowStart + 1) *
                  (layout.colEnd - layout.colStart + 1)
              }),
              previewMode ? noTile(index) : deleteIcon(index)
            ]
    });
  });
  return template;
}

function noTile(index) {
  return {
    comp: "StackLayout",
    attrs: {
      id: index,
      row: 0,
      col: 0
    }
  };
}

function hasBackgroundImage({ styleSettings, theme }) {
  return (
    theme &&
    theme.data.dashboard["default"].use_background_image &&
    theme.data.dashboard["default"].background_image
  );
}

function emptyTile({ index, styleSettings, theme }) {
  const key = hasBackgroundImage({ styleSettings, theme })
    ? "has_image"
    : "has_color";

  const icon = theme && theme.data.tile.default.empty_tile[key].icon;
  const backgroundColor =
    theme && theme.data.tile.default.empty_tile[key].background_color;
  return {
    comp: "StackLayout",
    attrs: {
      id: index,
      row: 0,
      col: 0
    },
    style: {
      padding: unit(4)
    },
    on: {},
    children: [
      {
        comp: "EmptyTemplate",
        props: {
          icon,
          backgroundColor
        },
        attrs: {
          id: index
        }
      }
    ]
  };
}

function getDefaultIcon(type) {
  let src = null;
  switch (type) {
    case "device":
      src = "icons/Devices/ic_tabbar_device-hub_56.svg";
      break;
    case "dashboard":
      src = "icons/Actions/ic_actions_calendar-month_56.svg";
      break;
    case "url":
      src = "icons/Controls/ic_controls_pair_56.svg";
      break;
    case "scene":
      src = "icons/Tabbar/ic_tabbar_scenes_56.svg";
      break;
    case "datetime":
      src = "icons/Actions/ic_actions_calendar_56.svg";
      break;
    case "link":
      src = "icons/Controls/ic_controls_pair_56.svg";
      break;
    case "houseMode":
      src = "icons/Modes/ic_modes_at-home_56.svg";
      break;
    case "panic":
      src = "icons/Actions/ic_actions_ezlo-protect_56.svg";
      break;
    default:
      src = "";
      break;
  }
  return src;
}

function unknownTile(index, item, previewMode) {
  const { design_properties } = item;
  delete design_properties.theme;
  const theme = convertTheme(item.theme);
  return {
    comp: "StackLayout",
    attrs: {
      id: index,
      row: 0,
      col: 0
    },
    style: {
      padding: unit(4)
    },
    on: {},
    children: [
      {
        comp: "TileItem",
        attrs: {
          id: index
        },
        props: {
          src: getDefaultIcon(item.type),
          id: item.id,
          title: item.title,
          type: item.type,
          design_properties: design_properties,
          theme,
          status: previewMode
            ? VUE_APP_MODE === "web"
              ? "Unreachable"
              : "Unreach\nable"
            : "",
          loading: false
        }
      }
    ]
  };
}

function deviceTile(index, item, previewMode) {
  const { design_properties } = item;
  delete design_properties.theme;
  const theme = convertTheme(item.theme);
  return {
    comp: "StackLayout",
    attrs: {
      id: index,
      row: 0,
      col: 0
    },
    style: {
      padding: unit(4)
    },
    on: {},
    children: [
      {
        comp: "TileItem",
        attrs: {
          id: index
        },
        props: {
          src: (item.icons && item.icons.defaultSrc) || "",
          backgroundImage:
            (design_properties &&
              design_properties.on &&
              design_properties.on.use_background_image &&
              design_properties.on.background_image) ||
            "",
          id: item.id,
          srcActive: (item.icons && item.icons.green) || "",
          title: item.title,
          room: (item.room && item.room.name) || "",
          status: previewMode ? item.status : "",
          state: ["amazonAlexa", "googleHome"].includes(item.category)
            ? "general"
            : item.state || "general",
          loading: (previewMode && item.loading) || false,
          backgroundColor: "primary",
          type: "device",
          // TODO add status check: on / off
          // backgroundColorHex: item.design_properties.on.background_color,
          // colorHex: item.design_properties.on.font_color,
          design_properties: design_properties,
          theme,
          controller_id: item.controller_id ? item.controller_id : "",
          controller_uuid: item.data ? item.data.controller_uuid : ""
        }
      }
    ]
  };
}

function urlTile(index, item) {
  const { design_properties } = item;
  delete design_properties.theme;
  const theme = convertTheme(item.theme);
  return {
    comp: "StackLayout",
    attrs: {
      id: index,
      row: 0,
      col: 0
    },
    style: {
      padding: unit(4)
    },
    on: {},
    children: [
      {
        comp: "TileItem",
        attrs: {
          id: index
        },
        props: {
          src: "icons/Controls/ic_controls_pair_56.svg",
          backgroundImage:
            (design_properties &&
              design_properties.on &&
              design_properties.on.use_background_image &&
              design_properties.on.background_image) ||
            "",
          id: item.id,
          title: item.title,
          type: "url",
          design_properties: design_properties,
          theme
        }
      }
    ]
  };
}

function dashboardTile(index, item) {
  const { design_properties } = item;
  delete design_properties.theme;
  const theme = convertTheme(item.theme);
  return {
    comp: "StackLayout",
    attrs: {
      id: index,
      row: 0,
      col: 0
    },
    style: {
      padding: unit(4)
    },
    on: {},
    children: [
      {
        comp: "TileItem",
        attrs: {
          id: index
        },
        props: {
          src: "icons/Actions/ic_actions_calendar-month_56.svg",
          backgroundImage:
            (design_properties &&
              design_properties.on &&
              design_properties.on.use_background_image &&
              design_properties.on.background_image) ||
            "",
          id: item.id,
          title: item.title,
          type: "dashboard",
          design_properties: design_properties,
          theme
        }
      }
    ]
  };
}

function sceneTile(index, item, previewMode) {
  const { design_properties } = item;
  delete design_properties.theme;
  const theme = convertTheme(item.theme);
  return {
    comp: "StackLayout",
    attrs: {
      id: index,
      row: 0,
      col: 0
    },
    style: {
      padding: unit(4)
    },
    on: {},
    children: [
      {
        comp: "TileItem",
        attrs: {
          id: index
        },
        props: {
          src: "icons/Tabbar/ic_tabbar_scenes_56.svg",
          backgroundImage:
            (design_properties &&
              design_properties.on &&
              design_properties.on.use_background_image &&
              design_properties.on.background_image) ||
            "",
          id: item.id,
          title: item.title,
          type: "scene",
          design_properties: design_properties,
          theme,
          loading: (previewMode && item.loading) || false
        }
      }
    ]
  };
}

function linkTile(index, item, previewMode) {
  const { design_properties } = item;
  delete design_properties.theme;
  const theme = convertTheme(item.theme);
  return {
    comp: "StackLayout",
    attrs: {
      id: index,
      row: 0,
      col: 0
    },
    style: {
      padding: unit(4)
    },
    on: {},
    children: [
      {
        comp: "TileItem",
        attrs: {
          id: index
        },
        props: {
          src: "icons/Controls/ic_controls_pair_56.svg",
          backgroundImage:
            (design_properties &&
              design_properties.on &&
              design_properties.on.use_background_image &&
              design_properties.on.background_image) ||
            "",
          id: item.id,
          title: item.title,
          type: "link",
          subType: item.subType,
          text: item.text,
          design_properties: design_properties,
          theme,
          target_entity: item.target_entity,
          loading: (previewMode && item.loading) || false
        }
      }
    ]
  };
}
function datetimeTile(index, item, previewMode) {
  const { design_properties } = item;
  delete design_properties.theme;
  const theme = convertTheme(item.theme);
  return {
    comp: "StackLayout",
    attrs: {
      id: index,
      row: 0,
      col: 0
    },
    style: {
      padding: unit(4)
    },
    on: {},
    children: [
      {
        comp: "TileItem",
        attrs: {
          id: index
        },
        props: {
          src:
            item.subType === "time"
              ? "icons/Actions/ic_actions_clock_56.svg"
              : "icons/Actions/ic_actions_calendar_56.svg",
          backgroundImage:
            (design_properties &&
              design_properties.on &&
              design_properties.on.use_background_image &&
              design_properties.on.background_image) ||
            "",
          id: item.id,
          title: "",
          type: "datetime",
          subType: item.subType,
          design_properties: design_properties,
          theme,
          loading: (previewMode && item.loading) || false,
          autoTextSize: true,
          tileSize: item.tileSize
        }
      }
    ]
  };
}
function houseModeTile(index, item, previewMode) {
  const { design_properties } = item;
  delete design_properties.theme;
  const theme = convertTheme(item.theme);
  return {
    comp: "StackLayout",
    attrs: {
      id: index,
      row: 0,
      col: 0
    },
    style: {
      padding: unit(4)
    },
    on: {},
    children: [
      {
        comp: "TileItem",
        attrs: {
          id: index
        },
        props: {
          src:
            (design_properties &&
              design_properties.general &&
              design_properties.general.icon) ||
            item.icon ||
            "icons/Modes/ic_modes_at-home_56.svg",
          backgroundImage:
            (design_properties &&
              design_properties.on &&
              design_properties.on.use_background_image &&
              design_properties.on.background_image) ||
            (theme && theme.general && theme.general.background_image) ||
            "",
          id: item.id,
          title: item.title,
          type: "houseMode",
          subType: item.subType,
          design_properties,
          theme,
          loading: (previewMode && item.loading) || false,
          autoTextSize: true,
          tileSize: item.tileSize
        }
      }
    ]
  };
}

function panicTile(index, item, previewMode) {
  const { design_properties } = item;
  delete design_properties.theme;
  const theme = convertTheme(item.theme);
  return {
    comp: "StackLayout",
    attrs: {
      id: index,
      row: 0,
      col: 0
    },
    style: {
      padding: unit(4)
    },
    on: {},
    children: [
      {
        comp: "TileItem",
        attrs: {
          id: index
        },
        props: {
          src:
            (design_properties &&
              design_properties.general &&
              design_properties.general.icon) ||
            item.icon ||
            "icons/Actions/ic_actions_ezlo-protect_56.svg",
          backgroundImage:
            (design_properties &&
              design_properties.on &&
              design_properties.on.use_background_image &&
              design_properties.on.background_image) ||
            (theme && theme.general && theme.general.background_image) ||
            "",
          id: item.id,
          title: item.title,
          type: "panic",
          subType: item.subType,
          design_properties: design_properties,
          theme,
          loading: (previewMode && item.loading) || false,
          autoTextSize: true,
          tileSize: item.tileSize
        }
      }
    ]
  };
}

function deleteIcon(index) {
  return {
    comp: "SvgIcon",
    attrs: {
      id: index,
      row: 0,
      col: 0
    },
    props: {
      src: "icons/Controls/ic_controls_input-erase_red_24.svg",
      isTapable: true,
      size: 24
    },
    style: {
      horizontalAlign: "right",
      verticalAlign: "top",
      androidElevation: "0",
      gridColumn: "1",
      gridRow: "1",
      justifySelf: "end",
      alignSelf: "start"
    }
  };
}

export function createTile({ index, item, type, theme }) {
  let tile = null;
  switch (type) {
    case "device":
      tile = deviceTile(index, item);
      break;
    case "dashboard":
      tile = dashboardTile(index, item);
      break;
    case "url":
      tile = urlTile(index, item);
      break;
    case "scene":
      tile = sceneTile(index, item);
      break;
    case "link":
      tile = linkTile(index, item);
      break;
    case "delete":
      tile = deleteIcon(index);
      break;
    case "empty":
      tile = emptyTile({ index, theme });
      break;
    case "datetime":
      tile = datetimeTile(index, item);
      break;
    case "houseMode":
      tile = houseModeTile(index, item);
      break;
    case "panic":
      tile = panicTile(index, item);
      break;
    default:
      tile = unknownTile(index, item);
      break;
  }
  return tile;
}

function unit(value) {
  const { VUE_APP_MODE } = process.env;
  return VUE_APP_MODE === "web" ? value.toString() + "px" : value.toString();
}
