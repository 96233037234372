<template>
  <GridLayout
    rows="*"
    columns="*"
    class="tile-item-template"
    :backgroundColor="
      backgroundImage ? 'transparent' : getColor(backgroundColor)
    "
  >
    <ImageCacheIt
      row="0"
      col="0"
      class="tile-item-template_image"
      v-show="backgroundImage"
      :src="backgroundImage"
      stretch="aspectFill"
    />
    <GridLayout row="0" col="0" rows="auto, auto" columns="*">
      <GridLayout row="0" col="0" rows="56" columns="56, *">
        <ColorEllipse
          row="0"
          col="0"
          :color="getColor(iconColor)"
          :shadow="false"
        />
      </GridLayout>
      <GridLayout row="1" col="0" rows="16, 16" columns="56, *, *, *">
        <ColorBar
          row="0"
          col="0"
          sowSpan="2"
          v-if="showBar1"
          class="tile-item-template__color-bar-1"
          :color="getColor(fontColor)"
        />
        <ColorBar
          row="1"
          col="0"
          v-if="showBar2"
          class="tile-item-template__color-bar-2"
          :color="getColor(fontColor)"
        />
      </GridLayout>
    </GridLayout>
  </GridLayout>
</template>

<script>
import platform from "vuexp/src/platform";
import { colorHexA } from "@/helpers/utils";
import moment from "moment";

const { VUE_APP_MODE } = process.env;
export default {
  name: "TileItemTemplate",
  props: {
    backgroundImage: {
      type: String,
      default: ""
    },
    backgroundColor: {
      type: String,
      default: "#3e46ae"
    },
    iconColor: {
      type: String,
      default: "#ffffff"
    },
    fontColor: {
      type: String,
      default: "#ffffff"
    },
    showBar1: {
      type: Boolean,
      default: true
    },
    showBar2: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    getColor(color) {
      return VUE_APP_MODE === "web" ? colorHexA(color) : color;
    }
  }
};
</script>

<style scoped lang="scss">
@import "~/assets/styles/variables.scss";
.tile-item-template {
  border-radius: $l;
  border-width: $stroke;
  border-style: solid;
  border-color: $color-grey-a25;
  &__color-bar-1 {
    grid-row: 1;
    grid-column: 1 / span 4;
  }
  &__color-bar-2 {
    grid-row: 2;
    grid-column: 1 / span 2;
  }
}
</style>
