import auth from "./auth";
import location from "./location";
import dashboards from "./dashboards";
import ui from "./ui";
import devices from "./devices";
import favorites from "./favorites";
import rooms from "./rooms";
import hub from "./hub";
import items from "./items";
import template from "./template";
import categories from "./categories";
import scenes from "./scenes";
import abstracts from "./abstracts";
import energy from "./energy";
import broadcast from "./broadcast";
import themes from "./themes";

export default {
  ui,
  auth,
  dashboards,
  location,
  devices,
  favorites,
  rooms,
  hub,
  items,
  template,
  categories,
  scenes,
  abstracts,
  energy,
  broadcast,
  themes
};
