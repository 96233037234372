<template>
  <MainPage>
    <KeyboardAvoidingView contentVerticalAlign="top" fillHeight>
      <IBtnHiBtn
        slot="action-bar"
        :text="translations.textTitle"
        icon1Src="icons/Controls/ic_controls_arrow-left_56.svg"
        @iconTap1="handleNavigateBack"
      />
      <StackLayout slot="content" height="100%">
        <component
          :is="stepComponent.component"
          v-bind="stepComponent.props"
          @submit="stepComponent.submit"
        />
      </StackLayout>
    </KeyboardAvoidingView>
  </MainPage>
</template>
<script>
import NewLinkAddress from "@/components/dashboard/NewLinkAddress";
import NewLinkAddressName from "@/components/dashboard/NewLinkAddressName";
import { createNamespacedHelpers } from "vuex";

const { mapActions: mapTemplateActions } = createNamespacedHelpers("template");

export default {
  name: "NewLinkTile",
  components: {
    NewLinkAddress,
    NewLinkAddressName
  },
  props: {
    index: {
      type: Number,
      required: true
    },
    isLandscape: {
      type: Boolean,
      default: false
    },
    dashboardId: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      link: "",
      name: "",
      step: "link"
    };
  },
  computed: {
    orientation() {
      return this.isLandscape ? "landscape" : "portrait";
    },
    translations() {
      return this.stepComponent.translations;
    },
    steps() {
      return {
        name: {
          component: "NewLinkAddressName",
          submit: this.handleSubmitName.bind(this),
          translations: this.$t("newLinkAddressName") // eslint-disable-line
        },
        link: {
          component: "NewLinkAddress",
          submit: this.handleSubmitLink.bind(this),
          props: {
            initialLink: this.link
          },
          translations: this.$t("newLinkAddress") // eslint-disable-line
        }
      };
    },
    stepComponent() {
      return this.steps[this.step];
    }
  },
  methods: {
    ...mapTemplateActions(["setTile"]),
    handleSubmitLink(link) {
      this.link = link;
      this.step = "name";
    },
    handleSubmitName(name) {
      this.name = name;
      this.setTile({
        index: this.index,
        item: {
          link: this.link,
          title: this.name
        },
        dashboardId: this.dashboardId,
        type: "url",
        orientation: this.orientation
      });
      this.navigateBack();
    },
    handleNavigateBack() {
      if (this.step === "name") {
        this.step = "link";
        this.name = "";
        return;
      }
      this.navigateBack();
    }
  }
};
</script>
