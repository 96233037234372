<template>
  <MainPage>
    <GridLayout class="bg" height="100%" rows="auto, *, auto" columns="*">
      <IBtnHiBtn
        row="0"
        col="0"
        icon1Src="icons/Controls/ic_controls_arrow-left_56.svg"
        :text="title"
        @iconTap1="handleGoBack"
      />
      <ScrollView row="1" col="0" height="100%">
        <StackLayout class="bg">
          <BackgroundOverview
            :texts="textsMain"
            :imagePresets="getDashboardDefaultImagePresets"
            :selectedImageSrc="selectedImageSrc"
            :isDisabled="getDoneBtnStatus"
            :useBackgroundImage="useBackgroundImage"
            :colorHex="colorHex"
            @customImg="handleAddCustomImage"
            @selectImg="handleSelectImage"
            @useBackgroundImage="handleUseBackgroundImage"
            @selectColor="selectColor"
            @colorChange="colorChange"
            @done="handleDone"
          />
        </StackLayout>
      </ScrollView>
      <StackLayout row="2" col="0" class="bg__btn">
        <ButtonFilled
          :disabled="getDoneBtnStatus"
          :text="textsMain.textBtn"
          @tap="handleDone"
        />
      </StackLayout>
    </GridLayout>
  </MainPage>
</template>
<script>
import { createNamespacedHelpers } from "vuex";
import { ColorPicker } from "../../helpers/helperLibrary";
import BackgroundOverview from "@/components/dashboard/BackgroundOverview";
import { helpers } from "ezlo-core-ui";
import platform from "vuexp/src/platform";
import { rgbaToAHex, rgbaTohex } from "@/helpers/utils";

const { VUE_APP_MODE } = process.env;
const colorModule = helpers.core.color;
const { mapActions: mapUiActions } = createNamespacedHelpers("ui");
const {
  mapActions: mapDashboardActions,
  mapGetters: mapDashboardGetters
} = createNamespacedHelpers("dashboards");

export default {
  name: "Background",
  components: {
    BackgroundOverview
  },
  props: {
    backgroundOptions: {
      type: Object,
      default: () => ({})
    },
    bgType: {
      type: String,
      default: "dashboard",
      validator(value) {
        return ["dashboard", "tile"].includes(value);
      }
    },
    callbackDone: {
      type: Function
    }
  },
  created() {
    if (platform.platform !== "web" && !this.colorPicker) {
      this.colorPicker = new ColorPicker();
    }
  },
  data() {
    const useImage =
      this.backgroundOptions.use_background_image ||
      this.backgroundOptions.useBackgroundImage;
    const selectedImageSrc =
      this.backgroundOptions.background_image ||
      this.backgroundOptions.backgroundImage ||
      "";
    const colorHex =
      this.backgroundOptions.background_color ||
      this.backgroundOptions.backgroundColor;
    return {
      colorPicker: null,
      useBackgroundImage: useImage ? true : false,
      selectedImageSrc,
      colorHex
    };
  },
  watch: {
    backgroundOptions(newValue) {
      this.colorHex = newValue.background_color;
      this.useBackgroundImage = newValue.use_background_image;
      this.selectedImageSrc = newValue.background_image;
    }
  },
  beforeDestroy() {
    this.selectedImageSrc = null;
  },
  computed: {
    ...mapDashboardGetters(["getDashboardDefaultImagePresets"]),
    isChanged() {
      return this.dataChanges;
    },
    getDoneBtnStatus() {
      return !this.isChanged;
    },
    textsMain() {
      return this.$t("styleSettingsBgImage");
    },
    title() {
      return this.bgType === "dashboard"
        ? this.textsMain.textTitle
        : this.textsMain.textTileBackground;
    },
    dataChanges() {
      let data;
      const color =
        VUE_APP_MODE === "web" ? rgbaToAHex(this.colorHex) : this.colorHex;
      // const color = this.colorHex;
      if (this.options) {
        const {
          use_background_image,
          background_image,
          background_color
        } = this.backgroundOptions;

        if (use_background_image !== this.useBackgroundImage) {
          data = { use_background_image: this.useBackgroundImage };
        }

        if (
          background_image !== this.selectedImageSrc ||
          this.useBackgroundImage
        ) {
          data = { ...data, background_image: this.selectedImageSrc };
        }
        if (
          background_color.toUpperCase() !== color.toUpperCase() &&
          !this.useBackgroundImage
        ) {
          data = { ...data, background_color: color };
        }
      } else {
        data = { use_background_image: this.useBackgroundImage };
        data = { ...data, background_image: this.selectedImageSrc };
        data = { ...data, background_color: color };
      }
      return data;
    }
  },
  methods: {
    ...mapUiActions(["saveSnackbarText"]),
    ...mapDashboardActions(["addDashboardCustomBgImg"]),
    handleGoBack() {
      this.navigateBack();
    },
    async handleAddCustomImage(image) {
      await this.addDashboardCustomBgImg(image);
      setTimeout(() => {
        this.saveSnackbarText(this.textsMain.textSnackbar.added);
      }, 500);
    },
    handleSelectImage(item) {
      this.selectedImageSrc = item.src;
    },
    handleUseBackgroundImage(value) {
      this.useBackgroundImage = value;
      if (!value) {
        this.selectedImageSrc = "";
      } else {
        this.colorHex = "";
      }
    },
    handleDone() {
      // emit: _dashboardBackgroundSelected
      // emit: _tileBackgroundSelected
      this.$root.$emit(`_${this.bgType}BackgroundSelected`, {
        ...this.dataChanges
      });
      this.callbackDone &&
        this.callbackDone({
          ...this.dataChanges,
          color: this.colorHex,
          hexA: rgbaTohex(this.colorHex),
          aHex: rgbaToAHex(this.colorHex)
        });
      this.handleGoBack();
    },
    selectColor(oldColorHex) {
      if (platform.platform !== "web") {
        if (!this.colorPicker) {
          this.colorPicker = new ColorPicker();
        }
        this.colorPicker
          .show(oldColorHex ? oldColorHex : "#ffffff", "ARGB")
          .then(result => {
            if (result) {
              const color = new colorModule.Color(result);
              this.colorHex = color ? color.hex : oldColorHex;
            }
          })
          .catch(err => {
            console.log(`Error handle select color:${err}`); // eslint-disable-line
          });
      }
    },
    colorChange(color) {
      this.colorHex = color;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~/assets/styles/base.scss";
.bg {
  height: 100%;
  &__btn {
    padding-bottom: $padding-xl;
    padding-left: $padding-xl;
    padding-right: $padding-xl;
  }
}
</style>
