import Vue from "vue";
import Vuex from "vuex";
import modules from "./modules";
import VuexPersistence from "vuex-persist";
Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  modules: ["auth"] //only save user module
});

export default new Vuex.Store({
  modules,
  strict: true,
  plugins: [vuexLocal.plugin]
});
