<template>
  <StackLayout>
    <StackLayout class="error-dialog__close-button">
      <SvgIcon v-if="closeButton" :src="closeButtonIcon" @tap="cancel" />
    </StackLayout>
    <OneAction
      :icon="icon"
      :text1="texts.text1"
      :text2="texts.text2"
      :action="texts.actionText"
      :warning="warning"
      @tap="close"
    />
  </StackLayout>
</template>

<script>
export default {
  name: "ErrorDialog",
  props: {
    icon: {
      type: String
    },
    text1: {
      type: String
    },
    text2: {
      type: String
    },
    actionText: {
      type: String
    },
    warning: {
      type: Boolean
    },
    closeButton: {
      type: Boolean,
      default: false
    },
    closeButtonIcon: {
      type: String,
      default: "icons/Controls/ic_controls_close_56.svg"
    },
    resultActionName: String
  },
  computed: {
    texts() {
      return {
        text1: this.$t(this.text1),
        text2: this.$t(this.text2),
        actionText: this.$t(this.actionText)
      };
    }
  },
  methods: {
    cancel(event) {
      this.$emit("close", {
        resultActionName: this.resultActionName,
        action: "cancel",
        result: false,
        event
      });
    },
    close(event) {
      this.$emit("close", {
        resultActionName: this.resultActionName,
        action: "close",
        result: true,
        event: event
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~/assets/styles/base.scss";
.error-dialog {
  &__close-button {
    align-self: start;
  }
}
</style>
