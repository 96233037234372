<template>
  <Page actionBarHidden="true">
    <StackLayout
      height="100%"
      class="spinner"
      isPassThroughParentEnabled="false"
      @tap="() => {}"
    >
      <Spinner height="100%" />
    </StackLayout>
  </Page>
</template>
<script>
import { createNamespacedHelpers } from "vuex";

const {
  mapActions: mapAuthActions,
  mapGetters: mapAuthGetters
} = createNamespacedHelpers("auth");
const { mapGetters: mapUiGetters } = createNamespacedHelpers("ui");

export default {
  name: "LoadingPage",
  mounted() {
    // timeout increased
    this.setIsWebView(false);
    this.setControllerId(null);
    setTimeout(() => {
      if (this.isAuthenticated) {
        this.getUserData();
      } else {
        this.navigateTo("SignUpIntro", { clearHistory: true });
      }
    }, 100);
  },
  computed: {
    ...mapUiGetters(["hasError"]),
    ...mapAuthGetters(["isAuthenticated", "tokenExpiration"])
  },
  methods: {
    ...mapAuthActions([
      "fetchAllUserData",
      "refreshToken",
      "setIsWebView",
      "setControllerId"
    ]),
    async getUserData() {
      const time = new Date().getTime();
      // if (this.tokenExpiration && this.tokenExpiration > time) {
      //   console.log("###### TOKEN EXPIRED, REFRESHING TOKEN");//eslint-disable-line

      this.refreshToken();
      // }
      await this.fetchAllUserData();
      if (this.hasError) {
        this.navigateTo("SignUpIntro", { clearHistory: true });
        this.$destroy();
        return;
      }
      this.navigateTo("DashboardPreview", { clearHistory: true });
      this.$destroy();
    }
  }
};
</script>
<style lang="scss" scoped>
.spinner {
  justify-content: center;
}
</style>
