<template>
  <StackLayout class="promo">
    <GridLayout>
      <StackLayout row="0" col="0" width="100%">
        <StackLayout class="promo-image">
          <Vxp-Image
            class="promo-image--picture"
            :src="`~/assets/images/promo_1.png`"
            stretch="aspectFill"
            :isUserInteractionEnabled="false"
          />
        </StackLayout>
        <StackLayout class="promo-content">
          <VxpLabel
            class="font-txt-16-par promo-content--type"
            :text="type"
            textWrap
          />
          <VxpLabel
            class="font-h2-24 promo-content--title"
            :text="title"
            textWrap
          />
          <GridLayout rows="auto" columns="auto, *">
            <StackLayout row="0" col="0">
              <ButtonFilledSmall
                class="font-txt-14-btn promo-content--button"
                horizontalAlignment="left"
                :text="btn"
                @tap="$emit('addDashboard', $event)"
              />
            </StackLayout>
          </GridLayout>
        </StackLayout>
      </StackLayout>
      <GridLayout v-if="false" top="0" left="0" columns="*,auto" width="100%">
        <ButtonIcon
          row="0"
          col="1"
          :src="iconPath"
          :size="iconSize"
          @tap="$emit('close', $event)"
        />
      </GridLayout>
    </GridLayout>
  </StackLayout>
</template>

<script>
export default {
  name: "PromoTileBig",
  props: {
    image: {
      type: String,
      default: ""
    },
    type: {
      type: String
    },
    title: {
      type: String
    },
    btn: {
      type: String,
      default: "Button"
    },
    iconPath: {
      type: String,
      default: ""
    },
    iconSize: {
      type: Number,
      default: 56
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~/assets/styles/variables.scss";

.promo {
  border-width: $stroke;
  border-style: solid;
  border-color: $color-grey-a25;
  border-radius: $l;
  &-image {
    width: 100%;
    &--picture {
      border-radius: $corner-l $corner-l 0 0;
    }
  }
  &-content {
    padding: $padding-m $padding-l $padding-l $padding-l;
    &--type {
      margin-bottom: $margin-xs;
      color: $color-grey;
    }
    &--title {
      margin-bottom: $margin-m;
      color: $color-black;
    }
    &--button {
      background: $color-primary;
      color: $color-white;
      border-radius: $xs;
    }
  }
}
</style>
