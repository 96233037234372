<template>
  <MainPage>
    <GridLayout
      height="100%"
      rows="auto, *"
      columns="*"
      :style="{ height: '100%' }"
    >
      <IBtnHiBtn
        row="0"
        col="0"
        icon1Src="icons/Controls/ic_controls_arrow-left_56.svg"
        @iconTap1="navigateBack"
        :text="translations.textTitle"
      />

      <DashboardTemplateTypeOverview
        row="1"
        :texts="translations"
        :templates="getTemplates"
        :selectedTemplateId="getSelectedTemplateId(orientation)"
        @selectTemplate="handleSelectTemplate"
        @gotoNext="handleNextByMode"
        :orientation="isLandscape ? 'landscape' : 'portrait'"
      />
    </GridLayout>
  </MainPage>
</template>

<script>
import DashboardTemplateTypeOverview from "@/components/dashboard/DashboardTemplateTypeOverview";
import { createNamespacedHelpers } from "vuex";

const {
  mapActions: mapTemplateActions,
  mapGetters: mapTemplateGetters
} = createNamespacedHelpers("template");

export default {
  name: "DashboardTemplateType",
  components: {
    DashboardTemplateTypeOverview
  },
  props: {
    editMode: {
      type: String,
      default: "newTemplate",
      validator(value) {
        return ["newTemplate", "changeTemplate", "editTemplate"].includes(
          value
        );
      }
    },
    isEditMode: {
      type: Boolean,
      default: false
    },
    isLandscape: {
      type: Boolean,
      default: false
    },
    dashboardId: {
      type: String,
      default: ""
    },
    isSingleTemplate: {
      type: Boolean,
      default: false
    },
    templateMode: {
      type: Object,
      default: () => ({})
    },
    resetMode: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapTemplateGetters([
      "getSelectedTemplateId",
      "getTemplatesPortrait",
      "getTemplatesLandscape"
    ]),
    orientation() {
      return this.isLandscape ? "landscape" : "portrait";
    },
    getTemplates() {
      return this.isLandscape
        ? this.getTemplatesLandscape
        : this.getTemplatesPortrait;
    },
    translations() {
      const t = this.$t("dashboardTemplateType"); // eslint-disable-line
      const tLnt = {
        textTitle: t.createMode.textTitleLantscape,
        textBtn: t.createMode.textBtn
      };
      const tPrt = {
        textTitle: t.createMode.textTitlePortrait,
        textBtn: t.createMode.textBtn
      };
      const tLntEdit = {
        textTitle: t.editMode.textTitleLantscape,
        textBtn: t.editMode.textBtn
      };
      const tPrtEdit = {
        textTitle: t.editMode.textTitlePortrait,
        textBtn: t.editMode.textBtn
      };
      const getTextsForCreateMode = this.isLandscape ? tLnt : tPrt;
      const getTextsForEditMode = this.isLandscape ? tLntEdit : tPrtEdit;
      return this.isEditMode ? getTextsForEditMode : getTextsForCreateMode;
    }
  },
  methods: {
    ...mapTemplateActions([
      "selectTemplate",
      "setTemplate",
      "setTemplateLandscape",
      "resetTemplatePortrait",
      "resetTemplateLandscape"
    ]),
    handleSelectTemplate(template) {
      console.log("### from DashboardTemplateType/handleSelectTemplate", template); //eslint-disable-line
      this.selectTemplate({ id: template.id, orientation: this.orientation });
    },
    handleNextEditMode() {
      if (this.resetMode) {
        if (this.templateMode.id === "portrait") {
          this.resetTemplateLandscape();
        } else if (this.templateMode.id === "landscape") {
          this.resetTemplatePortrait();
        }
      }
      if (this.isLandscape) {
        this.setTemplateLandscape({
          id: this.getSelectedTemplateId(this.orientation)
        });
      } else {
        this.setTemplate({ id: this.getSelectedTemplateId(this.orientation) });
      }
      this.navigateBack();
    },
    handleNextCreateMode() {
      console.log("--->> ### from DashboardTemplateType/handleNextCreateMode to  DashboardTemplate"); //eslint-disable-line
      if (this.isLandscape) {
        this.setTemplateLandscape({
          id: this.getSelectedTemplateId(this.orientation)
        });
      } else {
        this.setTemplate({ id: this.getSelectedTemplateId(this.orientation) });
      }
      this.navigateTo("DashboardTemplate", {
        props: {
          isLandscape: this.isLandscape,
          isSingleTemplate: this.isSingleTemplate,
          templateMode: this.templateMode
        }
      });
    },
    handleNextByMode() {
      this.isEditMode ? this.handleNextEditMode() : this.handleNextCreateMode();
    }
  }
};
</script>
