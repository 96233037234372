<template>
  <GridLayout :columns="columns" :rows="rows" width="100%">
    <StackLayout
      v-for="(template, index) in templates"
      :key="template.id"
      :row="row(index)"
      :col="col(index)"
      @tap="handleSelectTemplate(template)"
      :class="templateClass"
    >
      <TemplateTile
        class="tile"
        :template="template"
        :selected="template.id === selectedTemplateId"
      />
    </StackLayout>
  </GridLayout>
</template>
<script>
import TemplateTile from "@/components/dashboard/TemplateTile";
const { VUE_APP_MODE } = process.env;
export default {
  name: "Templates",
  components: { TemplateTile },
  props: {
    templates: {
      type: Array,
      default: () => []
    },
    selectedTemplateId: {
      type: String
    },
    orientation: {
      type: String,
      default: "portrait",
      validator(value) {
        return ["portrait", "landscape"].includes(value);
      }
    }
  },
  data() {
    return {
      borderSpacing: 9
    };
  },
  computed: {
    rows() {
      return this.orientation === "portrait"
        ? `*,${this.borderSpacing},`.repeat(
            Math.round(this.templates.length / 2)
          )
        : `*,${this.borderSpacing},`.repeat(this.templates.length);
    },
    columns() {
      return this.orientation === "portrait"
        ? `auto,*,${this.borderSpacing},*`
        : "*";
    },
    templateClass() {
      return VUE_APP_MODE === "web" ? `template__${this.orientation}` : "";
    }
  },
  methods: {
    handleSelectTemplate(template) {
      this.$emit("selectTemplate", template);
    },
    row(index) {
      return this.orientation === "portrait"
        ? 2 * Math.trunc(index / 2) // + (VUE_APP_MODE === "web" ? -1 : 0)
        : 2 * index;
    },
    col(index) {
      return this.orientation === "portrait" ? 2 * (index % 2) + 1 : "0";
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~/assets/styles/variables.scss";
.template {
  &__portrait {
    height: unit(249);
    padding-bottom: unit(9);
  }
  &__landscape {
    height: unit(159);
    padding-bottom: unit(9);
  }
}
.tile {
  height: 100%;
}
</style>
