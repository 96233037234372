import { getInitialState } from "@/store/modules/rooms/state";

export default {
  getRooms: state => {
    return state.roomsState.rooms;
  },
  getLoadingState: state => key => state.roomsState.loadingStates[key],
  getActiveRoom: state => {
    return state.roomsState.rooms.find(element => element.selected);
  },
  getRoomByIndex: state => index => {
    return state.roomsState.rooms[index];
  },
  getRoomById: state => id => {
    return state.roomsState.rooms.find(element => element.id === id) || {};
  },
  getRoomByName: state => name => {
    return state.roomsState.rooms.find(element => element.name === name);
  },
  getMaxRoomId: state => {
    return Math.max(...state.roomsState.rooms.map(item => item.id));
  },
  getNewRoom: state => {
    if (state.roomsState.newRoom.name) {
      return state.roomsState.newRoom;
    } else if (state.roomsState.rooms.length > 0) {
      return state.roomsState.rooms[0];
    }

    return getInitialState();
  },
  getValue: state => ({ screenName }) => {
    return state.roomsState[screenName];
  },
  getSelectedItemId: state => {
    return state.roomsState.selectRoomName.selectedItemId;
  },
  getRoomIndexByID: state => id => {
    return state.roomsState.rooms.findIndex(element => element.id === id);
  }
};
