import {
  LOCATIONS_RESET_STATE,
  SET_CURRENT_LOCATION,
  SET_LOCATIONS
} from "./mutationTypes";
import locationService from "@/services/location.service";
import { AUTH } from "@/helpers/errorCodes";
import { HttpError } from "@/helpers/customErrors";
import { getCreatedLocations } from "@/helpers/createLocations";
import { parseHttpResponse } from "@/helpers/responseParser";

let fetchLocationsAsync = false;

const actions = {
  // Sync
  resetState({ commit }) {
    commit(LOCATIONS_RESET_STATE);
  },
  setCurrentLocation({ commit }, payload) {
    commit(SET_CURRENT_LOCATION, payload);
  },

  // Async
  async fetchLocationsAsync({ commit, dispatch, rootGetters }) {
    try {
      const isAuthenticated = rootGetters["auth/isAuthenticated"];
      if (!isAuthenticated) {
        throw AUTH.NOT_LOGGED_IN;
      }

      dispatch("ui/startFetching", null, { root: true });
      const authState = rootGetters["auth/getAuthState"];
      const accountId = rootGetters["auth/getAccountId"];

      const response = await locationService.getLocationsByAccountId(
        accountId,
        {
          token: {
            Identity: authState.Identity,
            IdentitySignature: authState.IdentitySignature
          },
          baseUrl: !fetchLocationsAsync
            ? authState.Server_Account
            : authState.Server_Account_Alt
        }
      );

      const { data, statusCode } = await parseHttpResponse(response);

      if (statusCode !== 200) {
        if (statusCode === 401) {
          throw new HttpError("User not logged in", statusCode);
        }
        if (statusCode === 403) {
          throw new HttpError(data, statusCode);
        }
        throw new HttpError(data, statusCode);
      }
      const locations = getCreatedLocations([{ Devices: data.Devices }]);
      commit(SET_LOCATIONS, locations);
    } catch (err) {
      if (!fetchLocationsAsync) {
        // eslint-disable-next-line
        console.log("fetchLocationsAsync FAILED TRYING WITH ALT SERVER....", err);
        fetchLocationsAsync = true;
        dispatch("fetchLocationsAsync", null);
      } else {
        // eslint-disable-next-line
        console.log("fetchLocationsAsync ERROR:", err);
        fetchLocationsAsync = false;
        dispatch(
          "ui/emitError",
          {
            error: err.message,
            meta: "fetchLocationsAsync",
            code: err.code
          },
          {
            root: true
          }
        );
      }
    } finally {
      dispatch("ui/stopFetching", null, { root: true });
    }
  },
  async resetLocationAllDataAsync({ dispatch }) {
    try {
      await Promise.all([
        dispatch("hub/resetState", null, { root: true }),
        dispatch("devices/resetState", null, { root: true }),
        dispatch("rooms/resetState", null, { root: true })
      ]);
      console.log("Location data has been reset"); //eslint-disable-line
    } catch (err) {
      // eslint-disable-next-line
      console.log("resetLocationAllDataAsync ERROR:", err);
      dispatch(
        "ui/emitError",
        {
          error: err.message,
          meta: "resetLocationAllDataAsync",
          code: err.code
        },
        {
          root: true
        }
      );
    }
  }
};

export default actions;
