import Vue from "vue";
import { SET_ABSTRACTS, SET_ABSTRACT_DEVICES } from "./mutationTypes";

function generateDeviceFromAbstract(device) {
  const { uuid: id, name, capabilities } = device;
  const events = undefined;
  const category = name.split("-")[0];

  return {
    id,
    parentDeviceId: "",
    data: device,
    category,
    subcategory: "",
    type: "abstract",
    batteryPowered: "",
    roomId: "",
    name,
    reachable: "",
    iconSrc: "",
    iconSrcActive: "",
    deviceCategoryIcon: getCategoryIcon(category),
    deviceRoomIcon: "3",
    isQuickAction: true,
    isDeviceDetailsShortcut: true,
    isNotifications: "",
    manufacturerName: category,
    model: "ZW3004",
    manufacturer: category,
    deviceType: "abstract",
    selected: false,
    events,
    eventsCount: events && events.length,
    controller_id: id
  };
}

export default {
  [SET_ABSTRACTS](state, payload) {
    Vue.set(state, "abstracts", payload);
  }
};
