<template>
  <MainPage>
    <StackLayout height="100%" row="0" col="0">
      <IBtnHiBtn
        icon1Src="icons/Controls/ic_controls_arrow-left_56.svg"
        :text="textsMain.title"
        @iconTap1="handleBack"
      />
      <StackLayout>
        <ScrollView height="100%" class="mode-devices__scroll">
          <DevicesGroups
            :devices="scenes"
            @tap="handleTapDevice"
          ></DevicesGroups>
        </ScrollView>
      </StackLayout>
    </StackLayout>
  </MainPage>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const {
  mapGetters: mapSceneGetters,
  mapActions: mapSceneActions
} = createNamespacedHelpers("scenes");
const { mapGetters: mapAuthGetters } = createNamespacedHelpers("auth");
const { mapActions: mapTemplateActions } = createNamespacedHelpers("template"); // to be deleted
export default {
  name: "Scenes",
  props: {
    index: {
      type: Number,
      default: 0
    },
    isLandscape: {
      type: Boolean,
      default: false
    },
    dashboardId: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      groups: ["byType", "byRoom"],
      groupBy: "byType"
    };
  },
  computed: {
    ...mapSceneGetters(["getScenesByFullRoom"]),
    ...mapAuthGetters(["getControllerId"]),
    orientation() {
      return this.isLandscape ? "landscape" : "portrait";
    },
    textsMain() {
      // eslint-disable-next-line
      return this.$t("scenes");
    },
    scenes() {
      return this.getScenesByFullRoom(
        this.isLandscape ? "landscape" : "portrait",
        this.getControllerId
      );
    }
  },
  methods: {
    ...mapTemplateActions(["setTile"]),
    ...mapSceneActions(["addSelectedScene"]),
    handleBack() {
      this.navigateBack();
    },
    handleTapDevice(item) {
      this.addSelectedScene({ id: item.id, isLandscape: this.isLandscape });
      this.setTile({
        index: this.index,
        dashboardId: this.dashboardId,
        type: "scene",
        item,
        orientation: this.orientation
      });
      this.handleBack();
    },
    handleSelectGroupBy(groupBy) {
      this.groupBy = groupBy;
    }
  }
};
</script>
