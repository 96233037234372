import { defaultDesignProperties } from "./state";
import { isEmpty } from "@/helpers/utils";

export default {
  template: state => {
    console.log("### TEMPLATE:"); //eslint-disable-line
    return state.template;
  },
  getTemplateLandscape: state => state.templateLandscape,
  getTemplates: state => state.templates,
  getSelectedTemplate: state => state.selectedTemplate,
  getSelectedTemplateId: (state, getters) => orientation =>
    getters.getSelectedTemplate[orientation].templateId,
  getTemplatesPortrait: (state, getters) => {
    return getters.getTemplates.portrait;
  },
  getTemplatesLandscape: (state, getters) => {
    return getters.getTemplates.landscape;
  },
  getTemplateElementProps: state => ({
    cell_id: index,
    orientation = "portrait"
  }) => {
    const key = orientation === "portrait" ? "template" : "templateLandscape";
    return state[key].children[index].children[0].children[0].props;
  },
  getTemplateElementByOrientation: (state, getters) => ({
    cell_id: index,
    orientation = "portrait"
  }) => {
    const { design_properties } = getters.getTemplateElementProps({
      cell_id: index,
      orientation
    });
    return design_properties || defaultDesignProperties();
  },
  getDesignPropertiesChanged: (state, getters) => ({
    cell_id,
    orientation = "portrait"
  }) =>
    state.designPropertiesChanged || {
      ...getters.getTemplateElementByOrientation({
        orientation,
        cell_id
      })
    },
  hasDesignPropertiesChanges: state => state.designPropertiesChanged,
  getDefaultDesignProperties: defaultDesignProperties,
  getUsedLinkItemsInSelectedTeplate: state => ({
    orientation = "portrait",
    type = "link"
  }) => {
    const key = orientation === "portrait" ? "template" : "templateLandscape";

    const items =
      state[key].children &&
      state[key].children.map(item => {
        const link =
          item.children[0].children && item.children[0].children[0].props;
        if (link && link.type === type) {
          return link.subType;
        }
      });
    return (items && items.length && items.filter(item => item)) || [];
  },
  /**
   * Returns the existing tiles from current template.
   */
  getTiles: state => ({ orientation = "portrait" }) => {
    const key = orientation === "portrait" ? "template" : "templateLandscape";
    const template = state[key];
    const tiles =
      template.children &&
      template.children.map((item, index) => {
        const tile = {
          templateId: template.id,
          cellIndex: index,
          props: item.children[0].children && item.children[0].children[0].props
        };

        if (tile) {
          return tile;
        }
      });
    return (tiles && tiles.filter(item => item.props)) || [];
  },
  getCurrentTemplateMode: state => {
    if (!isEmpty(state.template) && !isEmpty(state.templateLandscape)) {
      return "both";
    } else if (!isEmpty(state.template)) {
      return "portrait";
    } else if (!isEmpty(state.templateLandscape)) {
      return "landscape";
    }
    return "";
  },
  getTemplateBuffer: state => state.templateBuffer,
  getHouseModes: state => state.houseModes,
  getPanicTypes: state => state.panicTypes,
  getTileTypes: (state, getters, rootState, rootGetters) => {
    return state.tileTypes.map(tile => {
      const newTile = { ...tile };
      return {
        ...newTile,
        items: newTile.items.map(item => {
          const visible =
            item.visible === undefined
              ? true
              : typeof item.visible === "boolean"
              ? item.visible
              : rootGetters[item.visible];

          return {
            ...item,
            visible
          };
        })
      };
    });
  }
};
