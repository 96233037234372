<template>
  <GridLayout class="bg-img" height="100%" rows="*" columns="*">
    <StackLayout class="bg-img" row="0" col="0">
      <BackgroundImageOverview
        :texts="textsMain"
        :imagePresets="getDashboardDefaultImagePresets"
        :selectedImageSrc="selectedImage"
        :isDisabled="getDoneBtnStatus"
        @customImg="handleAddCustomImage"
        @selectImg="handleSelectImage"
      />
    </StackLayout>
  </GridLayout>
</template>
<script>
import { createNamespacedHelpers } from "vuex";
import BackgroundImageOverview from "@/components/dashboard/BackgroundImageOverview";

const { mapActions: mapUiActions } = createNamespacedHelpers("ui");
const {
  mapActions: mapDashboardActions,
  mapGetters: mapDashboardGetters
} = createNamespacedHelpers("dashboards");

export default {
  name: "BackgroundImage",
  components: {
    BackgroundImageOverview
  },
  props: {
    dashboardId: {
      type: String,
      required: true,
      default: "1"
    },
    selectedImageSrc: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      selectedImage: this.selectedImageSrc
    };
  },
  beforeDestroy() {
    this.selectedImage = null;
  },
  watch: {
    selectedImageSrc(value) {
      this.selectedImage = value;
    }
  },
  computed: {
    ...mapDashboardGetters(["getDashboardDefaultImagePresets"]),
    getDoneBtnStatus() {
      return !this.selectedImage;
    },
    textsMain() {
      return this.$t("styleSettingsBgImage");
    }
  },
  methods: {
    ...mapUiActions(["saveSnackbarText"]),
    ...mapDashboardActions([
      "selectDashboardActiveBgImage",
      "addDashboardCustomBgImg",
      "saveDashboardSelectedBgImage",
      "uploadImage"
    ]),
    handleGoBack() {
      this.navigateBack();
    },
    async handleAddCustomImage(image) {
      // add image placeholder for upload process
      const options = {
        id: image.id,
        src: "", // image.src
        selected: image.selected
      };
      await this.addDashboardCustomBgImg(options);
      // upload image
      await this.uploadImage(image);
      // get new imageSrc from zero index
      const imageSrc = this.getDashboardDefaultImagePresets[0];
      this.handleSelectImage(imageSrc);
      setTimeout(() => {
        this.saveSnackbarText(this.textsMain.textSnackbar.added);
      }, 500);
    },
    handleSelectImage(item) {
      this.selectedImage = item.src;
      this.$emit("selectImg", item);
    },
    async handleDone() {
      this.navigateBack({
        props: {
          dashboardId: this.dashboardId,
          bgImage: this.selectedImage
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.bg-img {
  height: 100%;
}
</style>
