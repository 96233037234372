<template>
  <GridLayout height="100%" rows="auto, *, auto" columns="*" slot="content">
    <StackLayout row="0" col="0" class="dasboard-name dasboard-name__input">
      <VxpLabel
        ref="label"
        v-if="label"
        class="font-txt-13 dasboard-name__placeholder-label"
        :text="label"
      />
      <TextInput
        ref="nameInput"
        keyboardType="text"
        v-model="dashboardName"
        showSoftInput
        eraseButton
        keepSoftInput
        :maxLength="64"
        @returnPress="finish"
        T9Disabled
      />
    </StackLayout>
    <StackLayout row="1" col="0" height="100%" />
    <StackLayout row="2" col="0" class="dasboard-name dasboard-name__bottom">
      <ButtonFilled
        :text="btnText"
        :disabled="!isFormValid"
        @tap="finish"
        :loading="loading"
      />
    </StackLayout>
  </GridLayout>
</template>
<script>
import { helpers } from "ezlo-core-ui";
import { createNamespacedHelpers } from "vuex";
const { isEmptyString } = helpers.utils;
const { clearFocus, forceHideKeyboard } = helpers.core;
const { mapActions: mapUiActions } = createNamespacedHelpers("ui");
const { mapGetters: mapDashboardGetters } = createNamespacedHelpers(
  "dashboards"
);

export default {
  name: "DashboardNameForm",
  props: {
    name: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    btnText: {
      type: String,
      default: "Button"
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dashboardName: this.name,
      isUsingOneTemplate: false
    };
  },
  computed: {
    ...mapDashboardGetters(["getDashboardByName"]),
    isFormValid() {
      return !this.$v.dashboardName.$invalid;
    },
    nameIsUsed() {
      return this.getDashboardByName(this.dashboardName);
    },
    translations() {
      return this.$t("newDashboard");
    }
  },
  methods: {
    ...mapUiActions(["saveSnackbarErrorText"]),
    finish() {
      if (this.nameIsUsed) {
        this.saveSnackbarErrorText(this.translations.dashboardNameIsUsed);
        return;
      }
      if (!this.isFormValid) return;
      forceHideKeyboard();
      this.$emit("finish", this.dashboardName);
    }
  },
  validations() {
    return {
      dashboardName: {
        nonEmpty: value => !isEmptyString(value)
      }
    };
  }
};
</script>

<style lang="scss" scoped>
@import "~/assets/styles/variables.scss";

.dasboard-name {
  padding: 0 $padding-xl;

  &__input {
    padding-top: $padding-xs;
    padding-bottom: $padding-xl;
  }

  &__bottom {
    margin-bottom: $margin-xl;
  }

  &__placeholder-label {
    color: $color-grey;
  }
}
</style>
