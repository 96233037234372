<template>
  <StackLayout class="progressive-card" @tap="$emit('tap')">
    <StackLayout class="progressive-card__header" orientation="horizontal">
      <SvgIcon class="progressive-card__header__icon" :src="icon" />
      <VxpLabel
        class="progressive-card__header__text font-h3-20"
        :text="header"
        :textWrap="false"
      />
    </StackLayout>
    <StackLayout class="progressive-card__body">
      <VxpLabel
        class="font-txt-16-par progressive-card__body__header"
        :text="subHeader"
        :textWrap="false"
      />
      <SliderCore
        class="progressive-card__body__bar"
        :barColor="barColors.foreground"
        :backgroundColor="barColors.background"
        :initialPercentage="percentage"
        :barheight="6"
      />
      <VxpLabel
        class="font-h3-20 progressive-card__body__description"
        :text="description"
        :textWrap="false"
        :style="{ color: descriptionColor }"
      />
      <VxpLabel
        class="font-txt-13 progressive-card__body__sub-description"
        :text="subDescription"
        :textWrap="false"
      />
    </StackLayout>
  </StackLayout>
</template>
<script>
export default {
  name: "ProgressiveCard",
  props: {
    icon: String,
    header: String,
    subHeader: String,
    percentage: Number,
    description: String,
    descriptionColor: String,
    subDescription: String,
    barColors: {
      type: Object,
      validator: value => {
        return (
          Object.keys(value).includes("background") &&
          Object.keys(value).includes("foreground")
        );
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~/assets/styles/variables.scss";
.progressive-card {
  color: $color-black;
  background: $color-grey-a15;
  padding: $s $xxs $xxs $xxs;
  border-radius: $l;
  border: 1px solid $color-grey-a15;
  &__header {
    &__text {
      margin-top: $l;
    }
  }
  &__body {
    background: $color-white;
    padding: $s $m $m $m;
    border-radius: $l;
    &__bar {
      margin: $l 0 $s 0;
    }
    &__sub-description {
      margin-top: $xs;
      color: $color-grey;
    }
  }
}
</style>
