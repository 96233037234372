export default {
  isPromoShown: state => state.isPromoShown,
  getDashboards: state => state.dashboards,
  filterDashboards: state => controllerId => {
    return controllerId
      ? state.dashboards.filter(dash => dash.entity_id === controllerId)
      : state.dashboards;
  },
  getDashboard: (state, getters) => id => {
    const dashboards = getters.getDashboards;
    console.log("getDashboard",id); // eslint-disable-line
    return dashboards.find(dashboard => dashboard.id === id);
  },
  getDefaultDashboardIndex: (state, getters) => {
    const index = getters.getDashboards.findIndex(
      dashboard => dashboard.isDefault
    );
    return index === -1 ? 0 : index;
  },
  getDashboardByIndex: (state, getters) => payload => {
    return getters.getDashboards[payload];
  },
  getDashboardsExceptCurrent: (state, getters) => id => {
    return getters.getDashboards.filter(dashboard => dashboard.id !== id);
  },
  hasDashboardChanges: state => {
    return state.dashboardsChanged;
  },
  getDashboardChanged: (state, getters) => id => {
    return state.dashboardsChanged || getters.getDashboard(id);
  },
  hasDashboardSettingsChanges: state => {
    return !!state.stylesChanged;
  },
  getDashboardSettingsChanged: (state, getters) => id => {
    const dChanged = getters.getDashboardChanged(id);
    return state.stylesChanged || (dChanged && dChanged.styleSettings);
  },
  getFontFamilies: state => state.fontFamilies,
  getNewDashboard: state => state.newDashboard,
  getNewDashboardName: state => state.newDashboard.name,
  getDashboardDefaultStyleSettings: (
    state,
    getters,
    rootState,
    rootGetters
  ) => {
    const theme = rootGetters["themes/getSelectedTheme"];
    return {
      ...state.setDefStyleSettings,
      theme: {
        portrait: {
          theme_id: (theme && theme.uuid) || "light"
        },
        landscape: {
          theme_id: (theme && theme.uuid) || "light"
        }
      }
    };
  },
  getDashboardLoadingStatus: (state, getters, rootState, rootGetters) => {
    const actions = [
      "createDashboardAsync",
      "removeDashboardAsync",
      "updateDashboardAsync"
    ];
    return actions.some(i => !!i === rootGetters["ui/isFetching"](i));
  },
  getDashboardDefaultImagePresets: state => state.defaultImagePresets,
  getDashboardBgImgPresetBySrc: (_, getters) => src => {
    return getters.getDashboardDefaultImagePresets.find(p => p.src === src);
  },
  getDashboardElementByOrientation: (state, getters) => item => {
    const { dashboardId, orientation = "portrait", cell_id } = item;
    const dashboard = getters.getDashboard(dashboardId);
    if (dashboard) {
      const element = dashboard.template_data[
        orientation
      ].dashboard_elements.find(item => item.cell_id == cell_id);
      return element || {};
    }
    return null;
  },
  getDashboardElementChanged: (state, getters) => item => {
    const { dashboardId, orientation = "portrait", cell_id } = item;
    return (
      state.designPropertiesChanged ||
      getters.getDashboardElementByOrientation({
        dashboardId,
        orientation,
        cell_id
      })
    );
  },
  hasDesignPropertiesChanges: state => state.designPropertiesChanged,
  getStylesChanged: (state, getters) => dashboardId => {
    const dChanged = getters.getDashboardChanged(dashboardId);
    return state.stylesChanged || (dChanged && dChanged.styleSettings);
  },
  getDefaultStyleSettings: state => state.setDefStyleSettings,
  getCustomIcons: state => state.customIcons,
  getDashboardByName: state => name => {
    return state.dashboards.find(dashboard => dashboard.name === name);
  },
  getTutorialApproved: state => state.tutorialApproved,
  hasDashboard: (state, getters) =>
    getters.getDashboards && getters.getDashboards.length > 0,
  getDashboardTheme: (state, getters, rootState, rootGetters) => id => {
    const db = getters.getDashboard(id);
    const dbTheme = db && db.styleSettings && db.styleSettings.theme;
    return {
      portrait:
        (dbTheme &&
          rootGetters["themes/getThemeOrDefault"](
            dbTheme["portrait"].theme_id
          )) ||
        rootGetters["themes/getDefaultTheme"],
      landscape:
        (dbTheme &&
          rootGetters["themes/getThemeOrDefault"](
            dbTheme["landscape"].theme_id
          )) ||
        rootGetters["themes/getDefaultTheme"]
    };
  }
};
