import {
  START_LOADING,
  STOP_LOADING,
  ADD_SELECTED_DEVICE,
  REMOVE_SELECTED_DEVICE,
  RESET_SELECTED_DEVICE,
  SET_SELECTED_DEVICE,
  ADD_DEVICE,
  CHANGE_DEVICE_CATEGORY_ICON,
  CHANGE_DEVICE_NAME,
  CHANGE_DEVICE_NOTIFICATIONS,
  CHANGE_DEVICE_QUICK_ACTION,
  CHANGE_DEVICE_ROOM,
  CHANGE_ROOM,
  DELETE_DEVICE,
  RESET_DEVICES,
  RESET_CURRENT_DEVICE_SETTINGS,
  SAVE_DEVICE_SETTINGS,
  SAVE_DEVICE_CATEGORY_ICON,
  SET_CURRENT_DEVICE_CATEGORY_ICON,
  SET_CURRENT_DEVICE_SETTINGS,
  SET_DEVICES,
  UPDATE_DEVICE,
  UPDATE_WINDOW_COVER,
  CHANGE_DEVICE_DETAILS_SHORTCUT,
  SET_ABSTRACT_DEVICES,
  UPDATE_DEVICES
} from "./mutationTypes";
import * as nmaService from "@/services/nma.service";
import deviceService from "@/services/nma.service/device";
import cloudService from "@/services/cloud.service";
import { HttpError } from "@/helpers/customErrors";
import { parseHttpResponse } from "@/helpers/responseParser";

export default {
  startLoading({ commit }, payload) {
    commit(START_LOADING, payload);
  },
  stopLoading({ commit }, payload) {
    commit(STOP_LOADING, payload);
  },
  addSelectedDevice({ commit }, payload) {
    commit(ADD_SELECTED_DEVICE, payload);
  },
  removeSelectedDevice({ commit }, payload) {
    commit(REMOVE_SELECTED_DEVICE, payload);
  },
  resetSelectedDevices({ commit }, payload) {
    commit(RESET_SELECTED_DEVICE, payload);
  },
  setSelectedDevices({ commit }, payload) {
    commit(SET_SELECTED_DEVICE, payload);
  },
  changeDeviceNameState({ commit }, payload) {
    commit(CHANGE_DEVICE_NAME, payload);
  },
  changeQuickActionState(context, payload) {
    context.commit(CHANGE_DEVICE_QUICK_ACTION, payload);
  },
  changeDeviceDetailsShortCut(context, payload) {
    context.commit(CHANGE_DEVICE_DETAILS_SHORTCUT, payload);
  },
  changeCategoryIconState(context, payload) {
    context.commit(CHANGE_DEVICE_CATEGORY_ICON, payload);
  },
  changeNotificationState(context, payload) {
    context.commit(CHANGE_DEVICE_NOTIFICATIONS, payload);
  },
  changeRoomState(context, payload) {
    context.commit(CHANGE_DEVICE_ROOM, payload);
  },
  setInitialCategoryIcon(context, payload) {
    context.commit(SET_CURRENT_DEVICE_CATEGORY_ICON, payload);
  },
  resetCurrentDeviceSettings(context) {
    context.commit(RESET_CURRENT_DEVICE_SETTINGS);
  },
  saveNewCategoryIconState(context, payload) {
    const icons = context.getters.getItemsState;
    const categoryIconId = payload.id;
    const iconType = icons.find(i => i.id === categoryIconId);

    const data = {
      src: iconType.icons.default,
      srcActive: iconType.icons.white,
      ...payload
    };

    context.commit(SAVE_DEVICE_CATEGORY_ICON, data);
  },
  setCurrentDeviceSettingsById(context, payload) {
    const device = context.getters.getDeviceById(payload);
    context.commit(SET_CURRENT_DEVICE_SETTINGS, device);
  },
  resetState({ commit }) {
    commit(RESET_DEVICES);
  },

  // Async
  async fetchDevices(context, payload) {
    try {
      const {
        data: { devices }
      } = await nmaService.devices.list(payload);
      context.commit(SET_DEVICES, { devices, hub: payload });
    } catch (err) {
      console.log(err); // eslint-disable-line
      context.dispatch(
        "ui/emitError",
        {
          error: err,
          meta: "fetchDevices"
        },
        {
          root: true
        }
      );
    }
  },
  async fetchDevicesFromCloud({ commit, dispatch, rootGetters }, payload) {
    try {
      const { uuids, pairs } = payload;
      dispatch("ui/startFetching", "fetchDevicesFromCloud", { root: true });
      const authJwtToken = rootGetters["auth/getCloudJwtToken"];

      const response = await cloudService.controllerGetReport({
        authJwtToken,
        controllers: uuids
      });
      const { data: content, statusCode } = await parseHttpResponse(
        response,
        "json"
      );
      if (statusCode !== 200) {
        throw new HttpError("Api cloud error or other", statusCode);
      }
      const data = content.data;

      const array = [];
      Object.entries(data).forEach(([key, value]) => {
        value.device_list.raw.forEach(device => {
          device.controller_id = pairs[key];
          device.controller_uuid = key;
          array.push(device);
        });
        const {
          scene_list: { raw: scenes }
        } = value;
        dispatch(
          "scenes/setScenes",
          { scenes, hub: pairs[key] },
          { root: true }
        );
      });
      commit(SET_DEVICES, { devices: array });
    } catch (err) {
      console.log(err); //eslint-disable-line
    } finally {
      dispatch("ui/stopFetching", "fetchDevicesFromCloud", { root: true });
    }
  },
  async deleteDeviceById({ dispatch, commit }, id) {
    try {
      dispatch("ui/startFetching", null, { root: true });

      await deviceService.delete(id);
      await commit(DELETE_DEVICE, id);
    } catch (error) {
      throw new Error(error);
    } finally {
      dispatch("ui/stopFetching", null, { root: true });
    }
  },
  async setBigShadeFavoritePosition({ commit, dispatch }, payload) {
    dispatch("ui/startFetching", null, { root: true });
    commit(UPDATE_WINDOW_COVER, { ...payload, key: "favoritePosition" });
    dispatch("ui/stopFetching", null, { root: true });
  },
  async changeRoom({ commit }, { deviceId, roomId }) {
    await deviceService.changeDeviceRoom({ id: deviceId, roomId });
    commit(CHANGE_ROOM, { deviceId, roomId });
  },
  async saveDeviceSettingsByIdAsync({ state, commit, dispatch, getters }, id) {
    try {
      dispatch("ui/startFetching", id, { root: true });

      const deviceCurrentSettings = state.currentDeviceSettings;
      const {
        name,
        roomId,
        isNotifications,
        isQuickAction
      } = deviceCurrentSettings;
      const prevState = getters.getDeviceById(id);
      // await deviceService.changeDeviceRoom({ id, roomId: data.roomId });

      if (prevState.name !== name) {
        await nmaService.device.nameSet(id, name);
      }
      if (prevState.roomId !== roomId) {
        await nmaService.device.roomSet(id, roomId);
      }
      if (prevState.isNotifications !== isNotifications) {
        const prevNotifiedIds = getters.getDevicesTurnOnNotification;
        const newNotifiedIds = [...prevNotifiedIds];
        isNotifications && newNotifiedIds.push(id);
        await nmaService.devices.service.notificationsSet(newNotifiedIds);
      }
      if (prevState.isQuickAction !== isQuickAction) {
        await nmaService.favorites.set([
          {
            _id: id,
            favorite: isQuickAction
          }
        ]);
      }

      /*
        need to save current device for changing all other
        data which isn't getting from api by sockets
       */
      commit(SAVE_DEVICE_SETTINGS, { id, data: deviceCurrentSettings });
      dispatch("resetCurrentDeviceSettings");
    } catch (error) {
      dispatch(
        "ui/emitError",
        {
          error: error,
          meta: "saveNewDeviceSettingsAsync"
        },
        {
          root: true
        }
      );
    } finally {
      dispatch("ui/stopFetching", id, { root: true });
    }
  },
  updateDevices({ commit, dispatch }, { action, data }) {
    const { _id } = data;
    switch (action) {
      case "updated":
        console.log("# ---> Device Updated <--- #", { _id, data }); // eslint-disable-line
        commit(UPDATE_DEVICE, { _id, data });
        break;
      case "added":
        console.log("# ---> Device Added <--- #", { _id, data }); // eslint-disable-line
        commit(ADD_DEVICE, data);
        break;
      case "removed":
        console.log("# ---> Device Removed <--- #", { _id, data }); // eslint-disable-line
        dispatch(
          "favorites/updateFavorite",
          { action: "removed", data: { devices: [_id] } },
          { root: true }
        );
        commit(DELETE_DEVICE, _id);
        break;
    }
    console.log("# ---> broadcast <--- #", data); // eslint-disable-line
  },
  addVeraDevices({ commit, dispatch, getters, rootGetters }, payload) {
    const categories = rootGetters["categories/getCategories"];
    const options = { isVera: true, categories, ...payload };
    commit(SET_DEVICES, options);
  },
  updateVeraDevices({ commit, dispatch, getters, rootGetters }, payload) {
    const options = { isVera: true, ...payload };
    commit(UPDATE_DEVICES, options);
  },
  createDevicesFromAbstracts({ commit, getters }, payload) {
    commit(SET_ABSTRACT_DEVICES, payload);
  }
};
