<template>
  <MainPage @layoutChanged="onlayoutChanged">
    <GridLayout rows="*, auto" columns="*">
      <KeyboardAvoidingView
        row="0"
        col="0"
        contentVerticalAlign="bottom"
        :iosTextInputKeyboardDistance="iosTextInputKeyboardDistance"
      >
        <IBtnAct
          slot="action-bar"
          src="icons/Controls/ic_controls_arrow-left_56.svg"
          @tap="handleBack"
        />

        <CurrentPasswordForm
          slot="content"
          :isShowError="getError"
          :titleText="translations.textTitle"
          :passwordPlaceholderText="translations.textPWDPlaceholder"
          :btnText="translations.textBtn"
          :errorText="translations.textError"
          @submit="handleSubmit"
          @layoutChange="handleKeyboard"
        />
      </KeyboardAvoidingView>
    </GridLayout>
  </MainPage>
</template>

<script>
import { helpers } from "ezlo-core-ui";
import CurrentPasswordForm from "@/components/forms/CurrentPasswordForm";
import { createNamespacedHelpers } from "vuex";

const {
  mapGetters: mapUiGetters,
  mapActions: mapUiActions
} = createNamespacedHelpers("ui");
const { IOS_TEXT_INPUT_KEYBOARD_OFFSET_DEFAULT } = helpers.appConst;
const IOS_TEXT_INPUT_KEYBOARD_OFFSET = 220;

export default {
  name: "ProfileCurrentPassword",
  components: {
    CurrentPasswordForm
  },
  data() {
    return {
      areInputsVisible: false
    };
  },
  computed: {
    ...mapUiGetters(["hasError"]),
    getError() {
      return this.hasError;
    },
    iosTextInputKeyboardDistance() {
      return this.areInputsVisible
        ? IOS_TEXT_INPUT_KEYBOARD_OFFSET_DEFAULT
        : IOS_TEXT_INPUT_KEYBOARD_OFFSET;
    },
    translations() {
      return this.$t("profileCurrentPassword");
    }
  },
  methods: {
    handleBack() {
      this.navigateTo("Profile");
    },
    handleKeyboard(areInputsVisible) {
      this.areInputsVisible = areInputsVisible;
    },
    handleSubmit(currentPassword) {
      this.navigateTo("ProfileCreatePassword", {
        props: {
          userCurrentPassword: currentPassword
        }
      });
    },
    onlayoutChanged(event) {
      if (event.object.android) {
        event.object.android.setFocusable(true);
        event.object.android.setFocusableInTouchMode(true);
      }
    }
  }
};
</script>
