import {
  DASHBOARDS_SET,
  DASHBOARDS_HIDE_PROMO,
  DASHBOARD_RENAME,
  DASHBOARD_DELETE,
  DASHBOARD_UPDATE,
  DASHBOARD_CANCEL,
  STYLE_SETTING_SET,
  STYLE_SETTINGS_CANCEL,
  STYLE_SETTINGS_SAVE,
  STYLE_SETTINGS_SELECT_BG_IMG,
  STYLE_SETTINGS_ADD_BG_IMG,
  STYLE_SETTINGS_SAVE_BG_IMG,
  STYLE_SETTINGS_DEFAULT,
  SET_FONT_FAMILY,
  NEW_DASHBOARD_SET_TEMPLATE,
  NEW_DASHBOARD_RESET,
  NEW_DASHBOARD_SET_NAME,
  SET_STYLE_CHANGED,
  DASHBOARD_CHANGED_SET,
  SET_BACKGROUND_IMAGES,
  SET_AS_DEFAULT_DASHBOARD,
  DELETE_ICON,
  SET_USER_ICONS,
  SET_TUTORIAL_APPROVED,
  SET_DASHBOARD_THEME
} from "./mutationsTypes";
import storageService from "@/services/storage.service";
import { HttpError } from "@/helpers/customErrors";
import dashboardsService from "@/services/dashboards.service";
import { getCreatedDashboards } from "@/helpers/createDashboards";
import { getSetupDashboardJSON } from "@/helpers/dasboardMapper";
import apiConfig from "../../../../config/api";
import { parseHttpResponse } from "@/helpers/responseParser";
import { cloneDeep } from "lodash";
const { VUE_APP_MODE } = process.env;

let fetchBackgroundImages = false;
let fetchCustomIcons = false;

export default {
  // Sync
  hidePromo({ commit }) {
    commit(DASHBOARDS_HIDE_PROMO);
  },
  setStyleSetting({ commit }, payload) {
    commit(STYLE_SETTING_SET, payload);
  },
  cancelDashboardSettings({ commit }, payload) {
    commit(STYLE_SETTINGS_CANCEL, payload);
  },
  updateDashboardSettings({ commit }, payload) {
    commit(STYLE_SETTINGS_SAVE, payload);
  },
  setStyleChanged({ commit }, payload) {
    commit(SET_STYLE_CHANGED, payload);
  },
  setDefaultDashboardSettings({ commit }) {
    commit(STYLE_SETTINGS_DEFAULT);
  },
  setFontFamily({ commit }, payload) {
    commit(SET_FONT_FAMILY, payload);
  },
  selectDashboardActiveBgImage({ commit }, payload) {
    commit(STYLE_SETTINGS_SELECT_BG_IMG, payload);
  },
  addDashboardCustomBgImg({ commit }, payload) {
    commit(STYLE_SETTINGS_ADD_BG_IMG, payload);
  },
  saveDashboardSelectedBgImage({ commit }, payload) {
    commit(STYLE_SETTINGS_SAVE_BG_IMG, payload);
  },
  cancelEditDashboard({ commit }) {
    commit(DASHBOARD_CANCEL);
  },
  renameDashboard({ commit }, payload) {
    commit(DASHBOARD_RENAME, payload);
  },
  setAsDefaultDashboard({ commit }, payload) {
    commit(SET_AS_DEFAULT_DASHBOARD, payload);
  },
  resetNewDashboard({ commit }) {
    commit(NEW_DASHBOARD_RESET);
  },
  setNewDashboardTemplate({ commit }, payload) {
    commit(NEW_DASHBOARD_SET_TEMPLATE, payload);
  },
  setNewDashbardName({ commit }, payload) {
    commit(NEW_DASHBOARD_SET_NAME, payload);
  },
  dashboardsHasChanged({ commit, getters }, payload) {
    let dashboardChanged = getters.hasDashboardChanges;
    if (!dashboardChanged) {
      const dashboard = getters.getDashboard(payload);
      dashboardChanged = { ...dashboard };
    }
    commit(DASHBOARD_CHANGED_SET, dashboardChanged);
  },
  // Async
  async setDashboardByIndex({ commit, dispatch, getters }, payload) {
    if (getters.getDashboardByIndex(payload)) {
      const {
        id,
        template,
        template_data,
        styleSettings
      } = getters.getDashboardByIndex(payload);
      const { portrait, landscape } = template;
      if (portrait) {
        dispatch(
          "template/setTemplate",
          {
            id: portrait,
            dashboardId: id,
            tiles: template_data.portrait.dashboard_elements,
            styleSettings,
            previewMode: true
          },
          { root: true }
        );
      } else {
        dispatch("template/resetTemplatePortrait", null, { root: true });
      }
      if (landscape) {
        dispatch(
          "template/setTemplateLandscape",
          {
            id: landscape,
            dashboardId: id,
            tiles: template_data.landscape.dashboard_elements,
            styleSettings,
            previewMode: true
          },
          { root: true }
        );
      } else {
        dispatch("template/resetTemplateLandscape", null, { root: true });
      }
      // fill selected items arrays of different stores like devices, scenes etc.
      dispatch("template/setAssociatedElements", template_data, { root: true });
    }
  },
  async fetchDashboardsAsync({ commit, dispatch, rootGetters }) {
    try {
      dispatch("ui/startFetching", "fetchDashboardsAsync", { root: true });
      const authJwtToken = rootGetters["auth/getCloudJwtToken"];

      const response = await dashboardsService.getDashboardsApi({
        authJwtToken
      });
      const { data: content, statusCode } = await parseHttpResponse(
        response,
        "json"
      );
      if (statusCode !== 200) {
        throw new HttpError("Api cloud error or other", statusCode);
      }

      const { status, complete, data } = content;
      if (status === 0 && complete === 1) {
        throw new HttpError(data.error_text, status);
      }
      const dashboardsList = getCreatedDashboards(data.dashboards);
      commit(DASHBOARDS_SET, dashboardsList);
    } catch (err) {
      console.log("fetchDashboardsAsync ERROR:", err); // eslint-disable-line
      dispatch(
        "ui/emitError",
        {
          type: "errorAvailableEzloHubs",
          error: err.message,
          meta: "fetchDashboardsAsync",
          code: err.code
        },
        {
          root: true
        }
      );
    } finally {
      dispatch("ui/stopFetching", "fetchDashboardsAsync", { root: true });
    }
  },
  async setDefaultDashboard(
    { commit, dispatch, getters, rootGetters },
    payload
  ) {
    const { index, value = false } = payload;
    let db = { ...getters.getDashboardByIndex(index) };
    db.isDefault = value;

    const authJwtToken = rootGetters["auth/getCloudJwtToken"];

    return await dashboardsService.updateDashboardApi({
      authJwtToken,
      options: db
    });
  },
  async updateDashboardAsync(
    { commit, dispatch, getters, rootGetters },
    payload
  ) {
    try {
      dispatch("ui/startFetching", "updateDashboardAsync", { root: true });
      const authJwtToken = rootGetters["auth/getCloudJwtToken"];
      const options = getSetupDashboardJSON(payload);

      // check and clear the current default dashboard.
      if (payload && payload.isDefault) {
        const index = getters.getDefaultDashboardIndex;
        const defaultDb = getters.getDashboardByIndex(index);
        if (defaultDb.id !== options.id && defaultDb.isDefault) {
          await dispatch("setDefaultDashboard", { index, value: false });
        }
      }
      const response = await dashboardsService.updateDashboardApi({
        authJwtToken,
        options
      });

      const { data: content, statusCode } = await parseHttpResponse(
        response,
        "json"
      );
      if (statusCode !== 200) {
        throw new HttpError("Api cloud error or other", statusCode);
      }
      const { status, complete, data } = content;
      if (status === 0 && complete === 1) {
        throw new HttpError(data.error_text, status);
      }

      await dispatch("fetchDashboardsAsync");
      await commit(DASHBOARD_UPDATE);
      await dispatch("cancelEditDashboard");
      dispatch("template/cleanTemplateBuffer", null, {
        root: true
      });
    } catch (err) {
      // eslint-disable-next-line
      console.log("updateDashboardAsync ERROR:", err); // eslint-disable-line
      dispatch(
        "ui/emitError",
        {
          error: err.message,
          meta: "updateDashboardAsync",
          code: err.code
        },
        {
          root: true
        }
      );
    } finally {
      dispatch("ui/stopFetching", "updateDashboardAsync", { root: true });
    }
  },
  async removeDashboardAsync({ commit, dispatch, rootGetters }, { id }) {
    try {
      dispatch("ui/startFetching", "removeDashboardAsync", { root: true });

      const authJwtToken = rootGetters["auth/getCloudJwtToken"];
      const response = await dashboardsService.deleteDashboardApi({
        authJwtToken,
        options: { id }
      });
      const { data: content, statusCode } = await parseHttpResponse(
        response,
        "json"
      );
      if (statusCode !== 200) {
        throw new HttpError("Api cloud error or other", statusCode);
      }
      const { status, complete, data } = content;
      if (status === 0 && complete === 1) {
        throw new HttpError(data.error_text, status);
      }

      await dispatch("fetchDashboardsAsync");
      commit(DASHBOARD_DELETE);
    } catch (err) {
      // eslint-disable-next-line
      console.dir("removeDashboardAsync ERROR:", err);
      dispatch(
        "ui/emitError",
        {
          error: err.message,
          meta: "removeDashboardAsync",
          code: err.code
        },
        {
          root: true
        }
      );
    } finally {
      dispatch("ui/stopFetching", "removeDashboardAsync", {
        root: true
      });
    }
  },
  async createDashboardAsync(context, payload) {
    const { commit, dispatch, getters, rootGetters } = context;
    try {
      dispatch("ui/startFetching", "createDashboardAsync", { root: true });
      const authJwtToken = rootGetters["auth/getCloudJwtToken"];
      let initialStyleSettings = cloneDeep(
        getters.getDashboardDefaultStyleSettings
      );
      initialStyleSettings.general = {
        ...initialStyleSettings.general,
        ...payload.styleSettings?.general
      };
      const hubsUUids = rootGetters["hub/getHubUUIDs"];
      const controllerID = rootGetters["auth/getControllerId"];
      const isWebView = rootGetters["auth/isWebView"];

      const hubInfo = rootGetters["hub/getHubs"].find(
        hub => hub.hubSerial === controllerID
      );
      const hubUUIDInfo = hubsUUids.find(hub => hub.id === controllerID);

      const entity_uuid = hubUUIDInfo
        ? hubUUIDInfo.uuid
        : isWebView
        ? "00000000-0000-0000-0000-000000000000"
        : hubsUUids.length > 0
        ? hubsUUids[0].uuid
        : "00000000-0000-0000-0000-000000000000";
      const entity_id = hubInfo ? hubInfo.hubSerial : "no_hub";

      const newDashboard = {
        name: payload.name,
        isDefault: payload.isDefault || false,
        entity_type: isWebView
          ? entity_id === "no_hub"
            ? "group"
            : "controller"
          : "group",
        // for distinction between standalone and webview groups
        group_type: isWebView ? "static" : "dynamic",
        entity_uuid,
        entity_id,
        styleSettings: initialStyleSettings,
        template_tiles: {
          portrait: payload.portrait,
          landscape: payload.landscape
        }
      };
      const options = getSetupDashboardJSON(newDashboard, true);
      const response = await dashboardsService.createDashboardApi({
        authJwtToken,
        options
      });
      const { data: content, statusCode } = await parseHttpResponse(
        response,
        "json"
      );
      if (statusCode !== 200) {
        throw new HttpError("Api cloud error or other", statusCode);
      }
      const { status, complete, data } = content;
      if (status === 0 && complete === 1) {
        throw new HttpError(data.error_text, status);
      }
      newDashboard.id = data.created.uuid;
      await dispatch("updateDashboardAsync", newDashboard);
      await dispatch("fetchDashboardsAsync");
    } catch (err) {
      // eslint-disable-next-line
      console.dir("createDashboardAsync ERROR:", err);
      dispatch(
        "ui/emitError",
        {
          error: err.message,
          meta: "createDashboardAsync",
          code: err.code
        },
        {
          root: true
        }
      );
    } finally {
      dispatch("ui/stopFetching", "createDashboardAsync", {
        root: true
      });
    }
  },
  async createDuplicateDashItemAsync(
    { commit, dispatch, getters, rootGetters },
    payload
  ) {
    try {
      dispatch("ui/startPageLoading", null, { root: true });
      let dashboard = getters.getDashboard(payload.dashboardId);
      let dashboardOptions = {
        landscape: rootGetters["template/getTemplateLandscape"],
        portrait: rootGetters["template/template"],
        name: dashboard.name + " Copy",
        styleSettings: dashboard.styleSettings,
        isDefault: false
      };

      await dispatch("createDashboardAsync", dashboardOptions);
    } catch (err) {
      // eslint-disable-next-line
      console.dir("createDuplicateDashItem ERROR:", err);
      dispatch(
        "ui/emitError",
        {
          error: err.message,
          meta: "createDuplicateDashItem",
          code: err.code
        },
        {
          root: true
        }
      );
    } finally {
      dispatch("ui/stopPageLoading", null, { root: true });
    }
  },
  async fetchCustomIcons({ commit, dispatch }) {
    const payload = await dispatch("getCustomIconsFromKVS");
    commit(SET_USER_ICONS, payload);
  },
  async getCustomIconsFromKVS({
    commit,
    dispatch,
    state,
    getters,
    rootGetters
  }) {
    try {
      dispatch("ui/startFetching", "getCustomIconsFromKVS", { root: true });
      const token = {
        ...rootGetters["auth/getKVSToken"](fetchCustomIcons),
        key: "customIcons"
      };
      const PK_Account = rootGetters["auth/getAccountId"];
      const payload = await storageService.getKeyValueFromKVS(token, {
        PK_Account
      });
      return payload;
    } catch (error) {
      if (!fetchCustomIcons) {
        console.log("getCustomIconsFromKVS RETRYING WITH ALT SERVER...."); //eslint-disable-line
        fetchCustomIcons = true;
        dispatch("fetchBackgroundImages", null);
      } else {
        dispatch(
          "ui/emitError",
          {
            error: error.message,
            meta: "getImagesFromKVS",
            code: error.code
          },
          {
            root: true
          }
        );
      }
    } finally {
      dispatch("ui/stopFetching", "getCustomIconsFromKVS", { root: true });
    }
  },
  async fetchBackgroundImages({ commit, dispatch }) {
    const payload = await dispatch("getImagesFromKVS");
    commit(SET_BACKGROUND_IMAGES, payload);
  },
  async getImagesFromKVS({ commit, dispatch, state, getters, rootGetters }) {
    try {
      dispatch("ui/startFetching", "fetchBackgroundImages", { root: true });
      const token = {
        ...rootGetters["auth/getKVSToken"](fetchBackgroundImages),
        key: "backgroundImages"
      };
      const PK_Account = rootGetters["auth/getAccountId"];
      const payload = await storageService.getKeyValueFromKVS(token, {
        PK_Account
      });
      return payload;
    } catch (error) {
      if (!fetchBackgroundImages) {
        console.log("fetchBackgroundImages RETRYING WITH ALT SERVER...."); //eslint-disable-line
        fetchBackgroundImages = true;
        dispatch("fetchBackgroundImages", null);
      } else {
        dispatch(
          "ui/emitError",
          {
            error: error.message,
            meta: "getImagesFromKVS",
            code: error.code
          },
          {
            root: true
          }
        );
      }
    } finally {
      dispatch("ui/stopFetching", "fetchBackgroundImages", { root: true });
    }
  },
  async uploadImage(
    { commit, state, dispatch, getters, rootGetters },
    payload
  ) {
    try {
      console.log("### uploadImage actions:", payload); //eslint-disable-line
      const PK_Account = rootGetters["auth/getAccountId"];
      const PK_User = rootGetters["auth/getPK_User"];
      const { Identity, IdentitySignature } = rootGetters["auth/getAuthState"];
      const token = {
        Identity,
        IdentitySignature
      };
      const options = {
        account: {
          PK_Account,
          PK_User
        },
        fileName: "backgroundImage.jpg",
        file: payload
      };
      let response = null;
      if (VUE_APP_MODE === "web") {
        response = await storageService.uploadWeb(token, options);
      } else {
        response = await storageService.upload(token, options);
      }
      // {"PK_Store":872387475,"Key":"2449032692"}
      // sample url: https://vera-us-oem-storage25.mios.com/storage/storage/store/872334865/thumbnail?Key=3722163767
      console.log("Send Successfull to File Storage Server"); //eslint-disable-line
      console.dir(response); // eslint-disable-line
      const { PK_Store, Key, responseCode } = response;
      if (response) {
        if (
          (responseCode && [201, -1].includes(responseCode)) ||
          (VUE_APP_MODE === "web" && PK_Store)
        ) {
          const images = state.imagesKVS ? [...state.imagesKVS] : []; // get current images form state
          images.unshift({ PK_Store, Key });
          await dispatch("saveImageToKVS", { key: "backgroundImages", images });
          commit(SET_BACKGROUND_IMAGES, {
            imageUrl: `https://${apiConfig.storageBaseUrl}/storage/storage/store`,
            value: images
          });
          return true;
        } else {
          return false;
        }
      }
    } catch (error) {
      console.log("uploadImage upload ERROR:", error); //eslint-disable-line
      dispatch(
        "ui/emitError",
        {
          error: error.message,
          meta: "uploadImage",
          code: error.code
        },
        {
          root: true
        }
      );
    }
  },
  async saveImageToKVS(
    { commit, dispatch, state, getters, rootGetters },
    payload
  ) {
    try {
      const PK_Account = rootGetters["auth/getAccountId"];
      const { Identity, IdentitySignature } = rootGetters["auth/getAuthState"];
      const token = {
        Identity,
        IdentitySignature,
        baseUrl: rootGetters["auth/getUserAvatarHost"]
      };
      const { key: Key = "backgroundImages", images: Value } = payload;
      const response = await storageService.saveToKVS(token, {
        PK_Account,
        Key,
        Value
      });
      return response;
    } catch (error) {
      dispatch(
        "ui/emitError",
        {
          error: error.message,
          meta: "saveImageToKVS",
          code: error.code
        },
        {
          root: true
        }
      );
    }
  },
  async uploadIcon({ commit, state, dispatch, getters, rootGetters }, payload) {
    try {
      console.log("###-----> Action uploadCustomIcon:", payload); //eslint-disable-line
      const PK_Account = rootGetters["auth/getAccountId"];
      const PK_User = rootGetters["auth/getPK_User"];
      const { Identity, IdentitySignature } = rootGetters["auth/getAuthState"];
      const token = {
        Identity,
        IdentitySignature
      };
      const options = {
        account: {
          PK_Account,
          PK_User
        },
        fileName: "backgroundImage.jpg",
        file: payload
      };
      let response = null;
      if (VUE_APP_MODE === "web") {
        response = await storageService.uploadWeb(token, options);
      } else {
        response = await storageService.upload(token, options);
      }
      // sample url: https://vera-us-oem-storage25.mios.com/storage/storage/store/872334865/thumbnail?Key=3722163767
      console.log("Send Successfull to File Storage Server"); //eslint-disable-line
      console.dir(response); // eslint-disable-line
      const { PK_Store, Key, responseCode } = response;
      if (response) {
        if (
          (responseCode && [201, -1].includes(responseCode)) ||
          (VUE_APP_MODE === "web" && PK_Store)
        ) {
          const storeUrl = `https://${apiConfig.storageBaseUrl}/storage/storage/store`;
          const src = `${storeUrl}/${PK_Store}/archive?Key=${Key}`;
          await dispatch("saveCustomIcons", src);
          return true;
        } else {
          return false;
        }
      }
    } catch (error) {
      console.log("uploadImage upload ERROR:", error); //eslint-disable-line
      dispatch(
        "ui/emitError",
        {
          error: error.message,
          meta: "uploadImage",
          code: error.code
        },
        {
          root: true
        }
      );
    }
  },
  async deleteCustomIcon({ commit, dispatch, getters }, payload) {
    try {
      dispatch("ui/startFetching", "deleteIcon", { root: true });
      await commit(DELETE_ICON, payload);
      const { response } = await dispatch(
        "saveCustomIcons",
        getters.getCustomIcons
      );
      return response;
    } catch (error) {
      dispatch(
        "ui/emitError",
        {
          error: error.message,
          meta: "deleteCustomIcon",
          code: error.code
        },
        {
          root: true
        }
      );
    } finally {
      dispatch("ui/stopFetching", "deleteIcon", { root: true });
    }
  },
  async saveCustomIcons(context, payload) {
    const { commit, dispatch, state, getters, rootGetters } = context;
    try {
      dispatch("ui/startFetching", "saveCustomIconUrl", { root: true });
      let icons = payload;

      if (!Array.isArray(icons)) {
        const customIcons = getters.getCustomIcons;
        icons = [...customIcons, icons];
      }

      const token = rootGetters["auth/getKVSToken"](false);
      const PK_Account = rootGetters["auth/getAccountId"];
      const { url, baseUrl } = await storageService.saveToKVS(token, {
        PK_Account,
        Key: "customIcons",
        Value: icons
      });

      return {
        PK_Account,
        baseUrl,
        url,
        icons: payload
      };
    } catch (error) {
      dispatch(
        "ui/emitError",
        {
          error: error.message,
          meta: "saveCustomIcons",
          code: error.code
        },
        {
          root: true
        }
      );
    } finally {
      dispatch("ui/stopFetching", "saveCustomIconUrl", { root: true });
    }
  },
  setTutorialApproved({ commit }, payload) {
    commit(SET_TUTORIAL_APPROVED, payload);
  },
  setDashboardTheme({ commit }, payload) {
    commit(SET_DASHBOARD_THEME, payload);
  }
};
