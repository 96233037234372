import getters from "../auth/getters";

export default {
  getItemsByDeviceId: state => deviceId =>
    state.items.filter(item => item.deviceId === deviceId),
  getItems: state => state.items,
  getEnergyMonitoringEligableDevices: (
    state,
    getters,
    rootState,
    rootGetters
  ) => (name = "electric_meter_watt") => {
    let addedDevicIds = [];
    const items = state.items.filter(item => item.name === name);
    let devices = [];
    if (items) {
      items.forEach(item => {
        const device = rootGetters["devices/getDeviceById"](item.deviceId);
        if (device && !addedDevicIds.includes(item.deviceId)) {
          addedDevicIds.push(item.deviceId);
          devices.push(device);
        }
      });
    }
    return devices;
  },
  getItemChanges: state => ({ name = "electric_meter_watt" }) => {
    return state.lastUpdatedItem.name === name && state.lastUpdatedItem;
  }
};
