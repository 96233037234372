<template>
  <MainPage>
    <ConfirmationDialog
      :icon="icon"
      :text1="translations.title"
      :text2="translations.description"
      :btnTextAccept="translations.btnTextAccept"
      :btnTextCancel="translations.btnTextCancel"
      :warning="warning"
      :size="327"
      @accept="accept(true)"
      @cancel="goBack(false)"
    />
  </MainPage>
</template>
<script>
export default {
  name: "Tutorial",
  props: {
    icon: {
      type: String,
      default: "icons/Tutorial/Anim_Tips-Tap0.svg"
    },
    title: {
      type: String
    },
    description: {
      type: String
    },
    btnTextAccept: {
      type: String,
      default: "Accept"
    },
    btnTextCancel: {
      type: String,
      default: ""
    },
    warning: {
      type: Boolean,
      default: false
    },
    resultActionName: {
      type: String,
      default: "tutorialApproved"
    },
    callbackApproved: {
      type: Function
    }
  },
  computed: {
    translations() {
      return {
        title: this.$t(this.title),
        description: this.$t(this.description),
        btnTextAccept: this.$t(this.btnTextAccept),
        btnTextCancel: this.$t(this.btnTextCancel)
      };
    }
  },
  methods: {
    goBack(event) {
      this.navigateBack();
    },
    accept(event) {
      this.$root.$emit("_tutorialApproved", {
        resultActionName: this.resultActionName,
        result: event
      });
      this.callbackApproved &&
        this.callbackApproved({
          resultActionName: this.resultActionName,
          result: event
        });
      this.goBack();
    }
  }
};
</script>
