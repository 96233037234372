export default {
  methods: {
    navigateTo(component, options, cb) {
      /*eslint-disable */
      console.info(`>>>>>>>> Navigating to: ${component}`);
      console.time(">>>>>>>> Navigation completed in");
      /* eslint-enable */
      this.$root.$emit("navigateTo", {
        component,
        options,
        cb
      });
    },
    navigateBack(options, backstackEntry) {
      this.$root.$emit("navigateBack", {
        options,
        backstackEntry
      });
    }
  }
};
