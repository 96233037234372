<template>
  <IconTwoActions
    iconPath="icons/Visuals/ic_warning_logout_200.svg"
    type="warning2"
    :loading="isLoading"
    :text1="translations.text1"
    :text2="translations.text2"
    :btnTextAccept="translations.btnTextAccept"
    :btnTextCancel="translations.btnTextCancel"
    @accept="handleAccept"
    @cancel="handleCancel"
  />
</template>

<script>
export default {
  name: "LogoutConfirm",
  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    translations() {
      return {
        text1: this.$t("core.logoutConfirm.text1"), // eslint-disable-line
        text2: this.$t("core.logoutConfirm.text2"), // eslint-disable-line
        btnTextAccept: this.$t("core.logoutConfirm.btnTextAccept"), // eslint-disable-line
        btnTextCancel: this.$t("core.logoutConfirm.btnTextCancel") // eslint-disable-line
      };
    }
  },
  methods: {
    handleAccept() {
      this.$emit("accept");
    },
    handleCancel() {
      this.$emit("close");
    }
  }
};
</script>
