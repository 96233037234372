import * as nmaService from "@/services/nma.service";
import cloudService from "@/services/cloud.service";

const cb = {};
let cbCloud = null;
export default {
  onMessage({ dispatch }, payload) {
    const data = payload.result;
    const [hub, module, action, method] = payload.msg_subclass.split(".");

    // if (hub === "EzloStateChanged") {
    //   dispatch("hub/updateHub", { action, data }, { root: true });
    //   return;
    // }

    // eslint-disable-next-line
    console.log(
      "#### UI broadcast message #####",
      payload,
      hub,
      module,
      action
    );
    switch (module) {
      case "device":
        if (data.parentDeviceId === "" && data && data._id) {
          dispatch(
            "zwave/setAddedDeviceName",
            { name: data.name, id: data._id, type: data.category },
            { root: true }
          );
        }
        dispatch("devices/updateDevices", { action, data }, { root: true });
        break;
      case "item":
        dispatch("items/updateItems", { action, data }, { root: true });
        const { valueType } = data;
        // https://log.ezlo.com/new/devices/items/ power is the value of the current consumption
        if (valueType === "power") {
          dispatch("energy/addLiveData", { data }, { root: true });
        }
        break;
      case "room":
        dispatch("rooms/updateRooms", { action, data }, { root: true });
        break;
      case "modes":
        dispatch("modes/updateMode", { action, method, data }, { root: true });
        break;
      case "favorite":
        dispatch("favorites/updateFavorite", { action, data }, { root: true });
        break;
      case "extensions":
        dispatch("zwave/setStatus", payload.result.event, { root: true });
        console.log("ZWAVE :", payload.result.event); //eslint-disable-line
        break;
      case "scene":
        dispatch("scenes/updateScene", payload.result, { root: true });
        break;
      case "network":
      case "software":
      case "firmware":
      case "backup":
      case "log":
      case "user":
      case "gateway":
        console.log(`Event Module "${module}" not implemented yet!`); //eslint-disable-line
        break;
      default:
        console.log("Unknown action!", module, action); //eslint-disable-line
        break;
    }
  },
  onMessageCloud({ dispatch }, payload) {
    const data = payload.result;
    // const [hub, module, action, method] = payload.msg_subclass.split(".");
  },
  subscribe({ dispatch }, payload) {
    const onMessage = function(data) {
      dispatch("onMessage", data);
    };
    cb[payload] = onMessage;
    nmaService.subscribe("ui_broadcast", onMessage, payload);
  },
  unsubscribe(context, payload) {
    nmaService.unsubscribe("ui_broadcast", cb[payload], payload);
  },
  subscribeToCloud({ dispatch }) {
    const onMessage = function(data) {
      dispatch("onMessageCloud", data);
    };
    cbCloud = onMessage;
    cloudService.subscribe("ui_broadcast", onMessage);
  },
  unsubscribeFromCloud(context, payload) {
    cloudService.unsubscribe("ui_broadcast", cbCloud);
  }
};
