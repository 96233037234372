<template>
  <MainPage>
    <StackLayout>
      <IBtnHiBtn
        icon1Src="icons/Controls/ic_controls_arrow-left_56.svg"
        :text="capabilityName"
        @iconTap1="handleBack"
      />
    </StackLayout>

    <StackLayout>
      <Separator size="xl" />
      <IconHTxtiBtn
        :icon1Src="abstractDevices.src"
        alignText="left"
        alignUnderline="left"
        headerSize="h3"
        showUnderline
        :text="abstractDevices.name"
        :underlineText="abstractDevices.eMail"
      />
      <Separator size="xl" />
    </StackLayout>

    <GridLayout rows="*, auto" columns="*" class="set-dimmer">
      <ScrollView row="0" col="0" class="set-dimmer__scroll" height="100%">
        <StackLayout height="100%">
          <HTextUnderlineTextSwitch
            alignText="left"
            alignUnderline="left"
            headerSize="h3"
            :colorPrimary="onActiveColor"
            :text="translations.configureCustomDimmer"
            :value="changes.configureCustomDimmer"
            @change="changeConfigureCustomDimmer"
          />
          <Separator size="xl" />
          <Label
            class="font-h2-24 set-dimmer__scroll__label"
            :text="translations.commands"
          />
          <VxpLabel
            class="font-txt-16-par set-dimmer__scroll__label-desc"
            textWrap
            :text="getDescription"
          />
          <Separator size="xl" />
          <GridLayout
            v-if="changes.configureCustomDimmer"
            rows="auto, auto"
            columns="*"
            height="100%"
          >
            <StackLayout row="0" col="0">
              <StackLayout
                v-for="command in getCommands"
                :key="command.id"
                @tap="onTapCommand(command)"
              >
                <BadgeHTxtBtn
                  :badgeText="command.id"
                  :text="command.name"
                  src="icons/Controls/ic_controls_set_56.svg"
                />
                <Separator size="xl" />
              </StackLayout>
            </StackLayout>
            <IconH
              row="1"
              col="0"
              class="nvd__more-capabilities"
              src="icons/Controls/ic_controls_plus_warning_56.svg"
              :text="translations.addSetPoint"
              @tap="addSetPoint"
            />
          </GridLayout>
          <IconHTxtiBtn
            v-else
            icon2Src="icons/Controls/ic_controls_edit_56.svg"
            alignText="left"
            alignUnderline="left"
            headerSize="h3"
            :showUnderline="true"
            :disabled="true"
            :text="translations.defaultDimmerScale"
            :underlineText="defaultDimmerScaleDescription"
            @tap="onTapDefaultDimmerScale"
          />

          <Separator size="xl" />
        </StackLayout>
      </ScrollView>

      <StackLayout row="1" col="0" class="set-dimmer__btn-container">
        <ButtonFilled
          :disabled="isDisabled"
          :text="translations.done"
          @tap="handleBack"
        />
      </StackLayout>
    </GridLayout>
  </MainPage>
</template>
<script>
import Vue from "vue";
import { locals } from "@/helpers/variables";

export default {
  name: "SetDimmerPage",
  props: {
    configureCustomDimmer: {
      type: Boolean,
      default: false
    },
    min: {
      type: [Number, String],
      default: "0"
    },
    max: {
      type: [Number, String],
      default: "100"
    }
  },
  data() {
    return {
      abstractDevices: {
        id: "1",
        name: "Light in living room",
        eMail: "user@gmail.com",
        src: "icons/Smarthomes/ic_smarthomes_amazon_alexa.svg"
      },
      changes: {
        configureCustomDimmer: this.configureCustomDimmer,
        min: this.min,
        max: this.max
      },
      commands: [
        {
          id: "1",
          name: "Set to warm color"
        },
        {
          id: "2",
          name: "Set to cool color"
        }
      ]
    };
  },
  created() {
    this.$root.$on("_changeDefaultDimmerScale", this.changeDefaultDimmerScale);
    this.$root.$on("_changeCommand", this.changeCommand);
  },
  beforeDestroy() {
    this.changeDefaultDimmerScale &&
      this.$root.$off("_changeDefaultDimmerScale");
    this.$root.$off(["_defaultDimmerScale", "navigateBack"]);
  },
  computed: {
    onActiveColor() {
      return locals.colorPrimary;
    },
    translations() {
      // eslint-disable-next-line
      return this.$t("virtualDevices.setDimmer");
    },
    defaultDimmerScaleDescription() {
      // eslint-disable-next-line
      return this.$t("virtualDevices.setDimmer.defaultDimmerScaleDescription", { min: this.changes.min, max: this.changes.max });
    },
    capabilityName() {
      return this.translations.title;
    },
    isDisabled() {
      return this.configureCustomDimmer === this.changes.configureCustomDimmer;
    },
    getDescription() {
      return this.changes.configureCustomDimmer
        ? this.translations.customCommandsDescription
        : this.translations.commandsDescription;
    },
    getCommands() {
      return this.commands;
    }
  },
  methods: {
    handleBack() {
      this.navigateBack();
    },
    changeConfigureCustomDimmer(value) {
      console.log("handleConfigureCustomDimmer:", value); //eslint-disable-line
      Vue.set(this.changes, "configureCustomDimmer", value);
    },
    onTapDefaultDimmerScale() {
      console.log("onTapDefaultDimmerScale:"); //eslint-disable-line
      this.navigateTo("DefaultDimmerScalePage", {
        props: {
          min: this.changes.min,
          max: this.changes.max
        }
      });
    },
    changeDefaultDimmerScale(scale) {
      console.log("changeDefaultDimmerScale", scale); //eslint-disable-line
      Vue.set(this.changes, "min", scale.min);
      Vue.set(this.changes, "max", scale.max);
    },
    changeCommand(command) {
      console.log("changeCommand", command); //eslint-disable-line
      if (command && command.id) {
        console.log("editCommand"); //eslint-disable-line
        let item = this.commands.find(item => item.id === command.id);
        Vue.set(item, "name", command.name);
      } else {
        console.log("addCommand"); //eslint-disable-line
        // TODO
        this.commands.push({
          ...command,
          id: this.commands.length + 1
        });
      }
    },
    addSetPoint() {
      console.log("addSetPoint"); //eslint-disable-line
      this.navigateTo("AddSetPoint", {
        props: {
          devices: this.abstractDevices,
          isEditMode: false,
          command: {}
        }
      });
    },
    onTapCommand(command) {
      console.log("onTapCommand", command); //eslint-disable-line
      this.navigateTo("AddSetPoint", {
        props: {
          devices: this.abstractDevices,
          isEditMode: true,
          command
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~/assets/styles/variables.scss";

.set-dimmer {
  height: 100%;
  &__scroll {
    height: 100%;
    &__label {
      padding: $xl $xl 0 $xl;
    }
    &__label-desc {
      padding: $xl $xl $l $xl;
    }
  }
  &__btn-container {
    padding: 0 $xl $xxl $xl;
  }
}
</style>
