<template>
  <MainPage>
    <StackLayout>
      <IBtnHiBtn
        icon1Src="icons/Controls/ic_controls_arrow-left_56.svg"
        :text="capabilityName"
        @iconTap1="handleBack"
      />
    </StackLayout>

    <GridLayout rows="*, auto, auto" columns="*" class="dimmer-scale">
      <ScrollView row="0" col="0" class="dimmer-scale__scroll">
        <GridLayout rows="auto, auto" columns="*">
          <StackLayout row="0" col="0" height="100%">
            <Separator size="xl" />
            <Label
              class="font-h2-24 dimmer-scale__scroll__label"
              :text="translations.scaleValues"
            />
            <VxpLabel
              class="font-txt-16-btn dimmer-scale__scroll__label"
              textWrap
              :text="translations.scaleValuesDescription"
            />
            <Separator size="xl" />
          </StackLayout>
          <GridLayout
            class="dimmer-scale__scroll__input"
            row="1"
            col="0"
            rows="auto, auto"
            columns="56, 68, *"
          >
            <Label
              row="0"
              col="0"
              class="font-txt-16-par"
              :text="translations.min"
              textWrap
            />
            <TextInput
              row="0"
              col="1"
              class="dimmer-scale__min"
              v-model="$v.minScale.$model"
              type="number"
              showSoftInput
              returnKeyType="done"
            />
            <Label
              row="1"
              col="0"
              class="font-txt-16-par"
              :text="translations.max"
              textWrap
            />
            <TextInput
              row="1"
              col="1"
              class="dimmer-scale__max"
              v-model="$v.maxScale.$model"
              type="number"
              showSoftInput
              returnKeyType="done"
            />
          </GridLayout>
        </GridLayout>
      </ScrollView>

      <StackLayout row="1" col="0" class="dimmer-scale__btn-container">
        <ButtonFilled
          :disabled="isDisabled"
          :text="translations.done"
          @tap="handleDone"
        />
      </StackLayout>
    </GridLayout>
  </MainPage>
</template>
<script>
import Vue from "vue";
import {
  required,
  minValue,
  maxValue,
  numeric
} from "vuelidate/lib/validators";
import { locals } from "@/helpers/variables";

export default {
  name: "DefaultDimmerScalePage",
  props: {
    min: {
      type: [Number, String],
      default: "0"
    },
    max: {
      type: [Number, String],
      default: "100"
    }
  },
  data() {
    return {
      minScale: String(this.min),
      maxScale: String(this.max),
      abstractDevices: {
        name: "Light in living room",
        eMail: "user@gmail.com",
        src: "icons/Smarthomes/ic_smarthomes_amazon_alexa.svg"
      }
    };
  },
  computed: {
    onActiveColor() {
      return locals.colorPrimary;
    },
    translations() {
      // eslint-disable-next-line
      return this.$t("virtualDevices.defaultDimmerScale");
    },
    capabilityName() {
      return this.translations.title;
    },
    isDisabled() {
      return (
        this.$v.$invalid ||
        Number(this.minScale) > Number(this.maxScale) ||
        !this.$v.$anyDirty
      );
    }
  },
  methods: {
    handleBack() {
      this.navigateBack();
    },
    handleDone() {
      this.$root.$emit("_changeDefaultDimmerScale", {
        min: Number(this.minScale),
        max: Number(this.maxScale)
      });
      this.handleBack();
    }
  },
  validations() {
    return {
      minScale: {
        required,
        minValue: 0
      },
      maxScale: {
        required,
        maxValue: 100
      }
    };
  }
};
</script>
<style lang="scss" scoped>
@import "~/assets/styles/variables.scss";

.dimmer-scale {
  height: 100%;
  &__scroll {
    height: 100%;
    &__label {
      padding: $xl $xl 0 $xl;
    }
    &__input {
      padding: $xl $xl 0 $xl;
    }
  }
  &__btn-container {
    padding: 0 $xl $xxl $xl;
  }
  &__min {
    grid-row: 1;
  }
  &__max {
    grid-row: 2;
  }
}
</style>
