<template>
  <StackLayout class="pwd-checks">
    <StackLayout class="pwd-checks-title-cont">
      <VxpLabel :text="title" class="pwd-checks-title font-h3-20" />
    </StackLayout>

    <StackLayout horizontalAlignment="left">
      <StackLayout
        orientation="horizontal"
        verticalAlignment="center"
        class="pwd-checks-item"
        :class="{ 'pwd-checks-item--error': isUppercase }"
      >
        <SvgIcon :size="16" class="pwd-checks-icon" :src="icon(isUppercase)" />
        <VxpLabel
          class="pwd-checks-text font-txt-13"
          :text="uppercaseLetterReq"
        />
      </StackLayout>
      <StackLayout
        orientation="horizontal"
        verticalAlignment="center"
        class="pwd-checks-item"
        :class="{ 'pwd-checks-item--error': isLowercase }"
      >
        <SvgIcon :size="16" class="pwd-checks-icon" :src="icon(isLowercase)" />
        <VxpLabel
          class="pwd-checks-text font-txt-13"
          :text="lowercaseLetterReq"
        />
      </StackLayout>
      <StackLayout
        orientation="horizontal"
        verticalAlignment="center"
        class="pwd-checks-item"
        :class="{ 'pwd-checks-item--error': isOneNumber }"
      >
        <SvgIcon :size="16" class="pwd-checks-icon" :src="icon(isOneNumber)" />
        <VxpLabel class="pwd-checks-text font-txt-13" :text="numberReq" />
      </StackLayout>
      <StackLayout
        orientation="horizontal"
        verticalAlignment="center"
        class="pwd-checks-item"
        :class="{ 'pwd-checks-item--error': isCharacters }"
      >
        <SvgIcon :size="16" class="pwd-checks-icon" :src="icon(isCharacters)" />
        <VxpLabel class="pwd-checks-text font-txt-13" :text="charactersReq" />
      </StackLayout>
    </StackLayout>
  </StackLayout>
</template>
<script>
export default {
  name: "PasswordChecks",
  props: {
    title: {
      type: String,
      default: "Password requirements"
    },
    uppercaseLetterReq: {
      type: String,
      default: "Uppercase letter"
    },
    lowercaseLetterReq: {
      type: String,
      default: "Lowercase letter"
    },
    numberReq: {
      type: String,
      default: "One number"
    },
    charactersReq: {
      type: String,
      default: "8-16 characters"
    },
    uppercaseError: {
      type: Boolean,
      required: false,
      default: false
    },
    lowercaseError: {
      type: Boolean,
      required: false,
      default: false
    },
    oneNumberError: {
      type: Boolean,
      required: false,
      default: false
    },
    charactersError: {
      type: Boolean,
      required: false,
      default: false
    },
    src: {
      type: String,
      required: false,
      default: "icons/Controls/ic_controls_check_grey_16.svg"
    },
    srcValid: {
      type: String,
      required: false,
      default: "icons/Controls/ic_controls_check_blue_16.svg"
    }
  },
  computed: {
    isUppercase() {
      return this.uppercaseError;
    },
    isLowercase() {
      return this.lowercaseError;
    },
    isOneNumber() {
      return this.oneNumberError;
    },
    isCharacters() {
      return this.charactersError;
    }
  },
  methods: {
    icon(valid) {
      return valid ? this.srcValid : this.src;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~/assets/styles/variables.scss";
.pwd-checks-title-cont {
  margin-bottom: $m;
}
.pwd-checks-title {
  color: $color-grey;
}
.pwd-checks-item {
  margin-top: $s;
  margin-bottom: $s;
  color: $color-grey;
  &--error {
    .pwd-checks-text {
      color: $color-primary;
    }
  }
}
.pwd-checks-text {
  margin-left: $sm;
}
</style>
