<template>
  <MainPage>
    <GridLayout height="100%" rows="auto,*" columns="*">
      <StackLayout row="0" col="0">
        <IBtnHiBtn
          icon1Src="icons/Controls/ic_controls_arrow-left_56.svg"
          @iconTap1="navigateBack"
          :text="translations.textTitle"
        />
      </StackLayout>
      <ScrollView row="1" col="0" height="100%" iosOverflowSafeArea="false">
        <StackLayout row="1" col="0">
          <TileTypesList
            :items="tileTypes"
            @selectTileType="handleSelectTileType"
          />
        </StackLayout>
      </ScrollView>
    </GridLayout>
  </MainPage>
</template>

<script>
import TileTypesList from "@/components/dashboard/TileTypesList";
import { createNamespacedHelpers } from "vuex";
const { mapGetters: mapAuthGetters } = createNamespacedHelpers("auth");
const {
  mapGetters: mapTemplateGetters,
  mapActions: mapTemplateActions
} = createNamespacedHelpers("template");

export default {
  name: "SelectMenuItems",
  components: {
    TileTypesList
  },
  props: {
    index: {
      type: [Number, String],
      required: true
    },
    isLandscape: {
      type: Boolean,
      default: false
    },
    dashboardId: {
      type: String,
      default: ""
    }
  },
  data() {
    const icon2 = "icons/Controls/ic_controls_arrow-right_56.svg";
    return {
      menuItems: [
        {
          text: "core.selectMenuItemsDialog.textDashboard",
          type: "dashboard",
          icon2
        },
        {
          text: "core.selectMenuItemsDialog.textDevices",
          type: "devices",
          icon2
        },
        {
          text: "core.selectMenuItemsDialog.textCameras",
          type: "cameras",
          icon2
        },
        {
          text: "core.selectMenuItemsDialog.textScenes",
          type: "scenes",
          icon2
        },
        {
          text: "core.selectMenuItemsDialog.textRooms",
          type: "rooms",
          icon2
        },
        {
          text: "core.selectMenuItemsDialog.textEzloVOI",
          type: "ezloVOI",
          icon2
        },
        {
          text: "core.selectMenuItemsDialog.textAlerts",
          type: "alerts",
          icon2
        },
        {
          text: "core.selectMenuItemsDialog.textGeofencing",
          type: "geofencing",
          icon2
        },
        {
          text: "core.selectMenuItemsDialog.textUsers",
          type: "users",
          icon2
        },
        {
          text: "core.selectMenuItemsDialog.textSettings",
          type: "settings",
          icon2
        },
        {
          text: "core.selectMenuItemsDialog.textMyControllers",
          type: "myControllers",
          icon2
        }
      ],
      no_hub: [
        "dashboard",
        "scenes",
        "ezloVOI",
        "users",
        "settings",
        "myControllers"
      ],
      ezlo: [
        "dashboard",
        "devices",
        "scenes",
        "rooms",
        "ezloVOI",
        "alerts",
        "users",
        "settings",
        "myControllers"
      ],
      vera: [
        "dashboard",
        "devices",
        "cameras",
        "scenes",
        "rooms",
        "alerts",
        "geofencing",
        "users",
        "settings",
        "myControllers"
      ]
    };
  },
  computed: {
    ...mapAuthGetters(["getControllerType"]),
    ...mapTemplateGetters(["getUsedLinkItemsInSelectedTeplate"]),
    translations() {
      return this.$t("core.selectMenuItemsDialog"); // eslint-disable-line
    },
    tileTypes() {
      const usedItems = this.getUsedLinkItemsInSelectedTeplate({
        orientation: this.orientation,
        type: "link"
      });
      return this.menuItems.filter(
        item =>
          !usedItems.includes(item.type) &&
          this[this.getControllerType].includes(item.type)
      );
    },
    orientation() {
      return this.isLandscape ? "landscape" : "portrait";
    }
  },
  methods: {
    ...mapTemplateActions(["setTile"]),
    handleSelectTileType(tileType) {
      let item = { ...this.menuItems.find(item => item.type === tileType) };
      if (item) {
        item = {
          ...item,
          title: this.$t(item.text),
          subType: item.type,
          type: "link"
        };

        this.setTile({
          index: this.index,
          dashboardId: this.dashboardId,
          type: "link",
          item,
          orientation: this.orientation
        });
        this.navigateBack();
      }
    }
  }
};
</script>
