<template>
  <MainPage ref="mainPage" @confirmationResult="confirmationResult">
    <GridLayout rows="56,*,">
      <StackLayout row="0" col="0" class="theme__navigation" width="100%">
        <NavigationHeader
          row="0"
          icon1Src="icons/Controls/ic_controls_arrow-left_56.svg"
          :text="translations.headerTitle"
          :textBtnDone="editMode ? translations.done : translations.edit"
          :showTextButton="hasCustomTheme"
          textSize="h3"
          @iconTap1="goBack"
          @iconTap2="editMode = !editMode"
        />
      </StackLayout>
      <ScrollView row="1" col="0" height="100%" iosOverflowSafeArea="false">
        <StackLayout>
          <ThemeList
            :items="themesItems"
            :editMode="editMode"
            :selectedItem="getSelectedTheme"
            idKey="uuid"
            deleteButton
            @onSelect="onSelect"
            @onDelete="onDeleteTheme"
          />
          <IconHTxtiBtn
            class="theme__add-button"
            icon1Src="icons/Controls/ic_controls_plus_primary_56.svg"
            alignText="left"
            headerSize="h3"
            :text="translations.createNewTheme"
            @tap="createNewTheme"
          />
          <Separator size="xl" />
        </StackLayout>
      </ScrollView>
    </GridLayout>
  </MainPage>
</template>
<script>
import { cloneDeep, merge } from "lodash";
import ThemeList from "@/pages/theme/ThemeList";
import { createNamespacedHelpers } from "vuex";
const { mapActions: mapTemplateActions } = createNamespacedHelpers("template");
const {
  mapGetters: mapThemesGetters,
  mapActions: mapThemesActions
} = createNamespacedHelpers("themes");
const {
  mapActions: mapDashboardActions,
  mapGetters: mapDashboardGetters
} = createNamespacedHelpers("dashboards");
const { mapActions: mapUiActions } = createNamespacedHelpers("ui");

export default {
  name: "ThemeSettings",
  components: {
    ThemeList
  },
  props: {
    dashboardId: {
      type: String,
      default: ""
    },
    isLandscape: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      editMode: false,
      themeToBeDeleted: null,
      initialTheme: null
    };
  },
  created() {
    this.setInitialTheme();
  },
  watch: {
    getSelectedTheme(newValue, oldValue) {
      if (newValue !== oldValue && this.editMode) {
        this.editMode = false;
      }
    },
    dashboardId(newValue) {
      this.setInitialTheme();
    },
    orientation(newValue) {
      this.setInitialTheme();
    }
  },
  computed: {
    ...mapDashboardGetters(["getDashboardSettingsChanged"]),
    ...mapThemesGetters([
      "getThemes",
      "getCustomThemes",
      "getDefaultCustomTheme",
      "getSelectedTheme"
    ]),
    translations() {
      return this.$t("themeSettings"); //eslint-disable-line
    },
    themesItems() {
      return this.getThemes.map(theme => {
        const options = {
          ...theme.data,
          name: this.$t(theme.name),
          uuid: theme.uuid
        };
        return options;
      });
    },
    hasCustomTheme() {
      return this.getCustomThemes && this.getCustomThemes.length > 0;
    },
    orientation() {
      return this.isLandscape ? "landscape" : "portrait";
    }
  },
  methods: {
    ...mapUiActions(["emitError"]),
    ...mapThemesActions(["deleteTheme", "removeTheme", "selectTheme"]),
    ...mapDashboardActions(["setDashboardTheme"]),
    ...mapTemplateActions(["setTemplateTheme"]),
    goBack() {
      this.navigateBack();
      if (
        !this.initialTheme ||
        !this.initialTheme.theme ||
        !this.initialTheme.theme[this.orientation] ||
        this.initialTheme.theme[this.orientation].theme_id !==
          this.getSelectedTheme.uuid
      ) {
        this.setTheme();
      }
    },
    async onSelect(item) {
      if (this.editMode) {
        this.editTheme(item);
      } else {
        await this.selectTheme(item);
      }
    },
    async setInitialTheme() {
      const st = this.getDashboardSettingsChanged(this.dashboardId);
      this.initialTheme = cloneDeep(st);
      await this.selectTheme({
        uuid: (st && st.theme && st.theme[this.orientation].theme_id) || ""
      });
    },
    setTheme() {
      if (this.initialTheme && this.initialTheme.theme) {
        this.initialTheme.theme[
          this.orientation
        ].theme_id = this.getSelectedTheme.uuid;
      }
      this.setDashboardTheme({
        dashboardId: this.dashboardId,
        theme: this.getSelectedTheme,
        orientation: this.orientation,
        applyForAllOrientations: true
      });

      this.setTemplateTheme(this.getSelectedTheme);
    },
    createNewTheme() {
      console.log("### createNewTheme"); //eslint-disable-line
      this.navigateTo("EditTheme", {
        props: {
          editMode: false,
          options: this.getDefaultCustomTheme,
          state: "default",
          isLandscape: this.isLandscape
        }
      });
    },
    editTheme(options) {
      // eslint-disable-next-line
      console.log("#### ThemeSettings/editTheme:", {
        isLandscape: this.isLandscape,
        options
      });
      // If there are any new attributes, those new attributes are added to the options.
      this.navigateTo("EditTheme", {
        props: {
          editMode: true,
          options: merge(this.getDefaultCustomTheme, options), // deep merge
          isLandscape: this.isLandscape
        }
      });
    },
    onDeleteTheme(item) {
      console.log("### deleteTheme", item); //eslint-disable-line
      this.themeToBeDeleted = item;
      this.showConfirmationDialog({ type: "deleteTheme" });
    },
    showConfirmationDialog({ type = "deleteTheme" }) {
      this.emitError({
        type
      });
    },
    async confirmationResult(event) {
      console.log("### --> ThemeSettings confirmationResult:", event); //eslint-disable-line
      if (event.resultActionName === "deleteTheme" && event.result) {
        console.log("### --> delete action ThemeSettings confirmationResult:", event); //eslint-disable-line
        if (this.themeToBeDeleted) {
          const selectedThemeId =
            this.getSelectedTheme.uuid === this.themeToBeDeleted.uuid
              ? this.getThemes[0]
              : this.getSelectedTheme.uuid;

          await this.deleteTheme(this.themeToBeDeleted.uuid);
          await this.selectTheme({ uuid: selectedThemeId });
          this.themeToBeDeleted = null;
        }
        this.editMode = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~/assets/styles/variables.scss";

.theme {
  &__navigation {
    padding: 0 $margin-xl 0 $margin-s;
  }
  &__header {
    margin: $margin-l $margin-xl;
  }
  &__add-button {
    /deep/ * {
      color: $color-primary !important;
    }
  }
}
</style>
