const apiConfig = Object.freeze({
  baseUrl: process.env.VUE_APP_BASE_URL,
  storageBaseUrl: process.env.VUE_APP_STORAGE_BASE_URL,
  storagePort: process.env.VUE_APP_STORAGE_PORT,
  salt: process.env.VUE_APP_SALT,
  pkOem: process.env.VUE_APP_PK_OEM,
  tokenVersion: process.env.VUE_APP_TOKEN_VERSION,
  cloudBaseUrl: process.env.VUE_APP_CLOUD_BASE_URL,
  cloudApiUrl: process.env.VUE_APP_CLOUD_API_URL,
  cloudApiVersion: process.env.VUE_APP_CLOUD_API_VERSION,
  cloudApiFullUrl: () =>
    `${apiConfig.cloudApiUrl}/${apiConfig.cloudApiVersion}`,
  cloudXAppToken: process.env.VUE_APP_CLOUD_X_API_TOKEN
});

export default apiConfig;
