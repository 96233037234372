<template>
  <Page actionBarHidden="true">
    <StackLayout
      height="100%"
      class="spinner"
      isPassThroughParentEnabled="false"
      @tap="() => {}"
    >
      <Spinner height="100%" />
    </StackLayout>
  </Page>
</template>
<script>
import { createNamespacedHelpers } from "vuex";
const {
  mapActions: mapAuthActions,
  mapGetters: mapAuthGetters
} = createNamespacedHelpers("auth");
const { mapActions: mapEnergyActions } = createNamespacedHelpers("energy");
const { mapGetters: mapUiGetters } = createNamespacedHelpers("ui");
export default {
  name: "LoginWithBounceTokenPage",
  async mounted() {
    const token = this.$route && this.$route.query && this.$route.query.token;
    if (token) {
      await this.loginWithBounceToken(token);
      if (this.hasError) {
        this.navigateTo("SignUpIntro", { clearHistory: true });
        this.$destroy();
        return;
      }
      if (this.getPath === "energy") {
        // await this.getAllEnergyData();
        this.navigateTo("Energy");
        this.$destroy();
      } else if (this.getPath === "edit") {
        this.navigateTo("DashboardTemplate", {
          props: {
            dashboardId: this.getDashboardId,
            editMode: true
          }
        });
        this.$destroy();
      } else {
        this.navigateTo("DashboardList");
        this.$destroy();
      }
    } else {
      if (this.isAuthenticated) {
        this.setIsWebView(true);
        await this.fetchAllUserData();
        this.navigateTo("DashboardList");
        this.$destroy();
        return;
      } else {
        this.navigateTo("SignUpIntro", { clearHistory: true });
        this.$destroy();
        return;
      }
    }
  },
  computed: {
    ...mapUiGetters(["hasError"]),
    ...mapAuthGetters([
      "isAuthenticated",
      "tokenExpiration",
      "getPath",
      "getDashboardId"
    ])
  },
  methods: {
    ...mapAuthActions(["loginWithBounceToken", "setIsWebView"]),
    ...mapAuthActions(["fetchAllUserData", "refreshToken"]),
    ...mapEnergyActions(["getAllEnergyData"])
  }
};
</script>
<style lang="scss" scoped>
.spinner {
  justify-content: center;
}
</style>
