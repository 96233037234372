export const SET_VALUE_BY_KEY = "SET_VALUE_BY_KEY";
export const SET_CURRENT_GOAL = "SET_CURRENT_GOAL";
export const SELECT_DEVICE = "SELECT_DEVICE";
export const CLEAR_SELECTION = "CLEAR_SELECTION";
export const SET_ACTIVE_MODE = "SET_ACTIVE_MODE";
export const ADD_DEVICE_TO_FILTER = "ADD_DEVICE_TO_FILTER";
export const REMOVE_DEVICE_FROM_FILTER = "REMOVE_DEVICE_FROM_FILTER";
export const RESET_FILTER = "RESET_FILTER";
export const SET_GOALS = "SET_GOALS";
export const ADD_GOAL = "ADD_GOAL";
export const UPDATE_GOAL = "UPDATE_GOAL";
export const SET_ENERGY_DATA = "SET_ENERGY_DATA";
export const SET_MONITORING = "SET_MONITORING";
export const SET_SELECTED_TIME = "SET_SELECTED_TIME";
export const SET_DEVICES = "SET_DEVICES";
export const SET_LIVE_DATA = "SET_LIVE_DATA";
export const ADD_LIVE_DATA = "ADD_LIVE_DATA";
export const SET_INITIAL_CONSUMPTION_OBJECT = "SET_INITIAL_CONSUMPTION_OBJECT";
export const SET_PREVIOUS_CONSUMPTION = "SET_PREVIOUS_CONSUMPTION";
export const SET_AVERAGE_CONSUMPTION = "SET_AVERAGE_CONSUMPTION";
export const RESET_DEVICES = "RESET_DEVICES";
export const SET_CONNECTION = "SET_CONNECTION";
export const SET_LATEST_LIVE_DATA_VALUE = "SET_LATEST_LIVE_DATA_VALUE";
export const CLEAR_ENERGY_DATA = "CLEAR_ENERGY_DATA";
