export const SET_TEMPLATE = "SET_TEMPLATE";
export const SET_TILE = "SET_TILE";
export const SET_URL_TILE = "SET_URL_TILE";
export const SET_DASHBOARD_TILE = "SET_DASHBOARD_TILE";
export const SELECT_TEMPLATE = "SELECT_TEMPLATE";
export const CLEAN_SELECT_TEMPLATE = "CLEAN_SELECT_TEMPLATE";
export const DELETE_TILE = "DELETE_TILE";
export const CLEAN_TEMPLATE = "CLEAN_TEMPLATE";
export const SET_TEMPLATE_LANDSCAPE = "SET_TEMPLATE_LANDSCAPE";
export const CLEAN_TEMPLATE_LANDSCAPE = "CLEAN_TEMPLATE_LANDSCAPE";
export const CLEAN_TEMPLATE_PORTRAIT = "CLEAN_TEMPLATE_PORTRAIT";
export const SET_DESIGN_PROPERTIES = "SET_DESIGN_PROPERTIES";
export const DEFAULT_DESIGN_PROPERTIES = "DEFAULT_DESIGN_PROPERTIES";
export const RESET_DESIGN_PROPERTIES = "RESET_DESIGN_PROPERTIES";
export const SAVE_DESIGN_PROPERTIES = "SAVE_DESIGN_PROPERTIES";
export const SAVE_TO_TEMPLATE_BUFFER = "SAVE_TO_TEMPLATE_BUFFER";
export const CLEAN_TEMPLATE_BUFFER = "CLEAN_TEMPLATE_BUFFER";
export const SET_TEMPLATE_THEME = "SET_TEMPLATE_THEME";
