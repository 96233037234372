export const defaultDesignProperties = () => {
  return {
    font_family: "ezlo-sans",
    font_size: 16,
    general: {}
  };
};

export default function() {
  return {
    template: {},
    templateLandscape: {},
    designPropertiesChanged: null,
    templateBuffer: null,
    // TODO: these templates should be requested from api
    houseModes: [
      {
        name: "home",
        desc: "",
        icon: "icons/Modes/ic_modes_at-home_56.svg",
        subType: "home",
        disable: false
      },
      {
        name: "away",
        desc: "",
        icon: "icons/Modes/ic_modes_away_56.svg",
        subType: "away",
        disable: false
      },
      {
        name: "night",
        desc: "",
        icon: "icons/Modes/ic_modes_good-night_56.svg",
        subType: "night",
        disable: false
      },
      {
        name: "vacation",
        desc: "",
        icon: "icons/Modes/ic_modes_vacation_56.svg",
        subType: "vacation",
        disable: false
      },
      {
        name: "allModes",
        desc: "",
        icon: "icons/Modes/ic_modes_allmodes_56.svg",
        subType: "allModes",
        disable: false
      }
    ],
    panicTypes: [
      {
        name: "medical",
        title: "medicalTitle",
        desc: "",
        icon: "icons/Controls/ic_controls_arrow-right_56.svg",
        subType: "medical",
        subTypeIcon: "icons/Actions/ic_actions_protect-medical_56.svg",
        disable: false
      },
      {
        name: "police",
        title: "policeTitle",
        desc: "",
        icon: "icons/Controls/ic_controls_arrow-right_56.svg",
        subType: "police",
        subTypeIcon: "icons/Actions/ic_actions_protect-police_56.svg",
        disable: false
      },
      {
        name: "fire",
        title: "fireTitle",
        desc: "",
        icon: "icons/Controls/ic_controls_arrow-right_56.svg",
        subType: "fire",
        subTypeIcon: "icons/Actions/ic_actions_protect-firefighters_56.svg",
        disable: false
      }
    ],
    tileTypes: [
      {
        group: "core.tileTypesDialog.textGeneral",
        items: [
          {
            text: "core.tileTypesDialog.textDevice",
            icon: "icons/Tabbar/ic_tabbar_device-hub_56.svg",
            icon2: "icons/Controls/ic_controls_arrow-right_56.svg",
            type: "device"
          },
          {
            text: "core.tileTypesDialog.textScene",
            icon: "icons/Tabbar/ic_tabbar_scenes_56.svg",
            icon2: "icons/Controls/ic_controls_arrow-right_56.svg",
            type: "scene"
          },
          {
            text: "core.tileTypesDialog.textMode",
            icon: "icons/Modes/ic_modes_at-home_56.svg",
            icon2: "icons/Controls/ic_controls_arrow-right_56.svg",
            type: "mode"
          },
          {
            text: "core.tileTypesDialog.textLink",
            icon: "icons/Controls/ic_controls_pair_56.svg",
            icon2: "icons/Controls/ic_controls_arrow-right_56.svg",
            type: "link"
          },
          {
            text: "core.tileTypesDialog.textTimeAndDate",
            icon: "icons/Actions/ic_actions_calendar_56.svg",
            icon2: "icons/Controls/ic_controls_arrow-right_56.svg",
            type: "datetime"
          }
        ]
      },
      {
        group: "core.tileTypesDialog.textServices",
        items: [
          {
            text: "core.tileTypesDialog.textEzloProtect",
            icon: "icons/Actions/ic_actions_ezlo-protect_56.svg",
            icon2: "icons/Controls/ic_controls_arrow-right_56.svg",
            type: "panic",
            visible: "auth/hasEzloProtectSubscription" // check from auth store
          }
        ]
      }
    ],
    templates: {
      portrait: [
        {
          id: "0",
          columns: 3,
          rows: 5,
          layout: [
            { colStart: 0, colEnd: 2, rowStart: 0, rowEnd: 1 },
            { colStart: 0, colEnd: 0, rowStart: 2, rowEnd: 2 },
            { colStart: 1, colEnd: 1, rowStart: 2, rowEnd: 2 },
            { colStart: 2, colEnd: 2, rowStart: 2, rowEnd: 2 },
            { colStart: 0, colEnd: 0, rowStart: 3, rowEnd: 3 },
            { colStart: 1, colEnd: 1, rowStart: 3, rowEnd: 3 },
            { colStart: 2, colEnd: 2, rowStart: 3, rowEnd: 3 },
            { colStart: 0, colEnd: 0, rowStart: 4, rowEnd: 4 },
            { colStart: 1, colEnd: 1, rowStart: 4, rowEnd: 4 },
            { colStart: 2, colEnd: 2, rowStart: 4, rowEnd: 4 }
          ]
        },
        {
          id: "1",
          columns: 3,
          rows: 5,
          layout: [
            { colStart: 0, colEnd: 0, rowStart: 0, rowEnd: 0 },
            { colStart: 1, colEnd: 1, rowStart: 0, rowEnd: 0 },
            { colStart: 2, colEnd: 2, rowStart: 0, rowEnd: 0 },
            { colStart: 0, colEnd: 2, rowStart: 1, rowEnd: 2 },
            { colStart: 0, colEnd: 0, rowStart: 3, rowEnd: 3 },
            { colStart: 1, colEnd: 1, rowStart: 3, rowEnd: 3 },
            { colStart: 2, colEnd: 2, rowStart: 3, rowEnd: 3 },
            { colStart: 0, colEnd: 0, rowStart: 4, rowEnd: 4 },
            { colStart: 1, colEnd: 1, rowStart: 4, rowEnd: 4 },
            { colStart: 2, colEnd: 2, rowStart: 4, rowEnd: 4 }
          ]
        },
        {
          id: "2",
          columns: 3,
          rows: 5,
          layout: [
            { colStart: 0, colEnd: 1, rowStart: 0, rowEnd: 1 },
            { colStart: 2, colEnd: 2, rowStart: 0, rowEnd: 0 },
            { colStart: 2, colEnd: 2, rowStart: 1, rowEnd: 1 },
            { colStart: 0, colEnd: 0, rowStart: 2, rowEnd: 2 },
            { colStart: 1, colEnd: 1, rowStart: 2, rowEnd: 2 },
            { colStart: 2, colEnd: 2, rowStart: 2, rowEnd: 2 },
            { colStart: 0, colEnd: 0, rowStart: 3, rowEnd: 3 },
            { colStart: 1, colEnd: 1, rowStart: 3, rowEnd: 3 },
            { colStart: 2, colEnd: 2, rowStart: 3, rowEnd: 3 },
            { colStart: 0, colEnd: 0, rowStart: 4, rowEnd: 4 },
            { colStart: 1, colEnd: 1, rowStart: 4, rowEnd: 4 },
            { colStart: 2, colEnd: 2, rowStart: 4, rowEnd: 4 }
          ]
        },
        {
          id: "3",
          columns: 3,
          rows: 5,
          layout: [
            { colStart: 0, colEnd: 0, rowStart: 0, rowEnd: 0 },
            { colStart: 1, colEnd: 1, rowStart: 0, rowEnd: 0 },
            { colStart: 2, colEnd: 2, rowStart: 0, rowEnd: 0 },
            { colStart: 0, colEnd: 0, rowStart: 1, rowEnd: 1 },
            { colStart: 1, colEnd: 1, rowStart: 1, rowEnd: 1 },
            { colStart: 2, colEnd: 2, rowStart: 1, rowEnd: 1 },
            { colStart: 0, colEnd: 0, rowStart: 2, rowEnd: 2 },
            { colStart: 1, colEnd: 1, rowStart: 2, rowEnd: 2 },
            { colStart: 2, colEnd: 2, rowStart: 2, rowEnd: 2 },
            { colStart: 0, colEnd: 0, rowStart: 3, rowEnd: 3 },
            { colStart: 1, colEnd: 1, rowStart: 3, rowEnd: 3 },
            { colStart: 2, colEnd: 2, rowStart: 3, rowEnd: 3 },
            { colStart: 0, colEnd: 0, rowStart: 4, rowEnd: 4 },
            { colStart: 1, colEnd: 1, rowStart: 4, rowEnd: 4 },
            { colStart: 2, colEnd: 2, rowStart: 4, rowEnd: 4 }
          ]
        },
        {
          id: "4",
          columns: 3,
          rows: 5,
          layout: [
            { colStart: 0, colEnd: 1, rowStart: 0, rowEnd: 1 },
            { colStart: 2, colEnd: 2, rowStart: 0, rowEnd: 0 },
            { colStart: 2, colEnd: 2, rowStart: 1, rowEnd: 1 },
            { colStart: 0, colEnd: 0, rowStart: 2, rowEnd: 2 },
            { colStart: 1, colEnd: 1, rowStart: 2, rowEnd: 2 },
            { colStart: 2, colEnd: 2, rowStart: 2, rowEnd: 2 },
            { colStart: 0, colEnd: 0, rowStart: 3, rowEnd: 3 },
            { colStart: 1, colEnd: 2, rowStart: 3, rowEnd: 4 },
            { colStart: 0, colEnd: 0, rowStart: 4, rowEnd: 4 }
          ]
        },
        {
          id: "5",
          columns: 3,
          rows: 5,
          layout: [
            { colStart: 0, colEnd: 0, rowStart: 0, rowEnd: 0 },
            { colStart: 1, colEnd: 1, rowStart: 0, rowEnd: 0 },
            { colStart: 2, colEnd: 2, rowStart: 0, rowEnd: 0 },
            { colStart: 0, colEnd: 0, rowStart: 1, rowEnd: 1 },
            { colStart: 1, colEnd: 1, rowStart: 1, rowEnd: 1 },
            { colStart: 2, colEnd: 2, rowStart: 1, rowEnd: 1 },
            { colStart: 0, colEnd: 0, rowStart: 2, rowEnd: 2 },
            { colStart: 1, colEnd: 1, rowStart: 2, rowEnd: 2 },
            { colStart: 2, colEnd: 2, rowStart: 2, rowEnd: 2 },
            { colStart: 0, colEnd: 2, rowStart: 3, rowEnd: 4 }
          ]
        },
        {
          id: "6",
          columns: 3,
          rows: 5,
          layout: [
            { colStart: 0, colEnd: 1, rowStart: 0, rowEnd: 1 },
            { colStart: 2, colEnd: 2, rowStart: 0, rowEnd: 0 },
            { colStart: 2, colEnd: 2, rowStart: 1, rowEnd: 1 },
            { colStart: 0, colEnd: 0, rowStart: 2, rowEnd: 2 },
            { colStart: 1, colEnd: 1, rowStart: 2, rowEnd: 2 },
            { colStart: 2, colEnd: 2, rowStart: 2, rowEnd: 2 },
            { colStart: 0, colEnd: 2, rowStart: 3, rowEnd: 4 }
          ]
        },
        {
          id: "7",
          columns: 3,
          rows: 5,
          layout: [
            { colStart: 0, colEnd: 1, rowStart: 0, rowEnd: 1 },
            { colStart: 2, colEnd: 2, rowStart: 0, rowEnd: 0 },
            { colStart: 2, colEnd: 2, rowStart: 1, rowEnd: 1 },
            { colStart: 0, colEnd: 1, rowStart: 2, rowEnd: 3 },
            { colStart: 2, colEnd: 2, rowStart: 2, rowEnd: 2 },
            { colStart: 2, colEnd: 2, rowStart: 3, rowEnd: 3 },
            { colStart: 0, colEnd: 0, rowStart: 4, rowEnd: 4 },
            { colStart: 1, colEnd: 1, rowStart: 4, rowEnd: 4 },
            { colStart: 2, colEnd: 2, rowStart: 4, rowEnd: 4 }
          ]
        },
        {
          id: "8",
          columns: 3,
          rows: 5,
          layout: [
            { colStart: 0, colEnd: 2, rowStart: 0, rowEnd: 1 },
            { colStart: 0, colEnd: 0, rowStart: 2, rowEnd: 2 },
            { colStart: 1, colEnd: 1, rowStart: 2, rowEnd: 2 },
            { colStart: 2, colEnd: 2, rowStart: 2, rowEnd: 2 },
            { colStart: 0, colEnd: 2, rowStart: 3, rowEnd: 4 }
          ]
        },
        {
          id: "9",
          columns: 3,
          rows: 5,
          layout: [
            { colStart: 0, colEnd: 2, rowStart: 0, rowEnd: 1 },
            { colStart: 0, colEnd: 2, rowStart: 2, rowEnd: 3 },
            { colStart: 0, colEnd: 0, rowStart: 4, rowEnd: 4 },
            { colStart: 1, colEnd: 1, rowStart: 4, rowEnd: 4 },
            { colStart: 2, colEnd: 2, rowStart: 4, rowEnd: 4 }
          ]
        }
      ],
      landscape: [
        {
          id: "0",
          columns: 5,
          rows: 3,
          layout: [
            { colStart: 0, colEnd: 1, rowStart: 0, rowEnd: 2 },
            { colStart: 2, colEnd: 2, rowStart: 0, rowEnd: 0 },
            { colStart: 3, colEnd: 3, rowStart: 0, rowEnd: 0 },
            { colStart: 4, colEnd: 4, rowStart: 0, rowEnd: 0 },
            { colStart: 2, colEnd: 2, rowStart: 1, rowEnd: 1 },
            { colStart: 3, colEnd: 3, rowStart: 1, rowEnd: 1 },
            { colStart: 4, colEnd: 4, rowStart: 1, rowEnd: 1 },
            { colStart: 2, colEnd: 2, rowStart: 2, rowEnd: 2 },
            { colStart: 3, colEnd: 3, rowStart: 2, rowEnd: 2 },
            { colStart: 4, colEnd: 4, rowStart: 2, rowEnd: 2 }
          ]
        },
        {
          id: "1",
          columns: 5,
          rows: 3,
          layout: [
            { colStart: 0, colEnd: 0, rowStart: 0, rowEnd: 0 },
            { colStart: 1, colEnd: 1, rowStart: 0, rowEnd: 0 },
            { colStart: 2, colEnd: 2, rowStart: 0, rowEnd: 0 },
            { colStart: 3, colEnd: 4, rowStart: 0, rowEnd: 1 },
            { colStart: 0, colEnd: 1, rowStart: 1, rowEnd: 2 },
            { colStart: 2, colEnd: 2, rowStart: 1, rowEnd: 1 },
            { colStart: 2, colEnd: 2, rowStart: 2, rowEnd: 2 },
            { colStart: 3, colEnd: 3, rowStart: 2, rowEnd: 2 },
            { colStart: 4, colEnd: 4, rowStart: 2, rowEnd: 2 }
          ]
        },
        {
          id: "2",
          columns: 5,
          rows: 3,
          layout: [
            { colStart: 0, colEnd: 0, rowStart: 0, rowEnd: 0 },
            { colStart: 1, colEnd: 1, rowStart: 0, rowEnd: 0 },
            { colStart: 2, colEnd: 2, rowStart: 0, rowEnd: 0 },
            { colStart: 0, colEnd: 0, rowStart: 1, rowEnd: 1 },
            { colStart: 1, colEnd: 1, rowStart: 1, rowEnd: 1 },
            { colStart: 2, colEnd: 2, rowStart: 1, rowEnd: 1 },
            { colStart: 0, colEnd: 0, rowStart: 2, rowEnd: 2 },
            { colStart: 1, colEnd: 1, rowStart: 2, rowEnd: 2 },
            { colStart: 2, colEnd: 2, rowStart: 2, rowEnd: 2 },
            { colStart: 3, colEnd: 4, rowStart: 0, rowEnd: 2 }
          ]
        },
        {
          id: "3",
          columns: 5,
          rows: 3,
          layout: [
            { colStart: 0, colEnd: 0, rowStart: 0, rowEnd: 0 },
            { colStart: 1, colEnd: 2, rowStart: 0, rowEnd: 2 },
            { colStart: 3, colEnd: 1, rowStart: 0, rowEnd: 0 },
            { colStart: 4, colEnd: 4, rowStart: 0, rowEnd: 0 },
            { colStart: 0, colEnd: 0, rowStart: 1, rowEnd: 1 },
            { colStart: 3, colEnd: 1, rowStart: 1, rowEnd: 1 },
            { colStart: 4, colEnd: 4, rowStart: 1, rowEnd: 1 },
            { colStart: 0, colEnd: 0, rowStart: 2, rowEnd: 2 },
            { colStart: 3, colEnd: 1, rowStart: 2, rowEnd: 2 },
            { colStart: 4, colEnd: 4, rowStart: 2, rowEnd: 2 }
          ]
        },
        {
          id: "4",
          columns: 5,
          rows: 3,
          layout: [
            { colStart: 0, colEnd: 0, rowStart: 0, rowEnd: 0 },
            { colStart: 1, colEnd: 1, rowStart: 0, rowEnd: 0 },
            { colStart: 2, colEnd: 2, rowStart: 0, rowEnd: 0 },
            { colStart: 3, colEnd: 3, rowStart: 0, rowEnd: 0 },
            { colStart: 4, colEnd: 4, rowStart: 0, rowEnd: 0 },
            { colStart: 0, colEnd: 0, rowStart: 1, rowEnd: 1 },
            { colStart: 1, colEnd: 1, rowStart: 1, rowEnd: 1 },
            { colStart: 2, colEnd: 2, rowStart: 1, rowEnd: 1 },
            { colStart: 3, colEnd: 3, rowStart: 1, rowEnd: 1 },
            { colStart: 4, colEnd: 4, rowStart: 1, rowEnd: 1 },
            { colStart: 0, colEnd: 0, rowStart: 2, rowEnd: 2 },
            { colStart: 1, colEnd: 1, rowStart: 2, rowEnd: 2 },
            { colStart: 2, colEnd: 2, rowStart: 2, rowEnd: 2 },
            { colStart: 3, colEnd: 3, rowStart: 2, rowEnd: 2 },
            { colStart: 4, colEnd: 4, rowStart: 2, rowEnd: 2 }
          ]
        },
        {
          id: "5",
          columns: 5,
          rows: 3,
          layout: [
            { colStart: 0, colEnd: 0, rowStart: 0, rowEnd: 0 },
            { colStart: 1, colEnd: 1, rowStart: 0, rowEnd: 0 },
            { colStart: 2, colEnd: 2, rowStart: 0, rowEnd: 0 },
            { colStart: 3, colEnd: 3, rowStart: 0, rowEnd: 0 },
            { colStart: 4, colEnd: 4, rowStart: 0, rowEnd: 0 },
            { colStart: 0, colEnd: 1, rowStart: 1, rowEnd: 2 },
            { colStart: 2, colEnd: 3, rowStart: 1, rowEnd: 2 },
            { colStart: 4, colEnd: 4, rowStart: 1, rowEnd: 1 },
            { colStart: 4, colEnd: 4, rowStart: 2, rowEnd: 2 }
          ]
        },
        {
          id: "6",
          columns: 5,
          rows: 3,
          layout: [
            { colStart: 0, colEnd: 0, rowStart: 0, rowEnd: 0 },
            { colStart: 1, colEnd: 1, rowStart: 0, rowEnd: 0 },
            { colStart: 2, colEnd: 2, rowStart: 0, rowEnd: 0 },
            { colStart: 3, colEnd: 3, rowStart: 0, rowEnd: 0 },
            { colStart: 4, colEnd: 4, rowStart: 0, rowEnd: 0 },
            { colStart: 0, colEnd: 1, rowStart: 1, rowEnd: 2 },
            { colStart: 2, colEnd: 2, rowStart: 1, rowEnd: 1 },
            { colStart: 3, colEnd: 3, rowStart: 1, rowEnd: 1 },
            { colStart: 4, colEnd: 4, rowStart: 1, rowEnd: 1 },
            { colStart: 2, colEnd: 2, rowStart: 2, rowEnd: 2 },
            { colStart: 3, colEnd: 3, rowStart: 2, rowEnd: 2 },
            { colStart: 4, colEnd: 4, rowStart: 2, rowEnd: 2 }
          ]
        },
        {
          id: "7",
          columns: 5,
          rows: 3,
          layout: [
            { colStart: 0, colEnd: 0, rowStart: 0, rowEnd: 0 },
            { colStart: 1, colEnd: 1, rowStart: 0, rowEnd: 0 },
            { colStart: 2, colEnd: 2, rowStart: 0, rowEnd: 0 },
            { colStart: 3, colEnd: 4, rowStart: 0, rowEnd: 2 },
            { colStart: 0, colEnd: 1, rowStart: 1, rowEnd: 2 },
            { colStart: 2, colEnd: 2, rowStart: 1, rowEnd: 1 },
            { colStart: 2, colEnd: 2, rowStart: 2, rowEnd: 2 }
          ]
        },
        {
          id: "8",
          columns: 5,
          rows: 3,
          layout: [
            { colStart: 0, colEnd: 1, rowStart: 0, rowEnd: 2 },
            { colStart: 2, colEnd: 2, rowStart: 0, rowEnd: 0 },
            { colStart: 2, colEnd: 2, rowStart: 1, rowEnd: 1 },
            { colStart: 2, colEnd: 2, rowStart: 2, rowEnd: 2 },
            { colStart: 3, colEnd: 4, rowStart: 0, rowEnd: 2 }
          ]
        },
        {
          id: "9",
          columns: 5,
          rows: 3,
          layout: [
            { colStart: 0, colEnd: 1, rowStart: 0, rowEnd: 2 },
            { colStart: 2, colEnd: 3, rowStart: 0, rowEnd: 2 },
            { colStart: 4, colEnd: 4, rowStart: 0, rowEnd: 0 },
            { colStart: 4, colEnd: 4, rowStart: 1, rowEnd: 1 },
            { colStart: 4, colEnd: 4, rowStart: 2, rowEnd: 2 }
          ]
        }
      ]
    },
    selectedTemplate: {
      portrait: {
        templateId: undefined
      },
      landscape: {
        templateId: undefined
      }
    }
  };
}
