<template>
  <MainPage ref="mainPage" @confirmationResult="confirmationResult">
    <GridLayout
      height="100%"
      rows="*, auto"
      columns="*"
      class="edit-theme__container"
    >
      <StackLayout class="edit-theme__body" row="0" col="0">
        <StackLayout left="0" top="0" width="100%">
          <IBtnHiBtn
            icon1Src="icons/Controls/ic_controls_arrow-left_56.svg"
            :text="editMode ? translations.headerTitle : translations.newTheme"
            @iconTap1="goBack"
          />
        </StackLayout>
        <Separator size="xl" />

        <ThemeTemplate
          class="edit-theme__theme-template"
          :themeOptions="themeOptions.dashboard[state]"
          :tileOptions="themeOptions.tile[state]"
        />

        <IconHTxtiBtn
          class="edit-theme__theme-name"
          :class="{ 'edit-theme__theme-name__warning': isNameDuplicate }"
          icon2Src="icons/Controls/ic_controls_edit_56.svg"
          alignText="left"
          alignUnderline="left"
          headerSize="h3"
          :text="translations.name"
          :underlineText="
            themeOptions.name || translations.themeName.placeholder
          "
          showUnderline
          @tap="editName"
        />
        <Separator size="xl" />
        <IconHTxtiBtn
          v-if="false"
          row="0"
          col="0"
          class="edit-theme__tiles"
          headerSize="h2"
          :showUnderline="true"
          alignText="left"
          alignUnderline="left"
          underlineText=""
          :text="translations.tiles"
          :hover="false"
        />
        <Separator size="xl" v-if="false" />
        <HTextUnderlineTextSwitch
          v-if="false"
          alignText="left"
          alignUnderline="left"
          headerSize="h3"
          showUnderline
          :colorPrimary="getTemplateActiveColor"
          :text="translations.applyForAllTiles"
          :value="applyForAllTiles"
          :underlineText="translations.applyForAllTilesDesc"
          :disabled="!hasTile"
          @change="changeApplyForAllTiles"
        />
        <Separator size="xl" v-if="false" />
        <!-- TileList -->
        <TileList
          v-if="!applyForAllTiles && hasTile"
          :tiles="tiles"
          @selectTile="selectTile"
        />
        <StackLayout v-if="!hasTile">
          <IconHTxtiBtn
            class="edit-theme__live-warning"
            icon1Src="icons/Controls/ic_controls_info_56.svg"
            :text="translations.noAddedTiles"
            headerSize="s"
            alignText="left"
            :hover="false"
          />
          <Separator size="xl" />
        </StackLayout>
        <ScrollView>
          <StackLayout v-if="applyForAllTiles && hasTile">
            <ColorImageSelector
              :text="translations.tileBackground"
              :color="getColor('tile', 'background_color')"
              :image="backgroundImage('tile')"
              :useBackgroundImage="useBackgroundImage('tile')"
              :imageSelect="true"
              :themeType="'tile'"
              @change="changeBackground('tile', $event)"
            />
            <ColorImageSelector
              :text="translations.fontColor"
              :color="getColor('tile', 'font_color')"
              :themeType="'tile'"
              @change="changeColor('font_color', $event)"
            />
            <ColorImageSelector
              :text="translations.iconColor"
              :color="getColor('tile', 'icon_color')"
              :themeType="'tile'"
              @change="changeColor('icon_color', $event)"
            />
            <ColorImageSelector
              :text="translations.themeBackground"
              :color="getColor('dashboard', 'background_color')"
              :image="backgroundImage('dashboard')"
              :useBackgroundImage="useBackgroundImage('dashboard')"
              :imageSelect="true"
              :themeType="'dashboard'"
              @change="changeBackground('dashboard', $event)"
            />
          </StackLayout>
        </ScrollView>
      </StackLayout>
      <StackLayout row="1" col="0" class="edit-theme__button">
        <ButtonFilled
          :loading="isFetching('createTheme')"
          :disabled="isDisabled"
          :text="translations.save"
          @tap="saveTheme"
        />
      </StackLayout>
      <!-- <BottomUpDialog
        row="0"
        col="0"
        @beforeShown="handleBeforeShownDialogChangeName"
        @beforeHidden="handleBeforeHiddenDialogChangeName"
        class="dialog"
        ref="dialog"
      >
        <component
          ref="component"
          :is="dialog.component"
          v-bind="dialog.data"
          @close="closeDialog"
          @change="handleThemeName"
        />
      </BottomUpDialog> -->
    </GridLayout>
  </MainPage>
</template>
<script>
import { locals } from "@/helpers/variables";
import { isEqual, cloneDeep } from "lodash";
import { helpers } from "ezlo-core-ui";
import { createNamespacedHelpers } from "vuex";
import TileList from "@/components/dashboard/TileList";
import ColorImageSelector from "@/components/theme/ColorImageSelector";
import ThemeTemplate from "@/components/theme/ThemeTemplate";
import { isEmpty } from "@/helpers/utils";

const {
  mapGetters: mapUiGetters,
  mapActions: mapUiActions
} = createNamespacedHelpers("ui");
const { mapGetters: mapTemplateGetters } = createNamespacedHelpers("template");
const {
  mapGetters: mapThemesGetters,
  mapActions: mapThemesActions
} = createNamespacedHelpers("themes");
const colorModule = helpers.core.color;
const { VUE_APP_MODE } = process.env;

const _DASHBOARD = "dashboard";
const _TILE = "tile";

export default {
  name: "EditTheme",
  components: {
    TileList,
    ColorImageSelector,
    ThemeTemplate
  },
  props: {
    isLandscape: {
      type: Boolean,
      default: false
    },
    editMode: {
      type: Boolean,
      default: false
    },
    state: {
      type: String,
      default: "default"
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      // dialog: {
      //   component: "My theme",
      //   data: {}
      // },
      applyForAllTiles: true,
      themeOptions: cloneDeep(this.options),
      colorItemKey: null
    };
  },
  watch: {
    options(newValue) {
      this.resetThemeOptions();
    }
  },
  computed: {
    ...mapUiGetters(["isFetching", "hasError"]),
    ...mapTemplateGetters(["getTiles"]),
    ...mapThemesGetters(["getThemeByName"]),
    translations() {
      return this.$t("editTheme"); // eslint-disable-line
    },
    isDisabled() {
      return (
        !this.hasChanged ||
        !this.themeOptions.name ||
        (!this.editMode && this.isNameDuplicate)
      );
    },
    getTemplateActiveColor() {
      return locals.locals.colorPrimary;
    },
    hasTile() {
      return this.tiles && this.tiles.length > 0;
    },
    orientation() {
      return this.isLandscape ? "landscape" : "portrait";
    },
    tiles() {
      const tiles = this.getTiles({
        orientation: this.orientation
      });
      console.log("### tiles:", tiles); // eslint-disable-line
      return tiles.map(tile => {
        const dateText = {
          dateandtime: "Date & Time",
          date: "Date",
          time: "Time"
        };
        const text =
          tile.props.title ||
          (tile.props.type === "datetime" ? dateText[tile.props.subType] : "");
        return {
          text,
          type: tile.props.type,
          icon: tile.props.src,
          actionIcon: "icons/Controls/ic_controls_arrow-right_56.svg",
          raw: cloneDeep(tile)
        };
      });
    },
    hasChanged() {
      return !isEqual(this.options, this.themeOptions);
    },
    isNameDuplicate() {
      return (
        this.themeOptions.name &&
        !this.editMode &&
        !isEmpty(this.getThemeByName(this.themeOptions.name) || {})
      );
    }
  },
  methods: {
    ...mapUiActions(["emitError"]),
    ...mapThemesActions(["createTheme", "addTheme"]),
    close() {
      this.navigateBack();
      this.resetThemeOptions();
    },
    goBack(checkBeforeExit = true) {
      if (checkBeforeExit && this.hasChanged) {
        this.showConfirmationDialog({ type: "themeHasBeenChanged" });
      } else {
        this.close();
      }
    },
    resetThemeOptions() {
      this.$set(this, "themeOptions", cloneDeep(this.options));
    },
    changeApplyForAllTiles(event) {
      this.applyForAllTiles = event;
    },
    setThemeBackground(type, event) {
      this.themeOptions[type][this.state] = {
        ...this.themeOptions[type][this.state],
        ...event
      };
    },
    backgroundImage(type) {
      return this.themeOptions[type][this.state].background_image;
    },
    useBackgroundImage(type) {
      return this.themeOptions[type][this.state].use_background_image;
    },
    changeThemeBackground(event) {
      const {
        raw: { background_color, background_image, use_background_image }
      } = event;

      this.setThemeBackground("dashboard", {
        background_color,
        background_image,
        use_background_image
      });
    },
    changeBackground(type, event) {
      const {
        raw: { background_color, background_image, use_background_image }
      } = event;

      this.setThemeBackground(type, {
        background_color,
        background_image,
        use_background_image
      });
    },
    changeColor(key, event) {
      console.log("#### colorSelect callbackDone:", { key, event }); // eslint-disable-line
      const { color } = event;

      const colorKeyObj = { [key]: color || undefined };
      this.$set(this.themeOptions.tile, this.state, {
        ...this.themeOptions.tile[this.state],
        ...colorKeyObj
      });
    },
    showConfirmationDialog({ type = "themeHasBeenChanged" }) {
      this.emitError({
        type,
        meta: "themeHasBeenChanged"
      });
    },
    confirmationResult(event) {
      console.log("### --> EditTheme/confirmationResult:", event); //eslint-disable-line
      if (event.resultActionName) {
        if (event.resultActionName === "themeHasBeenChanged" && event.result) {
          // TODO save action
          this.saveTheme();
          return event;
        }
        this.close();
      }
    },
    getColor(type, key) {
      return this.themeOptions[type][this.state][key];
    },
    selectTile(tile) {
      console.log("### selectTile:", tile); // eslint-disable-line
    },
    editName() {
      console.log("### editName:"); // eslint-disable-line
      this.showThemeNameDialog();
    },
    showThemeNameDialog() {
      this.dialog = {
        component: "ChangeNameEntity",
        data: {
          name: this.themeOptions.name,
          titleText: this.translations.themeName.title,
          placeholderText: this.translations.themeName.placeholder,
          actionText: this.translations.themeName.btn,
          maxLength: 64
        }
      };
      this.showDialog();
    },
    showDialog() {
      const dialog = this.$refs.dialog;
      dialog && dialog.show();
    },
    closeDialog() {
      const dialog = this.$refs.dialog;
      dialog && dialog.hide();
    },
    handleBeforeShownDialogChangeName() {
      if (this.dialog.component === "ChangeNameEntity") {
        setTimeout(() => {
          const dialog = this.$refs.component;
          dialog && dialog.onShown();
        }, 25);
      }
    },
    handleBeforeHiddenDialogChangeName() {
      if (this.dialog.component === "ChangeNameEntity") {
        setTimeout(() => {
          const dialog = this.$refs.component;
          dialog && dialog.onHidden();
        }, 25);
      }
    },
    handleThemeName(name) {
      console.log("### name:", name); //eslint-disable-line
      this.themeOptions.name = name;
      this.closeDialog();
    },
    async saveTheme() {
      // // TODO this code will be removed, I added it for testing purposes.
      // await this.addTheme(cloneDeep(this.themeOptions));
      const options = {
        name: this.themeOptions.name,
        predefined: this.themeOptions.predefined,
        selected: false,
        dashboard: {
          ...this.themeOptions.dashboard
        },
        tile: {
          ...this.themeOptions.tile
        }
      };
      if (this.editMode) {
        options["uuid"] = this.themeOptions.uuid;
      }
      const result = await this.createTheme(cloneDeep(options));
      console.log("### EditTheme/saveTheme:", result); //eslint-disable-line
      if (result.content.status !== 0 && result.statusCode === 200) {
        this.goBack(false);
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~/assets/styles/variables.scss";

.edit-theme {
  &__body {
    overflow-y: auto;
  }
  &__header {
    margin: $margin-l $margin-xl;
  }
  &__add-button {
    color: $color-primary;
  }
  &__action {
    color: $color-primary;
    margin-right: $xl;
  }
  &__live-warning {
    padding-bottom: $xxs;
    /deep/ * {
      color: $color-grey !important;
    }
  }
  &__tiles {
    margin-top: $xl;
  }
  &__button {
    padding: $m $xl $xl $xl;
  }
  &__container {
    height: 100%;
  }
  &__theme-template {
    height: unit(189);
    min-height: unit(189);
    width: auto;
  }
  &__theme-name {
    margin-top: $margin-xl;
    min-height: $xxxll;
    &__warning {
      /deep/ .wrapper_text--underline {
        color: $color-warning;
      }
    }
  }
}
</style>
