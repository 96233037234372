<template>
  <StackLayout class="list-item">
    <IconHTxtiBtn
      class="list-item__field"
      alignText="left"
      alignUnderline="left"
      headerSize="h3"
      :icon2Src="icon"
      :text="text"
      :underlineText="underlineText"
      @tap="selectDashboard"
      showUnderline
    />
    <Separator size="xl" />
  </StackLayout>
</template>
<script>
export default {
  name: "ListItem",
  props: {
    icon: {
      type: String,
      default: ""
    },
    text: {
      type: String,
      default: ""
    },
    underlineText: {
      type: String,
      default: "Vera Edge"
    }
  },
  methods: {
    selectDashboard() {
      this.$emit("selectDashboard");
    }
  }
};
</script>
