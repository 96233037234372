export default function() {
  return {
    connectivity: {
      connectionType: "",
      offline: false
    },
    isFetching: [],
    error: {
      type: "",
      message: "",
      error: false,
      meta: "",
      code: ""
    },
    snackbar: {
      text: "",
      type: ""
    },
    isPageLoading: false
  };
}
