<template>
  <StackLayout slot="content" class="current-pwd" height="100%">
    <VxpLabel
      class="current-pwd__title font-h1-32"
      :text="titleText"
      :textWrap="true"
    />

    <TextInput
      ref="password"
      type="password"
      class="current-pwd__password"
      returnKeyType="next"
      :placeholder="passwordPlaceholderText"
      :hintType="passwordHintType"
      :hintText="passwordHintText"
      :showSoftInput="true"
      v-model="$v.password.$model"
      @textChange="handleChangePassword"
      @focus="handleFocusPassword"
      @blur="handleBlurPassword"
      @returnPress="handleTapSubmit"
      T9Disabled
      preventSpace
    />

    <ButtonFilled
      class="current-pwd__btn"
      :text="btnText"
      :disabled="isNextButtonDisabled"
      @tap="handleTapSubmit"
    />
  </StackLayout>
</template>

<script>
import { minLength, required } from "vuelidate/lib/validators";
import { helpers } from "ezlo-core-ui";

const { clearFocus } = helpers.core;
const { noWhiteSpace } = helpers.utils;

export default {
  name: "CurrentPasswordForm",
  props: {
    titleText: {
      type: String,
      default: "Title"
    },
    passwordPlaceholderText: {
      type: String,
      default: "Placeholder"
    },
    btnText: {
      type: String,
      default: "Button"
    },
    errorText: {
      type: String,
      default: "Error text"
    },
    isShowError: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      password: "",
      minPasswordLength: 1,
      pwdShowError: false,
      pwdHasBeenClicked: false
    };
  },
  computed: {
    isNextButtonDisabled() {
      return this.$v.password.$invalid;
    },
    passwordHintText() {
      const passwordV = this.$v.password.$model;
      let error = "";

      if (this.isShowError && !this.pwdHasBeenClicked) {
        error = this.errorText;
      }

      return passwordV && this.pwdShowError ? error : "";
    },
    passwordHintType() {
      return this.passwordHintText ? "error" : "info";
    }
  },
  methods: {
    clearFocus,
    handleChangePassword() {
      this.pwdHasBeenClicked = true;
    },
    handleFocusPassword() {
      this.pwdShowError = false;
    },
    handleBlurPassword() {
      this.pwdShowError = true;
    },
    handleTapSubmit() {
      const isFormValid = !this.$v.$invalid;

      if (isFormValid) {
        // this.pwdShowError = false;

        this.$emit("submit", this.password);
      }
    }
  },
  validations() {
    return {
      password: {
        required,
        minLength: minLength(this.minPasswordLength),
        noWhiteSpace
      }
    };
  },
  watch: {
    password(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.pwdHasBeenClicked = false;
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "~/assets/styles/variables.scss";

.current-pwd {
  height: 100%;
  color: $color-black;
  padding-left: $padding-xl;
  padding-right: $padding-xl;
  vertical-align: top;

  &__title {
    vertical-align: bottom;
    color: $color-black;
  }

  &__title,
  &__password,
  &__btn {
    margin-bottom: $margin-xl;
  }
}
</style>
