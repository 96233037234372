<template>
  <HyperlinkLabel
    :class="agreementsLinkClasses"
    :text="text"
    :isAllClickable="isAllClickable"
    @tap="$emit('tap')"
  />
</template>
<script>
export default {
  name: "AgreementsLink",
  props: {
    text: {
      type: String,
      required: true
    },
    textSize: {
      type: String,
      default: "sm",
      validator(value) {
        return ["sm", "md"].includes(value);
      }
    },
    isAllClickable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    agreementsLinkClasses() {
      return {
        terms: true,
        "font-txt-13": this.textSize === "sm",
        "font-txt-16-par": this.textSize === "md"
      };
    }
  }
};
</script>

<style scoped lang="scss">
@import "~/assets/styles/base.scss";

.terms {
  text-align: center;
  color: $color-grey;
}
</style>
