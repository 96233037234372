import request from "./http";
import endpoints from "./endpoints";
import apiConfig from "../../config/api";

const { cloud, theme } = endpoints;
const { cloudApiFullUrl, cloudXAppToken } = apiConfig;

const themeService = {
  fetchThemes: async ({ authJwtToken = "" }) => {
    const url = cloud.request;
    const headers = {
      Authorization: `Bearer ${authJwtToken}`,
      "X-App": cloudXAppToken
    };
    const params = {
      call: theme.get,
      params: JSON.stringify({
        data: 1
      })
    };
    return await request({
      method: "GET",
      baseUrl: cloudApiFullUrl(),
      url,
      headers,
      params
    });
  },
  updateTheme: async ({ authJwtToken = "", options }) => {
    const {
      id,
      name,
      entity_type,
      entity_uuid,
      entity_id,
      group_type,
      last_modified, // do not change
      ...rest
    } = options;
    const uuid = options.uuid;
    const url = cloud.request;
    const headers = {
      Authorization: `Bearer ${authJwtToken}`,
      "X-App": cloudXAppToken
    };
    const params = {
      call: theme.put,
      params: {
        uuid,
        name: options.name,
        data: options
        //TODO THEMES
      }
    };

    return await request({
      method: "POST",
      baseUrl: cloudApiFullUrl(),
      url,
      headers,
      params
    });
  },
  deleteTheme: async ({ authJwtToken = "", options }) => {
    const uuid = options.uuid;
    const url = cloud.request;
    const headers = {
      Authorization: `Bearer ${authJwtToken}`,
      "X-App": cloudXAppToken
    };
    const params = {
      call: theme.delete,
      params: {
        uuid,
        delete: 1
      }
    };
    return await request({
      method: "POST",
      baseUrl: cloudApiFullUrl(),
      url,
      headers,
      params
    });
  },
  createTheme: async ({ authJwtToken = "", options }) => {
    const {
      name,
      entity_type,
      entity_uuid,
      entity_id,
      group_type,
      ...rest
    } = options;
    const url = cloud.request;
    const headers = {
      Authorization: `Bearer ${authJwtToken}`,
      "X-App": cloudXAppToken
    };
    // params are not used in the current implmentation
    const params = {
      call: theme.post,
      params: options
    };
    return await request({
      method: "POST",
      baseUrl: cloudApiFullUrl(),
      url,
      headers,
      params
    });
  }
};

export default themeService;
