<template>
  <MainPage>
    <StackLayout>
      <IBtnHiBtn
        icon1Src="icons/Controls/ic_controls_arrow-left_56.svg"
        :text="textsMain.textTitle"
        @iconTap1="handleBack"
        class="devices__header"
      />
    </StackLayout>
    <GridLayout rows="*, auto" columns="*" class="add-device">
      <StackLayout row="0" col="0" class="add-device__container">
        <GridLayout
          rows="110, auto, auto, *"
          columns="*"
          class="add-device__devices"
        >
          <GridLayout row="1" col="0" rows="*" columns="*, auto, auto, *">
            <ImageCacheIt
              row="0"
              col="1"
              class="add-device__alexaImage"
              width="124"
              height="124"
              stretch="aspectFill"
              :src="amazonAlexaImage"
            />
            <ImageCacheIt
              row="0"
              col="2"
              class="add-device__googleImage"
              width="124"
              height="124"
              stretch="aspectFill"
              :src="googleHomeImage"
            />
          </GridLayout>
          <StackLayout row="2" col="0">
            <Label
              class="add-device__title font-h2-24"
              :text="textsMain.textDescTitle"
              textWrap
            />
            <Label
              class="add-device__text font-txt-16-par"
              :text="textsMain.textDesc"
              textWrap
            />
          </StackLayout>
        </GridLayout>
      </StackLayout>

      <StackLayout row="1" col="0" class="add-device__btn-container">
        <ButtonFilled :text="textsMain.textCreate" @tap="handleCreate" />
      </StackLayout>
    </GridLayout>
  </MainPage>
</template>
<script>
export default {
  name: "AddDevice",
  data() {
    return {
      amazonAlexaImage: "~/assets/images/SmartHomes/amazonAlexa_56.png",
      googleHomeImage: "~/assets/images/SmartHomes/googleHome_56.png"
    };
  },
  computed: {
    textsMain() {
      // eslint-disable-next-line
      return this.$t("addDevice");
    }
  },
  methods: {
    handleBack() {
      this.navigateBack();
    },
    handleCreate() {
      this.navigateTo("DeviceToControl");
      console.log("handleCreate"); // eslint-disable-line
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~/assets/styles/variables.scss";

.add-device {
  height: 100%;
  &__container {
    margin: 0 $xl;
  }
  &__btn-container {
    padding: 0 $xl $l $xl;
  }
  &__title {
    text-align: center;
    color: $color-black;
  }
  &__text {
    margin: $margin-s 0 0 0;
    text-align: center;
    color: $color-black;
  }
  &__alexaImage {
    grid-column: 2;
    justify-self: end;
    width: unit(124);
    height: unit(124);
  }
  &__googleImage {
    grid-column: 3;
    justify-self: start;
    width: unit(124);
    height: unit(124);
  }
}
</style>
