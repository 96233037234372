<template>
  <MainPage>
    <GridLayout class="icon" height="100%" rows="auto,auto,*,auto" columns="*">
      <IBtnHiBtn
        row="0"
        col="0"
        icon1Src="icons/Controls/ic_controls_arrow-left_56.svg"
        :text="translations.title"
        @iconTap1="handleGoBack"
      />
      <StackLayout row="1" col="0" @tap="handleUploadCustomIcon">
        <Label
          class="font-txt-16-par icon__subtitle"
          :text="translations.sub"
          textWrap
        />
        <Separator size="xl" />
        <!-- <IconHTxtiBtn
          class="icon__primary"
          icon2Src="icons/Controls/ic_controls_plus_primary_56.svg"
          headerSize="h3"
          alignText="left"
          :text="translations.upload"
        />
        <Separator size="xl" /> -->
      </StackLayout>
      <ScrollView row="2" col="0">
        <StackLayout class="icon__wrap">
          <Label class="font-h2-24 icon__sub" :text="translations.sub2" />
          <TileItem
            height="103"
            width="103"
            class="icon__tile icon__current"
            :src="iconList[0]"
            :title="name"
            :backgroundColorHex="selectedTile !== 0 ? '#ffffff' : '#3e46ae'"
            :colorHex="selectedTile !== 0 ? '#000000' : '#ffffff'"
            :iconColorHex="selectedTile !== 0 ? '#000000' : '#ffffff'"
            @tap="selectTile(0)"
          />
          <Label class="font-h2-24 icon__sub" :text="categoryTitle" />
          <WrapLayout>
            <TileItem
              v-show="idx !== 0"
              height="103"
              width="103"
              class="icon__tile"
              v-for="(icon, idx) in iconList"
              :key="idx"
              :src="icon"
              :title="getIconTitle(icon)"
              :backgroundColorHex="selectedTile !== idx ? '#ffffff' : '#3e46ae'"
              :colorHex="selectedTile !== idx ? '#000000' : '#ffffff'"
              @tap="selectTile(idx)"
            />
          </WrapLayout>
        </StackLayout>
      </ScrollView>
      <StackLayout row="3" col="0" class="icon__btn">
        <ButtonFilled
          :disabled="getDoneBtnStatus"
          :text="translations.btn"
          @tap="handleDone"
        />
      </StackLayout>
    </GridLayout>
  </MainPage>
</template>
<script>
import { createNamespacedHelpers } from "vuex";
import { helpers } from "ezlo-core-ui";
import platform from "vuexp/src/platform";

const { VUE_APP_MODE } = process.env;
const { mapActions: mapUiActions } = createNamespacedHelpers("ui");
const { mapGetters: mapCategoryGetters } = createNamespacedHelpers(
  "categories"
);
const {
  mapActions: mapDashboardActions,
  mapGetters: mapDashboardGetters
} = createNamespacedHelpers("dashboards");

export default {
  name: "IconList",
  props: {
    category: {
      type: String,
      default: ""
    },
    subcategory: {
      type: String,
      default: ""
    },
    currentIcon: {
      type: String,
      default: "icons/Devices/ezlo-ic_tabbar_device-hub_56.svg"
    },
    name: {
      type: String,
      default: ""
    },
    tileType: {
      type: String,
      default: "device"
    }
  },
  data() {
    return {
      selectedTile: -1
    };
  },
  mounted() {
    this.selectedTile = this.iconList.findIndex(
      icon => this.currentIcon === icon
    );
  },
  computed: {
    ...mapCategoryGetters(["getIconListByCategory"]),
    ...mapDashboardGetters(["getDashboardDefaultImagePresets"]),
    iconList() {
      return this.tileType === "device"
        ? this.getIconListByCategory(this.category, this.subcategory)
        : [];
    },
    isChanged() {
      return this.dataChanges;
    },
    getDoneBtnStatus() {
      return this.selectedTile === -1;
    },
    translations() {
      return this.$t("iconList");
    },
    categoryTitle() {
      if (this.category) {
        const iconText = this.category.split("_");
        let text = "";
        for (let i = 0; i < iconText.length; i++) {
          text +=
            iconText[i].charAt(0).toUpperCase() + iconText[i].slice(1) + " ";
        }
        return text;
      } else {
        return "--";
      }
    }
  },
  methods: {
    ...mapUiActions(["saveSnackbarText"]),
    ...mapDashboardActions(["addDashboardCustomBgImg"]),
    selectTile(index) {
      this.selectedTile = index;
    },
    handleGoBack() {
      this.$destroy();
      this.navigateBack();
    },
    handleDone() {
      this.$root.$emit("_changeIcon", {
        icon: this.iconList[this.selectedTile]
      });
      this.handleGoBack();
    },
    handleUploadCustomIcon() {
      this.handleGoBack();
    },
    getIconTitle(icon) {
      const iconText = icon.split("/")[2].split("_");
      return (
        iconText[0].charAt(0).toUpperCase() +
        iconText[0].slice(1) +
        " " +
        iconText[1].charAt(0).toUpperCase() +
        iconText[1].slice(1)
      );
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~/assets/styles/base.scss";
.icon {
  height: 100%;
  color: $color-black;
  &__btn {
    padding-bottom: $padding-l;
    padding-left: $padding-xl;
    padding-right: $padding-xl;
  }
  &__sub {
    padding: $padding-xl 0;
  }
  &__subtitle {
    padding: $padding-xl;
  }
  &__primary {
    /deep/ & * {
      color: $color-primary;
    }
  }
  &__tile {
    height: $tile-height;
    width: $tile-height;
    margin: $xs;
  }
  &__wrap {
    padding-left: $lmx;
    padding-right: $lmx;
  }
  &__current {
    horizontal-align: left;
  }
}
</style>
