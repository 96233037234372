<template>
  <MainPage>
    <GridLayout class="icon" height="100%" rows="auto,auto,*,auto" columns="*">
      <IBtnHiBtn
        row="0"
        col="0"
        icon1Src="icons/Controls/ic_controls_arrow-left_56.svg"
        :text="translations.title"
        @iconTap1="handleGoBack"
      />

      <ScrollView row="2" col="0">
        <StackLayout class="icon__wrap">
          <StackLayout :key="key" v-for="(icons, key) in iconList">
            <Label
              v-if="icons.size !== 0"
              class="font-h2-24 icon__sub"
              :text="getCategoryTitle(key)"
            />
            <WrapLayout v-if="icons.size !== 0">
              <TileItem
                height="103"
                width="103"
                class="icon__tile"
                v-for="(icon, idx) in setToList(icons)"
                :key="idx"
                :src="icon"
                :title="getIconTitle(icon)"
                :backgroundColorHex="
                  isSelected(key, idx) ? '#ffffff' : '#3e46ae'
                "
                :iconColorHex="isSelected(key, idx) ? '#000000' : '#ffffff'"
                :colorHex="isSelected(key, idx) ? '#000000' : '#ffffff'"
                @tap="selectTile(key, idx)"
              />
            </WrapLayout>
          </StackLayout>
        </StackLayout>
      </ScrollView>
      <StackLayout row="3" col="0" class="icon__btn">
        <ButtonFilled
          :disabled="getDoneBtnStatus"
          :text="translations.btn"
          @tap="handleDone"
        />
      </StackLayout>
    </GridLayout>
  </MainPage>
</template>
<script>
import { createNamespacedHelpers } from "vuex";
import { helpers } from "ezlo-core-ui";
import platform from "vuexp/src/platform";

const { VUE_APP_MODE } = process.env;
const { mapActions: mapUiActions } = createNamespacedHelpers("ui");
const { mapGetters: mapCategoryGetters } = createNamespacedHelpers(
  "categories"
);
const {
  mapActions: mapDashboardActions,
  mapGetters: mapDashboardGetters
} = createNamespacedHelpers("dashboards");

export default {
  name: "IconSetsList",
  props: {
    currentIcon: {
      type: String,
      default: "icons/Devices/ezlo-ic_tabbar_device-hub_56.svg"
    },
    version: {
      type: String,
      default: "v2"
    }
  },
  data() {
    return {
      selectedTile: { category: "", index: 0 }
    };
  },
  mounted() {
    let selected = { category: "", index: 0 };
    Object.keys(this.iconList).forEach(category => {
      const icons = [...this.iconList[category]]; // Set to array
      const index = icons.findIndex(icon => this.currentIcon === icon);
      if (index !== -1) {
        selected = { category, index };
      }
    });
    this.selectedTile = selected;
  },
  computed: {
    ...mapCategoryGetters(["getAllIcons"]),
    ...mapDashboardGetters(["getDashboardDefaultImagePresets"]),

    iconList() {
      return this.getAllIcons(this.version);
    },
    isChanged() {
      return this.dataChanges;
    },
    getDoneBtnStatus() {
      return this.selectedTile === -1;
    },
    translations() {
      return this.$t("iconList");
    }
  },
  methods: {
    ...mapUiActions(["saveSnackbarText"]),
    ...mapDashboardActions(["addDashboardCustomBgImg"]),
    isSelected(category, index) {
      return !(
        this.selectedTile.category === category &&
        this.selectedTile.index === index
      );
    },
    setToList(set) {
      return set;
    },
    selectTile(category, index) {
      this.selectedTile = {
        category,
        index
      };
    },
    handleGoBack() {
      this.$destroy();
      this.navigateBack();
    },
    handleDone() {
      const icons = [...this.iconList[this.selectedTile.category]]; // Set to array
      this.$root.$emit("_changeIcon", {
        icon: icons[this.selectedTile.index]
      });
      this.handleGoBack();
    },
    handleUploadCustomIcon() {
      this.handleGoBack();
    },
    getIconTitle(icon) {
      if (icon) {
        const iconText = icon.split("/")[2].split("_");
        const index = iconText.length - 1;
        return (
          iconText[index - 1].charAt(0).toUpperCase() +
          iconText[index - 1].slice(1)
        );
      }
      return "";
    },
    getCategoryTitle(category) {
      const iconText = category.split("_");
      let text = "";
      for (let i = 0; i < iconText.length; i++) {
        text +=
          iconText[i].charAt(0).toUpperCase() + iconText[i].slice(1) + " ";
      }
      return text;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~/assets/styles/base.scss";
.icon {
  height: 100%;
  color: $color-black;
  &__btn {
    padding-bottom: $padding-l;
    padding-left: $padding-xl;
    padding-right: $padding-xl;
  }
  &__sub {
    padding: $padding-xl 0;
  }
  &__subtitle {
    padding: $padding-xl;
  }
  &__primary {
    /deep/ & * {
      color: $color-primary;
    }
  }
  &__tile {
    height: $tile-height;
    width: $tile-height;
    margin: $xs;
  }
  &__wrap {
    padding-left: $lmx;
    padding-right: $lmx;
  }
  &__current {
    horizontal-align: left;
  }
}
</style>
