import Vue from "vue";
import {
  DASHBOARDS_SET,
  DASHBOARD_RENAME,
  DASHBOARDS_HIDE_PROMO,
  DASHBOARD_DELETE,
  DASHBOARD_DUPLICATE,
  DASHBOARD_UPDATE,
  DASHBOARD_CREATE,
  DASHBOARD_CANCEL,
  STYLE_SETTING_SET,
  STYLE_SETTINGS_CANCEL,
  STYLE_SETTINGS_SAVE,
  STYLE_SETTINGS_SELECT_BG_IMG,
  STYLE_SETTINGS_ADD_BG_IMG,
  STYLE_SETTINGS_SAVE_BG_IMG,
  STYLE_SETTINGS_DEFAULT,
  SET_FONT_FAMILY,
  NEW_DASHBOARD_SET_TEMPLATE,
  NEW_DASHBOARD_RESET,
  NEW_DASHBOARD_SET_NAME,
  SET_STYLE_CHANGED,
  DASHBOARD_CHANGED_SET,
  SET_BACKGROUND_IMAGES,
  SET_AS_DEFAULT_DASHBOARD,
  DELETE_ICON,
  SET_USER_ICONS,
  SET_TUTORIAL_APPROVED,
  SET_DASHBOARD_THEME
} from "./mutationsTypes";
import { cloneDeep } from "lodash";

export default {
  [DASHBOARDS_HIDE_PROMO](state) {
    Vue.set(state, "isPromoShown", false);
  },
  [DASHBOARDS_SET](state, payload) {
    Vue.set(state, "dashboards", payload);
  },
  [DASHBOARD_CANCEL](state) {
    Vue.set(state, "dashboardsChanged", null);
  },
  [DASHBOARD_CHANGED_SET](state, payload) {
    Vue.set(state, "dashboardsChanged", payload);
  },
  [DASHBOARD_RENAME](state, payload) {
    const { id, name } = payload;
    let dashboardChanged = state.dashboardsChanged;

    if (!dashboardChanged) {
      const index = state.dashboards.findIndex(d => d.id === id);
      const dashboard = state.dashboards[index];
      dashboardChanged = { ...dashboard };
    }

    dashboardChanged.name = name;
    Vue.set(state, "dashboardsChanged", dashboardChanged);
  },
  [DASHBOARD_UPDATE](state) {
    Vue.set(state, "dashboards", state.dashboards);
  },
  [DASHBOARD_DELETE](state) {
    Vue.set(state, "dashboards", state.dashboards);
  },
  [DASHBOARD_CREATE](state, payload) {
    Vue.set(state, "dashboards", [...state.dashboards, payload]);
  },
  [DASHBOARD_DUPLICATE](state, { id, item }) {
    const index = state.dashboards.findIndex(d => d.id === id);
    const newDashboards = [...state.dashboards];
    newDashboards.splice(index + 1, 0, item);
    Vue.set(state, "dashboards", newDashboards);
  },
  [STYLE_SETTING_SET](state, { id, group, option, color }) {
    let stylesChanged = state.stylesChanged;
    let dashboardChanged = state.dashboardsChanged;

    if (!stylesChanged) {
      if (!dashboardChanged) {
        const dashboard = state.dashboards.find(d => d.id === id);
        dashboardChanged = { ...dashboard };
      }
      stylesChanged = { ...dashboardChanged.styleSettings };
    }
    stylesChanged = {
      ...stylesChanged,
      [group]: {
        ...stylesChanged[group],
        [option]: color
      }
    };
    Vue.set(state, "stylesChanged", stylesChanged);
  },
  [STYLE_SETTINGS_CANCEL](state) {
    Vue.set(state, "stylesChanged", null);
  },
  [STYLE_SETTINGS_SAVE](state, id) {
    const newStyles = state.stylesChanged;
    let dashboardChanged = state.dashboardsChanged;

    if (!newStyles) return;
    if (!dashboardChanged) {
      const dashboard = state.dashboards.find(d => d.id === id);
      dashboardChanged = { ...dashboard };
    }

    dashboardChanged.styleSettings = { ...newStyles };
    Vue.set(state, "dashboardsChanged", dashboardChanged);
    Vue.set(state, "stylesChanged", null);
  },
  [STYLE_SETTINGS_SELECT_BG_IMG](state, payload) {
    state.defaultImagePresets.forEach(async element => {
      Vue.set(element, "selected", element.id === payload);
    });
  },
  [STYLE_SETTINGS_ADD_BG_IMG](state, payload) {
    const newImagePresets = [payload, ...state.defaultImagePresets];
    Vue.set(state, "defaultImagePresets", newImagePresets);
  },
  [STYLE_SETTINGS_SAVE_BG_IMG](state, { id, bgImage }) {
    const dashboardBgImage = bgImage.src;
    let stylesChanged = state.stylesChanged;
    let dashboardChanged = state.dashboardsChanged;

    if (!stylesChanged) {
      if (!dashboardChanged) {
        const dashboard = state.dashboards.find(d => d.id === id);
        dashboardChanged = { ...dashboard };
      }
      stylesChanged = { ...dashboardChanged.styleSettings };
    }
    stylesChanged = {
      ...stylesChanged,
      general: {
        ...stylesChanged.general,
        background_image: dashboardBgImage
      }
    };

    Vue.set(state, "stylesChanged", stylesChanged);
  },
  [SET_FONT_FAMILY](state, { id, fontFamily }) {
    let stylesChanged = state.stylesChanged;
    if (!stylesChanged) {
      let dashboardChanged = state.dashboardsChanged;
      if (!dashboardChanged) {
        const dashboard = state.dashboards.find(d => d.id === id);
        dashboardChanged = { ...dashboard };
      }
      stylesChanged = { ...dashboardChanged.styleSettings };
    }
    stylesChanged = {
      ...stylesChanged,
      fontFamily
    };
    Vue.set(state, "stylesChanged", stylesChanged);
  },
  [NEW_DASHBOARD_SET_TEMPLATE](state, { orientation, template }) {
    Vue.set(state.newDashboard, orientation, template);
  },
  [NEW_DASHBOARD_RESET](state) {
    Vue.set(state, "newDashboard", { portrait: null, landscape: null });
  },
  [STYLE_SETTINGS_DEFAULT](state) {
    Vue.set(state, "stylesChanged", { ...state.setDefStyleSettings });
  },
  [NEW_DASHBOARD_SET_NAME](state, payload) {
    Vue.set(state.newDashboard, "name", payload);
  },
  [SET_STYLE_CHANGED](state, payload) {
    Vue.set(state, "stylesChanged", payload);
  },
  [SET_BACKGROUND_IMAGES](state, payload) {
    if (!payload) return;
    const { imageUrl, value: backgroundImages } = payload;
    const images =
      backgroundImages &&
      backgroundImages.map(item => {
        // archive / thumbnail
        return {
          id: item.PK_Store,
          src: `${imageUrl}/${item.PK_Store}/archive?Key=${item.Key}`,
          selected: false
        };
      });
    // replace placeholder
    let placeHolder = state.defaultImagePresets[0];
    if (!placeHolder.src) {
      placeHolder = { ...images[0] };
      images.shift();
      Vue.set(state.defaultImagePresets, 0, placeHolder);
    }

    let allImages = [...state.defaultImagePresets];
    if (images) {
      allImages = [...allImages, ...images];
    }
    let unique = Array.from(new Set(allImages.map(item => item.id))).map(id =>
      allImages.find(item => item.id === id)
    );

    Vue.set(state, "defaultImagePresets", unique);
    Vue.set(state, "imagesKVS", backgroundImages);
  },
  [SET_AS_DEFAULT_DASHBOARD](state, payload) {
    const { id, isDefault } = payload;
    let dashboardChanged = state.dashboardsChanged;

    if (!dashboardChanged) {
      const index = state.dashboards.findIndex(d => d.id === id);
      const dashboard = state.dashboards[index];
      dashboardChanged = { ...dashboard };
    }
    dashboardChanged.isDefault = isDefault;
    Vue.set(state, "dashboardsChanged", dashboardChanged);
  },
  async [DELETE_ICON](state, payload) {
    Vue.set(
      state,
      "customIcons",
      state.customIcons.filter((item, index) => index !== payload)
    );
  },
  [SET_USER_ICONS](state, payload) {
    Vue.set(state, "customIcons", payload.value || []);
  },
  [SET_TUTORIAL_APPROVED](state, payload) {
    Vue.set(state, "tutorialApproved", payload || false);
  },
  [SET_DASHBOARD_THEME](state, payload) {
    const {
      dashboardId,
      theme,
      orientation,
      applyForAllOrientations = false
    } = payload;
    const index = state.dashboards.findIndex(d => d.id === dashboardId);
    if (index !== -1) {
      let dashboardChanged = cloneDeep(state.dashboardsChanged);

      if (!dashboardChanged) {
        const dashboard = cloneDeep(state.dashboards[index]);
        dashboardChanged = { ...dashboard };
      }

      let themeOrientation = {
        [orientation]: {
          theme_id: theme.uuid
        }
      };

      if (applyForAllOrientations) {
        themeOrientation = {
          ["portrait"]: {
            theme_id: theme.uuid
          },
          ["landscape"]: {
            theme_id: theme.uuid
          }
        };
      }
      dashboardChanged.styleSettings.theme = {
        ...dashboardChanged.styleSettings.theme,
        ...themeOrientation
      };
      Vue.set(state, "dashboardsChanged", dashboardChanged);
    }
  }
};
