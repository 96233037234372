<template>
  <StackLayout
    class="new-link-address-name"
    stretchLastChild="false"
    height="100%"
  >
    <StackLayout class="new-link-address-name__input" dock="top">
      <TextInput
        v-model="$v.name.$model"
        type="text"
        showSoftInput
        eraseButton
        returnKeyType="done"
        :hintText="translations.textLinkHint"
        @returnPress="handleSubmit"
      />
    </StackLayout>
    <StackLayout dock="bottom" class="new-link-address-name__footer">
      <ButtonFilled
        :text="translations.textBtn"
        :disabled="isButtonDisabled"
        @tap="handleSubmit"
      />
    </StackLayout>
  </StackLayout>
</template>

<script>
import { required } from "vuelidate/lib/validators";

export default {
  name: "NewLinkAddressName",
  computed: {
    translations() {
      return this.$t("newLinkAddressName"); // eslint-disable-line
    },
    isButtonDisabled() {
      return this.$v.name.$invalid;
    }
  },
  data() {
    return {
      name: ""
    };
  },
  methods: {
    handleSubmit() {
      this.$emit("submit", this.name);
    }
  },
  validations() {
    return {
      name: {
        required
      }
    };
  }
};
</script>
<style scoped lang="scss">
@import "~/assets/styles/variables.scss";
.new-link-address-name {
  padding: 0 $xl;
  &__input {
    width: 100%;
  }
  &__footer {
    padding-bottom: $xl;
  }
}
</style>
