<template>
  <StackLayout class="goal-devices">
    <StackLayout v-for="item in devices" :key="item.groupTitle">
      <GoalDevicesList
        :title="item.groupTitle"
        :items="item.devices"
        @tap="$emit('tap', $event)"
      />
    </StackLayout>
    <StackLayout height="16" />
  </StackLayout>
</template>
<script>
import GoalDevicesList from "./GoalDevicesList";

export default {
  name: "GoalDevices",
  components: {
    GoalDevicesList
  },
  props: {
    devices: {
      type: Array,
      default: () => []
    }
  }
};
</script>
<style scoped lang="scss">
@import "~/assets/styles/variables.scss";

.goal-devices {
  margin-top: $m;
}
</style>
