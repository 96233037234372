<template>
  <StackLayout slot="content" class="signin-by-user-start" height="100%">
    <VxpLabel
      class="signin-by-user-start__title font-h1-32"
      :text="titleText"
      :textWrap="true"
    />
    <TextInput
      ref="username"
      class="signin-by-user-start__username"
      type="text"
      v-model.lazy="$v.username.$model"
      :maxLength="maxUsernameLength"
      :placeholder="usernamePlaceholder"
      :hintType="usernameError ? 'error' : 'info'"
      :hintText="usernameError"
      :showSoftInput="true"
      :eraseButton="true"
      @blur="onBlurUsername"
      @focus="onFocusUsername"
      @textChange="onTextUsernameChange"
      T9Disabled
      preventSpace
      @returnPress="focusPassword"
      returnKeyType="next"
      :disabled="isLoading"
    ></TextInput>

    <TextInput
      ref="password"
      class="signin-by-user-start__password"
      type="password"
      v-model.lazy="$v.password.$model"
      :placeholder="passwordPlaceholder"
      T9Disabled
      preventSpace
      @returnPress="login"
      returnKeyType="done"
      @blur="onBlurPassword"
      :disabled="isLoading"
    ></TextInput>

    <ButtonFilled
      @tap="login()"
      :disabled="isNextButtonDisabled"
      class="signin-by-user-start__button"
      :text="loginButtonText"
      :loading="isLoading"
    />
  </StackLayout>
</template>

<script>
import { minLength, maxLength, required } from "vuelidate/lib/validators";
import { helpers } from "ezlo-core-ui";
const { clearFocus } = helpers.core;
import platform from "vuexp/src/platform";

const {
  utils: { noWhiteSpace }
} = helpers;
const IOS_TEXT_INPUT_KEYBOARD_OFFSET = 220,
  IOS_TEXT_INPUT_KEYBOARD_OFFSET_DEFAULT = 125;

export default {
  name: "LoginForm",
  props: {
    translations: {
      type: Object,
      required: true
    },
    usernamePlaceholder: {
      type: String,
      default: "Username"
    },
    passwordPlaceholder: {
      type: String,
      default: "Password"
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      username: "",
      password: "",
      minPasswordLength: 1,
      minUsernameLength: 1,
      maxUsernameLength: 100,
      usernameShowError: false,
      usernameHasBeenClicked: false,
      titleText: this.fetchTranslations("titleText"),
      loginButtonText: this.fetchTranslations("loginButtonText")
    };
  },
  computed: {
    isNextButtonDisabled() {
      const passwordValid = this.$v.password.$invalid;
      const usernameValid = this.$v.username.$invalid;
      return passwordValid || usernameValid;
    },
    usernameError() {
      let error = "";
      if (this.$v.username.$invalid && this.usernameHasBeenClicked) {
        error = this.fetchTranslations("usernameError");
      }
      return this.usernameShowError ? error : "";
    }
  },
  methods: {
    clearFocus,
    fetchTranslations(key) {
      if (
        this.translations &&
        Object.prototype.hasOwnProperty.call(this.translations, key)
      ) {
        return this.translations[key];
      }
      return "";
    },
    onTextUsernameChange() {
      this.usernameHasBeenClicked = true;
    },
    onFocusUsername() {
      this.usernameShowError = false;
    },
    onBlurUsername() {
      this.usernameShowError = true;
      if (platform.platform === "ios") {
        this.changeKeyboardDistance(IOS_TEXT_INPUT_KEYBOARD_OFFSET_DEFAULT);
      }
    },
    onBlurPassword() {
      if (platform.platform === "ios") {
        this.changeKeyboardDistance(IOS_TEXT_INPUT_KEYBOARD_OFFSET);
      }
    },
    focusPassword() {
      this.$refs.password.focus();
    },
    login() {
      const isFormValid = !this.$v.$invalid;
      helpers.core.forceHideKeyboard();
      if (isFormValid) {
        const message = {
          username: this.username,
          password: this.password
        };
        this.$emit("login", message);
      }
    },
    changeKeyboardDistance(value) {
        const keyboardManager = IQKeyboardManager.sharedManager(); // eslint-disable-line
      keyboardManager.keyboardDistanceFromTextField = value;
    }
  },
  validations() {
    return {
      password: {
        required,
        minLength: minLength(this.minPasswordLength),
        noWhiteSpace
      },
      username: {
        required,
        minLength: minLength(this.minUsernameLength),
        maxLength: maxLength(this.maxUsernameLength),
        noWhiteSpace
      }
    };
  }
};
</script>

<style scoped lang="scss">
@import "~/assets/styles/variables.scss";
.signin-by-user-start {
  color: $color-black;
  height: 100%;
  padding-left: $padding-xl;
  padding-right: $padding-xl;
  vertical-align: top;
  &__title {
    margin-bottom: $margin-xl;
    color: $color-black;
    vertical-align: bottom;
  }
  &__password {
    margin-bottom: $margin-xl;
  }
  &__username {
    margin-bottom: $margin-xl;
  }
  &__hint {
    margin-top: $margin-m;
    color: $color-grey;
    text-align: center;
  }
  &__button {
    margin-bottom: $margin-xl;
  }
}
</style>
