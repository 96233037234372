export default function() {
  return {
    categories: {
      icons: {
        id: -1,
        name: "Generic",
        defaultSrc: "icons/Logos/ic_smarthomes_ezlo_56.svg",
        green: "icons/Logos/ic_smarthomes_ezlo_green_56.svg",
        white: "icons/Logos/ic_smarthomes_ezlo_white_56.svg",
        warning: "icons/Logos/ic_smarthomes_ezlo_warning_56.svg"
      },
      interface: {
        id: 1,
        name: "Interface",
        icons: {},
        subcategory: {},
        states: [{ name: "on" }]
      },
      dimmable_light: {
        id: 2,
        name: "Dimmable Light",
        icons: {
          defaultSrc: "icons/Devices/ic_device_dimmers-switches_dimmer_56.svg",
          green: "icons/Devices/ic_device_dimmers-switches_dimmer_green_56.svg",
          white: "icons/Devices/ic_device_dimmers-switches_dimmer_white_56.svg",
          warning:
            "icons/Devices/ic_device_dimmers-switches_dimmer_warning_56.svg",
          ui7: {
            on: "icons/UI7/dimmable_light_default.png",
            off: "icons/UI7/dimmable_light_off.png"
          }
        },
        states: [{ name: "on" }, { name: "off" }],
        subcategory: {
          dimmable_bulb: {
            subId: 1,
            subName: "Bulb"
          },
          dimmable_plugged: {
            subId: 2,
            subName: "Plugged"
          },
          dimmable_in_wall: {
            subId: 3,
            subName: "In Wall"
          },
          dimmable_colored: {
            subId: 4,
            subName: "RGB"
          }
        }
      },
      switch: {
        id: 3,
        name: "Switch",
        icons: {
          defaultSrc:
            "icons/Devices/ic_device_dimmers-switches_switcher_56.svg",
          green:
            "icons/Devices/ic_device_dimmers-switches_switcher_green_56.svg",
          white:
            "icons/Devices/ic_device_dimmers-switches_switcher_white_56.svg",
          warning:
            "icons/Devices/ic_device_dimmers-switches_switcher_warning_56.svg",
          ui7: {
            on: "icons/UI7/switch_on.png",
            off: "icons/UI7/switch_off.png"
          }
        },
        states: [{ name: "on" }, { name: "off" }],
        subcategory: {
          interior_plugin: {
            subId: 1,
            subName: "Interior",
            icons: {
              defaultSrc:
                "icons/Devices/ic_device_dimmers-switches_switcher_56.svg",
              green:
                "icons/Devices/ic_device_dimmers-switches_switcher_green_56.svg",
              white:
                "icons/Devices/ic_device_dimmers-switches_switcher_white_56.svg",
              warning:
                "icons/Devices/ic_device_dimmers-switches_switcher_warning_56.svg"
            }
          },
          exterior_plugin: {
            subId: 2,
            subName: "Exterior"
          },
          in_wall: {
            subId: 3,
            subName: "In Wall",
            icons: {
              defaultSrc:
                "icons/Devices/ic_device_dimmers-switches_switcher_56.svg",
              green:
                "icons/Devices/ic_device_dimmers-switches_switcher_green_56.svg",
              white:
                "icons/Devices/ic_device_dimmers-switches_switcher_white_56.svg",
              warning:
                "icons/Devices/ic_device_dimmers-switches_switcher_warning_56.svg",
              ui7: {
                on: "icons/UI7/wall_switch_config.png",
                off: "icons/UI7/wall_switch_no_config.png"
              }
            }
          },
          refrigerator: {
            subId: 4,
            subName: "Refrigerator"
          },
          garage_door: {
            subId: 5,
            subName: "Garage Door"
          },
          doorbell: {
            subId: 6,
            subName: "Doorbell",
            icons: {
              defaultSrc:
                "icons/Devices/ic_device_dimmers-switches_switcher_56.svg",
              green:
                "icons/Devices/ic_device_dimmers-switches_switcher_green_56.svg",
              white:
                "icons/Devices/ic_device_dimmers-switches_switcher_white_56.svg",
              warning:
                "icons/Devices/ic_device_dimmers-switches_switcher_warning_56.svg",
              ui7: {
                on: "icons/UI7/doorbell_active.png",
                off: "icons/UI7/doorbell_static.png"
              }
            }
          },
          valve: {
            subId: 7,
            subName: "Valve",
            //missing new icons
            icons: {
              defaultSrc:
                "icons/Devices/ic_device_dimmers-switches_switcher_56.svg",
              green:
                "icons/Devices/ic_device_dimmers-switches_switcher_green_56.svg",
              white:
                "icons/Devices/ic_device_dimmers-switches_switcher_white_56.svg",
              warning:
                "icons/Devices/ic_device_dimmers-switches_switcher_warning_56.svg",
              ui7: {
                on: "icons/UI7/wv-01_opened.png",
                off: "icons/UI7/wv-01_closed.png"
              }
            }
          },
          relay: {
            subId: 8,
            subName: "Relay",
            //missing new icons
            icons: {
              defaultSrc:
                "icons/Devices/ic_device_dimmers-switches_switcher_56.svg",
              green:
                "icons/Devices/ic_device_dimmers-switches_switcher_green_56.svg",
              white:
                "icons/Devices/ic_device_dimmers-switches_switcher_white_56.svg",
              warning:
                "icons/Devices/ic_device_dimmers-switches_switcher_warning_56.svg",
              ui7: {
                on: "icons/UI7/relay_active.png",
                off: "icons/UI7/relay_default.png"
              }
            }
          }
        }
      },
      security_sensor: {
        id: 4,
        name: "Security Sensor	",
        icons: {},
        states: [{ name: "armed" }, { name: "disarmed" }, { name: "tripped" }],
        subcategory: {
          door: {
            subId: 1,
            subName: "Door Sensor",
            icons: {
              defaultSrc: "icons/Devices/ic_device_sensor_door-sensor_56.svg",
              green: "icons/Devices/ic_device_sensor_door-sensor_green_56.svg",
              white: "icons/Devices/ic_device_sensor_door-sensor_white_56.svg",
              warning:
                "icons/Devices/ic_device_sensor_door-sensor_warning_56.svg",
              ui7: {
                armed: "icons/UI7/door_window_default.png",
                disarmed: "icons/UI7/door_window_untripped.png",
                tripped: "icons/UI7/door_window_tripped.png"
              }
            }
          },
          leak: {
            subId: 2,
            subName: "Leak Sensor",
            icons: {
              defaultSrc: "icons/Devices/ic_device_sensor_leak_56.svg",
              green: "icons/Devices/ic_device_sensor_leak_green_56.svg",
              white: "icons/Devices/ic_device_sensor_leak_white_56.svg",
              warning: "icons/Devices/ic_device_sensor_leak_warning_56.svg",
              ui7: "icons/UI7/temp_leak_default.png"
            }
          },
          motion: {
            subId: 3,
            subName: "Motion Sensor",
            icons: {
              defaultSrc: "icons/Devices/ic_device_sensor_motion_56.svg",
              green: "icons/Devices/ic_device_sensor_motion_green_56.svg",
              white: "icons/Devices/ic_device_sensor_motion_white_56.svg",
              warning: "icons/Devices/ic_device_sensor_motion_warning_56.svg",
              ui7: {
                armed: "icons/UI7/motion_sensor_untripped.png",
                disarmed: "icons/UI7/security_sensor_default.png",
                tripped: "icons/UI7/motion_sensor_tripped.png"
              }
            }
          },
          smoke: {
            subId: 4,
            subName: "Smoke Sensor",
            icons: {
              defaultSrc: "icons/Devices/ic_device_sensor_door-sensor_56.svg",
              green: "icons/Devices/ic_device_sensor_door-sensor_green_56.svg",
              white: "icons/Devices/ic_device_sensor_door-sensor_white_56.svg",
              warning:
                "icons/Devices/ic_device_sensor_door-sensor_warning_56.svg",
              ui7: {
                armed: "icons/UI7/smoke_sensor_untripped.png",
                disarmed: "icons/UI7/smoke_sensor_default.png",
                tripped: "icons/UI7/smoke_sensor_tripped.png"
              }
            }
          },
          co: {
            subId: 5,
            subName: "CO Sensor",
            icons: {
              defaultSrc: "icons/Devices/ic_device_sensor_door-sensor_56.svg",
              green: "icons/Devices/ic_device_sensor_door-sensor_green_56.svg",
              white: "icons/Devices/ic_device_sensor_door-sensor_white_56.svg",
              warning:
                "icons/Devices/ic_device_sensor_door-sensor_warning_56.svg",
              ui7: {
                armed: "icons/UI7/co_sensor_untripped.png",
                disarmed: "icons/UI7/co_sensor_untripped.png",
                tripped: "icons/UI7/co_sensor_tripped.png"
              }
            }
          },
          glass: {
            subId: 6,
            subName: "	Glass Break Sensor",
            icons: {
              defaultSrc: "icons/Devices/ic_device_sensor_door-sensor_56.svg",
              green: "icons/Devices/ic_device_sensor_door-sensor_green_56.svg",
              white: "icons/Devices/ic_device_sensor_door-sensor_white_56.svg",
              warning:
                "icons/Devices/ic_device_sensor_door-sensor_warning_56.svg",
              ui7: {
                armed: "icons/UI7/glass_break_sensor_untripped.png",
                disarmed: "icons/UI7/glass_break_sensor_untripped.png",
                tripped: "icons/UI7/glass_break_sensor_tripped.png"
              }
            }
          },
          co2: {
            subId: 7,
            subName: "CO2 Sensor"
          },
          gas: {
            subId: 8,
            subName: "Gas Sensor"
          },
          heat: {
            subId: 9,
            subName: "Heat Sensor"
          }
        }
      },
      hvac: {
        id: 5,
        name: "HVAC",
        icons: {
          defaultSrc: "icons/Devices/ic_device_sensor_temperature_56.svg",
          green: "icons/Devices/ic_device_sensor_temperature_green_56.svg",
          white: "icons/Devices/ic_device_sensor_temperature_white_56.svg",
          warning: "icons/Devices/ic_device_sensor_temperature_warning_56.svg",
          ui7: "icons/UI7/temperature_sensor_default.png"
        },
        states: [
          { name: "off" },
          { name: "cool" },
          { name: "heat" },
          { name: "auto" }
        ],
        subcategory: {
          hvac: {
            subId: 1,
            subName: "HVAC",
            icons: {
              defaultSrc: "icons/Devices/ic_device_sensor_temperature_56.svg",
              green: "icons/Devices/ic_device_sensor_temperature_green_56.svg",
              white: "icons/Devices/ic_device_sensor_temperature_white_56.svg",
              warning:
                "icons/Devices/ic_device_sensor_temperature_warning_56.svg",
              ui7: {
                off: "icons/UI7/thermostat_device_default.png",
                cool: "icons/UI7/thermostat_mode_cooling.png",
                heat: "icons/UI7/thermostat_mode_heating.png",
                auto: "icons/UI7/thermostat_mode_auto.png"
              }
            }
          },
          heater: {
            subId: 2,
            subName: "Heater"
          },
          custom_hvac: {
            subId: 3,
            subName: "Custom HVAC"
          }
        }
      },
      camera: {
        id: 6,
        name: "Camera",
        icons: {
          defaultSrc: "icons/Devices/ic_category_cameras_56.svg",
          green: "icons/Devices/ic_category_cameras_green_56.svg",
          white: "icons/Devices/ic_category_cameras_white_56.svg",
          warning: "icons/Devices/ic_category_cameras_warning_56.svg",
          ui7: "icons/UI7/camera_default.png"
        },
        states: [{ name: "on" }],
        subcategory: {
          indoor_cam: {
            subId: 1,
            subName: "Indoor"
          },
          outdoor_cam: {
            subId: 2,
            subName: "Outdoor"
          },
          doorbell_cam: {
            subId: 3,
            subName: "Doorbell"
          }
        }
      },
      door_lock: {
        id: 7,
        name: "Door Lock",
        icons: {
          ui7: {
            locked: "icons/UI7/door_lock_locked.png",
            unlocked: "icons/UI7/door_lock_default.png"
          }
        },
        states: [{ name: "locked" }, { name: "unlocked" }],
        subcategory: {}
      },
      window_cov: {
        id: 8,
        name: "Window Covering",
        icons: {
          defaultSrc: "icons/Devices/ic_device_misc_blinds_56.svg",
          green: "icons/Devices/ic_device_misc_blinds_green_56.svg",
          white: "icons/Devices/ic_device_misc_blinds_white_56.svg",
          warning: "icons/Devices/ic_device_misc_blinds_warning_56.svg",
          ui7: {
            on: "icons/UI7/window_covering_default.png"
          }
        },
        states: [{ name: "on" }],
        subcategory: {
          window_cov: {
            subId: 1,
            subName: "Window Covering",
            icons: {
              defaultSrc: "icons/Devices/ic_device_misc_blinds_56.svg",
              green: "icons/Devices/ic_device_misc_blinds_green_56.svg",
              white: "icons/Devices/ic_device_misc_blinds_white_56.svg",
              warning: "icons/Devices/ic_device_misc_blinds_warning_56.svg",
              1: {
                defaultSrc: "icons/Devices/ic_device_misc_blinds-1_56.svg",
                green: "icons/Devices/ic_device_misc_blinds-1_green_56.svg",
                white: "icons/Devices/ic_device_misc_blinds-1_white_56.svg",
                warning: "icons/Devices/ic_device_misc_blinds-1_warning_56.svg"
              },
              2: {
                defaultSrc: "icons/Devices/ic_device_misc_blinds-2_56.svg",
                green: "icons/Devices/ic_device_misc_blinds-2_green_56.svg",
                white: "icons/Devices/ic_device_misc_blinds-2_white_56.svg",
                warning: "icons/Devices/ic_device_misc_blinds-2_warning_56.svg"
              },
              3: {
                defaultSrc: "icons/Devices/ic_device_misc_blinds-3_56.svg",
                green: "icons/Devices/ic_device_misc_blinds-3_green_56.svg",
                white: "icons/Devices/ic_device_misc_blinds-3_white_56.svg",
                warning: "icons/Devices/ic_device_misc_blinds-3_warning_56.svg"
              },
              ui7: {
                on: "icons/UI7/window_covering_default.png"
              }
            }
          },
          zrtsi: {
            subId: 2,
            subName: "ZRTSI"
          }
        }
      },
      remote_control: {
        id: 9,
        name: "Remote Control",
        icons: {},
        states: [{ name: "on" }],
        subcategory: {}
      },
      ir_tx: {
        id: 10,
        name: "IR Transmitter",
        icons: {},
        states: [{ name: "on" }],
        subcategory: {
          irt: {
            subId: 1,
            subName: "IR Transmitter"
          },
          usbuirt: {
            subId: 2,
            subName: "USB UIRT"
          }
        }
      },
      generic_io: {
        id: 11,
        name: "Generic I/O",
        icons: {},
        states: [{ name: "on" }],
        subcategory: {
          generic_io: {
            subId: 1,
            subName: "Generic I/O"
          },
          repeater: {
            subId: 2,
            subName: "Repeater"
          }
        }
      },
      generic_sensor: {
        id: 12,
        name: "Generic Sensor",
        icons: {
          defaultSrc: "icons/Devices/ic_device_sensor_temperature_56.svg",
          green: "icons/Devices/ic_device_sensor_temperature_green_56.svg",
          white: "icons/Devices/ic_device_sensor_temperature_white_56.svg",
          warning: "icons/Devices/ic_device_sensor_temperature_warning_56.svg",
          ui7: "icons/UI7/zwave_default.png"
        },
        states: [{ name: "on" }],
        subcategory: {}
      },
      serial_port: {
        id: 13,
        name: "Serial Port",
        icons: {},
        states: [{ name: "on" }],
        subcategory: {}
      },
      scene_controller: {
        id: 14,
        name: "Scene Controller",
        icons: {},
        states: [{ name: "on" }],
        subcategory: {}
      },
      av: {
        id: 15,
        name: "A/V",
        icons: {},
        states: [{ name: "on" }],
        subcategory: {}
      },
      humidity: {
        id: 16,
        name: "Humidity Sensor",
        icons: {
          defaultSrc: "icons/Devices/ic_device_sensor_humidity_56.svg",
          green: "icons/Devices/ic_device_sensor_humidity_green_56.svg",
          white: "icons/Devices/ic_device_sensor_humidity_white_56.svg",
          warning: "icons/Devices/ic_device_sensor_humidity_warning_56.svg",
          ui7: "icons/UI7/humidity_sensor_default.png"
        },
        states: [{ name: "on" }],
        subcategory: {}
      },
      temperature: {
        id: 17,
        name: "Temperature Sensor	",
        icons: {
          defaultSrc: "icons/Devices/ic_device_sensor_temperature_56.svg",
          green: "icons/Devices/ic_device_sensor_temperature_green_56.svg",
          white: "icons/Devices/ic_device_sensor_temperature_white_56.svg",
          warning: "icons/Devices/ic_device_sensor_temperature_warning_56.svg",
          ui7: "icons/UI7/temperature_sensor_default.png"
        },
        states: [{ name: "on" }],
        subcategory: {}
      },
      light_sensor: {
        id: 18,
        name: "Light Sensor",
        icons: {
          defaultSrc: "icons/Devices/ic_device_sensor_light_56.svg",
          green: "icons/Devices/ic_device_sensor_light_green_56.svg",
          white: "icons/Devices/ic_device_sensor_light_white_56.svg",
          warning: "icons/Devices/ic_device_sensor_light_warning_56.svg",
          ui7: "icons/UI7/light_sensor_default.png"
        },
        states: [{ name: "on" }],
        subcategory: {}
      },
      zwave_int: {
        id: 19,
        name: "Z-Wave Interface",
        icons: {},
        states: [{ name: "on" }],
        subcategory: {}
      },
      insteon_int: {
        id: 20,
        name: "Insteon Interface",
        icons: {},
        states: [{ name: "on" }],
        subcategory: {}
      },
      power_meter: {
        id: 21,
        name: "Power Meter",
        icons: {
          icons: {
            ui7: "icons/UI7/meter_default.png"
          }
        },
        states: [{ name: "on" }],
        subcategory: {}
      },
      alarm_panel: {
        id: 22,
        name: "Alarm Panel",
        icons: {},
        states: [{ name: "on" }],
        subcategory: {}
      },
      alarm_partition: {
        id: 23,
        name: "Alarm Partition",
        icons: {},
        states: [{ name: "on" }],
        subcategory: {}
      },
      siren: {
        id: 24,
        name: "Siren",
        icons: {
          ui7: {
            on: "icons/UI7/siren_on.png",
            off: "icons/UI7/siren_off.png"
          }
        },
        states: [{ name: "on" }, { name: "off" }],
        subcategory: {}
      },
      weather: {
        id: 25,
        name: "Weather",
        icons: {},
        states: [{ name: "on" }],
        subcategory: {}
      },
      philips_controller: {
        id: 26,
        name: "Philips Controller",
        icons: {},
        states: [{ name: "on" }, { name: "off" }],
        subcategory: {}
      },
      appliance: {
        id: 27,
        name: "Appliance",
        icons: {},
        states: [{ name: "on" }],
        subcategory: {}
      },
      uv_sensor: {
        id: 28,
        name: "UV Sensor",
        icons: {
          defaultSrc: "icons/Devices/ic_device_sensor_uv_56.svg",
          green: "icons/Devices/ic_device_sensor_uv_green_56.svg",
          white: "icons/Devices/ic_device_sensor_uv_white_56.svg",
          warning: "icons/Devices/ic_device_sensor_uv_warning_56.svg",
          ui7: "icons/UI7/uv_sensor_default.png"
        },
        states: [{ name: "on" }],
        subcategory: {}
      },
      mouse_trap: {
        id: 29,
        name: "Mouse Trap",
        icons: {
          ui7: {
            on: "icons/UI7/mouse_trap_tripped.png",
            off: "icons/UI7/mouse_trap_untripped.png"
          }
        },
        states: [{ name: "on" }, { name: "off" }],
        subcategory: {}
      },
      doorbell: {
        id: 30,
        name: "Doorbell",
        icons: {},
        states: [{ name: "on" }],
        subcategory: {}
      },
      keypad: {
        id: 31,
        name: "	Keypad",
        icons: {},
        states: [{ name: "on" }],
        subcategory: {}
      },
      garage_door: {
        id: 32,
        name: "Garage Door",
        icons: {
          ui7: {
            on: "icons/UI7/garage_door_open.png",
            off: "icons/UI7/garage_door_closed.png"
          }
        },
        states: [{ name: "open" }, { name: "closed" }],
        subcategory: {}
      },
      flow_meter: {
        id: 33,
        name: "Flow meter",
        icons: {
          ui7: "icons/UI7/meter_default.png"
        },
        states: [{ name: "on" }],
        subcategory: {}
      },
      voltage_sensor: {
        id: 34,
        name: "Voltage Sensor",
        icons: {},
        states: [{ name: "on" }],
        subcategory: {}
      },
      state_sensor: {
        id: 35,
        name: "State Sensor",
        icons: {
          defaultSrc: "icons/Devices/ic_device_sensor_door-sensor_56.svg",
          green: "icons/Devices/ic_device_sensor_door-sensor_green_56.svg",
          white: "icons/Devices/ic_device_sensor_door-sensor_white_56.svg",
          warning: "icons/Devices/ic_device_sensor_door-sensor_warning_56.svg",
          ui7: {
            armed: "icons/UI7/door_window_default.png",
            disarmed: "icons/UI7/door_window_untripped.png",
            tripped: "icons/UI7/door_window_tripped.png"
          }
        },
        states: [{ name: "armed" }, { name: "disarmed" }, { name: "tripped" }],
        subcategory: {
          light: {
            subId: 1,
            subName: "Light"
          },
          rain: {
            subId: 2,
            subName: "Rain"
          },
          moisture: {
            subId: 3,
            subName: "Moisture"
          },
          freeze: {
            subId: 4,
            subName: "Freeze",
            icons: {
              defaultSrc: "icons/Devices/ic_device_sensor_door-sensor_56.svg",
              green: "icons/Devices/ic_device_sensor_door-sensor_green_56.svg",
              white: "icons/Devices/ic_device_sensor_door-sensor_white_56.svg",
              warning:
                "icons/Devices/ic_device_sensor_door-sensor_warning_56.svg",
              ui7: {
                armed: "icons/UI7/freeze_sensor_default.png",
                disarmed: "icons/UI7/freeze_sensor_untripped.png",
                tripped: "icons/UI7/freeze_sensor_tripped.png"
              }
            }
          },
          power: {
            subId: 5,
            subName: "Power",
            icons: {
              defaultSrc:
                "icons/Devices/ic_device_sensor_energy-consumption_56.svg",
              green:
                "icons/Devices/ic_device_sensor_energy-consumption_green_56.svg",
              white:
                "icons/Devices/ic_device_sensor_energy-consumption_white_56.svg",
              warning:
                "icons/Devices/ic_device_sensor_energy-consumption_warning_56.svg"
            }
          }
        }
      },
      level_sensor: {
        id: 36,
        name: "Level Sensor",
        icons: {
          ui7: "icons/UI7/meter_on.png"
        },
        states: [{ name: "on" }],
        subcategory: {
          co2: {
            subId: 1,
            subName: "CO2"
          },
          co: {
            subId: 2,
            subName: "CO"
          },
          current: {
            subId: 3,
            subName: "Current"
          },
          velocity: {
            subId: 4,
            subName: "Velocity"
          },
          capacity: {
            subId: 5,
            subName: "Capacity"
          },
          water: {
            subId: 6,
            subName: "Water"
          },
          particulate_matter: {
            subId: 7,
            subName: "Particulate Matter"
          },
          frequency: {
            subId: 8,
            subName: "Frequency"
          },
          health: {
            subId: 9,
            subName: "Health"
          },
          modulation: {
            subId: 10,
            subName: "Modulation"
          },
          smoke: {
            subId: 11,
            subName: "Smoke"
          },
          soil: {
            subId: 12,
            subName: "Soil"
          },
          moisture: {
            subId: 13,
            subName: "Moisture"
          },
          air_pollution: {
            subId: 14,
            subName: "Air Pollution"
          },
          electricity: {
            subId: 15,
            subName: "Electricity"
          },
          sound: {
            subId: 16,
            subName: "Sound"
          },
          navigation: {
            subId: 17,
            subName: "Navigation"
          },
          seismicity: {
            subId: 18,
            subName: "Seismicity"
          },
          time: {
            subId: 19,
            subName: "Time"
          }
        }
      },
      clock: {
        id: 37,
        name: "Clock",
        icons: {},
        states: [{ name: "on" }],
        subcategory: {}
      },
      googleHome: {
        id: "googleHome",
        name: "Google Home",
        icons: {
          defaultSrc: "images/SmartHomes/googleHome_56.png",
          green: "images/SmartHomes/googleHome_56.png",
          white: "images/SmartHomes/googleHome_56.png",
          warning: "images/SmartHomes/googleHome_56.png"
        },
        states: [{ name: "on" }],
        subcategory: {}
      },
      amazonAlexa: {
        id: "amazonAlexa",
        name: "Amazon Alexa",
        icons: {
          defaultSrc: "images/SmartHomes/amazonAlexa_56.png",
          green: "images/SmartHomes/amazonAlexa_56.png",
          white: "images/SmartHomes/amazonAlexa_56.png",
          warning: "images/SmartHomes/amazonAlexa_56.png"
        },
        states: [{ name: "on" }],
        subcategory: {}
      }
    }
  };
}
