import moment from "moment";

export const common = () => {
  return {
    ratio: 0,
    monthToDate: 0,
    mountly: 0
  };
};

export default function() {
  return {
    socketConnection: false,
    activeMode: 0,
    modes: ["live", "day", "week", "month"],
    selectedDates: {
      live: {
        start: null,
        end: null
      },
      day: {
        start: null,
        end: null
      },
      week: {
        start: null,
        end: null
      },
      month: {
        start: null,
        end: null
      }
    },
    processing: false,
    filteredDevices: [],
    current: {
      scene: "",
      room: "livingRoom"
    },
    currentGoal: {},
    goals: [],
    colors: [
      "#3E46AE",
      "#8C40C8",
      "#FE5C15",
      "#FCB20D",
      "#50C1D6",
      "#000000",
      "#511E7F",
      "#A4190E",
      "#C73115",
      "#0E6E87",
      "#20297F",
      "#7538A9",
      "#E53020",
      "#E97F21",
      "#3EA7BF",
      "#050E56",
      "#632B94",
      "#CD2015",
      "#DC511A",
      "#1A8FAC"
    ],
    devices: [],
    latestLiveDataValue: 0,
    consumption: {},
    deviceGroups: [
      {
        groupId: "1F8D7089",
        groupTitle: "Living room",
        devices: [
          {
            id: "Z396F186A",
            title: "Smart Energy Switch",
            icons: {
              defaultSrc:
                "icons/Devices/ic_device_dimmers-switches_switcher_56.svg"
            },
            selected: false
          },
          {
            id: "Z8A18A7BD",
            title: "Smart Dimmer 6",
            icons: {
              defaultSrc:
                "icons/Devices/ic_device_dimmers-switches_switcher_56.svg"
            },
            selected: false
          },
          {
            id: "Z16762A6F",
            title: "Wall Plug",
            icons: {
              defaultSrc:
                "icons/Devices/ic_device_dimmers-switches_switcher_56.svg"
            },
            selected: false
          }
        ]
      },
      {
        groupId: "ZA297A0BE",
        groupTitle: "No room",
        devices: [
          {
            id: "9999999",
            title: "Wall Plug ghost",
            icons: {
              defaultSrc: "icons/Controls/ic_controls_more_56.svg"
            },
            selected: false
          }
        ]
      }
    ]
  };
}
