<template>
  <MainPage>
    <ScrollView @loaded="scrollLoaded" ref="scroll" class="dash-template">
      <GridLayout
        height="100%"
        rows="*, auto"
        columns="*"
        class="dash-template"
      >
        <GridLayout
          row="0"
          col="0"
          rows="auto, * ,auto"
          columns="*"
          class="dash-template"
        >
          <NavigationHeader
            row="0"
            class="dash-template__navigation"
            icon1Src="icons/Controls/ic_controls_arrow-left_56.svg"
            :icon2Src="iconSettings"
            textSize="h3"
            :text="
              editMode
                ? translations.textTitleEditDashboard
                : translations.textTitle
            "
            @iconTap1="handleBack"
            @iconTap2="navigationOnTap"
          />
          <ScrollView row="1">
            <StackLayout class="dash-template__scroll">
              <Separator size="xl" />
              <TemplateWrapRenderer
                v-if="isSingleTemplate"
                :template="getInitialTemplate"
                :backgroundImage="getDashboardBgImage"
                :designProperties="getDesignProperties"
              />
              <TabUI
                v-else
                class="dash-template__tabs"
                :initialTab="getInitialTabIndex"
                :items="getTabItems"
                :isHeaderBordered="true"
                @tap="handleTapTabsHeader"
              />
            </StackLayout>
          </ScrollView>
        </GridLayout>
        <StackLayout row="1" col="0" class="dash-template__button">
          <ButtonFilled
            :loading="getDashboardLoadingStatus"
            :disabled="isDisabled"
            :text="
              editMode ? translations.textBtnEdit : translations.textBtnTemplate
            "
            @tap="
              editMode ? handleUpdateDashboard() : handleCreateNewDashboard()
            "
          />
        </StackLayout>

        <BottomUpDialog
          @beforeShown="handleBeforeShownDialogChangeName"
          @beforeHidden="handleBeforeHiddenDialogChangeName"
          class="dialog"
          ref="dialog"
          row="0"
          col="0"
        >
          <component
            ref="component"
            :is="dialog.component"
            v-bind="dialog.data"
            @delete="handleDeleteDashboard"
            @accept="handleUseOneTemplate"
            @cancel="handleCancelUseOneTemplate"
            @close="closeDialog"
            @change="handleRename"
          />
        </BottomUpDialog>
      </GridLayout>
    </ScrollView>
  </MainPage>
</template>

<script>
import { isEqual } from "lodash";
import { createNamespacedHelpers } from "vuex";
import { locals } from "@/helpers/variables";
import {
  isEmpty,
  hasTileItem,
  isChanged,
  imageArchiveUrl
} from "@/helpers/utils";
const {
  mapGetters: mapUiGetters,
  mapActions: mapUiActions
} = createNamespacedHelpers("ui");
const {
  mapActions: mapDashboardActions,
  mapGetters: mapDashboardGetters
} = createNamespacedHelpers("dashboards");
const {
  mapActions: mapTemplateActions,
  mapGetters: mapTemplateGetters
} = createNamespacedHelpers("template");
const { mapGetters: mapThemesGetters } = createNamespacedHelpers("themes");
const { mapGetters: mapCatagoriesGetters } = createNamespacedHelpers(
  "categories"
);
const { mapGetters: mapDeviceGetters } = createNamespacedHelpers("devices");
export default {
  name: "DashboardTemplate",
  props: {
    dashboardId: {
      type: String,
      default: "1"
    },
    editMode: {
      type: Boolean,
      default: false
    },
    activeTab: {
      type: Number,
      default: 0
    },
    templateMode: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      initials: {
        isTemplateSingle: false
      },
      isTemplateSingle: false,
      isTemplateSinglePending: false,
      isTemplatesChanged: false,
      selectedTabItem: null,
      tileIndex: null,
      useAsDefault: false,
      dialog: {
        component: "",
        data: {}
      },
      activeTabMode: this.templateMode.mode,
      iconSettings: "icons/Controls/ic_controls_settings_56.svg",
      iconDelete: "icons/Controls/ic_controls_delete_orange_56.svg"
    };
  },
  created() {
    this.selectedTabItem = this.getTabItems[0];
  },
  mounted() {
    this.isTemplateSingle = this.getTemplateSingleStatus;
    this.initials.isTemplateSingle = this.isTemplateSingle;
    this.$root.$on("_tileTap", this.handleTapTile);
    this.$root.$on("_deleteTile", this.handleDeleteTile);
    this.$root.$on("_onTapSelectTemplate", this.handleSelectTemplate);
    this.$root.$on("_tileSettingsTileChangedSave", this.saveTileDesign);
    this.$root.$on("_changeTemplateMode", this.changeTemplateMode);
    this.useAsDefault = this.isUseAsDefault || false;
  },
  beforeDestroy() {
    this.handleTapTile && this.$root.$off("_tileTap");
    this.handleDeleteTile && this.$root.$off("_deleteTile");
    this.handleSelectTemplate && this.$root.$off("_onTapSelectTemplate");
    this.saveTileDesign && this.$root.$off("_tileSettingsTileChangedSave");
    this.changeTemplateMode && this.$root.$off("_changeTemplateMode");
    this.resetTemplatePortrait();
    this.resetTemplateLandscape();
  },
  watch: {
    isTemplateSingle(value) {
      this.isTemplateSinglePending = value;
    },
    getDashboardLoadingStatus(newValue) {
      this.dialog.data = {
        isFetching: newValue
      };
    },
    template(newValue, oldValue) {
      this.isTemplatesChanged = !isEqual(newValue, oldValue);
    },
    getTemplateLandscape(newValue, oldValue) {
      this.isTemplatesChanged = !isEqual(newValue, oldValue);
    },
    dashboardId() {
      this.isTemplateSingle = this.getTemplateSingleStatus;
      this.initials.isTemplateSingle = this.isTemplateSingle;
      this.useAsDefault = this.isUseAsDefault || false;
      this.$set(
        this,
        "activeTabMode",
        this.activeTab === 0 ? "portrait" : "landscape"
      );
    },
    getTilesCount(newValue) {
      if (newValue > 0 && !this.hasDashboard && !this.getTutorialApproved) {
        this.showTutorial();
      }
    },
    templateMode(value) {
      this.activeTabMode = value.mode;
    }
  },
  computed: {
    ...mapUiGetters(["hasError"]),
    ...mapTemplateGetters([
      "template",
      "getTemplateLandscape",
      "getTiles",
      "getTemplateBuffer",
      "getTileTypes"
    ]),
    ...mapDashboardGetters([
      "getDashboardChanged",
      "hasDashboardChanges",
      "hasDashboardSettingsChanges",
      "getDashboardLoadingStatus",
      "getDashboardSettingsChanged",
      "getDashboards",
      "getTutorialApproved",
      "hasDashboard"
    ]),
    ...mapCatagoriesGetters(["getCategoryStates"]),
    ...mapDeviceGetters(["getDeviceById"]),
    ...mapThemesGetters([
      "getThemeById",
      "getSelectedTheme",
      "getThemeOrDefault"
    ]),
    isDisabled() {
      return (
        !this.getDashboardChangedStatus &&
        (isEmpty(this.template) ||
          !isEmpty(this.template) ||
          !hasTileItem(this.template)) &&
        (isEmpty(this.getTemplateLandscape) ||
          !isEmpty(this.getTemplateLandscape) ||
          !hasTileItem(this.getTemplateLandscape))
      );
    },
    templateModeChanged() {
      return (
        this.getTemplateBuffer &&
        (!isEqual(this.template, this.getTemplateBuffer.template) ||
          !isEqual(
            this.getTemplateLandscape,
            this.getTemplateBuffer.templateLandscape
          ))
      );
    },
    getCurrentTemplateMode() {
      if (this.getTemplateBuffer) {
        return !isEmpty(this.getTemplateBuffer.template) &&
          !isEmpty(this.getTemplateBuffer.templateLandscape)
          ? "both"
          : isEmpty(this.getTemplateBuffer.template)
          ? "landscape"
          : "portrait";
      }
      return "";
    },
    orientation() {
      if (this.isSingleTemplate) {
        return !isEmpty(this.getDashboardTemplatePortrait)
          ? "portrait"
          : "landscape";
      }
      return this.getActiveTab || "portrait";
    },
    translations() {
      return this.$t("dashboardTemplate");
    },
    isSingleTemplate() {
      console.log("### isSingleTemplate", { templateMode: this.templateMode.mode,  getTemplateSingleStatus: this.getTemplateSingleStatus }); //eslint-disable-line
      if (this.templateMode.mode) {
        return this.templateMode.mode !== "both";
      } else {
        return this.getTemplateSingleStatus;
      }
      return this.templateMode.mode === "both"
        ? false
        : this.getTemplateSingleStatus;
    },
    isLandscape() {
      return (
        this.orientation === "landscape" ||
        (!isEmpty(this.getDashboardTemplateLandscape) && this.isSingleTemplate)
      );
    },
    getInitialTabIndex() {
      return this.activeTabMode && this.activeTabMode === "landscape" ? 1 : 0;
    },
    getTabItems() {
      return [
        {
          id: "portrait",
          header: {
            component: "TabHeader",
            details: {
              title: "Portrait",
              underlineColor: this.getTabActiveColor
            }
          },
          content: {
            component: "TemplateWrapRenderer",
            details: {
              template: this.getDashboardTemplatePortrait,
              text: this.translations.textAddTemplate,
              backgroundImage: this.getDashboardBgImage,
              designProperties: this.getDesignProperties
            }
          }
        },
        {
          id: "landscape",
          header: {
            component: "TabHeader",
            details: {
              title: "Landscape",
              underlineColor: this.getTabActiveColor
            }
          },
          content: {
            component: "TabLandscape",
            details: {
              template: this.getDashboardTemplateLandscape,
              text: this.translations.textAddTemplate,
              backgroundImage: this.getDashboardBgImage,
              designProperties: this.getDesignProperties
            }
          }
        }
      ];
    },
    getInitialTemplate() {
      return this.isLandscape
        ? this.getDashboardTemplateLandscape
        : this.getDashboardTemplatePortrait;
    },
    getDashboard() {
      return this.getDashboardChanged(this.dashboardId);
    },
    getDashboardChangedStatus() {
      return this.hasDashboardChanges !== null;
    },
    getDashboardName() {
      return this.getDashboard && this.getDashboard.name;
    },
    getDashboardTemplate() {
      return this.getDashboard.templates;
    },
    getDashboardTemplatePortrait() {
      return this.template;
    },
    useBackgroundImage() {
      const st = this.getDashboardSettingsChanged(this.dashboardId);
      if (st && st.theme && st.theme[this.orientation]) {
        const theme = this.getThemeOrDefault(
          st.theme[this.orientation].theme_id
        );
        return theme &&
          theme.data.dashboard &&
          theme.data.dashboard["default"].use_background_image === true
          ? theme.data.dashboard["default"].background_image
          : "";
      }
      return (st &&
        (st.general.use_background_image || st.general.useBackgroundImage)) ===
        true
        ? st && (st.general.background_image || st.general.backgroundImage)
        : "";
    },
    getDashboardBgImage() {
      const image = this.useBackgroundImage;
      return image ? imageArchiveUrl(image) : "";
    },
    getDesignProperties() {
      const st = this.getDashboardSettingsChanged(this.dashboardId);
      let designProperties = st && st.general;
      let theme = null;
      if (st && st.theme && st.theme[this.orientation]) {
        theme = this.getThemeOrDefault(st.theme[this.orientation].theme_id);
        designProperties = theme && theme.data.dashboard["default"];
      } else if (this.dashboardId === "1") {
        theme = this.getSelectedTheme;
        designProperties = theme && theme.data.dashboard["default"];
      }
      return designProperties;
    },
    getDashboardSettings() {
      return this.getDashboardSettingsChanged(this.dashboardId);
    },
    getDashboardTemplateLandscape() {
      return this.getTemplateLandscape;
    },
    getTemplateActiveColor() {
      return locals.colorPrimary;
    },
    getTemplateSingleStatus() {
      return (
        isEmpty(this.getDashboardTemplatePortrait) ||
        isEmpty(this.getDashboardTemplateLandscape)
      );
    },
    getTabActiveColor() {
      return locals.colorBlack;
    },
    dialogTranslations() {
      return {
        editName: this.translations.textEditNameDialog
      };
    },
    getSnackbarTexts() {
      return {
        duplicate: this.translations.textDuplicateSuccessful
      };
    },
    isUseAsDefault() {
      return this.getDashboard && this.getDashboard.isDefault;
    },
    selectedTab() {
      return (this.getActiveTab && this.getActiveTab.id) || "portrait";
    },
    getActiveTab() {
      return (this.selectedTabItem && this.selectedTabItem.id) || "portrait";
    },
    getTilesCount() {
      return this.getTiles({ orientation: this.orientation }).length || 0;
    }
  },
  methods: {
    ...mapUiActions(["saveSnackbarText"]),
    ...mapTemplateActions([
      "deleteTile",
      "resetTemplateSelected",
      "resetTemplatePortrait",
      "resetTemplateLandscape",
      "deleteAssociatedElement",
      "saveDesignProperties",
      "setDesignProperties"
    ]),
    ...mapDashboardActions([
      "renameDashboard",
      "cancelEditDashboard",
      "updateDashboardAsync",
      "removeDashboardAsync",
      "setNewDashboardTemplate",
      "createDuplicateDashItemAsync",
      "setAsDefaultDashboard",
      "setTutorialApproved",
      "dashboardsHasChanged"
    ]),
    handleBack() {
      this.navigateBack();
      this.editMode && this.cancelEditDashboard();
    },
    scrollLoaded() {
      if (
        this.$refs.scroll &&
        this.$refs.scroll.nativeView &&
        this.$refs.scroll.nativeView.ios
      ) {
        // for jumping fix of the dialogs with input for iOS
        // this code disables user interaction with the scollview
        // but dilaogs can scroll correctly
        this.$refs.scroll.nativeView.ios.panGestureRecognizer.minimumNumberOfTouches = 0;
        this.$refs.scroll.nativeView.ios.panGestureRecognizer.maximumNumberOfTouches = 0;
      }
    },
    handleCreateNewDashboard() {
      // save dashboard template to the correct orientation
      // if both orientations are created, proceed to naming page
      // template should be created here or in the action
      if (!this.editMode) {
        if (!isEmpty(this.template)) {
          this.setNewDashboardTemplate({
            orientation: "portrait",
            template: this.template
          });
        }
        if (!isEmpty(this.getTemplateLandscape)) {
          this.setNewDashboardTemplate({
            orientation: "landscape",
            template: this.getTemplateLandscape
          });
        }
        this.navigateTo("DashboardName");
      } else {
        this.resetTemplateSelected();
        this.navigateBack();
      }
    },
    showTutorial() {
      this.navigateTo("Tutorial", {
        props: {
          icon: "icons/Tutorial/Anim_Tips-Tap0.svg",
          title: "core.tapToCustomizeASpesificTile.title",
          description: "core.tapToCustomizeASpesificTile.description",
          btnTextAccept: "core.tapToCustomizeASpesificTile.btnTextAccept",
          warning: false,
          resultActionName: "tutorialApproved",
          callbackApproved: this.tutorialApproved
        }
      });
    },
    async handleUpdateDashboard() {
      await this.updateDashboardAsync({
        ...this.getDashboard,
        template_tiles: {
          portrait: this.template,
          landscape: this.getTemplateLandscape
        }
      });
      setTimeout(() => {
        this.saveSnackbarText(
          this.translations.textSnackbar.textUpdateSuccessful
        );
      }, 150);
      !this.hasError && this.handleBack();
    },
    handleRename(name) {
      this.renameDashboard({ id: this.dashboardId, name });
      this.closeDialog();
    },
    getCurrentTemplate() {
      return this.isLandscape
        ? this.getDashboardTemplateLandscape
        : this.getDashboardTemplatePortrait;
    },
    getTileByIndex(index) {
      const template = this.getCurrentTemplate();
      return (
        (template &&
          template.children &&
          template.children[index].children[0].children[0]) ||
        {}
      );
    },
    handleDeleteTile({ orientation, index }) {
      const tile = this.getTileByIndex(index);
      const { id, type } = tile.props;
      this.deleteAssociatedElement({
        id: id,
        type,
        isLandscape: this.isLandscape
      });
      return this.deleteTile({
        index,
        dashboardId: this.dashboardId,
        orientation: this.orientation
      });
    },
    isTileEmpty(index) {
      const tile = this.getTileByIndex(index);
      return !(tile.props && tile.props.type);
    },
    getTileDeviceId(index) {
      const tile = this.getTileByIndex(index);
      return tile.props && tile.props.id;
    },
    handleTapTile({ orientation, index }) {
      this.tileIndex = index;
      const props = {
        index: this.tileIndex,
        isLandscape: this.isLandscape,
        dashboardId: this.dashboardId
      };
      if (this.isTileEmpty(index)) {
        this.navigateTo("TileTypes", {
          props,
          backstackVisible: false
        });
      } else {
        let tile = this.getTileByIndex(index);
        let tileId = tile?.props?.id;
        let tileCategoryType = null;
        let tileSubcategoryType = null;
        let categoryStates = [];
        let props = {};
        if (tile?.props?.type === "device") {
          const deviceInfo = this.getDeviceById(tileId);
          tileCategoryType = deviceInfo ? deviceInfo.category : "";
          tileSubcategoryType = deviceInfo ? deviceInfo.subcategory : "";
          categoryStates = this.getCategoryStates(tileCategoryType);
        }
        this.navigateTo("TileSettings", {
          props: {
            dashboardId: this.dashboardId,
            orientation: this.orientation,
            tileState: (tile.props && tile.props.state) || "general",
            tile,
            save: true,
            tileIcon: tile.props?.src ? tile.props.src : "",
            tileLabel: tile.props?.title ? tile.props.title : "",
            tileType: tile?.props?.type,
            categories: categoryStates,
            category: tileCategoryType,
            subcategory: tileSubcategoryType
          },
          backstackVisible: true
        });
      }
    },
    async handleDeleteDashboard() {
      await this.removeDashboardAsync({ id: this.dashboardId });
      if (this.hasError) {
        return this.closeDialog();
      }
      this.navigateBack();
      this.closeDialog();
      setTimeout(() => {
        this.saveSnackbarText(
          this.translations.textSnackbar.textDeleteSuccessful
        );
      }, 150);
    },
    handleSelectTemplate({ orientation }) {
      console.log("--->> ### from DashboardTemplate/handleSelectTemplateLandscape to  DashboardTemplateType", event); //eslint-disable-line
      this.navigateTo("DashboardTemplateType", {
        props: {
          dashboardId: this.dashboardId,
          isEditMode: this.editMode,
          isLandscape: orientation === "landscape",
          templateMode: this.templateMode
        },
        backstackVisible: false
      });
    },
    handleTapTabsHeader(index, tabItem) {
      this.selectedTabItem = tabItem;
    },
    handleChangeTemplate(value) {
      this.isTemplateSinglePending = value;
      if (value) {
        return this.handleShowUseSingleTemplateConfirm();
      }
      this.isTemplateSingle = value;
    },
    handleUseOneTemplate() {
      this.isTemplateSingle = true;
      this.resetTemplateSelected();
      this.resetTemplateLandscape();
      this.closeDialog();
    },
    handleCancelUseOneTemplate() {
      this.isTemplateSingle = false;
      this.closeDialog();
    },
    handleShowUseSingleTemplateConfirm() {
      this.dialog = {
        component: "SingleTemplateConfirm"
      };
      this.showDialog();
    },
    navigationOnTap() {
      this.handleEditStyle();
    },
    showDeleteDashboardConfirm() {
      this.dialog = {
        component: "DeleteDashboardConfirm",
        data: {
          isFetching: this.getDashboardLoadingStatus
        }
      };
      this.showDialog();
    },
    showDialog() {
      const dialog = this.$refs.dialog;
      dialog && dialog.show();
    },
    closeDialog() {
      const dialog = this.$refs.dialog;
      dialog && dialog.hide();
    },
    showChangeNameDialog() {
      const dialog = this.$refs.dialog;
      this.dialog = {
        component: "ChangeNameEntity",
        data: {
          name: this.getDashboardName,
          titleText: this.dialogTranslations.editName.title,
          placeholderText: this.dialogTranslations.editName.placeholder,
          hintText: this.dialogTranslations.editName.hint,
          actionText: this.dialogTranslations.editName.btn,
          maxLength: 64
        }
      };
      this.showDialog();
    },
    handleBeforeShownDialogChangeName() {
      if (this.dialog.component === "ChangeNameEntity") {
        setTimeout(() => {
          const dialog = this.$refs.component;
          dialog && dialog.onShown();
        }, 25);
      }
    },
    handleBeforeHiddenDialogChangeName() {
      if (this.dialog.component === "ChangeNameEntity") {
        setTimeout(() => {
          const dialog = this.$refs.component;
          dialog && dialog.onHidden();
        }, 25);
      } else if (this.dialog.component === "SingleTemplateConfirm") {
        this.isTemplateSinglePending = this.isTemplateSingle;
      }
    },
    handleEditStyle() {
      console.log("### handleEditStyle"); // eslint-disable-line
      let designProperties = undefined;
      if (this.editMode) {
        const {
          use_background_image,
          useBackgroundImage = false,
          backgroundColor,
          background_image,
          backgroundImage,
          fontFamily,
          fontSize
        } = this.getDashboardSettings.general;
        designProperties = {
          use_background_image:
            use_background_image || useBackgroundImage || false,
          background_image: background_image || backgroundImage,
          background_color: backgroundColor,
          fontFamily,
          fontSize
        };
      }
      this.navigateTo("DashboardSettings", {
        props: {
          dashboardId: this.dashboardId,
          editMode: this.editMode,
          isLandscape: this.isLandscape,
          options: {
            orientation: this.orientation,
            designProperties
          }
        },
        backstackVisible: false
      });
    },
    async handleCreateDuplicate() {
      await this.createDuplicateDashItemAsync({
        dashboardId: this.dashboardId
      });
      if (this.hasError) {
        return;
      }
      this.navigateBack();
      setTimeout(() => {
        this.saveSnackbarText(this.getSnackbarTexts.duplicate);
      }, 150);
    },
    changeUseAsDefault(isDefault) {
      this.useAsDefault = isDefault;
      this.setAsDefaultDashboard({ id: this.dashboardId, isDefault });
    },
    saveTileDesign(design) {
      this.setDesignProperties({
        ...design,
        orientation: this.orientation,
        index: this.tileIndex
      });
      this.saveDesignProperties({
        orientation: this.orientation,
        index: this.tileIndex,
        dashboardId: this.dashboardId
      });
    },
    changeTemplateMode(modeItem) {
      this.dashboardsHasChanged(this.dashboardId);
      // from "both" mode to "portrait" or "landscape" mode
      if (this.getCurrentTemplateMode === "both") {
        if (modeItem.id === "portrait") {
          this.resetTemplateLandscape();
        } else {
          this.resetTemplatePortrait();
        }
      } else {
        let landscape = modeItem.id === "landscape";
        if (modeItem.id === "both") {
          landscape = isEmpty(this.getTemplateLandscape);
        }
        this.activeTabMode = landscape ? "landscape" : "portrait";
        this.navigateTo("DashboardTemplateType", {
          props: {
            isEditMode: this.editMode,
            isLandscape: landscape,
            templateMode: modeItem,
            resetMode: true
          },
          backstackVisible: false
        });
      }
    },
    tutorialApproved(result) {
      if (result && result.resultActionName === "tutorialApproved") {
        this.setTutorialApproved(true);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~/assets/styles/variables.scss";
.dash-template {
  height: 100%;
  color: $color-black;
  &__navigation {
    margin: 0 $s;
  }
  &__scroll {
    &__label {
      padding: $xl $lmx $xl $lmx;
    }
    &__name {
      padding-bottom: $s;
    }
  }
  &__button {
    padding: $m $xl $xl $xl;
  }
  &__tabs {
    /deep/ & .tab-ui__header {
      margin-bottom: $xs;
    }
  }
}
</style>
