import WS from "@/services/ws.service";

export default {
  changeDeviceRoom: ({ id, roomId }) =>
    WS.send("hub.device.room.set", {
      _id: id,
      roomId
    }),
  delete: id =>
    WS.send("hub.device.force_remove", {
      _id: id
    })
};
