import {
  SET_THEMES,
  SET_TEMP_THEME,
  SET_TEMP_CHANGED,
  RESET_THEMES,
  RESET_TEMP_THEME,
  ADD_THEME,
  REMOVE_THEME,
  SELECT_THEME
} from "./mutationsTypes";
import themeService from "@/services/theme.service";
import { HttpError } from "@/helpers/customErrors";
import { parseHttpResponse } from "@/helpers/responseParser";
import { uuidMock } from "@/helpers/utils";
// import { cloneDeep } from "lodash";

// const { VUE_APP_MODE } = process.env;

export default {
  // Async
  async fetchThemes({ commit, dispatch, getters, rootGetters }) {
    try {
      dispatch("ui/startFetching", "fetchThemes", { root: true });
      const authJwtToken = rootGetters["auth/getCloudJwtToken"];

      const response = await themeService.fetchThemes({
        authJwtToken
      });
      const { data: content, statusCode } = await parseHttpResponse(
        response,
        "json"
      );
      if (statusCode !== 200) {
        throw new HttpError("Api cloud error or other", statusCode);
      }

      const { status, complete, data } = content;
      if (status === 0 && complete === 1) {
        throw new HttpError(data.error_text, status);
      }
      //TODO THEMES parse data accordingly
      const { dashboard_themes } = data;
      commit(SET_THEMES, dashboard_themes);
    } catch (err) {
      console.log("fetchThemes ERROR:", err); // eslint-disable-line
      dispatch(
        "ui/emitError",
        {
          type: "errorAvailableEzloHubs",
          error: err.message,
          meta: "fethThemesAsync",
          code: err.code
        },
        {
          root: true
        }
      );
    } finally {
      dispatch("ui/stopFetching", "fetchThemes", { root: true });
    }
  },
  async createTheme(context, payload) {
    const { commit, dispatch, getters, rootGetters } = context;
    let result = null;
    try {
      dispatch("ui/startFetching", "createTheme", { root: true });
      const authJwtToken = rootGetters["auth/getCloudJwtToken"];
      // TODO THEMES creation flow helper needed USE state.tempState
      const options = {
        uuid: payload.uuid,
        name: payload.name,
        data: payload
      }; //TODO

      const response = await themeService.createTheme({
        authJwtToken,
        options
      });
      const { data: content, statusCode } = await parseHttpResponse(
        response,
        "json"
      );
      result = { content, statusCode };
      if (statusCode !== 200) {
        throw new HttpError("Api cloud error or other", statusCode);
      }
      const { status, complete, data } = content;
      if (status === 0 && complete === 1) {
        throw new HttpError(data.error_text, status);
      }
      await dispatch("fetchThemes");
      // if the new theme than, find uuid
      if (!options.uuid) {
        options.uuid = getters.getLastTheme.uuid;
      }
      await dispatch("selectTheme", { uuid: options.uuid });
    } catch (err) {
      // eslint-disable-next-line
      console.dir("createTheme ERROR:", err);
      dispatch(
        "ui/emitError",
        {
          error: err.message,
          meta: "createTheme",
          code: err.code
        },
        {
          root: true
        }
      );
    } finally {
      dispatch("ui/stopFetching", "createTheme", {
        root: true
      });
      return result;
    }
  },
  async updateTheme({ commit, dispatch, getters, rootGetters }, payload) {
    try {
      dispatch("ui/startFetching", "updateTheme", { root: true });
      const authJwtToken = rootGetters["auth/getCloudJwtToken"];
      const options = getters.getTempTheme; //TODO THEMES
      const response = await themeService.updateTheme({
        authJwtToken,
        options
      });

      const { data: content, statusCode } = await parseHttpResponse(
        response,
        "json"
      );
      if (statusCode !== 200) {
        throw new HttpError("Api cloud error or other", statusCode);
      }
      const { status, complete, data } = content;
      if (status === 0 && complete === 1) {
        throw new HttpError(data.error_text, status);
      }

      //TODO THEMES may add some more actions
      await dispatch("fetchThemes");
      await dispatch("selectTheme", { uuid: payload.uuid });
      // await commit(DASHBOARD_UPDATE);
      // await dispatch("cancelEditDashboard");
    } catch (err) {
      // eslint-disable-next-line
      console.log("updateTheme ERROR:", err); // eslint-disable-line
      dispatch(
        "ui/emitError",
        {
          error: err.message,
          meta: "updateTheme",
          code: err.code
        },
        {
          root: true
        }
      );
    } finally {
      dispatch("ui/stopFetching", "updateTheme", { root: true });
    }
  },
  async deleteTheme({ commit, dispatch, getters, rootGetters }, uuid) {
    try {
      dispatch("ui/startFetching", "deleteTheme", { root: true });

      const authJwtToken = rootGetters["auth/getCloudJwtToken"];
      const response = await themeService.deleteTheme({
        authJwtToken,
        options: { uuid }
      });
      const { data: content, statusCode } = await parseHttpResponse(
        response,
        "json"
      );
      if (statusCode !== 200) {
        throw new HttpError("Api cloud error or other", statusCode);
      }
      const { status, complete, data } = content;
      if (status === 0 && complete === 1) {
        throw new HttpError(data.error_text, status);
      }
      //TODO THEMES
      await dispatch("fetchThemes");
      // commit(DASHBOARD_DELETE);
    } catch (err) {
      // eslint-disable-next-line
      console.dir("deleteTheme ERROR:", err);
      dispatch(
        "ui/emitError",
        {
          error: err.message,
          meta: "deleteTheme",
          code: err.code
        },
        {
          root: true
        }
      );
    } finally {
      dispatch("ui/stopFetching", "deleteTheme", {
        root: true
      });
    }
  },
  // Sync
  resetThemes({ commit }) {
    commit(RESET_THEMES);
  },
  resetTempTheme({ commit }) {
    commit(RESET_TEMP_THEME);
  },
  startEditingTheme({ commit, getters }, theme) {
    let defaultTheme = {};
    if (theme === "light") {
      defaultTheme = getters.getDefaultThemes["light"];
    } else if (theme === "dark") {
      defaultTheme = getters.getDefaultThemes["dark"];
    } else {
      defaultTheme = getters.getThemeById(theme);
    }
    commit(SET_TEMP_THEME, defaultTheme);
  },
  setThemeChanged({ commit }, payload) {
    commit(SET_TEMP_CHANGED, payload);
  },
  async addTheme({ commit, dispatch }, payload) {
    if (!payload.uuid) {
      payload.uuid = uuidMock();
    }
    await commit(ADD_THEME, payload);
    dispatch("selectTheme", payload);
    return payload;
  },
  async removeTheme({ commit }, payload) {
    await commit(REMOVE_THEME, payload);
    return payload;
  },
  async selectTheme({ commit }, payload) {
    await commit(SELECT_THEME, payload);
    return payload;
  },
  async resetToDefaultTheme({ dispatch, getters }) {
    return await dispatch("selectTheme", {
      uuid: getters.getDefaultTheme.uuid
    });
  }
};
