// Core
import Vue from "@/vue";
import {
  SET_SCENES,
  RESET_SCENES,
  ADD_SELECTED_SCENE,
  REMOVE_SELECTED_SCENE,
  SET_SELECTED_SCENE,
  RESET_SELECTED_SCENE,
  SET_CLOUD_SCENES,
  START_LOADING,
  STOP_LOADING
} from "./mutationTypes";

function generateScene(scene, hub) {
  const {
    _id: id,
    enabled,
    group_id,
    is_group,
    name,
    parent_id,
    house_modes,
    then,
    when
  } = scene;

  return {
    id,
    enabled,
    group_id,
    is_group,
    name,
    parent_id,
    house_modes,
    then,
    when,
    controller_id: hub,
    type: "ezlo"
  };
}
function generateCloudScene(scene) {
  const { uuid, current_detail, name, used_abstracts, deleted } = scene;

  return {
    id: uuid,
    current_detail,
    name,
    used_abstracts,
    deleted,
    type: "cloud",
    controller_id: "no_hub"
  };
}

function generateVeraScene(scene, hub) {
  // Timestamp: 1592889762
  // groups: [{…}]
  // id: 1
  // invisible: 0
  // last_run: 1592889768
  // name: "scene notice"
  // paused: 0
  // room: 0
  // status: 0
  // triggers: []
  // triggers_operator: "OR"
  // users: "2677202"
  const {
    Timestamp,
    groups,
    id,
    invisible,
    last_run,
    name,
    paused,
    room,
    status,
    triggers,
    triggers_operator,
    users
  } = scene;

  return {
    id,
    enabled: status,
    group_id: "",
    is_group: !!groups,
    name,
    parent_id: hub + "_" + room,
    house_modes: [],
    then: [],
    when: [],
    type: "vera",
    controller_id: hub
  };
}

export default {
  [SET_SCENES](state, payload) {
    const { scenes, hub, isVera } = payload;
    const newScenes = isVera
      ? scenes.map(scene => generateVeraScene(scene, hub))
      : scenes.map(scene => generateScene(scene, hub));
    Vue.set(state, "scenes", [...state.scenes, ...newScenes]);
  },
  [SET_CLOUD_SCENES](state, payload) {
    const { list: scenes } = payload;
    const newScenes = scenes.map(scene => generateCloudScene(scene));
    Vue.set(state, "scenes", [...state.scenes, ...newScenes]);
  },
  [RESET_SCENES](state) {
    Vue.set(state, "scenes", []);
  },
  [ADD_SELECTED_SCENE](state, payload) {
    const { id, isLandscape = false } = payload;
    const selectedScenes = state.selectedScenes;

    if (!isLandscape) {
      const prtScenes = selectedScenes.portrait;
      if (prtScenes.findIndex(el => el === id) === -1) {
        prtScenes.push(id);
      }
    } else {
      const lntScenes = selectedScenes.landscape;
      if (lntScenes.findIndex(el => el === id) === -1) {
        lntScenes.push(id);
      }
    }
  },
  [REMOVE_SELECTED_SCENE](state, payload) {
    const { id, isLandscape = false } = payload;
    const selectedScenes = state.selectedScenes;
    if (!isLandscape) {
      Vue.set(
        state.selectedScenes,
        "portrait",
        selectedScenes.portrait.filter(item => item !== id)
      );
    } else {
      Vue.set(
        state.selectedScenes,
        "landscape",
        selectedScenes.landscape.filter(item => item !== id)
      );
    }
  },
  [RESET_SELECTED_SCENE](state) {
    Vue.set(state, "selectedScenes", { portrait: [], landscape: [] });
  },
  [SET_SELECTED_SCENE](state, payload) {
    Vue.set(state, "selectedScenes", payload);
  },
  [START_LOADING](state, payload) {
    Vue.set(state, "loadingItems", [...state.loadingItems, payload]);
  },
  [STOP_LOADING](state, payload) {
    Vue.set(
      state,
      "loadingItems",
      state.loadingItems.filter(item => item !== payload)
    );
  }
};
