<template>
  <MainPage>
    <GridLayout height="100%" rows="auto,*" columns="*">
      <StackLayout row="0" col="0">
        <IBtnHiBtn
          icon1Src="icons/Controls/ic_controls_arrow-left_56.svg"
          @iconTap1="navigateBack"
          :text="translations.textTitle"
        />
      </StackLayout>
      <StackLayout row="1" col="0">
        <FontFamilyOverview
          :styleSettings="styleSettings"
          :fontFamilies="getFontFamilies"
          @submit="handleSubmit"
        />
      </StackLayout>
    </GridLayout>
  </MainPage>
</template>
<script>
import FontFamilyOverview from "@/components/dashboard/FontFamilyOverview";
import { createNamespacedHelpers } from "vuex";

const { mapGetters: mapDashboardGetters } = createNamespacedHelpers(
  "dashboards"
);

export default {
  name: "FontFamily",
  components: {
    FontFamilyOverview
  },
  props: {
    fontFamily: {
      type: String,
      default: "ezlo-sans"
    },
    fontSize: {
      type: [String, Number],
      default: 16
    }
  },
  computed: {
    ...mapDashboardGetters(["getFontFamilies"]),
    translations() {
      return this.$t("fontFamily"); // eslint-disable-line
    },
    styleSettings() {
      return { fontFamily: this.fontFamily, fontSize: this.fontSize };
    }
  },
  methods: {
    handleSubmit(fontFamily) {
      this.$root.$emit("_fontFamilySelected", {
        id: this.dashboardId,
        fontFamily
      });
      this.navigateBack();
    }
  }
};
</script>
