import Vue from "@/vue";
import {
  SET_ITEMS,
  UPDATE_ITEM_VALUE,
  REMOVE_ITEM,
  ADD_ITEM
} from "@/store/modules/items/mutationTypes";
import moment from "moment";

export default {
  [SET_ITEMS](state, payload) {
    Vue.set(state, "items", [...state.items, ...payload]);
  },
  [UPDATE_ITEM_VALUE](state, payload) {
    const { _id, value } = payload;
    state.items = state.items.map(item =>
      item._id === _id ? { ...item, value } : item
    );

    Vue.set(state, "lastUpdatedItem", {
      ...state.items.find(item => item._id === _id),
      createdAt: moment().valueOf()
    });
  },
  [REMOVE_ITEM](state, id) {
    Vue.set(
      state,
      "items",
      state.items.filter(item => {
        return item.id !== id;
      })
    );
  },
  [ADD_ITEM](state, payload) {
    state.items.push(payload);
  }
};
