<template>
  <GridLayout class="color-bar" rows="auto" columns="*">
    <StackLayout
      row="0"
      col="0"
      class="color-bar__badge"
      :backgroundColor="color"
      :width="width"
      :height="height"
    />
  </GridLayout>
</template>
<script>
import TemplateTile from "@/components/dashboard/TemplateTile";

export default {
  name: "ColorEllipse",
  props: {
    color: {
      type: String,
      default: "#ffffff"
    },
    width: {
      type: [Number, String],
      default: "100%"
    },
    height: {
      type: [Number, String],
      default: 8
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~/assets/styles/variables.scss";
.color-bar {
  padding: 0 $padding-l;
  align-content: center;
  &__badge {
    border-width: $stroke;
    border-style: solid;
    border-color: $color-grey-a25;
  }
}
</style>
