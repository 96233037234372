<template>
  <GridLayout class="badge-htxt" rows="56" columns="56, *, 56">
    <StackLayout
      row="0"
      col="0"
      class="badge-htxt__badge"
      :height="badgeSize"
      :width="badgeSize"
      :backgroundColor="color"
      verticalAlignment="center"
      @tap="tap"
    >
      <VxpLabel
        class="badge-htxt__badge-text font-txt-16-par"
        :text="badgeText"
      ></VxpLabel>
    </StackLayout>

    <VxpLabel
      row="0"
      col="1"
      verticalAlignment="center"
      :class="{
        'font-h1-32': headerSize === 'h1',
        'font-h2-24': headerSize === 'h2',
        'font-h3-20': headerSize === 'h3',
        '`badge-htxt__text__align-right`': alignText === 'right',
        'badge-htxt__text__align-left': alignText === 'left'
      }"
      class="badge-htxt__text"
      :text="text"
    ></VxpLabel>
    <ButtonIcon row="0" col="2" :src="src" :disabled="disabled" @tap="tap" />
  </GridLayout>
</template>
<script>
import { locals } from "@/helpers/variables";

export default {
  name: "BadgeHTxtBtn",
  props: {
    color: {
      type: String,
      default: "rgba(144, 160, 175, 0.5)"
    },
    badgeText: {
      type: String,
      default: ""
    },
    badgeSize: {
      type: [Number, String],
      default: "32"
    },
    text: {
      type: String,
      default: ""
    },
    src: {
      type: String,
      default: ""
    },
    alignText: {
      type: String,
      default: "center"
    },
    headerSize: {
      type: String,
      default: "h3"
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    tap() {
      this.$emit("tap");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~/assets/styles/variables.scss";
.badge-htxt {
  padding: 0 $l 0 $padding-s;
  &__badge {
    width: $xxl;
    height: $xxl;
    border-radius: 100%;
    background-color: $color-grey-a50;
    justify-self: center;
    align-self: center;
    justify-content: center;
  }
  &__badge-text {
    text-align: center;
    align-self: center;
  }
  &__text {
    line-height: unit(56, 0);
    vertical-align: middle;
    &__align-right {
      text-align: right;
    }
    &__align-center {
      text-align: center;
    }
    &__align-left {
      text-align: left;
    }
  }
}
</style>
