export const DASHBOARDS_HIDE_PROMO = "DASHBOARDS_HIDE_PROMO";
export const DASHBOARDS_SET = "DASHBOARDS_SET";
export const DASHBOARD_RENAME = "DASHBOARD_RENAME";
export const DASHBOARD_DELETE = "DASHBOARD_DELETE";
export const DASHBOARD_DUPLICATE = "DASHBOARD_DUPLICATE";
export const DASHBOARD_UPDATE = "DASHBOARD_UPDATE";
export const DASHBOARD_CREATE = "DASHBOARD_CREATE";
export const DASHBOARD_CANCEL = "DASHBOARD_CANCEL";
export const STYLE_SETTING_SET = "STYLE_SETTING_SET";
export const STYLE_SETTINGS_CANCEL = "STYLE_SETTINGS_CANCEL";
export const STYLE_SETTINGS_SAVE = "STYLE_SETTINGS_SAVE";
export const STYLE_SETTINGS_SELECT_BG_IMG = "STYLE_SETTINGS_SELECT_BG_IMG";
export const STYLE_SETTINGS_ADD_BG_IMG = "STYLE_SETTINGS_ADD_BG_IMG";
export const STYLE_SETTINGS_SAVE_BG_IMG = "STYLE_SETTINGS_SAVE_BG_IMG";
export const STYLE_SETTINGS_DEFAULT = "STYLE_SETTINGS_DEFAULT";
export const SET_FONT_FAMILY = "SET_FONT_FAMILY";
export const NEW_DASHBOARD_SET_TEMPLATE = "NEW_DASHBOARD_SET_TEMPLATE";
export const NEW_DASHBOARD_RESET = "RESET_NEW_DASHBOARD";
export const NEW_DASHBOARD_SET_NAME = "NEW_DASHBOARD_SET_NAME";
export const SET_STYLE_CHANGED = "SET_STYLE_CHANGED";
export const DASHBOARD_CHANGED_SET = "DASHBOARD_CHANGED_SET";
export const SET_BACKGROUND_IMAGES = "SET_BACKGROUND_IMAGES";
export const SET_AS_DEFAULT_DASHBOARD = "SET_AS_DEFAULT_DASHBOARD";
export const DELETE_ICON = "DELETE_ICON";
export const SET_USER_ICONS = "SET_USER_ICONS";
export const SET_TUTORIAL_APPROVED = "SET_TUTORIAL_APPROVED";
export const SET_DASHBOARD_THEME = "SET_DASHBOARD_THEME";
