import request from "./http";
import endpoints from "./endpoints";
import stringInject from "stringinject";

const location = {
  async getLocationsByAccountId(accountId, options) {
    const url = stringInject(endpoints.account.installation.installations.get, {
      account: accountId
    });
    const headers = {
      MMSAuth: options.token.Identity,
      MMSAuthSig: options.token.IdentitySignature
    };
    return await request({
      method: "GET",
      url,
      baseUrl: options.baseUrl,
      headers
    });
  }
};

export default location;
