export const getInitialWindowCover = () => {
  return {
    _id: "window_cov644A0DA11",
    deviceId: "11",
    name: "window_cov",
    valueType: "string",
    value: "↑100%",
    currentValue: 100,
    favoritePosition: 0,
    maxvalue: 100,
    minValue: 0
  };
};

export default function() {
  return {
    loadingStates: {
      saveFavoritePosition: false
    },
    loadingItems: [],
    windowCover: getInitialWindowCover(),
    currentDeviceSettings: {},
    devices: [],
    selectedDevices: {
      portrait: [],
      landscape: []
    },
    devicesData: {
      groups: [
        { name: "Switches and dimmers" },
        { name: "Lighting" },
        { name: "Appliance" },
        { name: "Window Coverings" },
        { name: "Motion sensor" },
        { name: "Smoke sensor" },
        { name: "Door" }
      ],
      items: [
        {
          id: "1",
          icons: {
            default: "icons/Devices/ic_device_lighthing_wall-switch_56.svg",
            white: "icons/Devices/ic_device_lighthing_wall-switch_white_56.svg",
            green: "icons/Devices/ic_device_lighthing_wall-switch_green_56.svg"
          },
          title: "Switch",
          groupIconName: "Switches and dimmers",
          isIcon: true
        },
        {
          id: "2",
          icons: {
            default: "icons/Devices/ic_device_lighthing_basic-light_56.svg",
            white: "icons/Devices/ic_device_lighthing_basic-light_white_56.svg",
            green: "icons/Devices/ic_device_lighthing_basic-light_green_56.svg"
          },
          title: "Dimmer",
          groupIconName: "Switches and dimmers",
          isIcon: true
        },
        {
          id: "3",
          icons: {
            default: "icons/Devices/ic_device_dimmers-switches_switcher_56.svg",
            white:
              "icons/Devices/ic_device_dimmers-switches_switcher_white_56.svg",
            green:
              "icons/Devices/ic_device_dimmers-switches_switcher_green_56.svg"
          },
          title: "Dimmer",
          groupIconName: "Switches and dimmers",
          isIcon: true
        },
        {
          id: "4",
          icons: {
            default: "icons/Devices/ic_device_lighthing_lamp3_56.svg",
            white: "icons/Devices/ic_device_lighthing_lamp3_white_56.svg",
            green: "icons/Devices/ic_device_lighthing_lamp3_green_56.svg"
          },
          title: "Room light",
          groupIconName: "Lighting",
          isIcon: true
        },
        {
          id: "5",
          icons: {
            default: "icons/Devices/ic_device_lighthing_lamp4_56.svg",
            white: "icons/Devices/ic_device_lighthing_lamp4_white_56.svg",
            green: "icons/Devices/ic_device_lighthing_lamp4_green_56.svg"
          },
          title: "Bulb",
          groupIconName: "Lighting",
          isIcon: true
        },
        {
          id: "6",
          icons: {
            default: "icons/Devices/ic_device_lighthing_lamp1_56.svg",
            white: "icons/Devices/ic_device_lighthing_lamp1_white_56.svg",
            green: "icons/Devices/ic_device_lighthing_lamp1_green_56.svg"
          },
          title: "Top light",
          groupIconName: "Lighting",
          isIcon: true
        },
        {
          id: "7",
          icons: {
            default: "icons/Devices/ic_device_lighthing_lamp2_56.svg",
            white: "icons/Devices/ic_device_lighthing_lamp2_white_56.svg",
            green: "icons/Devices/ic_device_lighthing_lamp2_green_56.svg"
          },
          title: "Sconce",
          groupIconName: "Lighting",
          isIcon: true
        },
        {
          id: "8",
          icons: {
            default: "icons/Devices/ic_device_plug_tv_56.svg",
            white: "icons/Devices/ic_device_plug_tv_white_56.svg",
            green: "icons/Devices/ic_device_plug_tv_green_56.svg"
          },
          title: "TV",
          groupIconName: "Appliance",
          isIcon: true
        },
        {
          id: "9",
          icons: {
            default: "icons/Devices/ic_device_misc_blinds_56.svg",
            white: "icons/Devices/ic_device_misc_blinds_white_56.svg",
            green: "icons/Devices/ic_device_misc_blinds_green_56.svg"
          },
          title: "Window Coverings",
          groupIconName: "Window Coverings",
          isIcon: true
        },
        {
          id: "10",
          icons: {
            default: "icons/Devices/ic_device_sensor_motion_56.svg",
            white: "icons/Devices/ic_device_sensor_motion_white_56.svg",
            green: "icons/Devices/ic_device_sensor_motion_green_56.svg"
          },
          title: "Motion sensor",
          groupIconName: "Motion sensor",
          subcategory: "motion",
          isIcon: true
        }
      ]
    }
  };
}
