<template>
  <StackLayout class="devices-list">
    <DevicesListHeader
      v-if="items.length > 0"
      class="devices-list__header"
      :texts="translations"
      :showActionButton="false"
    />
    <Separator size="xl" v-if="items.length > 0" />
    <StackLayout
      v-for="item in items"
      :key="item.id"
      @tap="onTap(item, $event)"
    >
      <IconHTxtiBtn
        class=""
        alignText="left"
        headerSize="h3"
        :icon1Src="item.icons.defaultSrc"
        :icon2Src="selectIcon(item.selected)"
        :text="item.title"
      />
      <Separator size="xl" />
    </StackLayout>
  </StackLayout>
</template>

<script>
import DevicesListHeader from "@/components/device/DevicesListHeader";

export default {
  name: "GoalDevicesList",
  components: {
    DevicesListHeader
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    items: {
      required: false
    }
  },
  computed: {
    translations() {
      return { title: this.title };
    }
  },
  methods: {
    onTap(item) {
      this.$emit("tap", item);
    },
    selectIcon(event) {
      return event
        ? "icons/Controls/ic_controls_radio-on_56.svg"
        : "icons/Controls/ic_controls_radio-off_56.svg";
    }
  }
};
</script>
<style scoped lang="scss">
@import "~/assets/styles/variables.scss";

.devices-list {
  &__header {
    padding-left: $xl;
    padding-right: $xl;
  }
}
</style>
