<template>
  <StackLayout>
    <StackLayout v-for="tile in tiles" :key="tile.index">
      <IconHTxtiBtn
        alignText="left"
        headerSize="h3"
        :text="$t(tile.text)"
        :icon1Src="tile.icon"
        :icon2Src="tile.actionIcon"
        @tap="tap(tile)"
      />
      <Separator size="xl" />
    </StackLayout>
  </StackLayout>
</template>

<script>
export default {
  name: "TileList",
  props: {
    tiles: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    tap(tile) {
      this.$emit("selectTile", tile);
    }
  }
};
</script>
