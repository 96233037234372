<template>
  <MainPage>
    <ConfirmationDialog
      :icon="icon"
      :text1="translations.title"
      :text2="translations.description"
      :btnTextAccept="translations.btnTextAccept"
      :btnTextCancel="translations.btnTextCancel"
      :warning="warning"
      @accept="accept(true)"
      @cancel="goBack(false)"
    />
  </MainPage>
</template>
<script>
export default {
  name: "Confirmation",
  props: {
    icon: {
      type: String,
      default: "icons/Warnings/ic_warning_200.svg"
    },
    title: {
      type: String
    },
    description: {
      type: String
    },
    btnTextAccept: {
      type: String,
      default: "Accept"
    },
    btnTextCancel: {
      type: String,
      default: "Cancel"
    },
    warning: {
      type: Boolean,
      default: false
    },
    resultActionName: {
      type: String,
      default: "_confirmationDialog"
    }
  },
  computed: {
    translations() {
      return {
        title: this.$t(this.title),
        description: this.$t(this.description),
        btnTextAccept: this.$t(this.btnTextAccept),
        btnTextCancel: this.$t(this.btnTextCancel)
      };
    }
  },
  methods: {
    goBack(event) {
      this.navigateBack();
    },
    accept(event) {
      this.$root.$emit("_confirmationDialog", {
        resultActionName: this.resultActionName,
        result: event
      });
      this.goBack();
    }
  }
};
</script>
