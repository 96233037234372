import WS from "@/services/ws.service";

export default {
  get: () => WS.send("hub.room.list", {}),
  add: ({ name }) =>
    WS.send("hub.room.create", {
      name
    }),
  edit: ({ id, name }) =>
    WS.send("hub.room.name.set", {
      _id: id,
      name
    }),
  delete: ({ id }) =>
    WS.send("hub.room.delete", {
      _id: id
    })
};
