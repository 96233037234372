<script>
import { helpers } from "ezlo-core-ui";
import { createNamespacedHelpers } from "vuex";

import { routerOptions } from "./router";

const {
  mapGetters: mapUiGetters,
  mapActions: mapUiActions
} = createNamespacedHelpers("ui");
const { connectionUtils } = helpers;

const { VUE_APP_MODE } = process.env;

let routerHistory = [];
let returnToBackstack = false;

export default {
  computed: {
    ...mapUiGetters(["offline"])
  },
  created() {
    this.$root.$on("navigateTo", this.handleNavigateTo);
    this.$root.$on("navigateBack", this.handleNavigateBack);

    // set initial connection type, subscribe on change connectivity
    if (VUE_APP_MODE !== "web") {
      this.setConnectionType(connectionUtils.getConnectionType());
      connectionUtils.addConnectionStateChangeListener(this.setConnectionType);
    }
  },
  beforeDestroy() {
    this.$root.$off(["navigateTo", "navigateBack"]);

    // unsubscribe on change connectivity
    connectionUtils.removeAllListeners();
  },
  methods: {
    ...mapUiActions(["setConnectionType"]),
    handleNavigateTo({ component, options, cb }) {
      //TODO: frame : "app" is removed until nativescript bug is fixed -->  this.$navigateTo(component, { frame: "app", ...options
      if (VUE_APP_MODE !== "web") {
        this.$navigateTo(component, { ...options }).then(page => {
          /*eslint-disable */
          console.timeEnd(">>>>>>>> Navigation completed in");
          console.info(`>>>>>>>> Navigated to: ${component}`);
          /* eslint-enable */
          if (typeof cb === "function") {
            return cb(page);
          }
        });
      } else {
        if (component === "terminate-app") {
          console.log("Terminating App....");//eslint-disable-line
          this.$router.replace({ name: "Terminate", path: "/terminate-app" });
        } else if (component.startsWith("link-")) {
            console.log("Routing link....", component);//eslint-disable-line
          this.$router.replace({ name: "Link", path: component });
        } else {
          const routes = routerOptions.routes;
          const route = routes.find(route => route.name === component);
          const navRoute = {
            name: route.name,
            path: route.path,
            params: options ? options.props : {}
          };
          if (options && options.clearHistory) {
            routerHistory = [];
          }
          if (
            (options &&
              options.backstackVisible &&
              options.backstackVisible !== false) ||
            (options && options.backstackVisible === undefined) ||
            options === undefined
          ) {
            returnToBackstack = false;
            routerHistory.push(navRoute);
          } else {
            returnToBackstack = true;
          }
          this.$router.replace(navRoute);
        }
      }
    },
    //TODO: frame : "app" is removed until nativescript bug is fixed -->  this.$navigateBack({ frame: "app", ...options
    handleNavigateBack({ options, backstackEntry }) {
      if (VUE_APP_MODE !== "web") {
        if (this.offline) return;
        this.$navigateBack({ ...options }, backstackEntry);
      } else {
        if (!returnToBackstack) {
          routerHistory.pop();
        }
        let route = routerHistory[routerHistory.length - 1];
        this.$router.replace(route);
        returnToBackstack = false;
      }
    },
    targetComponent() {
      // TODO: change to SignUpIntro when need to create demo build;
      if (VUE_APP_MODE === "web") {
        return "RouterPage";
      }
      return TNS_ENV === "production" ? "LoadingPage" : "DevelopmentPage";
    }
  },
  render: function(h) {
    //TODO: frame and Page commented out until nativescript bug is fixed
    return h(
      // "Page",
      // { attrs: { actionBarHidden: "true" } },
      // [
      //   h("Frame", { attrs: { id: "app" } },
      // [h(
      this.targetComponent()
      // )]
      // )
      // ]
    );
  }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "~/assets/styles/icons.scss";
@import "~/assets/styles/ezlo-icon.scss";
@import "~/assets/styles/variables.scss";
@import "~/assets/styles/fonts.scss";
@import "~/assets/styles/typography.scss";

body {
  height: 100%;
  margin: 0;
  user-select: none;
}
html {
  height: 100%;
}
</style>
