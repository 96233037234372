import {
  SET_TEMPLATE,
  SET_TILE,
  SET_URL_TILE,
  SET_DASHBOARD_TILE,
  SELECT_TEMPLATE,
  DELETE_TILE,
  CLEAN_TEMPLATE,
  SET_TEMPLATE_LANDSCAPE,
  // FILL_TEMPLATE_LANDSCAPE_TILE,
  // DELETE_TEMPLATE_LANDSCAPE_TILE,
  CLEAN_TEMPLATE_LANDSCAPE,
  CLEAN_TEMPLATE_PORTRAIT,
  CLEAN_SELECT_TEMPLATE,
  SET_DESIGN_PROPERTIES,
  DEFAULT_DESIGN_PROPERTIES,
  SAVE_DESIGN_PROPERTIES,
  RESET_DESIGN_PROPERTIES,
  SAVE_TO_TEMPLATE_BUFFER,
  CLEAN_TEMPLATE_BUFFER,
  SET_TEMPLATE_THEME
} from "./mutationTypes";
import { createTemplateFromJson } from "@/helpers/templateCreator";
import { defaultDesignProperties } from "./state";

export default {
  setTemplate(
    { commit, getters, rootGetters },
    { id, dashboardId, tiles = [], styleSettings = {}, previewMode = false }
  ) {
    const theme =
      (styleSettings &&
        styleSettings.theme &&
        rootGetters["themes/getThemes"].find(
          item => item.uuid === styleSettings.theme["portrait"].theme_id
        )) ||
      rootGetters["themes/getDefaultTheme"];

    const template = createTemplateFromJson(
      getters.getTemplatesPortrait[id],
      dashboardId,
      tiles,
      styleSettings,
      {
        devices: rootGetters["devices/getDevices"],
        dashboards: rootGetters["dashboards/getDashboards"],
        scenes: rootGetters["scenes/getScenes"],
        defaultDesignProperties: defaultDesignProperties(),
        houseModes: getters.getHouseModes,
        panicTypes: getters.getPanicTypes,
        theme
      },
      previewMode
    );
    commit(SET_TEMPLATE, template);
  },
  setTemplateLandscape(
    { commit, getters, rootGetters },
    { id, dashboardId, tiles = [], styleSettings = {}, previewMode = false }
  ) {
    const theme =
      (styleSettings &&
        styleSettings.theme &&
        rootGetters["themes/getThemes"].find(
          item => item.uuid === styleSettings.theme["landscape"].theme_id
        )) ||
      rootGetters["themes/getDefaultTheme"];

    const template = createTemplateFromJson(
      getters.getTemplatesLandscape[id],
      dashboardId,
      tiles,
      styleSettings,
      {
        devices: rootGetters["devices/getDevices"],
        dashboards: rootGetters["dashboards/getDashboards"],
        defaultDesignProperties: defaultDesignProperties(),
        scenes: rootGetters["scenes/getScenes"],
        houseModes: getters.getHouseModes,
        panicTypes: getters.getPanicTypes,
        theme
      },
      previewMode
    );
    commit(SET_TEMPLATE_LANDSCAPE, template);
  },
  setTile({ commit, dispatch, getters, rootGetters }, payload) {
    const {
      dashboardId,
      type,
      id,
      subType,
      orientation,
      index,
      icon
    } = payload;
    let item = payload.item ? payload.item : {};
    let deviceStatesDesigns = {};
    let iconSrc = "";
    if (type === "device") {
      iconSrc = rootGetters["devices/getIconsByCategory"](
        item.category,
        item.subcategory
      ).defaultSrc;
      const deviceStates = rootGetters["categories/getCategoryStates"](
        item.category
      );
      for (let i = 0; i < deviceStates.length; i++) {
        deviceStatesDesigns[deviceStates[i].name] = {
          ...defaultDesignProperties().general,
          icon: iconSrc
        };
      }
    } else if (type === "scene") {
      iconSrc = "icons/Tabbar/ic_tabbar_scenes_56.svg";
    } else if (type === "url") {
      iconSrc = "icons/Controls/ic_controls_pair_56.svg";
    } else if (type === "link") {
      iconSrc = "icons/Controls/ic_controls_pair_56.svg";
    } else if (type === "datetime") {
      iconSrc =
        subType === "time"
          ? "icons/Actions/ic_actions_clock_56"
          : "icons/Actions/ic_actions_calendar_56";
    } else if (type === "houseMode") {
      iconSrc = icon;
    } else if (type === "panic") {
      iconSrc = icon;
    }
    let template = null;
    let tileSize = null;
    if (orientation === "portrait") {
      template = getters.getTemplatesPortrait[getters.template.id];
    } else {
      template = getters.getTemplatesLandscape[getters.getTemplateLandscape.id];
    }
    const layoutElement = template.layout[index];
    tileSize =
      (layoutElement.colEnd - layoutElement.colStart + 1) *
      (layoutElement.rowEnd - layoutElement.rowStart + 1);
    switch (type) {
      case "dashboard":
        const dashboard = rootGetters["dashboards/getDashboard"](id);
        item = { id, title: dashboard.name };
        break;
    }

    const dashboard = rootGetters["dashboards/getDashboardChanged"](
      dashboardId
    );
    let theme = rootGetters["themes/getSelectedTheme"];
    if (dashboard) {
      const { styleSettings } = dashboard;
      const theme_id =
        (styleSettings &&
          styleSettings.theme &&
          styleSettings.theme[orientation].theme_id) ||
        theme.uuid;
      theme = rootGetters["themes/getThemeOrDefault"](theme_id);
    }
    theme["icon"] = iconSrc;
    const generalTileDesing = theme
      ? { ...theme.data.tile }
      : defaultDesignProperties().general;

    let design_properties = {};
    item = {
      ...item,
      design_properties,
      theme: generalTileDesing,
      tileSize,
      subType
    };
    dispatch("dashboards/dashboardsHasChanged", dashboardId, { root: true });
    commit(SET_TILE, {
      index,
      item,
      type,
      orientation
    });
  },

  deleteTile({ commit, dispatch, rootGetters }, payload) {
    const { dashboardId, orientation } = payload;
    dispatch("dashboards/dashboardsHasChanged", dashboardId, {
      root: true
    });
    const theme = rootGetters["dashboards/getDashboardTheme"](dashboardId)[
      orientation
    ];
    payload["theme"] = theme;
    commit(DELETE_TILE, payload);
  },
  deleteAssociatedElement({ commit, dispatch }, payload) {
    const { type } = payload;
    switch (type) {
      case "scene":
        dispatch("scenes/removeSelectedScene", payload, { root: true });
        break;
      case "device":
        dispatch("devices/removeSelectedDevice", payload, { root: true });
    }
  },
  setAssociatedElements({ commit, dispatch }, payload) {
    const devices = { portrait: [], landscape: [] };
    const scenes = { portrait: [], landscape: [] };

    if (payload.portrait) {
      for (const element of payload.portrait.dashboard_elements) {
        switch (element.target_entity.type) {
          case "scene":
            scenes.portrait.push(element.target_entity.object_id);
            break;
          case "device":
            devices.portrait.push(element.target_entity.object_id);
            break;
        }
      }
    }
    if (payload.landscape) {
      for (const element of payload.landscape.dashboard_elements) {
        switch (element.target_entity.type) {
          case "scene":
            scenes.landscape.push(element.target_entity.object_id);
            break;
          case "device":
            devices.landscape.push(element.target_entity.object_id);
            break;
        }
      }
    }
    dispatch("scenes/setSelectedScenes", scenes, { root: true });
    dispatch("devices/setSelectedDevices", devices, { root: true });
  },
  resetAssociatedElements({ commit, dispatch }) {
    dispatch("scenes/resetSelectedScenes", null, { root: true });
    dispatch("devices/resetSelectedDevices", null, { root: true });
  },

  selectTemplate({ commit }, payload) {
    commit(SELECT_TEMPLATE, payload);
  },
  resetTemplateSelected({ commit }) {
    commit(CLEAN_SELECT_TEMPLATE);
  },
  resetTemplatePortrait({ commit }) {
    commit(CLEAN_TEMPLATE);
  },
  resetTemplateLandscape({ commit }) {
    commit(CLEAN_TEMPLATE_LANDSCAPE);
  },
  resetTemplatePortrait({ commit }) {
    commit(CLEAN_TEMPLATE_PORTRAIT);
  },
  setDesignProperties({ commit }, payload) {
    commit(SET_DESIGN_PROPERTIES, payload);
  },
  setToDefaultDesignProperties({ commit }, payload) {
    commit(DEFAULT_DESIGN_PROPERTIES, payload);
  },
  resetDesignProperties({ commit }) {
    commit(RESET_DESIGN_PROPERTIES);
  },
  saveDesignProperties({ commit, dispatch }, payload) {
    dispatch("dashboards/dashboardsHasChanged", payload.dashboardId, {
      root: true
    });
    commit(SAVE_DESIGN_PROPERTIES, payload);
    dispatch("resetDesignProperties");
  },
  saveToTemplateBuffer({ commit }) {
    commit(SAVE_TO_TEMPLATE_BUFFER);
  },
  cleanTemplateBuffer({ commit }) {
    commit(CLEAN_TEMPLATE_BUFFER);
  },
  setTemplateTheme({ commit }, payload) {
    const { tile } = payload.data;
    if (tile) {
      commit(SET_TEMPLATE_THEME, tile);
    }
  }
};
