<template>
  <GridLayout class="wrap" rows="*" columns="*" iosOverflowSafeArea="true">
    <ImageCacheIt
      class="bg-image"
      iosOverflowSafeArea="true"
      row="0"
      col="0"
      v-if="backgroundImage"
      :src="backgroundImage"
      stretch="aspectFill"
    />
    <StackLayout
      iosOverflowSafeArea="true"
      row="0"
      col="0"
      class="template-wraper bg-image"
      :class="{ 'template-wraper--preview': preview }"
      :backgroundColor="getBackgroundColor"
    >
      <StackLayout v-if="hasTemplate" @tap="handleSeletTemplate">
        <IconHTxtiBtn
          icon2Src="icons/Controls/ic_controls_arrow-right_56.svg"
          headerSize="h3"
          alignText="left"
          :text="text"
        />
        <Separator size="xl" />
      </StackLayout>

      <TemplateRenderer
        v-if="!hasTemplate"
        :template="template"
        @tileTap="handleTapTile"
        @deleteTile="handleDeleteTile"
      />
    </StackLayout>
  </GridLayout>
</template>
<script>
import { isEmpty, colorHexA } from "@/helpers/utils";

const { VUE_APP_MODE } = process.env;
export default {
  name: "TemplateWrapRenderer",
  props: {
    template: {
      type: Object,
      required: true
    },
    backgroundImage: {
      type: String,
      default: ""
    },
    text: {
      type: String,
      default: "Button"
    },
    designProperties: {
      type: Object
    },
    preview: {
      type: Boolean,
      default: false
    },
    backgroundColor: {
      type: String,
      default: "rgba(0, 0, 0, 0.25)"
    }
  },
  computed: {
    hasTemplate() {
      return isEmpty(this.template);
    },
    getBackgroundColor() {
      const color = this.hasBackgroundImage
        ? this.backgroundColor //"transparent"
        : this.designProperties
        ? this.designProperties.backgroundColor ||
          this.designProperties.background_color
        : null;
      return VUE_APP_MODE === "web" ? colorHexA(color) : color;
    },
    hasBackgroundImage() {
      return (
        this.designProperties &&
        (this.designProperties.use_background_image ||
          this.designProperties.useBackgroundImage) &&
        this.designProperties.background_image
      );
    }
  },
  methods: {
    handleTapTile(index) {
      this.preview
        ? this.$emit("tileTap", index)
        : this.$root.$emit("_tileTap", { orientation: "portrait", index });
    },
    handleDeleteTile(index) {
      this.$root.$emit("_deleteTile", { orientation: "portrait", index });
    },
    handleSeletTemplate() {
      this.$root.$emit("_onTapSelectTemplate", { orientation: "portrait" });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~/assets/styles/variables.scss";
.wrap {
  height: 100%;
}
.template-wraper {
  padding: $padding-l $padding-xl $padding-l $padding-xl;
  &--preview {
    padding: $xxxlll $padding-l $padding-xxxll $padding-l;
  }
  &--opacity {
    background-color: $color-black-025;
  }
}
.bg-image {
  grid-column: 1;
  grid-row: 1;
}
</style>
