<template>
  <MainPage>
    <StackLayout>
      <StackLayout left="0" top="0" width="100%">
        <IBtnHiBtn
          icon1Src="icons/Controls/ic_controls_arrow-left_56.svg"
          :text="translations.headerTitle"
          @iconTap1="navigateBack"
        />
      </StackLayout>
      <DashboardSelectOverview
        :dashboards="items"
        @onTapSelectDashboard="handleDateTimeSelect"
      />
    </StackLayout>
  </MainPage>
</template>
<script>
import { createNamespacedHelpers } from "vuex";
import DashboardSelectOverview from "@/components/dashboard/DashboardSelectOverview";

const { mapActions: mapTemplateActions } = createNamespacedHelpers("template");

export default {
  name: "DateTimeSelect",
  props: {
    index: {
      type: Number
    },
    dashboardId: {
      type: String
    },
    isLandscape: {
      type: Boolean,
      default: false
    }
  },
  components: {
    DashboardSelectOverview
  },
  computed: {
    orientation() {
      return this.isLandscape ? "landscape" : "portrait";
    },
    translations() {
      return this.$t("dateTimeSelect"); // eslint-disable-line
    },
    items() {
      return [
        {
          name: this.translations.time,
          desc: "",
          icon: "icons/Controls/ic_controls_arrow-right_56.svg",
          subType: "time",
          disable: false
        },
        {
          name: this.translations.date,
          desc: "",
          icon: "icons/Controls/ic_controls_arrow-right_56.svg",
          subType: "date",
          disable: false
        },
        {
          name: this.translations.dateandtime,
          desc: "",
          icon: "icons/Controls/ic_controls_arrow-right_56.svg",
          subType: "dateandtime",
          disable: false
        }
      ];
    }
  },
  methods: {
    ...mapTemplateActions(["setTile"]),
    handleDateTimeSelect(item) {
      console.log("handleDashboardSelect"); //eslint-disable-line
      this.setTile({
        index: this.index,
        dashboardId: this.dashboardId,
        type: "datetime",
        subType: item.subType,
        orientation: this.orientation
      });
      this.navigateBack();
    }
  }
};
</script>
