import state from "../auth/state";
import { defaultCustomTheme } from "./state";
export default {
  getThemes: (state, getters) => [
    ...getters.getDefaultThemesAsArray,
    ...getters.getCustomThemes
  ],
  getThemeOrDefault: (state, getters) => uuid => {
    return (uuid && getters.getThemeById(uuid)) || getters.getDefaultTheme;
  },
  getThemeById: (state, getters) => uuid => {
    const themes = getters.getThemes;
    return themes.find(theme => theme.uuid === uuid);
  },
  getThemeByName: (state, getters) => name =>
    getters.getThemes.find(theme => theme.name === name),
  hasThemeChanged: state => state.hasThemeChanged,
  getDefaultThemes: state => state.defaultThemes,
  getDefaultThemesAsArray: state => Object.values(state.defaultThemes),
  getSelectedTheme: (state, getters) =>
    getters.getThemes.find(item => item.data.selected) ||
    getters.getDefaultTheme,
  getTempTheme: state => state.tempTheme,
  getCustomThemes: state => {
    if (Array.isArray(state.themes)) {
      return state.themes;
    }
    return Object.values(state.themes);
  },
  getLastTheme: (state, getters) =>
    getters.getThemes[getters.getThemes.length - 1],
  getDefaultCustomTheme: defaultCustomTheme,
  getDefaultTheme: (state, getters) => getters.getThemeById("light")
};
