export default function() {
  return {
    scenes: [],
    loadingItems: [],
    selectedScenes: {
      portrait: [],
      landscape: []
    }
  };
}
