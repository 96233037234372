<template>
  <GridLayout
    rows="auto,*,auto"
    columns="*"
    class="profile__content profile__scroll"
  >
    <StackLayout row="0" col="0" class="profile__avatar">
      <GridLayout rows="*" columns="auto" horizontalAlignment="center">
        <Avatar
          ref="avatar"
          row="0"
          cols="0"
          :src="avatar"
          :loading="isFetchingAvatar"
          :disabled="isFetchingAvatar"
          @tap="handleTapAvatar"
          @selectedOption="selectedOption"
        />
      </GridLayout>
    </StackLayout>

    <StackLayout row="1" col="0" class="profile__block profile__scroll">
      <Separator size="xl" />
      <IconHTxtiBtn
        class="profile__no-scroll"
        showUnderline
        alignText="left"
        alignUnderline="left"
        headerSize="h3"
        icon2Src="icons/Actions/ic_actions_user-grey_56.svg"
        :text="userName"
        :underlineText="texts.name"
        :hover="false"
      />
      <Separator size="xl" />

      <IconHTxtiBtn
        class="profile__no-scroll"
        showUnderline
        alignText="left"
        alignUnderline="left"
        headerSize="h3"
        icon2Src="icons/Actions/ic_actions_mail_56_gray.svg"
        :text="email"
        :underlineText="texts.email"
        @tap="handleTapEmail"
      />
      <Separator size="xl" />

      <ScrollView
        v-if="hubList.length > 0"
        class="profile__scroll"
        iosOverflowSafeArea="false"
      >
        <StackLayout>
          <Label class="profile__title font-h2-24" :text="texts.hubs" />
          <GridLayout
            columns="*,auto"
            rows="56"
            width="100%"
            class="profile__hubs"
            v-for="hub of hubList"
            :key="hub.id"
          >
            <IconHTxtiBtn
              class="profile__hubs "
              showUnderline
              alignText="left"
              alignUnderline="left"
              headerSize="l"
              :text="hub.name"
              :underlineText="hub.statusText"
              :hover="false"
            />
            <ColorEllipse
              row="0"
              col="1"
              class="profile__hubs__color"
              :color="hub.status === 'connected' ? 'green' : 'red'"
            />
          </GridLayout>
        </StackLayout>
      </ScrollView>
    </StackLayout>
    <StackLayout row="2" col="0">
      <Separator v-if="hubList.length > 0" size="xl" />
      <HAction
        class="profile__no-scroll"
        alignText="left"
        headerSize="h3"
        text="Version"
        :actionName="versionText"
        :hover="false"
        actionNameSize="l"
      />
      <Separator size="xl" />

      <IconHTxtiBtn
        class="profile__block-item--warning profile__no-scroll"
        alignText="left"
        headerSize="h3"
        icon2Src="icons/Actions/ic_actions_logout_56.svg"
        :text="texts.logOut"
        @tap="handleTapLogOut"
      />
      <Separator size="xl" />
    </StackLayout>
  </GridLayout>
</template>

<script>
import { locals } from "@/helpers/variables";
import { formatPhoneNumber } from "@/helpers/string";
import Avatar from "./Avatar";

export default {
  name: "ProfileOverview",
  components: { Avatar },
  props: {
    userName: {
      type: String,
      default: "-"
    },
    email: {
      type: String,
      default: "-"
    },
    texts: {
      type: Object,
      default: () => ({
        title: "Title",
        name: "Username",
        email: "Email",
        logOut: "Some action"
      })
    },
    versionText: {
      type: String,
      default: "0.0.2.16"
    },
    avatar: {
      type: String,
      default: ""
    },
    isFetchingAvatar: {
      type: Boolean,
      default: false
    },
    hubStatuses: {
      type: Object,
      default: () => ({})
    },
    hubs: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    handleTapAvatar() {
      this.$emit("onTapAvatar", this.$refs.avatar);
    },
    selectedOption(event) {
      this.$emit("selectedOption", event);
    },
    handleTapEmail() {
      this.$emit("onTapEmail");
    },
    handleTapLogOut() {
      this.$emit("onTapLogOut");
    }
  },
  computed: {
    hubList() {
      const hubs = [];
      this.hubs.forEach(hub => {
        let hubStatus = this.hubStatuses[hub.id]
          ? this.hubStatuses[hub.id]
          : "disconnected";
        hubs.push({
          id: hub.id,
          name: hub.Name ? hub.Name : hub.nickName,
          statusText:
            hub.id +
            " - " +
            hubStatus.charAt(0).toUpperCase() +
            hubStatus.slice(1),
          status: hubStatus
        });
      });

      return hubs;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~/assets/styles/variables.scss";

.profile {
  &__title {
    padding: $l $xl;
    min-height: $xl;
  }
  &__hubs {
    padding: 0 0 0 $xs;
    &__color {
      align-self: center;
      /deep/ & .color-ellipse__badge {
        height: $m;
        width: $m;
      }
    }
  }
  color: $color-black;
  &__scroll {
    overflow-y: auto;
    height: 100%;
  }
  &__no-scroll {
    min-height: $xxxll;
  }
  &__avatar {
    padding-top: $padding-xl;
    padding-bottom: $padding-m;
  }
  &__block {
    &--last {
      margin-bottom: 0;
    }
  }
  &__block-item {
    &--primary {
      /deep/ & * {
        color: $color-primary;
      }
    }
    &--warning {
      /deep/ & * {
        color: $color-warning;
      }
    }
  }
  &__version {
    &__text {
      min-height: $l;
      margin-bottom: $xs;
      text-align: center;
    }
  }
}
</style>
