<template>
  <GridLayout
    rows="auto"
    :columns="getColumns"
    class="wrapper"
    :class="{ wrapper__width: isWeb, wrapper__hover: hover }"
  >
    <SvgIcon
      row="0"
      col="0"
      rowSpan="2"
      verticalAlignment="center"
      class="wrapper_icon"
      :src="icon1Src"
      v-show="!!icon1Src && !skeleton"
      :width="icon1Size"
      :height="icon1Size"
    />
    <StackLayout
      row="0"
      col="0"
      rowSpan="2"
      verticalAlignment="center"
      class="wrapper__icon-skeleton"
      v-show="skeleton"
    />
    <StackLayout
      row="0"
      col="1"
      v-show="!skeleton"
      verticalAlignment="center"
      class="wrapper_text-container"
      :class="{
        'wrapper_text-container--no-left-icon': !icon1Src
      }"
      @tap="tap"
    >
      <StackLayout
        class="wrapper_text-container_container"
        verticalAlignment="center"
        :rowSpan="showUnderline ? 1 : 2"
      >
        <VxpLabel
          :class="`${getHeaderSize} wrapper_text-align-${alignText}`"
          class="wrapper_text--upperline"
          textWrap
          :text="text"
        />
      </StackLayout>
      <StackLayout
        class="wrapper_text-container_container"
        verticalAlignment="center"
        rowSpan="1"
      >
        <VxpLabel
          v-if="showUnderline"
          :class="
            `wrapper_text-align-${alignUnderline} ${getUnderlinetextSize}`
          "
          class="wrapper_text--underline font-txt-13"
          textWrap
          :text="underlineText"
        />
      </StackLayout>
    </StackLayout>
    <StackLayout
      row="0"
      col="1"
      rowSpan="2"
      verticalAlignment="center"
      class="wrapper__text-skeleton"
      v-show="skeleton"
      horizontalAlignment="left"
    />
    <GridLayout
      row="0"
      col="2"
      rowSpan="2"
      rows="*"
      columns="auto, auto"
      class="wrapper__actions-container"
    >
      <StackLayout
        row="0"
        :col="Number(item.id) - 1"
        class="wrapper__actions-container__action"
        verticalAlignment="center"
        v-for="item in actions"
        :key="item.id"
        @tap="onAction(item)"
      >
        <VxpLabel class="font-txt-10" :text="item.name" />
      </StackLayout>
    </GridLayout>
    <ButtonIcon
      row="0"
      col="3"
      rowSpan="2"
      verticalAlignment="top"
      class="pz wrapper_icon2"
      :src="icon2Src"
      :disabled="disabled"
      v-show="!!getIcon2Src"
      @tap="tap"
    />
  </GridLayout>
</template>

<script>
import platform from "vuexp/src/platform";
export default {
  name: "onOffCapability",
  props: {
    text: {
      type: String,
      default: ""
    },
    underlineText: {
      type: String,
      default: ""
    },
    underlineTextSize: {
      type: String,
      default: "xs"
    },
    icon1Src: {
      type: String,
      default: ""
    },
    icon1Size: {
      type: Number,
      default: 56
    },
    actions: {
      type: Array,
      default: () => [
        { id: "1", name: "Off" },
        { id: "2", name: "On" }
      ]
    },
    icon2Src: {
      type: String,
      default: ""
    },
    image2Src: {
      type: String,
      default: ""
    },
    image2Size: {
      type: Number,
      default: 56
    },
    image2Radius: {
      type: Number,
      default: 56
    },
    countBadge: {
      type: Number,
      default: 0
    },
    disabled: {
      type: Boolean,
      default: false
    },
    alignText: {
      type: String,
      default: "center"
    },
    alignUnderline: {
      type: String,
      default: "center"
    },
    showUnderline: {
      type: Boolean,
      default: false
    },
    headerSize: {
      type: String,
      default: "l-b"
    },
    fontIconText: {
      type: String,
      default: ""
    },
    fontIconColorHex: {
      type: String,
      default: "#000000"
    },
    hover: {
      type: Boolean,
      default: true
    },
    skeleton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fontSize: {
        sx: "font-txt-10",
        s: "font-txt-13",
        l: "font-txt-16-par",
        "s-b": "font-txt-14-btn",
        "l-b": "font-txt-16-btn",
        "xs-a": "font-act-8",
        "s-a": "font-act-10",
        h1: "font-h1-32",
        h2: "font-h2-24",
        h3: "font-h3-20"
      }
    };
  },
  computed: {
    getColumns() {
      let columns = [];
      columns.push(this.icon1Src || this.skeleton ? "56" : "auto"); // col = 0
      columns.push(this.text || this.skeleton ? "*" : "auto"); // col = 1
      // columns.push(this.actions & this.actions.length ? 56 * this.actions.length : "auto"); //col = 2 actions area
      columns.push("auto");
      columns.push(this.icon2Src ? "56" : "auto"); // col = 3
      return columns.toString() || "*";
    },
    isWeb() {
      return platform.platform === "web";
    },
    getUnderlinetextSize() {
      return this.fontSize[this.underlineTextSize];
    },
    getHeaderSize() {
      return this.fontSize[this.headerSize];
    },
    getIcon2Src() {
      return this.countBadge || this.fontIconText ? "" : this.icon2Src;
    },
    getImage2Src() {
      return this.countBadge || this.fontIconText ? "" : this.image2Src;
    },
    isIconWhite() {
      return this.fontIconColorHex.toUpperCase().startsWith("#FFFFFF");
    }
  },
  methods: {
    tap() {
      this.$emit("tap");
    },
    onAction(item) {
      this.$emit("onAction", item);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~vuexp/src/assets/styles/helpers.scss";
@import "~/assets/styles/variables.scss";
@import "~/assets/styles/typography.scss";

.wrapper {
  align-content: center;
  user-select: none;
  &__hover {
    cursor: pointer;
    &:hover {
      background-color: $color-grey-a15;
    }
  }
  &__width {
    width: -webkit-fill-available;
    width: -moz-available;
  }
  &__icon-skeleton {
    background-color: #c4c4c4;
    border-radius: $xs;
    justify-self: center;
    align-self: center;
    height: $xxlm;
    width: $xxlm;
  }
  &__text-skeleton {
    background-color: #c4c4c4;
    border-radius: $xs;
    align-self: center;
    height: $l;
    width: unit(96);
  }
  &_icon2 {
    grid-column: 4;
  }
  &_text {
    &-align-right {
      text-align: right;
    }
    &-align-center {
      text-align: center;
    }
    &-align-left {
      text-align: left;
    }
    &--upperline {
      color: $color-black;
      vertical-align: middle; //for-android
    }
    &--underline {
      color: $color-grey;
      vertical-align: middle; //for-android
    }
  }
  &_text-container {
    justify-content: center; // for web
    vertical-align: middle; //for-android
    &_container {
      margin-top: $margin-xs;
      width: 100%;
    }
    &--no-left-icon {
      padding-left: $padding-l;
    }
  }
  &__actions-container {
    padding: $xs;
    align-items: center;
    &__action {
      vertical-align: middle; //for-android
      text-align: center;
      color: white;
      background-color: $color-primary;
      width: $xxlm;
      height: $xxlm;
      border-radius: $xl;
      margin: $xs;
      justify-content: center;
      align-items: center;
      &:hover {
        cursor: pointer;
        background-color: $color-grey-a15;
      }
    }
  }

  &__badge {
    color: white;
    vertical-align: middle;
    horizontal-align: center;
  }
  &__badge-wrap {
    padding: $padding-l;
    width: $xl;
    height: $xl;
    border-radius: 50%;
    background-color: $color-warning;
  }
  &__badge-text {
    vertical-align: middle;
    horizontal-align: center;
    color: $color-white;
  }
  &__fonticon_white {
    background-color: $color-grey-a25;
    border-radius: 50%;
    font-size: $xxxll;
  }
  &__fonticon {
    font-size: $xxxll;
  }
}
</style>
