<template>
  <MainPage>
    <StackLayout>
      <IBtnHiBtn
        icon1Src="icons/Controls/ic_controls_arrow-left_56.svg"
        :text="capabilityName"
        @iconTap1="handleBack"
        class="devices__header"
      />
    </StackLayout>

    <StackLayout>
      <Separator size="xl" />
      <IconHTxtiBtn
        :icon1Src="abstractDevices.src"
        alignText="left"
        alignUnderline="left"
        headerSize="h3"
        showUnderline
        :text="abstractDevices.name"
        :underlineText="abstractDevices.eMail"
      />
      <Separator size="xl" />
    </StackLayout>

    <GridLayout rows="*, auto" columns="*" class="device-control">
      <ScrollView row="0" col="0" class="device-control__scroll">
        <StackLayout height="100%">
          <Label
            class="font-h2-24 device-control__scroll__label"
            :text="translations.title"
          />
          <Separator size="xl" />
          <StackLayout
            @tap="editCommand"
            v-for="capability in getCapabilityCommands"
            :key="capability.id"
          >
            <IconHTxtiBtn
              class="device-control__scroll__content"
              icon2Src="icons/Controls/ic_controls_edit_56.svg"
              alignText="left"
              alignUnderline="left"
              headerSize="h3"
              :showUnderline="true"
              :disabled="true"
              :text="capability.commandName"
              :underlineText="capability.commandText"
            />
            <Separator size="xl" />
          </StackLayout>
        </StackLayout>
      </ScrollView>

      <StackLayout row="1" col="0" class="device-control__btn-container">
        <ButtonFilled
          :disabled="isDisabled"
          :text="translations.done"
          @tap="handleBack"
        />
      </StackLayout>
    </GridLayout>
  </MainPage>
</template>
<script>
import { required } from "vuelidate/lib/validators";

export default {
  name: "EditCapabilityPage",
  props: {
    capabilityId: {
      type: String,
      default: "0"
    }
  },
  data() {
    return {
      name: "",
      abstractDevices: {
        name: "Amazon Alexa",
        eMail: "user@gmail.com",
        src: "icons/Smarthomes/ic_smarthomes_amazon_alexa.svg"
      }
    };
  },
  computed: {
    getCapabilityCommands() {
      return [
        {
          id: "0",
          commandName: "On",
          commandText: "Turn On"
        },
        {
          id: "1",
          commandName: "Off",
          commandText: "Turn Off"
        }
      ];
    },
    translations() {
      // eslint-disable-next-line
      return this.$t("virtualDevices.editCapability");
    },
    capabilityName() {
      return "Turn on/off";
    },
    isDisabled() {
      return this.$v.name.$invalid;
    }
  },
  methods: {
    handleBack() {
      this.navigateBack();
    },
    editCommand() {
      this.navigateTo("EditCommandPage");
    }
  },
  validations() {
    return {
      name: {
        required
      }
    };
  }
};
</script>
<style lang="scss" scoped>
@import "~/assets/styles/variables.scss";

.device-control {
  height: 100%;
  &__scroll {
    height: 100%;

    &__label {
      padding: $xl $xl 0 $xl;
    }
    &__content {
      padding: 0 $xl;
    }
  }
  &__container {
    margin: 0 $xl;
  }
  &__btn-container {
    padding: 0 $xl $xxl $xl;
  }
  &__hint {
    text-align: left;
    color: $color-grey;
  }
  &__input-label {
    margin-bottom: $s;
    text-align: left;
    color: $color-black;
  }
  &__alexaImage {
    grid-column: 2;
    justify-self: end;
    width: unit(124);
    height: unit(124);
  }
  &__googleImage {
    grid-column: 3;
    justify-self: start;
    width: unit(124);
    height: unit(124);
  }
}
</style>
