import { deviceStatus } from "@/helpers/devicesState";
const { VUE_APP_MODE } = process.env;

export default {
  getLoadingState: state => key => state.loadingStates[key],
  getDevices: (state, getters, rootState, rootGetters) => {
    return state.devices.map(device => {
      const room = rootGetters["rooms/getRoomById"](device.roomId);
      const items = rootGetters["items/getItemsByDeviceId"](device.id);
      const isFavorite = rootGetters["favorites/isFavorite"](device.id);
      const icons = getters.getIconsByDeviceId(device.id);
      const status = getters.getDeviceStatusById(device.id);
      const deviceState = getters.getDeviceStateById(device.id);
      const loading = getters.getLoadingByDeviceId(device.id);
      const statusItem = getters.getDeviceStatusItemByDeviceId(device.id);
      return {
        ...device,
        status,
        loading,
        statusItem,
        title: device.name,
        room,
        icons,
        items,
        state: deviceState,
        isQuickAction: isFavorite
      };
    });
  },
  getDeviceById: (state, getters) => deviceId => {
    return state.devices.find(d => d.id === deviceId);
  },
  getSelectedDevices: (state, getters) => {
    const devices = state.selectedDevices.map(id => {
      const device = getters.getDeviceById(id);
      return {
        ...device,
        status: getters.getDeviceStatusById(id),
        loading: getters.getLoadingByDeviceId(id)
      };
    });
    return devices;
  },
  getDeviceOptionsById: (state, getters) => (deviceId = "") => {
    const device = getters.getDeviceById(deviceId);
    const deviceName = device && device.name;
    const deviceRoomName = device && device.room && device.room.name;
    const iconPassive = device && device.icons.defaultSrc;
    const iconActive = device && device.icons.white;
    const items = device && device.items;
    const reachable = device && device.reachable;

    return {
      ...device,
      deviceName,
      deviceRoomName,
      iconPassive,
      iconActive,
      items,
      reachable
    };
  },
  getLoadingItems: state => state.loadingItems,
  getLoadingByDeviceId: state => deviceId =>
    state.loadingItems.indexOf(deviceId) !== -1,
  getCurrentDevice: state => {
    return state.currentDeviceSettings;
  },
  getDeviceName: state => {
    return state.currentDeviceSettings.name;
  },
  getQuickActionStatus: state => {
    return state.currentDeviceSettings.isQuickAction;
  },
  getDeviceDetailsShortcutStatus: state => {
    // TODO There is no corresponding field on the API.
    return state.currentDeviceSettings.isDeviceDetailsShortcut;
  },
  getDeviceNotificationStatus: state => {
    return state.currentDeviceSettings.isNotifications;
  },
  getDeviceRooms: (state, getters, rootState, rootGetters) => {
    const roomId = state.currentDeviceSettings.roomId;
    return rootGetters["rooms/getRooms"].map(r => {
      return { ...r, selected: r.id === roomId };
    });
  },
  getDeviceCategory: state => {
    return state.currentDeviceSettings.deviceCategoryIcon;
  },
  getDeviceCategoryIconId: (state, getters) => {
    const item = getters.getDeviceCategory;
    return item && item.id;
  },
  getDeviceCategoryName: (state, getters) => {
    const item = getters.getDeviceCategory;
    return item && item.group;
  },
  getDeviceCategoryIcon: state => {
    const device = state.currentDeviceSettings;
    return device && device.iconSrc;
  },
  getGroupsState: state => {
    return state.devicesData.groups;
  },
  getItemsState: state => {
    return state.devicesData.items;
  },
  getDeviceIconsByCategoryName: (state, getters) => {
    return getters.getGroupsState.map(g => {
      const groupOptions = getters.getItemsState
        .filter(item => g.name === item.groupIconName)
        .map(({ icons, ...rest }) => {
          console.log("### icons"); // eslint-disable-line
          return { src: icons.default, srcActive: icons.green, ...rest };
        });

      return { groupTitle: g.name, groupOptions };
    });
  },
  getDevicesByRoom: (state, getters) => roomId => {
    return state.devices
      .filter(device => device.roomId === roomId)
      .map(item => {
        const {
          id,
          name: title,
          room,
          reachable,
          parentDeviceId,
          selected,
          category,
          subcategory,
          type,
          controller_id
        } = item;
        const caption = room && room.name;
        const status = getters.getDeviceStatusById(id);
        const loading = getters.getLoadingByDeviceId(id);
        const icons = getters.getIconsByCategory(category, subcategory);
        return {
          id,
          status,
          loading,
          title,
          caption,
          error: !reachable,
          parentDeviceId,
          selected,
          category,
          subcategory,
          type,
          src: icons.defaultSrc,
          srcActive: icons.white,
          icons,
          controller_id
        };
      });
  },
  getDevicesNoRoom: (state, getters, rootState, rootGetters) => {
    const rooms = rootGetters["rooms/getRooms"].map(room => room.id);
    return getters.getDevices.filter(
      device => !device.roomId || !rooms.includes(device.roomId)
    );
  },
  getDevicesTurnOnNotification: state => {
    return state.devices
      .filter(device => device.isNotifications)
      .map(device => device.id);
  },
  getDeviceStatusItemByDeviceId: (
    state,
    getters,
    rootState,
    rootGetters
  ) => id => {
    const items = rootGetters["items/getItemsByDeviceId"](id);
    return (
      items &&
      items.find(item_ =>
        [
          "switch",
          "dimmer",
          "window_cov",
          "temp",
          "humidity",
          "lux",
          "door_lock",
          "thermostat_setpoint"
        ].includes(item_.name)
      )
    );
  },
  getDeviceStatusById: (state, getters, rootState, rootGetters) => id => {
    const device = getters.getDeviceById(id);
    if (!device) return false;
    let status = "";
    if (device && device.data && device.data.states) {
      return deviceStatus(device);
    }

    const items = rootGetters["items/getItemsByDeviceId"](id);
    if (items) {
      // console.log("items", device.name, device, items)//eslint-disable-line
      const item = items.find(item_ =>
        [
          "switch",
          "dimmer",
          "window_cov",
          "temp",
          "humidity",
          "lux",
          "door_lock",
          "thermostat_setpoint",
          "dw_state"
        ].includes(item_.name)
      );
      if (item) {
        const scales = {
          celsius: "℃",
          fahreneit: "℉",
          fahrenheit: "℉",
          kelvin: "K",
          "": "K"
        };
        const dwState = {
          unknown: "Unlocked",
          dw_is_opened: "Opened",
          dw_is_closed: "Closed"
        };
        switch (item.name) {
          case "switch":
            status = item.value ? "On" : "Off";
            break;
          case "basic":
            status = item.deviceArmed ? "Armed" : "Disarmed";
            break;
          case "temp":
            if (typeof item.value === "object") {
              status =
                parseFloat(item.value.value).toFixed(2) +
                scales[item.value.scale];
            } else {
              status = parseFloat(item.value).toFixed(2) + scales[item.scale];
            }
            break;
          case "humidity":
            if (typeof item.value === "object") {
              status =
                item.value.scale === "percent"
                  ? parseFloat(item.value.value).toFixed(2) + "%"
                  : item.value.value;
            } else {
              status = item.value.toFixed(2) + "%";
            }
            break;
          case "lux":
            status = item.value + item.scale;
            break;
          case "door_lock":
            status = dwState[item.value];
            break;
          case "dw_state":
            status = item.value === "unknown" ? "Unlocked" : item.value;
            break;
          case "thermostat_setpoint":
            if (typeof item.value === "object") {
              status =
                parseFloat(item.value.value).toFixed(2) +
                scales[item.value.scale];
            } else {
              status = parseFloat(item.value).toFixed(2) + scales[item.scale];
            }
            break;
          case "dimmer":
            status = item.value ? "On" : "Off";
            break;
          default:
            status = "X";
            break;
        }
      } else {
        switch (device.category) {
          case "security_sensor":
            status = device.data.armed ? "Armed" : "Disarmed";
            break;
          case "googleHome":
          case "amazonAlexa":
            status = "";
            break;
          default:
            status = VUE_APP_MODE === "web" ? "Unreachable" : "Unreach\nable";
            break;
        }
        return status;
      }
      return status;
    }
    return "";
  },
  getDeviceStateById: (state, getters, rootState, rootGetters) => id => {
    // TODO: CHECK THIS METHOD, may not work well
    const device = getters.getDeviceById(id);
    if (!device) return false;

    let devState = "";
    if (device && device.data && device.data.states) {
      let states = device.data.states;
      // console.log("states", device.name, device, states)//eslint-disable-line
      for (let i = states.length - 1; i > -1; i--) {
        let state = states[i];
        if (
          device.category === "temperature" &&
          state.variable === "CurrentTemperature"
        ) {
          devState = "on";
          break;
        } else if (
          device.category === "security_sensor" &&
          (state.variable === "Armed" || state.variable === "Tripped")
        ) {
          if (state.variable === "Tripped" && state.value === "1") {
            devState = "tripped";
          } else {
            devState = state.value === "0" ? "disarmed" : "armed";
          }
          break;
        } else if (
          device.category === "humidity" &&
          state.variable === "CurrentLevel"
        ) {
          devState = "on";
          break;
        } else if (
          device.category === "generic" &&
          state.variable === "Status"
        ) {
          devState = state.value === "0" ? "disarmed" : "armed";
          break;
        } else if (
          device.category === "light_sensor" &&
          state.variable === "CurrentLevel"
        ) {
          devState = "on";
          break;
        } else {
          devState = "";
        }
      }
      return devState;
    }
    const items = rootGetters["items/getItemsByDeviceId"](id);
    if (items) {
      // console.log("items", device.name, device, items)//eslint-disable-line
      const item = items.find(item_ =>
        [
          "switch",
          "dimmer",
          "window_cov",
          "temp",
          "humidity",
          "lux",
          "door_lock",
          "thermostat_setpoint"
        ].includes(item_.name.toLowerCase())
      );
      if (item) {
        switch (item.name.toLowerCase()) {
          case "switch":
            devState = item.value ? "on" : "off";
            break;
          case "basic":
            devState = item.deviceArmed ? "armed" : "disarmed";
            break;
          case "temp":
            devState = "on";
            break;
          case "humidity":
            devState = "on";
            break;
          case "lux":
            devState = "on";
            break;
          case "door_lock":
            devState = item.value === "unknown" ? "unlocked" : "locked";
            break;
          case "thermostat_setpoint":
            devState = "on";
            break;
          case "dimmer":
            devState = item.value ? "on" : "off";
            break;
          default:
            devState = "general";
            break;
        }
      } else {
        switch (device.category) {
          case "security_sensor":
            devState = device.data.armed ? "armed" : "disarmed";
            break;
          case "door_lock":
            devState = device.data.status;
            break;
          default:
            devState = device.data.status || "";
            break;
        }
        return devState;
      }
      return devState;
    }
    return "";
  },
  getBigShade: state => {
    return state.windowCover;
  },
  getBatteryValueById: (state, getters) => id => {
    const device = getters.getDeviceById(id);
    const item = device && device.items.find(item => item.name === "battery");
    return item ? item.value : Number(-1);
  },
  getDevicesByFullRoom: (state, getters, rootState, rootGetters) => (
    orientation,
    controllerId = null
  ) => {
    const rooms = rootGetters["rooms/getRooms"];
    const groups = rooms.reduce((res, roomItem) => {
      const devices = getters.getDevicesByRoom(roomItem.id);
      const devicesRoom = devices.filter(device => {
        const isDeviceNotAdded = !state.selectedDevices[orientation].includes(
          device.id
        );
        if (controllerId) {
          if (controllerId === "no_hub") {
            return isDeviceNotAdded && device.type === "abstract";
          } else if (parseInt(controllerId) > 69999999) {
            return (
              isDeviceNotAdded &&
              (device.type === "abstract" ||
                device.controller_id === controllerId)
            );
          } else {
            return isDeviceNotAdded && device.controller_id === controllerId;
          }
        }
        return isDeviceNotAdded;
      });
      const roomHasDevices = Array.isArray(devicesRoom) && devicesRoom.length;
      if (!roomHasDevices) return res;
      const groupOptions = devicesRoom.map(device => ({
        ...device,
        loading: getters.getLoadingByDeviceId(device.id)
      }));
      res.push({
        groupId: roomItem.id,
        groupTitle: roomItem.name,
        groupDevices: groupOptions,
        groupOptions: groupOptions
      });

      return res;
    }, []);

    const noRoomDevices = getters.getDevicesNoRoom.filter(device => {
      const isDeviceNotAdded = !state.selectedDevices[orientation].includes(
        device.id
      );

      if (controllerId) {
        if (controllerId === "no_hub") {
          return isDeviceNotAdded && device.type === "abstract";
        } else if (parseInt(controllerId) > 69999999) {
          return (
            isDeviceNotAdded &&
            (device.type === "abstract" ||
              device.controller_id === controllerId)
          );
        } else {
          return isDeviceNotAdded && device.controller_id === controllerId;
        }
      }
      return !state.selectedDevices[orientation].includes(device.id);
    });
    if (noRoomDevices.length) {
      groups.push({
        groupId: "No rooms",
        groupTitle: "No rooms",
        groupDevices: noRoomDevices,
        groupOptions: noRoomDevices
      });
    }
    return groups;
  },
  getDevicesGroupByRoom: (state, getters) => filter => {
    const groupedRooms = getters.getDevicesByFullRoom;
    if (filter && Array.isArray(filter.value)) {
      return groupedRooms.map(item => {
        const result = {
          groupTitle: item.groupTitle,
          groupOptions: item.groupOptions.filter(device =>
            filter.filterType === "includes"
              ? filter.value.includes(device[filter.key])
              : !filter.value.includes(device[filter.key])
          )
        };
        return result;
      });
    } else {
      return groupedRooms;
    }
  },
  getDevicesByFilters: (state, getters) => (
    searchValue = "",
    accountValue = ""
  ) => {
    const groupedRooms = getters.getDevicesByFullRoom;
    // TODO: add filters by search && account

    return groupedRooms;
  },
  getCategories: state => {
    let groups = [
      ...new Set(
        state.devices.map(item => {
          return item.category;
        })
      )
    ];
    return groups;
  },
  getDevicesByCategory: (state, getters, rootState, rootGetters) => (
    category,
    orientation,
    controllerId
  ) => {
    // console.log("controllerId",controllerId)
    const devices = controllerId
      ? state.devices.filter(
          device =>
            device.controller_id === controllerId || device.type === "abstract"
        )
      : state.devices;
    return devices
      .filter(
        device =>
          device.category === category &&
          !state.selectedDevices[orientation].includes(device.id)
      )
      .map(item => {
        const {
          id,
          name: title,
          roomId,
          data: { status },
          reachable,
          parentDeviceId,
          selected,
          category,
          subcategory,
          type
        } = item;
        const icons = getters.getIconsByCategory(category, subcategory);
        const room = rootGetters["rooms/getRoomById"](roomId).name;

        // const status = data.status;

        return {
          src: icons.default,
          srcActive: icons.white,
          status: getters.getDeviceStatusById(id),
          loading: getters.getLoadingByDeviceId(id),
          title,
          id,
          room,
          error: !reachable,
          parentDeviceId,
          selected,
          category,
          subcategory,
          type,
          icons
        };
      });
  },
  getDevicesGroupByCategory: (state, getters, rootState, rootGetters) => (
    orientation,
    controllerId
  ) => {
    const categories = getters.getCategories;
    const devices = categories.map(category => {
      const result = {
        groupId: category,
        groupTitle:
          rootGetters["categories/getCategoryName"](category) || category,
        groupDevices: getters.getDevicesByCategory(
          category,
          orientation,
          controllerId
        )
      };
      return result;
    });
    return devices;
  },
  getIconsByDeviceId: (state, getters, rootState, rootGetters) => deviceId => {
    const {
      category: deviceCategory,
      subcategory: deviceSubCategory
    } = state.devices.find(d => d.id === deviceId);

    return rootGetters["categories/getIconsByCategory"](
      deviceCategory,
      deviceSubCategory
    );
  },
  getIconsByCategory: (state, getters, rootState, rootGetters) => (
    deviceCategory,
    deviceSubCategory
  ) => {
    return rootGetters["categories/getIconsByCategory"](
      deviceCategory,
      deviceSubCategory
    );
  }
};
