import { SET_ABSTRACTS } from "./mutationTypes";
import cloudService from "@/services/cloud.service";
import { HttpError } from "@/helpers/customErrors";
import { parseHttpResponse } from "@/helpers/responseParser";

export default {
  async fetchAbstractDevices({ commit, dispatch, rootGetters }) {
    try {
      dispatch("ui/startFetching", "fetchAbstractDevices", { root: true });
      const authJwtToken = rootGetters["auth/getCloudJwtToken"];

      const response = await cloudService.getAbstractDevices({ authJwtToken });
      const { data: content, statusCode } = await parseHttpResponse(
        response,
        "json"
      );
      if (statusCode !== 200) {
        throw new HttpError("Api cloud error or other", statusCode);
      }
      const { data } = content;
      commit(SET_ABSTRACTS, data.abstracts);
      dispatch("createDevicesFromAbstracts");
    } catch (err) {
      console.log(err); //eslint-disable-line
    } finally {
      dispatch("ui/stopFetching", "fetchAbstractDevices", { root: true });
    }
  },
  createDevicesFromAbstracts({ commit, dispatch, getters }) {
    // googleHome, amazonAlexa
    const abstracts = getters.getAbstracts.filter(item =>
      item.capabilities.some(element => element.startsWith("ui_flag_"))
    );
    dispatch("devices/createDevicesFromAbstracts", abstracts, { root: true });
  }
};
