<template>
  <GridLayout
    ref="container"
    class="size-slider"
    rows="auto"
    columns="*"
    @tap="handleTap"
  >
    <StackLayout
      ref="slider"
      row="0"
      col="0"
      class="size-slider__block"
      verticalAlignment="center"
    />
    <StackLayout ref="title" row="0" col="0" class="size-slider__title">
      <Label :class="titleClass" :text="titleText" />
    </StackLayout>
    <StackLayout
      row="0"
      col="0"
      horizontalAlignment="left"
      ref="curseur"
      class="size-slider__curseur"
    />
  </GridLayout>
</template>

<script>
import platform from "vuexp/src/platform";

const checkWidth = (width, maxWidth) => {
  if (width <= 0) return 0;
  if (width >= maxWidth) return maxWidth;
  return width;
};

export default {
  name: "HourSlider",
  props: {
    animationSpeed: {
      type: Number,
      default: 100
    },
    initialValue: {
      type: Number,
      default: 0
    },
    values: {
      type: Number,
      default: 4
    },
    valueTitles: {
      type: Array,
      default: () => ["12pm", "1pm", "2pm", "3pm"]
    },
    from: {
      type: String
    },
    to: {
      type: String
    }
  },
  data() {
    return {
      value: this.initialValue,
      prevClientX: 0,
      isPanning: false
    };
  },
  watch: {
    initialValue(value) {
      if (this.value !== value) {
        this.value = value;
        this.translateX(this.value);
        this.$emit("valueChange", {
          index: this.value,
          value: this.valueTitles[this.value]
        });
      }
    }
  },
  methods: {
    handleTap(event) {
      this.setX(event);
    },
    getClientX(event) {
      return event.clientX
        ? event.clientX
        : event.touches[0]
        ? event.touches[0].clientX
        : this.prevClientX;
    },
    handlePan(event) {
      const { curseur, title } = this.$refs;
      if (curseur) {
        let position = null;
        let titlePosition = null;
        const clientX = this.getClientX(event);
        if (clientX) {
          position = clientX - curseur.$el.getBoundingClientRect().width - 4;
          titlePosition =
            position - (title.$el.getBoundingClientRect().width - 32) / 2;
        }

        if (this.isPanning && clientX > 0 && clientX < this.getSliderWidth()) {
          curseur.$el.style.transform = `translateX(${position}px)`;
          title.$el.style.transform = `translate(${titlePosition}px,-20px)`;
          this.prevClientX = clientX;
        }
      }
    },
    getSliderWidth() {
      return this.$refs.slider.$el.getBoundingClientRect().width;
    },
    setX(translateX) {
      const { slider, curseur, title } = this.$refs;
      if (curseur && slider) {
        const clientX = this.getClientX(translateX);
        let position = clientX - curseur.$el.getBoundingClientRect().width - 4;

        const value = this.getValue(position);
        this.translateX(value);
        this.$emit("valueChange", {
          index: this.value,
          value: this.valueTitles[this.value]
        });
        this.$emit("dragEnd", {
          index: this.value,
          value: this.valueTitles[this.value]
        });
      }
    },
    translateX(value) {
      const { curseur, title } = this.$refs;
      if (curseur && title) {
        const x = this.getX(value);
        curseur.$el.style.transform = `translateX(${x}px)`;
        title.$el.style.transform = `translate(${x}px,-20px)`;
      }
    },
    setPosition(value) {
      this.value = value;
      this.translateX(value);
    },
    getX(value) {
      return checkWidth(
        value * (this.getSliderWidth() / (this.values - 1)),
        this.getSliderWidth()
      );
    },
    getValue(x) {
      if (x <= 0) return 0;
      if (x >= this.getSliderWidth()) return this.values - 1;
      const value = Math.round((this.values - 1) * (x / this.getSliderWidth()));
      this.value = value;
      return value > this.values - 1 ? this.values - 1 : value < 0 ? 0 : value;
    },
    startPan(event) {
      this.handlePan(event);
      this.isPanning = true;
    },
    stopPan(event) {
      this.isPanning = false;
      this.setX(event);
    },
    onResize(event) {
      this.translateX(this.value);
    }
  },
  computed: {
    titleClass() {
      return this.isPanning
        ? "font-txt-16-par size-slider__title__panning"
        : "font-txt-13 size-slider__title__not-panning";
    },
    titleText() {
      return this.valueTitles[this.value];
    },
    getInnerWidth() {
      return window.innerWidth;
    }
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  mounted() {
    const { container, title, slider } = this.$refs;
    if ((container, title)) {
      container.$el.addEventListener("mousedown", this.startPan);
      container.$el.addEventListener("mouseup", this.stopPan);
      container.$el.addEventListener("mousemove", this.handlePan);

      container.$el.addEventListener("touchstart", this.startPan);
      container.$el.addEventListener("touchend", this.stopPan);
      container.$el.addEventListener("touchmove", this.handlePan);

      title.$el.style.transform = "translate(0px,-15px)";

      this.value = this.initialValue;
      this.translateX(this.value);
    }
  },
  destroyed() {
    const container = this.$refs.container;
    if (container) {
      container.$el.removeEventListener("mousedown");
      container.$el.removeEventListener("mouseup");
      container.$el.removeEventListener("mousemove");

      container.$el.removeEventListener("touchstart");
      container.$el.removeEventListener("touchend");
      container.$el.removeEventListener("touchmove");

      window.removeEventListener("resize", this.onResize);
    }
  }
};
</script>

<style scoped lang="scss">
@import "~/assets/styles/variables.scss";

.size-slider {
  padding: $s 0;
  &__from {
    margin-right: $l;
  }
  &__to {
    margin-left: $l;
  }
  &__block {
    margin: 0 $ml;
    margin-top: $xxs;
    justify-content: center;
    height: $xl;
    border-radius: $l;
    background: $color-grey-a25;
  }
  &__curseur {
    width: $xxm;
    height: $xxm;
    border-radius: 100%;
    border-width: $stroke;
    border-style: solid;
    border-color: $color-grey-a25;
    background: $color-white;
    box-shadow: 0px 4px 8px rgba(144, 160, 175, 0.7);
  }
  &__title {
    position: absolute;
    padding-left: $xxs;
    &__panning {
      color: $color-black;
      transform: translateY(-42px);
      background: white;
      height: 56px;
      vertical-align: center;
      justify-content: center;
      line-height: 56px !important;
      padding: 0 $l;
      border-radius: $l;
      box-shadow: 0px 8px 24px rgba(144, 160, 175, 0.3);
    }
    &__not-panning {
      color: $color-grey;
      padding-bottom: $xl;
    }
  }
}
</style>
