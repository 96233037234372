import Vue from "@/vue";
import VueI18n from "vue-i18n";

const ALL_LOCALES = ["en", "fr"];
const LOADED_LOCALES = [];
const DEFAULT_LOCALE = "en";

function getAvailableLocales() {
  return ALL_LOCALES;
}

function getAllLocales() {
  let locales = {};
  ALL_LOCALES.forEach(locale => {
    LOADED_LOCALES.push(locale);
    locales[locale] = require(`../locales/${locale}.js`);
  });
  return locales;
}

// function getDefaultLocale() {
//   LOADED_LOCALES.push(DEFAULT_LOCALE);
//   return {
//     [DEFAULT_LOCALE]: require(`../locales/${DEFAULT_LOCALE}.js`)
//   };
// }

// i18n
Vue.use(VueI18n);
export const i18n = new VueI18n({
  locale: DEFAULT_LOCALE, // set locale
  fallbackLocale: DEFAULT_LOCALE,
  messages: getAllLocales(),
  availableLocales: getAvailableLocales()
});
