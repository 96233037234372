<template>
  <GridLayout
    rows="*"
    columns="*"
    class="theme-template"
    :backgroundColor="
      getThemeBackgroundImage ? 'transparent' : colorHexA(theme.backgroundColor)
    "
  >
    <ImageCacheIt
      row="0"
      col="0"
      class="theme-template__image"
      v-show="getThemeBackgroundImage"
      :src="getThemeBackgroundImage"
      stretch="aspectFill"
    />
    <GridLayout
      row="0"
      col="0"
      class="theme-template__tile-items"
      rows="103, 103"
      columns="*, * ,*"
    >
      <TileItemTemplate
        v-for="(item, index) in tiles"
        :key="index"
        :row="item.row"
        :col="item.col"
        :showBar1="item.showBar1"
        :backgroundImage="item.backgroundImage"
        :backgroundColor="item.backgroundColor"
        :iconColor="item.iconColor"
        :fontColor="item.fontColor"
      />
    </GridLayout>
  </GridLayout>
</template>

<script>
import TileItemTemplate from "@/components/theme/TileItemTemplate";
import { colorHexA } from "@/helpers/utils";

const { VUE_APP_MODE } = process.env;
const initialOptions = {
  background_color: "#ffffff",
  use_background_image: false,
  background_image: "",
  font_color: "#fffff",
  icon_color: "#cccfff"
};

export default {
  name: "ThemeTemplate",
  components: {
    TileItemTemplate
  },
  props: {
    themeOptions: {
      type: Object,
      default: () => cloneDeep(initialOptions)
    },
    tileOptions: {
      type: Object,
      default: () => cloneDeep(initialOptions)
    }
  },
  computed: {
    getThemeBackgroundImage() {
      return this.theme.backgroundImage;
    },
    colorHexA() {
      return colorHexA;
    },
    theme() {
      return {
        useBackgroundImage: this.themeOptions.use_background_image,
        backgroundImage: this.themeOptions.background_image,
        backgroundColor: this.themeOptions.background_color
      };
    },
    tile() {
      return {
        useBackgroundImage: this.tileOptions.use_background_image,
        backgroundImage: this.tileOptions.background_image,
        backgroundColor: this.tileOptions.background_color,
        fontColor: this.tileOptions.font_color,
        iconColor: this.tileOptions.icon_color
      };
    },
    tiles() {
      const options = {
        backgroundImage: this.tile.backgroundImage,
        backgroundColor: this.tile.backgroundColor,
        iconColor: this.tile.iconColor,
        fontColor: this.tile.fontColor
      };
      return [
        {
          row: "0",
          col: "0",
          showBar1: true,
          ...options
        },
        {
          row: "0",
          col: "1",
          showBar1: false,
          ...options
        },
        {
          row: "0",
          col: "2",
          showBar1: false,
          ...options
        },
        {
          row: "1",
          col: "0",
          showBar1: false,
          ...options
        },
        {
          row: "1",
          col: "1",
          showBar1: false,
          ...options
        },
        {
          row: "1",
          col: "2",
          showBar1: false,
          ...options
        }
      ];
    }
  }
};
</script>

<style scoped lang="scss">
@import "~/assets/styles/variables.scss";
.theme-template {
  width: 100%;
  &__image {
    object-fit: fill;
    height: -webkit-fill-available;
    height: -moz-available;
  }
  &__tile-items {
    padding: $padding-xl;
    grid-gap: unit(9);
  }
}
</style>
