<template>
  <StackLayout height="100%" v-show="getStatus">
    <Separator size="xl" />
    <StackLayout v-for="item in items" :key="item[idKey]">
      <IconHTxtiBtn
        alignText="left"
        alignUnderline="left"
        headerSize="h3"
        showUnderline
        :text="item.name"
        :underlineText="item.desc"
        :icon1Src="getDeleteIcon(item)"
        :icon2Src="getIcon(item)"
        :disabled="disabled(item)"
        @tap="tap(item)"
        @iconTap1="onDelete(item)"
      />
      <Separator size="xl" />
    </StackLayout>
  </StackLayout>
</template>
<script>
export default {
  name: "ThemeList",
  props: {
    editMode: {
      type: Boolean,
      default: false
    },
    listMode: {
      type: String,
      default: "",
      validator: value => {
        return ["radio", "arrow", ""].includes(value);
      }
    },
    deleteButton: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => []
    },
    arrowIcon: {
      type: String,
      default: "icons/Controls/ic_controls_arrow-right_56.svg"
    },
    iconOn: {
      type: String,
      default: "icons/Controls/ic_controls_radio-on-primary_56.svg"
    },
    iconOff: {
      type: String,
      default: "icons/Controls/ic_controls_radio-off_56.svg"
    },
    iconDelete: {
      type: String,
      default: "icons/Controls/ic_controls_delete_orange_56.svg"
    },
    iconDeleteDisabled: {
      type: String,
      default: "icons/Controls/ic_controls_delete_disabled_56.svg"
    },
    selectedItem: {
      type: Object,
      default: undefined
    },
    idKey: {
      type: String,
      default: "id"
    }
  },
  computed: {
    getStatus() {
      return !!this.items.length;
    }
  },
  methods: {
    tap(item) {
      if (this.disabled(item)) return;
      this.$emit("onSelect", item);
    },
    onDelete(item) {
      if (this.disabled(item)) return;
      this.$emit("onDelete", item);
    },
    disabled(item) {
      return this.editMode && item.predefined;
    },
    selected(item) {
      return (
        this.selectedItem &&
        this.selectedItem[this.idKey] &&
        this.selectedItem[this.idKey] === item[this.idKey]
      );
    },
    getDeleteIcon(item) {
      if (!this.deleteButton) {
        return "";
      } else {
        return this.disabled(item)
          ? this.iconDeleteDisabled
          : this.editMode
          ? this.iconDelete
          : "";
      }
    },
    getIcon(item) {
      if (this.listMode === "radio") {
        return this.selected(item) ? this.iconOn : this.iconOff;
      } else if (this.listMode === "arrow") {
        return this.arrowIcon;
      } else {
        if (this.editMode) {
          return item.predefined ? this.iconOff : this.arrowIcon;
        } else {
          return this.selected(item) ? this.iconOn : this.iconOff;
        }
      }
    }
  }
};
</script>
