import DevelopmentPage from "./pages/development/DevelopmentPage";
//onboarding
import LoginByUserIntro from "./pages/onboarding/LoginByUserIntro";
import SignUpIntro from "./pages/onboarding/SignUpIntro";
import ForgotPasswordEmailIntro from "./pages/onboarding/ForgotPasswordEmailIntro";
import ForgotPasswordEmailSend from "./pages/onboarding/ForgotPasswordEmailSend";
import ForgotPasswordEmailReset from "./pages/onboarding/ForgotPasswordEmailReset";
import ForgotPasswordEmailCheck from "./pages/onboarding/ForgotPasswordEmailCheck";
import ForgotPasswordEmailNewPassword from "./pages/onboarding/ForgotPasswordEmailNewPassword";
//settings
import Profile from "./pages/settings/Profile";
import ProfileCurrentPassword from "./pages/settings/ProfileCurrentPassword";
import ProfileCreatePassword from "./pages/settings/ProfileCreatePassword";
import ProfileFullName from "./pages/settings/ProfileFullName";
//dashboard
import NewDashboard from "./pages/dashboard/NewDashboard";
import DashboardPreview from "./pages/dashboard/DashboardPreview";
import DashboardList from "./pages/dashboard/DashboardList";
import DashboardSelect from "./pages/dashboard/DashboardSelect";
import DashboardTemplate from "./pages/dashboard/DashboardTemplate";
import DashboardTemplateType from "./pages/dashboard/DashboardTemplateType";
import DashboardSettings from "./pages/dashboard/DashboardSettings";
import TileTypes from "./pages/dashboard/TileTypes";
import SelectMenuItems from "./pages/dashboard/SelectMenuItems";
// import StyleSettingsBgImage from "./pages/dashboard/StyleSettingsBgImage";
import Devices from "./pages/device/Devices";
import Scenes from "./pages/device/Scenes";
import NewLinkTile from "./pages/dashboard/NewLinkTile";
import FontFamily from "./pages/dashboard/FontFamily";
import FontSize from "./pages/dashboard/FontSize";
import DashboardName from "./pages/dashboard/DashboardName";
import TileSettings from "./pages/dashboard/TileSettings";
import TileStates from "./pages/dashboard/TileStates";
import Background from "./pages/dashboard/Background";
import LoadingPage from "./pages/LoadingPage";
import LoginWithBounceTokenPage from "./pages/LoginWithBounceTokenPage";
import ColorSelect from "./pages/dashboard/ColorSelect";
import DeviceToControl from "./pages/virtualDevices/DeviceToControl";
import AddDevice from "./pages/device/AddDevice";
import DeviceCapabilities from "./pages/virtualDevices/DeviceCapabilities";
import CapabilitySearchPage from "./pages/virtualDevices/CapabilitySearchPage";
import SelectCapabilities from "./pages/virtualDevices/SelectCapabilities";
import EditCapabilityPage from "./pages/virtualDevices/EditCapabilityPage";
import EditCommandPage from "./pages/virtualDevices/EditCommandPage";
import NewVirtualDevice from "./pages/virtualDevices/NewVirtualDevice";
import ConfigureCapabilityPage from "./pages/virtualDevices/ConfigureCapabilityPage";
import CapabilityErrorPage from "./pages/virtualDevices/CapabilityErrorPage";
import SetDimmerPage from "./pages/virtualDevices/SetDimmerPage";
import DefaultDimmerScalePage from "./pages/virtualDevices/DefaultDimmerScalePage";
import DetectionOptions from "./pages/virtualDevices/DetectionOptions";
import AddSetPoint from "./pages/virtualDevices/AddSetPoint";
import Energy from "./pages/energyMetering/Energy";
import DeviceEnergyDetails from "./pages/energyMetering/DeviceEnergyDetails";
import Climate from "./pages/energyMetering/Climate";
import ConsumptionDetails from "./pages/energyMetering/ConsumptionDetails";
import IconList from "./pages/dashboard/IconList";
import NewGoal from "./pages/goals/NewGoal";
import NewGoalDevice from "./pages/goals/NewGoalDevice";
import InformationDialog from "./pages/dialogs/InformationDialog";
import GoalSaveConfirmationDialog from "./pages/goals/GoalSaveConfirmationDialog";
import Confirmation from "./pages/dialogs/Confirmation";
import DateTimeSelect from "./pages/dateTimeTile/DateTimeSelect";
import IconSetSelection from "./pages/icons/IconSetSelection";
import IconSetsList from "./pages/icons/IconSetsList";
import CustomIcons from "./pages/icons/CustomIcons";
import HouseModeSelect from "./pages/houseMode/HouseModeSelect";
import PanicTypeSelect from "./pages/panic/PanicTypeSelect";
import TemplateMode from "./pages/dashboard/TemplateMode";
import ThemeSettings from "./pages/theme/ThemeSettings";
import EditTheme from "./pages/theme/EditTheme";
import Tutorial from "./pages/dialogs/Tutorial";
import ThemeName from "./pages/theme/ThemeName";
export const routerOptions = {
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "LoadingPage",
      component: LoadingPage
    },
    {
      path: "/dev",
      name: "DevelopmentPage",
      component:
        process.env.NODE_ENV === "production" ? LoadingPage : DevelopmentPage
    },
    {
      path: "/login-user-with-token",
      name: "LoginWithBounceTokenPage",
      component: LoginWithBounceTokenPage,
      props: true
    },
    {
      path: "/automatic-login",
      name: "AutomaticLogin",
      component: LoadingPage,
      props: true
    },
    //for freemium
    // {
    //   path: "/dashboard-select",
    //   name: "DashboardSelect",
    //   component: DashboardSelect,
    //   props: true
    // },
    {
      path: "/sign-up-intro",
      name: "SignUpIntro",
      component: SignUpIntro,
      props: true
    },
    {
      path: "/forgot-password-email-intro",
      name: "ForgotPasswordEmailIntro",
      component: ForgotPasswordEmailIntro,
      props: true
    },
    {
      path: "/forgot-password-email-send",
      name: "ForgotPasswordEmailSend",
      component: ForgotPasswordEmailSend,
      props: true
    },
    {
      path: "/forgot-password-email-reset",
      name: "ForgotPasswordEmailReset",
      component: ForgotPasswordEmailReset,
      props: true
    },
    {
      path: "/forgot-password-email-check",
      name: "ForgotPasswordEmailCheck",
      component: ForgotPasswordEmailCheck,
      props: true
    },
    {
      path: "/forgot-password-email-new-password",
      name: "ForgotPasswordEmailNewPassword",
      component: ForgotPasswordEmailNewPassword,
      props: true
    },
    {
      path: "/login-user-intro",
      name: "LoginByUserIntro",
      component: LoginByUserIntro,
      props: true
    },
    {
      path: "/loading-page",
      name: "LoadingPage",
      component: LoadingPage,
      props: true
    },
    {
      path: "/settings-profile",
      name: "Profile",
      component: Profile,
      props: true
    },
    {
      path: "/settings-profile-fullname",
      name: "ProfileFullName",
      component: ProfileFullName,
      props: true
    },
    {
      path: "/settings-profile-current-password",
      name: "ProfileCurrentPassword",
      component: ProfileCurrentPassword,
      props: true
    },
    {
      path: "/settings-profile-new-password",
      name: "ProfileCreatePassword",
      component: ProfileCreatePassword,
      props: true
    },
    {
      path: "/new-dashboard",
      name: "NewDashboard",
      component: NewDashboard,
      props: true
    },
    {
      path: "/dashboard-preview",
      name: "DashboardPreview",
      component: DashboardPreview,
      props: true
    },
    {
      path: "/dashboard-list",
      name: "DashboardList",
      component: DashboardList,
      props: true
    },
    {
      path: "/dashboard-settings",
      name: "DashboardSettings",
      component: DashboardSettings,
      props: true
    },
    // {
    //   path: "/style-settings-bg-image",
    //   name: "StyleSettingsBgImage",
    //   component: StyleSettingsBgImage,
    //   props: true
    // },
    {
      path: "/background",
      name: "Background",
      component: Background,
      props: true
    },
    {
      path: "/dashboard-template",
      name: "DashboardTemplate",
      component: DashboardTemplate,
      props: true
    },
    {
      path: "/dashboard-template-type",
      name: "DashboardTemplateType",
      component: DashboardTemplateType,
      props: true
    },
    {
      path: "/tile-types",
      name: "TileTypes",
      component: TileTypes,
      props: true
    },
    {
      path: "/select-menu-items",
      name: "SelectMenuItems",
      component: SelectMenuItems,
      props: true
    },
    {
      path: "/devices",
      name: "Devices",
      component: Devices,
      props: true
    },
    {
      path: "/scenes",
      name: "Scenes",
      component: Scenes,
      props: true
    },
    //for freemium
    // {
    //   path: "/new-link-tile",
    //   name: "NewLinkTile",
    //   component: NewLinkTile,
    //   props: true
    // },
    // {
    //   path: "/font-family",
    //   name: "FontFamily",
    //   component: FontFamily,
    //   props: true
    // },
    // {
    //   path: "/font-size",
    //   name: "FontSize",
    //   component: FontSize,
    //   props: true
    // },
    {
      path: "/dashboard-name",
      name: "DashboardName",
      component: DashboardName,
      props: true
    },
    {
      path: "/tile-settings",
      name: "TileSettings",
      component: TileSettings,
      props: true
    },
    {
      path: "/tile-states",
      name: "TileStates",
      component: TileStates,
      props: true
    },
    {
      path: "/color-select",
      name: "ColorSelect",
      component: ColorSelect,
      props: true
    },
    {
      path: "/add-device",
      name: "AddDevice",
      component: AddDevice,
      props: true
    },
    {
      path: "/device-to-control",
      name: "DeviceToControl",
      component: DeviceToControl,
      props: true
    },
    {
      path: "/device-capabilities",
      name: "DeviceCapabilities",
      component: DeviceCapabilities,
      props: true
    },
    {
      path: "/capability-search",
      name: "CapabilitySearchPage",
      component: CapabilitySearchPage,
      props: true
    },
    {
      path: "/select-capabilities",
      name: "SelectCapabilities",
      component: SelectCapabilities,
      props: true
    },
    {
      path: "/edit-capability",
      name: "EditCapabilityPage",
      component: EditCapabilityPage,
      props: true
    },
    {
      path: "/edit-command",
      name: "EditCommandPage",
      component: EditCommandPage,
      props: true
    },
    {
      path: "/new-virtual-device",
      name: "NewVirtualDevice",
      component: NewVirtualDevice,
      props: true
    },
    {
      path: "/edit-virtual-device",
      name: "NewVirtualDevice",
      component: NewVirtualDevice,
      props: true,
      options: {
        props: {
          isEditMode: true
        }
      }
    },
    {
      path: "/configure-capability",
      name: "ConfigureCapabilityPage",
      component: ConfigureCapabilityPage,
      props: true
    },
    {
      path: "/capability-error",
      name: "CapabilityErrorPage",
      component: CapabilityErrorPage,
      props: true
    },
    {
      path: "/set-dimmer-page",
      name: "SetDimmerPage",
      component: SetDimmerPage,
      props: true
    },
    {
      path: "/default-dimmer-scale-page",
      name: "DefaultDimmerScalePage",
      component: DefaultDimmerScalePage,
      props: true
    },
    {
      path: "/detection-options",
      name: "DetectionOptions",
      component: DetectionOptions,
      props: true
    },
    {
      path: "/add-set-point",
      name: "AddSetPoint",
      component: AddSetPoint,
      props: true
    },
    {
      path: "/energy",
      name: "Energy",
      component: Energy,
      props: true
    },
    {
      path: "/device-energy-details",
      name: "DeviceEnergyDetails",
      component: DeviceEnergyDetails,
      props: true
    },
    {
      path: "/climate",
      name: "Climate",
      component: Climate,
      props: true
    },
    {
      path: "/consumption-details",
      name: "ConsumptionDetails",
      component: ConsumptionDetails,
      props: true
    },
    {
      path: "/icon-list",
      name: "IconList",
      component: IconList,
      props: true
    },
    {
      path: "/new-goal",
      name: "NewGoal",
      component: NewGoal,
      props: true
    },
    {
      path: "/new-goal-device",
      name: "NewGoalDevice",
      component: NewGoalDevice,
      props: true
    },
    {
      path: "/information-dialog",
      name: "InformationDialog",
      component: InformationDialog,
      props: true,
      options: {
        props: {
          icon: "icons/Visuals/ic_visuals_history_coming-soon_200.svg",
          text1: "text1",
          text2: "text2",
          actionText: "actionText",
          warning: false
        }
      }
    },
    {
      path: "/error-dialog",
      name: "ErrorDialog",
      component: InformationDialog,
      props: true,
      options: {
        props: {
          icon: "icons/Visuals/ic_warning_visuals_not-responding_200.svg",
          text1: "Sorry, that didn't go through",
          text2:
            "It's possible there are some connection issues. Please try again.",
          actionText: "Got it",
          warning: true
        }
      }
    },
    {
      path: "/goal-save-confirmation-dialog",
      name: "GoalSaveConfirmationDialog",
      component: GoalSaveConfirmationDialog,
      props: true,
      options: {
        props: {
          icon: "icons/Warnings/ic_warning_200.svg",
          text1: "text1",
          text2: "text2",
          btnTextAccept: "btnTextAccept",
          btnTextCancel: "btnTextCancel",
          warning: true
        }
      }
    },
    {
      path: "/confirmation",
      name: "Confirmation",
      component: Confirmation,
      props: true,
      options: {
        props: {
          icon: "icons/Visuals/ic_visuals_history_coming-soon_200.svg",
          title: "core.disableMonitoring.title",
          description: "core.disableMonitoring.description",
          btnTextAccept: "core.disableMonitoring.btnTextAccept",
          btnTextCancel: "core.disableMonitoring.btnTextCancel",
          warning: true
        }
      }
    },
    {
      path: "/select-date-time",
      name: "DateTimeSelect",
      component: DateTimeSelect,
      props: true
    },
    {
      path: "/icon-set-selection",
      name: "IconSetSelection",
      component: IconSetSelection,
      props: true
    },
    {
      path: "/custom-icons",
      name: "CustomIcons",
      component: CustomIcons,
      props: true
    },
    {
      path: "/icon-sets-list",
      name: "IconSetsList",
      component: IconSetsList,
      props: true
    },
    {
      path: "/leave-without-changes",
      name: "LeaveWithoutChanges",
      component: Confirmation,
      props: true,
      options: {
        props: {
          icon: "icons/Visuals/ic_warning_logout_200.svg",
          title: "core.leaveWithoutChanges.title",
          description: "core.leaveWithoutChanges.description",
          btnTextAccept: "core.leaveWithoutChanges.btnTextAccept",
          btnTextCancel: "Cancel",
          warning: true,
          resultActionName: "leaveWithoutChanges" // this.$options.name
        }
      }
    },
    {
      path: "/house-mode-select",
      name: "HouseModeSelect",
      component: HouseModeSelect,
      props: true
    },
    {
      path: "/panic-type-select",
      name: "PanicTypeSelect",
      component: PanicTypeSelect,
      props: true
    },
    {
      path: "/template-mode",
      name: "TemplateMode",
      component: TemplateMode,
      props: true
    },
    {
      path: "/theme-settings",
      name: "ThemeSettings",
      component: ThemeSettings,
      props: true
    },
    {
      path: "/edit-theme",
      name: "EditTheme",
      component: EditTheme,
      props: true
    },
    {
      path: "/tutorial",
      name: "Tutorial",
      component: Tutorial,
      props: true,
      options: {
        props: {
          icon: "icons/Tutorial/Anim_Tips-Tap0.svg",
          title: "core.tapToCustomizeASpesificTile.title",
          description: "core.tapToCustomizeASpesificTile.description",
          btnTextAccept: "core.tapToCustomizeASpesificTile.btnTextAccept",
          warning: false,
          resultActionName: "tutorialAccept" // this.$options.name
        }
      }
    },
    {
      path: "/theme-name",
      name: "ThemeName",
      component: ThemeName,
      props: true
    }
  ]
};

// Register Route pages
export default {
  install(Vue) {
    routerOptions.routes.forEach(r => {
      Vue.component(r.name, r.component);
    });
  }
};
