import request from "./http";
import endpoints from "./endpoints";
import stringInject from "stringinject";
import sha1 from "sha1";
import apiConfig from "../../config/api";
import WebSocketConnection from "./ws.service";

const { cloud, dashboards, hub, abstract } = endpoints;
const { cloudApiFullUrl, cloudXAppToken, cloudApiUrl } = apiConfig;

let WS = null;

const dashboardsApi = {
  // WEBSOCKET
  isOnline: () => {
    return WS ? WS.isOnline() : false;
  },
  socketConnect: async ({ authJwtToken = "", onReconnect }) => {
    if (WS) {
      await WS.close();
      WS = null;
    }
    WS = new WebSocketConnection();
    var headers = {
      Authorization: `${authJwtToken}`,
      "X-App": cloudXAppToken
    };
    const url = "wss://" + cloudApiUrl + "/v1" + cloud.websocket;
    await WS.start({ url, headers, onReconnect });
  },

  sendSocketMessage: async ({ call, params }) => {
    return WS.send(call, params);
  },
  subscribe: async (channel, cb) => WS.subscribe(channel, cb),

  unsubscribe: async (channel, cb) => WS.unsubscribe(channel, cb),

  // HTTP API
  accessKeysAsync: async ({ authJwtToken = "" }) => {
    const url = cloud.request;
    const headers = {
      Authorization: `Bearer ${authJwtToken}`,
      "X-App": cloudXAppToken
    };
    const params = {
      call: cloud.keys.get,
      params: {}
    };

    return await request({
      method: "POST",
      baseUrl: cloudApiFullUrl(),
      url,
      headers,
      params
    });
  },
  controllerGetReport: async ({ authJwtToken = "", controllers }) => {
    const url = cloud.request;
    const headers = {
      Authorization: `Bearer ${authJwtToken}`,
      "X-App": cloudXAppToken
    };
    const params = {
      call: cloud.controller.report.get,
      params: {
        controllers: [...controllers]
      }
    };

    return await request({
      method: "POST",
      baseUrl: cloudApiFullUrl(),
      url,
      headers,
      params
    });
  },
  getHubDevices: async ({ PK_Device, serverRelay, relayToken }) => {
    const url = stringInject(hub.devices, { PK_Device, relayToken });

    const requestOptions = {
      url,
      baseUrl: serverRelay,
      method: "GET"
    };
    return await request(requestOptions);
  },
  startPolling: async ({
    PK_Device,
    serverRelay,
    relayToken,
    LoadTime,
    DataVersion
  }) => {
    const url = stringInject(hub.polling, {
      PK_Device,
      relayToken,
      LoadTime,
      DataVersion
    });

    const requestOptions = {
      url,
      baseUrl: serverRelay,
      method: "GET"
    };
    return await request(requestOptions);
  },
  getMMSInfoWithToken: async (token = "") => {
    const url = cloud.request;
    const headers = {
      "X-App": cloudXAppToken
    };
    const params = {
      call: cloud.bounce.get,
      params: {
        key: token
      }
    };

    return await request({
      method: "POST",
      baseUrl: cloudApiFullUrl(),
      url,
      headers,
      params
    });
  },
  getAbstractDevices: async ({ authJwtToken = "" }) => {
    const url = abstract.request;
    const headers = {
      Authorization: `Bearer ${authJwtToken}`
    };
    const params = {
      call: abstract.list.abstractList,
      params: { data: 1 }
    };

    return await request({
      method: "POST",
      baseUrl: cloudApiUrl,
      url,
      headers,
      params
    });
  },
  getCloudScenes: async ({ authJwtToken = "" }) => {
    const url = abstract.request;
    const headers = {
      Authorization: `Bearer ${authJwtToken}`
    };
    const params = {
      call: cloud.scenes.get,
      params: {}
    };

    return await request({
      method: "POST",
      baseUrl: cloudApiUrl,
      url,
      headers,
      params
    });
  }
};

export default dashboardsApi;
