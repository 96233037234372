export default function() {
  return {
    authState: {},
    user: {
      avatar: [],
      username: "",
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      password: "",
      locationName: "",
      isResetPassword: false
    },
    fetchingAvatar: false,
    fetchingShareActivity: false,
    fetchingShareInactivity: false,
    isWebView: false,
    controllerId: null,
    dashboardId: null,
    updateTemplate: true,
    path: "",
    hasEzloProtectSubscription: true
  };
}
