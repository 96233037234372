<template>
  <GridLayout
    class="action"
    :class="{ action__primary: primary }"
    rows="56,28,12"
    columns="56,56"
  >
    <SvgIcon
      col="0"
      row="0"
      class="action__icon"
      :src="action.iconSrc"
      :isTapable="false"
    />
    <StackLayout row="0" col="1" class="action__status">
      <VxpLabel
        class="action__status__text font-act-8"
        :class="{ action__status__primary: primary }"
        :text="action.status"
        :textWrap="true"
      />
    </StackLayout>
    <StackLayout row="1" col="0" colSpan="2" class="action__device">
      <VxpLabel
        class="action__device__text font-txt-13"
        :class="{ action__device__primary: primary }"
        :text="action.deviceName"
      />
    </StackLayout>
    <StackLayout row="2" col="0" colSpan="2" class="action__room">
      <VxpLabel
        class="action__room__text font-txt-10"
        :class="{ action__room__primary: primary }"
        :text="action.room"
      />
    </StackLayout>
  </GridLayout>
</template>

<script>
export default {
  name: "ControlItem",
  props: {
    action: {
      type: Object,
      default: () => ({
        iconSrc: "",
        status: "",
        deviceName: "",
        room: ""
      })
    },
    primary: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped lang="scss">
@import "~/assets/styles/base.scss";
.action {
  border-radius: $l;
  border-width: $stroke;
  border-color: $color-grey-a25;
  background-color: $color-white;
  height: 100%;
  width: 100%;
  &-wrapper {
    background-color: $color-white;
  }
  &__status {
    padding-right: $l;
    padding-top: $ml;
    &__text {
      width: 100%;
      text-align: right;
      vertical-align: top;
    }
    &__primary {
      color: $color-white-a65;
    }
  }
  &__room,
  &__device {
    padding-left: $l;
    padding-right: $s;
  }

  &__device {
    height: $l;
    margin-top: $m;
    &__primary {
      color: $color-white;
    }
  }

  &__room {
    height: $m;
    color: $color-grey;
    &__primary {
      color: $color-white-a65;
    }
  }
  &__primary {
    background-color: $color-primary;
  }
}
</style>
