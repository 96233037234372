<template>
  <GridLayout
    rows="*"
    columns="*"
    class="action"
    :class="{ action__border: !getBackgroundImage }"
    :backgroundColor="
      !getBackgroundImage ? getBackgroundColorHex : 'transparent'
    "
    @tap="$emit('tap', $event)"
  >
    <!--
    <StackLayout
      row="0"
      col="0"
      class="spinner"
      verticalAlignment="center"
      horizontalAlignment="center"
      v-if="getBackgroundImage && imageLoading"
    >
      <Spinner />
    </StackLayout>
-->
    <ImageCacheIt
      row="0"
      col="0"
      class="action__image"
      v-show="getBackgroundImage"
      :src="getBackgroundImage"
      stretch="aspectFill"
    />
    <GridLayout row="0" col="0" rows="auto, *, auto" columns="56, *, auto">
      <StackLayout verticalAlignment="center" height="56">
        <Label
          v-if="!isHideIcon && !isIconPng && !isIconSvg"
          class="ezlo fonticon"
          :color="getIconColorHex"
          :text="iconText | fonticon"
        />
        <ImageCacheIt
          v-if="(!isHideIcon && isIconPng) || isIconSvg"
          class="action__png"
          :src="computedIcon"
          width="24"
          height="24"
          stretch="aspectFill"
        />
      </StackLayout>

      <!-- <SvgIcon row="0" col="0" :src="fetchedIcon" :isTapable="false" /> -->
      <ImageCacheIt
        row="0"
        col="0"
        v-if="srcPng"
        :src="srcPng"
        :width="30"
        :height="40"
        stretch="aspectFill"
      />

      <StackLayout
        row="0"
        col="2"
        v-if="loading"
        class="action__spinner"
        verticalAlignment="center"
        height="40"
      >
        <Spinner />
      </StackLayout>

      <StackLayout
        row="0"
        col="2"
        class="action__status"
        v-show="!isIcon && !loading"
      >
        <Label
          class="action__status-text font-txt-10"
          :text="status"
          :textWrap="true"
          :color="getTitleColorHex"
        />
      </StackLayout>

      <SvgIcon
        row="0"
        col="2"
        v-if="isIcon && !loading"
        :src="getSelectedIcon"
        :isTapable="false"
      />
      <StackLayout row="2" col="0" colSpan="3">
        <StackLayout class="action__device">
          <Label
            v-if="!isHideTitle"
            class="action__device--text"
            :fontFamily="getFontFamily"
            :style="{
              'font-family': getFontFamily,
              'font-size': getFontSize + 'px'
            }"
            :fontSize="getFontSize"
            :text="getTitle"
            :color="getTitleColorHex"
            :textWrap="true"
          />
        </StackLayout>
        <!-- <StackLayout :class="`action__room action__room-${roomTextColor}`">
          <VxpLabel class="font-txt-10" :text="room" />
        </StackLayout> -->
      </StackLayout>
    </GridLayout>
  </GridLayout>
</template>

<script>
import platform from "vuexp/src/platform";
import { isEmpty, colorHexA } from "@/helpers/utils";
import moment from "moment";

const { VUE_APP_MODE } = process.env;
export default {
  name: "DeviceTileItem",
  props: {
    src: {
      type: String,
      default: "icons/Devices/ic_device_lighthing_basic-light_56.svg"
    },
    srcActive: {
      type: String,
      default: "icons/Devices/ic_device_lighthing_basic-light_56.svg"
    },
    srcPng: {
      type: String
    },
    status: {
      type: [String, Boolean],
      default: ""
    },
    backgroundImage: {
      type: String,
      default: ""
    },
    selectedIcon: {
      type: String,
      default: "icons/Controls/ic_controls_check_on_56.svg"
    },
    unselectedIcon: {
      type: String,
      default: "icons/Controls/ic_controls_check_off_56.svg"
    },
    title: {
      type: String,
      default: "Room Light"
    },
    selected: {
      type: Boolean,
      default: false
    },
    isIcon: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    iconColorHex: {
      type: String,
      default: "#ffffff"
    },
    backgroundColorHex: {
      type: String,
      default: "#3e46ae"
    },
    colorHex: {
      type: String,
      default: "#ffffff"
    },
    fontFamily: {
      type: String,
      default: "ezlo-sans"
    },
    fontSize: {
      type: [String, Number],
      default: 16
    },
    type: {
      type: String,
      default: ""
    },
    subType: {
      type: String,
      default: ""
    },
    design_properties: {
      type: Object,
      default: () => ({})
    },
    theme: {
      type: Object,
      default: () => ({})
    },
    controller_id: {
      type: String,
      default: ""
    },
    controller_uuid: {
      type: String,
      default: ""
    },
    hideTitle: {
      type: Boolean,
      default: false
    },
    hideIcon: {
      type: Boolean,
      default: false
    },
    state: {
      type: String,
      default: "general"
    },
    autoTextSize: {
      type: Boolean,
      default: false
    },
    tileSize: {
      type: [String, Number],
      default: 1
    },
    forceSvg: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isImageLoading: true,
      tileTitle: "",
      interval: null
    };
  },
  mounted() {
    this.setTime();
    if (!this.interval) {
      this.interval = setInterval(this.setTime, 1000);
    }
  },
  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  },
  computed: {
    designProperties() {
      return isEmpty(this.design_properties)
        ? this.theme
        : this.design_properties;
    },
    getBackgroundColorHex() {
      // TODO add status check
      let color =
        (this.designProperties &&
          this.designProperties[this.state] &&
          this.designProperties[this.state].background_color) ||
        this.backgroundColorHex;
      return VUE_APP_MODE === "web" ? colorHexA(color) : color;
    },
    getFontFamily() {
      let fontFamily =
        (this.designProperties &&
          this.designProperties.general &&
          this.designProperties.general.fontFamily) ||
        this.fontFamily;
      if (fontFamily === "ezlo-sans" && VUE_APP_MODE === "web") {
        return "EzloSans-Medium";
      }
      return fontFamily;
    },
    getFontSize() {
      if (!this.autoTextSize) {
        return (
          (this.designProperties &&
            this.designProperties.general &&
            this.designProperties.general.fontSize) ||
          this.fontSize
        );
      } else {
        if (this.tileSize === 1) {
          return 13;
        } else {
          return 32;
        }
      }
    },
    getTitleColorHex() {
      // TODO add status check
      let color =
        (this.designProperties &&
          this.designProperties[this.state] &&
          this.designProperties[this.state].font_color) ||
        this.colorHex;
      if (VUE_APP_MODE === "web") {
        return colorHexA(color);
      }
      return color;
    },
    getBackgroundImage() {
      let ret = "";

      if (this.designProperties && this.designProperties[this.state]) {
        ret = this.designProperties[this.state].use_background_image
          ? this.designProperties &&
            this.designProperties[this.state].background_image
          : "";
      } else {
        ret = this.backgroundImage;
      }
      return ret || "";
    },
    computedIcon() {
      let ret = this.src;

      if (this.designProperties && this.designProperties[this.state]) {
        ret = this.designProperties[this.state].icon
          ? this.designProperties && this.designProperties[this.state].icon
          : ret;
      }
      if (ret && !this.isUrl(ret)) {
        ret = "~/assets/" + ret;
      }
      return ret || "";
    },
    fetchedIcon() {
      return this.selected ? this.srcActive : this.src;
    },
    getSelectedIcon() {
      return this.selected ? this.selectedIcon : this.unselectedIcon;
    },
    iconText() {
      return this.src
        ? "ezlo-" + this.src.split("/")[2].split(".")[0]
        : "ezlo-ic_actions_calendar-month_56";
    },
    isIconPng() {
      return (
        this.computedIcon.split(".")[1] === "png" ||
        this.isUrl(this.computedIcon)
      );
    },
    isIconSvg() {
      return this.forceSvg;
    },
    getIconColorHex() {
      // TODO add status check "on/ off"
      let color =
        (this.designProperties &&
          this.designProperties[this.state] &&
          this.designProperties[this.state].icon_color) ||
        this.iconColorHex;
      if (VUE_APP_MODE === "web") {
        return colorHexA(color);
      }
      return color;
    },
    isHideTitle() {
      return (
        (this.designProperties &&
          this.designProperties[this.state] &&
          this.designProperties[this.state].hideTitle) ||
        this.hideTitle
      );
    },
    isHideIcon() {
      return (
        (this.designProperties &&
          this.designProperties[this.state] &&
          this.designProperties[this.state].hideIcon) ||
        this.hideIcon
      );
    },
    imageLoading() {
      return platform.platform !== "web" && this.isImageLoading;
    },
    getTitle() {
      if (this.type === "datetime") {
        return this.tileTitle;
      } else {
        return this.title;
      }
    }
  },
  methods: {
    isUrl(src) {
      return src && typeof src === "string"
        ? src.startsWith("http://") || src.startsWith("https://")
        : false;
    },
    setTime() {
      const date = moment();
      if (this.subType === "time") {
        this.tileTitle = date.format("hh:mm A");
      } else if (this.subType === "date") {
        this.tileTitle = date.format("DD MMMM");
      } else {
        this.$set(
          this,
          "tileTitle",
          date.format("hh:mm A") + " " + date.format("DD MMMM")
        );
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "~/assets/styles/variables.scss";
.fonticon {
  font-size: $xxxll;
}
.container {
  background-color: $color-white;
}
.action {
  height: 100%;
  width: 100%;
  border-radius: $l;
  &__border {
    border-width: $stroke;
    border-color: $color-grey-a25;
    border-style: solid;
  }
  &__png {
    width: $xll;
    height: $xll;
    padding: $s;
  }
  &__image {
    border-radius: $l;
    object-fit: fill;
  }
  &__status {
    justify-content: center;
    padding-right: $l;
    margin-bottom: $m;
    vertical-align: center;
  }
  &__spinner {
    align-self: center;
  }
  &__device {
    padding-left: $l;
    padding-right: $s;
    padding-bottom: $margin-lmx;
    &--text {
      font-size: unit(13);
      line-height: getLineHeight(18);
      text-overflow: ellipsis;
    }
  }
}
.spinner {
  align-self: center;
}
</style>
