export default function() {
  return {
    currentLocation: {
      hubs: []
    },
    locations: [
      /*
        ### Look in helpers/createLocations
        id: PK_Installation,
        name: FirstName || PK_Installation,
        hubs: locationHubs,
        etc..
      */
    ]
  };
}
