<template>
  <StackLayout class="tile-settings-state">
    <StackLayout class="tile-settings-state__empty">
      <StackLayout class="tile-settings-state__tile">
        <TileItem
          :title="tileLabel"
          titleTextWrap
          :backgroundImage="getBackgroundImage"
          :src="designProperties.icon"
          :backgroundColorHex="designProperties.background_color"
          :colorHex="designProperties.font_color"
          :iconColorHex="designProperties.icon_color"
          :fontFamily="designProperties.font_family"
          :fontSize="designProperties.font_size"
          :hideTitle="designProperties.hideTitle"
          :hideIcon="designProperties.hideIcon"
          :type="tileType"
          :status="statusText"
        />
      </StackLayout>

      <!-- <Separator v-if="false" size="xl" />
      <HTextUnderlineTextSwitch
        v-if="false"
        alignText="left"
        alignUnderline="left"
        headerSize="h3"
        :colorPrimary="getTemplateActiveColor"
        :text="text.hideIcon"
        :value="designProperties.hideIcon"
        @change="changeHideIcon"
      />
      <Separator v-if="false" size="xl" />
      <HTextUnderlineTextSwitch
        v-if="false"
        alignText="left"
        alignUnderline="left"
        headerSize="h3"
        :colorPrimary="getTemplateActiveColor"
        :text="text.hideTitle"
        :value="designProperties.hideTitle"
        @change="changeHideTitle"
      /> -->
      <Separator size="xl" />
      <!-- Tile background -->
      <GridLayout columns="auto, *" rows="56" width="100%" @tap="tapBackground">
        <ColorEllipse
          row="0"
          col="0"
          v-if="designProperties && !designProperties.use_background_image"
          class="tile-settings-state__color-ellipse"
          :color="getBackgroundColor"
        />
        <IconHTxtiBtn
          class="tile-settings-state__item-text"
          :class="{
            'tile-settings-state__margin-left':
              designProperties && designProperties.use_background_image
          }"
          verticalAlignment="center"
          row="0"
          col="1"
          alignText="left"
          headerSize="h3"
          :icon1Src="
            designProperties && designProperties.use_background_image
              ? 'icons/Actions/ic_actions_img_56.svg'
              : ''
          "
          :icon2Src="iconRight"
          :text="text.textTileBackground"
        />
      </GridLayout>
      <Separator size="xl" />
      <!-- Font color -->
      <GridLayout
        columns="auto, *"
        rows="56"
        width="100%"
        @tap="tapSelectColor('font_color')"
      >
        <ColorEllipse
          row="0"
          col="0"
          class="tile-settings-state__color-ellipse"
          :color="getFontColor"
        />
        <IconHTxtiBtn
          class="tile-settings-state__item-text"
          verticalAlignment="center"
          row="0"
          col="1"
          alignText="left"
          headerSize="h3"
          :text="text.textFontColor"
          :icon2Src="iconRight"
        />
      </GridLayout>

      <Separator size="xl" />
      <!-- Icon -->
      <GridLayout
        v-if="!isWebView"
        class="tile-settings-state__icon-container"
        columns="auto, *"
        rows="56"
        width="100%"
        @tap="tapSelectItem"
      >
        <Label
          row="0"
          col="0"
          v-if="!isIconPng"
          class="ezlo fonticon"
          :text="iconText | fonticon"
        />
        <StackLayout
          v-if="isIconPng"
          width="56"
          height="56"
          row="0"
          col="0"
          class="tile-settings-state__icon-png"
        >
          <ImageCacheIt
            :src="computedIcon"
            width="100%"
            height="100%"
            stretch="fill"
          />
        </StackLayout>
        <IconHTxtiBtn
          class="tile-settings-state__item-text"
          verticalAlignment="center"
          row="0"
          col="1"
          alignText="left"
          headerSize="h3"
          :text="text.textIcon"
          :icon2Src="iconRight"
        />
      </GridLayout>
      <Separator v-if="!isWebView" size="xl" />

      <!-- Icon color -->
      <GridLayout
        columns="auto, *"
        rows="56"
        width="100%"
        @tap="tapSelectColor('icon_color')"
        v-if="!isIconPng"
      >
        <ColorEllipse
          row="0"
          col="0"
          class="tile-settings-state__color-ellipse"
          :color="getIconColor"
        />
        <IconHTxtiBtn
          class="tile-settings-state__item-text"
          verticalAlignment="center"
          row="0"
          col="1"
          alignText="left"
          headerSize="h3"
          :text="text.textIconColor"
          :icon2Src="iconRight"
        />
      </GridLayout>
      <Separator v-if="!isIconPng" size="xl" />

      <!-- Restore defaults -->
      <IconHTxtiBtn
        class="tile-settings-state--warning"
        verticalAlignment="center"
        row="0"
        col="0"
        alignText="left"
        headerSize="h3"
        :text="text.textRestoreDefaults"
        icon1Src="icons/Controls/ic_controls_rotate_56.svg"
        @tap="tapRestoreDefaults"
      />
      <Separator size="xl" />
    </StackLayout>
  </StackLayout>
</template>
<script>
import { cloneDeep } from "lodash";
import { locals } from "@/helpers/variables";
import { colorHexA } from "@/helpers/utils";
const { VUE_APP_MODE } = process.env;

export default {
  name: "TileSettingsState",
  props: {
    text: {
      type: Object,
      default: () => ({})
    },
    tileElement: {
      type: Object
    },
    tileLabel: {
      type: String,
      default: ""
    },
    isWebView: {
      type: Boolean,
      default: false
    },
    tileState: String,
    tileType: String,
    iconRight: {
      type: String,
      default: "icons/Controls/ic_controls_arrow-right_56.svg"
    }
  },
  data() {
    return {
      designProperties: null
    };
  },
  created() {
    this.setDesignProperties();
  },
  watch: {
    tileElement(newVal) {
      this.setDesignProperties();
    }
  },
  computed: {
    isIconPng() {
      return this.computedIcon.split(".")[1] === "png";
    },
    computedIcon() {
      let ret = "";

      if (this.designProperties && this.designProperties) {
        ret =
          "~/assets/" +
          (this.designProperties.icon
            ? this.designProperties && this.designProperties.icon
            : "");
      } else {
        ret = "~/assets/" + this.designProperties?.icon;
      }

      return ret;
    },
    iconText() {
      return this.designProperties?.icon
        ? "ezlo-" + this.designProperties.icon.split("/")[2].split(".")[0]
        : "ezlo-ic_actions_calendar-month_56";
    },
    getBackgroundImage() {
      return this.designProperties.background_image;
    },
    getTemplateActiveColor() {
      return locals.colorPrimary;
    },
    getFontColor() {
      const color = this.designProperties && this.designProperties.font_color;
      if (VUE_APP_MODE === "web") {
        return colorHexA(color);
      }
      return color;
    },
    getBackgroundColor() {
      const color =
        this.designProperties && this.designProperties.background_color;
      if (VUE_APP_MODE === "web") {
        return colorHexA(color);
      }
      return color;
    },
    getIconColor() {
      const color = this.designProperties && this.designProperties.icon_color;
      if (VUE_APP_MODE === "web") {
        return colorHexA(color);
      }
      return color;
    },
    statusText() {
      return this.$t("tileStates.capabilities")[this.tileState] || "";
    }
  },
  methods: {
    setDesignProperties() {
      this.$set(this, "designProperties", cloneDeep(this.tileElement));
    },
    tapBackground() {
      this.$root.$emit("_tapBackground");
    },
    tapSelectColor(key) {
      this.$root.$emit("_tapSelectColor", key);
    },
    tapSelectItem() {
      const options = {
        [this.tileState]: this.designProperties
      };
      this.$root.$emit("_tapSelectItem", options);
    },
    tapRestoreDefaults() {
      const options = {
        tileState: this.tileState
      };
      this.$root.$emit("_tapRestoreDefaults", options);
    },
    changeHideTitle(event) {
      this.$root.$emit("_hideTitle", { hideTitle: event });
    },
    changeHideIcon(event) {
      this.$root.$emit("_hideIcon", { hideIcon: event });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~/assets/styles/variables.scss";

.tile-settings-state {
  // padding: 0 $padding-xl 0 $padding-xl;
  color: $color-black;
  &__icon-container {
    margin-left: $margin-s;
  }
  &__icon-png {
    align-self: center;
    margin-right: $xl;
  }
  &__tile {
    margin: $m $xl;
    height: unit(215);
  }
  &__text {
    margin-top: $xl;
  }
  &--warning {
    margin-left: 0;
    /deep/ & * {
      color: $color-warning;
    }
  }
  &__item-text {
    padding-left: 0;
    grid-column: 2;
    /deep/ & * {
      padding-left: 0;
    }
  }
  &__color-ellipse {
    margin-left: $margin-s;
  }
  &__margin-left {
    margin-left: $margin-s;
  }
}
.fonticon {
  font-size: $xxxll;
}
</style>
