<template>
  <StackLayout class="mode-devices">
    <StackLayout>
      <ScrollView class="mode-devices__scroll">
        <StackLayout class="mode-devices__inner">
          <StackLayout class="mode-devices__content">
            <TabUI
              :items="getTabItems"
              :initialTab="initialGroup"
              isHeaderBordered
              @tap="handleSelectTab"
              @contentTap="handleContentTap"
            />
          </StackLayout>
        </StackLayout>
      </ScrollView>
    </StackLayout>
  </StackLayout>
</template>

<script>
import { locals } from "@/helpers/variables";

export default {
  name: "DevicesOverview",
  props: {
    texts: {
      type: Object,
      default: () => ({})
    },
    devices: {
      type: Array,
      default: () => []
    },
    groups: {
      type: Array,
      default: () => []
    },
    initialGroup: {
      type: Number,
      default: 0
    }
  },
  computed: {
    translations() {
      return this.$t("devicesListTabs"); // eslint-disable-line
    },
    getTabItems() {
      return this.groups.map(group => {
        return {
          id: group,
          header: {
            component: "TabHeader",
            details: {
              title: this.translations[group],
              underlineColor: locals.colorBlack
            }
          },
          content: {
            component: "DevicesGroups",
            details: { devices: this.devices }
          }
        };
      });
    }
  },
  methods: {
    handleContentTap(item) {
      this.$emit("onTapDevice", item);
    },
    handleSelectTab(index, { id }) {
      this.$emit("selectGroupBy", id);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~/assets/styles/variables.scss";

.mode-devices {
  &__inner {
    color: $color-black;
  }
  &__scroll {
    height: 100%;
  }
}
</style>
