<template>
  <GridLayout rows="*, auto" columns="*" class="intro-get-up">
    <GridLayout rows="*, auto, auto" columns="*" class="intro-get-up__img">
      <StackLayout row="0" col="0" class="intro-get-up__image">
        <SvgIcon :src="iconPath" :size="200" />
      </StackLayout>
      <VxpLabel
        row="1"
        col="0"
        class="intro-get-up__title font-h1-32"
        :text="title"
        :textWrap="true"
      />
      <VxpLabel
        row="2"
        col="0"
        class="intro-get-up__text font-txt-16-par"
        :text="descr"
        :textWrap="true"
      />
    </GridLayout>
    <StackLayout row="1" col="0">
      <ButtonFilled
        row="4"
        col="0"
        class="intro-get-up__button"
        :text="btnText"
        :loading="btnLoading"
        @tap="$emit('send', $event)"
      />

      <AgreementsLink
        row="5"
        col="0"
        class="intro-get-up__terms"
        v-show="isShowTerms"
        :text="policyText"
        :isAllClickable="true"
        @tap="onTapTerms"
      />
    </StackLayout>
  </GridLayout>
</template>

<script>
export default {
  name: "IntroGetUp",
  props: {
    iconPath: {
      type: String,
      default: "icons/Visuals/ic_visuals_home_200.svg"
    },
    title: {
      type: String,
      default: "Title"
    },
    descr: {
      type: String,
      default: "Description text"
    },
    btnText: {
      type: String,
      default: "Button"
    },
    isShowTerms: {
      type: Boolean,
      default: false
    },
    btnLoading: {
      type: Boolean,
      default: false
    },
    policyUrlAgr: {
      type: String,
      default: "https://getvera.com/pages/eula"
    },
    policyText: {
      type: String,
      default: "Policy agreement"
    }
  },
  methods: {
    onTapTerms() {
      if (process.env.VUE_APP_MODE !== "web") {
        this.$showModal("WebViewModal", {
          fullscreen: true,
          props: {
            url: this.policyUrlAgr
          }
        });
      } else {
        window.open(this.policyUrlAgr, "_blank");
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "~/assets/styles/base.scss";
.intro-get-up {
  padding: $padding-xl;
  horizontal-align: center; //nativescript only attribute
  color: $color-black;
  background-color: transparent;
  &__image {
    vertical-align: bottom;
    horizontal-align: center; //nativescript only attribute
    margin: auto auto 0;
  }
  &__title {
    margin-bottom: $margin-s;
    text-align: center;
  }
  &__text {
    margin-bottom: $margin-s;
    text-align: center;
  }
  &__value {
    text-align: center;
  }
  &__button {
    margin-top: $margin-xxxl;
  }
  &__terms {
    margin-top: $margin-xl;
    color: $color-grey;
  }
  &__img {
    height: 100%;
  }
}
</style>
