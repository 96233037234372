export default {
  getLocations: state => state.locations,
  getCurrentLocation: state => state.currentLocation,
  getLocationByIndex: (state, getters) => index => {
    const locations = getters.getLocations;
    if (
      !locations ||
      !locations.length ||
      index < 0 ||
      index >= locations.length
    ) {
      return null;
    }
    return locations[index];
  },
  getLocationById: (state, getters) => locationId => {
    return getters.getLocations.find(loc => loc.id === locationId);
  },
  getLocationByName: (state, getters) => locationName => {
    return getters.getLocations.find(loc => loc.name === locationName);
  },
  getHubsFromLocations: (state, getters) => {
    let hubArray = [];
    state.locations.forEach(item => {
      hubArray = hubArray.concat(item.hubs);
    });
    return hubArray;
  }
};
