import WSPacket from "./packet";

export class WSPool {
  constructor(size) {
    this.current = 1;
    this.pool = new Set();
    this.packets = new Map();
    this.index = 1000000000000000;
    for (var i = 0; i < size; i++) {
      this.pool.add(new WSPacket());
    }
  }
  getIndex() {
    return (++this.index).toString();
  }
  clear() {
    this.pool.clear();
    this.packets.clear();
  }
  getLastPacket() {
    var packets = [...this.pool], // SetObject to Array
      packet = packets[packets.length - 1];
    this.pool.delete(packet);
    return packet;
  }
  /**
   * @return {WSPacket} packet
   * */
  createPacket() {
    var packet = null,
      index = this.getIndex().toString();
    if (this.pool.size) {
      packet = this.getLastPacket();
      packet.id = index;
    } else {
      packet = new WSPacket(index);
    }

    this.packets.set(index, packet);

    return packet;
  }

  resolvePacket(resp) {
    var packet = this.packets.get(resp.id);
    if (packet && packet instanceof WSPacket) {
      packet.sourceIn = resp.sourceIn;
      if (resp.error) {
        packet.reject(resp.error);
      } else {
        const data = resp.id ? { id: resp.id, ...resp.data } : resp.data;
        packet.resolve(resp.result ? resp.result : data);
      }
      packet.clear();
      this.packets.delete(resp.id);
      this.pool.add(packet);
    }
  }
  hasPacket(id) {
    var packet = this.packets.get(id);
    return packet && packet instanceof WSPacket;
  }
}
export default WSPool;
