<template>
  <IconTwoActions
    iconPath="icons/Visuals/ic_warning_reset_200.svg"
    type="warning2"
    :text1="translations.text1"
    :text2="translations.text2"
    :btnTextAccept="translations.btnTextRestore"
    :btnTextCancel="translations.btnTextCancel"
    @accept="handleRestore"
    @cancel="handleCancel"
  />
</template>

<script>
export default {
  name: "RestoreStyleSettings",
  computed: {
    translations() {
      return {
        text1: this.$t("core.restoreStyleSettings.text1"), // eslint-disable-line
        text2: this.$t("core.restoreStyleSettings.text2"), // eslint-disable-line
        btnTextRestore: this.$t("core.restoreStyleSettings.btnTextRestore"), // eslint-disable-line
        btnTextCancel: this.$t("core.restoreStyleSettings.btnTextCancel") // eslint-disable-line
      };
    }
  },
  methods: {
    handleRestore() {
      this.$emit("restore");
    },
    handleCancel() {
      this.$emit("close");
    }
  }
};
</script>
