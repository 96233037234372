module.exports = Object.freeze({
  name: "English",
  // core components
  core: {
    mailAppsDialog: {
      title: "Check inbox with"
    },
    sendSuccessMessage: {
      title: "We've received your message",
      desc: "Our Customer Care team will be in touch shortly.",
      btn: "Done"
    },
    errorDialogConnection: {
      title: "Sorry, that didn't go through",
      description:
        "It's possible there are some connection issues. Please try again.",
      actionText: "@:common.gotIt"
    },
    errorDialogConnectionLost: {
      title: "Connection lost",
      description: "Be sure the Ezlo Atom powered on and you are nearby",
      actionText: "@:common.gotIt"
    },
    errorDialogLinkValid: {
      title: "The link is no longer valid",
      desc:
        "Confirmation links expire 30 minutes after first click, or after 24 hours if unused.",
      btn: "Request a new link"
    },
    errorDialogSigninCredentials: {
      title: "Incorrect username or password",
      desc: "Please check your username and password and try log in again.",
      btn: "Got it"
    },
    errorDialogSigninAttempts: {
      title: "Too many attempts",
      desc: "Please try to login again later.",
      btn: "Got it"
    },
    errorDialogInvalidCurrentPWD: {
      title: "Invalid current password.",
      desc: "Check and correct the current password you entered and try again.",
      btn: "Got it"
    },
    errorAvailableHubs: {
      title: "No controllers have been detected",
      desc: "Please check your location and try login again.",
      btn: "Got it"
    },
    errorAvailableEzloHubs: {
      title: "No Ezlo controllers have been detected",
      desc: "Make sure you have an Ezlo controller and try again.",
      btn: "Got it"
    },
    errorAvailableDevices: {
      title: "No devices have been detected",
      desc: "Please add your first device.",
      btn: "Got it"
    },
    policy: {
      text:
        "By continuing, you agree to {companyName}’s <link>Terms of Service and Privacy Policy<link>",
      text2:
        "By continuing, you agree to our <link>Terms of Service, Privacy Policy and Apps and Service Agreement<link>",
      textPlaceholder: "License agreements"
    },
    loginByUsernamePasswordFailed: {
      usernameField: "Username Field is invalid"
    },
    logoutConfirm: {
      text1: "Log out from your profile?",
      text2: "You can always log in again using password.",
      btnTextAccept: "@:common.confirm",
      btnTextCancel: "@:common.cancel"
    },
    errorNoInternetConnectionDialog: {
      title: "No Internet Connection",
      description:
        "To continue the setup, please, connect to a internet WiFi access point.",
      actionText: "Try again"
    },
    deleteDashboardConfirm: {
      text1: "Are you sure you want to delete the dashboard?",
      text2:
        "The dashboard will be removed from the list and will not participate in scenes.",
      btnTextDelete: "@:common.delete",
      btnTextCancel: "@:common.cancel"
    },
    errorDialogNotEzlo: {
      title: "Sorry, you can't use the app with Vera hubs",
      description:
        "You can use the app to operate Atoms and PlugHubs. Please, use Veramobile to operate Vera hubs.",
      actionText: "@:common.gotIt"
    },
    restoreStyleSettings: {
      text1: "Are you sure you want to return to the default settings?",
      text2:
        "All style settings will be reset. You can set them again in the dashboard style settings.",
      btnTextRestore: "@:common.setDefaults",
      btnTextCancel: "@:common.cancel"
    },
    singleTemplateConfirm: {
      text1: "Do you want to use one template?",
      text2: "Only portrait orientation template will be used.",
      btnTextAccept: "Use one template",
      btnTextCancel: "@:common.cancel"
    },
    tileTypesDialog: {
      textTitle: "Select tile type",
      textDevice: "Device",
      textScene: "Scene",
      textTimeAndDate: "Time & Date",
      textScene: "Scene",
      textLink: "Link menu item",
      textServiceWidget: "Service widget",
      textURL: "URL address",
      textDashboards: "Dashboard",
      textMode: "Mode",
      textEzloProtect: "Ezlo Protect",
      textGeneral: "General",
      textServices: "Services"
    },
    selectMenuItemsDialog: {
      textTitle: "Select menu item",
      textDashboard: "Dashboard",
      textScenes: "Scenes",
      textEzloVOI: "Ezlo VOI",
      textUsers: "Users",
      textSettings: "Settings",
      textMyControllers: "My controllers",
      textDevices: "Devices",
      textRooms: "Rooms",
      textAlerts: "Alerts",
      textCameras: "Cameras",
      textGeofencing: "Geofencing"
    },
    moreOptionsAreComingSoon: {
      title: "More options are coming soon",
      description:
        "We are working hard to add new features that let you to set goals on a daily, weekly, and annual basis.",
      actionText: "Got it"
    },
    currentGoalResulstWillBeReset: {
      title: "Current goal results will be reset",
      description:
        "In order to effectively track your new goal, previously collected data for this device will be deleted.",
      btnTextAccept: "Confirm",
      btnTextCancel: "Cancel"
    },
    currentGoalResulstWillBeReseted: {
      title: "Current goal results will be reset",
      description:
        "To start tracking a goal with new settings the currently collected data will be deleted.",
      btnTextAccept: "Confirm",
      btnTextCancel: "Cancel"
    },
    disableMonitoring: {
      title: "Disable monitoring on this device?",
      description:
        "It will no longer will be included in your energy statistics and any energy goals associated with it will be erased. You can always re-enable this device in the future.",
      btnTextAccept: "Confirm",
      btnTextCancel: "Cancel"
    },
    errorDialogNoEnergyData: {
      title: "No energy data for this period yet",
      description:
        "Don’t worry, this time frame become available when you get more data during the day.",
      actionText: "@:common.gotIt"
    },
    leaveWithoutChanges: {
      title: "Leave without changes?",
      description: "All changes you made will not be saved.",
      btnTextAccept: "Leave",
      btnTextCancel: "Cancel"
    },
    themeHasBeenChanged: {
      title: "The theme has been changed",
      description:
        "You can save the new theme with your own style settings, otherwise the settings will not be saved.",
      btnTextAccept: "Save as new theme",
      btnTextCancel: ""
    },
    deleteTheme: {
      title: "Delete a theme?",
      description:
        "The theme will be removed and the default theme will be applied instead.",
      btnTextAccept: "@:common.delete",
      btnTextCancel: ""
    },
    tapToCustomizeASpesificTile: {
      title: "Tap to customize a specific tile",
      description:
        "You can change the icon color, background and text color for each tile sepparately.",
      btnTextAccept: "@:common.gotIt"
    },
    bothModesToOneMode: {
      title: "You are changing the template mode",
      description:
        "The template with the unused orientation mode will be deleted. You can add the second mode again in the settings.",
      btnTextAccept: "@:common.gotIt",
      btnTextCancel: ""
    },
    oneModeToBothModes: {
      title: "You are changing the template mode",
      description:
        "You can use the same template for a new orientation or choose a new one.",
      btnTextAccept: "Use same template",
      btnTextCancel: "Select new template"
    },
    portraitModeToLandscape: {
      title: "You are changing the template mode",
      description:
        "If you change the orientation of the template, you must select a new template and re-add the tiles.",
      btnTextAccept: "@:common.gotIt",
      btnTextCancel: ""
    }
  },
  common: {
    next: "Next",
    send: "Send",
    getHelp: "Get help",
    call: "Call",
    cancel: "Cancel",
    submit: "Submit",
    done: "Done",
    ok: "OK",
    finish: "Finish",
    confirm: "Confirm",
    enable: "Enable",
    add: "Add",
    to: "to",
    edit: "Edit",
    gotIt: "Got it",
    about: "About",
    save: "Save",
    delete: "Delete",
    setDefaults: "Set defaults"
  },

  validation: {
    phone: {
      invalid: "Invalid phone number"
    },
    country: {
      invalid: "Invalid country"
    }
  },
  offlineMode: {
    textTitle: "Offline"
  },
  // already used
  PhoneCheck: {
    title: "Welcome to {companyName}",
    description:
      "Enter your phone number to sign up or log in into your {companyName} profile."
  },
  signupIntro: {
    title: "Welcome to MiOS Dashboard Configurator",
    description: "To get started please log in using your Vera account",
    button: "Sign in",
    signIn: "Forgot password?"
  },
  forgotPwdOTPIntro: {
    title: "Forgot password?",
    description:
      "To reset your old password and create a new one, please on the next screen enter the code we will send you in SMS.",
    next: "@:common.next"
  },
  loginByUserPhoneWelcome: {
    title: "Phone number required",
    description:
      "To give you more easier login experience and enhancement security we are moving from using username to phone number.",
    next: "@:common.next"
  },
  loginByUserPhone: {
    title: "Enter phone number",
    description:
      "Please enter the phone number you want to link with profile and use for future login."
  },
  Verification: {
    title: "Enter {length}-digit code",
    description: "We’ve sent it to {phone}",
    countDownLabel: "You can request a new code in: ",
    confirmation: "Do you want to use code: {code}",
    requestNewCode: "Request a new code",
    requestNewCodeSendMessage: "New code was sent to you",
    expiredTitle: "Code expired",
    expiredDescription: "You need to request a new code",
    wrongTitle: "Wrong code",
    wrongDescription: "Check the code and retry or request new"
  },
  loginByUserIntro: {
    title: "Log in with username",
    forgotPasswordText: "Forgot password",
    loginButtonText: "Log in",
    usernamePlaceholder: "Username or E-Mail",
    passwordPlaceholder: "Password"
  },
  loginByPhoneIntro: {
    hintNav: "Forgot password",
    title: "Let’s log in",
    desc: "Enter the password to log in into your account linked to {phone}",
    pwdPlaceholder: "Password",
    btnText: "Log in",
    btnDisabledText: "@:common.next",
    errorText: "Wrong password"
  },
  createPassword: {
    title: "Create password",
    pwdPlaceholder: "Password",
    reEnterPwdPlaceholder: "Re-enter password",
    btnText: "@:common.next",
    errorText: "Passwords do not match."
  },
  createNewPassword: {
    title: "Create new password",
    pwdPlaceholder: "New password",
    reEnterPwdPlaceholder: "Re-enter new password",
    btnText: "@:common.confirm",
    errorText: "Passwords do not match.",
    snackbarText: "New password saved"
  },
  dashboardTemplate: {
    textTitle: "New dashboard",
    textTitleEditDashboard: "Edit dashboard",
    textBtnEdit: "Save",
    textBtnTemplate: "Continue",
    textHint: "Dashboard name",
    useAsDefault: "Use as default",
    textDuplicate: "Duplicate dashboard",
    textDuplicateHint: "With all tiles and style setting",
    textDuplicateSuccessful: "Dashboard duplicated",
    textStyle: "Dashboard settings",
    textStyleHint: "Background, icon style and more.",
    textTemplate: "Use one template",
    textTemplateHint:
      "A portrait template will be used for both modes: portrait and landscape.",
    textAddTemplate: "Select template",
    textEditNameDialog: {
      title: "Edit dashboard name",
      placeholder: "Dashboard name",
      hint: "E.g.: Weekday evenings, Summer watering etc.",
      btn: "Done"
    },
    textSnackbar: {
      duplicateSuccessful: "Dashboard duplicated.",
      textDeleteSuccessful: "Dashboard deleted.",
      textUpdateSuccessful: "Dashboard updated.",
      textCreateSuccessful: "Dashboard created."
    }
  },
  passwordChecks: {
    title: "Password requirements",
    uppercaseLetter: "Uppercase letter",
    lowercaseLetter: "Lowercase letter",
    number: "One number",
    characters: "8-16 characters"
  },
  forgotPasswordEmailIntro: {
    title: "Forgot password?",
    description:
      "Please enter the e-mail address associated with your Vera account and we’ll send you a reset link.",
    emailPlaceholder: "Email",
    btn: "@:common.next"
  },
  forgotPasswordEmailSend: {
    title: "Forgot password?",
    description:
      "We will send you password recovery instructions to your email address.",
    btn: "@:common.send"
  },
  forgotPasswordEmailReset: {
    title: "Check your inbox",
    desc: "You'll find password reset instructions in the email we sent to.",
    btn: "Check inbox",
    hint:
      "If you can't find an email, please check the spam folder in your inbox"
  },
  deviceState: {
    on: "On",
    off: "Off",
    text: "State"
  },
  profile: {
    title: "Account",
    name: "Username",
    email: "Email",
    logOut: "Log out",
    titleEditEmail: "Edit email",
    btnEditEmail: "Save",
    hintEditEmail: "Email is already taken",
    snackbarTextEditEmail: "Email saved",
    errorWhileUploadingImage: "Error while uploading image",
    profilePhotoChanged: "Profile photo changed",
    hubs: "Controllers"
  },
  profileCurrentPassword: {
    textTitle: "Enter your current password",
    textPWDPlaceholder: "Current password",
    textBtn: "Continue",
    textError: "Invalid password entered"
  },
  profileFullName: {
    textTitle: "Full name",
    textPlaceholderFirstName: "First name",
    textPlaceholderLastName: "Last name",
    textBtn: "Save",
    textSnackbar: "Full name saved"
  },
  devices: {
    title: "Select a device"
  },
  scenes: {
    title: "Select a scene",
    finished: "Scene executed successfully",
    failed: "Scene could not be executed"
  },
  devicesListTabs: {
    byType: "By type",
    byRoom: "By room"
  },
  dashboardList: {
    textTitle: "My dashboards",
    add: "Add New Dashboard",
    hubsDialog: {
      textTitle: "Select controller for new dashboard"
    },
    dafaultDashboard: "Default dashboard"
  },
  dashboardSelect: {
    headerTitle: "Select dashboard"
  },
  newDashboard: {
    textTitle: "Select template mode",
    useOneTemplate: "Use one template",
    useOneTemplateDescription:
      "A portrait template will be used for both modes: portrait and landscape.",
    textBtn: "Continue",
    pleaseNameYourDashboard: "Enter dashboard name",
    dashboardNamePlaceholder: "Dashboard name",
    dashboardNameIsUsed: "This dashboard name has already been used before.",
    desc:
      "Here you can choose the orientation for the template that you will use. It can be changed in the Dashboard Settings section."
  },
  dashboardPromoTile: {
    type: "Dashboard",
    title: "Meet brand new customizable dashboards",
    btn: "Add dashboard"
  },
  dashboardSettings: {
    textHeader: "Dashboard settings",
    textFirstGroup: "General",
    textFirstGroupActionFirst: "Dashboard background",
    textFirstGroupActionSecond: "Font family",
    textFirstGroupActionThird: "Font size",
    textSecondGroup: "Other",
    textSecondGroupActionFirst: "Restore defaults",
    textBtn: "Done",
    duplicateDashboard: "Duplicate dashboard",
    duplicateDashboardDesc: "With all tiles and style settings",
    templateMode: "Template mode",
    theme: "Theme",
    dashboardName: "Dashboard name",
    portrait: "Portrait",
    landscape: "Landscape",
    both: "Both"
  },
  styleSettingsBgImage: {
    textTitle: "Theme background",
    textAddCustomImg: "Upload custom image",
    textPresetsTitle: "Image presets",
    textBackgroundImages: "Background images",
    textBtn: "Done",
    textSnackbar: {
      added: "Image added successfully"
    },
    textCameraDialog: {
      textFirst: "Take photo",
      textSecond: "Choose from library",
      textCancel: "Cancel"
    },
    textBackgroundColor: "Background color",
    textTileBackground: "Tile background",
    textUseBackgroundImage: "Use background image",
    textUseBackgroundImageDesc:
      "The image will be used instead of the background color."
  },
  orientations: {
    portrait: "Portrait orientation",
    landscape: "Landscape orientation"
  },
  templateModes: {
    new: {
      headerTitle: "Select template mode",
      portrait: "Portrait mode",
      portraitDesc: "Use the dashboard only in portrait orientation.",
      landscape: "Landscape mode",
      landscapeDesc: "Use the dashboard only in landscape orientation.",
      both: "Both mode",
      bothDesc: "Use of dashboards in portrait and landscape orientations.",
      desc:
        "Here you can choose the orientation for the template that you will use. It can be changed in the Dashboard Settings section."
    },
    edit: {
      headerTitle: "Template mode",
      portrait: "Portrait mode",
      portraitDesc: "Portrait only for template",
      landscape: "Landscape mode",
      landscapeDesc: "Landscape only for template",
      both: "Both mode",
      bothDesc: "Use both orientations for template",
      desc: "You can choose the orientation for the template."
    }
  },
  portraitTemplate: {
    title: "Select template",
    btn: "Done"
  },
  newLinkAddress: {
    textTitle: "Enter link address",
    textLinkHint: "E.g.: Youtube, Spotify, website, image etc.",
    textBtn: "Continue"
  },
  newLinkAddressName: {
    textTitle: "Enter tile name",
    textBtn: "Done"
  },
  fontFamily: {
    textTitle: "Font family",
    textInstruction:
      "You can select from the list below the font that will be applied to the tiles.",
    textBtn: "Done"
  },
  fontSize: {
    textTitle: "Font size",
    textInstruction:
      "By moving the slider at the bottom of the screen, you can select the font size for the tiles.",
    textBtn: "Done"
  },
  fontFamilies: {
    "ezlo-sans": "Ezlo Sans",
    "sf-pro-display": "SF Pro Display",
    roboto: "Roboto",
    "open-sans": "Open Sans",
    defaultFont: "Default",
    "sans-serif": "Sans Serif",
    monospace: "Monospace",
    serif: "Serif",
    casual: "Casual",
    avenir: "Avenir"
  },
  dashboardTemplateType: {
    createMode: {
      textTitlePortrait: "Select template",
      textTitleLantscape: "Select landscape template",
      textBtn: "Continue"
    },
    editMode: {
      textTitlePortrait: "Select portrait template",
      textTitleLantscape: "Select landscape template",
      textBtn: "Continue"
    }
  },
  tileSettings: {
    textTitle: "Tile settings",
    textOnState: "On state",
    textOffState: "Off state",
    textTileBackground: "Tile background",
    textFontColor: "Font color",
    textIcon: "Icon",
    textIconColor: "Icon color",
    textOther: "Other",
    textRestoreDefaults: "Restore defaults",
    textBtn: "Done",
    hideTitle: "Hide title",
    hideIcon: "Hide icon",
    headerFirst: "Edit",
    headerLast: "state",
    textEditTile: "Edit tile"
  },
  tileStates: {
    textTitle: "Tile states",
    textInfo: "You can edit the tile for any state of your device.",
    capabilities: {
      on: "On",
      off: "Off",
      open: "Open",
      closed: "Closed",
      cool: "Cool",
      heat: "Heat",
      auto: "Auto",
      armed: "Armed",
      disarmed: "Disarmed",
      tripped: "Tripped"
    }
  },
  preview: {
    empty: "No Dashboards"
  },
  deviceToControl: {
    textTitle: "Device to control",
    textInputLabel: "Device name you want to control",
    textDesc:
      "Enter the exact device or group name you tell Alexa when you want to control it. E.g.: Light in living room",
    textBtn: "Next"
  },
  addDevice: {
    textTitle: "Add device",
    textDescTitle: "Create a virtual device",
    textDesc:
      "You can control devices connected to your Amazon Alexa or Google Home accounts by creating a virtual device.",
    textCreate: "Create"
  },
  deviceCapabilities: {
    textTitle: "Configure manually",
    textDecsTitle: "Let’s detect device capabilities",
    textDesc:
      "Your device may change state during detection process. Make sure the selected language in detection options is the one you use with the voice assistant.",
    textOptions: "Detection options",
    textOptionsDesc: "{language}, All capabilities",
    textBtn: "Start detection"
  },
  virtualDevices: {
    capabilitySearching: {
      title: "Detecting capabilities...",
      description:
        "Please do not power off the device during the detection process"
    },
    editCapability: {
      title: "Commands",
      done: "Done"
    },
    editCommand: {
      title: "Command",
      subtitle:
        "Enter the command you want to send to your voice assistant to apply the device to a specific state.\n E.g.: Turn on.",
      test: "Test",
      done: "Done"
    },
    configureCapabilities: {
      title: "Configure capabilities"
    },
    detectionCancelDialog: {
      title: "Leave the detection process?",
      sub:
        "It will stop the detection and capabilties won’t be configured automatically. Check your device as it may remain in changed state.",
      leave: "Leave"
    },
    linkVOI: {
      title: "No accounts linked to Ezlo VOI™?",
      sub:
        "You need to link your Amazon Alexa or Google voice assistant accounts to VOI in order to add virtual devices.",
      leave: "Link VOI account"
    },
    capabilityError: {
      title: "Sorry, we couldn’t detect any capability",
      l1: "Make sure you check the following:",
      l2: "- The device is powered ON",
      l3:
        "- You entered the device name exactly as it is written in your voice assistant app",
      l4:
        "- The language set in automatic discovery is the one you use when talking to your voice assistant",
      tryAgain: "Try again",
      config: "Configure manually"
    },
    selectVOI: {
      title: "Select Ezlo VOI™ account",
      bt: "Add new account"
    },
    setDimmer: {
      title: "Set dimmer",
      configureCustomDimmer: "Configure custom dimmer",
      commands: "Commands",
      commandsDescription:
        "By default we generated an autoscale for your dimmer between 0 and 100%. You can always change the scale min and max values.",
      customCommandsDescription:
        "Add set point with a command you want to send to your voice assistant when it is applied.",
      defaultDimmerScale: "Default dimmer scale",
      defaultDimmerScaleDescription: "Dim to [{min}-{max}%]",
      customDimmerScaleDescription: "Minimum 2 set points required.",
      addSetPoint: "Add set point",
      done: "Done"
    },
    defaultDimmerScale: {
      title: "Default dimmer scale",
      scaleValues: "Scale values",
      scaleValuesDescription:
        "You can change your dimmer min and max scale values.",
      min: "Min",
      max: "Max",
      defaultDimmerScaleDescription: "Dim to [{min}-{max}]",
      done: "Done"
    },
    addSetPoint: {
      title: "Add set point",
      titleEdit: "Edit set point",
      inputLabel: "Command",
      subtitle:
        "Enter the command you want to send to your voice assistant to apply device to a specific state. E.g.: Dim to 50%.",
      test: "Test",
      done: "Done"
    },
    detectionOptions: {
      title: "Detection options",
      header: "Language",
      sub:
        "Select the command language you currently use to control the device with your voice assistant.",
      detect: "Detect capabilities",
      capabilities:
        "Select the capability you want to be detected and configured automatically.",
      all: "Select All"
    }
  },
  newVirtualDevice: {
    titleNew: "New virtual device",
    titleEdit: "Edit virtual device",
    description: "Use the widget actions to test the virtual device.",
    groupTitle: "Capabilities",
    inputLabel: "Name",
    inputHint: "E.g.: Bedside lamp",
    actionText: "Add capability",
    textBtn: "Save device"
  },
  selectCapabilities: {
    title: "Select capabilities",
    groupTitle: "Supported by device",
    actionText: "Configure more capabilities",
    textBtn: "Add {count} capabilitiy"
  },
  virtualDeviceAdded: {
    title: "Virtual device added",
    byRoom: "By Room",
    byType: "By Type",
    failed: "Failed"
  },
  energy: {
    title: "Energy",
    compatibleDevicesLink:
      "Use this tool to better track and manage your energy usage for <link>compatible devices<link>",
    dailyAverage: "Daily average",
    monthToDate: "Month to date",
    fromMonth: "↓{differencePercentage}% from {longMonthName}",
    unknown: "Unknown",
    climate: "Climate",
    appliance: "Appliance",
    lighting: "Lighting",
    thermostatInLivingRoom: "Thermostat in Living room",
    heaterInBedroom: "Heater in Bedroom",
    control: "Control",
    thermostat: "Thermostat",
    filter: "Filter",
    create: "Create",
    reset: "Reset",
    cancel: "Cancel",
    backToLive: "Back to Live",
    total: "Total",
    live: {
      name: "Live",
      average: "Average per day",
      total: "Back to Live",
      warning:
        "Live view shows the real-time consumption when the app is open. Prior data is available in 10 minute increments.",
      usage: "Live usage"
    },
    day: {
      name: "Day",
      average: "Average per day",
      from: "From yesterday",
      total: "Day total",
      last: "Last day",
      goal: "Daily Goal",
      usage: "Usage by day"
    },
    week: {
      name: "Week",
      average: "Average per week",
      from: "From last week",
      total: "Week total",
      last: "Last week",
      goal: "Weekly Goal",
      usage: "Usage by week"
    },
    month: {
      name: "Month",
      average: "Average per month",
      from: "From last month",
      total: "Month total",
      last: "Last Month",
      goal: "Monthly Goal",
      usage: "Usage by month"
    },
    goals: {
      header: "Goals",
      headerButton: "Create",
      noGoalHeader: "Set energy goals",
      noGoalText:
        "Track usage and receive alerts when nearing your consumption goal.",
      noGoalButton: "Create goal"
    },
    energyDetails: {
      monitoring: {
        header: "Enable energy monitoring",
        text: "Collect and show its consumption data"
      },
      details: {
        time: {
          live: "Now stats",
          day: "Day stats",
          week: "Week stats",
          month: "Month stats"
        },
        consumption: {
          live: "Consuming now",
          total: "Total"
        },
        ratioText: "Breakdown ratio"
      }
    },
    calendar: {
      noDate: "No date is selected",
      today: "Today, ",
      month: "This month",
      week: "This week",
      day: "Today",
      live: "Today",
      apply: "Apply",
      infoText: "Energy history is limited by 2 months"
    }
  },
  roomType: {
    roomLight: "Room light",
    livingRoom: "Living room"
  },
  services: {
    title: "Energy, {dailyAverage} kWh",
    dailyAverage: "Daily average, {shortMonthName}",
    fromLastMonth: "↓{differencePercentage}% from last month"
  },
  iconList: {
    title: "Tile Icon",
    sub: "Select an icon from the list.", // or upload your own  The size of the icon should be 103*103px, up to 2MB.
    sub2: "Default Icon",
    current: "Current Icon",
    upload: "Upload custom icon",
    btn: "Done"
  },
  goals: {
    title: "New goal",
    iWantMy: "I want my",
    selectADevice: "Select a device",
    toBeUnder: "To be under",
    enterTheLimit: "Enter the limit",
    atTheEndOfThe: "At the end of the",
    month: "Month",
    save: "Save",
    noDeviceSelected: "No device selected",
    noLimitEntered: "No limit entered",
    limitErrorText: "Goal won't work without limit",
    goalSaved: "Goal saved",
    editGoal: "Edit goal"
  },
  dateTimeSelect: {
    headerTitle: "Select Tile Type",
    time: "Time",
    date: "Date",
    dateandtime: "Date & Time"
  },
  iconSets: {
    textTitle: "Icons",
    subTitle:
      "Select the set of icons that you want to use for the tile. You can upload your own icons.",
    v2: "Ezlo Icon v.2",
    v1: "Ezlo Icon v.1",
    custom: "Custom icons"
  },
  customIcons: {
    title: "Custom icons",
    subTitle:
      "Upload your own icons for any tile type. The size of the icon should be 56*56px, up to 2MB.",
    uploadCustomIcon: "Upload custom icon",
    errorImageSizeToBig: "The size of the icon should be 56*56px, up to 2MB.",
    IconAdded: "Icon added successfully",
    uploadedIcons: "Uploaded icons",
    btn: "Done"
  },
  houseModeSelect: {
    headerTitle: "Select mode",
    home: "Home",
    away: "Away",
    night: "Night",
    vacation: "Vacation",
    allModes: "All modes"
  },
  panicTypeSelect: {
    headerTitle: "Select tile type",
    medical: "Medical service",
    medicalTitle: "Medical",
    police: "Police service",
    policeTitle: "Police",
    fire: "Fire service",
    fireTitle: "Fire"
  },
  themeSettings: {
    headerTitle: "Theme",
    theme: "Theme",
    edit: "Edit",
    done: "Done",
    light: "Light",
    dark: "Dark",
    createNewTheme: "Add new theme",
    enterThemeName: "Enter theme name",
    themeName: "Theme name",
    textSnackbar: {
      textCreateSuccessful: "Theme created."
    }
  },
  editTheme: {
    headerTitle: "Edit theme",
    newTheme: "New theme",
    themeBackground: "Theme background",
    tiles: "Tiles",
    name: "Name",
    applyForAllTiles: "Apply for all tiles",
    applyForAllTilesDesc: "If disable you can setup style for each tile",
    tileBackground: "Tile background",
    fontColor: "Font color",
    iconColor: "Icon color",
    noAddedTiles:
      "There are no added tiles, when you add at least one tile, you can change its style.",
    save: "Save",
    themeName: {
      title: "Edit theme name",
      placeholder: "Theme name",
      btn: "Done"
    }
  }
});
