<template>
  <GridLayout
    class="month-picker"
    rows="auto, auto, auto"
    columns="*, *"
    width="100%"
  >
    <!-- place holder if no data -->
    <Label
      v-show="!shouldShow(prevMonth)"
      class="month-picker__label placeholder"
      row="0"
      col="0"
    />
    <!-- Prev Month -->
    <Label
      v-show="shouldShow(prevMonth)"
      class="month-picker__label out"
      :text="formatDate(prevMonth, 'MMMM')"
      row="0"
      col="0"
      @tap="setSelectedMonth('prev')"
    />
    <!-- Prev Year -->
    <Label
      v-show="shouldShow(prevYear)"
      class="month-picker__label out"
      :text="formatDate(prevYear, 'YYYY')"
      row="0"
      col="1"
      @tap="setSelectedYear('prev')"
    />
    <!-- Selected Month -->
    <Label
      class="month-picker__label selected"
      :text="formatDate(currentMonth, 'MMMM')"
      row="1"
      col="0"
    />
    <!-- Selected Year -->
    <Label
      class="month-picker__label selected"
      :text="formatDate(currentYear, 'YYYY')"
      row="1"
      col="1"
    />
    <!-- place holder if no data -->
    <Label
      v-show="!shouldShow(nextMonth)"
      class="month-picker__label placeholder"
      row="2"
      col="0"
    />
    <!-- Next Month -->
    <Label
      v-show="shouldShow(nextMonth)"
      class="month-picker__label out"
      :text="formatDate(nextMonth, 'MMMM')"
      row="2"
      col="0"
      @tap="setSelectedMonth('next')"
    />
    <!-- Next Year -->
    <Label
      v-show="shouldShow(nextYear)"
      class="month-picker__label out"
      :text="formatDate(nextYear, 'YYYY')"
      row="2"
      col="1"
      @tap="setSelectedYear('next')"
    />
  </GridLayout>
</template>

<script>
import moment from "moment";

export default {
  name: "MonthPicker",
  props: {
    date: {
      type: Object,
      default: () => ({
        start: new Date(),
        end: new Date()
      })
    },
    minDate: {
      type: Date,
      default: null
    },
    maxDate: {
      type: Date,
      default: null
    }
  },
  data() {
    return {
      selectedMonth: this.date.end,
      selectedYear: this.date.end
    };
  },
  computed: {
    prevMonth() {
      return moment(this.selectedMonth)
        .subtract(1, "months")
        .endOf("month")
        .toDate();
    },
    currentMonth() {
      return moment(this.selectedMonth).toDate();
    },
    nextMonth() {
      return moment(this.selectedMonth)
        .add(1, "months")
        .startOf("month")
        .toDate();
    },
    prevYear() {
      return moment(this.selectedYear)
        .subtract(1, "years")
        .toDate();
    },
    currentYear() {
      return moment(this.selectedYear).toDate();
    },
    nextYear() {
      return moment(this.selectedYear)
        .add(1, "years")
        .toDate();
    },
    dateSpan() {
      const theDate = moment(
        `${moment(this.selectedYear).year()}/${moment(
          this.selectedMonth
        ).month() + 1}/1`,
        "YYYY/MM/DD"
      );
      const startDate = moment(theDate)
        .startOf("month")
        .toDate();

      const endDate = moment(theDate)
        .endOf("month")
        .toDate();

      return {
        start: startDate < this.minDate ? this.minDate : startDate,
        end: endDate > this.maxDate ? this.maxDate : endDate
      };
    }
  },
  methods: {
    setSelectedMonth(direction) {
      if (direction === "prev") {
        this.$set(
          this,
          "selectedMonth",
          moment(this.selectedMonth)
            .subtract(1, "months")
            .valueOf()
        );
      } else if (direction === "next") {
        this.$set(
          this,
          "selectedMonth",
          moment(this.selectedMonth)
            .add(1, "months")
            .valueOf()
        );
      }
      this.$emit("change", { name: "month-selected", date: this.dateSpan });
    },
    setSelectedYear(direction) {
      if (direction === "prev") {
        this.$set(
          this,
          "selectedYear",
          moment(this.selectedYear)
            .subtract(1, "years")
            .valueOf()
        );
      } else if (direction === "next") {
        this.$set(
          this,
          "selectedYear",
          moment(this.selectedYear)
            .add(1, "years")
            .valueOf()
        );
      }
      this.$emit("change", { name: "year-selected", date: this.dateSpan });
    },
    formatDate(date, formatString) {
      return moment(date).format(formatString);
    },
    shouldShow(date) {
      if (this.minDate && this.maxDate) {
        return moment(date).isBetween(this.minDate, this.maxDate);
      } else if (this.minDate) {
        return moment(date).isSameOrAfter(this.minDate);
      } else if (this.maxDate) {
        return moment(date).isSameOrBefore(this.maxDate);
      }
      return true;
    },
    showPageRange(date) {
      this.$set(this, "selectedYear", date.end);
      this.$set(this, "selectedMonth", date.end);
    }
  }
};
</script>

<style scoped lang="scss">
@import "~assets/styles/typography.scss";
@import "~assets/styles/variables.scss";

.month-picker {
  &__label {
    @extend .font-h3-20;
    text-align: center;
    cursor: pointer;
    padding: $s;

    &.out {
      color: $color-grey-a75;
    }
    &.selected {
      border-top: solid;
      border-bottom: solid;
      color: $color-pure-black;
      border-width: $stroke;
      border-color: $color-grey-a25;
    }
    &.placeholder {
      height: $xxmm;
    }
  }
}
</style>
