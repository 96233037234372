<template>
  <MainPage>
    <GridLayout rows="auto, auto" columns="*">
      <KeyboardAvoidingView
        row="0"
        col="0"
        contentVerticalAlign="bottom"
        :iosTextInputKeyboardDistance="iosTextInputKeyboardDistance"
      >
        <IBtnAct
          slot="action-bar"
          src="icons/Controls/ic_controls_arrow-left_56.svg"
          @tap="handleBack"
        />

        <CreatePasswordForm
          slot="content"
          :isLoading="isFetching('updatePassword')"
          :titleText="translations.title"
          :passwordPlaceholder="translations.pwdPlaceholder"
          :reEnterPasswordPlaceholder="translations.reEnterPwdPlaceholder"
          :btnText="translations.btn"
          :errorText="translations.errorText"
          @submit="handleSubmit"
          @layoutChange="handleKeyboard"
        />
      </KeyboardAvoidingView>
    </GridLayout>
  </MainPage>
</template>
<script>
import CreatePasswordForm from "@/components/forms/CreatePasswordForm";
import { helpers } from "ezlo-core-ui";
import { createNamespacedHelpers } from "vuex";

const {
  mapGetters: mapUiGetters,
  mapActions: mapUiActions
} = createNamespacedHelpers("ui");
const { mapActions: mapAuthActions } = createNamespacedHelpers("auth");

const { IOS_TEXT_INPUT_KEYBOARD_OFFSET_DEFAULT } = helpers;
const IOS_TEXT_INPUT_KEYBOARD_OFFSET = 220;

export default {
  name: "ProfileCreatePassword",
  components: {
    CreatePasswordForm
  },
  props: {
    userCurrentPassword: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      areInputsVisible: false
    };
  },
  computed: {
    ...mapUiGetters(["isFetching", "error", "hasError"]),
    getUserCredentials() {
      return { oldPassword: this.userCurrentPassword };
    },
    iosTextInputKeyboardDistance() {
      return this.areInputsVisible
        ? IOS_TEXT_INPUT_KEYBOARD_OFFSET_DEFAULT
        : IOS_TEXT_INPUT_KEYBOARD_OFFSET;
    },
    translations() {
      const t = this.$t("createNewPassword");
      return { ...t, btn: this.$t("createNewPassword.btnText") };
    }
  },
  methods: {
    ...mapUiActions(["saveSnackbarText"]),
    ...mapAuthActions(["updatePasswordAsync"]),
    handleBack() {
      this.navigateBack();
    },
    handleKeyboard(areInputsVisible) {
      this.areInputsVisible = areInputsVisible;
    },
    async handleSubmit(password) {
      const user = { ...this.getUserCredentials, newPassword: password };

      await this.updatePasswordAsync(user);

      if (this.hasError) {
        return;
      }

      (this.error === null || this.error.error === null) &&
        this.saveSnackbarText(this.translations.snackbarText);
      this.navigateTo("LoginByUserIntro", {
        clearHistory: true
      });
    }
  }
};
</script>
