<template>
  <StackLayout>
    <ScrollView iosOverflowSafeArea="false">
      <StackLayout v-if="getDashboardsStatus">
        <Separator size="xl" />
        <StackLayout
          v-for="item in dashboards"
          :key="item.id"
          @tap="handleSelectDashboard(item)"
        >
          <IconHTxtiBtn
            :icon2Src="
              item.icon
                ? item.icon
                : 'icons/Actions/ic_actions_calendar-month_56.svg'
            "
            alignText="left"
            alignUnderline="left"
            headerSize="h3"
            showUnderline
            :disabled="item.disable === undefined ? true : item.disable"
            :text="item.name"
            :underlineText="item.desc"
          />
          <Separator size="xl" />
        </StackLayout>
      </StackLayout>
    </ScrollView>
  </StackLayout>
</template>
<script>
export default {
  name: "DashboardSelectOverview",
  props: {
    dashboards: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    getDashboardsStatus() {
      return !!this.dashboards.length;
    }
  },
  methods: {
    handleSelectDashboard(item) {
      this.$emit("onTapSelectDashboard", item);
    }
  }
};
</script>
