import Vue from "@/vue";
import Vuelidate from "vuelidate";
import VueRouter from "vue-router";
import VueGtag from "vue-gtag";
import Calendar from "v-calendar/lib/components/calendar.umd";
import DatePicker from "v-calendar/lib/components/date-picker.umd";

// Vuexp components
import AbsoluteLayout from "vuexp/src/layouts/AbsoluteLayout";
import FlexboxLayout from "vuexp/src/layouts/FlexboxLayout";
import GridLayout from "vuexp/src/layouts/GridLayout";
import WrapLayout from "vuexp/src/layouts/WrapLayout";
import StackLayout from "vuexp/src/layouts/StackLayout";
import DockLayout from "vuexp/src/layouts/DockLayout";

import Page from "vuexp/src/core/components/Page/Page";
import ScrollView from "vuexp/src/core/components/ScrollView/ScrollView";
import VxpImage from "vuexp/src/components/VxpImage";
import VxpButton from "vuexp/src/components/VxpButton";
import VxpListView from "vuexp/src/components/VxpListView";
import VxpLabel from "vuexp/src/components/VxpLabel";
import Label from "vuexp/src/core/components/Label/Label";
import VxpCheckbox from "vuexp/src/components/VxpCheckbox";
import TextField from "vuexp/src/core/components/TextField/TextField";
import TextView from "vuexp/src/core/components/TextView/TextView";
import Img from "vuexp/src/core/components/Img/Img";

// UI components import
import OfflineLock from "./components/ui/OfflineLock";
import AgreementsLink from "./components/onboarding/AgreementsLink";
import TemplateGrid from "@/components/dashboard/TemplateGrid";
import EmptyTemplate from "./components/dashboard/EmptyTemplate";
import TemplateRenderer from "@/components/dashboard/TemplateRenderer";
import TemplateWrapRenderer from "@/components/dashboard/TemplateWrapRenderer";
import TabLandscape from "@/components/dashboard/TabLandscape";
import ColorEllipse from "./components/ui/ColorEllipse";
import TileSettingsState from "@/components/dashboard/TileSettingsState";
import TileStateTile from "@/components/dashboard/TileStateTile";
import SizeSlider from "@/components/ui/SizeSlider";
import HourSlider from "@/components/ui/HourSlider";
import RangeSlider from "@/components/ui/RangeSlider";
import onOffCapability from "@/components/capabilities/onOffCapability";
import BadgeHTxtBtn from "./components/ui/Rows/BadgeHTxtBtn";
import Chart from "@/components/ui/chart";
import ColorBar from "./components/ui/ColorBar";

// cards
import ProgressiveCard from "./components/cards/ProgressiveCard";
import CalendarCard from "./components/cards/CalendarCard";

// dialogs
import ErrorDialog from "./components/dialogs/ErrorDialog";
import ErrorLinkValid from "./components/dialogs/ErrorLinkValid";
import LogoutConfirm from "./components/dialogs/LogoutConfirm";
import ChangeNameEntity from "./components/dialogs/ChangeNameEntity";
import HubList from "./components/dialogs/HubList";
import DeleteDashboardConfirm from "./components/dialogs/DeleteDashboardConfirm";
import RestoreStyleSettings from "./components/dialogs/RestoreStyleSettings";
import SingleTemplateConfirm from "./components/dialogs/SingleTemplateConfirm";
import ConfirmationDialog from "./components/dialogs/ConfirmationDialog";
import TileStatesList from "./components/dialogs/TileStatesList";

// virtual devices
import DetectionCancelDialog from "./components/dialogs/DetectionCancelDialog";
import LinkVOI from "./components/dialogs/LinkVOI";
import SelectVOIAccount from "./components/dialogs/SelectVOIAccount";

// Settings
import Avatar from "./components/settings/Avatar";

// Dashboard
import ListItem from "./components/dashboard/ListItem";
import PromoTileBig from "./components/dashboard/PromoTileBig";

// devices
import TileItem from "@/components/template/TileItem";
import DevicesGroups from "@/components/device/DevicesGroups";

// router page
import RouterPage from "@/pages/RouterPage";
import MainPage from "@/pages/MainPage";
import LoadingPage from "./pages/LoadingPage";

// Utils
import store from "./store";
import RouterModule from "./router";
import { routerOptions } from "./router";

import { i18n } from "./plugins/i18n";
import App from "~/App.vue";
import { navigate, destroySlots, isMobileBrowser } from "./mixins";

import VxpFontIcon from "vuexp-fonticon";

import VueApexCharts from "vue-apexcharts";
import VueScreen from "vue-screen";

// Core ezlo components
import {
  Snackbar,
  SvgIcon,
  Loading,
  IBtnAct,
  IconHAct,
  IconHTxtiBtn,
  IconH,
  BottomUpDialog,
  HiBtnDropdown,
  HAction,
  IBtnHiBtn,
  HiBtn,
  OneAction,
  RadioWithLabel,
  NavigationHeader,
  ImgControl,
  TextInput,
  MultilineInput,
  CSwitch,
  CheckboxLabel,
  ControlCheckbox,
  ControlSwitch,
  Radio,
  RadioGroup,
  RadioItem,
  HTextUnderlineTextSwitch,
  HyperlinkLabel,
  ButtonIcon,
  ButtonFilled,
  ButtonNoFill,
  ButtonFilledSmall,
  ButtonStroke,
  ButtonStrokeIcon,
  Separator,
  TabUI,
  TabHeader,
  Spinner,
  IconTwoActions,
  KeyboardAvoidingView,
  ColorPicker,
  CheckHTxtiBtn,
  RadioHTxt,
  SliderCore,
  RowPlaceholder,
  CardPlaceholder,
  AltCardPlaceholder
} from "ezlo-core-ui";

// Plugins

Vue.use(Vuelidate);
const router = new VueRouter({ routes: routerOptions.routes });
//route guard
// if (process.env.NODE_ENV === "production") {
router.beforeEach((to, from, next) => {
  if (
    to.name !== "LoginWithBounceTokenPage" &&
    to.name !== "SignUpIntro" &&
    to.name !== "LoginByUserIntro" &&
    to.name !== "LoadingPage" &&
    to.name !== "AutomaticLogin" &&
    to.name !== "ForgotPasswordEmailIntro" &&
    to.name !== "ForgotPasswordEmailSend" &&
    to.name !== "ForgotPasswordEmailReset" &&
    to.name !== "ForgotPasswordEmailCheck" &&
    to.name !== "ForgotPasswordEmailNewPassword" &&
    !store.getters["auth/isAuthenticated"]
  ) {
    if (store.getters["auth/isWebView"]) {
      next({ name: "LoginWithBounceTokenPage" });
    } else {
      next({ name: "AutomaticLogin" });
    }
  } else if (
    store.getters["auth/isAuthenticated"] &&
    from.name === null &&
    to.name !== "AutomaticLogin" &&
    to.name !== "LoadingPage" &&
    to.name !== "LoginWithBounceTokenPage"
  ) {
    if (store.getters["auth/isWebView"]) {
      next({ name: "LoginWithBounceTokenPage" });
    } else {
      next({ name: "AutomaticLogin" });
    }
  } else if (
    store.getters["auth/isWebView"] &&
    to.name === "DashboardPreview"
  ) {
    next({ name: "DashboardList" });
  } else next();
});
// }

Vue.use(VueRouter);
Vue.use(RouterModule);
Vue.mixin(navigate);
Vue.mixin(destroySlots);
Vue.mixin(isMobileBrowser);

//fonticon
const FontIcons = {
  paths: {
    ezlo: "./assets/styles/ezlo-icon.scss"
  }
};

Vue.use(VxpFontIcon, FontIcons);
Vue.use(VueApexCharts);
Vue.use(VueScreen);

// Incluted components
Vue.component("GridLayout", GridLayout);
Vue.component("StackLayout", StackLayout);
Vue.component("AbsoluteLayout", AbsoluteLayout);
Vue.component("FlexboxLayout", FlexboxLayout);
Vue.component("DockLayout", DockLayout);
Vue.component("WrapLayout", WrapLayout);

Vue.component("Page", Page);
Vue.component("VxpButton", VxpButton);
Vue.component("ImageCacheIt", Img);
Vue.component("VxpImage", VxpImage);
Vue.component("VxpLabel", VxpLabel);
Vue.component("ScrollView", ScrollView);
Vue.component("VxpListView", VxpListView);
Vue.component("VxpCheckbox", VxpCheckbox);
Vue.component("TextField", TextField);
Vue.component("TextView", TextView);

// Incluted core ezlo components

Vue.component("Label", Label);
Vue.component("SvgIcon", SvgIcon);
Vue.component("Loading", Loading);
Vue.component("ButtonIcon", ButtonIcon);
Vue.component("ButtonFilled", ButtonFilled);
Vue.component("ButtonFilledSmall", ButtonFilledSmall);
Vue.component("ButtonNoFill", ButtonNoFill);
Vue.component("ButtonStroke", ButtonStroke);
Vue.component("ButtonStrokeIcon", ButtonStrokeIcon);
Vue.component("CSwitch", CSwitch);
Vue.component("ControlCheckbox", ControlCheckbox);
Vue.component("ControlSwitch", ControlSwitch);
Vue.component("Radio", Radio);
Vue.component("RadioGroup", RadioGroup);
Vue.component("TextInput", TextInput);
Vue.component("MultilineInput", MultilineInput);
Vue.component("ImgControl", ImgControl);
Vue.component("Snackbar", Snackbar);
Vue.component("HyperlinkLabel", HyperlinkLabel);
Vue.component("CheckboxLabel", CheckboxLabel);
Vue.component("Spinner", Spinner);
Vue.component("Separator", Separator);
Vue.component("SizeSlider", SizeSlider);
Vue.component("HourSlider", HourSlider);

Vue.component("ColorPicker", ColorPicker);
Vue.component("RangeSlider", RangeSlider);
Vue.component("onOffCapability", onOffCapability);
Vue.component("RadioHTxt", RadioHTxt);
Vue.component("SliderCore", SliderCore);
Vue.component("Chart", Chart);
Vue.component("RowPlaceholder", RowPlaceholder);
Vue.component("CardPlaceholder", CardPlaceholder);
Vue.component("AltCardPlaceholder", AltCardPlaceholder);

Vue.component("RadioWithLabel", RadioWithLabel);
Vue.component("RadioItem", RadioItem);
Vue.component("HiBtn", HiBtn);
Vue.component("IBtnAct", IBtnAct);
Vue.component("IBtnHiBtn", IBtnHiBtn);
Vue.component("HAction", HAction);
Vue.component("HiBtnDropdown", HiBtnDropdown);
Vue.component("IconH", IconH);
Vue.component("IconHAct", IconHAct);
Vue.component("IconHTxtiBtn", IconHTxtiBtn);
Vue.component("BottomUpDialog", BottomUpDialog);
Vue.component("NavigationHeader", NavigationHeader);
Vue.component("OneAction", OneAction);
Vue.component("HTextUnderlineTextSwitch", HTextUnderlineTextSwitch);
Vue.component("IconTwoActions", IconTwoActions);
Vue.component("KeyboardAvoidingView", KeyboardAvoidingView);
Vue.component("TabUI", TabUI);
Vue.component("TabHeader", TabHeader);
// router page
Vue.component("RouterPage", RouterPage);
Vue.component("MainPage", MainPage);
Vue.component("LoadingPage", LoadingPage);

// Global UI Components
Vue.component("AgreementsLink", AgreementsLink);
Vue.component("TemplateGrid", TemplateGrid);
Vue.component("OfflineLock", OfflineLock);
Vue.component("TemplateWrapRenderer", TemplateWrapRenderer);
Vue.component("TemplateRenderer", TemplateRenderer);
Vue.component("TabLandscape", TabLandscape);
Vue.component("ColorEllipse", ColorEllipse);
Vue.component("TileSettingsState", TileSettingsState);
Vue.component("TileStateTile", TileStateTile);
Vue.component("EmptyTemplate", EmptyTemplate);
Vue.component("BadgeHTxtBtn", BadgeHTxtBtn);
Vue.component("ColorBar", ColorBar);

// Calendar
Vue.component("calendar", Calendar);
Vue.component("date-picker", DatePicker);

// cards
Vue.component("ProgressiveCard", ProgressiveCard);
Vue.component("CalendarCard", CalendarCard);

//dialogs
Vue.component("ErrorDialog", ErrorDialog);
Vue.component("ErrorLinkValid", ErrorLinkValid);
Vue.component("LogoutConfirm", LogoutConfirm);
Vue.component("ChangeNameEntity", ChangeNameEntity);
Vue.component("HubList", HubList);
Vue.component("DeleteDashboardConfirm", DeleteDashboardConfirm);
Vue.component("RestoreStyleSettings", RestoreStyleSettings);
Vue.component("SingleTemplateConfirm", SingleTemplateConfirm);
Vue.component("ConfirmationDialog", ConfirmationDialog);
Vue.component("TileStatesList", TileStatesList);
// Settings
Vue.component("Avatar", Avatar);

// Dashboard
Vue.component("ListItem", ListItem);
Vue.component("PromoTileBig", PromoTileBig);

// devices
Vue.component("TileItem", TileItem);
Vue.component("DevicesGroups", DevicesGroups);
Vue.component("CheckHTxtiBtn", CheckHTxtiBtn);

// virtual devices
Vue.component("DetectionCancelDialog", DetectionCancelDialog);
Vue.component("LinkVOI", LinkVOI);
Vue.component("SelectVOIAccount", SelectVOIAccount);
Vue.component("apexchart", VueApexCharts);
Vue.component("SliderCore", SliderCore);

Vue.config.api = require("../config/api");

Vue.use(
  VueGtag,
  {
    config: { id: "G-B4XLN94G3B" }
  },
  router
);

new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount("#app");
