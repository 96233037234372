<template>
  <GridLayout
    ref="slider"
    class="size-slider"
    rows="auto"
    columns="auto,*,auto"
  >
    <StackLayout
      row="0"
      col="0"
      class="size-slider__from"
      verticalAlignment="center"
    >
      <slot name="from" />
    </StackLayout>
    <GridLayout
      row="0"
      col="1"
      class="size-slider"
      rows="auto"
      columns="*"
      @tap="handleTap"
    >
      <StackLayout class="size-slider__block" verticalAlignment="center">
        <StackLayout
          ref="line"
          class="size-slider__background"
          height="2"
          @layoutChanged="handleLayoutChanged"
        />
      </StackLayout>
      <StackLayout class="size-slider__block" verticalAlignment="center">
        <GridLayout
          rows="auto"
          :columns="`${'auto,*,'.repeat(values - 1)}auto`"
          verticalAlignment="center"
        >
          <StackLayout
            :key="index"
            v-for="(item, index) of Array(values)"
            row="0"
            :col="2 * index"
            width="2"
            height="10"
            class="size-slider__divider"
          />
        </GridLayout>
      </StackLayout>
      <StackLayout
        horizontalAlignment="left"
        ref="curseur"
        class="size-slider__curseur"
      />
    </GridLayout>
    <StackLayout
      row="0"
      col="2"
      class="size-slider__to"
      verticalAlignment="center"
    >
      <slot name="to" />
    </StackLayout>
  </GridLayout>
</template>

<script>
import platform from "vuexp/src/platform";

const getXPosition = event => {
  return event;
};

const checkWidth = (width, maxWidth) => {
  if (width <= 0) return 0;
  if (width >= maxWidth) return maxWidth;
  return width;
};

export default {
  name: "SizeSlider",
  props: {
    animationSpeed: {
      type: Number,
      default: 100
    },
    initialValue: {
      type: Number,
      default: 0
    },
    values: {
      type: Number,
      default: 4
    },
    from: {
      type: String
    },
    to: {
      type: String
    }
  },
  data() {
    return {
      value: this.initialValue,
      width: 0,
      prevDeltaX: 0,
      isPanning: false
    };
  },
  watch: {
    initialValue(value) {
      this.value = value;
    }
  },
  methods: {
    handleTap(event) {
      const x = getXPosition(event);
      this.setX(x);
    },
    handlePan(event) {
      const curseur = this.$refs.curseur;
      if (curseur) {
        let position =
          event.clientX - curseur.$el.getBoundingClientRect().width * 1.5;
        if (this.isPanning) {
          curseur.$el.style.transform = `translateX(${position}px)`;
        }
      }
    },
    setX(translateX) {
      const { line, curseur } = this.$refs;
      if (curseur && line) {
        //  let position = curseur.$el.getBoundingClientRect().left + 50
        let position =
          translateX.clientX - curseur.$el.getBoundingClientRect().width * 1.5;
        this.width = line.$el.getBoundingClientRect().width;
        const value = this.getValue(position);
        const x = this.getX(value);
        curseur.$el.style.transform = `translateX(${x}px)`;

        this.$emit("valueChange", this.value);
        this.$emit("dragEnd", this.value);
      }
    },
    getX(value) {
      return checkWidth(value * (this.width / (this.values - 1)), this.width);
    },
    getValue(x) {
      if (x <= 0) return 0;
      if (x >= this.width) return this.values - 1;
      const value = Math.round((this.values - 1) * (x / this.width));
      return value > this.values - 1 ? this.values - 1 : value < 0 ? 0 : value;
    },
    handleLayoutChanged() {
      const { line, curseur } = this.$refs;
      if (line && line.nativeView) {
        this.width = line.nativeView.getActualSize().width;
      }
      if (curseur && curseur.nativeView) {
        curseur.nativeView.translateX = this.getX(this.initialValue);
      }
    },
    startPan() {
      this.isPanning = true;
    },
    stopPan() {
      this.isPanning = false;
    }
  },
  mounted() {
    const slider = this.$refs.slider;
    if (slider) {
      slider.$el.addEventListener("mousedown", this.startPan);
      slider.$el.addEventListener("mouseup", this.stopPan);
      slider.$el.addEventListener("mousemove", this.handlePan);
    }
  },
  destroyed() {
    const slider = this.$refs.slider;
    if (slider) {
      slider.$el.addEventListener("mousedown", this.startPan);
      slider.$el.addEventListener("mouseup", this.stopPan);
      slider.$el.addEventListener("mousemove", this.handlePan);
    }
  }
};
</script>

<style lang="scss">
@import "~/assets/styles/variables.scss";

.size-slider {
  padding: $s 0;
  &__from {
    margin-right: $l;
  }
  &__to {
    margin-left: $l;
  }
  &__background {
    background: $color-grey-a25;
  }
  &__block {
    margin: 0 $ml;
  }
  &__curseur {
    width: $xxm;
    height: $xxm;
    border-radius: 100%;
    border-width: $stroke;
    border-style: solid;
    border-color: $color-grey-a25;
    background: $color-white;
  }
  &__divider {
    background: $color-grey-a25;
  }
}
</style>
