<template>
  <StackLayout row="0" col="0" class="st-bg-image-overview__main">
    <Separator size="xl" />
    <HTextUnderlineTextSwitch
      showUnderline
      alignText="left"
      alignUnderline="left"
      headerSize="h3"
      :colorPrimary="onActiveColor"
      :text="texts.textUseBackgroundImage"
      :underlineText="texts.textUseBackgroundImageDesc"
      :value="useBackgroundImage"
      immediatelyChange
      @change="handleUseBackgroundImage"
    />
    <Separator size="xl" />
    <BackgroundImage
      v-if="useBackgroundImage"
      row="1"
      col="0"
      height="100%"
      dashboardId="0"
      :selectedImageSrc="selectedImageSrc"
      @selectImg="handleSelectBgImage"
    />
    <StackLayout
      v-if="!useBackgroundImage && !isWeb"
      row="1"
      col="0"
      class="st-bg-image-overview__main"
    >
      <GridLayout columns="*,auto" rows="56" width="100%" @tap="selectColor">
        <IconHTxtiBtn
          verticalAlignment="center"
          row="0"
          col="0"
          alignText="left"
          headerSize="h3"
          :text="texts.textBackgroundColor"
        />
        <ColorEllipse
          row="0"
          col="1"
          class="st-bg-image-overview__color-ellipse"
          :color="getTileColor"
        />
      </GridLayout>
      <Separator size="xl" />
    </StackLayout>
    <ScrollView
      v-if="!useBackgroundImage && isWeb"
      row="1"
      col="0"
      height="100%"
    >
      <GridLayout rows="*" columns="*" height="100%">
        <ColorPicker row="1" v-model="color" @input="colorChange" />
      </GridLayout>
    </ScrollView>
  </StackLayout>
</template>
<script>
import { helpers } from "ezlo-core-ui";
import { uuidMock } from "@/helpers/utils";
import { locals } from "@/helpers/variables";
import { openNativeGetImgDialog } from "@/helpers/openNativeGetImgDialog";
import { selectFileByCamera } from "@/helpers/selectFileByCamera";
import platform from "vuexp/src/platform";

import BackgroundImage from "@/components/dashboard/BackgroundImage";
import { colorHexA } from "@/helpers/utils";

const colorModule = helpers.core.color;
const imageSourceModule = helpers.core.imageSourceModule;
const { VUE_APP_MODE } = process.env;

export default {
  name: "BackgroundOverview",
  components: {
    BackgroundImage
  },
  props: {
    imagePresets: {
      type: Array,
      default: () => []
    },
    selectedImageSrc: {
      type: String,
      default: ""
    },
    isDisabled: {
      type: Boolean,
      default: true
    },
    texts: {
      type: Object,
      default: () => ({
        textAddCustomImg: "Custom handle",
        textPresetsTitle: "Block title",
        textBtn: "Button",
        textCameraDialog: {
          textFirst: "Action 1",
          textSecond: "Action 2",
          textCancel: "Back"
        }
      })
    },
    useBackgroundImage: {
      type: Boolean,
      default: false
    },
    colorHex: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      color: VUE_APP_MODE === "web" ? colorHexA(this.colorHex) : this.colorHex
    };
  },
  watch: {
    colorHex(newValue) {
      this.color = newValue.includes("rgb") ? newValue : colorHexA(newValue);
    }
  },
  computed: {
    onActiveColor() {
      return locals.colorPrimary;
    },
    isWeb() {
      return platform.platform === "web";
    },
    getTileColor() {
      if (VUE_APP_MODE === "web") {
        return colorHexA(this.colorHex);
      }
      return this.colorHex;
    }
  },
  methods: {
    getImageData({ file }) {
      const img = imageSourceModule.ImageSource.fromFileSync(file.filename);
      file.data = img;
      // example of path "/storage/emulated/0/DCIM/IMG_2020410_8291.jpg"

      const newImg = {
        id: uuidMock(),
        src: file.filename,
        selected: false
      };
      this.$emit("customImg", newImg);
    },
    async handleAddCustomImage() {
      let resp = {
        pickerType: "media",
        multipleSelection: false
      };

      if (VUE_APP_MODE !== "web") {
        resp = await openNativeGetImgDialog(this.texts.textCameraDialog);
      }
      selectFileByCamera(resp, this.getImageData);
    },
    handleSelectBgImage(item) {
      this.$emit("selectImg", item);
    },
    handleDone() {
      this.$emit("done");
    },
    handleUseBackgroundImage(value) {
      this.$emit("useBackgroundImage", value);
    },
    selectColor() {
      this.$emit("selectColor", this.color);
    },
    colorChange(value) {
      if (this.colorHex !== this.color) {
        this.$emit("colorChange", this.color);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~/assets/styles/base.scss";

.preset-picture {
  width: 100%;
  height: unit(215);
  margin-bottom: $margin-xl;
  &__image {
    border-radius: $corner-l;
    border-width: $corner-xs;
    border-color: transparent;
    border-style: solid;
  }
  &--selected {
    & .preset-picture__image {
      border-color: $color-primary;
    }
  }
}
.st-bg-image-overview {
  color: $color-black;
  height: 100%;
  &__main {
    height: 100%;
  }
  &__actions {
    margin-bottom: $margin-xl;
  }
  &__presets-title {
    margin-bottom: $margin-xl;
  }
  &__btn {
    padding-bottom: $padding-xl;
  }
  &__primary {
    /deep/ & * {
      color: $color-primary;
    }
  }

  &__presets,
  &__btn {
    padding-left: $padding-xl;
    padding-right: $padding-xl;
  }
  &__color-ellipse {
    margin-right: $margin-s;
  }
}
</style>
