import { SET_FAVORITES, ADD_FAVORITE, DELETE_FAVORITE } from "./mutationTypes";
import * as nmaService from "@/services/nma.service";

export default {
  async fetchFavorites({ dispatch, commit }) {
    try {
      dispatch("ui/startFetching", null, { root: true });
      const {
        data: { favorites }
      } = await nmaService.favorites.list();
      commit(SET_FAVORITES, favorites);
    } catch (error) {
      // eslint-disable-next-line
      console.log("fetchFavorites ERROR:", error);
      dispatch(
        "ui/emitError",
        {
          error: error,
          meta: "fetchFavorites"
        },
        {
          root: true
        }
      );
    } finally {
      dispatch("ui/stopFetching", null, { root: true });
    }
  },
  updateFavorite({ commit }, { action, data }) {
    switch (action) {
      case "added":
        commit(ADD_FAVORITE, data);
        break;
      case "removed":
        commit(DELETE_FAVORITE, data);
        break;
    }
  }
};
