<template>
  <MainPage>
    <GridLayout rows="*, auto" columns="*">
      <KeyboardAvoidingView row="0" col="0" contentVerticalAlign="bottom">
        <IBtnAct
          slot="action-bar"
          src="icons/Controls/ic_controls_arrow-left_56.svg"
          @tap="goBack"
        />

        <PasswordSendForm
          slot="content"
          :title="titleT"
          :description="descT"
          :emailPlaceholder="placeholderT"
          :btnText="btnT"
          @submit="onSend"
        />
      </KeyboardAvoidingView>
    </GridLayout>
  </MainPage>
</template>

<script>
import PasswordSendForm from "@/components/forms/PasswordSendForm";
import { createNamespacedHelpers } from "vuex";

const { mapActions: mapAuthActions } = createNamespacedHelpers("auth");

export default {
  name: "ForgotPasswordEmailIntro",
  components: {
    PasswordSendForm
  },
  data() {
    return {
      titleT: this.$t("forgotPasswordEmailIntro.title"),
      descT: this.$t("forgotPasswordEmailIntro.description"),
      placeholderT: this.$t("forgotPasswordEmailIntro.emailPlaceholder"),
      btnT: this.$t("forgotPasswordEmailIntro.btn")
    };
  },
  methods: {
    ...mapAuthActions(["setUserEmail"]),
    goBack() {
      this.navigateBack();
    },
    goNext() {
      this.navigateTo("ForgotPasswordEmailSend");
    },
    onSend(email) {
      this.setUserEmail({ email });
      this.goNext();
    }
  }
};
</script>
