<template>
  <IconTwoActions
    iconPath="icons/Visuals/ic_warning_delete_200.svg"
    type="warning2"
    :text1="translations.text1"
    :text2="translations.text2"
    :btnTextAccept="translations.btnTextDelete"
    :btnTextCancel="translations.btnTextCancel"
    :loading="isFetching"
    @accept="onDelete"
    @cancel="cancel"
  />
</template>

<script>
export default {
  name: "DeleteDashboardConfirm",
  props: {
    isFetching: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    translations() {
      return {
        text1: this.$t("core.deleteDashboardConfirm.text1"), // eslint-disable-line
        text2: this.$t("core.deleteDashboardConfirm.text2"), // eslint-disable-line
        btnTextDelete: this.$t("core.deleteDashboardConfirm.btnTextDelete"), // eslint-disable-line
        btnTextCancel:this.$t("core.deleteDashboardConfirm.btnTextCancel") // eslint-disable-line
      };
    }
  },
  methods: {
    onDelete() {
      this.$emit("delete");
    },
    cancel() {
      this.$emit("close");
    }
  }
};
</script>
