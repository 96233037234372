<template>
  <GridLayout rows="*" columns="*" height="100%">
    <StackLayout row="0" class="climate-view">
      <DailyAverage
        class="climate-view__padding"
        :title="energy.climate.dailyAverage"
        :titleDesc="energy.measurementUnit"
        :desc1="translations.dailyAverage"
        :desc2="statusDesc"
      />
      <VxpImage
        class="climate-view__graph"
        :src="graphImage"
        width="auto"
        height="auto"
        stretch="aspectFill"
        verticalAlignment="center"
      />
      <MonthlyConsumption
        class="climate-view__montly"
        :monthToDate="`${energy.climate.monthToDate}${energy.measurementUnit}`"
        :monthToDateDesc="translations.monthToDate"
        :monthly="`${energy.climate.monthly}`"
        :montlyDesc="energy.longMonthName"
      />
      <Separator size="xl" />
      <ConsumptionRate
        :title="`${energy.climate.livingRoom.rate}%`"
        :desc="translations.thermostatInLivingRoom"
        :src="iconClimate"
        :rate="energy.unknown.rate"
        :barColor="colorSec3"
        @tap="onTap('livingRoom')"
      />
      <Separator size="xl" />
      <ConsumptionRate
        :title="`${energy.climate.bedroom.rate}%`"
        :desc="translations.heaterInBedroom"
        :src="iconClimate"
        :rate="energy.climate.rate"
        :barColor="colorSec3"
        @tap="onTap('bedroom')"
      />
      <Separator size="xl" />
    </StackLayout>
  </GridLayout>
</template>

<script>
import { locals } from "../../assets/styles/variables.scss";
import DailyAverage from "./DailyAverage";
import MonthlyConsumption from "./MonthlyConsumption";
import ConsumptionRate from "./ConsumptionRate";

export default {
  name: "ClimateOverview",
  components: { DailyAverage, MonthlyConsumption, ConsumptionRate },
  props: {
    energy: {
      type: Object,
      default: () => ({})
    },
    graphImage: {
      type: String,
      default: "~/assets/images/Energy/Month-30-Days-2.png"
    },
    iconClimate: {
      type: String,
      default: "icons/Category/ic_category_climate_56.svg"
    }
  },
  computed: {
    translations() {
      return {
        dailyAverage: this.$t("energy.dailyAverage"),
        monthToDate: this.$t("energy.monthToDate"),
        climate: this.$t("energy.climate"),
        thermostatInLivingRoom: this.$t("energy.thermostatInLivingRoom"),
        heaterInBedroom: this.$t("energy.heaterInBedroom")
      };
    },
    statusDesc() {
      const { longMonthName } = this.energy;
      const { differencePercentage } = this.energy.climate;
      return this.$t("energy.fromMonth", {
        differencePercentage,
        longMonthName
      });
    },
    colorSec3() {
      return "#50c1d6";
    }
  },
  methods: {
    onTap(event) {
      this.$emit("tap", event);
    }
  }
};
</script>

<style lang="scss">
@import "~/assets/styles/variables.scss";

.climate-view {
  &__desc {
    color: $color-grey;
  }
  &__desc-status {
    color: $color-primary;
  }
  &__padding {
    padding: 0 $padding-xl $m $padding-xl;
  }
  &__graph {
    padding: 0 $padding-xl $padding-xl $padding-xl;
  }
  &__montly {
    padding: $padding-xl $padding-xl $m $padding-xl;
  }
}
</style>
