<template>
  <StackLayout class="d-overview">
    <ScrollView iosOverflowSafeArea="false" class="d-overview__scroll">
      <GridLayout class="d-overview" rows="auto, *" columns="*">
        <VxpLabel
          row="0"
          col="0"
          class="d-overview__title font-h1-32"
          :text="texts.textTitle"
          :textWrap="true"
        />

        <StackLayout
          row="1"
          col="0"
          rowSpan="2"
          class="d-overview__items"
          v-if="getDashboardsStatus"
        >
          <Separator size="xl" />
          <HiBtn
            class="d-overview__add-button"
            src="icons/Controls/ic_controls_plus_primary_56.svg"
            :text="texts.add"
            @tap="handleAddDashboard"
            headerSize="h3"
          />
          <StackLayout
            v-for="(item, idx) in dashboards"
            :key="item.id"
            @tap="handleEditDashboard(item, $event)"
          >
            <Separator size="xl" />
            <IconHTxtiBtn
              icon2Src="icons/Controls/ic_controls_arrow-right_56.svg"
              alignText="left"
              alignUnderline="left"
              headerSize="h3"
              :showUnderline="item.isDefault"
              :disabled="true"
              :text="item.name"
              :underlineText="texts.textDefaultDashboard"
            />
            <Separator size="xl" v-if="getLastDashboardItem(idx)" />
          </StackLayout>
        </StackLayout>

        <StackLayout
          row="1"
          col="0"
          rowSpan="2"
          class="d-overview__promo"
          v-if="getPromoStatus"
        >
          <PromoTileBig
            :image="promoImage"
            :iconPath="promoIconClose"
            :type="texts.textPromo.textHint"
            :title="texts.textPromo.textTitle"
            :btn="texts.textPromo.textBtn"
            @addDashboard="handleAddDashboard"
          />
        </StackLayout>
      </GridLayout>
    </ScrollView>
  </StackLayout>
</template>

<script>
export default {
  name: "DashboardListOverview",
  props: {
    dashboards: {
      type: Array,
      default: () => []
    },
    isPromoShown: {
      type: Boolean,
      default: false
    },
    promoImage: {
      type: String,
      default: "/images/promo_1.png"
    },
    promoIconClose: {
      type: String,
      default: "icons/Controls/ic_controls_input-erase_56.svg"
    },
    texts: {
      type: Object,
      default: () => ({
        textTitle: "Title",
        add: "Add",
        textPromo: {
          textTitle: "Title Promo",
          textHint: "Hint",
          textBtn: "Button"
        }
      })
    }
  },
  computed: {
    getDashboardsLength() {
      return this.dashboards.length;
    },
    getDashboardsStatus() {
      return !!this.getDashboardsLength;
    },
    getPromoStatus() {
      return this.isPromoShown && !this.getDashboardsStatus;
    }
  },
  methods: {
    getLastDashboardItem(index) {
      return this.getDashboardsLength - 1 === index;
    },
    handleAddDashboard() {
      this.$emit("onTapAddDashboard");
    },
    handleCloseDashboardPromo() {
      this.$emit("onCloseDashboardPromo");
    },
    handleEditDashboard(item) {
      this.$emit("onTapEditDashboard", item);
    }
  }
};
</script>

<style scoped lang="scss">
@import "~/assets/styles/variables.scss";

.d-overview {
  overflow-y: auto;
  &__add-button {
    color: $color-primary;
  }
  &__scroll {
    height: 100%;
  }

  &__title {
    padding: $padding-xxxxll $padding-xl $padding-xl $padding-xl;
    color: $color-black;
  }

  &__promo {
    min-height: $web-min-height;
    padding-left: $padding-xl;
    padding-right: $padding-xl;
    padding-top: $padding-xl;
  }
}
</style>
