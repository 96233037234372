<template>
  <MainPage>
    <GridLayout rows="auto, auto, * ,auto" columns="*" class="tile-settings">
      <NavigationHeader
        row="0"
        icon1Src="icons/Controls/ic_controls_arrow-left_56.svg"
        textSize="h3"
        :text="headerText"
        @iconTap1="goBack"
      />
      <StackLayout row="1" col="0">
        <Separator size="xl" />
        <IconHTxtiBtn
          v-if="categories && categories.length > 1"
          alignText="left"
          alignUnderline="left"
          headerSize="h3"
          :text="stateText"
          icon2Src="icons/Controls/ic_controls_drop-down-down_56.svg"
          @tap="onTapSelectState()"
        />
      </StackLayout>
      <ScrollView row="2">
        <StackLayout class="tile-settings__scroll">
          <StackLayout class="tile-settings__scroll__name">
            <!-- TODO: remove tileIcon when every state has their own icon -->
            <TileSettingsState
              ref="tileDemo"
              class="tile-settings__tabs"
              :text="translations"
              :tileElement="tileElement"
              :tileState="activeTileState"
              :tileLabel="tileLabel"
              :isWebView="isWebView"
              :tileType="tileType"
            />
          </StackLayout>
        </StackLayout>
      </ScrollView>

      <StackLayout row="3" class="tile-settings__button">
        <ButtonFilled
          :disabled="!canSave"
          :text="translations.textBtn"
          @tap="doneButtonTap"
        />
      </StackLayout>
      <BottomUpDialog class="dialog" ref="dialog" col="0" rowSpan="4">
        <component
          :is="dialog.component"
          v-bind="dialog.data"
          @restore="handleRestore"
          @close="handleCloseDialog"
          @select="changeState"
        />
      </BottomUpDialog>
    </GridLayout>
  </MainPage>
</template>

<script>
import { isEqual, cloneDeep, merge } from "lodash";
import { ColorPicker } from "../../helpers/helperLibrary";
import { helpers } from "ezlo-core-ui";
import { colorHexA } from "@/helpers/utils";
import { createNamespacedHelpers } from "vuex";
const { mapGetters: mapTemplateGetters } = createNamespacedHelpers("template");
const { mapGetters: mapAuthGetters } = createNamespacedHelpers("auth");
const { mapGetters: mapDashboardGetters } = createNamespacedHelpers(
  "dashboards"
);
const colorModule = helpers.core.color;

const { VUE_APP_MODE } = process.env;
export default {
  name: "TileSettings",
  props: {
    dashboardId: {
      type: String,
      default: "1"
    },
    orientation: String,
    tile: Object,
    tileState: String,
    tileLabel: String,
    categories: Array,
    category: {
      type: String,
      default: ""
    },
    subcategory: {
      type: String,
      default: ""
    },
    tileType: {
      type: String,
      default: ""
    },
    save: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      picker: null,
      dialog: {
        component: "",
        data: {}
      },
      colorKey: null,
      activeTileState: null,
      designProperties: null
    };
  },
  created() {
    this.setDesignProperties();
    this.setTileState();
  },
  watch: {
    tile(newVal) {
      this.setDesignProperties();
    },
    tileState(newVal) {
      this.setActiveTileState(newVal);
    }
  },
  mounted() {
    this.$root.$on("_tapBackground", this.selectBackground);
    this.$root.$on("_tapSelectColor", this.selectColor);
    this.$root.$on("_tapSelectItem", this.selectIcon);
    this.$root.$on("_tapRestoreDefaults", this.restoreDefaults);
    this.$root.$on("_tileBackgroundSelected", this.backgroundSelected);
    this.$root.$on("_hideTitle", this.hideTitle);
    this.$root.$on("_hideIcon", this.hideIcon);
    this.$root.$on("_changeColor", this.changeColor);
    this.$root.$on("_changeIcon", this.changeIcon);
  },
  beforeDestroy() {
    this.selectBackground && this.$root.$off("_tapBackground");
    this.selectColor && this.$root.$off("_tapSelectColor");
    this.selectIcon && this.$root.$off("_tapSelectItem");
    this.restoreDefaults && this.$root.$off("_tapRestoreDefaults");
    this.hideTitle && this.$root.$off("_hideTitle");
    this.hideIcon && this.$root.$off("_hideIcon");
    this.changeColor && this.$root.$off("_changeColor");
    this.changeIcon && this.$root.$off("_changeIcon");
  },
  computed: {
    ...mapTemplateGetters(["getDefaultDesignProperties"]),
    ...mapAuthGetters(["isWebView"]),
    ...mapDashboardGetters(["getDashboardTheme"]),
    translations() {
      return this.$t("tileSettings");
    },
    canSave() {
      return !isEqual(this.designProperties, this.tile.props.design_properties);
    },
    tileSettingsText() {
      return {
        icon_color: this.translations.textIconColor,
        font_color: this.translations.textFontColor
      };
    },
    stateText() {
      return this.activeTileState === "general"
        ? this.translations.textEditTile
        : `${this.$t("tileStates").capabilities[this.activeTileState] || ""} ${
            this.translations.headerLast
          }`;
    },
    headerText() {
      return this.translations.textEditTile;
    },
    tileElement() {
      return (
        this.designProperties &&
        (this.designProperties[this.activeTileState] ||
          this.designProperties["general"])
      );
    }
  },
  methods: {
    goBack() {
      this.setDesignProperties();
      this.setTileState();
      this.navigateBack();
    },
    setDesignProperties() {
      const design_properties = merge(
        this.tile.props.theme,
        this.tile.props.design_properties
      );
      this.$set(this, "designProperties", cloneDeep(design_properties));
    },
    setTileState() {
      this.activeTileState =
        this.tileState === "general" && this.categories.length
          ? this.categories[0].name
          : this.tileState;
    },
    setActiveTileState(newState) {
      this.$set(this, "activeTileState", newState);
    },
    selectBackground() {
      this.navigateTo("Background", {
        props: {
          bgType: "tile",
          backgroundOptions: this.tileElement
        },
        backstackVisible: true
      });
    },
    selectColor(key) {
      const oldColorHex = this.tileElement[key];

      if (process.env.VUE_APP_PLATFORM !== "web") {
        if (!this.picker) {
          this.picker = new ColorPicker();
        }
        this.picker
          .show(oldColorHex, "ARGB")
          .then(result => {
            const color = new colorModule.Color(result);
            const colorKeyObj = { [key]: color ? color.hex : undefined };
            this.setProperties(colorKeyObj);
          })
          .catch(err => {
            console.log(`Error handle select color:${err}`); // eslint-disable-line
          });
      } else {
        this.colorKey = key;
        this.navigateTo("ColorSelect", {
          props: {
            title: this.tileSettingsText[key],
            colorHex:
              VUE_APP_MODE === "web" ? colorHexA(oldColorHex) : oldColorHex
          },
          backstackVisible: true
        });
      }
    },
    changeColor(color) {
      if (this.colorKey) {
        const colorKeyObj = { [this.colorKey]: color || undefined };
        this.setProperties(colorKeyObj);
      }
    },
    selectIcon(event) {
      this.navigateTo("IconSetSelection", {
        props: {
          category: this.category,
          subcategory: this.subcategory,
          tileType: this.tileType,
          name: this.tileLabel,
          currentIcon: this.tileElement.icon
        },
        backstackVisible: false
      });
    },
    restoreDefaults() {
      this.dialog = {
        component: "RestoreStyleSettings"
      };
      this.handleShowDialog();
    },
    doneButtonTap() {
      const event = !this.save
        ? "_tileSettingsTileChanged"
        : "_tileSettingsTileChangedSave";
      this.$root.$emit(event, {
        tileState: this.activeTileState,
        data: { ...this.tileElement }
      });
      this.navigateBack();
    },
    handleRestore() {
      this.handleCloseDialog();
      const theme = this.getDashboardTheme(this.dashboardId)[this.orientation]
        .data.tile["default"];
      const design = theme || this.getDefaultDesignProperties.general;
      this.$set(this.designProperties, this.activeTileState, cloneDeep(design));
    },
    handleShowDialog() {
      const dialog = this.$refs.dialog;
      dialog && dialog.show();
    },
    handleCloseDialog() {
      const dialog = this.$refs.dialog;
      dialog && dialog.hide();
    },
    changeIcon(event) {
      this.setProperties(event);
    },
    backgroundSelected(event) {
      this.setProperties(event);
    },
    hideTitle(event) {
      this.setProperties(event);
    },
    hideIcon(event) {
      this.setProperties(event);
    },
    tileStatesListDialog() {
      this.dialog = {
        component: "TileStatesList",
        data: {
          states: [...this.categories],
          activeState: this.activeTileState
        }
      };
      this.handleShowDialog();
    },
    onTapSelectState() {
      this.tileStatesListDialog();
    },
    changeState(event) {
      console.log("### selectState ---->", event); //eslint-disable-line
      this.setActiveTileState(event.id);
    },
    setProperties(event) {
      this.$set(this.designProperties, this.activeTileState, {
        ...this.tileElement,
        ...event
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~/assets/styles/variables.scss";

.tile-settings {
  color: $color-black;
  &__scroll {
    &__label {
      padding: $xl $lmx $xl $lmx;
    }
    &__name {
      padding-bottom: $s;
    }
  }

  &__button {
    padding: $m $xl $xl $xl;
  }

  &__tabs {
    /deep/ & .tab-ui__header {
      margin-bottom: $xl;
    }
  }
}
</style>
