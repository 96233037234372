import { EFAULT } from "constants";

export const dashboardState = () => {
  return {
    light: {
      default: {
        use_background_image: false,
        background_image: "",
        background_color: "#ffffff",
        font_color: "#ffffff",
        icon_color: "#ffffff"
      }
    },
    dark: {
      default: {
        use_background_image: false,
        background_image: "",
        background_color: "#141520",
        font_color: "#ffffff",
        icon_color: "#ffffff"
      }
    },
    deep_blue: {
      default: {
        use_background_image: false,
        background_image: "",
        background_color: "#040958",
        font_color: "#ffffff",
        icon_color: "#ffffff"
      }
    }
  };
};

export const tileState = () => {
  return {
    light: {
      default: {
        use_background_image: false,
        background_image: "",
        background_color: "#3E46AE",
        font_color: "#ffffff",
        icon_color: "#ffffff",
        empty_tile: {
          has_image: {
            icon: "icons/Controls/ic_controls_plus_white_56.svg",
            background_color: "rgba(255, 255, 255, 0.3)"
          },
          has_color: {
            icon: "icons/Controls/ic_controls_plus_56_grey.svg",
            background_color: "rgba(255, 255, 255, 0.3)"
          }
        },
        nav_bar: {
          background_color: "#F9FAFB"
        }
      },
      on: {
        use_background_image: false,
        background_image: "",
        background_color: "#3E46AE",
        font_color: "#ffffff",
        icon_color: "#ffffff"
      },
      off: {
        use_background_image: false,
        background_image: "",
        background_color: "#3E46AE",
        font_color: "#ffffff",
        icon_color: "#ffffff"
      }
    },
    dark: {
      default: {
        use_background_image: false,
        background_image: "",
        background_color: "#141520",
        font_color: "#ffffff",
        icon_color: "#ffffff",
        empty_tile: {
          has_image: {
            icon: "icons/Controls/ic_controls_plus_white_56.svg",
            background_color: "transparent"
          },
          has_color: {
            icon: "icons/Controls/ic_controls_plus_white_56.svg",
            background_color: "transparent"
          }
        },
        nav_bar: {
          background_color: "rgba(48, 49, 66, 0.5)"
        }
      },
      on: {
        use_background_image: false,
        background_image: "",
        background_color: "#303142",
        font_color: "#ffffff",
        icon_color: "#ffffff"
      },
      off: {
        use_background_image: false,
        background_image: "",
        background_color: "#141520",
        font_color: "#ffffff",
        icon_color: "#ffffff"
      }
    },
    deep_blue: {
      default: {
        use_background_image: false,
        background_image: "",
        background_color: "#040958",
        font_color: "#ffffff",
        icon_color: "#ffffff",
        empty_tile: {
          has_image: {
            icon: "icons/Controls/ic_controls_plus_white_56.svg",
            background_color: "transparent"
          },
          has_color: {
            icon: "icons/Controls/ic_controls_plus_white_56.svg",
            background_color: "transparent"
          }
        },
        nav_bar: {
          background_color: "#3E46A"
        }
      },
      on: {
        use_background_image: false,
        background_image: "",
        background_color: "#3E46AE",
        font_color: "#ffffff",
        icon_color: "#ffffff"
      },
      off: {
        use_background_image: false,
        background_image: "",
        background_color: "#040958",
        font_color: "#ffffff",
        icon_color: "#ffffff"
      }
    }
  };
};

export const defaultCustomTheme = () => {
  return {
    uuid: "",
    name: "",
    type: "custom",
    predefined: false,
    selected: false,
    dashboard: {
      default: {
        ...dashboardState().light.default
      }
    },
    tile: { default: { ...tileState().light.default } }
  };
};

export default function() {
  return {
    themes: [],
    defaultThemes: {
      light: {
        uuid: "light",
        name: "Light",
        data: {
          type: "predefined",
          predefined: true,
          selected: true,
          dashboard: {
            default: {
              ...dashboardState().light.default
            }
          },
          tile: {
            default: {
              ...tileState().light.default
            },
            on: {
              ...tileState().light.on
            },
            off: {
              ...tileState().light.off
            }
          }
        }
      },
      dark: {
        uuid: "dark",
        name: "Dark",
        data: {
          type: "predefined",
          predefined: true,
          selected: false,
          dashboard: {
            default: {
              ...dashboardState().dark.default
            }
          },
          tile: {
            default: {
              ...tileState().dark.default
            },
            on: {
              ...tileState().dark.on
            },
            off: {
              ...tileState().dark.off
            }
          }
        }
      },
      deep_blue: {
        uuid: "deep_blue",
        name: "Deep blue",
        data: {
          type: "predefined",
          predefined: true,
          selected: false,
          dashboard: {
            default: {
              ...dashboardState().deep_blue.default
            }
          },
          tile: {
            default: {
              ...tileState().deep_blue.default
            },
            on: {
              ...tileState().deep_blue.on
            },
            off: {
              ...tileState().deep_blue.off
            }
          }
        }
      }
    },
    hasThemeChanged: false,
    tempTheme: {}
  };
}
