<template>
  <OneAction
    icon="icons/Visuals/ic_warning_visuals_not-responding_200.svg"
    :text1="translations.title"
    :text2="translations.sub"
    :action="translations.leave"
    :warning="true"
    @tap="$emit('close', $event)"
  />
</template>

<script>
export default {
  name: "ErrorDialog",
  computed: {
    translations() {
      return this.$t("virtualDevices.detectionCancelDialog");
    }
  }
};
</script>
