<template>
  <StackLayout>
    <StackLayout class="tile-states-list__close-button">
      <SvgIcon v-if="closeButton" :src="closeButtonIcon" @tap="close" />
    </StackLayout>
    <VxpLabel
      class="tile-states-list__title font-h2-24"
      :text="translations.textTitle"
      :textWrap="true"
    />
    <VxpLabel
      class="tile-states-list__decs font-txt-16-par"
      :text="translations.textInfo"
      :textWrap="true"
    />
    <ThemeList
      ref="tileStateList"
      :items="items"
      :editMode="false"
      listMode="radio"
      :selectedItem="selectedItem"
      @onSelect="handleSelect"
    />
  </StackLayout>
</template>
<script>
import { sleep } from "@/helpers/utils";
import ThemeList from "@/pages/theme/ThemeList";

const mockItems = [
  {
    id: "on",
    name: "On",
    selected: false
  },
  {
    id: "off",
    name: "Off",
    selected: false
  },
  {
    id: "test",
    name: "Test",
    selected: false
  }
];
export default {
  name: "TileStatesList",
  components: {
    ThemeList
  },
  props: {
    states: {
      type: Array,
      default: () => []
    },
    activeState: {
      type: String,
      default: ""
    },
    icon: {
      type: String
    },
    closeButton: {
      type: Boolean,
      default: true
    },
    closeButtonIcon: {
      type: String,
      default: "icons/Controls/ic_controls_close_56.svg"
    },
    resultActionName: {
      type: String,
      default: "tileStates"
    },
    closeWhenSelected: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      selectedItem: { id: this.activeState }
    };
  },
  watch: {
    activeState(newValue) {
      this.selectedItem = { id: newValue };
    }
  },
  computed: {
    translations() {
      return this.$t("tileStates");
    },
    prepareItems() {
      return this.states.map(item => {
        return {
          id: item.name,
          name: this.translations.capabilities[item.name],
          selected: item.name === this.activeState
        };
      });
    },
    items() {
      return this.states.length > 0 ? this.prepareItems : mockItems;
    }
  },
  methods: {
    close(event) {
      this.$emit("close");
    },
    async handleSelect(event) {
      this.selectedItem = event;
      this.$emit("select", { ...event, selected: true });
      if (this.closeWhenSelected) {
        await sleep(50);
        this.close();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~/assets/styles/base.scss";
.tile-states-list {
  &__close-button {
    padding-left: $padding-s;
    align-self: start;
  }
  &__title {
    margin: 0 $margin-xl;
  }
  &__decs {
    margin: $margin-s $margin-xl 0;
    color: $color-ui-black;
  }
}
</style>
