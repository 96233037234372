<template>
  <StackLayout
    class="sector"
    ref="sectors"
    @layoutChanged="handleLayoutChanged"
  >
    <GridLayout
      class="sector__grid"
      :columns="'*,'.repeat(template.columns)"
      :rows="`${sectorSize},`.repeat(template.rows)"
      width="100%"
      height="100%"
    >
      <StackLayout
        v-for="(layout, index) in template.layout"
        :key="index"
        :col="layout.colStart"
        :row="layout.rowStart"
        :colSpan="layout.colEnd - layout.colStart + 1"
        :rowSpan="layout.rowEnd - layout.rowStart + 1"
      >
        <slot></slot>
      </StackLayout>
    </GridLayout>
  </StackLayout>
</template>
<script>
export default {
  name: "TemplateGrid",
  props: {
    template: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      sectorSize: 0
    };
  },
  methods: {
    handleLayoutChanged() {
      if (this.sectorSize) return;
      const { columns } = this.template;
      if (!columns) return;
      const { sectors: { nativeView } = {} } = this.$refs;
      if (!nativeView) return;
      this.sectorSize = nativeView.getActualSize().width / columns;
    }
  }
};
</script>
<style lang="scss" scoped>
.sector {
  height: 100%;
  &__grid {
    height: 100%;
  }
}
</style>
