export const getCreatedLocations = (locations = []) => {
  //   old implementation
  //   const list =
  //   locations.length &&
  //   locations.map(location => {
  //     const { PK_Installation, FirstName, Devices, ...rest } = location;
  //     const locationHubs = Devices.map(({ PK_Device, ...hubData }) => ({
  //       hubSerial: String(PK_Device),
  //       ...hubData
  //     }));
  //     return {
  //       id: PK_Installation,
  //       name: FirstName || PK_Installation,
  //       hubs: locationHubs,
  //       ...rest
  //     };
  //   });

  // return list;
  const list =
    locations.length &&
    locations.map(location => {
      const { Devices } = location;
      const locationHubs = Devices.map(({ PK_Device, ...hubData }) => ({
        hubSerial: String(PK_Device),
        ...hubData
      }));
      return {
        id: locationHubs[0].PK_Installation,
        name: locationHubs[0].PK_Installation,
        hubs: locationHubs
      };
    });

  return list;
};
