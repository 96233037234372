<template>
  <MainPage>
    <GridLayout height="100%" rows="auto,*" columns="*">
      <IBtnHiBtn
        row="0"
        col="0"
        icon1Src="icons/Controls/ic_controls_arrow-left_56.svg"
        @iconTap1="navigateBack"
        :text="translations.textTitle"
      />
      <GridLayout
        row="1"
        col="0"
        rows="*,auto"
        class="font-size__overview"
        height="100%"
      >
        <StackLayout row="0">
          <ScrollView height="100%" iosOverflowSafeArea="false">
            <GridLayout rows="auto,auto,*,auto" columns="*" height="100%">
              <StackLayout row="0" class="font-size__overview__block">
                <VxpLabel
                  class="font-txt-16-par font-size__overview--text"
                  :text="translations.textInstruction"
                  textWrap
                />
              </StackLayout>
              <StackLayout row="1" class="font-size__overview__block">
                <TileItem
                  width="103"
                  height="103"
                  horizontalAlignment="left"
                  titleTextWrap
                  :title="tileItem.title"
                  :srcPng="tileItem.srcPng"
                  :fontFamily="fontFamily"
                  :fontSize="newFontSize"
                />
              </StackLayout>
              <StackLayout row="2"></StackLayout>
              <StackLayout class="font-size__overview__block" row="3">
                <SizeSlider
                  :initialValue="value"
                  @valueChange="handleValueChange"
                >
                  <VxpLabel slot="from" class="font-txt-16-par" text="A" />
                  <VxpLabel slot="to" class="font-h2-24" text="A" />
                </SizeSlider>
              </StackLayout>
            </GridLayout>
          </ScrollView>
        </StackLayout>
        <StackLayout row="1">
          <ButtonFilled
            :disabled="isDisabled"
            :text="translations.textBtn"
            @tap="handleSubmit"
          />
        </StackLayout>
      </GridLayout>
    </GridLayout>
  </MainPage>
</template>
<script>
import { createNamespacedHelpers } from "vuex";

const {
  mapActions: mapDashboardActions,
  mapGetters: mapDashboardGetters
} = createNamespacedHelpers("dashboards");

export default {
  name: "FontSize",
  props: {
    dashboardId: {
      type: String,
      //   required: true
      default: "0"
    },
    tileItem: {
      type: Object,
      default: () => ({
        title: "VistaCam 1000",
        srcPng: "~/assets/images/Devices/DefaultCameraBack.png"
      })
    },
    fontFamily: {
      type: String,
      default: "ezlo-sans"
    },
    fontSize: {
      type: [String, Number],
      default: 16
    }
  },
  data() {
    return {
      value: (this.fontSize - 16) / 2, // initial slider step: 0, 1, 2, 3
      newFontSize: this.fontSize
    };
  },
  computed: {
    ...mapDashboardGetters(["getDefaultStyleSettings"]),
    translations() {
      return this.$t("fontSize"); // eslint-disable-line
    },
    isDisabled() {
      return this.fontSize == this.newFontSize;
    },
    defaultFontSize() {
      const {
        general: { fontSize }
      } = this.getDefaultStyleSettings;
      return Number(fontSize);
    }
    // styleSettings() {
    //   return this.getStylesChanged(this.dashboardId);
    // }
  },
  methods: {
    handleSubmit() {
      this.$root.$emit("_fontSizeSelected", {
        id: this.dashboardId,
        fontSize: this.newFontSize
      });
      this.navigateBack();
    },
    handleValueChange(value) {
      this.value = value;
      this.newFontSize = this.defaultFontSize + value * 2;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~/assets/styles/variables.scss";
.font-size {
  &__overview {
    padding: $xl;
    &__block {
      margin-bottom: $xl;
      width: $tile-height;
      height: $tile-height;
    }
    &--text {
      color: $color-black;
    }
  }
}
</style>
