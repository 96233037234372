<template>
  <MainPage>
    <GridLayout rows="*" columns="*">
      <StackLayout row="0" col="0" verticalAlignment="top">
        <IBtnAct
          src="icons/Controls/ic_controls_arrow-left_56.svg"
          @tap="goBack"
        />

        <IntroGetUp
          iconPath="icons/Visuals/ic_visuals_password-recovery_200.svg"
          :title="titleT"
          :descr="descrT"
          :btnText="btnT"
          :btnLoading="isFetching('resetPassword')"
          :isForgotPassword="true"
          @send="sendForgotPassword"
        />
      </StackLayout>
    </GridLayout>
  </MainPage>
</template>

<script>
import IntroGetUp from "@/components/onboarding/IntroGetUp";
import { createNamespacedHelpers } from "vuex";

const {
  mapActions: mapAuthActions,
  mapGetters: mapAuthGetters
} = createNamespacedHelpers("auth");
const { mapGetters: mapUiGetters } = createNamespacedHelpers("ui");

export default {
  name: "ForgotPasswordEmailSend",
  components: {
    IntroGetUp
  },
  data() {
    return {
      titleT: this.$t("forgotPasswordEmailSend.title"),
      descrT: this.$t("forgotPasswordEmailSend.description"),
      btnT: this.$t("forgotPasswordEmailSend.btn")
    };
  },
  computed: {
    ...mapUiGetters(["isFetching"]),
    ...mapAuthGetters(["userEmail", "resetPasswordStatus"])
  },
  methods: {
    ...mapAuthActions(["resetPasswordAsync"]),
    goBack() {
      this.navigateBack();
    },
    goNext(email) {
      this.navigateTo("ForgotPasswordEmailReset", {
        props: { userEmail: email }
      });
    },
    async sendForgotPassword() {
      await this.resetPasswordAsync();
      this.resetPasswordStatus && this.goNext(this.userEmail);
    }
  }
};
</script>
