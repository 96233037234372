<template>
  <GridLayout
    @tap="$emit('tap', $event)"
    rows="*"
    columns="*"
    class="template"
    :backgroundColor="backgroundColor"
    width="100%"
  >
    <SvgIcon class="template__image" :src="icon" />
  </GridLayout>
</template>
<script>
export default {
  name: "EmptyTemplate",
  props: {
    icon: {
      type: String,
      default: "icons/Controls/ic_controls_plus_white_56.svg"
    },
    backgroundColor: {
      type: String,
      default: "rgba(255, 255, 255, 0.3)"
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~/assets/styles/variables.scss";

.template {
  height: 100%;
  vertical-align: middle;
  horizontal-align: center;
  border-radius: $m;
  border-width: $xxs;
  border-color: $color-grey-a25;
  border-style: solid;
  &__image {
    justify-self: center;
    align-self: center;
  }
  &__opacity {
    background-color: rgba(255, 255, 255, 0.3);
  }
}
</style>
