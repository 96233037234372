import Vue from "@/vue";
import {
  SET_HUB_LIST,
  RESET_HUB_STATE,
  SET_HUB_UUIDS,
  SET_RELAY_TOKENS,
  SET_CONNECTION_STATUS,
  RESET_CONNECTION_STATUS
} from "./mutationTypes";
import InitialState from "./state";

const connectionStatus = {
  CONNECTING: "connecting",
  CONNECTED: "connected",
  DISCONNECTED: "disconnected"
};
export default {
  [SET_CONNECTION_STATUS](state, payload) {
    console.log("SET_CONNECTION_STATUS", payload);//eslint-disable-line
    Vue.set(
      state.connectionStatuses,
      payload.id,
      connectionStatus[payload.status]
    );
  },
  [RESET_CONNECTION_STATUS](state) {
    console.log("RESET_CONNECTION_STATUS");//eslint-disable-line
    Vue.set(state, "connectionStatuses", {});
  },
  [SET_HUB_LIST](state, payload) {
    Vue.set(state, "hubs", payload);
  },
  [RESET_HUB_STATE](state) {
    for (let prop in state) {
      Vue.set(state, prop, InitialState()[prop]);
    }
  },
  [SET_HUB_UUIDS](state, payload) {
    Vue.set(state, "hubUUIDs", payload);
  },
  [SET_RELAY_TOKENS](state, payload) {
    const {
      relayToken,
      data: { PK_Device, Server_Relay }
    } = payload;
    const index = state.relayTokens.findIndex(
      item => item.PK_Device === PK_Device
    );
    const item = { PK_Device, Server_Relay, relayToken };
    if (index === -1) {
      state.relayTokens.push(item);
    } else {
      state.relayTokens[index] = item;
    }
  }
};
