export const initialJSON = () => {
  return {
    version: 1,
    name: "",
    desc: "",
    id: "0",
    template: {
      portrait: "", // <uuid of portrait template without ->
      landscape: "" //<uuid of landscape template without ->
    },
    styleSettings: {
      general: {
        backgroundColor: "#ffffff",
        background_image: "",
        fontFamily: "ezlo-sans",
        fontSize: "16"
      },
      portrait: {
        theme_id: "light"
      },
      landscape: {
        theme_id: "light"
      }
    },
    template_data: {
      portrait: {
        design_properties: {
          background_image: "", //<image object, optional>
          background_color: "" //<background color, optional field>
        },
        dashboard_elements: [
          {
            cell_id: "", //<cell id according to template>
            title: "", // cell title, optional field (scene, device have their own name)
            target_entity: {
              object_id: "", // <id of the object being attached to dashboard, can be device id, abstract id, scene id>
              type: "", //<enum with possible values device, scene, abstract>
              parent_entity: "", //<id of parent entity, e.g  controller id>
              parent_entity_type: "" //<enum of parent resource, can be controller|cloud>
            },
            design_properties: {
              background_image: "", //<image object, optional>
              background_color: "#3e46ae", //<background color, optional field>
              on: {
                background_image: "", //<image object, optional>
                background_color: "#3e46ae", //<background color, optional field>
                font_color: "#ffffff",
                icon: "",
                icon_color: "#ffffff"
              },
              off: {
                background_image: "", //<image object, optional>
                background_color: "#ffffff", //<background color, optional field>
                font_color: "#ffffff",
                icon: "",
                icon_color: "#ffffff"
              }
            }
          }
        ]
      },
      landscape: {
        theme_id: "light",
        design_properties: {
          background_image: "", //<image object, optional>
          background_color: "" //<background color, optional field>
        },
        dashboard_elements: [
          {
            cell_id: "", //<cell id according to template>
            title: "", // cell title, optional field (scene, device have their own name)
            type: "", //<enum with possible values device, scene, abstract>
            target_entity: {
              object_id: "", //<id of the object being attached to dashboard, can be device id, abstract id, scene id>
              type: "", //<enum with possible values device, scene, abstract>
              parent_entity: "", //<id of parent entity, e.g  controller id>
              parent_entity_type: "" //<enum of parent resource, can be controller|cloud>
            },
            design_properties: {
              background_image: "", //<image object, optional>
              background_color: "#3e46ae", //<background color, optional field>
              on: {
                background_image: "", //<image object, optional>
                background_color: "#3e46ae", //<background color, optional field>
                font_color: "#ffffff",
                icon: "",
                icon_color: "#ffffff",
                hideTitle: false,
                hideIcon: false
              },
              off: {
                background_image: "", //<image object, optional>
                background_color: "#ffffff", //<background color, optional field>
                font_color: "#ffffff",
                icon: "",
                icon_color: "#ffffff",
                hideTitle: false,
                hideIcon: false
              }
            }
          }
        ]
      }
    }
  };
};

const defaultCellApiFormat = ({ attrs, props }) => ({
  cell_id: attrs.id,
  title: props.title,
  target_entity: {
    object_id: props.id,
    type: "abstract",
    parent_entity: "",
    parent_entity_type: ""
  },
  design_properties: { ...props.design_properties }
});

const sceneApiFormat = ({ type, attrs, props }) => ({
  cell_id: attrs.id,
  title: props.title,
  target_entity: {
    object_id: props.id,
    type,
    parent_entity: props.controller_id,
    parent_entity_uuid: props.controller_uuid,
    parent_entity_type: "controller"
  },
  design_properties: { ...props.design_properties }
});

const deviceApiFormat = ({ type, attrs, props }) => ({
  cell_id: attrs.id,
  title: props.title,
  target_entity: {
    object_id: props.id,
    type,
    parent_entity: props.controller_id,
    parent_entity_uuid: props.controller_uuid,
    parent_entity_type: "controller"
  },
  design_properties: { ...props.design_properties }
});

const dashboardApiFormat = ({ type, attrs, props }) => ({
  cell_id: attrs.id,
  title: props.title,
  target_entity: {
    object_id: props.id,
    type
  },
  design_properties: { ...props.design_properties }
});

const urlApiFormat = ({ type, attrs, props }) => ({
  cell_id: attrs.id,
  title: props.title,
  target_entity: {
    object_id: props.id,
    type
  },
  design_properties: { ...props.design_properties }
});

const datetimeApiFormat = ({ type, attrs, props }) => ({
  cell_id: attrs.id,
  title: props.title,
  target_entity: {
    object_id: props.id,
    type,
    sub_type: props.subType
  },
  design_properties: { ...props.design_properties }
});

const houseModeApiFormat = ({ type, attrs, props }) => ({
  cell_id: attrs.id,
  title: props.title,
  target_entity: {
    object_id: props.id,
    type,
    sub_type: props.subType
  },
  design_properties: { ...props.design_properties }
});

const panicApiFormat = ({ type, attrs, props }) => ({
  cell_id: attrs.id,
  title: props.title,
  target_entity: {
    object_id: props.id,
    type,
    sub_type: props.subType
  },
  design_properties: { ...props.design_properties }
});

const linkApiFormat = ({ type, attrs, props, props: { subType, text } }) => ({
  cell_id: attrs.id,
  title: props.title,
  subType,
  text,
  target_entity: {
    object_id: props.id,
    type,
    subType,
    text
  },
  design_properties: { ...props.design_properties }
});

const cellApiFormats = {};
const registerCellApiFormat = (type, fn) => {
  cellApiFormats[type] = fn;
};

const getCellApiFormat = ({ attrs, props = {} }) => {
  const { type } = props;
  const fn = cellApiFormats[type] || defaultCellApiFormat;
  return fn && fn({ type, attrs, props });
};

registerCellApiFormat("device", deviceApiFormat);
registerCellApiFormat("scene", sceneApiFormat);

registerCellApiFormat("dashboard", dashboardApiFormat);
registerCellApiFormat("url", urlApiFormat);
registerCellApiFormat("datetime", datetimeApiFormat);
registerCellApiFormat("link", linkApiFormat);
registerCellApiFormat("houseMode", houseModeApiFormat);
registerCellApiFormat("panic", panicApiFormat);

const cellsToApiFormat = (cells, item) => {
  const element = item.children[0].children[0];
  if (element.comp !== "EmptyTemplate") {
    const cell = getCellApiFormat(element);
    cells.push(cell);
  }
  return cells;
};

export function getSetupDashboardJSON(data, isCreate = false) {
  const { template_tiles, template_data, ...rest } = data;
  const portraitGrid = template_tiles && template_tiles.portrait;
  const landscapeGrid = template_tiles && template_tiles.landscape;

  const template = {
    ...(portraitGrid && {
      portrait: portraitGrid && portraitGrid.id
    }),
    ...(landscapeGrid && {
      landscape: landscapeGrid && landscapeGrid.id
    })
  };

  const getPortraitElements =
    portraitGrid && portraitGrid.children && portraitGrid.children.length
      ? portraitGrid.children.reduce(cellsToApiFormat, [])
      : [];

  const getLandscapeElements =
    landscapeGrid && landscapeGrid.children && landscapeGrid.children.length
      ? landscapeGrid.children.reduce(cellsToApiFormat, [])
      : [];

  const newDasboard = {
    ...rest,
    ...(isCreate && {
      version: 1,
      desc: ""
    }),
    template,
    template_data: {
      ...(portraitGrid && {
        portrait: {
          theme_id: "light",
          ...(isCreate && {
            design_properties: {
              use_background_image: "",
              background_image: "",
              background_color: ""
            }
          }),
          ...(template_data && template_data.portrait),
          dashboard_elements: getPortraitElements
        }
      }),
      ...(landscapeGrid && {
        landscape: {
          theme_id: "light",
          ...(isCreate && {
            design_properties: {
              use_background_image: "",
              background_image: "",
              background_color: ""
            }
          }),
          ...(template_data && template_data.landscape),
          dashboard_elements: getLandscapeElements
        }
      })
    }
  };
  return newDasboard;
}
