<template>
  <MainPage>
    <GridLayout height="100%" rows="*" columns="*" class="dashboards">
      <StackLayout row="0" col="0" class="dashboards__list">
        <GridLayout
          row="0"
          col="0"
          rows="64"
          columns="auto"
          class="dashboards__navigation"
        >
          <SvgIcon col="0" :src="closeIcon" @tap="goBack" />
        </GridLayout>

        <DashboardListOverview
          row="1"
          col="0"
          :isPromoShown="isPromoShown"
          :dashboards="filterDashboards(getControllerId)"
          :texts="textsMain"
          @onTapAddDashboard="handleAddDashboard"
          @onCloseDashboardPromo="hidePromo"
          @onTapEditDashboard="handleDashboarEdit"
          @onAddDashboard="handleAddDashboard"
        />
      </StackLayout>
    </GridLayout>
  </MainPage>
</template>

<script>
import DashboardListOverview from "@/components/dashboard/DashboardListOverview";
import { createNamespacedHelpers } from "vuex";
import { isEmpty } from "@/helpers/utils";

const {
  mapGetters: mapUiGetters,
  mapActions: mapUiActions
} = createNamespacedHelpers("ui");
const {
  mapGetters: mapAuthGetters,
  mapActions: mapAuthActions
} = createNamespacedHelpers("auth");
const {
  mapGetters: mapDashboardsGetters,
  mapActions: mapDashboardsActions
} = createNamespacedHelpers("dashboards");

const { mapGetters: mapDeviceGetters } = createNamespacedHelpers("devices");

const { mapActions: mapTemplateActions } = createNamespacedHelpers("template");
const { mapActions: mapThemesActions } = createNamespacedHelpers("themes");

export default {
  name: "DashboardList",
  components: {
    DashboardListOverview
  },
  computed: {
    ...mapUiGetters(["isSnackbarShowing"]),
    ...mapDashboardsGetters([
      "getDashboards",
      "filterDashboards",
      "isPromoShown",
      "getDefaultDashboardIndex"
    ]),
    ...mapAuthGetters([
      "getUserAvatar",
      "isFetchingAvatar",
      "isWebView",
      "getControllerId"
    ]),
    ...mapDeviceGetters(["getDevices"]),
    textsMain() {
      const t = this.$t("dashboardList");
      const tPromo = this.$t("dashboardPromoTile");
      return {
        textTitle: t.textTitle,
        add: t.add,
        textDefaultDashboard: t.dafaultDashboard,
        textPromo: {
          textTitle: tPromo.title,
          textHint: tPromo.type,
          textBtn: tPromo.btn
        }
      };
    },
    closeIcon() {
      return this.isWebView
        ? "icons/Controls/ic_controls_close_56.svg"
        : "icons/Controls/ic_controls_arrow-left_56.svg";
    }
  },
  methods: {
    ...mapUiActions(["emitError"]),
    ...mapDashboardsActions([
      "hidePromo",
      "resetNewDashboard",
      "setDashboardByIndex"
    ]),
    ...mapTemplateActions([
      "setTemplate",
      "setTemplateLandscape",
      "resetTemplateLandscape",
      "resetTemplatePortrait",
      "resetAssociatedElements",
      "setAssociatedElements"
    ]),
    ...mapAuthActions([
      "fetchUserAvatar",
      "setIsWebView",
      "setControllerId",
      "setUpdateTemplate"
    ]),
    ...mapThemesActions(["resetToDefaultTheme"]),
    handleProfile() {
      this.navigateTo("Profile");
    },
    goBack() {
      this.setUpdateTemplate(true);
      if (this.isWebView) {
        this.navigateTo("terminate-app");
        this.setIsWebView(false);
        this.setControllerId(null);
      } else {
        this.setDashboardByIndex(this.getDefaultDashboardIndex);
        this.$root.$emit("_resetIndex", this.getDefaultDashboardIndex);
        this.navigateBack();
      }
    },
    handleAddDashboard() {
      this.resetNewDashboard();
      this.resetAssociatedElements();
      this.resetToDefaultTheme();
      this.navigateTo("TemplateMode");
    },
    handleDashboarEdit(item) {
      const { id, template, template_data, styleSettings } = item;
      const { portrait, landscape } = template;
      this.resetTemplatePortrait();
      this.resetTemplateLandscape();
      if (!portrait && !landscape) return null;

      if (portrait) {
        this.setTemplate({
          id: portrait,
          dashboardId: id,
          tiles:
            template_data.portrait && template_data.portrait.dashboard_elements,
          styleSettings
        });
      }
      if (landscape) {
        this.setTemplateLandscape({
          id: landscape,
          dashboardId: id,
          tiles: template_data.landscape.dashboard_elements,
          styleSettings
        });
      }
      // fill selected items arrays of different stores like devices, scenes etc.
      this.setAssociatedElements(template_data);

      this.navigateTo("DashboardTemplate", {
        props: {
          dashboardId: id,
          editMode: true,
          activeTab: 0
        }
      });
    }
  },
  mounted() {
    this.fetchUserAvatar();
    // setTimeout(() => {
    //   if (!this.getDevices || !this.getDevices.length) {
    //     this.emitError({
    //       type: "errorAvailableDevices",
    //       meta: "fetchHubsAsync"
    //     });
    //   }
    // }, 1000);
  }
};
</script>

<style scoped lang="scss">
@import "~/assets/styles/variables.scss";

.dashboards {
  &__navigation {
    overflow-y: initial;
    min-height: $xxxxll;
    margin: 0 $margin-s;
  }
  &__list {
    overflow-y: auto;
  }
}
</style>
