import { render, staticRenderFns } from "./TemplateWrapRenderer.vue?vue&type=template&id=4de1d00f&scoped=true&"
import script from "./TemplateWrapRenderer.vue?vue&type=script&lang=js&"
export * from "./TemplateWrapRenderer.vue?vue&type=script&lang=js&"
import style0 from "./TemplateWrapRenderer.vue?vue&type=style&index=0&id=4de1d00f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4de1d00f",
  null
  
)

export default component.exports