<template>
  <GridLayout
    :rows="getSize"
    :columns="getSize"
    class="avatar"
    :width="imageSize"
    :height="imageSize"
    @tap="tap"
  >
    <ImageCacheIt
      row="0"
      col="0"
      v-if="!loading"
      verticalAlignment="center"
      stretch="aspectFill"
      :src="src"
      :width="imageSize"
      :height="imageSize"
      :borderRadius="borderRadius"
    />
    <StackLayout
      row="0"
      col="0"
      v-else
      verticalAlignment="center"
      horizontalAlignment="center"
    >
      <Spinner />
    </StackLayout>
  </GridLayout>
</template>

<script>
import { uuidMock } from "@/helpers/utils";
import { selectFileByCamera } from "@/helpers/selectFileByCamera";

export default {
  name: "Avatar",
  props: {
    src: {
      type: String,
      default: ""
    },
    imageSize: {
      type: Number,
      default: 104
    },
    borderRadius: {
      type: [Number, String],
      default: "100%"
    },
    editButton: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default: () => {
        return {
          // actions: ["Take photo", "Choose from library"],
          // "Take photo": filePicker.pickerTypes.camera,
          // "Choose from library": filePicker.pickerTypes.image,
          // Cancel: "cancel",
          // cancel: "Cancel"
        };
      }
    }
  },
  data() {
    return {
      setAvatar: null
    };
  },
  computed: {
    getSize() {
      return `${this.imageSize}`;
    }
  },
  methods: {
    tap() {
      if (!this.disabled) {
        this.$emit("tap");
      }
    },
    getFileData(file) {
      const newImg = {
        file,
        id: uuidMock(), //this.imagePresets.length + 1,
        src: file.name,
        selected: false
      };
      this.setAvatar(newImg);
    },
    async openActionDialog() {
      return "Choose from library";
    },
    initializeCamera(pickerType, _setAvatar) {
      this.setAvatar = _setAvatar;
      selectFileByCamera(
        {
          pickerType: "media",
          multipleSelection: false
        },
        this.getFileData
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~/assets/styles/variables.scss";
@import "~/assets/styles/typography.scss";

.avatar {
  justify-self: center;
  align-self: center;
  background-color: $color-black-035;
  fill: solid;
  border-radius: 100%;
  cursor: pointer;
}
</style>
