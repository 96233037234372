import {
  SET_VALUE_BY_KEY,
  SET_ACTIVE_MODE,
  ADD_DEVICE_TO_FILTER,
  REMOVE_DEVICE_FROM_FILTER,
  RESET_FILTER,
  SET_CURRENT_GOAL,
  SELECT_DEVICE,
  CLEAR_SELECTION,
  SET_GOALS,
  ADD_GOAL,
  UPDATE_GOAL,
  SET_ENERGY_DATA,
  SET_MONITORING,
  SET_SELECTED_TIME,
  SET_DEVICES,
  SET_LIVE_DATA,
  ADD_LIVE_DATA,
  SET_INITIAL_CONSUMPTION_OBJECT,
  SET_PREVIOUS_CONSUMPTION,
  SET_AVERAGE_CONSUMPTION,
  RESET_DEVICES,
  SET_CONNECTION,
  SET_LATEST_LIVE_DATA_VALUE,
  CLEAR_ENERGY_DATA
} from "./mutationTypes";
import Vue from "vue";
import { uuidMock } from "@/helpers/utils";
import { cloneDeep } from "lodash";
import moment from "moment";

let graphInitalValue = true;

function generateGoals(payload) {
  return payload.map(goal => generateGoal(goal));
}

function generateGoal(payload) {
  return {
    id: payload.id || uuidMock(),
    icon:
      payload.device.src ||
      (payload.device.icon && payload.device.icons.defaultSrc) ||
      payload.icon ||
      "icons/Controls/ic_controls_plus_primary_56.svg",
    header: payload.device.name || payload.header || "Device name",
    subHeader: payload.subHeader || "Monthly goal",
    percentage: (payload.used / payload.limit) * 100 || 0,
    description: `${payload.used || 0} of ${payload.limit || 0} kWh used`,
    descriptionColor: payload.descriptionColor || "#000000",
    subDescription: payload.subDescription || "First month in progress",
    barColors: payload.barColors || {
      foreground: "#00B491",
      background: "rgba(144, 160, 175, 0.15)"
    },
    used: payload.used || 0,
    limit: payload.limit || 0,
    month: payload.month,
    device: payload.device
  };
}

function generateLiveData(payload) {
  const { electricMeterMetric: metrics } = payload;
  return metrics.values || [];
}

function generateDayData(payload) {
  const { electricMeterMetric: metrics, selectedDates } = payload;
  const data = new Array(24).fill(null);
  if (metrics) {
    metrics.values.forEach(item => {
      const time = item[0];
      const value = item[1] / 1000;
      const hour = moment
        .utc(time)
        .local()
        .hour();
      if (data[hour]) {
        data[hour] = data[hour] += value;
      } else {
        data[hour] = value;
      }
    });
  }
  return data;
}

function generateWeekData(payload) {
  const { electricMeterMetric: metrics, selectedDates } = payload;
  const data = new Array(7).fill(null);
  if (metrics) {
    metrics.values.forEach(item => {
      const time = item[0];
      const value = item[1] / 1000;
      const day = moment.utc(time).day();
      if (data[day]) {
        data[day] = data[day] += value;
      } else {
        data[day] = value;
      }
    });
  }
  return data;
}

function generateMonthData(payload) {
  const { electricMeterMetric: metrics, selectedDates } = payload;
  const data = new Array(moment(selectedDates.start).daysInMonth()).fill(null);
  if (metrics) {
    metrics.values.forEach(item => {
      const time = item[0];
      const value = item[1] / 1000;
      const day =
        moment
          .utc(time)
          .local()
          .date() - 1;
      if (data[day]) {
        data[day] = data[day] += value;
      } else {
        data[day] = value;
      }
    });
  }
  return data;
}

function generateEnergyData(payload) {
  let data = {
    live: [],
    day: [],
    week: [],
    month: []
  };
  const { mode, metrics, selectedDates } = payload;

  if (metrics && metrics.length > 0) {
    const electricMeterMetric = metrics.find(
      metric => metric.name === "electric_meter_watt" //"electric_meter_kwh"
    );
    data.live =
      mode === "live" || !mode
        ? generateLiveData({
            electricMeterMetric,
            selectedDates
          })
        : [];
    data.day =
      mode === "day" || !mode
        ? generateDayData({
            electricMeterMetric,
            selectedDates
          })
        : [];
    data.week =
      mode === "week" || !mode
        ? generateWeekData({
            electricMeterMetric,
            selectedDates
          })
        : [];
    data.month =
      mode === "month" || !mode
        ? generateMonthData({
            electricMeterMetric,
            selectedDates
          })
        : [];
  }
  return data;
}

function calculateConsumption(payload) {
  const { mode, data } = payload;
  let count = 0,
    total = 0,
    average = 0;

  const consumption = data.filter(item => item);
  count = consumption.length;
  total = consumption.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );
  average = total / count || 0;
  return {
    count,
    total,
    average
  };
}

export default {
  [SET_VALUE_BY_KEY](state, payload) {
    const { key, value } = payload;
    Vue.set(state, key, value);
  },
  [SET_CURRENT_GOAL](state, payload) {
    Vue.set(state, "currentGoal", payload);
  },
  [SELECT_DEVICE](state, payload) {
    state.deviceGroups.forEach(group => {
      group.devices.forEach(device => {
        device.selected = device.id == payload;
      });
    });
  },
  [SET_MONITORING](state, payload) {
    const { id, monitoringEnabled } = payload;
    const device = state.devices.find(item => item.id === id);
    if (device) {
      Vue.set(device, "monitoringEnabled", monitoringEnabled);
    }
  },
  [CLEAR_SELECTION](state) {
    state.deviceGroups.forEach(group => {
      group.devices.forEach(device => {
        device.selected = false;
      });
    });
  },
  [SET_ACTIVE_MODE](state, payload) {
    Vue.set(state, "activeMode", payload);
  },
  [ADD_DEVICE_TO_FILTER](state, deviceId) {
    if (state.filteredDevices.indexOf(deviceId) === -1) {
      Vue.set(state, "filteredDevices", [...state.filteredDevices, deviceId]);
    }
  },
  [REMOVE_DEVICE_FROM_FILTER](state, deviceId) {
    Vue.set(
      state,
      "filteredDevices",
      state.filteredDevices.filter(id => deviceId !== id)
    );
  },
  [RESET_FILTER](state) {
    Vue.set(state, "filteredDevices", []);
  },
  [SET_GOALS](state, payload) {
    Vue.set(state, "goals", generateGoals(payload));
  },
  [ADD_GOAL](state, payload) {
    if (!payload) return;
    state.goals.push(generateGoal(payload));
  },
  [UPDATE_GOAL](state, payload) {
    if (!payload) return;
    let index = state.goals.findIndex(item => item.id === payload.id);
    if (index !== -1) {
      // state.goals[index] = generateGoal(payload);
      Vue.set(state.goals, index, generateGoal(payload));
    }
  },
  [SET_ENERGY_DATA](state, payload) {
    const { mode, deviceId } = payload;
    if (!payload || !mode) return;
    const data = generateEnergyData(payload);

    Vue.set(state.consumption[mode][deviceId], "values", data[mode]);
    if (mode !== "live") {
      const average = calculateConsumption({
        mode,
        data: data[mode]
      }).average;
      Vue.set(state.consumption[mode][deviceId], "average", average);
    }
  },
  [SET_PREVIOUS_CONSUMPTION](state, payload) {
    const { mode, deviceId, metrics } = payload;
    if (!payload || !mode) return;
    let value = 0;
    if (metrics && metrics.length > 0) {
      metrics[0].values.forEach(item => {
        value += item[1];
      });
    }
    Vue.set(state.consumption[mode][deviceId], "previous", value);
  },
  [SET_AVERAGE_CONSUMPTION](state, payload) {
    const { mode, deviceId, metrics } = payload;
    if (!payload || !mode) return;
    let value = 0;
    if (metrics && metrics.length > 0) {
      metrics[0].values.forEach(item => {
        value += item[1] / 1000;
      });
    }
    Vue.set(state.consumption[mode][deviceId], "average", value);
  },
  [SET_SELECTED_TIME](state, payload) {
    let { type, data } = payload;
    let selectedDates = cloneDeep(state.selectedDates);
    selectedDates[type] = data;
    Vue.set(state, "selectedDates", selectedDates);
  },
  [SET_DEVICES](state, payload) {
    if (!payload) return;
    Vue.set(state, "devices", payload);
  },
  [RESET_DEVICES](state, payload) {
    if (!payload) return;
    Vue.set(state, "devices", []);
  },
  [SET_LIVE_DATA](state, payload) {
    if (!payload) return;
    const { deviceId, data } = payload;
    Vue.set(state.consumption["live"][String(deviceId)], "liveValues", data);
  },
  [ADD_LIVE_DATA](state, payload) {
    // when a device reports time, we add it to other devices, with their latest value and the time of incoming data
    // to align the chart
    if (!payload || !state.consumption.hasOwnProperty("live")) return;
    const { deviceId, value, scale } = payload;
    let watts = value;
    if (scale === "kilo_watt_hour") {
      watts = value * 1000;
    }
    const currTime = moment().valueOf();
    let currData = [...state.consumption["live"][String(deviceId)].liveValues];
    if (currData.length === 0 && graphInitalValue) {
      currData.push([currTime - 15000, 0]);
      graphInitalValue = false;
    }
    currData.push([currTime, watts]);

    Vue.set(
      state.consumption["live"][String(deviceId)],
      "liveValues",
      currData
    );
    Object.keys(state.consumption["live"]).forEach(function(key, index) {
      if (String(key) !== String(deviceId)) {
        const values = [...state.consumption["live"][key].liveValues];
        const lastValue =
          values[values.length - 1] !== undefined
            ? values[values.length - 1][1]
            : 0;
        if (lastValue !== 0) {
          let updatedValues = [...values, [currTime, lastValue]];
          Vue.set(
            state.consumption["live"][String(key)],
            "liveValues",
            updatedValues
          );
        }
      }
    });
  },
  [SET_INITIAL_CONSUMPTION_OBJECT](state, payload) {
    if (!payload) return;
    Vue.set(state, "consumption", payload);
  },
  [SET_CONNECTION](state, payload) {
    if (!payload) return;
    Vue.set(state, "socketConnection", payload);
  },
  [SET_LATEST_LIVE_DATA_VALUE](state, payload) {
    if (!payload) return;
    Vue.set(state, "latestLiveDataValue", payload.value);
  },
  [CLEAR_ENERGY_DATA](state, payload) {
    if (!payload) return;
    const { mode, deviceId, key = "values" } = payload;
    Vue.set(state.consumption[mode][String(deviceId)], key, []);
  }
};
