import * as nmaService from "@/services/nma.service";
import {
  ADD_ITEM,
  REMOVE_ITEM,
  SET_ITEMS,
  UPDATE_ITEM_VALUE
} from "@/store/modules/items/mutationTypes";

export default {
  async fetchItems(context, payload) {
    try {
      const {
        data: { items }
      } = await nmaService.items.list(payload);
      context.commit(SET_ITEMS, items);
    } catch (err) {
      console.log(err); // eslint-disable-line
    }
  },
  async updateItemValueAsync({ dispatch }, { _id, deviceId, value, hub }) {
    // TODO: change item value by action for websockets
    try {
      dispatch("ui/startFetching", _id, { root: true });
      // eslint-disable-next-line
      console.log("# ---> updateItemValueAsync <--- #", { _id, value, hub }); // eslint-disable-line
      dispatch("devices/startLoading", deviceId, { root: true });
      return await nmaService.item.valueSet(_id, value, hub);
    } catch (error) {
      console.log("# ---> updateItemValueAsync <--- #", error); // eslint-disable-line
      dispatch("devices/stopLoading", deviceId, { root: true });
      dispatch(
        "ui/emitError",
        {
          error: error,
          meta: "updateItemValueAsync"
        },
        {
          root: true
        }
      );
      dispatch("ui/stopFetching", _id, { root: true });
    }
  },
  updateItems({ dispatch, commit }, { action, data }) {
    const { _id, deviceId, value } = data;
    try {
      switch (action) {
        case "updated":
          commit(UPDATE_ITEM_VALUE, { _id, value });
          dispatch("energy/setLatestLiveDataValue", data, { root: true });
          dispatch("devices/stopLoading", deviceId, { root: true });
          break;
        case "added":
          commit(ADD_ITEM, data);
          console.log("# ---> Item Added <--- #", {_id, value}); // eslint-disable-line
          break;
        case "removed":
          dispatch(
            "favorites/updateFavorite",
            { action: "removed", data: { items: [_id] } },
            { root: true }
          );
          commit(REMOVE_ITEM, _id);
          console.log("# ---> Item Removed <--- #", {_id, value}); // eslint-disable-line
          break;
      }
    } catch (error) {
      console.log("# ---> updateItemValue <--- #", error); // eslint-disable-line
      dispatch(
        "ui/emitError",
        {
          error: error,
          meta: "updateItemValueAsync"
        },
        {
          root: true
        }
      );
    } finally {
      dispatch("ui/stopFetching", _id, { root: true });
    }
  },
  async setFavoritePosition({ dispatch, rootGetters }, payload) {
    try {
      const { id, percentage } = payload;
      const device = rootGetters["devices/getDeviceOptionsById"](id);

      // TODO
      dispatch(
        "devices/setBigShadeFavoritePosition",
        { value: percentage },
        { root: true }
      );

      /**
       * step 1: shutter_command: Set to "learn_favorite"
       * step 2: set dimmer value
       * step 3: shutter_command: Set to "store"
       */
      // shutter_command: Set to "learn_favorite"
      const shutter = device.items.find(i => i.name === "shutter_command");
      let params = { ...shutter, ...{ value: "learn_favorite" } };
      await dispatch("updateItemValueAsync", params);

      // set dimmer value
      const dimmer = device.items.find(i => i.name === "dimmer");
      params = { ...dimmer, ...{ value: percentage } };
      await dispatch("updateItemValueAsync", params);

      // shutter_command: Set to "store"
      params = { ...shutter, ...{ value: "store" } };
      await dispatch("updateItemValueAsync", params);
    } catch (error) {
      console.log("# ---> setFavoritePosition <--- #", error);  // eslint-disable-line
      dispatch(
        "ui/emitError",
        {
          error: error,
          meta: "updateItemValueAsync"
        },
        {
          root: true
        }
      );
    } finally {
      dispatch("ui/stopFetching", null, { root: true });
    }
  }
};
