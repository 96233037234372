<template>
  <MainPage>
    <GridLayout height="100%" rows="auto,auto,*" columns="*">
      <StackLayout row="0" col="0">
        <IBtnHiBtn
          icon1Src="icons/Controls/ic_controls_arrow-left_56.svg"
          @iconTap1="navigateBack"
          :text="translations.textTitle"
        />
      </StackLayout>
      <StackLayout row="1" col="0">
        <Separator size="xl" />
        <Label
          class="font-txt-16-par txt"
          :text="translations.subTitle"
          textWrap
        />
      </StackLayout>
      <StackLayout row="2" col="0">
        <Separator size="xl" />
        <StackLayout v-for="item in tileTypes" :key="item.type">
          <IconHTxtiBtn
            alignText="left"
            headerSize="h3"
            :text="$t(item.text)"
            :icon1Src="item.icon"
            icon2Src="icons/Controls/ic_controls_arrow-right_56.svg"
            @tap="handleSelectTileType(item.type)"
          />
          <Separator size="xl" />
        </StackLayout>
      </StackLayout>
    </GridLayout>
  </MainPage>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

const { mapGetters: mapCategoryGetters } = createNamespacedHelpers(
  "categories"
);
export default {
  name: "IconSetSelection",
  props: {
    category: {
      type: String,
      default: ""
    },
    subcategory: {
      type: String,
      default: ""
    },
    currentIcon: {
      type: String,
      default: "icons/Devices/ezlo-ic_tabbar_device-hub_56.svg"
    },
    name: {
      type: String,
      default: ""
    },
    tileType: {
      type: String,
      default: "device"
    }
  },
  data() {
    return {
      tileTypes: [
        {
          text: "iconSets.v2",
          icon: "icons/Tabbar/ic_tabbar_device-hub_56.svg",
          type: "v2"
        },
        {
          text: "iconSets.v1",
          icon: "icons/UI7/camera_default.png",
          type: "v1"
        },
        {
          text: "iconSets.custom",
          icon: "icons/Controls/ic_controls_favourite-empty_56.svg",
          type: "custom"
        }
        // {
        //   text: "core.tileTypesDialog.textServiceWidget",
        //   icon: "icons/Tabbar/ic_tabbar_services_56.svg",
        //   type: "widget"
        // },
        // for freemium
        // {
        //   text: "core.tileTypesDialog.textURL",
        //   icon: "icons/Controls/ic_controls_pair_56.svg",
        //   type: "url"
        // },
        // {
        //   text: "core.tileTypesDialog.textDashboards",
        //   icon: "icons/Actions/ic_actions_calendar-month_56.svg",
        //   type: "dashboard"
        // }
        // {
        //   text: "core.tileTypesDialog.textMode",
        //   icon: "icons/Tabbar/ic_tabbar_dashboard_56.svg",
        //   type: "mode"
        // }
      ]
    };
  },
  computed: {
    ...mapCategoryGetters(["getAllIcons"]),
    translations() {
      return this.$t("iconSets"); // eslint-disable-line
    }
  },
  methods: {
    handleSelectTileType(tileType) {
      switch (tileType) {
        case "v1":
        case "v2":
          this.navigateTo("IconSetsList", {
            props: {
              currentIcon: this.currentIcon,
              version: tileType
            }
          });
          break;
        case "custom":
          this.navigateTo("CustomIcons", {
            props: {
              currentIcon: this.currentIcon
            }
          });
          break;
      }
    }
  }
};
</script>
<style lang="scss">
@import "~/assets/styles/variables.scss";

.txt {
  padding: $xl;
}
</style>
