export default {
  offline: state => state.connectivity.offline,
  isFetching: state => payload =>
    state.isFetching.findIndex(id => id === payload) !== -1,
  error: state => state.error,
  hasError: state => {
    console.dir(state.error); // eslint-disable-line
    return state.error && state.error.error;
  },
  getSnackbar: state => state.snackbar,
  getSnackbarText: (state, getters) =>
    getters.getSnackbar && getters.getSnackbar.text,
  getSnackbarType: (state, getters) =>
    getters.getSnackbar && getters.getSnackbar.type,
  isSnackbarShowing: (state, getters) => {
    return !!getters.getSnackbarText;
  },
  isPageLoading: state => state.isPageLoading
};
