<template>
  <GridLayout rows="*, auto" columns="*" class="st-bg-image-overview">
    <ScrollView
      row="0"
      iosOverflowSafeArea="false"
      class="st-bg-image-overview__scroll"
    >
      <StackLayout class="st-bg-image-overview__main">
        <StackLayout
          class="st-bg-image-overview__actions"
          @tap="handleAddCustomImage"
        >
          <IconHTxtiBtn
            class="st-bg-image-overview__primary"
            icon2Src="icons/Controls/ic_controls_plus_primary_56.svg"
            headerSize="h3"
            alignText="left"
            :text="texts.textAddCustomImg"
          />
          <Separator size="xl" />
        </StackLayout>

        <StackLayout
          ref="images"
          class="st-bg-image-overview__presets"
          v-if="!!imagePresets.length"
        >
          <VxpLabel
            class="st-bg-image-overview__presets-title font-h2-24"
            :text="texts.textBackgroundImages"
            :textWrap="true"
          />

          <StackLayout
            class="st-bg-image-overview__image preset-picture"
            v-for="pict in imagePresets"
            :key="pict.id"
            @tap="handleSelectBgImage(pict)"
          >
            <GridLayout class="preset-picture__img" rows="*" columns="*">
              <StackLayout
                row="0"
                col="0"
                verticalAlignment="center"
                horizontalAlignment="center"
              >
                <Spinner v-if="!isWeb" />
              </StackLayout>
              <ImageCacheIt
                row="0"
                col="0"
                class="preset-picture__image"
                stretch="aspectFill"
                :src="pict.src"
              />
              <StackLayout
                row="0"
                col="0"
                height="100%"
                verticalAlignment="center"
                v-if="pict.src === selectedImageSrc"
                class="preset-picture--selected"
              >
                <SvgIcon
                  class="preset-picture__image__selected"
                  src="icons/Controls/ic_controls_check_white_56.svg"
                  :isTapable="false"
                />
              </StackLayout>
            </GridLayout>
          </StackLayout>
        </StackLayout>
      </StackLayout>
    </ScrollView>
  </GridLayout>
</template>
<script>
import { helpers } from "ezlo-core-ui";
import { openNativeGetImgDialog } from "@/helpers/openNativeGetImgDialog";
import { selectFileByCamera } from "@/helpers/selectFileByCamera";
import { uuidMock } from "@/helpers/utils";
import platform from "vuexp/src/platform";
import FilePicker from "@/helpers/filePicker";
const imageSourceModule = helpers.core.imageSourceModule;
const { VUE_APP_MODE } = process.env;

const filePicker = new FilePicker();

export default {
  name: "BackgroundImageOverview",
  props: {
    imagePresets: {
      type: Array,
      default: () => []
    },
    selectedImageSrc: {
      type: String,
      default: ""
    },
    texts: {
      type: Object,
      default: () => ({
        textAddCustomImg: "Custom handle",
        textPresetsTitle: "Block title",
        textBtn: "Button",
        textCameraDialog: {
          textFirst: "Action 1",
          textSecond: "Action 2",
          textCancel: "Back"
        }
      })
    }
  },
  computed: {
    isWeb() {
      return platform.platform === "web";
    }
  },
  methods: {
    getImageData({ file }) {
      const img = imageSourceModule.ImageSource.fromFileSync(
        file.filename || file.name
      );
      file.data = img;
      // example of path "/storage/emulated/0/DCIM/IMG_2020410_8291.jpg"

      const newImg = {
        id: uuidMock(),
        src: file.filename,
        selected: false,
        ...file
      };
      this.$emit("customImg", newImg);
    },
    getFileData(file) {
      const newImg = {
        file,
        id: uuidMock(),
        src: file.name,
        selected: false
      };
      this.$emit("customImg", newImg);
    },
    async handleAddCustomImage() {
      if (VUE_APP_MODE === "web") {
        selectFileByCamera(
          {
            pickerType: "media",
            multipleSelection: false
          },
          this.getFileData
        );
      } else {
        const resp = await openNativeGetImgDialog(this.texts.textCameraDialog);
        resp && selectFileByCamera(resp, this.getImageData);
      }
    },
    handleSelectBgImage(item) {
      this.$emit("selectImg", item);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~/assets/styles/base.scss";

.preset-picture {
  width: 100%;
  height: unit(215);
  min-height: $image-height;
  margin-bottom: $margin-xl;
  &__image {
    border-radius: $corner-l;
    border-color: transparent;
    object-fit: fill;
    min-height: -webkit-fill-available;
    min-height: -moz-available;
    &__selected {
      height: 100%;
      align-self: center;
    }
  }
  &__img {
    height: 100%;
  }
  &--selected {
    & .preset-picture__image {
      border-color: $color-primary;
    }
    background-color: $color-black-045;
    border-radius: $corner-l;
  }
}
.st-bg-image-overview {
  color: $color-black;
  height: 100%;
  &__actions {
    margin-bottom: $margin-xl;
  }
  &__presets-title {
    margin-bottom: $margin-xl;
  }
  &__btn {
    padding-bottom: $padding-xl;
  }
  &__primary {
    /deep/ & * {
      color: $color-primary;
    }
  }

  &__presets {
    max-height: $web-min-height;
    padding-left: $padding-xl;
    padding-right: $padding-xl;
  }
  &__btn {
    padding-left: $padding-xl;
    padding-right: $padding-xl;
  }
}
</style>
