<template>
  <GridLayout class="wrap" rows="*" columns="*">
    <ImageCacheIt
      row="0"
      col="0"
      v-if="
        designProperties &&
          designProperties.use_background_image &&
          !hasTemplate
      "
      :src="
        designProperties.background_image || designProperties.backgroundImage
      "
      stretch="aspectFill"
    />
    <StackLayout
      row="0"
      col="0"
      class="tab-landscape"
      :backgroundColor="!hasTemplate ? getBackgroundColor : 'white'"
    >
      <StackLayout v-if="hasTemplate" @tap="handleSeletTemplate">
        <IconHTxtiBtn
          icon2Src="icons/Controls/ic_controls_arrow-right_56.svg"
          headerSize="h3"
          alignText="left"
          :text="text"
        />
        <Separator size="xl" />
      </StackLayout>

      <TemplateRenderer
        v-if="!hasTemplate"
        :template="template"
        @tileTap="handleTapTile"
        @deleteTile="handleDeleteTile"
      />
    </StackLayout>
  </GridLayout>
</template>
<script>
import { isEmpty } from "@/helpers/utils";
import { colorHexA } from "@/helpers/utils";

const { VUE_APP_MODE } = process.env;

export default {
  name: "TabLandscape",
  props: {
    template: {
      type: Object,
      default: () => {}
    },
    backgroundImage: {
      type: String,
      default: ""
    },
    text: {
      type: String,
      default: "Button"
    },
    designProperties: {
      type: Object
    },
    backgroundColor: {
      type: String,
      default: "rgba(0, 0, 0, 0.25)"
    }
  },
  computed: {
    hasTemplate() {
      return isEmpty(this.template);
    },
    getBackgroundColor() {
      const color = this.hasBackgroundImage
        ? this.backgroundColor //"transparent"
        : this.designProperties
        ? this.designProperties.backgroundColor ||
          this.designProperties.background_color
        : null;
      return VUE_APP_MODE === "web" ? colorHexA(color) : color;
    },
    hasBackgroundImage() {
      return (
        this.designProperties &&
        (this.designProperties.use_background_image ||
          this.designProperties.useBackgroundImage) &&
        this.designProperties.background_image
      );
    }
  },
  methods: {
    handleTapTile(index) {
      this.$root.$emit("_tileTap", { orientation: "landscape", index });
    },
    handleDeleteTile(index) {
      this.$root.$emit("_deleteTile", { orientation: "landscape", index });
    },
    handleSeletTemplate() {
      this.$root.$emit("_onTapSelectTemplate", { orientation: "landscape" });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~/assets/styles/variables.scss";
.wrap {
  height: 100%;
}
.tab-landscape {
  padding: $padding-l $padding-xl $padding-l $padding-xl;
}
</style>
