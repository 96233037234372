export const SET_AUTH_STATE = "SET_AUTH_STATE";
export const SET_AUTH_RESET_PASSWORD = "SET_AUTH_RESET_PASSWORD";
export const SET_AUTH_USER_EMAIL = "SET_AUTH_USER_EMAIL";
export const SET_AUTH_USER_GET_INFO = "SET_AUTH_USER_GET_INFO";
export const SET_AUTH_USER_UPDATE_INFO = "SET_AUTH_USER_UPDATE_INFO";
export const SET_AUTH_LOGOUT = "SET_AUTH_LOGOUT";
export const SET_AUTH_SIGNUP_LOCATION_NAME = "SET_AUTH_SIGNUP_LOCATION_NAME";
export const SET_AUTH_RESET_STATE = "SET_AUTH_RESET_STATE";
export const FETCHING_AUTH_SHARE_ACTIVITY = "FETCHING_AUTH_SHARE_ACTIVITY";
export const FETCHING_AUTH_SHARE_INACTIVITY = "FETCHING_AUTH_SHARE_INACTIVITY";
export const SET_AUTH_USER_AVATAR = "SET_AUTH_USER_AVATAR";
export const FETCHING_AUTH_AVATAR = "FETCHING_AUTH_AVATAR";
export const SET_WEB_VIEW_MODE = "SET_WEB_VIEW_MODE";
export const SET_CONTROLLER_ID = "SET_CONTROLLER_ID";
export const SET_UPDATE_TEMPLATE = "SET_UPDATE_TEMPLATE";
export const SET_PATH = "SET_PATH";
export const SET_DASHBOARD_ID = "SET_DASHBOARD_ID";
export const SET_EZLO_PROTECT = "SET_EZLO_PROTECT";
