export default {
  hasHubs: state => state.hubs.length > 0,
  getHubs: state => state.hubs,
  isFetchHubs: (state, getters, rootState, rootGetters) => {
    return rootGetters["ui/isFetching"]("fetchHubsAsync");
  },
  getHubByIndex: (state, getters) => index => {
    const hubs = getters.getHubs;
    if (!hubs || !hubs.length || index < 0 || index >= hubs.length) {
      return null;
    }
    return hubs[index];
  },
  getHub: state => hubSerial =>
    state.hubs.find(hub => hub.hubSerial === hubSerial),
  getHubUUIDs: state => state.hubUUIDs,
  getConnectionStatuses: state => state.connectionStatuses,
  getRelayToken: state => PK_Device => {
    return state.relayTokens.find(item => item.PK_Device === PK_Device);
  },
  getHubUUIDById: (state, getters) => id =>
    getters.getHubUUIDs.find(item => item.id === id).uuid
};
