import WSPool from "./pool";
import WSEvents from "./events";
import isString from "lodash/isString";
import isObject from "lodash/isObject";
import merge from "lodash/merge";
import pako from "pako";
import WebSocket from "./WebSocket";

var WSStatus = {
  CONNECTING: 0,
  OPEN: 1,
  CLOSING: 2,
  CLOSED: 3
};

class WebSocketConnection {
  constructor() {
    this.ws = null;
    this.reconnectCount = 0;
    this.config = {
      reconnectTimeout: 2000,
      reconnectCount: 7,
      poolSize: 100,
      poolTimeout: 1000 // life time packet
    };
    this.WSEvents = new WSEvents();
    this.WSPool = new WSPool(this.config.poolSize);
  }
  start(config) {
    if (config && config.url && !this.isOnline()) {
      merge(this.config, config);
      return new Promise((resolve, reject) => {
        this.connect(resolve, reject);
      });
    } else {
      return Promise.reject();
    }
  }
  close() {
    return this.ws && this.isOnline()
      ? new Promise(resolve => {
          this.ws.onclose = () => {
            resolve();
          };
          this.ws.close();
        })
      : Promise.resolve();
  }
  connect(resolve, reject) {
    this.ws = new WebSocket(
      this.config.url,
      this.config.headers
        ? [
            "x-app#" + this.config.headers["X-App"],
            "x-access-token#" + this.config.headers["Authorization"]
          ]
        : []
    );
    this.ws.binaryType = "arraybuffer";

    this.ws.onopen = () => {
      this.reconnectTimeoutHandler = null;
      this.reconnectCount = 0;
      this.ws.onmessage = this.onMessage.bind(this);
      // WSPool.init(this.config.poolSize);
      resolve();
    };

    this.ws.onclose = () => {
      if (
        // this.lostConnection &&
        this.reconnectCount < this.config.reconnectCount
      ) {
        this.reconnectCount++;
        this.reconnectTimeoutHandler = setTimeout(() => {
          this.connect(resolve, reject);
        }, this.config.reconnectTimeout);
      } else {
        reject();
      }
    };

    this.ws.onerror = () => {
      this.lostConnection = true;
    };
    // this.ws.open();
  }

  isOnline() {
    if (this.ws) {
      switch (this.ws.readyState) {
        case WSStatus.OPEN:
          return true;
        case WSStatus.CONNECTING:
        case WSStatus.CLOSED:
        case WSStatus.CLOSING:
          return false;
      }
    }

    return false;
  }

  onMessage(response) {
    try {
      var { data } = response;
      this.resolve(JSON.parse(data));
    } catch (e) {
      this.reject({
        error: e
      });
    }
  }

  resolve(response) {
    if (this.WSPool.hasPacket(response.id)) {
      this.WSPool.resolvePacket(response);
    } else {
      this.WSEvents.resolveEvent(response);
    }
  }

  send(method, params) {
    return new Promise((resolve, reject) => {
      var packet = this.WSPool.createPacket();
      packet.create(method, params, resolve, reject);

      if (!isString(method) || !isObject(params)) {
        packet.reject("Invalid method");
      }

      const sendMessage = () => {
        var data = JSON.stringify(packet.getMessage());
        packet.sourceOut = data;
        if (this.isBinary) {
          data = pako.deflate(data, { gzip: true }).buffer;
        }
        this.ws.send(data);
      };

      if (this.isOnline()) {
        sendMessage();
      } else {
        packet.reject("Failed connection");
      }
      return;

      // if (!this.config.onReconnect) {

      // }

      // this.config
      //   .onReconnect()
      // .then(sendMessage)
      // .catch(error => {
      //   packet.reject(error);
      // });
    });
  }

  subscribe(name, cb) {
    return this.WSEvents.subscribe(name, cb);
  }

  unsubscribe(name, cb) {
    return this.WSEvents.unsubscribe(name, cb);
  }
}

export default WebSocketConnection;
