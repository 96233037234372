<template>
  <MainPage>
    <GridLayout
      height="100%"
      rows="auto, *, auto"
      columns="*"
      class="custom-icons"
    >
      <StackLayout row="0" col="0">
        <GridLayout
          row="0"
          col="0"
          rows="64, auto"
          columns="*"
          class="custom-icons__navigation"
        >
          <NavigationHeader
            row="0"
            col="0"
            icon1Src="icons/Controls/ic_controls_arrow-left_56.svg"
            textSize="h3"
            :text="translations.title"
            @iconTap1="goBack"
          />
          <StackLayout row="1" col="0" class="st-bg-image-overview__actions">
            <Separator size="xl" />
            <VxpLabel
              class="font-txt-16-par custom-icons__description"
              :text="translations.subTitle"
              :textWrap="true"
            />
            <Separator size="xl" />
            <HiBtn
              class="custom-icons__add-button"
              src="icons/Controls/ic_controls_plus_primary_56.svg"
              headerSize="h3"
              :text="translations.uploadCustomIcon"
              @tap="uploadCustomIcon"
            />
            <Separator size="xl" />
          </StackLayout>
        </GridLayout>
      </StackLayout>
      <!-- uploaded icons -->
      <ScrollView row="1" col="0">
        <StackLayout
          v-if="getCustomIcons.length > 0"
          class="custom-icons__wrap"
        >
          <Label
            class="font-h2-24 custom-icons__sub"
            :text="translations.uploadedIcons"
          />
          <WrapLayout>
            <StackLayout v-for="(icon, idx) in iconList" :key="idx">
              <GridLayout rows="*" columns="auto">
                <TileItem
                  row="0"
                  col="0"
                  height="103"
                  width="103"
                  class="custom-icons__tile"
                  :src="icon"
                  title=""
                  :backgroundColorHex="
                    selectedTile !== idx ? '#ffffff' : '#3e46ae'
                  "
                  :colorHex="selectedTile !== idx ? '#000000' : '#ffffff'"
                  forceSvg
                  @tap="selectTile(idx)"
                />
                <SvgIcon
                  row="0"
                  col="0"
                  class="custom-icons__delete-icon"
                  src="icons/Controls/ic_controls_input-erase_red_24.svg"
                  :height="24"
                  :width="24"
                  isTapable
                  @tap="deleteTile(idx)"
                />
              </GridLayout>
            </StackLayout>
          </WrapLayout>
        </StackLayout>
      </ScrollView>
      <StackLayout row="2" col="0" class="custom-icons__btn">
        <ButtonFilled
          v-if="iconList.length > 0"
          :disabled="getDoneBtnStatus"
          :text="translations.btn"
          @tap="handleDone"
        />
      </StackLayout>
    </GridLayout>
  </MainPage>
</template>

<script>
// import DashboardListOverview from "@/components/dashboard/DashboardListOverview";
import { createNamespacedHelpers } from "vuex";
import { uuidMock } from "@/helpers/utils";
import { selectFileByCamera } from "@/helpers/selectFileByCamera";
import { openNativeGetImgDialog } from "@/helpers/openNativeGetImgDialog";
const { VUE_APP_MODE } = process.env;
const {
  mapGetters: mapUiGetters,
  mapActions: mapUiActions
} = createNamespacedHelpers("ui");
const {
  mapGetters: mapAuthGetters,
  mapActions: mapAuthActions
} = createNamespacedHelpers("auth");

const {
  mapActions: mapDashboardActions,
  mapGetters: mapDashboardGetters
} = createNamespacedHelpers("dashboards");

export default {
  name: "CustomIcons",
  components: {
    // DashboardListOverview
  },
  props: {
    currentIcon: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      selectedTile: -1
    };
  },
  computed: {
    ...mapUiGetters(["isSnackbarShowing"]),
    ...mapDashboardGetters(["getCustomIcons"]),
    translations() {
      return this.$t("customIcons");
    },
    iconList() {
      return this.getCustomIcons || [];
    },
    getDoneBtnStatus() {
      return this.selectedTile === -1;
    },
    getCurrentIconIndex() {
      return this.iconList.findIndex(icon => icon === this.currentIcon) || -1;
    }
  },
  mounted() {
    this.selectedTile = this.getCurrentIconIndex;
  },
  watch: {
    currentIcon(newValue) {
      this.selectedTile = this.getCurrentIconIndex;
    }
  },
  methods: {
    ...mapUiActions(["emitError", "saveSnackbarErrorText", "saveSnackbarText"]),
    ...mapDashboardActions([
      "selectDashboardActiveBgImage",
      "addDashboardCustomBgImg",
      "saveDashboardSelectedBgImage",
      "uploadIcon",
      "deleteCustomIcon",
      "saveCustomIcons",
      "fetchCustomIcons"
    ]),
    goBack() {
      // this.$destroy();
      this.selectedTile = this.getCurrentIconIndex;
      this.navigateBack();
    },
    handleDone() {
      this.$root.$emit("_changeIcon", {
        icon: this.iconList[this.selectedTile]
      });
      this.goBack();
    },
    selectTile(index) {
      this.selectedTile = index;
    },
    deleteTile(index) {
      this.deleteCustomIcon(index);
    },
    async sendImageFile(image) {
      // upload image
      const ok = await this.uploadIcon(image);
      if (ok) {
        await this.fetchCustomIcons();
        this.saveSnackbarText(this.translations.IconAdded);
        this.selectedTile = this.iconList.length - 1;
      }
    },
    getImageFile(file) {
      if (file.size > 2 * 1000 * 1024) {
        this.saveSnackbarErrorText(this.translations.errorImageSizeToBig); //eslint-disable-line
        throw new Error(this.translations.errorImageSizeToBig);
      }
      const newImg = {
        file,
        id: uuidMock(),
        src: file.name,
        selected: false
      };
      this.sendImageFile(newImg);
    },
    async uploadCustomIcon() {
      let resp = {
        pickerType: "image",
        multipleSelection: false
      };
      if (VUE_APP_MODE !== "web") {
        resp = await openNativeGetImgDialog(this.texts.textCameraDialog);
      }
      selectFileByCamera(resp, this.getImageFile);
    }
  }
};
</script>

<style scoped lang="scss">
@import "~/assets/styles/variables.scss";

.custom-icons {
  height: 100%;
  &__navigation {
    overflow-y: initial;
    min-height: $xxxxll;
  }
  &__add-button {
    color: $color-primary;
  }
  &__list {
    overflow-y: auto;
  }
  &__description {
    margin: 0 $margin-xl;
  }
  &__wrap {
    padding-left: $lmx;
    padding-right: $lmx;
  }
  &__sub {
    padding: $padding-xl 0;
  }
  &__tile {
    height: $tile-height;
    width: $tile-height;
    margin: $xs;
    grid-column: 1;
    grid-row: 1;
  }
  &__delete-icon {
    align-self: flex-start;
    justify-self: end;
    vertical-align: top;
    height: $xl;
    width: $xl;
    grid-column: 1;
    grid-row: 1;
  }
  &__btn {
    padding-top: $padding-l;
    padding-bottom: $padding-l;
    padding-left: $padding-xl;
    padding-right: $padding-xl;
  }
}
</style>
