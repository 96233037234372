export default (data, servers) => {
  const isEzlo = new RegExp(/^[7,8,9]00/);
  const initialIcon = "icons/Tabbar/ic_tabbar_device-hub_56.svg";
  const tempVeraHubName = `Vera ${data.PK_Device}`;
  const tempEzloHubName = `Ezlo ${data.PK_Device}`;
  const getName = isEzlo.test(data.PK_Device)
    ? tempEzloHubName
    : tempVeraHubName;

  return {
    id: data.PK_Device,
    icon: initialIcon,
    nickName: getName,
    hubSerial: data.PK_Device,
    websocketUrl: data.Server_Relay,
    isOnline: data.NMAControllerStatus === 1,
    isEzlo: isEzlo.test(data.PK_Device) || data.LinuxFirmware === 1,
    data,
    servers
  };
};
