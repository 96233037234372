export const RESET_STATE = "RESET_STATE";
export const SET_LOADING_STATE = "SET_LOADING_STATE";
export const UNSET_LOADING_STATE = "UNSET_LOADING_STATE";
export const SET_ROOMS_STATE = "SET_ROOMS_STATE";
export const SET_SELECTED_ROOM = "SET_SELECTED_ROOM";
export const SET_ROOM = "SET_ROOM";
export const NEW_ROOM = "NEW_ROOM";
export const ADD_ROOM = "ADD_ROOM";
export const EDIT_ROOM = "EDIT_ROOM";
export const SAVE_ROOM = "SAVE_ROOM";
export const DELETE_ROOM = "DELETE_ROOM";
export const SAVE_EDIT_ROOM = "SAVE_EDIT_ROOM";
export const SET_VALUE = "SET_VALUE";
