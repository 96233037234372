<template>
  <MainPage :class="{ orientation: lockOrientation }">
    <GridLayout height="100%" rows="auto, *" class="energy">
      <ScrollView row="1" iosOverflowSafeArea="false" height="100%">
        <StackLayout>
          <EnergyOverview
            :energy="getEnergy"
            @changeMode="changeMode"
            :activeIndex="activeMode"
            :enableFullscreenGraph="enableFullscreenGraph"
            :modes="getModes"
            @tap="onTap"
            @handleBack="handleBack"
            @noEnergyData="noEnergyData"
          />
        </StackLayout>
      </ScrollView>
    </GridLayout>
  </MainPage>
</template>
<script>
import { createNamespacedHelpers } from "vuex";
import EnergyOverview from "./EnergyOverview";
const { mapActions: mapUiActions } = createNamespacedHelpers("ui");

const {
  mapGetters: mapEnergyGetters,
  mapActions: mapEnergyActions
} = createNamespacedHelpers("energy");
const { mapGetters: mapAuthGetters } = createNamespacedHelpers("auth");
const { mapGetters: mapDeviceGetters } = createNamespacedHelpers("devices");
const { mapGetters: mapItemGetters } = createNamespacedHelpers("items");

export default {
  name: "Energy",
  components: { EnergyOverview },
  props: {
    energy: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      lockOrientation: false,
      enableFullscreenGraph: false
    };
  },
  created() {
    // if (!this.isWebView) {
    // console.log("NOT WEBVIEW, GETTING ENERGY DATA...")//eslint-disable-line
    this.getAllEnergyData();
    // }
  },
  computed: {
    ...mapAuthGetters(["isWebView"]),
    ...mapEnergyGetters([
      "getEnergy",
      "getActiveMode",
      "getModes",
      "getEnergyDevices"
    ]),
    ...mapDeviceGetters(["getDevices"]),
    ...mapItemGetters(["getItems"]),

    translations() {
      return {
        title: this.$t("energy.title")
      };
    },
    activeMode() {
      return this.getActiveMode;
    }
  },
  methods: {
    ...mapUiActions(["emitError"]),
    ...mapEnergyActions([
      "getAllEnergyData",
      "setActiveMode",
      "startCloudWebsocketConnection"
    ]),
    handleBack() {
      this.navigateTo("terminate-app");
    },
    onSelect(index, item) {
      this.$emit("selectedIndexChanged", { index, scene: item });
    },
    onTap(deviceId) {
      // this.navigateTo("DeviceEnergyDetails", {
      //   props: {
      //     deviceId,
      //     timeSpan: {
      //       startDate: new Date(),
      //       endDate: new Date()
      //     },
      //     usageType: this.getModes[this.activeMode] // day, week, month
      //   }
      // });
    },
    changeMode(index) {
      //TODO add actions for each type live , day, week and mont
      this.setActiveMode(index);
    },
    noEnergyData() {
      this.emitError({
        type: "errorDialogNoEnergyData",
        meta: "noEnergyData"
      });
    },
    checkOrientation() {
      if (this.getModes[this.activeMode] !== "live") {
        this.lockOrientation = true;
      } else {
        this.lockOrientation = false;
        if (this.$screen && this.$screen.landscape && this.isMobileBrowser) {
          this.enableFullscreenGraph = true;
        }
      }
    }
  },
  watch: {
    // TODO in case we may need spesific logic for landscape mode
    "$screen.landscape"(current) {
      if (
        this.getModes[this.activeMode] === "live" &&
        current &&
        this.isMobileBrowser
      ) {
        this.enableFullscreenGraph = true;
      } else {
        this.enableFullscreenGraph = false;
      }
      this.checkOrientation();
    },
    isMobileBrowser() {
      this.checkOrientation();
    },
    activeMode() {
      this.checkOrientation();
    },
    getDevices(oldDevices, newDevices) {
      if (oldDevices.length !== newDevices.length) {
        this.getAllEnergyData();
      }
    },
    getItems(oldItems, newItems) {
      if (oldItems.length !== newItems.length) {
        this.getAllEnergyData();
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~/assets/styles/base.scss";

@media screen and (min-aspect-ratio: 13/9) {
  .orientation {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
    height: auto;
  }
}

.energy {
  color: $color-black;
  height: 100%;
  &__content {
    padding: 0 0 $padding-xl $padding-xl;
  }
  &__title {
    padding: 0 0 $s $padding-xl;
  }
  &__period {
    padding: 0 0 $s $padding-xl;
  }
  &__item-container {
    padding: $padding-xl;
    &__item {
      margin-bottom: $margin-s;
    }
  }
}
</style>
