<template>
  <Page actionBarHidden="true" class="development-dashboard">
    <ScrollView height="100%">
      <StackLayout height="100%">
        <GridLayout rows="*" columns="*">
          <ScrollView row="0" col="0" iosOverflowSafeArea="false">
            <StackLayout class="demo-dashboard">
              <HiBtn
                v-for="item in routesList"
                :key="item.path"
                :text="item.path"
                @tap="goto(item.name, item.options)"
                headerSize="h4"
                src="icons/Actions/ic_actions_link_56.svg"
              />
              <Label text="Fonticon examples:"></Label>
              <WrapLayout>
                <Label
                  v-for="color in ['red', 'green', 'blue', 'yellow']"
                  :key="color"
                  :color="color"
                  class="ezlo"
                  :text="'ezlo-ic_category_cameras_56' | fonticon"
                ></Label>
              </WrapLayout>
              <StackLayout>
                <Separator />
                <Label
                  padding="24"
                  :text="`Slider is on ${value} - ${fontSize}`"
                  class="roboto"
                  :fontSize="fontSize"
                />
                <Label
                  padding="24"
                  :text="`Slider is on ${value} - ${fontSize}`"
                  fontFamily="monospace"
                  :fontSize="fontSize"
                />
                <Label
                  padding="24"
                  :text="`Slider is on ${value} - ${fontSize}`"
                  fontFamily="Avenir"
                  :fontSize="fontSize"
                />
                <Label
                  padding="24"
                  :text="`Slider is on ${value} - ${fontSize}`"
                  fontFamily="serif"
                  :fontSize="fontSize"
                />
                <Separator />
                <SizeSlider
                  paddingLeft="24"
                  paddingRight="24"
                  :initialValue="value"
                  @valueChange="handleValueChange"
                >
                  <VxpLabel slot="from" class="font-txt-16-par" text="A" />
                  <VxpLabel slot="to" class="font-h2-24" text="A" />
                </SizeSlider>
                <HourSlider class="hour" :initialValue="value" />
                <Separator />
              </StackLayout>
            </StackLayout>
          </ScrollView>
        </GridLayout>
      </StackLayout>
    </ScrollView>
  </Page>
</template>

<script>
import { routerOptions } from "~/router.js";
import { createNamespacedHelpers } from "vuex";
import { templates } from "@/helpers/templateCreator";

export default {
  name: "DevelopmentPage",
  data() {
    return {
      value: 0
    };
  },
  computed: {
    routesList() {
      return routerOptions.routes;
    },
    fontSize() {
      // 16, 20, 24, 28
      return 16 + (this.value + this.value) * 2;
    }
  },
  methods: {
    goto(path, options) {
      this.navigateTo(path, options);
    },
    handleValueChange(value) {
      this.value = value;
    }
  }
};
</script>

<style scoped lang="scss">
@import "~assets/styles/variables.scss";
.development-dashboard {
  height: auto;
}
.hour {
  padding: $xl;
}
</style>
