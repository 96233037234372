import {
  SET_VALUE_BY_KEY,
  SET_ACTIVE_MODE,
  ADD_DEVICE_TO_FILTER,
  REMOVE_DEVICE_FROM_FILTER,
  RESET_FILTER,
  SET_CURRENT_GOAL,
  SELECT_DEVICE,
  CLEAR_SELECTION,
  SET_GOALS,
  ADD_GOAL,
  UPDATE_GOAL,
  SET_ENERGY_DATA,
  SET_MONITORING,
  SET_SELECTED_TIME,
  SET_DEVICES,
  SET_LIVE_DATA,
  ADD_LIVE_DATA,
  SET_INITIAL_CONSUMPTION_OBJECT,
  SET_PREVIOUS_CONSUMPTION,
  SET_AVERAGE_CONSUMPTION,
  RESET_DEVICES,
  SET_CONNECTION,
  SET_LATEST_LIVE_DATA_VALUE,
  CLEAR_ENERGY_DATA
} from "./mutationTypes";
import cloudService from "@/services/cloud.service";
import { parseHttpResponse } from "@/helpers/responseParser";
import moment from "moment";

let failedRequests = [];
let failedRequestsWithId = [];
let reconnecting = false;
let keepAliveInterval = null;

function handleFailedRequestsWithId(response, payload, call, dispatch) {
  const {
    controller_uuid,
    device_id,
    selectedDates,
    groupBy,
    mode,
    aggregation,
    id
  } = payload;
  if (
    response &&
    response.data &&
    response.data.error_text === "Service timeout"
  ) {
    if (response && response.data.id) {
      const requestId = id ? id : response.data.id;
      if (!failedRequestsWithId[requestId]) {
        failedRequestsWithId[requestId] = {
          count: 0,
          request: null
        };
      }
      if (failedRequestsWithId[requestId].count < 4) {
        const request = {
          call,
          controller_uuid,
          device_id,
          selectedDates,
          groupBy,
          mode,
          aggregation, // eslint-disable-line,
          id: requestId
        };
        failedRequestsWithId[requestId].count++;
        failedRequestsWithId[requestId].request = request;
              console.log("Request Timeout, Trying again...");//eslint-disable-line
        dispatch(call, request);
      }
    }
  } else if (
    response &&
    response.data &&
    response.data.error_text === "read ECONNRESET"
  ) {
    Object.entries(failedRequestsWithId).forEach(req => {
      failedRequests.push(req.request);
    });
    failedRequestsWithId = {};
  }
}
function handleFailedRequestsWithoutId(err, payload, call) {
  const {
    controller_uuid,
    device_id,
    selectedDates,
    groupBy,
    mode,
    aggregation,
    id
  } = payload;
  if (err && err.error === "Failed connection") {
    failedRequests.push({
      call,
      controller_uuid,
      device_id,
      selectedDates,
      groupBy,
      mode,
      aggregation // eslint-disable-line
    });
  }
}
const previousDates = ({ mode, selectedDates }) => {
  if (mode === "live") return selectedDates;
  const dates = {
    start: moment(selectedDates.start)
      .subtract(1, mode)
      .startOf(mode),
    end: moment(selectedDates.start)
      .subtract(1, mode)
      .endOf(mode)
  };
  return dates;
};
export default {
  setValueByKey({ commit }, payload) {
    commit(SET_VALUE_BY_KEY, payload);
  },
  async retryFailedRequests() {},
  async startCloudWebsocketConnection({
    dispatch,
    commit,
    getters,
    rootGetters
  }) {
    try {
      const authJwtToken = rootGetters["auth/getCloudJwtToken"];
      if (!reconnecting) {
        await cloudService.socketConnect({
          authJwtToken
          // onReconnect: async () => {
          //   reconnecting = true;
          //   await dispatch("broadcast/unsubscribeFromCloud", null, {
          //     root: true
          //   });
          //   console.log("Re-initating socket connection...");//eslint-disable-line
          //   await dispatch("startCloudWebsocketConnection", null);
          //   failedRequests.forEach(request => {
          //     debugger;
          //     dispatch(request.call, request);
          //   });
          //   failedRequests = [];
          //   reconnecting = false;
          // }
        });
        dispatch("startKeepAliveRequestInterval", null);
        commit(SET_CONNECTION, true);

        // const response = await cloudService.sendSocketMessage({ call:"abstract_list", params:{} });
        await dispatch("broadcast/subscribeToCloud", null, {
          root: true
        });
      }
    } catch (error) {
      console.log("Connection failed...");//eslint-disable-line
      commit(SET_CONNECTION, false);
      dispatch("stopKeepAliveRequestInterval", null);
    }
  },
  async fetchDeviceMetricsFromInfluxDB({}, payload) {
    const {
      controller_uuid,
      device_id,
      aggregation,
      startDate,
      endDate,
      groupBy,
      fill = "fill(null)"
    } = payload;
    // sample usage
    // const query = "SELECT * from electric_watt";
    // const query = `SELECT ${aggregation}("value") from electric_watt WHERE time >= '${startDate}' AND time <= '${endDate}' GROUP BY time(${groupBy})`;
    const query = `SELECT ${aggregation} from electric_meter_watt WHERE time >= '${startDate}' AND time <= '${endDate}' GROUP BY time(${groupBy}) ${fill}`;
    console.log("### fetchDeviceMetricsFromInfluxDB query:", { device_id, query }); // eslint-disable-line
    return await cloudService.sendSocketMessage({
      call: "metrics_query",
      params: { controller_uuid, device_id, query }
    });
  },
  async getDeviceMetrics({ dispatch, commit, getters, rootGetters }, payload) {
    const {
      controller_uuid,
      device_id,
      selectedDates,
      groupBy,
      mode,
      aggregation = "MEAN(\"value\")",//eslint-disable-line
      id,
      utc = true
    } = payload;
    try {
      const startDate = moment
        .parseZone(selectedDates.start)
        .utc(utc)
        .format();
      const endDate = moment
        .parseZone(selectedDates.end)
        .utc(utc)
        .format();

      dispatch("ui/startFetching", mode, { root: true });

      console.log("### --> before getDeviceMetrics:", { mode, selectedDates, startDate, endDate, device_id, utc })//eslint-disable-line
      const response = await dispatch("fetchDeviceMetricsFromInfluxDB", {
        controller_uuid,
        device_id,
        aggregation,
        startDate,
        endDate,
        groupBy,
        fill: "fill(none)"
      });
      console.log("response", { mode, selectedDates, startDate, endDate, device_id, response }); //eslint-disable-line
      if (response && response.data && !response.data.error_text) {
        if (id && failedRequestsWithId[id]) {
          failedRequestsWithId[id] = null;
        }
        commit(SET_ENERGY_DATA, {
          mode,
          deviceId: device_id,
          metrics: response.data.metrics,
          selectedDates
        });
        dispatch("ui/stopFetching", mode, { root: true });
      } else {
        // with id
        console.log("Metrics request failed...");//eslint-disable-line
        handleFailedRequestsWithId(
          response,
          {
            controller_uuid,
            device_id,
            selectedDates,
            groupBy,
            mode,
            aggregation,//eslint-disable-line
            id
          },
          "getDeviceMetrics",
          dispatch
        );
      }
    } catch (err) {
      // no id
      console.log(err);//eslint-disable-line
      console.log("Metrics request failed...");//eslint-disable-line
      handleFailedRequestsWithoutId(
        err,
        {
          controller_uuid,
          device_id,
          selectedDates,
          groupBy,
          mode,
          aggregation//eslint-disable-line
        },
        "getDeviceMetrics"
      );
    } finally {
      dispatch("ui/stopFetching", mode, { root: true });
    }
  },
  async fetchPreviousConsumption({ commit, dispatch }, payload) {
    const {
      controller_uuid,
      device_id,
      selectedDates,
      groupBy,
      mode,
      aggregation = 'MEAN("value")',// eslint-disable-line
      id,
      utc = true
    } = payload;
    try {
      dispatch("ui/startFetching", "fetchPreviousConsumption_" + mode, {
        root: true
      });
      const startDate = moment
        .parseZone(selectedDates.start)
        .utc(utc)
        .format();
      const endDate = moment
        .parseZone(selectedDates.end)
        .utc(utc)
        .format();

      const response = await dispatch("fetchDeviceMetricsFromInfluxDB", {
        controller_uuid,
        device_id,
        aggregation,
        startDate,
        endDate,
        groupBy,
        fill: mode === "live" ? "fill(null)" : "fill(none)"
      });
      //eslint-disable-next-line
      console.log("fetchPreviousConsumption response", {
        mode,
        selectedDates,
        startDate,
        endDate,
        device_id,
        response
      });
      if (response && response.data && !response.data.error_text) {
        if (id && failedRequestsWithId[id]) {
          failedRequestsWithId[id] = null;
        }
        commit(SET_PREVIOUS_CONSUMPTION, {
          mode,
          deviceId: device_id,
          metrics: response.data.metrics
        });
        dispatch("ui/stopFetching", "fetchPreviousConsumption_" + mode, {
          root: true
        });
      } else {
        handleFailedRequestsWithId(
          response,
          {
            controller_uuid,
            device_id,
            selectedDates,
            groupBy,
            mode,
          aggregation,//eslint-disable-line
            id
          },
          "fetchPreviousConsumption",
          dispatch
        );
      }
    } catch (error) {
      console.log(error); //eslint-disable-line
      console.log("Metrics request failed...");//eslint-disable-line
      handleFailedRequestsWithoutId(
        error,
        {
          controller_uuid,
          device_id,
          selectedDates,
          groupBy,
          mode,
        aggregation//eslint-disable-line
        },
        "fetchPreviousConsumption"
      );
    } finally {
      dispatch("ui/stopFetching", "fetchPreviousConsumption_" + mode, {
        root: true
      });
    }
  },
  async fetchAverageConsumption({ commit, dispatch }, payload) {
    const {
      controller_uuid,
      device_id,
      selectedDates,
      groupBy,
      mode,
      aggregation = "MEAN(\"value\")", // eslint-disable-line
      utc = true
    } = payload;
    try {
      dispatch("ui/startFetching", "fetchAverageConsumption_" + mode, {
        root: true
      });
      const startDate = moment
        .parseZone(selectedDates.start)
        .utc(utc)
        .format();
      const endDate = moment
        .parseZone(selectedDates.end)
        .utc(utc)
        .format();

      const response = await dispatch("fetchDeviceMetricsFromInfluxDB", {
        controller_uuid,
        device_id,
        aggregation,
        startDate,
        endDate,
        groupBy,
        fill: mode === "live" ? "fill(null)" : "fill(none)"
      });
      //eslint-disable-next-line
      console.log("fetchAverageConsumption response", {
        mode,
        selectedDates,
        startDate,
        endDate,
        device_id,
        response
      });
      if (response && response.data) {
        commit(SET_AVERAGE_CONSUMPTION, {
          mode,
          deviceId: device_id,
          metrics: response.data.metrics
        });
      }
    } catch (error) {
      console.log(error); //eslint-disable-line
    } finally {
      dispatch("ui/stopFetching", "fetchAverageConsumption_" + mode, {
        root: true
      });
    }
  },
  async setNewGoal({ dispatch, getters }) {
    const goal = getters.getInitialGoal;
    dispatch("setCurrentGoal", goal);
  },
  async setCurrentGoalById({ dispatch, getters }, payload) {
    const goal = getters.getGoalById(payload);
    if (goal) {
      dispatch("setCurrentGoal", { ...goal });
    }
  },
  async setCurrentGoal({ commit }, payload) {
    commit(SET_CURRENT_GOAL, payload);
  },
  async saveGoal({ commit, dispatch, getters, rootGetters }, payload) {
    try {
      dispatch("ui/startFetching", "saveGoal", { root: true });
      //this line (promise) will be removed.
      await new Promise(resolve => {
        setTimeout(() => {
          resolve(true);
        }, 1000);
      });
      // TODO return value
      if (getters.getGoalById(payload.id)) {
        dispatch("updateGoal", payload);
      } else {
        dispatch("addGoal", payload);
      }
      dispatch("ui/saveSnackbarText", "goals.goalSaved", {
        root: true
      });
      return true;
    } catch (error) {
      console.log(error); //eslint-disable-line
    } finally {
      dispatch("ui/stopFetching", "saveGoal", { root: true });
    }
  },
  async selectDevice({ commit }, payload) {
    await commit(SELECT_DEVICE, payload);
  },
  async clearSelection({ commit }) {
    commit(CLEAR_SELECTION);
  },
  startKeepAliveRequestInterval() {
    keepAliveInterval = setInterval(() => {
      cloudService.sendSocketMessage({
        call: "ping",
        params: { timestamp: Date.now() }
      });
    }, 30000);
  },
  stopKeepAliveRequestInterval() {
    if (keepAliveInterval) {
      keepAliveInterval.clearInterval();
    }
    keepAliveInterval = null;
  },
  setActiveMode({ commit }, payload) {
    commit(SET_ACTIVE_MODE, payload);
  },
  addDeviceToFilter({ commit }, deviceId) {
    commit(ADD_DEVICE_TO_FILTER, deviceId);
  },
  removeDeviceFromFilter({ commit }, deviceId) {
    commit(REMOVE_DEVICE_FROM_FILTER, deviceId);
  },
  resetFilter({ commit }) {
    commit(RESET_FILTER);
  },
  async fetchGoals({ commit, dispatch, getters }) {
    // TODO
    try {
      dispatch("ui/startFetching", "fetchGoals", { root: true });
      const goals = getters.getMockGoals.map(goal => {
        return {
          ...goal,
          device: {
            ...goal.device,
            ...getters.getDeviceById(goal.device.id)
          }
        };
      });

      commit(SET_GOALS, goals);
    } catch (error) {
      console.log(error); //eslint-disable-line
    } finally {
      dispatch("ui/stopFetching", "fetchGoals", { root: true });
    }
  },
  async updateGoal({ commit }, payload) {
    commit(UPDATE_GOAL, payload);
  },
  async addGoal({ commit }, payload) {
    commit(ADD_GOAL, payload);
  },
  /**
   * @param {payload} deviceId
   */
  async fetchEnergyData(
    { dispatch, commit, getters, rootState, rootGetters },
    payload
  ) {
    const devices = getters.getEnergyDevices;
    const { mode, selectedDates } = payload;

    devices.forEach(device => {
      const uuid = rootGetters["hub/getHubUUIDById"](device.controller_id);
      const groupBy = {
        live: "10m",
        day: "1h",
        week: "1d",
        month: "1d"
      };

      // consumptions
      dispatch("clearEnergyData", {
        deviceId: device.id,
        mode,
        key: "values"
      });
      dispatch("getDeviceMetrics", {
        controller_uuid: uuid || "0c720cb0-8699-11e9-832b-6d4e42a8c158",
        device_id: device.id,
        mode,
        selectedDates,
        groupBy: groupBy[mode],
        utc: ["week", "month"].includes(mode)
      });

      // previous consupmtions
      if (mode !== "live") {
        const groupByPrevious = {
          day: "1d",
          week: "1w, 3d", // 3d -> starts from the Sunday
          month: "30d"
        };
        dispatch("fetchPreviousConsumption", {
          controller_uuid: uuid || "0c720cb0-8699-11e9-832b-6d4e42a8c158",
          device_id: device.id,
          mode,
          selectedDates: previousDates({ mode, selectedDates }),
          groupBy: groupByPrevious[mode],
          utc: ["week", "month"].includes(mode)
        });
      }
    });
  },
  async fetchEnergyDataForAllModes({ dispatch, getters }) {
    try {
      const { getModes, getSelectedDates } = getters;
      getModes.forEach(mode => {
        if (mode !== "live") {
          dispatch("fetchEnergyData", {
            mode,
            selectedDates: getSelectedDates[mode]
          });
        }
      });
    } catch (error) {
      console.log("fetchEnergyDataForAllModes",error)//eslint-disable-line
    }
  },
  async setMonitoring({ commit }, payload) {
    await commit(SET_MONITORING, payload);
  },
  setSelectedDate({ commit }, payload) {
    commit(SET_SELECTED_TIME, payload);
  },
  async fetchDevices({ commit, dispatch, getters, rootState, rootGetters }) {
    let allDevices = rootGetters["items/getEnergyMonitoringEligableDevices"](
      "electric_meter_watt"
    );
    const controllerId = rootGetters["auth/getControllerId"];
    let devices = controllerId
      ? allDevices.filter(device => device.controller_id === controllerId)
      : allDevices;
    if (
      devices.length !== 0 ||
      devices.length !== getters.getEnergyDevices.length
    ) {
      devices = devices.map((device, index) => {
        return {
          ...device,
          barColor: getters.getColorByIndex(index),
          src: device.iconSrc,
          status: "on",
          rate: 0,
          selected: false,
          groupId: "1F8D7089",
          groupTitle: "Living room",
          monitoringEnabled: true,
          consumption: 0,
          rate: device.consumption == 0 ? 0 : device.consumption
        };
      });
      await commit(SET_DEVICES, devices);
      await dispatch("setInitialConsumptionsObject", null);
    }
  },
  async setInitialConsumptionsObject({ commit, getters }) {
    const { getModes, getEnergyDevices } = getters;
    let consumptionObj = {};
    getModes.forEach(mode => {
      const obj = {
        [mode]: {}
      };
      consumptionObj = { ...consumptionObj, ...obj };
    });
    getModes.forEach(mode => {
      let obj = {};
      getEnergyDevices.forEach(device => {
        obj = {
          ...obj,
          ...{
            [device.id]: {
              controllerUUID: "",
              previous: 0,
              average: 0,
              values: [],
              liveValues: []
            }
          }
        };
      });

      consumptionObj[mode] = obj;
    });
    commit(SET_INITIAL_CONSUMPTION_OBJECT, consumptionObj);
  },
  async setLiveData({ commit }, payload) {
    commit(SET_LIVE_DATA, payload);
  },
  async addLiveData({ commit }, payload) {
    commit(ADD_LIVE_DATA, payload.data);
  },
  async setLatestLiveDataValue({ commit }, payload) {
    commit(SET_LATEST_LIVE_DATA_VALUE, payload);
  },
  setInitialDates({ dispatch }) {
    dispatch("setSelectedDate", {
      type: "live",
      data: {
        start: moment()
          .startOf("hour")
          .valueOf(),
        end: moment()
          .endOf("hour")
          .valueOf()
      }
    });
    dispatch("setSelectedDate", {
      type: "day",
      data: {
        start: moment()
          .startOf("day")
          .valueOf(),
        end: moment()
          .endOf("day")
          .valueOf()
      }
    });
    dispatch("setSelectedDate", {
      type: "week",
      data: {
        start: moment()
          .startOf("week")
          .valueOf(),
        end: moment().valueOf()
      }
    });
    dispatch("setSelectedDate", {
      type: "month",
      data: {
        start: moment()
          .startOf("month")
          .valueOf(),
        end: moment().valueOf()
      }
    });
  },
  async getAllEnergyData({ dispatch, getters }) {
    try {
      dispatch("ui/startFetching", "getAllEnergyData", { root: true });
      dispatch("setInitialDates", null);
      const currentDeviceCount = getters.getEnergyDevices.length;
      await dispatch("startCloudWebsocketConnection", null);
      await dispatch("fetchDevices", null);
      dispatch("fetchGoals", null);
      if (
        getters.getEnergyDevices.length !== 0 &&
        currentDeviceCount !== getters.getEnergyDevices.length
      ) {
        //TODO { "id":"1000000000000015" ,"call": "ping","params":{"timestamp":"12031289371982"} }
        dispatch("fetchEnergyDataForAllModes", null);
      }
    } catch (error) {
      console.log(error); //eslint-disable-line
    } finally {
      dispatch("ui/stopFetching", "getAllEnergyData", { root: true });
    }
  },
  resetDevices({ commit }) {
    commit(RESET_DEVICES);
  },
  clearEnergyData({ commit }, payload) {
    commit(CLEAR_ENERGY_DATA, payload);
  }
};
