<script>
const { VUE_APP_MODE } = process.env;
export default {
  name: "TemplateRenderer",
  props: {
    template: {
      type: Object,
      required: true
    }
  },
  methods: {
    tileTap(event) {
      const target =
        VUE_APP_MODE === "web"
          ? parseInt(event.currentTarget.id)
          : event.view.id;
      this.$emit("tileTap", target);
    },
    deleteTile(event) {
      const target =
        VUE_APP_MODE === "web"
          ? parseInt(event.currentTarget.id)
          : event.view.id;
      this.$emit("deleteTile", target);
    },
    renderTemplate(h, children) {
      const colCount = this.template.colCount ? this.template.colCount : 3;
      const rowCount = this.template.rowCount ? this.template.rowCount : 5;
      const rowHeight = this.template.rowHeight
        ? this.template.rowHeight.toString() + ","
        : "56,";

      const rows = rowHeight.repeat(rowCount).slice(0, -1);
      const columns = "*,".repeat(colCount).slice(0, -1);

      return h(
        "GridLayout",
        {
          attrs: {
            rows: rows,
            columns: columns
          }
        },
        children
      );
    },
    renderChildItems(h, children) {
      const items = [];
      for (let [index, item] of children.entries()) {
        items.push(
          h(
            item.comp ? item.comp : "StackLayout",
            {
              attrs: item.attrs
                ? item.attrs
                : { ...item.attrs, id: index, row: 0, col: 0 },
              style: item.style ? item.style : {},
              props: item.props ? item.props : {},
              on: item.on
                ? item.on
                : {
                    tap:
                      item.comp === "SvgIcon" ? this.deleteTile : this.tileTap
                  }
            },
            item.children === [] || !item.children
              ? []
              : this.renderChildItems(h, item.children)
          )
        );
      }
      return items;
    }
  },
  render(h) {
    return this.renderTemplate(
      h,
      this.renderChildItems(
        h,
        this.template.children ? this.template.children : []
      )
    );
  }
};
</script>
