<template>
  <MainPage ref="mainPage" @confirmationResult="confirmationResult">
    <GridLayout height="100%" rows="auto, *" class="device-energy-details">
      <IBtnHiBtn
        class="device-energy-details__nav"
        row="0"
        @iconTap1="navigateBack()"
        icon1Src="icons/Controls/ic_controls_arrow-left_56.svg"
        :text="deviceInfo.name"
      />
      <ScrollView row="1" iosOverflowSafeArea="false" height="100%">
        <StackLayout class="device-energy-details__body">
          <StackLayout class="device-energy-details__body__item-container">
            <StackLayout width="103" height="103">
              <TileItem
                width="103"
                height="103"
                horizontalAlignment="left"
                titleTextWrap
                :title="deviceInfo.name"
                :src="deviceInfo.icon"
                :status="deviceInfo.status"
                backgroundColorHex="#00B491"
                colorHex="#ffffff"
                iconColorHex="#ffffff"
              />
            </StackLayout>
          </StackLayout>
          <StackLayout class="device-energy-details__body__header-container">
            <VxpLabel class="font-h2-24" :text="headerText" :textWrap="true" />
            <VxpLabel
              class="font-txt-13 device-energy-details__body__header-container__description"
              text="Jul 13-20 2020"
              :textWrap="true"
            />
          </StackLayout>

          <Separator size="xl" class="device-energy-details__body__seperator" />

          <StackLayout class="device-energy-details__body__item-container">
            <VxpLabel
              class="font-h4-18"
              :text="energyInfo.totalConsumption.toFixed(3) + energyInfo.unit"
              :textWrap="true"
            />
            <VxpLabel
              class="device-energy-details__body__item-container__description font-txt-13"
              text="Total"
              :textWrap="true"
            />
          </StackLayout>

          <Separator size="xl" class="device-energy-details__body__seperator" />

          <StackLayout class="device-energy-details__body__item-container">
            <VxpLabel
              class="font-h4-18"
              :text="Math.floor(energyInfo.usageRatio * 100) + '%'"
              :textWrap="true"
            />
            <VxpLabel
              class="device-energy-details__body__item-container__description font-txt-13"
              :text="consumptionText"
              :textWrap="true"
            />
          </StackLayout>

          <Separator size="xl" class="device-energy-details__body__seperator" />

          <!-- <StackLayout class="device-energy-details__body__header-container">
            <Goals />
          </StackLayout> -->

          <StackLayout class="device-energy-details__body__header-container">
            <VxpLabel class="font-h2-24" :text="'Other'" :textWrap="true" />
          </StackLayout>
          <HTextUnderlineTextSwitch
            alignText="left"
            alignUnderline="left"
            headerSize="h4"
            :showUnderline="true"
            :text="translations.monitoring.header"
            :underlineText="translations.monitoring.text"
            :value="energyInfo.monitoringEnabled"
            @change="onMonitoringChange"
            colorPrimary="#00B491"
          />
        </StackLayout>
      </ScrollView>
    </GridLayout>
  </MainPage>
</template>
<script>
/* eslint-disable */
import { createNamespacedHelpers } from "vuex";
import Goals from "./EnergyGoals"
import { getDialogOptions } from "@/helpers/errorTypesDictionary";
const {
  mapGetters: mapEnergyGetters,
  mapActions: mapEnergyActions
 } = createNamespacedHelpers("energy");

export default {
  name: "DeviceEnergyDetails",
  props: {
    deviceId: {
      type: String,
      required: true
    },
    timeSpan: {
      type: Object,
      required: true
    },
    usageType: {
      type: String,
      required: true
    }
  },
  components: {
    Goals
  },
  created() {
    console.log(this.energyInfo);
  },
  computed: {
    ...mapEnergyGetters(["getDeviceEnergyInfo", "getDeviceById"]),
    energyInfo() {
      return this.getDeviceEnergyInfo(this.deviceId, this.timeSpan);
    },
    deviceInfo() {
      // TODO: get device name by id
      const { name, src: icon, status } = this.getDeviceById(this.deviceId);
      return {
        name,
        icon,
        status
      };
    },
    headerText() {
      return this.translations.details.time[this.usageType] ? this.translations.details.time[this.usageType] : "-";
    },
    consumptionText() {
      return this.translations.details.consumption[this.usageType] ? this.translations.details.consumption[this.usageType] : this.translations.details.consumption.total;
    },
    translations() {
      return this.$t("energy").energyDetails;
    },
  },
  methods: {
    ...mapEnergyActions(["setMonitoring"]),
    confirmationResult(event) {
      console.log("### --> DeviceEnergyDetails/confirmationResult:", event); //eslint-disable-line
      // if confirmed, the monitoringEnabled attribute is set to false 
      if (event.resultActionName === this.$options.name && event.result) {
        // TODO energy/action
        this.setMonitoring({ id: this.deviceId, monitoringEnabled: false})
      }
    },
    onMonitoringChange(val) {
      console.log("### --> onMonitoringChanged:", val); // eslint-disable-line
      if (val === false) {
        this.showConfirmationDialog();
      } else {
        this.setMonitoring({ id: this.deviceId, monitoringEnabled: val})
      }
    },
    showConfirmationDialog(dialog = "disableMonitoring") {
      const { props } = getDialogOptions(dialog);
      if (!props.resultActionName) {
        props["resultActionName"] = this.$options.name;
      }
      this.$refs.mainPage.showConfirmationDialog(props);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~/assets/styles/variables.scss";
.device-energy-details {
  color: $color-ui-black;
  &__nav {
    background: $color-light-grey;
  }
  &__body {
    &__tile-item {
      width: $tile-height;
      height: $tile-height;
    }
    &__header-container {
      padding: $xll $xl $xl $xl;
      &__description {
        color: $color-grey;
      }
    }
    &__item-container {
      padding: $l $xl $lmx $xl;
      &__description {
        color: $color-grey;
        margin-top: $xs;
      }
    }
    &__seperator {
      margin: 0 $xs 0 $xs;
    }
  }
}
</style>
