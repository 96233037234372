<template>
  <GridLayout
    class="rate-icon"
    rows="56"
    :columns="showCheckbox ? '56,*,56' : '56,*,16'"
  >
    <ButtonIcon
      row="0"
      col="0"
      v-show="showIcon"
      @tap="tap"
      :src="src"
      :disabled="disabled"
    />
    <StackLayout row="0" col="1" orientation="horizontal" @tap="tap">
      <StackLayout class="rate-icon__cont">
        <StackLayout class="rate-icon_title-container" orientation="horizontal">
          <GridLayout
            class="rate-icon_title-container_grid"
            rows="auto"
            columns="auto, *, auto"
          >
            <Label
              row="0"
              col="0"
              :class="{
                'font-h1-32': headerSize === 'h1',
                'font-h2-24': headerSize === 'h2',
                'font-h3-20': headerSize === 'h3'
              }"
              class="rate-icon_title"
              :text="title"
              :color="disabled ? disabledColod : consumptionColor"
            />
            <Label
              row="0"
              col="1"
              class="rate-icon_label font-txt-13"
              :text="desc"
            />
            <Label
              row="0"
              col="2"
              class="rate-icon_label font-txt-13"
              :text="getConsumption"
              :color="disabled ? disabledColod : consumptionColor"
            />
          </GridLayout>
        </StackLayout>
        <SliderCore
          :initialPercentage="rate"
          :barheight="6"
          :touchable="false"
          :barColor="barColor"
        />
      </StackLayout>
    </StackLayout>
    <ControlCheckbox
      color="#00B491"
      @change="onChanged"
      :checked="checkboxValue"
      v-if="showCheckbox"
      class="rate-icon__control"
      row="0"
      col="2"
    />
  </GridLayout>
</template>

<script>
export default {
  name: "ConsumptionRate",
  props: {
    title: {
      type: [String, Number],
      default: ""
    },
    desc: {
      type: [String, Number],
      default: ""
    },
    src: {
      type: String,
      default: "icons/Category/ic_category_other_56.svg"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    headerSize: {
      type: String,
      default: "h3"
    },
    descSize: {
      type: String,
      default: "h3"
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    rate: {
      type: [Number, String],
      default: 56
    },
    barColor: {
      type: String,
      default: "#00B491"
    },
    showCheckbox: {
      type: Boolean,
      default: false
    },
    checkboxValue: {
      type: Boolean,
      default: true
    },
    consumption: {
      type: [Number, String],
      default: "6.3 kWh"
    },
    consumptionColor: {
      type: String,
      default: "#000000"
    },
    disabledColod: {
      type: String,
      default: "#90a0af"
    },
    unit: {
      type: String,
      default: "watts"
    }
  },
  computed: {
    getConsumption() {
      return this.consumption === 0
        ? "No data"
        : this.consumption.toFixed(3) + " " + this.unit; //" kWh";
    }
  },
  methods: {
    tap() {
      this.$emit("tap", { disabled: this.disabled });
    },
    onChanged(value) {
      this.$emit("change", value);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~/assets/styles/variables.scss";
// @import "~/assets/styles/typography.scss";

.rate-icon {
  padding: 0 $s 0 $padding-s;
  box-sizing: border-box;
  &__cont {
    width: 100%;
  }
  &_title-container {
    padding-top: $xsm;
    padding-bottom: $padding-xs;
    &_grid {
      width: 100%;
    }
  }
  &_title {
    //for vertical alignment
    // line-height: unit(56, 0);
    vertical-align: bottom;
  }
  &_label {
    //for vertical alignment
    // line-height: unit(56, 0);
    margin-left: $margin-s;
    margin-bottom: $xxs;
    align-self: flex-end;
    vertical-align: bottom;
  }
  &__control {
    justify-content: center;
    align-items: center;
    padding-top: $xxs;
  }
}
</style>
