export const getParsedUserInfo = (value = {}) => {
  // "PK_Account": 1048402,
  // "PK_PermissionRole": 10,
  // "Username": "maxnp", // username for login
  // "Name": "M.Pereshibkin", // lastName of user
  // "Email": "max.pereshybkin@ezlo.com", // email of user
  // "ValidationCode": 3469,
  // "Validated": 0,
  // "EmailAlerts": 0,
  // "EmailNews": 0,
  // "EmailNotifications": 1,
  // "PhoneNotifications": 0,
  // "PushNotifications": 0,
  // "FirstName": "Max22", // firstName of user
  // "NotifyOnSeverity": [
  //     1,
  //     2,
  //     3,
  //     4,
  //     5,
  //     6,
  //     7,
  //     9
  // ],
  // "Created": "2019-11-19 16:30:58",
  // "EmailNotificationsBilling": 1,
  // "PhoneNotificationsBilling": 1,
  // "Blocked": 0,
  // "PK_Language": 1,
  // "PK_Oem_User": "",
  // "Phone": {
  //     "PK_Phone": 2796372,
  //     "PK_Provider": -1,
  //     "PK_Country": 804,
  //     "Phone": "123456", // user phone number
  //     "ValidationCode": 8985,
  //     "Validated": 0
  // }
  const {
    Username: username,
    Email: email,
    FirstName: firstName,
    Name: lastName,
    Phone
  } = value;
  const { Phone: phoneNumber } = Phone;
  const userInfo = {
    username,
    email,
    firstName,
    lastName,
    phoneNumber
  };

  return userInfo;
};
