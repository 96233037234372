<template>
  <MainPage @layoutChanged="onlayoutChanged">
    <GridLayout rows="*, auto" columns="*">
      <KeyboardAvoidingView
        row="0"
        col="0"
        class="signin-by-user-start-page"
        contentVerticalAlign="bottom"
        :iosTextInputKeyboardDistance="iosTextInputKeyboardDistance"
      >
        <IBtnAct
          slot="action-bar"
          class="signin-by-user-start-page__header"
          src="icons/Controls/ic_controls_arrow-left_56.svg"
          :text="this.translations.forgotPasswordText"
          @tap="onTapGoBack"
          primary
          @actionTap="forgotPassword"
        />
        <LoginForm
          slot="content"
          :translations="translations"
          :isLoading="isFetching('login')"
          @login="processLogin"
          :usernamePlaceholder="translations.usernamePlaceholder"
          :passwordPlaceholder="translations.passwordPlaceholder"
        />
      </KeyboardAvoidingView>
    </GridLayout>
  </MainPage>
</template>
<script>
import { createNamespacedHelpers } from "vuex";
import LoginForm from "@/components/forms/LoginForm";

const {
  mapGetters: mapUiGetters,
  mapActions: mapUiActions
} = createNamespacedHelpers("ui");
const { mapActions: mapAuthActions } = createNamespacedHelpers("auth");

const IOS_TEXT_INPUT_KEYBOARD_OFFSET = 220;

export default {
  name: "LoginByUserIntro",
  components: { LoginForm },
  data() {
    return {
      iosTextInputKeyboardDistance: IOS_TEXT_INPUT_KEYBOARD_OFFSET
    };
  },
  computed: {
    ...mapUiGetters(["isFetching", "hasError"]),
    translations() {
      return {
        titleText: this.$t("loginByUserIntro.title"),
        forgotPasswordText: this.$t("loginByUserIntro.forgotPasswordText"),
        loginButtonText: this.$t("loginByUserIntro.loginButtonText"),
        usernameError: this.$t(
          "core.loginByUsernamePasswordFailed.usernameField"
        ),
        usernamePlaceholder: this.$t("loginByUserIntro.usernamePlaceholder"),
        passwordPlaceholder: this.$t("loginByUserIntro.passwordPlaceholder")
      };
    }
  },
  methods: {
    ...mapAuthActions(["loginAsync"]),
    ...mapUiActions(["resetError"]),
    forgotPassword() {
      this.navigateTo("ForgotPasswordEmailIntro");
    },
    onTapGoBack() {
      this.navigateBack();
    },
    async processLogin({ username, password }) {
      this.resetError();
      await this.loginAsync({ username, password });

      if (this.hasError) {
        return;
      }
      this.navigateTo("DashboardPreview", { clearHistory: true });
    },
    onlayoutChanged(event) {
      if (event.object.android) {
        event.object.android.setFocusable(true);
        event.object.android.setFocusableInTouchMode(true);
      }
    }
  }
};
</script>
