import {
  liveLabelFormatter,
  dayLabelFormatter,
  weekLabelFormatter,
  monthLabelFormatter
} from "@/helpers/chartUtils";
import { orderBy } from "lodash";
import moment from "moment";
import { cloneDeep } from "lodash";

const mockData = {
  id: "2525252....",
  status: 1,
  complete: 1,
  data: {
    metric_state: 2,
    metrics: [
      {
        name: "electric_meter_watt",
        columns: ["time", "value"],
        values: [
          ["2020-06-01T00:00:17Z", 0.029333333333333333],
          ["2020-06-01T00:00:18Z", 0.058666666666666666],
          ["2020-06-01T00:00:19Z", 0.088],
          ["2020-06-01T00:00:20Z", 0.11733333333333333],
          ["2020-06-01T00:00:21Z", 0.14666666666666667],
          ["2020-06-01T00:00:22Z", 0.176],
          ["2020-06-01T00:00:24Z", 0.2053333333333333],
          ["2020-06-01T00:00:25Z", 0.23466666666666663],
          ["2020-06-01T00:00:26Z", 0.26399999999999996],
          ["2020-06-01T00:00:27Z", 0.2933333333333333],
          ["2020-06-01T00:00:32Z", 0.3489444444444444],
          ["2020-06-01T00:00:33Z", 0.4045555555555555],
          ["2020-06-01T00:00:34Z", 0.4601666666666666],
          ["2020-06-01T00:00:35Z", 0.5157777777777778],
          ["2020-06-01T00:00:36Z", 0.571388888888889],
          ["2020-06-01T00:00:37Z", 0.6270000000000001],
          ["2020-06-01T00:00:38Z", 0.6826111111111113],
          ["2020-06-01T00:00:40Z", 0.7394444444444446],
          ["2020-06-01T00:00:41Z", 0.7962777777777779],
          ["2020-06-01T00:00:42Z", 0.8531111111111112],
          ["2020-06-02T00:00:17Z", 0.029333333333333333],
          ["2020-06-02T00:00:18Z", 0.058666666666666666],
          ["2020-06-02T00:00:19Z", 0.088],
          ["2020-06-02T00:00:20Z", 0.11733333333333333],
          ["2020-06-03T00:00:21Z", 0.14666666666666667],
          ["2020-06-03T00:00:22Z", 0.176],
          ["2020-06-03T00:00:24Z", 0.2053333333333333],
          ["2020-06-03T00:00:25Z", 0.23466666666666663],
          ["2020-06-04T00:00:26Z", 0.26399999999999996],
          ["2020-06-05T00:00:27Z", 0.2933333333333333],
          ["2020-06-05T00:00:32Z", 0.3489444444444444],
          ["2020-06-05T00:00:33Z", 0.4045555555555555],
          ["2020-06-05T00:00:34Z", 0.4601666666666666],
          ["2020-06-05T00:00:35Z", 0.5157777777777778],
          ["2020-06-05T00:00:36Z", 0.571388888888889],
          ["2020-06-05T00:00:37Z", 0.6270000000000001],
          ["2020-06-05T00:00:38Z", 0.6826111111111113],
          ["2020-06-05T00:00:40Z", 0.7394444444444446],
          ["2020-06-05T00:00:41Z", 0.7962777777777779],
          ["2020-06-05T00:00:42Z", 0.8531111111111112]
        ]
      }
    ]
  }
};
const mockGoals = [
  {
    id: "goal_1",
    icon: "icons/Devices/ic_device_plug_fridge_56.svg",
    header: "Fridge",
    subHeader: "Monthly goal",
    percentage: 30,
    description: "50 of 248 kWh used",
    descriptionColor: "#000000",
    subDescription: "First month in progress",
    barColors: {
      foreground: "#00B491",
      background: "rgba(144, 160, 175, 0.15)"
    },
    used: 50,
    limit: 248,
    month: 8,
    device: { id: "Z396F186A" }
  },
  {
    id: "goal_2",
    icon: "icons/Devices/ic_device_lighthing_lamp4_56.svg",
    header: "Bedside lamp",
    subHeader: "Monthly goal",
    percentage: 60,
    description: "String",
    descriptionColor: "#000000",
    subDescription: "First month in progress",
    barColors: {
      foreground: "#00B491",
      background: "rgba(144, 160, 175, 0.15)"
    },
    used: 20,
    limit: 148,
    month: 8,
    device: { id: "Z8A18A7BD" }
  }
];
const mockDevices = [
  {
    id: "1",
    name: "Fridge",
    src: "icons/Devices/ic_device_plug_fridge_56.svg",
    groupId: "1F8D7089",
    groupTitle: "Living room",
    selected: false,
    monitoringEnabled: true,
    rate: 0,
    consumption: 6.3,
    status: "on"
  },
  {
    id: "2",
    name: "Bedside lamp",
    src: "icons/Devices/ic_device_lighthing_lamp4_56.svg",
    groupId: "1F8D7089",
    groupTitle: "Living room",
    selected: false,
    monitoringEnabled: true,
    rate: 0,
    consumption: 4.1,
    status: "on"
  },
  {
    id: "3",
    name: "Toaster",
    src: "icons/Devices/ic_device_plug_toaster_56.svg",
    groupId: "1F8D7089",
    groupTitle: "Living room",
    selected: false,
    monitoringEnabled: true,
    rate: 0,
    consumption: 0.5,
    status: "on"
  },
  {
    id: "4",
    name: "Kettle",
    src: "icons/Devices/ic_device_plug_kettle_56.svg",
    groupId: "4F8D7309",
    groupTitle: "No room",
    selected: false,
    monitoringEnabled: true,
    rate: 0,
    consumption: 0,
    status: "off"
  }
];

const mockDeviceConsumptions = {
  live: {
    chartOptions: {
      chart: {
        stacked: false
      },
      stroke: {
        width: 1,
        curve: "smooth"
      },
      fill: {
        opacity: 0.15
      },
      xaxis: {
        type: "category",
        range: 2000000,
        labels: {
          rotate: 0,
          formatter: liveLabelFormatter
        },
        categories: [
          "00",
          "01",
          "02",
          "03",
          "04",
          "05",
          "06",
          "07",
          "08",
          "09",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23"
        ]
      },
      yaxis: {
        decimalsInFloat: 0,
        opposite: true,
        labels: {
          offsetX: -10
        }
      },
      colors: ["#90A0AF", "#00B491"]
    }
  },
  day: {
    chartOptions: {
      plotOptions: {
        bar: {
          columnWidth: "70%"
        }
      },
      xaxis: {
        type: "category",
        labels: {
          rotate: 0,
          formatter: dayLabelFormatter
        },
        categories: [
          "00",
          "01",
          "02",
          "03",
          "04",
          "05",
          "06",
          "07",
          "08",
          "09",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23"
        ]
      },
      yaxis: {
        decimalsInFloat: 0,
        opposite: true,
        labels: {
          offsetX: -10
        }
      }
    }
  },
  week: {
    chartOptions: {
      plotOptions: {
        bar: {
          columnWidth: "20%"
        }
      },
      xaxis: {
        type: "category",
        labels: {
          rotate: 0,
          formatter: weekLabelFormatter
        },
        categories: ["01", "02", "03", "04", "05", "06", "07"]
      },
      yaxis: {
        decimalsInFloat: 0,
        opposite: true,
        labels: {
          offsetX: -10
        }
      }
    }
  },
  month: {
    chartOptions: {
      plotOptions: {
        bar: {
          columnWidth: "70%"
        }
      },
      xaxis: {
        type: "category",
        labels: {
          rotate: 0,
          formatter: monthLabelFormatter
        },
        categories: [
          "01",
          "02",
          "03",
          "04",
          "05",
          "06",
          "07",
          "08",
          "09",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31"
        ]
      },
      yaxis: {
        decimalsInFloat: 0,
        opposite: true,
        labels: {
          offsetX: -10
        }
      }
    }
  }
};
const initialGoal = {
  id: "",
  limit: "",
  month: "",
  device: {
    deviceId: "",
    name: "",
    icons: {}
  }
};

const reducer = (accumulator, currentValue, idx, src) => {
  return accumulator + currentValue;
};

export default {
  getEnergy: state => {
    console.dir("getEnergy:", state); //eslint-disable-line
    return state;
  },
  getAddress: state => {
    return state.address;
  },
  getValueByKey: state => key => {
    return state[key];
  },
  getSelectedDeviceId: state => state.selectedDeviceId,
  getTotalUsage: state => {
    // TODO: get total usage of devices
    return "354";
  },
  getDeviceEnergyInfo: (state, getters) => (deviceId, timeSpan) => {
    if (timeSpan.startDate && timeSpan.endDate) {
      // TODO: when services are ready
      // fetch device info with given timeSpan {startDate, endDate}
      // return fetched data
      const metrics = mockData.data.metrics;
      const electricMeterMetric = metrics.find(
        metric => metric.name === "electric_meter_watt"
      );
      const valueColumnIndex = electricMeterMetric.columns.indexOf("value");
      const totalConsumption = electricMeterMetric.values.reduce(
        (sum, item) => item[valueColumnIndex] + sum,
        0
      );
      const device = getters.getDeviceById(deviceId);
      return {
        deviceId: deviceId,
        totalConsumption: totalConsumption,
        usageRatio: totalConsumption / getters.getTotalUsage,
        monitoringEnabled: device && device.monitoringEnabled,
        unit: "kWh"
      };
    }
  },
  getMockEnergyData: () => mockData,
  getMockGoals: () => mockGoals,
  getActiveMode: state => {
    return state.activeMode;
  },
  getModes: state => {
    return state.modes;
  },
  getColors: state => state.colors,
  getColorByIndex: state => index => state.colors[index % state.colors.length],
  getGraphOptions: state => mode => {
    return mockDeviceConsumptions[mode].chartOptions;
  },
  getEnergyDevices: state => {
    return mockDevices;
  },
  isInFilter: state => deviceId => {
    return state.filteredDevices.indexOf(deviceId) > -1;
  },
  getFilteredDevices: state => {
    return state.filteredDevices;
  },
  getSeriesVisibility: (state, getters) => ({ mode, liveDataActive }) => {
    if (state.filteredDevices.length === 0) {
      return [];
    }
    return getters.getSeries({ mode, liveDataActive }).map(item => {
      return {
        id: item.id,
        name: item.name,
        visible: getters.isInFilter(item.id.toString())
      };
    });
  },
  getGoals: (state, getters) => {
    const goals = state.goals.map(goal => {
      const used = getters.getTotalConsumpsionByDeviceId({
        id: goal.device.id,
        mode: "month"
      });
      return {
        ...goal,
        used,
        percentage: (used / 1000 / goal.limit) * 100 || 0,
        description: `${(used / 1000).toFixed(3) || 0} of ${goal.limit ||
          0} kWh used`
      };
    });
    return goals;
  },
  getGoalById: state => id => state.goals.find(item => item.id === id),
  getCurrentGoal: state => state.currentGoal,
  getDeviceGroups: state => state.deviceGroups,
  getEnergyDevices: state => state.devices,
  getSocketConnectionStatus: state => state.socketConnection,
  getDevicesWithConsumptions: (state, getters) => ({
    mode,
    liveDataActive
  }) => {
    return state.devices.map((device, index) => {
      const consumption = getters.getTotalConsumpsionByDeviceId({
        id: device.id,
        mode,
        liveDataActive
      });
      const totalConsumption = getters.getTotalConsumptionByMode({
        mode,
        liveDataActive
      });
      const rate =
        consumption > 0
          ? Number(((consumption / totalConsumption) * 100).toFixed(1))
          : 0;
      const consumptionsData =
        state.consumption[mode] && state.consumption[mode][device.id]
          ? state.consumption[mode][device.id].values
          : [];
      const liveData =
        state.consumption[mode] && state.consumption[mode][device.id]
          ? state.consumption[mode][device.id].liveValues
          : [];

      return {
        ...device,
        title: consumption === 0 ? "--" : String(rate).concat("%"),
        desc: device.name,
        consumption,
        rate,
        barColor: device.barcolor || getters.getColorByIndex(index),
        consumptionsData,
        liveData
      };
    });
  },
  getDeviceById: state => id => state.devices.find(item => item.id == id),
  getInitialGoal: () => initialGoal,
  getSelectedDateByType: state => type => state.selectedDates[type],
  getSelectedDates: state => state.selectedDates,
  getTotalConsumpsionByDeviceId: (state, getters) => ({
    id,
    mode,
    dataPointIndex,
    seriesIndex = 0,
    liveDataActive = true,
    dataPoint
  }) => {
    if (!mode) {
      mode = getters.getModes[getters.getActiveMode];
    }
    seriesIndex = liveDataActive ? 1 : seriesIndex;
    const dataSeries = state.consumption[mode] && state.consumption[mode][id];
    let data = [];
    if (dataSeries && mode === "live") {
      switch (seriesIndex) {
        case 0:
          data = [...dataSeries.values];
          break;
        case 1:
          data = [...dataSeries.liveValues];
          break;
        default:
          data = [...dataSeries.values, ...dataSeries.liveValues];
          break;
      }
      data =
        seriesIndex && seriesIndex === 1
          ? [...dataSeries.liveValues]
          : [...dataSeries.values];
    } else {
      data = (dataSeries && dataSeries.values) || [];
    }

    if (!data || data.length === 0) {
      return 0;
    }

    if (dataPointIndex || dataPointIndex === 0) {
      if (mode === "live") {
        data = data.filter((item, index) =>
          moment(item[0]).isSame(dataPoint[0])
        );
      } else {
        data = data.filter((item, index) => index === dataPointIndex);
      }
    }

    let values = [];
    if (Array.isArray(data[0])) {
      const valueEntries = Object.fromEntries(data);
      values = Object.values(valueEntries);
    } else {
      values = data;
    }

    return values.reduce(reducer, 0);
  },
  getTotalConsumptionByMode: (state, getters) => ({
    mode,
    deviceIds,
    dataPointIndex,
    seriesIndex,
    dataPoint,
    liveDataActive = false
  }) => {
    if (!mode) {
      mode = getters.getModes[getters.getActiveMode];
    }
    const consumption = state.consumption[mode];
    let total = 0;
    const ids = deviceIds || (consumption && Object.keys(consumption)) || [];
    for (let deviceId of ids) {
      total += getters.getTotalConsumpsionByDeviceId({
        id: deviceId,
        mode,
        dataPointIndex,
        seriesIndex,
        dataPoint,
        liveDataActive
      });
    }
    return total;
  },
  getAverageConsumptionByDeviceId: (state, getters) => ({ id, mode }) => {
    if (!id) return 0;
    if (!mode) {
      mode = getters.getModes[getters.getActiveMode];
    }
    return state.consumption[mode] && state.consumption[mode][id].average;
  },
  getAverageConsumptionByMode: (state, getters) => ({ mode, deviceIds }) => {
    const divider = {
      day: 24,
      week: 7,
      month: moment(getters.getSelectedDateByType(mode).end).daysInMonth()
    };
    const consumption = state.consumption[mode];
    let total = 0;
    const ids = deviceIds || (consumption && Object.keys(consumption)) || [];
    for (let deviceId of ids) {
      total += getters.getAverageConsumptionByDeviceId({
        id: deviceId,
        mode
      });
    }
    return total;
  },
  getPreviousConsumptionByDeviceId: (state, getters) => ({ id, mode }) => {
    if (!id) return 0;
    if (!mode) {
      mode = getters.getModes[getters.getActiveMode];
    }
    return state.consumption[mode] && state.consumption[mode][id].previous;
  },
  getPreviousConsumptionByMode: (state, getters) => ({ mode, deviceIds }) => {
    const consumption = state.consumption[mode];
    let total = 0;
    const ids = deviceIds || (consumption && Object.keys(consumption)) || [];
    for (let deviceId of ids) {
      total += getters.getPreviousConsumptionByDeviceId({
        id: deviceId,
        mode
      });
    }
    return total;
  },
  getSeries: (state, getters) => ({
    mode,
    liveDataActive = true,
    deviceIds
  }) => {
    if (!mode) {
      mode = getters.getModes[getters.getActiveMode];
    }
    let orderedDevices = orderBy(
      getters
        .getDevicesWithConsumptions({ mode, liveDataActive })
        .filter(device => device.consumption > 0),
      ["consumption", "name"],
      ["desc", "asc"]
    );
    if (mode === "live" && deviceIds && deviceIds.length > 0) {
      orderedDevices = orderedDevices.filter(device =>
        deviceIds.includes(device.id)
      );
    }
    const type = mode === "live" ? "area" : "column";

    const series = [];
    if (mode === "live") {
      let staticSeries = {
        id: "1",
        name: "Consumption",
        type,
        data: []
      };
      let liveSeries = {
        id: "2",
        name: "Live consumption",
        type,
        data: []
      };
      let liveSeriesData = [];

      orderedDevices.forEach(device => {
        if (device.consumptionsData && device.consumptionsData.length > 0) {
          device.consumptionsData.forEach(newItem => {
            let data = staticSeries.data.find(
              oldItem => oldItem[0] === newItem[0]
            );
            if (data) {
              data[1] += newItem[1];
            } else {
              staticSeries.data.push([...newItem]);
            }
          });
        }
        if (device.liveData && device.liveData.length > 0) {
          if (liveSeriesData.length !== 0) {
            // insertion sort algo. to insert data into correct order
            device.liveData.forEach(data => {
              for (let i = 0; i < liveSeriesData.length; i++) {
                let oldData = liveSeriesData[i];
                if (data[0] === oldData[0]) {
                  liveSeriesData[i][1] += data[1];
                  break;
                } else if (data[0] < oldData[0]) {
                  liveSeriesData.splice(i, 0, cloneDeep(data));
                  break;
                } else {
                  if (i === liveSeriesData.length - 1) {
                    liveSeriesData.shift(cloneDeep(data));
                    break;
                  }
                }
              }
            });
          } else {
            liveSeriesData = cloneDeep(device.liveData);
          }
        }
      });
      liveSeries.data = liveSeriesData;
      // series.push(staticSeries);
      series.push(liveDataActive ? liveSeries : staticSeries);
    } else {
      orderedDevices.forEach(device => {
        const serie = {
          id: device.id,
          name: device.name,
          type,
          data: device.consumptionsData || []
        };
        series.push(serie);
      });
    }
    return series;
  },
  getLatestLiveDataValue(state) {
    return state.latestLiveDataValue;
  },
  getLatestLiveDataValueByDeviceId: state => ({
    id,
    liveDataActive = true
  }) => {
    if (state.consumption.hasOwnProperty("live")) {
      const values = liveDataActive
        ? state.consumption["live"][String(id)].liveValues
        : state.consumption["live"][String(id)].values;
      return (values && values.length && values[values.length - 1][1]) || 0;
    }
    return 0;
  },
  getLatestTotalConsumption: (state, getters) => ({
    mode,
    liveDataActive = true,
    deviceIds
  }) => {
    if (!mode) {
      mode = getters.getModes[getters.getActiveMode];
    }
    const consumption = state.consumption[mode];
    let total = 0;
    const ids = deviceIds || (consumption && Object.keys(consumption)) || [];
    for (let deviceId of ids) {
      total += getters.getLatestLiveDataValueByDeviceId({
        id: deviceId,
        liveDataActive
      });
    }
    return total;
  }
};
