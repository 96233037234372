import Vue from "vue";
import { SET_FAVORITES, ADD_FAVORITE, DELETE_FAVORITE } from "./mutationTypes";

export default {
  [SET_FAVORITES](state, payload) {
    state.favorites = payload;
  },
  [ADD_FAVORITE](state, { devices, items, rules }) {
    if (devices)
      devices.forEach(id => {
        state.favorites.devices.push(id);
      });
    if (items)
      items.forEach(id => {
        state.favorites.items.push(id);
      });
    if (rules)
      rules.forEach(id => {
        state.favorites.rules.push(id);
      });
  },
  [DELETE_FAVORITE](state, { devices, items, rules }) {
    if (devices)
      Vue.set(
        state.favorites,
        "devices",
        state.favorites.devices.filter(id => !devices.includes(id))
      );
    if (items)
      Vue.set(
        state.favorites,
        "items",
        state.favorites.items.filter(id => !items.includes(id))
      );
    if (rules)
      Vue.set(
        state.favorites,
        "rules",
        state.favorites.rules.filter(id => !rules.includes(id))
      );
  }
};
