<template>
  <StackLayout class="tile-types">
    <StackLayout class="tile-types__wrap">
      <Separator v-if="getItems.length" size="xl" />
      <StackLayout v-for="item in getItems" :key="item.type">
        <IconHTxtiBtn
          alignText="left"
          headerSize="h3"
          :text="$t(item.text)"
          :icon1Src="item.icon || ''"
          :icon2Src="item.icon2 || ''"
          @tap="handleTap(item.type)"
        />
        <Separator size="xl" />
      </StackLayout>
    </StackLayout>
  </StackLayout>
</template>

<script>
export default {
  name: "TileTypesList",
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    getItems() {
      return this.items.filter(
        item => item.visible || item.visible === undefined
      );
    }
  },
  methods: {
    handleTap(type) {
      this.$emit("selectTileType", type);
    }
  }
};
</script>
