export const SAVE_DEVICE_SETTINGS = "SAVE_DEVICE_SETTINGS";
export const SAVE_DEVICE_CATEGORY_ICON = "SAVE_DEVICE_CATEGORY_ICON";

export const ADD_DEVICE = "ADD_DEVICE";
export const DELETE_DEVICE = "DELETE_DEVICE";
export const UPDATE_DEVICE = "UPDATE_DEVICE";

export const SET_CURRENT_DEVICE_SETTINGS = "SET_CURRENT_DEVICE_SETTINGS";
export const SET_CURRENT_DEVICE_CATEGORY_ICON =
  "SET_CURRENT_DEVICE_CATEGORY_ICON";
export const RESET_CURRENT_DEVICE_SETTINGS = "RESET_CURRENT_DEVICE_SETTINGS";
export const CHANGE_DEVICE_NAME = "CHANGE_DEVICE_NAME";
export const CHANGE_DEVICE_ROOM = "CHANGE_DEVICE_ROOM";
export const CHANGE_DEVICE_CATEGORY_ICON = "CHANGE_DEVICE_CATEGORY_ICON";
export const CHANGE_DEVICE_QUICK_ACTION = "CHANGE_DEVICE_QUICK_ACTION";
export const CHANGE_DEVICE_NOTIFICATIONS = "CHANGE_DEVICE_NOTIFICATIONS";
export const UPDATE_DEVICE_ITEM_VALUE = "UPDATE_DEVICE_ITEM_VALUE";
export const SET_LOADING_STATE = "SET_LOADING_STATE";
export const CHANGE_ROOM = "CHANGE_ROOM";

export const SET_DEVICES = "SET_DEVICES";
export const RESET_DEVICES = "RESET_DEVICES";
export const UPDATE_WINDOW_COVER = "UPDATE_WINDOW_COVER";
export const CHANGE_DEVICE_DETAILS_SHORTCUT = "CHANGE_DEVICE_DETAILS_SHORTCUT";
export const START_LOADING = "START_LOADING";
export const STOP_LOADING = "STOP_LOADING";
export const ADD_SELECTED_DEVICE = "ADD_SELECTED_DEVICE";
export const REMOVE_SELECTED_DEVICE = "REMOVE_SELECTED_DEVICE";
export const RESET_SELECTED_DEVICE = "RESET_SELECTED_DEVICE";
export const SET_SELECTED_DEVICE = "SET_SELECTED_DEVICE";
export const SET_ABSTRACTS = "SET_ABSTRACTS";
export const SET_ABSTRACT_DEVICES = "SET_ABSTRACT_DEVICES";
export const UPDATE_DEVICES = "UPDATE_DEVICES";
