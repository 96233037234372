<template>
  <MainPage>
    <GridLayout rows="auto, *" columns="*">
      <IBtnAct
        row="0"
        col="0"
        src="icons/Controls/ic_controls_arrow-left_56.svg"
        @tap="onBack"
      />

      <PasswordResetSuccess
        row="1"
        col="0"
        :title="titleT"
        :desc="descT"
        :btn="btnT"
        :hint="hintT"
        @tap="onCheckEmail"
      />

      <BottomUpDialog
        rowSpan="2"
        ref="dialogListOfMailApps"
        :contentSwipeToClose="true"
      >
        <MailApps @count="setAppCount" @close="closeMailAppsDialog" />
      </BottomUpDialog>
    </GridLayout>
  </MainPage>
</template>

<script>
import utils from "vuexp/src/utils";
import PasswordResetSuccess from "@/components/onboarding/PasswordResetSuccess";

export default {
  name: "ForgotPasswordEmailReset",
  components: {
    PasswordResetSuccess
  },
  props: {
    userEmail: {
      type: String,
      require: true
    }
  },
  data() {
    return {
      appCount: 0,
      titleT: this.$t("forgotPasswordEmailReset.title"),
      descT: this.$t("forgotPasswordEmailReset.desc"),
      btnT: this.$t("forgotPasswordEmailReset.btn"),
      hintT: this.$t("forgotPasswordEmailReset.hint")
    };
  },
  methods: {
    onBack() {
      this.navigateBack();
    },
    setAppCount(event) {
      this.appCount = event;
    },
    openMailAppsDialog() {
      const dialog = this.$refs.dialogListOfMailApps;
      if (dialog) {
        dialog.show();
      }
    },
    closeMailAppsDialog() {
      const dialog = this.$refs.dialogListOfMailApps;
      if (dialog) {
        dialog.hide();
      }
    },
    onCheckEmail() {
      console.log("#### onCheckEmail ####:", this.appCount); // eslint-disable-line

      // if there is only default app opens the default app
      if (this.appCount === 1) {
        utils.openUrl(`mailto://${this.userEmail}`);
        return;
      }

      this.openMailAppsDialog();
    }
  }
};
</script>
