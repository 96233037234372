<template>
  <GridLayout rows="*,auto" class="new-dashboard-overview" height="100%">
    <StackLayout row="0">
      <ScrollView height="100%" iosOverflowSafeArea="false">
        <StackLayout>
          <Separator size="xl" />
          <!-- <HTextUnderlineTextSwitch
            showUnderline
            alignText="left"
            alignUnderline="left"
            headerSize="h3"
            :colorPrimary="onActiveColor"
            :text="texts.useOneTemplate"
            :underlineText="texts.useOneTemplateDescription"
            :value="useOneTemplate"
            @change="handleChangeUseOneTemplate"
          />
          <Separator size="xl" /> -->

          <StackLayout v-show="!useOneTemplate">
            <Orientations
              @onSelectOrientation="selectOrientation"
              :isPortraitCreated="isPortraitCreated"
              :isLandscapeCreated="isLandscapeCreated"
            />
            <Separator size="xl" />
          </StackLayout>
          <StackLayout
            v-show="useOneTemplate"
            class="new-dashboard-overview__center new-dashboard-overview__margin"
          >
            <Templates
              class="new-dashboard-overview__list"
              v-if="isPortrait"
              :templates="templates"
              :selectedTemplateId="selectedTemplateId"
              @selectTemplate="handleSelectTemplate"
            />
          </StackLayout>
        </StackLayout>
      </ScrollView>
    </StackLayout>
  </GridLayout>
</template>
<script>
import { locals } from "@/helpers/variables";
import Templates from "@/components/dashboard/Templates";
import Orientations from "@/components/dashboard/Orientations";

export default {
  name: "NewDashboardOverview",
  components: { Templates, Orientations },
  props: {
    templates: {
      type: Array,
      default: () => []
    },
    selectedTemplateId: {
      type: String
    },
    texts: {
      type: Object,
      default: () => ({
        textTitle: "Select dashboard template",
        textBtn: "Next",
        useOneTemplate: " Use one template",
        useOneTemplateDescription:
          "A portrait template will be used for both modes: portrait and landscape."
      })
    },
    isDisabledNext: {
      type: Boolean,
      default: true
    },
    isLandscapeCreated: {
      type: Boolean,
      default: false
    },
    isPortraitCreated: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      useOneTemplate: false,
      orientation: "portrait"
    };
  },
  computed: {
    onActiveColor() {
      return locals.colorPrimary;
    },
    isPortrait() {
      return this.orientation === "portrait";
    }
  },
  methods: {
    handleSelectTemplate(template) {
      this.$emit("selectTemplate", template);
    },
    handleTapNext() {
      this.$emit("gotoNext");
    },
    handleChangeUseOneTemplate(value) {
      this.useOneTemplate = value;
      this.orientation = "portrait";
      this.$emit("onChangeUseOneTemplate", value);
    },
    selectOrientation(event) {
      console.log("### ORIENTATION:", event); //eslint-disable-line
      this.orientation = event;
      this.$emit("onSelectOrientation", event);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~/assets/styles/variables.scss";

.new-dashboard-overview {
  &__center,
  &__footer {
    margin-bottom: $xl;
    padding: 0 $xl;
  }
  &__list {
    min-height: $web-min-height;
  }
  &--text {
    color: $color-black;
  }
  &__margin {
    margin-top: $margin-m;
  }
}
</style>
