export default Object.freeze({
  auth: {
    username: {
      get: "/autha/auth/username/{username}",
      post: "/autha/auth/username",
      logout: "/autha/auth/user/{PK_User}/logout",
      updatePassword: {
        put: "/autha/auth/username/{username}/password"
      },
      refreshToken: "/autha/auth/user/{PK_User}/token/refresh"
    },
    email: {
      passwordReset: {
        put: "/autha/auth/email/{email}/password_reset"
      },
      login: {
        get: "/autha/auth/email/{email}"
      }
    },
    userInfo: {
      get: "/account/user/user/{PK_User}",
      put: "/account/user/user/{PK_User}"
    },
    account: {
      get: "/autha/auth/account/{account}",
      post: "/autha/auth/account"
    },
    config: {
      get: "/info/config/config"
    },
    services: {
      get: "/api/v1/services/status/account"
    }
  },
  dashboards: {
    get: "dashboard_list",
    post: "dashboard_create",
    put: "dashboard_set",
    delete: "dashboard_set"
  },
  theme: {
    get: "dashboard_theme_list",
    post: "dashboard_theme_set",
    put: "dashboard_theme_set",
    delete: "dashboard_theme_set"
  },
  storage: {
    post: "/storage/storage/store",
    delete: "/storage/storage/store/{store}",
    get: "/account/kvs/kvs"
  },
  hub: {
    authd: {
      oemdevice: "/authd/auth/device/oemdevice/{PK_Device}?PK_Oem={oem}"
    },
    get: "/device/device/device/{PK_Device}",
    getServers: "/device/device/device/{PK_Device}/servers",
    relayToken: "/info/session/token",
    devices:
      "/relay/relay/relay/device/{PK_Device}/session/{relayToken}/port_3480/data_request?id=user_data",
    polling:
      "/relay/relay/relay/device/{PK_Device}/session/{relayToken}/port_3480/data_request?id=status&DataVersion={DataVersion}&MinimumDelay=1000&Timeout=60&LoadTime={LoadTime}"
  },
  account: {
    installation: {
      installations: {
        get: "/account/account/account/{account}/devices"
      }
    }
  },
  atom32: {
    connect: "/wifi/network/connect",
    availableWifiNetworks: "/wifi/networks",
    wifiStatus: "/wifi/status",
    connectionClose: "/wifi/network/close"
  },
  help: {
    support: "/api/feedback/email"
  },
  cloud: {
    jwtToken: "/mca-router/token/exchange/legacy-to-cloud",
    accessTokens: "access_keys_sync",
    request: "/request",
    websocket: "/websocket",
    keys: {
      get: "access_keys_sync"
    },
    bounce: {
      get: "bounce_get"
    },
    controller: {
      report: {
        get: "controller_get_report"
      }
    },
    scenes: {
      get: "scene_list"
    }
  },
  legacy: {
    runScene:
      "/relay/relay/relay/device/{PK_Device}/session/{relayToken}/port_3480/data_request?id=lu_action&serviceId=urn:micasaverde-com:serviceId:HomeAutomationGateway1&action=RunScene&SceneNum={sceneId}"
  },
  abstract: {
    request: "/v1/request",
    list: {
      abstractList: "abstract_list"
    }
  }
});
