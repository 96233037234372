<template>
  <MainPage>
    <GridLayout
      height="100%"
      rows="auto, *, auto"
      columns="*"
      class="new-dashboard"
    >
      <StackLayout row="0" col="0">
        <IBtnHiBtn
          icon1Src="icons/Controls/ic_controls_arrow-left_56.svg"
          @iconTap1="navigateBack()"
          :text="translations.textTitle"
        />
      </StackLayout>
      <ScrollView height="100%" row="1" col="0">
        <NewDashboardOverview
          :texts="translations"
          :templates="getTemplatesPortrait"
          :selectedTemplateId="getSelectedTemplateId('portrait')"
          :isDisabledNext="isDisabled"
          @selectTemplate="handleSelectTemplate"
          @gotoNext="handleGotoNext"
          @onSelectOrientation="handleSelectOrientation"
          @onChangeUseOneTemplate="useOneTemplate"
          :isPortraitCreated="isPortraitCreated"
          :isLandscapeCreated="isLandscapeCreated"
        />
      </ScrollView>
      <StackLayout row="2" col="0" class="new-dashboard__btn">
        <ButtonFilled
          :text="translations.textBtn"
          :disabled="isDisabled"
          @tap="handleGotoNext"
        />
      </StackLayout>
    </GridLayout>
  </MainPage>
</template>
<script>
import { createNamespacedHelpers } from "vuex";
import NewDashboardOverview from "@/components/dashboard/NewDashboardOverview";

const {
  mapActions: mapTemplateActions,
  mapGetters: mapTemplateGetters
} = createNamespacedHelpers("template");

const {
  mapActions: mapDashboardActions,
  mapGetters: mapDashboardGetters
} = createNamespacedHelpers("dashboards");

export default {
  name: "NewDashboard",
  components: {
    NewDashboardOverview
  },
  props: {
    templateMode: {
      type: String,
      default: "both"
    }
  },
  data() {
    return {
      isUsingOneTemplate: this.templateMode !== "both"
    };
  },
  watch: {
    templateMode(newValue) {
      this.isUsingOneTemplate = newValue !== "both";
    }
  },
  computed: {
    ...mapDashboardGetters(["getNewDashboard"]),
    ...mapTemplateGetters(["getSelectedTemplateId", "getTemplatesPortrait"]),
    translations() {
      return this.$t("newDashboard"); // eslint-disable-line
    },
    isDisabled() {
      if (this.isUsingOneTemplate && !this.getSelectedTemplateId("portrait")) {
        return true;
      } else if (
        !this.isUsingOneTemplate &&
        (this.getNewDashboard.portrait === null ||
          this.getNewDashboard.landscape === null)
      ) {
        return true;
      }
      return false;
    },
    isPortraitCreated() {
      return !(this.getNewDashboard.portrait === null);
    },
    isLandscapeCreated() {
      return !(this.getNewDashboard.landscape === null);
    }
  },
  methods: {
    ...mapTemplateActions(["selectTemplate", "setTemplate"]),
    handleSelectTemplate(template) {
      this.selectTemplate({ id: template.id, orientation: "portrait" });
    },
    handleGotoNext() {
      if (this.isUsingOneTemplate) {
        this.setTemplate({ id: this.getSelectedTemplateId("portrait") });
        this.navigateTo("DashboardTemplate", {
          props: { isSingleTemplate: true }
        });
      } else {
        // go to naming page
        this.navigateTo("DashboardName", { backstackVisible: false });
      }
    },
    handleSelectOrientation(orientation) {
      console.log("--->> ### from NewDashboard/handleSelectOrientation to  DashboardTemplateType", orientation); //eslint-disable-line
      this.navigateTo("DashboardTemplateType", {
        props: {
          isEditMode: false,
          isLandscape: orientation === "landscape"
        },
        backstackVisible: false
      });
    },
    useOneTemplate(event) {
      this.isUsingOneTemplate = event;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~/assets/styles/variables.scss";

.new-dashboard {
  height: 100%;
  &__btn {
    margin-bottom: $xl;
    padding: 0 $xl;
  }
}
</style>
