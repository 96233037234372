// Core
import Vue from "vue";
import {
  RESET_UI_STATE,
  SET_CONNECTION_TYPE,
  EMIT_ERROR,
  RESET_ERROR,
  START_FETCHING,
  STOP_FETCHING,
  SAVE_SNACKBAR_TEXT,
  SET_PAGE_LOADING
} from "./mutationTypes";
import InitialState from "./state";
import { helpers } from "ezlo-core-ui";
const { connectionUtils } = helpers;

export default {
  [RESET_UI_STATE](state) {
    const initialState = InitialState();
    for (let prop in initialState) {
      Vue.set(state, prop, initialState[prop]);
    }
  },
  [SET_CONNECTION_TYPE](state, payload) {
    state.connectivity = {
      connectionType: payload,
      offline: payload === connectionUtils.CONNECTION_TYPE.NONE
    };
  },
  [START_FETCHING](state, payload) {
    state.isFetching.push(payload);
  },
  [STOP_FETCHING](state, payload) {
    Vue.set(
      state,
      "isFetching",
      state.isFetching.filter(id => id !== payload)
    );
  },
  [EMIT_ERROR](state, payload) {
    state.error = { ...state.error, ...payload };
  },
  [RESET_ERROR](state) {
    state.error = {
      type: "",
      message: "",
      error: false,
      meta: "",
      code: ""
    };
  },
  [SAVE_SNACKBAR_TEXT](state, { text, type }) {
    state.snackbar = { text, type };
  },
  [SET_PAGE_LOADING](state, payload) {
    state.isPageLoading = payload;
    Vue.set(state, "isPageLoading", payload);
  }
};
