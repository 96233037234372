import {
  ADD_ROOM,
  EDIT_ROOM,
  NEW_ROOM,
  RESET_STATE,
  SAVE_ROOM,
  SET_LOADING_STATE,
  // SET_PROCESSING,
  SET_ROOM,
  SET_ROOMS_STATE,
  SET_SELECTED_ROOM,
  SET_VALUE,
  UNSET_LOADING_STATE,
  SAVE_EDIT_ROOM,
  DELETE_ROOM
} from "./mutationTypes";
import Vue from "@/vue";
import { getInitialRoom, getInitialState } from "./state";

const generateRoom = (room, hub) => ({
  id: room._id,
  src: "images/Rooms/bg-room-1.png",
  name: room.name,
  selected: null,
  data: room,
  controllerId: hub
});
const generateVeraRoom = (room, hub) => ({
  id: hub + "_" + room.id,
  src: "images/Rooms/bg-room-1.png",
  name: room.name,
  selected: null,
  data: room,
  conrollerId: hub
});

export default {
  [RESET_STATE](state) {
    state = { roomsState: getInitialState() };
  },
  [SET_VALUE](state, payload) {
    // eslint-disable-next-line no-unused-vars
    let val = state.roomsState[payload.screenName];
    val = Object.assign(val, payload.key);
  },
  [SET_LOADING_STATE](state, payload) {
    Vue.set(state.roomsState, "loadingStates", {
      ...state.roomsState.loadingStates,
      [payload]: true
    });
  },
  [UNSET_LOADING_STATE](state, payload) {
    Vue.set(state.roomsState, "loadingStates", {
      ...state.roomsState.loadingStates,
      [payload]: false
    });
  },
  [SET_ROOMS_STATE](state, payload) {
    const { isVera, rooms, hub } = payload;
    const newRooms = isVera
      ? rooms.map(room => generateVeraRoom(room, hub))
      : rooms.map(room => generateRoom(room, hub));
    Vue.set(state.roomsState, "rooms", [
      ...state.roomsState.rooms,
      ...newRooms
    ]);
  },
  [SET_SELECTED_ROOM](state, payload) {
    state.roomsState.rooms.forEach(async element => {
      Vue.set(element, "selected", element.id === payload.room.id);
    });
  },
  [SET_ROOM](state, payload) {
    state.roomsState.newRoom = { ...state.roomsState.newRoom, ...payload };
  },
  [NEW_ROOM](state, payload) {
    state.roomsState.newRoom = { ...getInitialRoom(), ...payload };
    state.roomsState.selectRoomName.selectedItemId = 0;
  },
  [ADD_ROOM](state, payload) {
    const id = Math.max(...state.roomsState.rooms.map(item => item.id)) + 1;
    const room = Object.assign(getInitialRoom(), { id, name: payload });
    state.roomsState.rooms.unshift(room);
    state.roomsState.selectRoomName.selectedItemId = id;
  },
  [EDIT_ROOM](state, payload) {
    const room = state.roomsState.rooms.find(
      element => element.id === payload.id
    );
    state.roomsState.newRoom = { ...room };
    state.roomsState.selectRoomName.selectedItemId = room.id;
  },
  [DELETE_ROOM](state, { id }) {
    Vue.set(
      state.roomsState,
      "rooms",
      state.roomsState.rooms.filter(room => {
        return room.id !== id;
      })
    );
  },
  [SAVE_EDIT_ROOM](state, payload) {
    const idx = state.roomsState.rooms.findIndex(
      element => element.id === payload.id
    );
    Vue.set(state.roomsState.rooms, idx, payload);
  },
  [SAVE_ROOM](state, payload) {
    // if not exists then return
    let newRoom = { ...state.roomsState.newRoom };
    if (!newRoom.name) return;
    if (payload) {
      newRoom = { ...newRoom, ...payload };
    }
    if (!newRoom.id) {
      // create new id
      newRoom.id = Math.max(...state.roomsState.rooms.map(item => item.id)) + 1;
      // add as first element
      state.roomsState.rooms.unshift({ ...newRoom });
    } else {
      let room = state.roomsState.rooms.find(item => item.id === newRoom.id);
      if (room) {
        room = Object.assign(room, newRoom);
      }
    }
    state.roomsState.selectRoomName.selectedItemId = newRoom.id;
  }
};
