<template>
  <GridLayout rows="*, auto" columns="*" height="100%">
    <BottomUpDialog ref="calendarDialog" row="0" col="0" rowSpan="2">
      <CalendarCard
        :mode="selectedMode"
        :date="getSelectedDateByType(selectedMode)"
        @change="dateChanged"
        @close="hideCalendar"
      />
    </BottomUpDialog>
    <StackLayout row="0">
      <IBtnHiBtn
        @iconTap1="handleBack"
        icon1Src="icons/Controls/ic_controls_arrow-left_56.svg"
        :text="translations.title"
      />
    </StackLayout>
    <StackLayout row="1" col="0" class="energy-view">
      <Separator size="xl" />
      <HyperlinkLabel
        class="font-txt-16-par energy-view__header-link"
        :text="translations.compatibleDevicesLink"
        :isAllClickable="false"
        @tap="onTapLink"
      />
      <Separator size="xl" />
      <IBtnHiBtn
        headerSize="h3"
        alignText="left"
        :text="dateText"
        icon1Src="icons/Actions/ic_actions_calendar_green_56.svg"
        v-show="!enableFullscreenGraph && !graphLoading"
        @tap="openCalendar"
      />
      <Separator v-show="!graphLoading" size="xl" />
      <DailyAverage
        v-show="!graphLoading"
        class="energy-view__padding"
        :title="getConsumption"
        :titleDesc="getMeasurementUnit"
        :desc1="totalDesc"
        :desc2="statusDesc"
        :showDescIcon="selectedMode === 'live' && !isPausedRealTime"
        @backToLive="backToLive"
      />
      <RowPlaceholder
        lineHeight="16"
        class="row-skeleton row-skeleton__title"
        v-show="graphLoading"
      />
      <Separator v-show="graphLoading" size="xl" />
      <RowPlaceholder
        lineHeight="56"
        hideAvatar
        class="row-skeleton row-skeleton__sub1"
        v-show="graphLoading"
      />
      <RowPlaceholder
        hideAvatar
        class="row-skeleton row-skeleton__sub2"
        v-show="graphLoading"
      />
      <GridLayout rows="*" columns="*" v-show="selectedMode !== 'live'">
        <Chart
          row="0"
          col="0"
          ref="chart"
          class="energy-view__chart"
          :options="getChartOptions"
          :series="getChartSeries"
          :seriesVisibility="seriesVisibility"
          @dataPointSelection="clickDataPoint"
        />
        <Spinner
          row="0"
          col="0"
          v-if="isDatataLoading"
          class="spinner"
          height="100%"
        />
      </GridLayout>
      <StackLayout
        class="energy-view__apexchart-container"
        v-show="selectedMode === 'live'"
      >
        <GridLayout rows="*" columns="*">
          <apexchart
            row="0"
            col="0"
            ref="realTimeChart"
            class="energy-view__apexchart"
            type="area"
            height="166"
            :options="chartOptionsRealTime"
            :series="liveSeries"
          />
          <Spinner
            row="0"
            col="0"
            v-if="isDatataLoading"
            class="spinner"
            height="100%"
          />
        </GridLayout>
        <HourSlider
          ref="hourSlider"
          class="energy-view__hour-slider"
          :values="hourSliderValues"
          :valueTitles="getHourSliderValueTitles"
          :initialValue="selectedHourIndex"
          @valueChange="hourSliderValueChange"
        />
      </StackLayout>
      <ModeSelector
        v-show="!enableFullscreenGraph"
        @modeTap="changeMode"
        :modes="modeNames"
        :activeIndex="activeIndex"
      />
      <MonthlyConsumption
        v-if="
          selectedMode !== 'live' && !enableFullscreenGraph && !graphLoading
        "
        class="energy-view__montly"
        :consumption="getConsumption"
        :averageConsumption="averageConsumption"
        :measurementUnit="getMeasurementUnit"
        :peviousConsumption="getPreviousConsumption"
        :titleDesc="currentModeTranslation.average"
        :montlyDesc="currentModeTranslation.from"
      />
      <RowPlaceholder
        hideAvatar
        class="row-skeleton row-skeleton__warning"
        v-show="graphLoading"
      />
      <RowPlaceholder
        lineHeight="8"
        hideAvatar
        class="row-skeleton row-skeleton__warning-2"
        v-show="graphLoading"
      />
      <IconHTxtiBtn
        class="energy-view__live-warning"
        v-if="
          selectedMode === 'live' && !enableFullscreenGraph && !graphLoading
        "
        icon1Src="icons/Controls/ic_controls_info_56.svg"
        :text="currentModeTranslation.warning"
        headerSize="s"
        alignText="left"
        :hover="false"
      />
      <DeviceFilter
        v-show="!enableFullscreenGraph && !graphLoading"
        class="energy-view__filter"
        :title="currentModeTranslation.usage"
        :titleDesc="getTotalConsumptionDesc"
        :button="!isFiltering ? translations.filter : getFilterStatus"
        @actionTap="filter"
      />
      <Separator v-show="!enableFullscreenGraph && !graphLoading" size="xl" />
      <StackLayout
        v-show="!enableFullscreenGraph"
        :key="device.id"
        v-for="device of deviceList"
      >
        <ConsumptionRate
          v-if="!graphLoading"
          :title="device.title"
          :desc="device.desc"
          :src="device.src"
          :rate="device.rate"
          :barColor="device.barColor"
          :checkboxValue="device.checkboxValue"
          :consumption="device.consumption"
          :disabled="device.rate == 0"
          :unit="getMeasurementUnit"
          @tap="onTap(device.id)"
          :showCheckbox="device.showCheckbox"
          @change="onFilterValueChanged($event, device.id)"
        />
        <RowPlaceholder
          lineHeight="16"
          class="row-skeleton row-skeleton__list"
          v-else
        />
        <Separator size="xl" />
      </StackLayout>
      <!-- <EnergyGoals
        v-show="!enableFullscreenGraph"
        class="energy-view__goals"
        :goals="getGoals"
        :loading="goalsLoading"
      /> -->
      <!-- <Separator v-show="!enableFullscreenGraph" size="xl" /> -->
    </StackLayout>
  </GridLayout>
</template>

<script>
import moment from "moment";
import { locals } from "../../assets/styles/variables.scss";
import DailyAverage from "./DailyAverage";
import MonthlyConsumption from "./MonthlyConsumption";
import ConsumptionRate from "./ConsumptionRate";
import { createNamespacedHelpers } from "vuex";
import DeviceFilter from "./DeviceFilter";
import ModeSelector from "./ModeSelector";
// import EnergyGoals from "./EnergyGoals";
import {
  liveLabelFormatter,
  dayLabelFormatter,
  weekLabelFormatter,
  monthLabelFormatter
} from "@/helpers/chartUtils";
import { round } from "@/helpers/utils";
import { capitalizeFirstChar } from "@/helpers/string";
import { orderBy, isEqual } from "lodash";

const { mapGetters: mapUiGetters } = createNamespacedHelpers("ui");
const { mapGetters: mapItemsGetters } = createNamespacedHelpers("items");

const {
  mapGetters: mapEnergyGetters,
  mapActions: mapEnergyActions
} = createNamespacedHelpers("energy");

export default {
  name: "EnergyOverview",
  components: {
    DailyAverage,
    MonthlyConsumption,
    ConsumptionRate,
    DeviceFilter,
    ModeSelector
    // EnergyGoals
  },
  props: {
    energy: {
      type: Object,
      default: () => ({})
    },
    graphImage: {
      type: String,
      default: "~/assets/images/Energy/Month-30-Days.png"
    },
    iconUnknown: {
      type: String,
      default: "icons/Category/ic_category_other_56.svg"
    },
    iconClimate: {
      type: String,
      default: "icons/Category/ic_category_climate_56.svg"
    },
    iconAppliance: {
      type: String,
      default: "icons/Category/ic_category_appliances_56.svg"
    },
    iconLighting: {
      type: String,
      default: "icons/Category/ic_category_lighting_56.svg"
    },
    activeIndex: {
      type: Number,
      default: 0
    },
    modes: {
      type: Array,
      default: () => []
    },
    enableFullscreenGraph: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      compatibleDevicesLink:
        "https://constellationconnect.com/LP/energydevices",
      selectedDate: new Date(),
      isFiltering: false,
      chart: null,
      hourSliderValues: 4,
      selectedHourIndex: 2,
      hourSliderValueTitles: "",
      currentHour: moment().hour(),
      series: [],
      liveSeries: [
        {
          name: "Consumption",
          data: []
        }
      ],
      chartOptionsRealTime: {
        chart: {
          id: "chartArea",
          toolbar: {
            autoSelected: "pan",
            show: false
          },
          animations: {
            enabled: true,
            easing: "linear",
            dynamicAnimation: {
              speed: 1000
            }
          },
          events: {
            dataPointSelection: this.dataPointSelection,
            animationEnd: this.animationEnd,
            click: this.clickDataPoint
          }
        },
        tooltip: {
          y: {
            formatter: function(
              value,
              { series, seriesIndex, dataPointIndex, w }
            ) {
              return value;
            }
          }
        },
        colors: ["#00B491"],
        stroke: {
          width: 1,
          curve: "straight"
        },
        dataLabels: {
          enabled: false
        },
        fill: {
          opacity: 0.15
        },
        markers: {
          size: 0
        },
        legend: {
          show: false
        },
        noData: {
          text: "No data"
        },
        markers: {
          size: 0,
          hover: {
            size: 6
          }
        },
        xaxis: {
          type: "datetime",
          range: 3000000,
          labels: {
            datetimeUTC: false
          }
        },
        yaxis: {
          decimalsInFloat: 4,
          opposite: true,
          labels: {
            offsetX: -10
          },
          datetimeFormatter: {
            year: "yyyy",
            month: "MMM 'yy",
            day: "dd MMM",
            hour: "HH:mm"
          }
        }
      },
      chartOptions: {
        chart: {
          type: "bar",
          height: 230,
          stacked: true,
          toolbar: {
            show: false,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: false,
              selection: true,
              zoom: true,
              zoomin: false,
              zoomout: false,
              pan: true,
              reset: false,
              customIcons: []
            },
            autoSelected: "pan"
          }
        },
        stroke: {
          width: 1,
          curve: "smooth"
        },
        dataLabels: {
          enabled: false
        },
        states: {
          normal: {
            filter: {
              type: "none",
              value: 0
            }
          },
          hover: {
            filter: {
              type: "lighten",
              value: 0.15
            }
          },
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: "darken",
              value: 0.35
            }
          }
        },
        xaxis: {
          type: "category",
          labels: {
            rotate: 0
            // formatter: dayLabelFormatter
          },
          categories: []
        },
        noData: {
          text: "Loading..."
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          labels: {
            show: true
          }
        },
        legend: {
          show: false
        },
        fill: {
          opacity: 1
        },
        colors: this.getColors
      },
      realTimeChart: null,
      isDataPointSelected: {
        live: false,
        day: false,
        week: false,
        month: false
      },
      selectedData: {},
      liveDataBuff: []
    };
  },
  created() {
    this.setHourSlider();
    setInterval(() => {
      this.currentHour = moment().hour();
    }, 1000);
  },
  mounted() {
    this.realTimeChart = this.$refs.realTimeChart;
    this.chartOptions.colors = this.getColors;
  },
  watch: {
    isPausedRealTime(newValue, oldValue) {
      if (this.selectedMode === "live" && !newValue && oldValue) {
        this.updateLiveSeries({ value: this.getLiveSeries });
      }
    },
    getLiveSeries(value) {
      if (
        this.selectedMode === "live" &&
        (!this.isPausedRealTime || !this.liveDataActive)
      ) {
        this.updateLiveSeries({ value, animate: true });
      }
    },
    itemsChanges(newValue, oldValue) {
      console.log("### ---> itemsChanges:", {newValue, oldValue}); // eslint-disable-line
      this.setLiveDataBuff(newValue);
    },
    getSelectedDates(newValue, oldValue) {
      if (!isEqual(newValue[this.selectedMode], oldValue[this.selectedMode])) {
        if (
          (this.selectedMode === "live" && !this.liveDataActive) ||
          this.selectedMode !== "live"
        ) {
          this.fetchEnergyData({
            mode: this.selectedMode,
            selectedDates: this.getSelectedDates[this.selectedMode]
          });
        }
      }
    },
    graphLoading(value) {
      const options = {
        noData: {
          text: value ? "Loading..." : "No data"
        }
      };
      this.$refs.realTimeChart.updateOptions(options);
    },
    selectedMode(newValue, oldValue) {
      if (newValue === "live" && newValue !== oldValue) {
        setTimeout(() => {
          this.updateLiveSeries({ value: this.getLiveSeries });
        }, 0);
      }
    },
    liveDataActive(newValue) {
      const options = {
        colors: [newValue ? "#00B491" : "#90A0AF"],
        xaxis: {
          range: newValue ? 3000000 : undefined
        }
      };
      this.isDataPointSelected[this.selectedMode] = false;
      this.$refs.realTimeChart.updateOptions(options);
    },
    enableFullscreenGraph(value) {
      this.setHourSlider();
    },
    currentHour(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.setHourSlider(this.selectedHourIndex);
      }
    }
  },
  computed: {
    ...mapUiGetters(["isFetching"]),
    ...mapItemsGetters(["getItemChanges"]),
    ...mapEnergyGetters([
      "getColors",
      "getColorByIndex",
      "getGoals",
      "getEnergyDevices",
      "isInFilter",
      "getFilteredDevices",
      "getGraphOptions",
      "getSelectedDateByType",
      "getSeriesVisibility",
      "getTotalConsumpsionByDeviceId",
      "getTotalConsumptionByMode",
      "getAverageConsumptionByMode",
      "getPreviousConsumptionByMode",
      "getSeries",
      "getDeviceById",
      "getSelectedDates",
      "getLatestLiveDataValue",
      "getLatestLiveDataValueByDeviceId",
      "getLatestTotalConsumption"
    ]),
    itemsChanges() {
      return this.getItemChanges({ name: "electric_meter_watt" });
    },
    deviceList() {
      let devices = this.getEnergyDevices.map((device, index) => {
        const { dataPointIndex, seriesIndex, data } =
          this.isDataPointSelected[this.selectedMode] &&
          this.selectedData[this.selectedMode];
        let consumption = 0;
        let totalConsumption = 0;
        const options = {
          id: device.id,
          mode: this.selectedMode,
          dataPointIndex,
          seriesIndex,
          dataPoint: data,
          liveDataActive: this.liveDataActive
        };
        if (this.selectedMode === "live" && !this.isPausedRealTime) {
          consumption = this.getLatestLiveDataValueByDeviceId(options);
          totalConsumption = this.getLatestTotalConsumption({
            mode: options.mode,
            liveDataActive: options.realTimeData,
            deviceIds:
              this.getFilteredDevices.length > 0
                ? this.getFilteredDevices
                : null
          });
        } else {
          consumption = this.getTotalConsumpsionByDeviceId(options);
          totalConsumption = this.getTotalConsumptionByMode({
            mode: this.selectedMode,
            dataPointIndex,
            seriesIndex,
            dataPoint: data,
            liveDataActive: this.liveDataActive
          });
        }
        const rate =
          Number(((consumption / totalConsumption) * 100).toFixed(3)) || 0;
        return {
          ...device,
          title: consumption === 0 ? "--" : String(rate).concat("%"),
          desc: device.name,
          consumption,
          rate,
          // barColor:  device.barcolor || this.getColorByIndex(index),
          showCheckbox: rate !== 0 && this.isFiltering,
          checkboxValue: this.isInFilter(device.id)
        };
      });

      // keep color order.
      devices = orderBy(devices, ["consumption", "name"], ["desc", "asc"]);
      devices = devices.map((device, index) => {
        return {
          ...device,
          barColor: device.barcolor || this.getColorByIndex(index)
        };
      });
      return devices;
    },
    isPausedRealTime() {
      return this.isDataPointSelected.live || !this.liveDataActive;
    },
    liveDataActive() {
      return (
        this.selectedMode === "live" &&
        this.selectedHourIndex === this.hourSliderValues - 2
      );
    },
    getFilterStatus() {
      return !!this.getFilteredDevices.length
        ? this.translations.reset
        : this.translations.cancel;
    },
    modeNames() {
      // Gets mode names according to translation language
      return this.modes.map(mode => this.$t("energy")[mode].name);
    },
    translations() {
      return this.$t("energy");
    },
    currentModeTranslation() {
      // Gets according translation object for each mode
      return this.translations[this.selectedMode];
    },
    selectedMode() {
      // Gets mode name from state
      return this.modes[this.activeIndex];
    },
    getTotalConsumptionDesc() {
      const total =
        this.selectedMode === "live"
          ? this.isPausedRealTime
            ? this.getSelectedDataPointConsumption.value || 0
            : this.getLatestLiveSeriesValue
          : this.getTotalConsumptionByMode({
              mode: this.selectedMode,
              liveDataActive: this.liveDataActive
            });
      // return `Total: ${total.toFixed(2)} ${this.getMeasurementUnit}`;
      return `Total: ${(this.selectedMode === "live"
        ? total
        : total / 1000
      ).toFixed(3)} ${this.getMeasurementUnit}`;
    },
    getLatestLiveSeriesValue() {
      return this.getLatestTotalConsumption({
        mode: "live",
        liveDataActive: true,
        deviceIds:
          this.getFilteredDevices.length > 0 ? this.getFilteredDevices : null
      });
    },
    getSelectedDataPointConsumption() {
      const { dataPointIndex, seriesIndex, data } =
        this.isDataPointSelected[this.selectedMode] &&
        this.selectedData[this.selectedMode];
      return { dataPointIndex, seriesIndex, data, value: data ? data[1] : 0 };
    },
    getConsumption() {
      // if the devices are filtered; Only belong to these devices consumption information.npm run lint --fix
      const { dataPointIndex, seriesIndex, data } =
        this.isDataPointSelected[this.selectedMode] &&
        this.selectedData[this.selectedMode];
      if (this.selectedMode === "live") {
        if (data) {
          return data[1] || 0;
        } else {
          return this.getLatestLiveSeriesValue;
        }
      }

      const total = this.getTotalConsumptionByMode({
        mode: this.selectedMode,
        deviceIds:
          this.getFilteredDevices.length > 0 ? this.getFilteredDevices : null,
        dataPointIndex,
        seriesIndex,
        dataPoint: data
      });
      return total || 0; //kWh
    },
    averageConsumption() {
      const average = this.getAverageConsumptionByMode({
        mode: this.selectedMode,
        deviceIds:
          this.getFilteredDevices.length > 0 ? this.getFilteredDevices : null
      });
      return average || 0; //kWh
    },
    getMeasurementUnit() {
      return this.selectedMode === "live" ? "watts" : "kWh";
    },
    getPreviousConsumption() {
      const previous = this.getPreviousConsumptionByMode({
        mode: this.selectedMode,
        deviceIds:
          this.getFilteredDevices.length > 0 ? this.getFilteredDevices : null
      });
      return previous / 1000 || 0; //kWh
    },
    totalDesc() {
      let text =
        this.selectedMode === "live"
          ? this.currentModeTranslation.name
          : this.currentModeTranslation.total;

      if (this.isDataPointSelected[this.selectedMode]) {
        switch (this.selectedMode) {
          case "live":
            let time = moment(this.selectedData[this.selectedMode].data[0]);
            text = `${moment(time).format("hh:mma")}  ${
              this.translations.total
            }`;
            break;
          case "day":
            const hourOfDay = this.selectedData[this.selectedMode].data[0];
            text = `${moment()
              .hour(hourOfDay)
              .format("hha")}  ${this.translations.total}`;
            break;
          case "week":
            const dayOfWeek = this.selectedData[this.selectedMode].data[0];
            text = `${moment()
              .day(dayOfWeek)
              .format("dddd")}`;
            break;
          default:
            const dayOfMonth = this.selectedData[this.selectedMode].data[0];
            text = `${moment(
              this.getSelectedDateByType(this.selectedMode).end
            ).format("MMMM")} ${dayOfMonth + 1}`;
            break;
        }
      } else if (this.getFilteredDevices.length > 0) {
        if (this.getFilteredDevices.length > 1) {
          text = `${capitalizeFirstChar(this.selectedMode)}: ${
            this.getFilteredDevices.length
          } Devices`;
        } else {
          text = this.getDeviceById(this.getFilteredDevices[0]).name;
        }
      }
      return text;
    },
    statusDesc() {
      return this.isDataPointSelected[this.selectedMode] ||
        (this.selectedMode === "live" && this.isPausedRealTime)
        ? this.translations[this.selectedMode].total
        : "";
    },
    colorUnknown() {
      return "#90a0af";
    },
    colorAppliance() {
      return "#3e46ae";
    },
    colorLighting() {
      return "#fe5c15";
    },
    colorClimate() {
      return "#50c1d6";
    },
    getChartSeries() {
      if (this.selectedMode === "live") return;
      return this.getSeries({
        mode: this.selectedMode,
        liveDataActive: this.liveDataActive
      });
    },
    getLiveSeries() {
      return this.getSeries({
        mode: "live",
        liveDataActive: this.liveDataActive,
        deviceIds:
          this.getFilteredDevices.length > 0 ? this.getFilteredDevices : []
      });
    },
    seriesVisibility() {
      return this.getSeriesVisibility({
        mode: this.selectedMode,
        liveDataActive: this.liveDataActive
      });
    },
    getChartOptions() {
      if (this.selectedMode === "live") return;
      const chartOptions = {
        ...this.chartOptions,
        noData: {
          text: this.graphLoading ? "Loading..." : "No data"
        }
      };
      return {
        ...chartOptions,
        ...this.getGraphOptions(this.selectedMode)
      };
    },
    dateText() {
      let dateSpan = this.getSelectedDateByType(this.selectedMode);
      if (this.selectedMode === "week") {
        return (
          moment(dateSpan.start).format("MMM D") +
          "-" +
          moment(dateSpan.end).format("D YYYY")
        );
      } else if (this.selectedMode === "month") {
        return moment(dateSpan.end).format("MMMM YYYY");
      } else if (this.selectedMode === "day" || this.selectedMode === "live") {
        let text = moment(dateSpan.end).isSame(moment(), "d")
          ? this.translations.calendar.today
          : "";
        return text + moment(dateSpan.end).format("MMMM D");
      }

      return this.translations.calendar.noDate;
    },
    isDatataLoading() {
      const { data } = this.getLiveSeries[0];
      return this.selectedMode === "live"
        ? !data || data.length === 0
        : this.graphLoading;
    },
    graphLoading() {
      return this.isFetching(this.selectedMode);
    },
    goalsLoading() {
      return this.isFetching("fetchGoals");
    },
    getValueTitles() {
      let hours = [];
      // for example: when the current time is 2pm
      // return values must be ["12pm", "1pm", "2pm", "3pm"]
      const firstHour = this.enableFullscreenGraph
        ? this.hourSliderValues
        : this.hourSliderValues - 2;

      for (let i = firstHour; i > -2; i--) {
        const hour = moment()
          .subtract(i, "hour")
          .format("ha");
        const dateTime = moment()
          .subtract(i, "hour")
          .startOf("hour")
          .valueOf();
        hours.push({ hour, dateTime });
      }
      return hours;
    },
    getHourSliderValueTitles() {
      return this.hourSliderValueTitles.map(time => {
        return time.hour;
      });
    },
    getCompatibleDevicesLink() {
      return this.$t("energy.compatibleDevicesLink", { compatibleDevicesLink });
    }
  },
  methods: {
    ...mapEnergyActions([
      "addDeviceToFilter",
      "removeDeviceFromFilter",
      "resetFilter",
      "setSelectedDate",
      "setLiveData",
      "fetchEnergyData"
    ]),
    backToLive() {
      this.isDataPointSelected[this.selectedMode] = false;
      this.$refs.realTimeChart.resetSeries();
      if (!this.liveDataActive) {
        this.selectedHourIndex = this.hourSliderValues - 2;
      }
    },
    setLiveDataBuff(data) {
      const newliveData = {
        deviceId: data.deviceId,
        data: [data.createdAt, data.value]
      };

      this.liveDataBuff.push(newliveData);
    },
    checkLiveDataBuff(secondsDifference = 30) {
      const chartRef = this.$refs.realTimeChart;
      const currentLiveSeriesData = chartRef?.chart?.w?.config?.series[1]?.data;
      if (this.liveDataBuff.length > 0) {
        const totals = [];
        this.liveDataBuff.forEach((device, index) => {
          if (
            moment().diff(device.data[0], "seconds") >= secondsDifference ||
            currentLiveSeriesData?.length === 0
          ) {
            const totalIndex = totals.findIndex(
              device => device.deviceId === device.deviceId
            );
            if (totalIndex !== -1) {
              totals[totalIndex].data[1] += device.data[1];
            } else {
              totals.push(device);
            }
            this.liveDataBuff.splice(index, 1);
          }
        });

        let currTime = new Date(
          chartRef?.chart?.w?.globals?.maxX + 30000
        ).getTime(); // 30 seconds
        const data1 = [...chartRef?.chart?.w?.config?.series[0].data];
        totals.forEach(item => {
          const intersection =
            chartRef?.chart?.w?.config?.series[1].data.length === 0 &&
            data1.length > 0
              ? [data1[data1.length - 1]]
              : [];
          const newData = [currTime || moment().valueOf(), item.data[1]];
          this.setLiveData({
            deviceId: item.deviceId,
            data: [...intersection, ...currentLiveSeriesData, newData]
          });
        });
      }
    },
    watchLiveData(period = 2000) {
      setInterval(() => {
        this.checkLiveDataBuff();
      }, period);
    },
    onTap(event) {
      if (!this.graphLoading && !this.goalsLoading) {
        this.$emit("tap", event);
      }
    },
    changeMode(index) {
      this.$emit("changeMode", index);
    },
    filter() {
      if (!this.graphLoading && !this.goalsLoading) {
        if (!!this.getFilteredDevices.length) {
          // reset filter state
          this.resetFilter();
        } else {
          this.isFiltering = !this.isFiltering;
        }
      }
    },
    clickDataPoint(event, chartContext, config) {
      const { dataPointIndex, seriesIndex } = config;
      const { series } = chartContext.w.config;
      this.isDataPointSelected[this.selectedMode] = seriesIndex !== -1;
      if (seriesIndex == -1) return;
      let data = [];
      if (Array.isArray(series[seriesIndex].data[dataPointIndex])) {
        data = [...series[seriesIndex].data[dataPointIndex]];
      } else {
        data = [dataPointIndex, series[seriesIndex].data[dataPointIndex]];
      }

      this.$set(this.selectedData, this.selectedMode, {
        dataPointIndex,
        seriesIndex,
        series,
        data
      });
      console.log("### ---> clickDataPoint data:", { dataPointIndex, seriesIndex, pause: this.isPausedRealTime, selectedData: this.selectedData }); //eslint-disable-line
    },
    animationEnd(chartContext) {
      console.log("### ---> animationEnd:", chartContext); //eslint-disable-line
    },
    labelFormatter(value) {
      const timeMap = {
        0: "12",
        6: "6",
        12: "12",
        18: "6"
      };
      const ampm = Number(value) < 12 ? "am" : "pm";
      return timeMap[Number(value)] ? timeMap[Number(value)] + ampm : "";
    },
    onFilterValueChanged(value, deviceId) {
      if (!value) {
        this.addDeviceToFilter(deviceId);
      } else {
        this.removeDeviceFromFilter(deviceId);
      }
    },
    openCalendar() {
      if (!this.graphLoading && !this.goalsLoading) {
        this.$refs.calendarDialog.show();
      }
    },
    hideCalendar() {
      if (!this.graphLoading && !this.goalsLoading) {
        this.$refs.calendarDialog.hide();
      }
    },
    dateChanged(event) {
      this.setSelectedDate(event);
      this.hideCalendar();
    },
    handleBack() {
      this.$emit("handleBack");
    },
    hourSliderValueChange(event) {
      if (event.index === this.hourSliderValues - 1) {
        this.$refs.hourSlider.setPosition(this.selectedHourIndex);
        this.$emit("noEnergyData");
        return;
      }
      this.selectedHourIndex = event.index;
      const start =
        this.hourSliderValueTitles.find(item => item.hour === event.value)[
          "dateTime"
        ] || moment(event.value, "hA").valueOf();
      const time = {
        type: "live",
        data: {
          start,
          end: moment().valueOf()
        }
      };
      console.log("### ---> hourSliderValueChange:", { event, time }); //eslint-disable-line
      this.setSelectedDate(time);
    },
    updateLiveSeries({ value, animate = false }) {
      const series = value || this.getLiveSeries;
      if (this.selectedMode === "live") {
        this.$refs.realTimeChart.updateSeries(
          [
            {
              data: series[0].data
            }
          ],
          animate
        );
      }
    },
    setHourSlider(selectedHourIndex) {
      this.hourSliderValues = this.enableFullscreenGraph
        ? Math.max(moment().hour(), 4)
        : 4;
      this.hourSliderValueTitles = this.getValueTitles;
      this.selectedHourIndex = selectedHourIndex || this.hourSliderValues - 2;
    },
    onTapLink() {
      if (process.env.VUE_APP_MODE !== "web") {
        this.$showModal("WebViewModal", {
          fullscreen: true,
          props: {
            url: this.compatibleDevicesLink
          }
        });
      } else {
        window.open(this.compatibleDevicesLink, "_blank");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~/assets/styles/variables.scss";

.energy-view {
  &__desc {
    color: $color-grey;
  }
  &__desc-status {
    color: $color-primary;
  }
  &__padding {
    padding: 0 $padding-xl $m $padding-xl;
  }
  &__graph {
    padding: 0 $padding-xl $padding-xl $padding-xl;
  }
  &__montly {
    padding: $padding-xl $padding-xl $m $padding-xl;
  }
  &__filter {
    margin-top: $padding-xl;
  }
  &__goals {
    padding: $xxxl $xl $xl $xl;
  }
  &__live-warning {
    margin: $xl 0 $lmx 0;
    padding-bottom: $xxs;
    /deep/ * {
      color: $color-grey !important;
    }
  }
  &__chart {
    margin: 0 $margin-m;
    grid-row: 1;
    grid-column: 1;
  }
  &__apexchart-container {
    margin: 0 $margin-m;
  }
  &__apexchart {
    grid-row: 1;
    grid-column: 1;
  }
  &__hour-slider {
    padding-bottom: $xxmm;
  }
  &__header {
    margin: $margin-l $margin-xl;
  }
  &__header-link {
    margin: $margin-l $margin-xl;
    text-align: left;
    align-self: flex-start;
    /deep/ & .hyperlink-label-group__wrap {
      justify-content: left;
    }
  }
}

.row-skeleton {
  margin: 0;
  padding: 0;
  &__title {
    width: 50%;
  }
  &__warning {
    margin-top: $xl !important;
    width: 70%;
  }
  &__warning-2 {
    width: 60%;
    padding: $m;
    margin-bottom: $xl;
    /deep/ & .row-skeleton__text__cont {
      padding: 0;
    }
  }
  &__sub1 {
    height: $xxxll;
    width: 50%;
  }
  &__sub2 {
    margin: m;
    height: $xxxll;
  }
  &__list {
    /deep/ & .row-skeleton__text__cont {
      padding: 0;
      margin: $lmx $xl 0 0;
    }
  }
}
.spinner {
  grid-row: 1;
  grid-column: 1;
}
</style>
