/**
  Usage:
  1. Register error type
  EX:
  errorConnectionLost: {
    type: "errorConnectionLost",
    component: "ErrorDialog",
    props: {
      icon: "icons/Visuals/ic_warning_visuals_not-responding_200.svg",
      text1: "core.errorDialogConnectionLost.title",
      text2: "core.errorDialogConnectionLost.description",
      actionText: "core.errorDialogConnectionLost.actionText",
      warning: true
    }
  }
  2. Pass "type" keyword when you dispatch emitError. 
  EX:
  dispatch(
    "ui/emitError",
    {
      type: "errorRequestConnection",
      error: error,
      meta: "createNewPassword"
    },
    {
      root: true
    }
  );
  3. To have errorDialog available on page you need to wrap that page with MainPage component
  4. To have errorDialog available on any kind of component you need to call the action emitError
  5. "component" keyword can take any kind of custom dialog, component, not just ErrorDialog
  6. By default we show defaultError dialog with "Sorry that didn't go through" text
 */

const errorTypes = {
  defaultError: {
    type: "defaultError",
    component: "ErrorDialog",
    props: {
      icon: "icons/Visuals/ic_warning_visuals_not-responding_200.svg",
      text1: "core.errorDialogConnection.title",
      text2: "core.errorDialogConnection.description",
      actionText: "core.errorDialogConnection.actionText",
      warning: true
    }
  },
  errorConnectionLost: {
    type: "errorConnectionLost",
    component: "ErrorDialog",
    props: {
      icon: "icons/Visuals/ic_warning_visuals_not-responding_200.svg",
      text1: "core.errorDialogConnectionLost.title",
      text2: "core.errorDialogConnectionLost.description",
      actionText: "core.errorDialogConnectionLost.actionText",
      warning: true
    }
  },
  errorSigninCredentials: {
    type: "errorSigninCredentials",
    meta: "loginAsync",
    code: 404,
    component: "ErrorDialog",
    props: {
      icon: "icons/Visuals/ic_warning_visuals_not-responding_200.svg",
      text1: "core.errorDialogSigninCredentials.title",
      text2: "core.errorDialogSigninCredentials.desc",
      actionText: "core.errorDialogSigninCredentials.btn",
      warning: true
    }
  },
  errorSigninAttempts: {
    type: "errorSigninAttempts",
    meta: "loginAsync",
    code: 603,
    component: "ErrorDialog",
    props: {
      icon: "icons/Visuals/ic_warning_visuals_not-responding_200.svg",
      text1: "core.errorDialogSigninAttempts.title",
      text2: "core.errorDialogSigninAttempts.desc",
      actionText: "core.errorDialogSigninAttempts.btn",
      warning: true
    }
  },
  errorAvailableHubs: {
    type: "errorAvailableHubs",
    component: "ErrorDialog",
    props: {
      icon: "icons/Visuals/ic_warning_visuals_not-responding_200.svg",
      text1: "core.errorAvailableHubs.title",
      text2: "core.errorAvailableHubs.desc",
      actionText: "core.errorAvailableHubs.btn",
      warning: true
    }
  },
  errorAvailableEzloHubs: {
    type: "errorAvailableEzloHubs",
    component: "ErrorDialog",
    props: {
      icon: "icons/Visuals/ic_warning_visuals_not-responding_200.svg",
      text1: "core.errorAvailableEzloHubs.title",
      text2: "core.errorAvailableEzloHubs.desc",
      actionText: "core.errorAvailableEzloHubs.btn",
      warning: true
    }
  },
  errorRequestConnection: {
    type: "errorRequestConnection",
    component: "ErrorDialog",
    props: {
      icon: "icons/Visuals/ic_warning_visuals_not-responding_200.svg",
      text1: "core.errorDialogConnection.title",
      text2: "core.errorDialogConnection.description",
      actionText: "core.errorDialogConnection.actionText",
      warning: true
    }
  },
  errorCurrentPassword: {
    type: "errorCurrentPassword",
    component: "ErrorDialog",
    meta: "updatePasswordAsync",
    code: 403,
    props: {
      icon: "icons/Visuals/ic_warning_visuals_not-responding_200.svg",
      text1: "core.errorDialogInvalidCurrentPWD.title",
      text2: "core.errorDialogInvalidCurrentPWD.desc",
      actionText: "core.errorDialogInvalidCurrentPWD.btn",
      warning: true
    }
  },
  errorDialogNotEzlo: {
    type: "errorDialogNotEzlo",
    component: "ErrorDialog",
    props: {
      icon: "icons/Visuals/ic_warning_visuals_not-responding_200.svg",
      text1: "core.errorDialogNotEzlo.title",
      text2: "core.errorDialogNotEzlo.description",
      actionText: "core.errorDialogNotEzlo.actionText",
      warning: true
    }
  },
  errorAvailableDevices: {
    type: "errorAvailableDevices",
    component: "ErrorDialog",
    props: {
      icon: "icons/Visuals/ic_warning_visuals_not-responding_200.svg",
      text1: "core.errorAvailableDevices.title",
      text2: "core.errorAvailableDevices.desc",
      actionText: "core.errorAvailableDevices.btn",
      warning: true
    }
  },
  errorLinkValid: {
    type: "errorLinkValid",
    component: "ErrorLinkValid"
  },
  moreOptionsAreComingSoon: {
    type: "moreOptionsAreComingSoon",
    component: "ErrorDialog",
    props: {
      icon: "icons/Visuals/ic_visuals_history_coming-soon_200.svg",
      text1: "core.moreOptionsAreComingSoon.title",
      text2: "core.moreOptionsAreComingSoon.description",
      actionText: "core.moreOptionsAreComingSoon.actionText",
      warning: false
    }
  },
  disableMonitoring: {
    type: "disableMonitoring",
    component: "DeviceEnergyDetails",
    props: {
      icon: "icons/Visuals/ic_visuals_history_coming-soon_200.svg",
      title: "core.disableMonitoring.title",
      description: "core.disableMonitoring.description",
      btnTextAccept: "core.disableMonitoring.btnTextAccept",
      btnTextCancel: "core.disableMonitoring.btnTextCancel",
      warning: true,
      resultActionName: ""
    }
  },
  errorDialogNoEnergyData: {
    type: "errorDialogNoEnergyData",
    component: "ErrorDialog",
    props: {
      icon: "icons/Warnings/ic_warning_200.svg",
      text1: "core.errorDialogNoEnergyData.title",
      text2: "core.errorDialogNoEnergyData.description",
      actionText: "core.errorDialogNoEnergyData.actionText",
      warning: true
    }
  },
  leaveWithoutChanges: {
    type: "leaveWithoutChanges",
    component: "LeaveWithoutChanges",
    props: {
      icon: "icons/Visuals/ic_warning_logout_200.svg",
      title: "core.leaveWithoutChanges.title",
      description: "core.leaveWithoutChanges.description",
      btnTextAccept: "core.leaveWithoutChanges.btnTextAccept",
      btnTextCancel: "core.leaveWithoutChanges.btnTextCanel",
      warning: true,
      resultActionName: ""
    }
  },
  themeHasBeenChanged: {
    type: "themeHasBeenChanged",
    component: "ErrorDialog",
    props: {
      icon: "icons/Warnings/ic_warning-primary_200.svg",
      text1: "core.leaveWithoutChanges.title",
      text2: "core.themeHasBeenChanged.description",
      actionText: "core.themeHasBeenChanged.btnTextAccept",
      btnTextCancel: "core.themeHasBeenChanged.btnTextCancel",
      warning: false,
      closeButton: true,
      closeButtonIcon: "icons/Controls/ic_controls_close_56.svg",
      resultActionName: "themeHasBeenChanged"
    }
  },
  deleteTheme: {
    type: "deleteTheme",
    component: "ErrorDialog",
    props: {
      icon: "icons/Warnings/ic_warning_delete_200.svg",
      text1: "core.deleteTheme.title",
      text2: "core.deleteTheme.description",
      actionText: "core.deleteTheme.btnTextAccept",
      btnTextCancel: "core.deleteTheme.btnTextCancel",
      warning: true,
      closeButton: true,
      closeButtonIcon: "icons/Controls/ic_controls_close_56.svg",
      resultActionName: "deleteTheme"
    }
  },
  bothModesToOneMode: {
    type: "bothModesToOneMode",
    component: "ErrorDialog",
    props: {
      icon: "icons/Warnings/ic_warning_200.svg",
      text1: "core.bothModesToOneMode.title",
      text2: "core.bothModesToOneMode.description",
      actionText: "core.bothModesToOneMode.btnTextAccept",
      btnTextCancel: "core.bothModesToOneMode.btnTextCancel",
      warning: true,
      closeButton: true,
      closeButtonIcon: "icons/Controls/ic_controls_close_56.svg",
      resultActionName: "bothModesToOneMode"
    }
  },
  oneModeToBothModes: {
    type: "oneModeToBothModes",
    component: "ErrorDialog",
    props: {
      icon: "icons/Warnings/ic_warning_200.svg",
      text1: "core.oneModeToBothModes.title",
      text2: "core.oneModeToBothModes.description",
      actionText: "core.oneModeToBothModes.btnTextAccept",
      btnTextCancel: "core.oneModeToBothModes.btnTextCancel",
      warning: true,
      closeButton: true,
      closeButtonIcon: "icons/Controls/ic_controls_close_56.svg",
      resultActionName: "oneModeToBothModes"
    }
  },
  portraitModeToLandscape: {
    type: "portraitModeToLandscape",
    component: "ErrorDialog",
    props: {
      icon: "icons/Warnings/ic_warning_200.svg",
      text1: "core.portraitModeToLandscape.title",
      text2: "core.portraitModeToLandscape.description",
      actionText: "core.portraitModeToLandscape.btnTextAccept",
      btnTextCancel: "core.portraitModeToLandscape.btnTextCancel",
      warning: true,
      closeButton: true,
      closeButtonIcon: "icons/Controls/ic_controls_close_56.svg",
      resultActionName: "portraitModeToLandscape"
    }
  }
};

const defaultErrorType = "defaultError";

const getErrorComponent = error => {
  if (!error) return undefined;
  if (!error.error) return undefined;
  const { type, meta, code } = error;
  let res;
  if (type) {
    res = errorTypes[type];
  } else {
    res = Object.values(errorTypes).find(
      errorType => errorType.meta === meta && errorType.code === code
    );
  }
  return res || errorTypes[defaultErrorType];
};

const getDialogOptions = dialog => {
  return errorTypes[dialog] || errorTypes[defaultErrorType];
};
export { getErrorComponent, getDialogOptions };
