<template>
  <GridLayout rows="auto" columns="*, 60, *" class="monthly-consumption">
    <StackLayout
      row="0"
      col="0"
      :class="{ 'monthly-consumption__disabled': averageConsumption === 0 }"
    >
      <VxpLabel col="0" class="font-h3-20" :text="getTitle" :textWrap="true" />
      <VxpLabel
        col="0"
        class="monthly-consumption__desc font-txt-13"
        :text="titleDesc"
        :textWrap="true"
      />
    </StackLayout>
    <StackLayout
      row="0"
      col="2"
      :class="{ 'monthly-consumption__disabled': consumption === 0 }"
    >
      <VxpLabel
        col="0"
        :class="{
          'monthly-consumption__from--warning': percentageDown,
          'monthly-consumption__from': !percentageDown && consumption !== 0
        }"
        class="font-h3-20"
        :text="getPercentageText"
        :textWrap="true"
      />
      <VxpLabel
        col="0"
        class="monthly-consumption__desc font-txt-13"
        :text="montlyDesc"
        :textWrap="true"
      />
    </StackLayout>
  </GridLayout>
</template>
<script>
import { round } from "@/helpers/utils";
export default {
  name: "MonthlyConsumption",
  props: {
    energy: {
      type: Object,
      default: () => ({})
    },
    title: {
      type: String,
      default: ""
    },
    consumption: {
      type: Number,
      default: 0
    },
    averageConsumption: {
      type: Number,
      default: 0
    },
    peviousConsumption: {
      type: Number,
      default: 0
    },
    measurementUnit: {
      type: String,
      default: ""
    },
    titleDesc: {
      type: String,
      default: ""
    },
    monthly: {
      type: String,
      default: ""
    },
    montlyDesc: {
      type: String,
      default: ""
    }
  },
  computed: {
    getTitle() {
      const value =
        this.averageConsumption < 1000
          ? this.averageConsumption
          : this.averageConsumption / 1000 || 0;
      const unit =
        // this.averageConsumption < 1000
        //   ? this.averageConsumption < 1
        //     ? "watt"
        //     : "watts"
        //   :
        "kWh";
      return this.averageConsumption === 0
        ? this.title || "--"
        : this.title || `${value.toFixed(3)}${unit}`;
    },
    getLastConsuption() {
      return `${this.peviousConsumption}%`;
    },
    diffPercentage() {
      const percentage =
        this.peviousConsumption === 0 && this.averageConsumption > 0
          ? 100
          : this.consumption === 0 && this.peviousConsumption > 0
          ? -100
          : (this.consumption / this.peviousConsumption) * 100 - 100 || 0;
      return percentage;
    },
    percentageDown() {
      return this.diffPercentage < 0;
    },
    getPercentageText() {
      return this.consumption === 0
        ? "--"
        : (this.percentageDown ? "↓ " : "↑ ") +
            round(this.diffPercentage, 2) +
            "%";
    }
  }
};
</script>

<style lang="scss">
@import "~/assets/styles/variables.scss";

.monthly-consumption {
  &__disabled {
    color: $color-grey;
  }
  &__desc {
    margin-top: $margin-xs;
    color: $color-grey;
  }
  &__desc-status {
    color: $color-primary;
  }
  &__from {
    color: $color-vera;
    &--warning {
      color: $color-warning;
    }
  }
}
</style>
