<template>
  <GridLayout class="header" rows="56" columns="*, auto">
    <VxpLabel
      row="0"
      col="0"
      class="header__title font-h2-24"
      :text="texts.title"
    />
  </GridLayout>
</template>
<script>
export default {
  name: "DevicesListHeader",
  props: {
    texts: {
      type: Object,
      default: () => ({
        title: "Living room"
      })
    }
  }
};
</script>

<style scoped lang="scss">
@import "~/assets/styles/variables.scss";

.header {
  height: $xxxll;
  &__title {
    text-align: left;
    vertical-align: middle;
    color: $color-black;
    align-self: center;
  }
}
</style>
