import WebSocketConnection from "./ws.service";

let WS = {};

export const socketConnect = async ({
  url,
  MMSAuth,
  MMSAuthSig,
  PK_Device,
  onReconnect
}) => {
  const socket = initNewConnection(PK_Device);
  await socket.close();
  const wsUrl = url.startsWith("wss://") ? url : `wss://${url}`;
  await socket.start({ url: wsUrl, onReconnect });
  await login({ MMSAuth, MMSAuthSig, PK_Device });
};

export const socketDisconnect = hub => WS[hub].close();

export const subscribe = (channel, cb, hub) => {
  WS[hub].subscribe(channel, cb);
};

export const unsubscribe = (channel, cb, hub) => {
  if (WS[hub]) {
    WS[hub].unsubscribe(channel, cb);
    WS[hub].close();
  }
};

export const resetWS = (channel, cb, hub) => {
  WS = {};
};

export const controllerSelect = serial => {
  return WS[serial].send("register", { serial });
};

const login = ({ MMSAuth, MMSAuthSig, PK_Device }) =>
  WS[PK_Device].send("loginUserMios", { MMSAuth, MMSAuthSig, PK_Device });

export const devices = {
  /**
   *
   * @returns {*|Promise<unknown>}
   */
  list: hub => WS[hub].send("hub.devices.list", {}),
  /**
   *
   * @param { string[] } ids
   * @returns {*|Promise<unknown>}
   */
  service: {
    notificationsSet: (ids, hub) =>
      WS[hub].send("hub.devices.service.notifications.set", { ids })
  }
};

export const device = {
  /**
   *
   * @param { string } id
   * @param { string } name
   * @returns {*|Promise<unknown>}
   */
  nameSet: (id, name, hub) =>
    WS[hub].send("hub.device.name.set", { _id: id, name }),

  /**
   *
   * @param { string } id
   * @param { string } roomId
   * @returns {*|Promise<unknown>}
   */
  roomSet: (id, roomId, hub) =>
    WS[hub].send("hub.device.room.set", { _id: id, roomId }),

  /**
   *
   * @param { string } id
   * @param { boolean } armed
   * @returns {*|Promise<unknown>}
   */
  armedSet: (id, armed, hub) =>
    WS[hub].send("hub.device.armed.set", { _id: id, armed }),
  /**
   *
   * @param { string } id
   * @returns {*|Promise<unknown>}
   */
  statusCheck: {
    check: (id, hub) => WS[hub].send("hub.device.status.check", { _id: id })
  },
  /**
   * { string }
   * @param { string } id
   * @returns {*|Promise<unknown>}
   */
  force_remove: (id, hub) => WS.send("hub.device.force_remove", { _id: id })
};

export const items = {
  list: hub => WS[hub].send("hub.items.list", {})
};

export const item = {
  /**
   * hello
   * @param {string | string[] } id
   * @param value
   */
  valueSet: (id, value, hub) => {
    // console.warn("valueSet",id, value, hub)
    const params = { value };
    params[typeof id === "string" ? "_id" : "ids"] = id;
    return (WS[hub] && WS[hub].send("hub.item.value.set", params)) || null;
  }

  // /**
  //  *
  //  * @param {string} id
  //  * @param {number}percent
  //  * @returns {*|Promise<unknown>}
  //  */
  // valuePercent: (id, percent) =>
  //   WS.send("hub.item.value.percent.apply", { _id: id, percent })

  /* TODO: will be implemented
  dictionary: {
    value: {
      add: () => WS.send("hub.item.dictionary.value.add", {}),
      set: () => WS.send("hub.item.dictionary.value.set", {}),
      remove: () => WS.send("hub.item.dictionary.value.remove", {})
    }
  }
  */
};

function initNewConnection(hub) {
  WS[hub] = new WebSocketConnection();
  return WS[hub];
}

export const favorites = {
  list: hub =>
    WS[hub].send("hub.favorite.list", {
      request: ["devices", "items", "rules"]
    }),
  set: (devices, items, rules, hub) =>
    WS[hub].send("hub.favorite.set", {
      devices,
      items,
      rules
    })
};

export const room = {
  /**
   *
   * @returns {*|Promise<unknown>}
   */
  list: hub => WS[hub].send("hub.room.list", {})
};

export async function closeAllConnections() {
  Object.entries(WS).forEach(([key, value]) => {
    value.close();
  });
  WS = {};
}

//TODO SUPPORT MULTIPLE SOCKET CONNECTIONS
export const createRoom = ({ name }) => WS.send("hub.room.create", { name });

export const editRoom = ({ id, name }) =>
  WS.send("hub.room.name.set", { _id: id, name });

export const modes = {
  /**
   *
   * @returns {*|Promise<unknown>}
   */
  get: () => WS.send("hub.modes.get", {}),
  currentGet: () => WS.send("hub.modes.current.get", {}),
  currentSet: params => WS.send("hub.modes.switch", params),
  cancelSwitch: () => WS.send("hub.modes.cancel_switch", {}),
  disarmedDevicesAdd: params =>
    WS.send("hub.modes.disarmed_devices.add", params),
  disarmedDevicesRemove: params =>
    WS.send("hub.modes.disarmed_devices.remove", params),
  alarmsOffDeviceAdd: params => WS.send("hub.modes.alarms_off.add", params),
  alarmsOffDeviceRemove: params =>
    WS.send("hub.modes.alarms_off.remove", params),
  switchToDelaySet: params => WS.send("hub.modes.switch_to_delay.set", params),
  alarmDelaySet: params => WS.send("hub.modes.alarm_delay.set", params)
};

export const zwave = {
  /**
   *
   * @returns {*|Promise<unknown>}
   */
  start_include: () =>
    WS.send("hub.extensions.plugin.run", {
      script: "HUB:zwave/scripts/start_include"
    }),
  stop_include: () =>
    WS.send("hub.extensions.plugin.run", {
      script: "HUB:zwave/scripts/stop_include"
    }),
  set_authentication_mode: () =>
    WS.send("hub.extensions.plugin.run", {
      script: "HUB:zwave/scripts/set_authentication_mode",
      scriptParams: {
        modes: ["accessControl", "unauthenticated"]
      }
    }),
  set_device_specific_key: () =>
    WS.send("hub.extensions.plugin.run", {
      script: "HUB:zwave/scripts/set_device_specific_key",
      scriptParams: {
        key: 1458
      }
    }),
  start_exclude: () =>
    WS.send("hub.extensions.plugin.run", {
      script: "HUB:zwave/scripts/start_exclude"
    }),
  stop_exclude: () =>
    WS.send("hub.extensions.plugin.run", {
      script: "HUB:zwave/scripts/stop_exclude"
    })
};

export const scenes = {
  get: hub => WS[hub].send("hub.scenes.get", {}),
  list: hub => WS[hub].send("hub.scenes.list", {}),
  run: (sceneId, hub) => {
    /* 
    {
    "method": "hub.scenes.run",
    "id": "_ID_",
    "params": {
        "sceneId": "d233cdel43422"
    }
    }
    */
    return (WS[hub] && WS[hub].send("hub.scenes.run", { sceneId })) || null;
  }
};
