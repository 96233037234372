<template>
  <MainPage>
    <StackLayout>
      <IBtnHiBtn
        icon1Src="icons/Controls/ic_controls_arrow-left_56.svg"
        :text="translations.title"
        @iconTap1="handleBack"
        class="device-control__header"
      />
    </StackLayout>

    <StackLayout>
      <Separator size="xl" />
      <IconHTxtiBtn
        :icon1Src="abstractDevices.src"
        alignText="left"
        alignUnderline="left"
        headerSize="h3"
        :icon1Size="40"
        showUnderline
        :text="abstractDevices.name"
        :underlineText="abstractDevices.eMail"
        :hover="false"
      />
      <Separator size="xl" />
    </StackLayout>
    <GridLayout rows="*, auto" columns="*" class="device-control">
      <GridLayout row="0" col="0" rows="auto, auto, auto, *" columns="*">
        <StackLayout row="0" col="0">
          <VxpLabel
            class="device-control__group-title font-h2-24"
            :text="translations.groupTitle"
            :textWrap="true"
          />
          <Separator size="xl" />
        </StackLayout>

        <ScrollView row="1" col="0" height="100%">
          <StackLayout height="100%">
            <StackLayout v-for="item in capabilities" :key="item.id">
              <CheckHTxtiBtn
                icon2Src="icons/Controls/ic_controls_arrow-right_56.svg"
                alignText="left"
                alignUnderline="left"
                headerSize="h3"
                showUnderline
                :checked="item.checked"
                :text="item.name"
                :underlineText="item.decription"
                @tap="onTapCapabilities(item, $event)"
                @change="selectCapabilities(item, $event)"
              />
              <Separator size="xl" />
            </StackLayout>
          </StackLayout>
        </ScrollView>

        <StackLayout row="2" col="0">
          <IconH
            class="device-control__more-capabilities"
            src="icons/Controls/ic_controls_plus_warning_56.svg"
            :text="translations.actionText"
            @tap="configureMoreCapabilities"
          />
          <Separator size="xl" />
        </StackLayout>
      </GridLayout>
      <StackLayout row="1" col="0" class="device-control__btn-container">
        <ButtonFilled
          :disabled="isDisabled"
          :text="getButtonText"
          @tap="handleCreate"
        />
      </StackLayout>
    </GridLayout>
  </MainPage>
</template>
<script>
import { required } from "vuelidate/lib/validators";

export default {
  name: "SelectCapabilities",
  data() {
    return {
      selectedCapabilities: []
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.selectedCapabilities = this.getSelectedCapabilities;
    });
  },
  computed: {
    abstractDevices() {
      return {
        name: "Light in living room",
        eMail: "user@gmail.com",
        src: "icons/Smarthomes/ic_smarthomes_amazon_alexa.svg"
      };
    },
    capabilities() {
      return [
        {
          id: "1",
          name: "Turn on/off",
          decription: "Configured",
          checked: true
        },
        {
          id: "2",
          name: "2222",
          decription: "Configured",
          checked: false
        },
        {
          id: "3",
          name: "333333333",
          decription: "Configured",
          checked: true
        }
      ];
    },
    getSelectedCapabilities() {
      return this.capabilities.filter(item => item.checked);
    },
    selectedCapabilitiesCount() {
      // TODO
      return this.selectedCapabilities.length || "";
    },
    translations() {
      // eslint-disable-next-line
      return this.$t("selectCapabilities");
    },
    isDisabled() {
      return this.selectedCapabilitiesCount === "";
    },
    getButtonText() {
      return this.$t("selectCapabilities.textBtn", {
        count: this.selectedCapabilitiesCount
      });
    }
  },
  methods: {
    handleBack() {
      this.navigateBack();
    },
    handleCreate() {
      this.navigateTo("NewVirtualDevice");
      console.log("handleCreate"); // eslint-disable-line
    },
    handleSubmit() {
      console.log("handleSubmit"); // eslint-disable-line
    },
    onTapCapabilities(item) {
      this.navigateTo("EditCapabilityPage");
      console.log("onTapCapabilities", item); // eslint-disable-line
    },
    selectCapabilities(capability, checked) {
      console.log("selectCapabilities", { checked, capability }); // eslint-disable-line
      capability.checked = !capability.checked;
      if (checked) {
        this.selectedCapabilities.push(capability);
      } else {
        this.selectedCapabilities = this.selectedCapabilities.filter(
          item => item.id !== capability.id
        );
      }
    },
    configureMoreCapabilities() {
      this.navigateTo("ConfigureCapabilityPage");
      console.log("configureMoreCapabilities"); //eslint-disable-line
    }
  },
  validations() {
    return {
      name: {
        required
      }
    };
  }
};
</script>
<style lang="scss" scoped>
@import "~/assets/styles/variables.scss";

.device-control {
  user-select: none;
  height: 100%;
  &__container {
    margin: 0 $xl;
  }
  &__btn-container {
    padding: 0 $xl $xxl $xl;
  }
  &__hint {
    text-align: left;
    color: $color-grey;
  }
  &__input-label {
    margin-bottom: $s;
    text-align: left;
    color: $color-black;
  }
  &__alexaImage {
    grid-column: 2;
    justify-self: end;
    width: unit(124);
    height: unit(124);
  }
  &__googleImage {
    grid-column: 3;
    justify-self: start;
    width: unit(124);
    height: unit(124);
  }
  &__group-title {
    color: $color-black;
    margin: $lmx $xl $xs $lmx;
  }
  &__more-capabilities {
    &:hover {
      cursor: pointer;
      background-color: $color-grey-a15;
    }
  }
  &__header {
    /deep/ & * {
      color: $color-grey;
    }
  }
}
</style>
