const { VUE_APP_MODE } = process.env;
export default {
  computed: {
    isMobileBrowser() {
      // if mobile web browser
      return (
        VUE_APP_MODE === "native" ||
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      );
    }
  }
};
