<template>
  <GridLayout class="color-ellipse" rows="auto" columns="auto">
    <StackLayout
      row="0"
      col="0"
      class="color-ellipse__badge"
      :class="{ 'color-ellipse__shadow': shadow }"
      :backgroundColor="color"
    />
  </GridLayout>
</template>
<script>
import TemplateTile from "@/components/dashboard/TemplateTile";

export default {
  name: "ColorEllipse",
  props: {
    color: {
      type: String,
      default: "#ffffff"
    },
    shadow: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~/assets/styles/variables.scss";
.color-ellipse {
  padding: $padding-l;
  &__badge {
    width: $xl;
    height: $xl;
    border-radius: 100%;
    border-width: $stroke;
    border-style: solid;
    border-color: $color-grey-a25;
    // box-shadow: $shadow-bottom-m;
  }
  &__shadow {
    box-shadow: $shadow-bottom-m;
  }
}
</style>
