<template>
  <MainPage>
    <GridLayout
      rows="*, auto"
      columns="*"
      height="100%"
      class="information-dialog"
    >
      <StackLayout row="0" col="0" verticalAlignment="center">
        <SvgIcon :src="icon" :size="200" verticalAlignment="center" />
        <Label
          class="information-dialog--title font-h1-32"
          :text="texts.text1"
          textWrap
        />
        <Label
          class="information-dialog--text font-txt-16-par"
          :text="texts.text2"
          textWrap
        />
      </StackLayout>
      <ButtonFilled
        row="1"
        col="0"
        class="information-dialog--button font-txt-16-btn"
        :warning="warning"
        :text="texts.actionText"
        @tap="goBack"
      />
    </GridLayout>
  </MainPage>
</template>
<script>
export default {
  name: "InformationDialog",
  props: {
    icon: {
      type: String
    },
    text1: {
      type: String
    },
    text2: {
      type: String
    },
    actionText: {
      type: String
    },
    warning: {
      type: Boolean
    }
  },
  computed: {
    texts() {
      return {
        text1: this.$t(this.text1),
        text2: this.$t(this.text2),
        actionText: this.$t(this.actionText)
      };
    }
  },
  methods: {
    goBack() {
      this.navigateBack();
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~/assets/styles/variables.scss";

.information-dialog {
  height: 100%;
  padding: $padding-s $padding-xl $padding-xl $padding-xl;
  align-items: center;
  &--title {
    text-align: center;
    color: $color-black;
  }
  &--text {
    margin: $margin-s 0 0 0;
    text-align: center;
    color: $color-black;
  }
  &--button {
    grid-row: 2;
    grid-column: 1;
    margin-top: $xxxl;
    color: $color-white;
  }
}
</style>
