// Core
import Vue from "@/vue";
import InitialState from "./state";
import {
  LOCATIONS_RESET_STATE,
  SET_CURRENT_LOCATION,
  SET_LOCATIONS,
  SET_LOCATION_HUB_DETAILS
} from "./mutationTypes";

export default {
  [LOCATIONS_RESET_STATE](state) {
    for (let prop in state) {
      Vue.set(state, prop, InitialState()[prop]);
    }
  },
  [SET_LOCATIONS](state, payload) {
    Vue.set(state, "locations", payload);
  },
  [SET_CURRENT_LOCATION](state, payload) {
    Vue.set(state, "currentLocation", payload);
  }
};
