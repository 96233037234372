import {
  SET_AUTH_RESET_PASSWORD,
  SET_AUTH_STATE,
  SET_AUTH_USER_EMAIL,
  SET_AUTH_USER_GET_INFO,
  SET_AUTH_USER_UPDATE_INFO,
  SET_AUTH_LOGOUT,
  SET_AUTH_SIGNUP_LOCATION_NAME,
  FETCHING_AUTH_SHARE_ACTIVITY,
  FETCHING_AUTH_SHARE_INACTIVITY,
  SET_AUTH_USER_AVATAR,
  SET_AUTH_RESET_STATE,
  FETCHING_AUTH_AVATAR,
  SET_WEB_VIEW_MODE,
  SET_CONTROLLER_ID,
  SET_UPDATE_TEMPLATE,
  SET_PATH,
  SET_DASHBOARD_ID,
  SET_EZLO_PROTECT
} from "./mutationTypes";
import authService from "@/services/auth.service";
import cloudService from "@/services/cloud.service";
import storageService from "@/services/storage.service";
import { HttpError } from "@/helpers/customErrors";
import { getIdentityDecoded } from "@/helpers/identityDecoded";
import { getParsedUserInfo } from "@/helpers/createUserInfo";
import { parseHttpResponse } from "@/helpers/responseParser";
import { closeAllConnections } from "@/services/nma.service";
import apiConfig from "../../../../config/api";
import { checkAvailbility } from "ezlo-core-ui/src/helpers/pushNotifications";
const { VUE_APP_MODE } = process.env;

let fetchUserInfoRetry = false;
let fetchUserAvatar = false;

const actions = {
  // Sync
  resetState(context) {
    context.commit(SET_AUTH_RESET_STATE);
  },
  setUserEmail(context, { email }) {
    context.commit(SET_AUTH_USER_EMAIL, email);
  },
  setSignupLocationName(context, locationName) {
    context.commit(SET_AUTH_SIGNUP_LOCATION_NAME, locationName);
  },
  fetchingShareActivity(context, payload) {
    context.commit(FETCHING_AUTH_SHARE_ACTIVITY, payload);
  },
  fetchingShareInactivity(context, payload) {
    context.commit(FETCHING_AUTH_SHARE_INACTIVITY, payload);
  },
  setFetchingAvatar({ commit }, payload) {
    commit(FETCHING_AUTH_AVATAR, payload);
  },
  setIsWebView({ commit }, payload) {
    commit(SET_WEB_VIEW_MODE, payload);
  },
  setPath({ commit }, payload) {
    commit(SET_PATH, payload);
  },
  setControllerId({ commit }, payload) {
    commit(SET_CONTROLLER_ID, payload);
  },
  setDashboardId({ commit }, payload) {
    commit(SET_DASHBOARD_ID, payload);
  },
  setUpdateTemplate({ commit }, payload) {
    commit(SET_UPDATE_TEMPLATE, payload);
  },
  // Async
  async fetchUserKVS({ commit, dispatch, state, getters, rootGetters }) {
    // the avatar fetch is in progress
    if (rootGetters["ui/isFetching"]("fetchUserKVS")) return;

    // the avatar was fetched, next reload in background
    const silent = getters.getAvatarUrl !== undefined;

    try {
      dispatch("ui/startFetching", "fetchUserKVS", { root: true });
      const prevAvatarUrl = getters.getAvatarUrl;

      if (!silent) dispatch("setFetchingAvatar", true);

      const token = {
        Identity: state.authState.Identity,
        IdentitySignature: state.authState.IdentitySignature
      };
      const { response, baseUrl, url } = await storageService.getFromKVS(
        token,
        {
          PK_StorageType: 3,
          Count: 1
        }
      );
      const PK_Account = getters.getAccountId;
      let PK_Store,
        Key,
        avatarUrl = null;
      if (response && response.statusCode === 200) {
        const content = response.content.toJSON();
        const [item] = content;
        PK_Store = item.PK_Store;
        Key = item.Key;
        if (PK_Store) {
          avatarUrl = `https://${baseUrl}${url}/${PK_Store}/archive?Key=${Key}`;
        }
      }
      // check if avatar was not changed while response was received
      if (getters.getAvatarUrl === prevAvatarUrl)
        commit(SET_AUTH_USER_AVATAR, {
          PK_Account,
          PK_Store,
          key: Key,
          baseUrl,
          url,
          avatarUrl
        });
    } catch (error) {
      dispatch(
        "ui/emitError",
        {
          error: error.message,
          meta: "fetchUserKVS",
          code: error.code
        },
        {
          root: true
        }
      );
    } finally {
      dispatch("setFetchingAvatar", false);
      dispatch("ui/stopFetching", "fetchUserKVS", { root: true });
    }
  },
  async fetchUserAvatar({ commit, dispatch, state, getters, rootGetters }) {
    // the avatar fetch is in progress
    if (rootGetters["ui/isFetching"]("fetchUserAvatar")) return;

    // the avatar was fetched, next reload in background
    const silent = getters.getAvatarUrl !== undefined;

    try {
      dispatch("ui/startFetching", "fetchUserAvatar", { root: true });

      if (!silent) dispatch("setFetchingAvatar", true);

      const token = {
        Identity: state.authState.Identity,
        IdentitySignature: state.authState.IdentitySignature,
        baseUrl: !fetchUserAvatar
          ? rootGetters["auth/getUserAvatarHost"]
          : rootGetters["auth/getUserAvatarHostAlt"],
        key: `profilePicture_${rootGetters["auth/getPK_User"]}`
      };
      const PK_Account = getters.getAccountId;
      const {
        avatarUrl,
        url,
        baseUrl
      } = await storageService.getAvatarUrlFromKVS(token, {
        PK_Account
      });
      commit(SET_AUTH_USER_AVATAR, {
        PK_Account,
        baseUrl,
        url,
        avatarUrl
      });
    } catch (error) {
      if (!fetchUserAvatar) {
        console.log("fetchUserAvatar RETRYING WITH ALT SERVER...."); //eslint-disable-line
        fetchUserAvatar = true;
        dispatch("ui/stopFetching", "fetchUserAvatar", { root: true });
        dispatch("fetchUserAvatar", null);
      } else {
        fetchUserAvatar = false;
        dispatch(
          "ui/emitError",
          {
            error: error.message,
            meta: "fetchUserAvatar",
            code: error.code
          },
          {
            root: true
          }
        );
      }
    } finally {
      dispatch("setFetchingAvatar", false);
      dispatch("ui/stopFetching", "fetchUserAvatar", { root: true });
    }
  },
  async saveAvatarUrl(context, payload) {
    const { commit, dispatch, state, getters, rootGetters } = context;
    try {
      dispatch("ui/startFetching", "saveAvatarUrl", { root: true });
      const { avatarUrl } = payload;
      const token = {
        Identity: state.authState.Identity,
        IdentitySignature: state.authState.IdentitySignature,
        baseUrl: rootGetters["auth/getUserAvatarHost"]
      };
      const PK_Account = getters.getAccountId;
      const key = `profilePicture_${rootGetters["auth/getPK_User"]}`;
      const { url, baseUrl } = await storageService.saveToKVS(token, {
        PK_Account,
        Key: key,
        Value: avatarUrl
      });

      return {
        PK_Account,
        baseUrl,
        url,
        avatarUrl
      };
    } catch (error) {
      dispatch(
        "ui/emitError",
        {
          error: error.message,
          meta: "saveAvatarUrl",
          code: error.code
        },
        {
          root: true
        }
      );
    } finally {
      dispatch("ui/stopFetching", "saveAvatarUrl", { root: true });
    }
  },
  async setUserAvatar({ commit, state, dispatch, getters }, payload) {
    try {
      console.log("### setUserAvtar actions:", payload); //eslint-disable-line
      dispatch("setFetchingAvatar", true);
      const PK_Account = getters.getAccountId;
      const token = {
        Identity: state.authState.Identity,
        IdentitySignature: state.authState.IdentitySignature
      };
      //eslint-disable-next-line
      console.log(
        token,
        state.authState.IdentityDecoded.PK_Account,
        state.authState.IdentityDecoded.PK_User
      );
      let oldAvatar = getters.getAvatarObject;
      const avatarUrl = payload.fileName || payload.relativePath;
      commit(SET_AUTH_USER_AVATAR, { PK_Account, avatarUrl });
      const options = {
        account: {
          PK_Account: state.authState.IdentityDecoded.PK_Account,
          PK_User: state.authState.IdentityDecoded.PK_User
        },
        fileName: "profilePicture.jpg",
        file: payload
      };
      let response = null;
      if (VUE_APP_MODE === "web") {
        options.file.file = payload;
        response = await storageService.uploadWeb(token, options);
      } else {
        response = await storageService.upload(token, options);
      }

      // {"PK_Store":872387475,"Key":"2449032692"}
      // sample url: https://vera-us-oem-storage25.mios.com/storage/storage/store/872334865/thumbnail?Key=3722163767
      console.log("Send Successfull to File Server"); //eslint-disable-line
      console.dir(response); // eslint-disable-line
      const {
        PK_Store,
        Key,
        url = `https://${apiConfig.storageBaseUrl}/storage/storage/store`,
        responseCode
      } = response;
      let avatar = {};
      if (response) {
        if (
          (responseCode && [201, -1].includes(responseCode)) ||
          (VUE_APP_MODE === "web" && PK_Store)
        ) {
          const avatarUrl = `${url}/${PK_Store}/archive?Key=${Key}`;
          avatar = {
            PK_Account,
            PK_Store,
            Key,
            url,
            avatarUrl
          };

          const saveResult = await dispatch("saveAvatarUrl", { avatarUrl });
          console.dir(saveResult); // eslint-disable-line
          commit(SET_AUTH_USER_AVATAR, avatar);
          return true;
        } else {
          commit(SET_AUTH_USER_AVATAR, oldAvatar);
          return false;
        }
      }
    } catch (error) {
      console.log("setUserAvatar upload ERROR:", error); //eslint-disable-line
      dispatch(
        "ui/emitError",
        {
          error: error.message,
          meta: "setUserAvatar",
          code: error.code
        },
        {
          root: true
        }
      );
    } finally {
      dispatch("setFetchingAvatar", false);
    }
  },
  async loginAsync({ dispatch, commit, rootGetters }, payload) {
    try {
      dispatch("ui/startFetching", "login", { root: true });
      const { username, password } = payload;

      const response = await authService.login(username, password);

      const { data, statusCode } = await parseHttpResponse(response);

      if (statusCode !== 200) {
        if (data.TooManyLogins) {
          // custome response code 603, the range 600-799
          // https://assets.dynatrace.com/keynote/support/lp_help/source/loadpro_errors.htm
          const REFUSED_ERROR = 603;
          throw new HttpError(Object.keys(data)[0], REFUSED_ERROR);
        }
        throw new HttpError(data, statusCode);
      }

      const IdentityDecoded = getIdentityDecoded(data.Identity);
      const jwtToken = await dispatch("fetchCloudJwtAsync", {
        mmsAuth: data.Identity,
        mmsAuthSig: data.IdentitySignature
      });

      const authData = {
        ...data,
        IdentityDecoded,
        jwtToken
      };
      commit(SET_AUTH_STATE, authData);
      await dispatch("fetchUserInfoAsync", { password });
      await dispatch("fetchAllUserData");
      await dispatch("getEzloProtect");
    } catch (error) {
      // eslint-disable-next-line
      console.log("loginAsync ERROR:", error); //eslint-disable-line
      dispatch(
        "ui/emitError",
        {
          error: error.message,
          meta: "loginAsync",
          code: error.code
        },
        {
          root: true
        }
      );
    } finally {
      dispatch("ui/stopFetching", "login", { root: true });
    }
  },
  async loginWithBounceToken({ dispatch, commit, rootGetters }, payload) {
    try {
      const token = payload;
      const response = await cloudService.getMMSInfoWithToken(token);
      const { data, statusCode } = await parseHttpResponse(response, "json");

      if (statusCode !== 200) {
        throw new HttpError(data, statusCode);
      }
      if (!data.data.raw) {
        throw new HttpError("data is empty or null", statusCode);
      }

      const {
        mmsAuth: Identity,
        mmsAuthSig: IdentitySignature,
        controllerId,
        path,
        dashboardId,
        Server_Account,
        Server_Account_Alt
      } = JSON.parse(data.data.raw);

      if (controllerId) {
        dispatch("setControllerId", controllerId);
      }
      if (dashboardId) {
        dispatch("setDashboardId", dashboardId);
      }
      const IdentityDecoded = getIdentityDecoded(Identity);
      const jwtToken = await dispatch("fetchCloudJwtAsync", {
        mmsAuth: Identity,
        mmsAuthSig: IdentitySignature
      });

      const authData = {
        Identity,
        IdentitySignature,
        IdentityDecoded,
        Server_Account,
        Server_Account_Alt,
        jwtToken
      };
      dispatch("setIsWebView", true);
      dispatch("setPath", path);

      commit(SET_AUTH_STATE, authData);
      await dispatch("fetchUserInfoAsync", { password: "" });
      await dispatch("fetchAllUserData");
      await dispatch("getEzloProtect");
    } catch (error) {
      // eslint-disable-next-line
     console.log("refreshToken ERROR:", error); //eslint-disable-line
      dispatch(
        "ui/emitError",
        {
          type: "errorRequestConnection",
          error: error.message,
          meta: "refreshToken",
          code: error.code
        },
        {
          root: true
        }
      );
    }
  },
  async refreshToken({ dispatch, commit, getters }) {
    try {
      dispatch("ui/startFetching", "refreshToken", { root: true });

      const { getAuthState } = getters;
      const userId = getAuthState.IdentityDecoded.PK_User;
      const response = await authService.refreshToken(userId, {
        token: {
          Identity: getAuthState.Identity,
          IdentitySignature: getAuthState.IdentitySignature
        }
      });
      const { data, statusCode } = await parseHttpResponse(response);

      if (statusCode !== 200) {
        if (data.TooManyLogins) {
          // custome response code 603, the range 600-799
          // https://assets.dynatrace.com/keynote/support/lp_help/source/loadpro_errors.htm
          const REFUSED_ERROR = 603;
          throw new HttpError(Object.keys(data)[0], REFUSED_ERROR);
        }
        throw new HttpError(data, statusCode);
      }

      const IdentityDecoded = getIdentityDecoded(data.Identity);
      const jwtToken = await dispatch("fetchCloudJwtAsync", {
        mmsAuth: data.Identity,
        mmsAuthSig: data.IdentitySignature
      });

      const authData = {
        ...data,
        IdentityDecoded,
        jwtToken
      };

      commit(SET_AUTH_STATE, authData);
    } catch (error) {
      // eslint-disable-next-line
      console.log("refreshToken ERROR:", error); //eslint-disable-line
      dispatch(
        "ui/emitError",
        {
          type: "errorRequestConnection",
          error: error.message,
          meta: "refreshToken",
          code: error.code
        },
        {
          root: true
        }
      );
    } finally {
      dispatch("ui/stopFetching", "refreshToken", { root: true });
    }
  },
  async fetchUserHubs({ dispatch, commit, rootGetters }) {
    // Set initial location
    await dispatch("location/fetchLocationsAsync", null, { root: true });

    const initialLocation = rootGetters["location/getLocationByIndex"](0);
    await dispatch("location/setCurrentLocation", initialLocation, {
      root: true
    });

    // old implementation
    await dispatch("hub/fetchHubsAsync", null, { root: true });
  },
  async fetchAllUserData({ dispatch, commit, rootGetters, getters }) {
    try {
      dispatch("ui/startFetching", "fetchAllUserData", { root: true });

      const { isWebView, getControllerId } = getters;
      // let initialTickCount = new Date().getTime();
      // let tickCount = new Date().getTime();
      dispatch("fetchUserAvatar");
      dispatch("dashboards/fetchBackgroundImages", null, { root: true });
      dispatch("dashboards/fetchCustomIcons", null, { root: true });
      await dispatch("getEzloProtect");

      dispatch("fetchUserHubs");
      if (
        !isWebView ||
        (isWebView && parseInt(getControllerId) > 69999999) ||
        (isWebView && getControllerId === "no_hub")
      ) {
        dispatch("abstracts/fetchAbstractDevices", null, { root: true });
      }

      //new cloud implementation
      // tickCount = new Date().getTime();
      await dispatch("hub/fetchHubInfoFromCloud", null, { root: true });
      // console.log(
      //   `### login Part 2 / step 4  fetchHubInfoFromCloud: ${new Date().getTime() -
      //     tickCount}ms`
      // );

      // Other data
      // tickCount = new Date().getTime();
      await dispatch("dashboards/fetchDashboardsAsync", null, {
        root: true
      });
      await dispatch("themes/fetchThemes", null, {
        root: true
      });

      // console.log(
      //   `### login Part 2 / step 5  fetchDashboardsAsync: ${new Date().getTime() -
      //     tickCount}ms`
      // );
      // console.log(
      //   `### fetchAllUserData LOGIN PART 2  FINISH --> : ${new Date().getTime() -
      //     initialTickCount}ms`
      // );
    } catch (error) {
      console.log("fetchAllUserData ERROR:", error); //eslint-disable-line
      dispatch(
        "ui/emitError",
        {
          error: error.message,
          meta: "fetchAllUserData",
          code: error.code
        },
        {
          root: true
        }
      );
    } finally {
      dispatch("ui/stopFetching", "fetchAllUserData", { root: true });
    }
  },
  async logoutAsync({ dispatch, commit, getters }) {
    try {
      dispatch("ui/startFetching", "logout", { root: true });

      const { getAuthState } = getters;
      const userId = getAuthState.IdentityDecoded.PK_User;
      const response = await authService.logout(userId, {
        token: {
          Identity: getAuthState.Identity,
          IdentitySignature: getAuthState.IdentitySignature
        }
      });

      const { data, statusCode } = await parseHttpResponse(response, "string");
      if (statusCode !== 200) {
        if (statusCode === 401) {
          throw new HttpError("User not logged in", statusCode);
        }
        throw new HttpError(data, statusCode);
      }
      dispatch("setIsWebView", false);
      dispatch("setControllerId", null);
      await dispatch("resetAppStates");
      closeAllConnections();
      commit(SET_AUTH_LOGOUT);
      return true;
    } catch (error) {
      // eslint-disable-next-line
      console.log("logoutAsync ERROR:", error); //eslint-disable-line
      dispatch(
        "ui/emitError",
        {
          type: "errorRequestConnection",
          error: error.message,
          meta: "logoutAsync",
          code: error.code
        },
        {
          root: true
        }
      );
    } finally {
      dispatch("ui/stopFetching", "logout", { root: true });
    }
  },
  async signupAsync({ dispatch, commit }, payload) {
    try {
      console.log("signupAsync", payload); // eslint-disable-line
      const { password, email, username } = payload;

      dispatch("ui/startFetching", "signUp", { root: true });
      await dispatch("resetAppStates");
      const response = await authService.signup({ password, email, username });

      const { data, statusCode } = await parseHttpResponse(response);

      if (statusCode !== 200) {
        if (data.TooManyLogins) {
          // custome response code 603, the range 600-799
          // https://assets.dynatrace.com/keynote/support/lp_help/source/loadpro_errors.htm
          const REFUSED_ERROR = 603;
          throw new HttpError(Object.keys(data)[0], REFUSED_ERROR);
        }
        throw new HttpError(data, statusCode);
      }
      const IdentityDecoded = getIdentityDecoded(data.Identity);
      const authData = { ...data, IdentityDecoded };

      commit(SET_AUTH_STATE, authData);
      await dispatch("fetchUserInfoAsync", { password });
    } catch (error) {
      console.log("signupAsync ERROR ", error); // eslint-disable-line
      dispatch(
        "ui/emitError",
        {
          error: error.message,
          meta: "signupAsync",
          code: error.code
        },
        {
          root: true
        }
      );
    } finally {
      dispatch("ui/stopFetching", "signUp", { root: true });
    }
  },
  async updatePasswordAsync({ dispatch, commit, getters }, payload) {
    try {
      const { oldPassword, newPassword } = payload;
      const { getAuthState, getUser } = getters;
      const username = getUser.username;
      const { Identity, IdentitySignature } = getAuthState;
      const config = {
        token: {
          Identity,
          IdentitySignature
        }
      };
      dispatch("ui/startFetching", "updatePassword", { root: true });

      const response = await authService.updatePassword(
        username,
        oldPassword,
        newPassword,
        config
      );
      const { data, statusCode } = await parseHttpResponse(response, "string");

      if (statusCode !== 201) {
        if (statusCode === 401) {
          throw new HttpError("User not logged in", statusCode);
        }
        if (statusCode === 403) {
          throw new HttpError("This user isn't registered", statusCode);
        }
        throw new HttpError(data, statusCode);
      }

      await dispatch("logoutAsync");
    } catch (error) {
      console.log("updatePasswordAsync ERROR ", error); // eslint-disable-line
      dispatch(
        "ui/emitError",
        {
          type: "errorRequestConnection",
          error: error,
          meta: "updatePasswordAsync",
          code: error.code
        },
        {
          root: true
        }
      );
    } finally {
      dispatch("ui/stopFetching", "updatePassword", { root: true });
    }
  },
  async resetPasswordAsync({ dispatch, commit, getters }) {
    try {
      const userEmail = getters.userEmail;
      dispatch("ui/startFetching", "resetPassword", { root: true });

      const response = await authService.resetPassword(userEmail);
      const { data, statusCode } = await parseHttpResponse(response, "string");

      if (statusCode !== 200 && statusCode !== 404) {
        const message = data;
        commit(SET_AUTH_RESET_PASSWORD, false);
        throw new Error(`${statusCode} ${message}`);
      }

      commit(SET_AUTH_RESET_PASSWORD, true);
    } catch (error) {
      dispatch(
        "ui/emitError",
        {
          type: "errorRequestConnection",
          error: error,
          meta: "resetPasswordAsync"
        },
        {
          root: true
        }
      );
    } finally {
      dispatch("ui/stopFetching", "resetPassword", { root: true });
    }
  },
  async createNewPassword({ dispatch }) {
    try {
      dispatch("ui/startFetching", "createNewPassword", { root: true });

      throw new Error("Not implemented");
    } catch (error) {
      console.log(error); //eslint-disable-line
      dispatch(
        "ui/emitError",
        {
          type: "errorRequestConnection",
          error: error,
          meta: "createNewPassword"
        },
        {
          root: true
        }
      );
    } finally {
      dispatch("ui/stopFetching", "createNewPassword", { root: true });
    }
  },
  async forgotPasswordEmailCheck({ dispatch }) {
    try {
      dispatch("ui/startFetching", "forgotPasswordEmailCheck", { root: true });

      throw new Error("Not implemented");
    } catch (error) {
      dispatch(
        "ui/emitError",
        {
          type: "errorLinkValid",
          error: error,
          meta: "forgotPasswordEmailCheck"
        },
        {
          root: true
        }
      );
    } finally {
      dispatch("ui/stopFetching", "forgotPasswordEmailCheck", { root: true });
    }
  },
  async fetchUserInfoAsync({ state, dispatch, commit, getters }, payload) {
    try {
      dispatch("ui/startFetching", "fetchUserInfoAsync", { root: true });
      const { getAuthState } = getters;
      const password = payload && payload.password;
      const userId = getAuthState.IdentityDecoded.PK_User;
      const config = {
        token: {
          Identity: getAuthState.Identity,
          IdentitySignature: getAuthState.IdentitySignature
        },
        baseUrl: !fetchUserInfoRetry
          ? getAuthState.Server_Account
          : getAuthState.Server_Account_Alt
      };

      const response = await authService.getUserInfo(userId, config);
      const { data, statusCode } = await parseHttpResponse(response);

      if (statusCode !== 200) {
        if (statusCode === 401) {
          throw new HttpError("User not logged in", statusCode);
        }
        throw new HttpError(data, statusCode);
      }
      const userInfo = {
        ...getParsedUserInfo(data),
        password
      };
      commit(SET_AUTH_USER_GET_INFO, userInfo);
    } catch (error) {
      if (!fetchUserInfoRetry) {
        console.log("fetchUserInfoAsync RETRYING WITH ALT SERVER...."); //eslint-disable-line
        fetchUserInfoRetry = true;
        dispatch("fetchUserInfoAsync", payload);
      } else {
        fetchUserInfoRetry = false;
          console.log("fetchUserInfoAsync ERROR:", error); //eslint-disable-line
        dispatch(
          "ui/emitError",
          {
            error: error.message,
            meta: "fetchUserInfoAsync",
            code: error.code
          },
          {
            root: true
          }
        );
      }
    } finally {
      dispatch("ui/stopFetching", "fetchUserInfoAsync", { root: true });
    }
  },
  async updateUserInfoAsync({ dispatch, commit, getters }, payload) {
    try {
      dispatch("ui/startFetching", "changeName", { root: true });
      const { firstName, lastName, email, phoneNumber } = payload;
      const userData = { ...payload };

      const { getAuthState, getUser } = getters;
      const password = getUser.password;
      const userId = getAuthState.IdentityDecoded.PK_User;
      const config = {
        token: {
          Identity: getAuthState.Identity,
          IdentitySignature: getAuthState.IdentitySignature
        },
        baseUrl: getAuthState.Server_Account
      };

      const response = await authService.updateUserInfo(
        userId,
        userData,
        config
      );
      const { data, statusCode } = await parseHttpResponse(response, "string");

      if (statusCode !== 200) {
        if (statusCode === 401) {
          throw new HttpError("User not logged in", statusCode);
        }
        if (statusCode === 403) {
          throw new HttpError(data, statusCode);
        }
        throw new HttpError(data, statusCode);
      }

      await dispatch("fetchUserInfoAsync", { password });
      commit(SET_AUTH_USER_UPDATE_INFO);
    } catch (error) {
      // eslint-disable-next-line
      console.log("updateUserInfoAsync ERROR:", error); //eslint-disable-line
      dispatch(
        "ui/emitError",
        {
          type: error.code === 403 ? undefined : "errorRequestConnection",
          error: error.message,
          meta: "updateUserInfoAsync",
          code: error.code
        },
        {
          root: true
        }
      );
    } finally {
      dispatch("ui/stopFetching", "changeName", { root: true });
    }
  },
  async sendShareActivityAsync({ dispatch }) {
    try {
      await dispatch("fetchingShareActivity", true);
      await dispatch("location/updateLocationSignupAsync", null, {
        root: true
      });
      // TODO: add api call if user agree share activity or not
    } catch (err) {
      // eslint-disable-next-line
      console.log("sendShareActivityAsync ERROR:", err); //eslint-disable-line
      dispatch(
        "ui/emitError",
        {
          error: err,
          meta: "sendShareActivityAsync",
          code: err.code
        },
        {
          root: true
        }
      );
    } finally {
      dispatch("fetchingShareActivity", false);
    }
  },
  async sendWithoutShareActivityAsync({ dispatch }) {
    try {
      await dispatch("fetchingShareInactivity", true);
      await dispatch("location/updateLocationSignupAsync", null, {
        root: true
      });
    } catch (err) {
      // eslint-disable-next-line
      console.log("sendWithoutShareActivityAsync ERROR:", err); //eslint-disable-line
      dispatch(
        "ui/emitError",
        {
          error: err,
          meta: "sendWithoutShareActivityAsync",
          code: err.code
        },
        {
          root: true
        }
      );
    } finally {
      dispatch("fetchingShareInactivity", false);
    }
  },
  async resetAppStates({ dispatch }) {
    dispatch("ui/resetState", null, { root: true });
    await Promise.all([
      dispatch("auth/resetState", null, { root: true }),
      dispatch("location/resetState", null, { root: true }),
      dispatch("location/resetLocationAllDataAsync", null, { root: true }),
      dispatch("devices/resetState", null, { root: true }),
      dispatch("scenes/resetScenes", null, { root: true }),
      dispatch("rooms/resetState", null, { root: true }),
      dispatch("energy/resetDevices", null, { root: true })
    ]);
    console.log("States has been re-set"); //eslint-disable-line
  },
  async fetchCloudJwtAsync(_, payload) {
    const response = await authService.cloudJwtTokenApi(payload);
    const { data, statusCode } = await parseHttpResponse(response, "json");

    if (statusCode !== 200) {
      if (statusCode === 401) {
        throw new HttpError("User not logged in", statusCode);
      }
      throw new HttpError(data.details.error, statusCode);
    }
    return data.token;
  },
  async getEzloProtect({ state, dispatch, commit, getters }) {
    try {
      dispatch("ui/startFetching", "getEzloProtect", { root: true });
      const { getAuthState } = getters;
      const userId = getAuthState.IdentityDecoded.PK_User;
      const config = {
        token: {
          Identity: getAuthState.Identity,
          IdentitySignature: getAuthState.IdentitySignature
        },
        baseUrl: !fetchUserInfoRetry
          ? getAuthState.Server_Account
          : getAuthState.Server_Account_Alt
      };

      const servers = await authService.getServers(config);
      const {
        data: { Server: server },
        statusCode
      } = await parseHttpResponse(servers);

      console.log("### auth/actions/getEzloProtect servers", server); //eslint-disable-line
      if (server && server.length) {
        const msp = server.find(item => item.Role === "msp");
        if (msp) {
          config["baseUrl"] = msp.Host;
          const response = await authService.getEzloProtect(config);
          const { data, statusCode } = await parseHttpResponse(response);
          console.log("### auth/actions/getEzloProtect", { statusCode, data }); //eslint-disable-line
          dispatch("setEzloProtect", data);
        }
      }

      if (statusCode !== 200) {
        if (statusCode === 401) {
          throw new HttpError("User not logged in", statusCode);
        }
        throw new HttpError(data, statusCode);
      }
    } catch (err) {
      console.log("getEzloProtect ERROR:", err); //eslint-disable-line
    } finally {
      dispatch("fetchingShareInactivity", false);
    }
  },
  setEzloProtect({ commit }, payload) {
    const { AdditionalServices } = payload;
    // Ezlo-Protect subsicription to be check.
    // state = 5 Then true, The user has a subscription.
    const trial = AdditionalServices && AdditionalServices["1"].state === 5;
    const hasEzloProtectSubsicription = trial === true;
    commit("SET_EZLO_PROTECT", hasEzloProtectSubsicription);
  }
  // async fetchCloudAccessTokensAsync({ dispatch }, payload) {
  //   const response = await authService.cloudAccessTokensApi(payload);
  //   const { statusCode, content } = response;
  //   if (statusCode !== 200) {
  //     throw new HttpError("Api cloud error or other", statusCode);
  //   }
  //   const { status, complete, data } = content.toJSON();
  //   if (status === 0 && complete === 1) {
  //     throw new HttpError(data.error_text, status);
  //   }
  //   try {
  //     const arrFlatItems = Object.entries(data.keys);
  //     const arrReadyItems = arrFlatItems.map(item => ({
  //       id: item[0],
  //       ...item[1]
  //     }));
  //     return arrReadyItems.length ? arrReadyItems[0].meta : null;
  //   } catch (error) {
  //     console.log("fetchCloudAccessTokensAsync Error:", error); //eslint-disable-line
  //     dispatch(
  //       "ui/emitError",
  //       {
  //         error: error.message,
  //         type: "errorAvailableEzloHubs",
  //         meta: "errorAvailableEzloHubs",
  //         code: error.code
  //       },
  //       {
  //         root: true
  //       }
  //     );
  //   }
  // }
};

export default actions;
