const getInt = (function*() {
  let i = 0;
  while (true) {
    yield ++i;
  }
})();

const createEnum = enumKeysArray =>
  Object.freeze(
    enumKeysArray.reduce((acc, enumVal) => {
      acc[enumVal] = getInt.next().value;
      return acc;
    }, {})
  );

export default createEnum;
