export function liveLabelFormatter(value) {
  const timeMap = {
    1: "8",
    9: "9"
  };
  const ampm = Number(value) < 12 ? "am" : "pm";
  return timeMap[Number(value)] ? timeMap[Number(value)] + ampm : "";
}
export function dayLabelFormatter(value) {
  const timeMap = {
    0: "12",
    6: "6",
    12: "12",
    18: "6"
  };
  const ampm = Number(value) < 12 ? "am" : "pm";
  return timeMap[Number(value)] ? timeMap[Number(value)] + ampm : "";
}

export function weekLabelFormatter(value) {
  const timeMap = {
    1: "S",
    2: "M",
    3: "T",
    4: "W",
    5: "T",
    6: "F",
    7: "S"
  };
  return timeMap[Number(value)];
}

export function monthLabelFormatter(value) {
  const timeMap = {
    1: "1",
    8: "8",
    15: "15",
    22: "22",
    29: "29"
  };
  return timeMap[Number(value)] ? timeMap[Number(value)] : "";
}
