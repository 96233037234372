<template>
  <GridLayout
    rows="*, auto, auto"
    columns="*"
    height="100%"
    class="information-dialog"
  >
    <StackLayout row="0" col="0" verticalAlignment="center">
      <SvgIcon :src="icon" :size="size" verticalAlignment="center" />
      <Label
        class="information-dialog--title font-h1-32"
        :text="text1"
        textWrap
      />
      <Label
        class="information-dialog--text font-txt-16-par"
        :text="text2"
        textWrap
      />
    </StackLayout>
    <ButtonFilled
      row="1"
      col="0"
      class="information-dialog--button font-txt-16-btn"
      :warning="warning"
      :text="btnTextAccept"
      :loading="loading"
      @tap="$emit('accept', $event)"
    />
    <ButtonStroke
      row="2"
      col="0"
      v-if="btnTextCancel"
      class="information-dialog--button-cancel"
      :warning="warning"
      :normal="true"
      :text="btnTextCancel"
      :disabled="disableWhileLoading && loading"
      @tap="$emit('cancel', $event)"
    />
  </GridLayout>
</template>
<script>
export default {
  name: "ConfirmationDialog",
  props: {
    icon: {
      type: String,
      default: "icons/Warnings/ic_warning_200.svg"
    },
    text1: {
      type: String
    },
    text2: {
      type: String
    },
    btnTextAccept: {
      type: String,
      default: "Confirm",
      required: false
    },
    btnTextCancel: {
      type: String,
      default: "Cancel",
      required: false
    },
    warning: {
      type: Boolean
    },
    acceptActionName: {
      type: String,
      default: "_accept"
    },
    loading: {
      type: Boolean,
      default: false
    },
    disableWhileLoading: {
      type: Boolean,
      default: true
    },
    size: {
      type: [Number, String],
      default: 200
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~/assets/styles/variables.scss";

.information-dialog {
  height: 100%;
  padding: $padding-s $padding-xl $padding-xl $padding-xl;
  align-items: center;
  &--title {
    text-align: center;
    color: $color-black;
  }
  &--text {
    margin: $margin-s 0 0 0;
    text-align: center;
    color: $color-black;
  }
  &--button {
    grid-row: 2;
    grid-column: 1;
    margin-top: $xxxl;
    color: $color-white;
  }
  &--button-cancel {
    grid-row: 3;
    grid-column: 1;
  }
}
</style>
