export default {
  getCategories: state => state.categories,
  getIconsByCategory: (state, getters) => (
    deviceCategory,
    deviceSubCategory
  ) => {
    const defaultIcons = state.categories.icons;
    const category = state.categories[deviceCategory];
    const subcategory = category && category.subcategory[deviceSubCategory];
    const categoryIcons = category && category.icons;
    const subCategoryIcons = subcategory && subcategory.icons;
    return { ...defaultIcons, ...categoryIcons, ...subCategoryIcons };
  },
  getAllIcons: (state, getters) => version => {
    let icons = {};
    const versionKey = version === "v2" ? "defaultSrc" : "ui7";
    const categories = getters.getCategories;
    Object.entries(categories).forEach(([key, value]) => {
      icons[key] = new Set();
      if (value.icons && value.icons[versionKey]) {
        if (
          versionKey === "ui7" &&
          typeof value.icons[versionKey] === "object"
        ) {
          Object.entries(value.icons[versionKey]).forEach(([k, v]) => {
            icons[key].add(v);
          });
        } else {
          icons[key].add(value.icons[versionKey]);
        }
      }

      if (value.subcategory) {
        let subcategory = value.subcategory;
        Object.entries(subcategory).forEach(([key1, value1]) => {
          if (value1.icons && value1.icons[versionKey]) {
            if (
              versionKey === "ui7" &&
              typeof value1.icons[versionKey] === "object"
            ) {
              Object.entries(value1.icons[versionKey]).forEach(([k, v]) => {
                icons[key].add(v);
              });
            } else {
              icons[key].add(value1.icons[versionKey]);
            }
          }
        });
      }
    });
    return icons;
  },
  getIconListByCategory: (state, getters) => (deviceCategory, subcategory) => {
    const subCategories = state.categories[deviceCategory]
      ? state.categories[deviceCategory].subcategory
      : null;
    let icons = [];
    if (subCategories) {
      icons.push(
        getters.getIconsByCategory(deviceCategory, subcategory).defaultSrc
      );
      Object.entries(subCategories).forEach(([key, value]) => {
        if (value.icons && value.icons.ui7) {
          const ui7Icons = value.icons.ui7;
          if (typeof ui7Icons === "object") {
            Object.entries(ui7Icons).forEach(([key, v]) => {
              icons.push(v);
            });
          } else {
            icons.push(ui7Icons);
          }
        }
      });
    }
    return icons;
  },
  getCategoryByVeraId: state => id => {
    const def = state.categories.icons;
    Object.entries(state.categories).forEach(([key, value]) => {
      if (value.id === id) {
        def = value;
        return;
      }
    });
    return def;
  },
  getCategoryName: (state, getters) => (
    deviceCategory,
    deviceSubCategory = ""
  ) => {
    const category = state.categories[deviceCategory];
    const subcategory = category && category.subcategory[deviceSubCategory];
    const categoryName = category && category.name;
    const subCategoryName = subcategory && subcategory.name;
    return subCategoryName || categoryName;
  },
  getCategoryStates: (state, getters) => deviceCategory => {
    return deviceCategory
      ? state.categories[deviceCategory].states
      : [{ name: "general" }];
  }
};
