import Vue from "@/vue";
import { cloneDeep } from "lodash";
import { defaultDesignProperties } from "./state";

import {
  SET_TEMPLATE,
  SET_TILE,
  SELECT_TEMPLATE,
  DELETE_TILE,
  CLEAN_TEMPLATE,
  SET_TEMPLATE_LANDSCAPE,
  CLEAN_TEMPLATE_LANDSCAPE,
  CLEAN_SELECT_TEMPLATE,
  SET_DESIGN_PROPERTIES,
  DEFAULT_DESIGN_PROPERTIES,
  RESET_DESIGN_PROPERTIES,
  SAVE_DESIGN_PROPERTIES,
  CLEAN_TEMPLATE_PORTRAIT,
  SAVE_TO_TEMPLATE_BUFFER,
  CLEAN_TEMPLATE_BUFFER,
  SET_TEMPLATE_THEME
} from "./mutationTypes";
import { createTile } from "@/helpers/templateCreator";

export default {
  [SELECT_TEMPLATE](state, { id, orientation }) {
    Vue.set(state.selectedTemplate, orientation, {
      ...state.selectedTemplate[orientation],
      templateId: id
    });
  },
  [CLEAN_SELECT_TEMPLATE](state) {
    Vue.set(state, "selectedTemplate", {
      portrait: {
        templateId: null
      },
      landscape: {
        templateId: null
      }
    });
  },
  [SET_TEMPLATE](state, payload) {
    Vue.set(state, "template", payload);
  },
  [SET_TILE](state, { index, item, orientation, type }) {
    const tile = createTile({ index, item, type });
    const deleteIcon = createTile({ index, type: "delete" });
    const stateObject =
      orientation === "portrait" ? state.template : state.templateLandscape;
    Vue.set(stateObject.children[index], "children", [tile, deleteIcon]);
  },
  [DELETE_TILE](state, { index, orientation, theme }) {
    const stateObject =
      orientation === "portrait" ? state.template : state.templateLandscape;
    Vue.set(stateObject.children[index], "children", [
      createTile({ index, type: "empty", theme })
    ]);
  },
  [CLEAN_TEMPLATE](state) {
    Vue.set(state, "template", {});
  },
  [SET_TEMPLATE_LANDSCAPE](state, payload) {
    Vue.set(state, "templateLandscape", payload);
  },
  // [FILL_TEMPLATE_LANDSCAPE_TILE](state, { index, item }) {
  //   Vue.set(state.templateLandscape.children[index], "children", [
  //     deviceTile(index, item),
  //     deleteIcon(index)
  //   ]);
  // },
  // [DELETE_TEMPLATE_LANDSCAPE_TILE](state, index) {
  //   Vue.set(state.templateLandscape.children[index], "children", [
  //     emptyTemplate(index)
  //   ]);
  // },
  [CLEAN_TEMPLATE_LANDSCAPE](state) {
    Vue.set(state, "templateLandscape", {});
  },
  [CLEAN_TEMPLATE_PORTRAIT](state) {
    Vue.set(state, "template", {});
  },
  [SET_DESIGN_PROPERTIES](state, payload) {
    const { tileState, key, value, index, orientation, data } = payload;
    const attribute =
      orientation === "portrait" ? "template" : "templateLandscape";

    const {
      props: { design_properties: currentDesignProperties }
    } = state[attribute].children[index].children[0].children[0];

    // hold old Value
    if (!state.designPropertiesChanged) {
      console.dir(currentDesignProperties); //eslint-disable-line
      const oldDesignProperties = JSON.parse(
        JSON.stringify(currentDesignProperties ? currentDesignProperties : {})
      );
      Vue.set(state, "designPropertiesChanged", {
        ...defaultDesignProperties(),
        ...oldDesignProperties
      });
    }
    let design = state.designPropertiesChanged[tileState];
    design = { ...design, ...data };
    Vue.set(state.designPropertiesChanged, tileState, design);
  },
  [DEFAULT_DESIGN_PROPERTIES](state, payload) {
    const { index, orientation, tileState } = payload;
    if (!state.designPropertiesChanged) {
      const key = orientation === "portrait" ? "template" : "templateLandscape";
      const { design_properties } = state[key].children[
        index
      ].children[0].children[0].props;

      Vue.set(state, "designPropertiesChanged", { ...design_properties });
    }
    Vue.set(state.designPropertiesChanged, tileState, {
      ...defaultDesignProperties()[tileState]
    });
  },
  [RESET_DESIGN_PROPERTIES](state) {
    Vue.set(state, "designPropertiesChanged", null);
  },
  [SAVE_DESIGN_PROPERTIES](state, payload) {
    const { index, orientation } = payload;
    const key = orientation === "portrait" ? "template" : "templateLandscape";
    const { props } = state[key].children[index].children[0].children[0];
    let deviceState = "general";
    Vue.set(props, "design_properties", { ...state.designPropertiesChanged });

    for (const key in state.designPropertiesChanged) {
      if (
        typeof state.designPropertiesChanged[key] === "object" &&
        key !== "general"
      ) {
        deviceState = key;
        break;
      }
    }

    Vue.set(props, "state", deviceState);
  },
  [SAVE_TO_TEMPLATE_BUFFER](state) {
    const templateBuffer = {
      template: cloneDeep(state.template),
      templateLandscape: cloneDeep(state.templateLandscape)
    };
    Vue.set(state, "templateBuffer", templateBuffer);
  },
  [CLEAN_TEMPLATE_BUFFER](state) {
    Vue.set(state, "templateBuffer", null);
  },
  [SET_TEMPLATE_THEME](state, payload) {
    const theme = {
      general: { ...payload.default },
      ...payload
    };
    delete theme.default;
    // portrait
    if (state.template.children) {
      state.template.children.forEach(item => {
        let tile =
          item.children[0].children && item.children[0].children[0].props;
        if (tile) {
          tile["theme"] = { ...tile["theme"], ...theme };
        }
      });
    }
    // landscape
    if (state.templateLandscape.children) {
      state.templateLandscape.children.forEach(item => {
        let tile =
          item.children[0].children && item.children[0].children[0].props;
        if (tile) {
          tile["theme"] = { ...tile["theme"], ...theme };
        }
      });
    }
  }
};
