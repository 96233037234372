import request from "./http";
import endpoints from "./endpoints";
import stringInject from "stringinject";

const { hub } = endpoints;

const hubService = {
  async getHub(config = {}) {
    const headers = {
      MMSAuth: config.token.Identity,
      MMSAuthSig: config.token.IdentitySignature
    };

    const url = stringInject(hub.get, { PK_Device: config.hubSerial });
    const requestOptions = {
      url,
      baseUrl: config.baseUrl,
      headers,
      method: "GET"
    };

    return await request(requestOptions);
  },
  async getHubServers(config = {}) {
    const headers = {
      MMSAuth: config.token.Identity,
      MMSAuthSig: config.token.IdentitySignature
    };

    const url = stringInject(hub.getServers, { PK_Device: config.hubSerial });
    const requestOptions = {
      url,
      baseUrl: config.baseUrl,
      headers,
      method: "GET"
    };

    return await request(requestOptions);
  },
  async getRelayToken(config = {}) {
    const headers = {
      MMSAuth: config.token.Identity,
      MMSAuthSig: config.token.IdentitySignature
    };

    const url = hub.relayToken;
    const requestOptions = {
      url,
      baseUrl: config.baseUrl,
      headers,
      method: "GET"
    };

    return await request(requestOptions);
  }
};

export default hubService;
