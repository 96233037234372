import request from "./http";
import endpoints from "./endpoints";
import stringInject from "stringinject";

const legacy = {
  async runScene(sceneId, hub, options) {
    // http://127.0.0.1:3480/data_request?id=lu_action&serviceId=urn:micasaverde-com:serviceId:HomeAutomationGateway1&action=RunScene&SceneNum=x

    const baseUrl = options.baseUrl;
    const url = stringInject(endpoints.legacy.runScene, {
      sceneId,
      PK_Device: hub,
      relayToken: options.relayToken
    });

    return await request({
      method: "POST",
      baseUrl,
      url
    });
  }
};

export default legacy;
