<template>
  <MainPage>
    <StackLayout>
      <IBtnHiBtn
        icon1Src="icons/Controls/ic_controls_arrow-left_white_56.svg"
        :icon2Src="
          isEditMode ? 'icons/Controls/ic_controls_delete_white_56.svg' : ''
        "
        :text="isEditMode ? translations.titleEdit : translations.titleNew"
        @iconTap1="handleBack"
        @iconTap2="handleDelete"
        class="nvd__title"
      />
    </StackLayout>

    <GridLayout rows="*, auto" columns="*" class="nvd">
      <ScrollView height="100%" iosOverflowSafeArea="false">
        <GridLayout row="0" col="0" rows="auto, auto, auto, *">
          <GridLayout
            row="0"
            col="0"
            rows="*, auto"
            columns="*"
            class="nvd__test"
          >
            <StackLayout
              row="0"
              col="0"
              height="100%"
              class="nvd__test__container"
            >
              <onOffCapability
                class="nvd__test__container__capability"
                :icon1Src="capabilities[0].icon"
                alignText="left"
                headerSize="h3"
                :text="capabilities[0].category"
                :hover="false"
                skeleton
                @tap="onTapCapability(capabilities[0], $event)"
                @onAction="onTestAction"
              />

              <RangeSlider
                :class="{ nvd__test__container__slider: isWeb }"
                minValue="0"
                maxValue="100"
                height="100"
                width="80%"
                :value="45"
                backgroundColor="#3e46ae"
              />
            </StackLayout>
            <StackLayout row="1" col="0" class="nvd__test__decription">
              <VxpLabel class="font-txt-13 " :text="translations.description" />
            </StackLayout>
          </GridLayout>
          <StackLayout row="1" col="0" class="nvd__input">
            <Label
              class="nvd__input__label font-txt-16-par"
              :text="translations.inputLabel"
              textWrap
            />
            <TextInput
              v-model="$v.name.$model"
              type="text"
              showSoftInput
              eraseButton
              returnKeyType="done"
              :hintText="translations.inputHint"
              hintTextWrap
              @returnPress="handleSubmit"
            />
          </StackLayout>
          <StackLayout row="2" col="0">
            <VxpLabel
              class="nvd__group-title font-h2-24"
              :text="translations.groupTitle"
              :textWrap="true"
            />
          </StackLayout>
          <StackLayout row="3" col="0">
            <StackLayout v-for="item in capabilities" :key="item.id">
              <Separator size="xl" />
              <IconHTxtiBtn
                :icon1Src="item.src"
                icon2Src="icons/Controls/ic_controls_arrow-right_56.svg"
                alignText="left"
                alignUnderline="left"
                headerSize="h3"
                showUnderline
                :icon1Size="40"
                :text="item.name"
                :underlineText="item.decription"
                @tap="handleCapabilities(item)"
              />
            </StackLayout>
            <Separator size="xl" />
            <StackLayout>
              <IconH
                class="nvd__more-capabilities"
                src="icons/Controls/ic_controls_plus_warning_56.svg"
                :text="translations.actionText"
                @tap="addCapabilities"
              />
              <Separator size="xl" />
            </StackLayout>
          </StackLayout>
        </GridLayout>
      </ScrollView>
      <StackLayout row="2" col="0" class="nvd__btn-container">
        <ButtonFilled
          :disabled="isDisabled"
          :text="translations.textBtn"
          @tap="handleSaveDevice"
        />
      </StackLayout>
    </GridLayout>
  </MainPage>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import { locals } from "@/helpers/variables";
const { VUE_APP_MODE } = process.env;
export default {
  name: "NewVirtualDevice",
  props: {
    isEditMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      name: "",
      selectedCapabilities: []
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.selectedCapabilities = this.getSelectedCapabilities;
    });
  },
  computed: {
    isWeb() {
      return VUE_APP_MODE === "web";
    },
    abstractDevices() {
      return {
        name: "Light in living room",
        eMail: "user@gmail.com",
        src: "icons/Smarthomes/ic_smarthomes_amazon_alexa.svg"
      };
    },
    capabilities() {
      return [
        {
          id: "1",
          name: "Turn on/off",
          decription: "Light in living room",
          src: "icons/Smarthomes/ic_smarthomes_amazon_alexa.svg",
          category: "Light",
          icon: "icons/Category/ic_category_virtual_device_56.svg"
        },
        {
          id: "2",
          name: "Set dimmer",
          decription: "Light in living room",
          src: "icons/Smarthomes/ic_smarthomes_amazon_alexa.svg",
          category: "Light",
          icon: "icons/Category/ic_category_virtual_device_56.svg"
        }
      ];
    },
    getSelectedCapabilities() {
      return this.capabilities.filter(item => item.checked);
    },
    selectedCapabilitiesCount() {
      // TODO
      return this.selectedCapabilities.length || "";
    },
    translations() {
      // eslint-disable-next-line
      return this.$t("newVirtualDevice");
    },
    isDisabled() {
      //TODO
      return this.$v.name.$invalid;
    }
  },
  methods: {
    handleBack() {
      this.navigateBack();
    },
    handleDelete() {
      console.log("handleDelete"); // eslint-disable-line
    },
    handleSaveDevice() {
      console.log("handleSaveDevice"); // eslint-disable-line
    },
    addCapabilities() {
      console.log("addCapabilities"); //eslint-disable-line
    },
    handleCapabilities(capabilitiy) {
      console.log("handleCapabilities", capabilitiy); //eslint-disable-line
    },
    onTapCapability(capabilitiy, event) {
      console.log("onTapCapability", { capabilitiy, event }); //eslint-disable-line
    },
    onTestAction(event) {
      console.log("onTestAction", event); //eslint-disable-line
    },
    handleSubmit() {
      console.log("handleSubmit:", this.name); //eslint-disable-line
    },
    getColor(color) {
      return locals[color];
    }
  },
  validations() {
    return {
      name: {
        required
      }
    };
  }
};
</script>
<style lang="scss" scoped>
@import "~/assets/styles/variables.scss";

.nvd {
  user-select: none;
  height: 100%;
  &__title {
    background-color: #fd9927;
    /deep/ & * {
      color: $color-white;
    }
  }
  &__test {
    background-color: #fd9927;
    padding: $padding-xl;
    &__container {
      height: 100%;
      background-color: $color-white;
      border-radius: $l;
      box-shadow: 0px 4px 8px rgba(144, 160, 175, 0.7);
      &__capability {
        margin: $xl;
      }
      &__slider {
        margin: $xl;
      }
    }
    &__decription {
      margin-top: $s;
      color: $color-white;
    }
  }
  &__input {
    padding: $xl;
    &__label {
      margin-bottom: $s;
      text-align: left;
      color: $color-black;
    }
  }
  &__btn-container {
    padding: 0 $xl $xxl $xl;
  }
  &__hint {
    text-align: left;
    color: $color-grey;
  }
  &__alexaImage {
    grid-column: 2;
    justify-self: end;
    width: unit(124);
    height: unit(124);
  }
  &__googleImage {
    grid-column: 3;
    justify-self: start;
    width: unit(124);
    height: unit(124);
  }
  &__group-title {
    color: $color-black;
    margin: $lmx $xl $xs $lmx;
  }
  &__more-capabilities {
    &:hover {
      cursor: pointer;
      background-color: $color-grey-a15;
    }
  }
}
</style>
