<template>
  <MainPage>
    <StackLayout>
      <IBtnHiBtn
        icon1Src="icons/Controls/ic_controls_arrow-left_56.svg"
        @iconTap1="navigateBack"
        :text="translations.textTitle"
      />
      <Separator size="xl" />
    </StackLayout>
    <KeyboardAvoidingView
      :fillHeight="isAndroid"
      :iosTextInputKeyboardDistance="210"
      contentVerticalAlign="bottom"
    >
      <DashboardNameForm
        slot="content"
        :btnText="translations.finish"
        :name="getNewDashboardName"
        :label="translations.label"
        @finish="finish"
        :loading="isFetching('createDashboardAsync')"
      />
    </KeyboardAvoidingView>
  </MainPage>
</template>
<script>
import { createNamespacedHelpers } from "vuex";
import platform from "vuexp/src/platform";
import DashboardNameForm from "@/components/forms/DashboardNameForm";

const {
  mapActions: mapDashboardActions,
  mapGetters: mapDashboardGetters
} = createNamespacedHelpers("dashboards");
const { mapActions: mapTemplateActions } = createNamespacedHelpers("template");
const {
  mapGetters: mapUiGetters,
  mapActions: mapUiActions
} = createNamespacedHelpers("ui");

export default {
  name: "DashboardName",
  components: {
    DashboardNameForm
  },
  props: {
    name: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      dashboardName: this.name,
      isUsingOneTemplate: false
    };
  },
  computed: {
    ...mapUiGetters(["isFetching"]),
    ...mapDashboardGetters([
      "getNewDashboardName",
      "getNewDashboard",
      "getDashboards"
    ]),
    ...mapUiGetters(["hasError"]),
    translations() {
      return {
        finish: this.$t("common.finish"),
        textTitle: this.$t("newDashboard.pleaseNameYourDashboard"),
        textSnackbar: this.$t("dashboardTemplate.textSnackbar"),
        label: this.$t("newDashboard.dashboardNamePlaceholder")
      };
    },
    isAndroid() {
      return platform.platform === "android";
    },
    dashboardCount() {
      return this.getDashboards.length;
    }
  },
  methods: {
    ...mapDashboardActions(["setNewDashbardName", "createDashboardAsync"]),
    ...mapTemplateActions([
      "resetTemplateSelected",
      "resetTemplatePortrait",
      "resetTemplateLandscape"
    ]),
    ...mapUiActions(["saveSnackbarText"]),
    async finish(event) {
      await this.setNewDashbardName(event);
      await this.createDashboardAsync(this.getNewDashboard);
      if (this.hasError) {
        return;
      }
      this.resetTemplateSelected();
      this.resetTemplatePortrait();
      this.resetTemplateLandscape();
      this.$root.$emit("setIndex", this.dashboardCount - 1);
      this.navigateTo("DashboardPreview", { clearHistory: true });
      setTimeout(() => {
        this.saveSnackbarText(
          this.translations.textSnackbar.textCreateSuccessful
        );
      }, 150);
    }
  }
};
</script>
