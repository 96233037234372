<template>
  <DockLayout
    v-if="offline"
    class="offline-lock"
    width="100%"
    height="100%"
    stretchLastChild="false"
    isPassThroughParentEnabled="false"
    @tap="() => {}"
  >
    <StackLayout dock="bottom" class="offline-bar">
      <Label
        class="offline-bar-text"
        horizontalAlignment="center"
        :text="texts.textTitle"
      />
    </StackLayout>
  </DockLayout>
</template>

<script>
import { helpers } from "ezlo-core-ui";
import { createNamespacedHelpers } from "vuex";
const { mapGetters: mapUiGetters } = createNamespacedHelpers("ui");
const { forceHideKeyboard } = helpers.core;

export default {
  name: "OfflineLock",
  computed: {
    ...mapUiGetters(["offline"]),
    texts() {
      return this.$t("offlineMode"); // eslint-disable-line
    }
  },
  watch: {
    offline() {
      forceHideKeyboard();
    }
  }
};
</script>

<style lang="scss">
@import "~assets/styles/variables.scss";

.offline-lock {
  background-color: $color-grey-a15;
  z-index: 1000;
}

.offline-bar {
  background-color: $color-warning;

  &-text {
    padding: $padding-s;
    text-align: center;
    color: $color-white;
  }
}
</style>
