<template>
  <GridLayout rows="*" columns="*" height="100%">
    <StackLayout row="0" class="details-view">
      <DailyAverage
        class="details-view__padding"
        :title="energy.climate.livingRoom.dailyAverage"
        :titleDesc="energy.measurementUnit"
        :desc1="translations.dailyAverage"
        :desc2="statusDesc"
      />
      <VxpImage
        class="details-view__graph"
        :src="graphImage"
        width="auto"
        height="auto"
        stretch="aspectFill"
        verticalAlignment="center"
      />
      <MonthlyConsumption
        class="details-view__montly"
        :monthToDate="`${energy.climate.monthToDate}${energy.measurementUnit}`"
        :monthToDateDesc="translations.monthToDate"
        :monthly="`${energy.climate.monthly}`"
        :montlyDesc="energy.longMonthName"
      />
      <GridLayout rows="auto" columns="auto, *">
        <StackLayout row="0" class="details-view__controls">
          <VxpLabel
            class="details-view__controls__Text font-h2-24"
            :text="translations.control"
            :textWrap="true"
          />
          <StackLayout @tap="onControlTap('Thermostat')">
            <ControlItem
              class="details-view__action"
              :action="controls"
              primary
              width="103"
            />
          </StackLayout>
        </StackLayout>
      </GridLayout>
    </StackLayout>
  </GridLayout>
</template>

<script>
import { locals } from "../../assets/styles/variables.scss";
import DailyAverage from "./DailyAverage";
import MonthlyConsumption from "./MonthlyConsumption";
import ControlItem from "./ControlItem";

export default {
  name: "ConsumptionDetailsOverview",
  components: { DailyAverage, MonthlyConsumption, ControlItem },
  props: {
    energy: {
      type: Object,
      default: () => ({})
    },
    graphImage: {
      type: String,
      default: "~/assets/images/Energy/Month-30-Days-2.png"
    },
    iconThermostat: {
      type: String,
      default: "icons/Climate/ic_climate_thermostat_white_56.svg"
    }
  },
  computed: {
    controls() {
      return {
        iconSrc: this.iconThermostat,
        status: this.translations.on,
        deviceName: this.translations.thermostat,
        room: this.translations.livingRoom
      };
    },
    translations() {
      return {
        dailyAverage: this.$t("energy.dailyAverage"),
        monthToDate: this.$t("energy.monthToDate"),
        climate: this.$t("energy.climate"),
        thermostatInLivingRoom: this.$t("energy.thermostatInLivingRoom"),
        heaterInBedroom: this.$t("energy.heaterInBedroom"),
        control: this.$t("energy.control"),
        on: this.$t("deviceState.on"),
        livingRoom: this.$t("roomType.livingRoom"),
        thermostat: this.$t("energy.thermostat")
      };
    },
    statusDesc() {
      const { longMonthName } = this.energy;
      const { differencePercentage } = this.energy.climate;
      return this.$t("energy.fromMonth", {
        differencePercentage,
        longMonthName
      });
    },
    colorSec3() {
      return locals.colorSec3;
    }
  },
  methods: {
    onControlTap(event) {
      this.$emit("onControlTap", event);
    }
  }
};
</script>

<style lang="scss">
@import "~/assets/styles/variables.scss";

.details-view {
  &__desc {
    color: $color-grey;
  }
  &__desc-status {
    color: $color-primary;
  }
  &__padding {
    padding: 0 $padding-xl $m $padding-xl;
  }
  &__graph {
    padding: 0 $padding-xl $padding-xl $padding-xl;
  }
  &__montly {
    padding: $padding-xl $padding-xl $m $padding-xl;
  }
  &__controls {
    padding: $padding-xl 0 $s $padding-xl;
    &__Text {
      margin-bottom: $margin-l;
    }
  }
  &__action {
    padding-bottom: $s;
  }
}
</style>
