<template>
  <MainPage @layoutChanged="onlayoutChanged">
    <GridLayout rows="*, auto" columns="*">
      <KeyboardAvoidingView
        row="0"
        col="0"
        contentVerticalAlign="bottom"
        :iosTextInputKeyboardDistance="iosTextInputKeyboardDistance"
      >
        <IBtnAct
          slot="action-bar"
          src="icons/Controls/ic_controls_arrow-left_56.svg"
          @tap="handleBack"
        />

        <FullNameForm
          slot="content"
          :isLoading="isFetching('changeName')"
          :firstFieldProp="firstName"
          :lastFieldProp="lastName"
          :titleText="translations.textTitle"
          :firstFieldPlaceholderText="translations.textPlaceholderFirstName"
          :lastFieldPlaceholderText="translations.textPlaceholderLastName"
          :btnText="translations.textBtn"
          @submit="handleSubmit"
          @layoutChange="handleKeyboard"
        />
      </KeyboardAvoidingView>
    </GridLayout>
  </MainPage>
</template>

<script>
import { helpers } from "ezlo-core-ui";
import FullNameForm from "@/components/forms/FullNameForm";
import { createNamespacedHelpers } from "vuex";

const {
  mapGetters: mapUiGetters,
  mapActions: mapUiActions
} = createNamespacedHelpers("ui");
const { mapActions: mapAuthActions } = createNamespacedHelpers("auth");

const { IOS_TEXT_INPUT_KEYBOARD_OFFSET_DEFAULT } = helpers.appConst;
const IOS_TEXT_INPUT_KEYBOARD_OFFSET = 220;

export default {
  name: "ProfileFullName",
  components: {
    FullNameForm
  },
  props: {
    firstName: {
      type: String,
      default: ""
    },
    lastName: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      areInputsVisible: false
    };
  },
  computed: {
    ...mapUiGetters(["isFetching", "hasError"]),
    iosTextInputKeyboardDistance() {
      return this.areInputsVisible
        ? IOS_TEXT_INPUT_KEYBOARD_OFFSET_DEFAULT
        : IOS_TEXT_INPUT_KEYBOARD_OFFSET;
    },
    translations() {
      return this.$t("profileFullName");
    }
  },
  methods: {
    ...mapUiActions(["saveSnackbarText"]),
    ...mapAuthActions(["updateUserInfoAsync"]),
    handleBack() {
      this.navigateTo("Profile");
    },
    handleKeyboard(areInputsVisible) {
      this.areInputsVisible = areInputsVisible;
    },
    async handleSubmit(values) {
      const { firstField, lastField } = values;
      const data = {
        firstName: firstField,
        lastName: lastField
      };

      await this.updateUserInfoAsync(data);
      this.saveSnackbarText(this.translations.textSnackbar);

      if (this.hasError) {
        return;
      }

      this.navigateTo("Profile");
    },
    onlayoutChanged(event) {
      if (event.object.android) {
        event.object.android.setFocusable(true);
        event.object.android.setFocusableInTouchMode(true);
      }
    }
  }
};
</script>
