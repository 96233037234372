export default function() {
  return {
    isPromoShown: true,
    dashboards: [],
    dashboardsChanged: null,
    stylesChanged: null,
    tutorialApproved: false,
    newDashboard: {
      name: "",
      portrait: null,
      landscape: null
    },
    setDefStyleSettings: {
      general: {
        backgroundColor: "#ffffff",
        use_background_image: false,
        background_image: "",
        fontFamily: "ezlo-sans",
        fontSize: "16"
      },
      theme: {
        portrait: {
          theme_id: "light"
        },
        landscape: {
          theme_id: "light"
        }
      }
    },
    defaultImagePresets: [
      {
        id: "1",
        // src: "~/assets/images/Settings/settings-bg-1.jpg",
        src:
          "https://vera-us-oem-storage25.mios.com/storage/storage/store/921388880/archive?Key=3267601347",
        selected: false
      },
      {
        id: "2",
        // src: "~/assets/images/Settings/settings-bg-2.jpg",
        src:
          "https://vera-us-oem-storage25.mios.com/storage/storage/store/921389055/archive?Key=1447263414",
        selected: false
      },
      {
        id: "3",
        // src: "~/assets/images/Settings/settings-bg-3.jpg",
        src:
          "https://vera-us-oem-storage25.mios.com/storage/storage/store/921389125/archive?Key=3291283821",
        selected: false
      }
    ],
    imagesKVS: [],
    customIcons: [],
    fontFamilies: [
      {
        id: "ezlo-sans",
        name: "ezlo-sans",
        default: true
      },
      {
        id: "Avenir",
        name: "avenir",
        default: false
      },
      {
        id: "serif",
        name: "serif",
        default: false
      },
      {
        id: "monospace",
        name: "monospace",
        default: false
      }
    ]
  };
}
