<template>
  <StackLayout class="orientations">
    <IconHTxtiBtn
      alignText="left"
      headerSize="h3"
      :text="translations.portrait"
      :icon1Src="getPortIcon"
      icon2Src="icons/Controls/ic_controls_arrow-right_56.svg"
      @tap="handleTap('portrait')"
    />
    <Separator size="xl" />
    <IconHTxtiBtn
      alignText="left"
      headerSize="h3"
      :text="translations.landscape"
      :icon1Src="getLandIcon"
      icon2Src="icons/Controls/ic_controls_arrow-right_56.svg"
      @tap="handleTap('landscape')"
    />
  </StackLayout>
</template>

<script>
export default {
  name: "Orientations",
  props: {
    isLandscapeCreated: {
      type: Boolean,
      default: false
    },
    isPortraitCreated: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    translations() {
      return this.$t("orientations");
    },
    getPortIcon(bool) {
      return this.isPortraitCreated
        ? "icons/Controls/ic_controls_check_on_56.svg"
        : "icons/Controls/ic_controls_check_off_56.svg";
    },
    getLandIcon(bool) {
      return this.isLandscapeCreated
        ? "icons/Controls/ic_controls_check_on_56.svg"
        : "icons/Controls/ic_controls_check_off_56.svg";
    }
  },
  methods: {
    handleTap(event) {
      this.$emit("onSelectOrientation", event);
    }
  }
};
</script>
<style scoped lang="scss">
@import "~/assets/styles/variables.scss";

.orientations {
  &__title {
    padding: $padding-xl $padding-xl 0 $padding-xl;
    margin-bottom: $margin-xs;
    text-align: left;
  }
  &__wrap {
    margin-bottom: $margin-m;
  }
}
</style>
