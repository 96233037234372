<template>
  <StackLayout class="calendar-card">
    <GridLayout rows="auto" columns="auto, *, auto">
      <SvgIcon
        class="calendar-card__close-btn"
        row="0"
        col="0"
        src="icons/Controls/ic_controls_close_56.svg"
        @tap="$emit('close')"
      />
      <Label row="0" col="1" />
      <Label
        class="calendar-card__text-btn font-txt-16-btn"
        :text="timeText"
        @tap="goToThisDate"
      />
    </GridLayout>
    <calendar
      v-if="mode !== 'month'"
      class="calendar-card__calendar"
      :min-date="minDate"
      :max-date="maxDate"
      is-expanded
      is-inline
      :attributes="attributes"
      color="green"
      @dayclick="onDateSelected"
      ref="calendar"
      style="border:none;"
    />
    <MonthPicker
      v-else
      :date="date"
      :minDate="minDate"
      :maxDate="maxDate"
      ref="calendar"
      @change="onDateSelected"
    />
    <IconHTxtiBtn
      class="calendar-card__warning"
      icon1Src="icons/Controls/ic_controls_info_56.svg"
      :text="translations.infoText"
      headerSize="s"
      alignText="left"
      :hover="false"
    />
    <ButtonFilled
      :text="translations.apply"
      @tap="applyChanges"
      class="font-txt-16-btn calendar-card__apply-btn"
    />
  </StackLayout>
</template>
<script>
import moment from "moment";
import MonthPicker from "@/components/ui/MonthPicker";

export default {
  name: "CalendarCard",
  components: { MonthPicker },
  mounted() {
    this.$refs.calendar.showPageRange(this.date);
  },
  created() {
    this.$set(this.attributes, 0, {
      highlight: {
        color: "green",
        fillMode: "solid"
      },
      dates: this.date
    });
  },
  data() {
    return {
      attributes: [],
      minDate: moment()
        .subtract(2, "month")
        .startOf("day")
        .toDate(),
      maxDate: moment()
        .endOf("day")
        .toDate(),
      now: moment().toDate()
    };
  },
  props: {
    mode: {
      type: String,
      validator: value => {
        return ["month", "week", "day", "live"].indexOf(value) > -1;
      }
    },
    date: {
      type: Object,
      default: () => ({
        start: new Date(),
        end: new Date()
      })
    }
  },
  methods: {
    createDateObject(date, mode) {
      return {
        start: moment(date)
          .startOf(mode)
          .toDate(),
        end: moment(date)
          .endOf(mode)
          .toDate()
      };
    },
    onDateSelected(event) {
      let dateSpan = event.date;

      if (
        (dateSpan.getTime && dateSpan.getTime() < this.minDate) ||
        (dateSpan.getTime && dateSpan.getTime() > this.maxDate)
      ) {
        return;
      }

      if (!(dateSpan.start && dateSpan.end)) {
        dateSpan = this.createDateObject(event.date, this.mode);
      }

      if (dateSpan.start < this.minDate) {
        dateSpan.start = this.minDate;
      }
      if (dateSpan.end > this.maxDate) {
        dateSpan.end = this.maxDate;
      }

      this.$set(this.attributes, 0, {
        ...this.attributes[0],
        dates: dateSpan
      });
    },
    goToThisDate() {
      let now = moment().toDate();
      this.$refs.calendar.showPageRange(now);
      this.onDateSelected({ date: now });
    },
    applyChanges() {
      this.$emit("change", { type: this.mode, data: this.attributes[0].dates });
    }
  },
  computed: {
    translations() {
      return this.$t("energy").calendar;
    },
    timeText() {
      return this.translations[this.mode];
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~/assets/styles/variables.scss";
@import "~/assets/styles/typography.scss";
.calendar-card {
  user-select: none;
  &__text-btn {
    color: $color-vera;
    padding-right: $xl;
    cursor: pointer;
  }
  &__close-btn {
    cursor: pointer;
  }
  &__calendar {
    /deep/ .vc-title {
      @extend .font-h1-32;
    }
    /deep/ .vc-svg-icon {
      color: $color-black;
      width: $xxl;
      height: $xxl;
    }
  }
  &__apply-btn {
    margin: $margin-xl;
    width: unset;
  }
  &__warning {
    /deep/ * {
      color: $color-grey !important;
    }
  }
  /deep/ .button-filled {
    background: $color-vera;
  }
}
</style>
