<template>
  <MainPage>
    <StackLayout>
      <IBtnHiBtn
        icon1Src="icons/Controls/ic_controls_arrow-left_56.svg"
        :text="command.commandName"
        @iconTap1="handleBack"
        class="devices__header"
      />
    </StackLayout>

    <StackLayout>
      <Separator size="xl" />
      <IconHTxtiBtn
        :icon1Src="abstractDevices.src"
        icon2Src="icons/Controls/ic_controls_arrow-right_56.svg"
        alignText="left"
        alignUnderline="left"
        headerSize="h3"
        showUnderline
        :text="abstractDevices.name"
        :underlineText="abstractDevices.eMail"
        @tap="onTapAbstractDevice"
      />
      <Separator size="xl" />
    </StackLayout>
    <GridLayout rows="*, auto" columns="*" class="device-control">
      <GridLayout rows="*, auto" columns="*" class="add-device__devices">
        <StackLayout row="1" col="0" class="device-control__btn-container">
          <Label
            class="device-control__input-label font-txt-16-par"
            :text="translations.title"
            textWrap
          />
          <TextInput
            v-model="$v.name.$model"
            type="text"
            showSoftInput
            eraseButton
            returnKeyType="done"
            :hintText="translations.subtitle"
            hintTextWrap
            @returnPress="handleSubmit"
            helpButton
            helpIcon="icons/Controls/ic_controls_voice-assistants_56.svg"
          />
          <ButtonFilledSmall
            class="device-control__test font-txt-16-par"
            :text="translations.test"
          />
        </StackLayout>
      </GridLayout>
      <StackLayout row="1" col="0" class="device-control__btn-container">
        <ButtonFilled
          :disabled="isDisabled"
          :text="translations.done"
          @tap="handleCreate"
        />
      </StackLayout>
    </GridLayout>
  </MainPage>
</template>
<script>
import { required } from "vuelidate/lib/validators";

export default {
  name: "EditCommandPage",
  props: {
    commandId: {
      type: String,
      default: "0"
    }
  },
  data() {
    return {
      name: "",
      abstractDevices: {
        name: "Amazon Alexa",
        eMail: "user@gmail.com",
        src: "icons/Smarthomes/ic_smarthomes_amazon_alexa.svg"
      }
    };
  },
  computed: {
    translations() {
      // eslint-disable-next-line
      return this.$t("virtualDevices.editCommand");
    },
    command() {
      return {
        id: "1",
        commandName: "On",
        commandText: "Turn On"
      };
    },
    isDisabled() {
      return this.$v.name.$invalid;
    }
  },
  methods: {
    handleBack() {
      this.navigateBack();
    },
    handleCreate() {
      console.log("handleCreate"); // eslint-disable-line
    },
    handleSubmit() {
      console.log("handleSubmit"); // eslint-disable-line
    },
    onTapAbstractDevice() {
      console.log("onTapAbstractDevice"); // eslint-disable-line
    }
  },
  validations() {
    return {
      name: {
        required
      }
    };
  }
};
</script>
<style lang="scss" scoped>
@import "~/assets/styles/variables.scss";

.device-control {
  height: 100%;
  &__container {
    margin: 0 $xl;
  }
  &__btn-container {
    padding: 0 $xl $xxl $xl;
  }
  &__hint {
    text-align: left;
    color: $color-grey;
  }
  &__input-label {
    margin-bottom: $s;
    text-align: left;
    color: $color-black;
  }
  &__alexaImage {
    grid-column: 2;
    justify-self: end;
    width: unit(124);
    height: unit(124);
  }
  &__googleImage {
    grid-column: 3;
    justify-self: start;
    width: unit(124);
    height: unit(124);
  }
  &__test {
    margin-top: $l;
    /deep/ .button-filled-small__act {
      background: $color-grey-a25;
    }
  }
}
</style>
