<template>
  <StackLayout
    class="template-tile"
    :class="{ 'template-tile--selected': selected }"
  >
    <TemplateGrid :template="template">
      <StackLayout
        class="template-tile__sector"
        :class="{ 'template-tile__primary': selected }"
      />
    </TemplateGrid>
  </StackLayout>
</template>
<script>
export default {
  name: "TemplateTile",
  props: {
    template: {
      type: Object,
      required: true
    },
    selected: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~/assets/styles/variables.scss";

.template-tile {
  padding: $l;
  border-width: $stroke;
  border-style: solid;
  border-color: $color-grey-a25;
  border-radius: $l;
  &--selected {
    // border-color: $color-primary;
    background-color: $color-primary;
  }

  &__sector {
    background-color: $color-grey-a25;
    border-width: $stroke;
    border-style: solid;
    border-color: $color-white;
    border-radius: $xs;
    height: 100%;
    width: 100%;
  }
  &__primary {
    border-width: $stroke;
    border-style: solid;
    fill: solid;
    background-color: $color-black-035;
    border-color: $color-primary;
  }
}
</style>
