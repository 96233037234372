import Vue from "vue";
import {
  ADD_DEVICE,
  CHANGE_DEVICE_CATEGORY_ICON,
  CHANGE_DEVICE_NAME,
  CHANGE_DEVICE_NOTIFICATIONS,
  CHANGE_DEVICE_QUICK_ACTION,
  CHANGE_DEVICE_ROOM,
  CHANGE_ROOM,
  DELETE_DEVICE,
  RESET_CURRENT_DEVICE_SETTINGS,
  SAVE_DEVICE_CATEGORY_ICON,
  SAVE_DEVICE_SETTINGS,
  SET_CURRENT_DEVICE_CATEGORY_ICON,
  SET_CURRENT_DEVICE_SETTINGS,
  SET_DEVICES,
  SET_LOADING_STATE,
  UPDATE_DEVICE,
  RESET_DEVICES,
  UPDATE_WINDOW_COVER,
  CHANGE_DEVICE_DETAILS_SHORTCUT,
  START_LOADING,
  STOP_LOADING,
  ADD_SELECTED_DEVICE,
  REMOVE_SELECTED_DEVICE,
  RESET_SELECTED_DEVICE,
  SET_SELECTED_DEVICE,
  SET_ABSTRACTS,
  SET_ABSTRACT_DEVICES,
  UPDATE_DEVICES
} from "./mutationTypes";

const abstactDeviceName = {
  googleHome: "Google Home",
  amazonAlexa: "Amazon Alexa"
};

function getIconSrc(category) {
  switch (category) {
    case "window_cov":
      return "icons/Devices/ic_device_misc_blinds_56.svg";
      break;
    case "security_sensor":
      return "icons/Devices/ic_device_sensor_motion_56.svg";
      break;
    default:
      return "icons/Devices/ic_device_dimmers-switches_switcher_56.svg";
      break;
  }
}

function getIconSrcActive(category) {
  switch (category) {
    case "window_cov":
      return "icons/Devices/ic_device_misc_blinds_white_56.svg";
      break;
    case "security_sensor":
      return "icons/Devices/ic_device_sensor_motion_green_56.svg";
      break;
    default:
      return "icons/Devices/ic_device_dimmers-switches_switcher_white_56.svg";
      break;
  }
}

function getCategory(category) {
  switch (category) {
    case "window_cov":
      return "Window Coverings";
      break;
    case "security_sensor":
      return "Motion sensor";
      break;
    default:
      return "Switches and dimmers";
      break;
  }
}
function getCategoryId(category) {
  switch (category) {
    case "window_cov":
      return "9";
      break;
    case "security_sensor":
      return "10";
      break;
    default:
      return "3";
      break;
  }
}

function getCategoryIcon(category) {
  return {
    id: getCategoryId(category),
    group: getCategory(category)
  };
}

function getCategoryByVeraId(categories, id) {
  let category = { key: "generic", value: categories.icons };
  Object.entries(categories).forEach(([key, value]) => {
    if (value.id == id) {
      category = { key, value };
      return;
    }
  });
  return category;
}

function getSubCategoryByVeraId(categories, id) {
  let category = {};
  Object.entries(categories).forEach(([key, value]) => {
    if (value.subId == id) {
      category = { key, value };
      return;
    }
  });
  return category;
}

function generateDevice(device, hub) {
  const {
    _id: id,
    roomId,
    parentDeviceId,
    name,
    reachable,
    category,
    subcategory,
    type,
    batteryPowered,
    serviceNotification,
    controller_id
  } = device;
  const events = type === "sensor" ? [{}, {}] : undefined;
  return {
    id,
    parentDeviceId,
    data: device,
    category,
    subcategory,
    type,
    batteryPowered,
    roomId,
    name,
    reachable,
    iconSrc: getIconSrc(category),
    iconSrcActive: getIconSrcActive(category),
    deviceCategoryIcon: getCategoryIcon(category),
    deviceRoomIcon: "",
    isQuickAction: true,
    isDeviceDetailsShortcut: true,
    isNotifications: serviceNotification,
    manufacturerName: "",
    model: "",
    manufacturer: "",
    deviceType: getCategory(category),
    selected: false,
    events,
    eventsCount: events && events.length,
    controller_id: hub ? hub : controller_id
  };
}

function generateVeraDevice(device, weatherSettings, categories, hub) {
  const {
    id: _id,
    room: roomId,
    id_parent,
    name,
    category_num,
    subcategory_num,
    device_type,
    manufacturer,
    model
  } = device;
  const events = device_type === "sensor" ? [{}, {}] : undefined;
  const category = getCategoryByVeraId(categories, category_num);
  const subcategory =
    subcategory_num && Number(subcategory_num) > 0
      ? getSubCategoryByVeraId(category.value.subcategory, subcategory_num)
      : "";
  return {
    _id,
    id: hub + "_" + _id,
    parentDeviceId: id_parent ? id_parent : "",
    data: device,
    category: category.key,
    subcategory: (subcategory && subcategory.key) || "",
    type: device_type,
    batteryPowered: "",
    roomId,
    name,
    reachable: "",
    iconSrc: getIconSrc(category.key),
    iconSrcActive: getIconSrcActive(category.key),
    deviceCategoryIcon: getCategoryIcon(category.key),
    deviceRoomIcon: "",
    isQuickAction: true,
    isDeviceDetailsShortcut: true,
    isNotifications: "",
    manufacturerName: "",
    model: model ? model : "",
    manufacturer: manufacturer ? manufacturer : "",
    deviceType: "",
    selected: false,
    events,
    eventsCount: events && events.length,
    controller_id: hub,
    weatherSettings
  };
}

function generateDeviceFromAbstract(device) {
  const { uuid: id, name, capabilities } = device;
  const events = undefined;
  const category = name.split("-")[0];

  return {
    id,
    parentDeviceId: "",
    data: device,
    category,
    subcategory: "",
    type: "abstract",
    batteryPowered: "",
    roomId: "",
    name: abstactDeviceName[category] || name,
    reachable: "",
    iconSrc: "",
    iconSrcActive: "",
    deviceCategoryIcon: "",
    deviceRoomIcon: "",
    isQuickAction: false,
    isDeviceDetailsShortcut: false,
    isNotifications: "",
    manufacturerName: category,
    model: "ZW3004",
    manufacturer: category,
    deviceType: "abstract",
    selected: false,
    events,
    eventsCount: events && events.length,
    controller_id: id
  };
}

export default {
  [SET_LOADING_STATE](state, payload) {
    Vue.set(state, "loadingStates", {
      ...state.loadingStates,
      [payload.key]: payload.value
    });
  },
  [START_LOADING](state, payload) {
    Vue.set(state, "loadingItems", [...state.loadingItems, payload]);
  },
  [STOP_LOADING](state, payload) {
    Vue.set(
      state,
      "loadingItems",
      state.loadingItems.filter(item => item !== payload)
    );
  },
  [SET_DEVICES](state, payload) {
    let { devices, weatherSettings, hub, isVera = false, categories } = payload;
    console.log("### SET_DEVICES...."); //eslint-disable-line
    if (isVera) {
      devices = devices.filter(
        item => !item.hasOwnProperty("invisible") || item.invisible !== "1"
      );
    }
    Vue.set(state, "devices", [
      ...state.devices,
      ...devices.map(device =>
        isVera
          ? generateVeraDevice(device, weatherSettings, categories, hub)
          : generateDevice(device, hub)
      )
    ]);
  },
  [UPDATE_DEVICES](state, payload) {
    let { devices, hub, isVera = false, categories } = payload;
    console.log("### SET_DEVICES...."); //eslint-disable-line
    const devicesById = {};
    const updatedDevices = [...state.devices];
    for (let i = 0; i < devices.length; i++) {
      devicesById[devices[i].id] = devices[i];
    }
    if (isVera) {
      updatedDevices.forEach(device => {
        const dev = devicesById[device._id];
        if (
          dev &&
          dev.states &&
          device.controller_id === hub &&
          device.data &&
          device.data.states
        ) {
          //TODO - override the incoming state
          device.data.states = [...device.data.states, ...dev.states];
        }
      });
    }
    Vue.set(state, "devices", updatedDevices);
  },
  [CHANGE_DEVICE_NAME](state, payload) {
    console.log("CHANGE_DEVICE_NAME:", payload); // eslint-disable-line
    Vue.set(state.currentDeviceSettings, "name", payload);
  },
  [CHANGE_DEVICE_ROOM](state, payload) {
    console.log("CHANGE_DEVICE_ROOM:", payload); // eslint-disable-line
    Vue.set(state.currentDeviceSettings, "roomId", payload);
  },
  [CHANGE_DEVICE_QUICK_ACTION](state, payload) {
    console.log("CHANGE_DEVICE_QUICK_ACTION:", payload); // eslint-disable-line
    Vue.set(state.currentDeviceSettings, "isQuickAction", payload);
  },
  [CHANGE_DEVICE_DETAILS_SHORTCUT](state, payload) {
    console.log("CHANGE_DEVICE_DETAILS_SHORTCUT:", payload); // eslint-disable-line
    // TODO There is no corresponding field on the API.
    Vue.set(state.currentDeviceSettings, "isDeviceDetailsShortcut", payload);
  },
  [CHANGE_DEVICE_CATEGORY_ICON](state, payload) {
    console.log("CHANGE_DEVICE_CATEGORY_ICON:", payload); // eslint-disable-line
    state.devicesData.items.forEach(async element => {
      return Vue.set(element, "selected", element.id === payload.id);
    });
  },
  [CHANGE_DEVICE_NOTIFICATIONS](state, payload) {
    console.log("CHANGE_DEVICE_NOTIFICATIONS:", payload); // eslint-disable-line
    Vue.set(state.currentDeviceSettings, "isNotifications", payload);
  },
  [SAVE_DEVICE_CATEGORY_ICON](state, payload) {
    const { id, group, src, srcActive } = payload;
    const newDeviceSettings = Object.assign({}, state.currentDeviceSettings, {
      iconSrc: src,
      iconSrcActive: srcActive,
      deviceCategoryIcon: { id: id, group: group }
    });
    state.currentDeviceSettings = {
      ...state.currentDeviceSettings,
      ...newDeviceSettings
    };
  },
  [SAVE_DEVICE_SETTINGS](state, payload) {
    const { id, data } = payload;
    const index = state.devices.findIndex(d => d.id === id);
    Vue.set(state.devices, index, data);
  },
  [CHANGE_ROOM](state, { deviceId, roomId }) {
    const device = state.devices.find(item => item.PK_Device === deviceId);
    if (device) {
      Vue.set(device, "roomId", roomId);
    }
  },
  [DELETE_DEVICE](state, payload) {
    console.log("DELETE_DEVICE", payload); // eslint-disable-line
    Vue.set(
      state,
      "devices",
      state.devices.filter(d => {
        return d.id !== payload;
      })
    );
  },
  [SET_CURRENT_DEVICE_SETTINGS](state, payload) {
    state.currentDeviceSettings = { ...payload };
  },
  [SET_CURRENT_DEVICE_CATEGORY_ICON](state, payload) {
    console.log("SET_CURRENT_DEVICE_SETTINGS_ICON:", payload); // eslint-disable-line
    state.devicesData.items.forEach(async element => {
      return Vue.set(element, "selected", element.id === payload);
    });
  },
  [RESET_CURRENT_DEVICE_SETTINGS](state) {
    state.currentDeviceSettings = {};
  },
  [UPDATE_WINDOW_COVER](state, payload) {
    const { key = "value" } = payload;
    Vue.set(state.windowCover, key, payload.value);
  },
  [ADD_DEVICE](state, payload) {
    state.devices.push(generateDevice(payload));
  },
  [UPDATE_DEVICE](state, { _id, data }) {
    state.devices = state.devices.map(device => {
      if (device.id === _id) {
        device.data = { ...device.data, ...data };
        ["roomId", "name", "reachable"].forEach(key => {
          if (typeof data[key] !== "undefined") {
            device[key] = data[key];
          }
        });
      }
      return device;
    });
  },
  [ADD_SELECTED_DEVICE](state, payload) {
    const { id, isLandscape = false } = payload;
    const selectedDevices = state.selectedDevices;

    if (!isLandscape) {
      const prtDevices = selectedDevices.portrait;
      if (prtDevices.findIndex(el => el === id) === -1) {
        prtDevices.push(id);
      }
    } else {
      const lntDevices = selectedDevices.landscape;
      if (lntDevices.findIndex(el => el === id) === -1) {
        lntDevices.push(id);
      }
    }
  },
  [REMOVE_SELECTED_DEVICE](state, payload) {
    const { id, isLandscape = false } = payload;
    const selectedDevices = state.selectedDevices;
    if (!isLandscape) {
      Vue.set(
        state.selectedDevices,
        "portrait",
        selectedDevices.portrait.filter(item => item !== id)
      );
    } else {
      Vue.set(
        state.selectedDevices,
        "landscape",
        selectedDevices.landscape.filter(item => item !== id)
      );
    }
  },
  [RESET_SELECTED_DEVICE](state) {
    Vue.set(state, "selectedDevices", { portrait: [], landscape: [] });
  },
  [RESET_DEVICES](state) {
    Vue.set(state, "devices", []);
  },
  [SET_SELECTED_DEVICE](state, payload) {
    Vue.set(state, "selectedDevices", payload);
  },
  [SET_ABSTRACTS](state, payload) {
    Vue.set(state, "abstracts", payload);
  },
  [SET_ABSTRACT_DEVICES](state, payload) {
    console.log("### SET_ABSTRACT_DEVICES...."); //eslint-disable-line
    Vue.set(state, "devices", [
      ...state.devices,
      ...payload.map(device => generateDeviceFromAbstract(device))
    ]);
  }
};
