module.exports = Object.freeze({
  // core components
  name: "Francais",
  core: {
    mailAppsDialog: {
      title: "Vérifier la boîte de réception avec"
    },
    sendSuccessMessage: {
      title: "Nous avons reçu votre message",
      desc: "Notre équipe de service à la clientèle sera en contact sous peu.",
      btn: "Terminé"
    },
    errorDialogConnection: {
      title: "Désolé, ça ne s'est pas passé",
      description:
        "Il est possible qu'il y ait des problèmes de connexion. Veuillez réessayer.",
      actionText: "@:common.gotIt"
    },
    errorDialogConnectionLost: {
      title: "Connexion perdue",
      description:
        "Assurez-vous que Ezlo Atom est sous tension et que vous êtes à proximité",
      actionText: "@:common.gotIt"
    },
    errorDialogLinkValid: {
      title: "Le lien n'est plus valide",
      desc:
        "Les liens de confirmation expirent 30 minutes après le premier clic ou après 24 heures s'ils ne sont pas utilisés.",
      btn: "Demander un nouveau lien"
    },
    errorDialogSigninCredentials: {
      title: "Identifiant ou mot de passe incorrect",
      desc:
        "Veuillez vérifier votre nom d'utilisateur et votre mot de passe et essayez à nouveau de vous connecter.",
      btn: "Je l'ai"
    },
    errorDialogSigninAttempts: {
      title: "Trop d'essais",
      desc: "Veuillez réessayer de vous connecter ultérieurement.",
      btn: "Je l'ai"
    },
    errorDialogInvalidCurrentPWD: {
      title: "Mot de passe actuel non valide.",
      desc:
        "Vérifiez et corrigez le mot de passe actuel que vous avez entré et réessayez.",
      btn: "Je l'ai"
    },
    errorAvailableHubs: {
      title: "Aucun contrôleur n'a été détecté",
      desc:
        "Veuillez vérifier votre emplacement et réessayer de vous connecter.",
      btn: "Je l'ai"
    },
    errorAvailableEzloHubs: {
      title: "Aucun contrôleur Ezlo n'a été détecté",
      desc: "Assurez-vous que vous disposez d'un contrôleur Ezlo et réessayez.",
      btn: "J'ai compris"
    },
    errorAvailableDevices: {
      title: "Aucun appareil n'a été détecté",
      desc: "Veuillez ajouter votre premier appareil.",
      btn: "Je l'ai"
    },
    policy: {
      text:
        "En continuant, vous acceptez de {companyName} <link>Conditions d'utilisation et politique de confidentialité<link>",
      text2:
        "En continuant, vous acceptez de <link>Conditions d'utilisation, politique de confidentialité et applications et contrat de service<link>",
      textPlaceholder: "Contrats de licence"
    },
    loginByUsernamePasswordFailed: {
      usernameField: "Nom d'utilisateur Le champ n'est pas valide"
    },
    logoutConfirm: {
      text1: "Vous déconnecter de votre profil?",
      text2:
        "Vous pouvez toujours vous reconnecter à l'aide d'un mot de passe.",
      btnTextAccept: "@:common.confirm",
      btnTextCancel: "@:common.cancel"
    },
    errorNoInternetConnectionDialog: {
      title: "Pas de connexion Internet",
      description:
        "Pour continuer la configuration, veuillez vous connecter à un point d'accès WiFi Internet.",
      actionText: "Réessayer"
    },
    deleteDashboardConfirm: {
      text1: "Voulez-vous vraiment supprimer le tableau de bord?",
      text2:
        "Le tableau de bord sera supprimé de la liste et ne participera pas aux scènes.",
      btnTextDelete: "@:common.delete",
      btnTextCancel: "@:common.cancel"
    },
    errorDialogNotEzlo: {
      title:
        "Désolé, vous ne pouvez pas utiliser l'application avec les hubs Vera",
      description:
        "Vous pouvez utiliser l'application pour faire fonctionner Atoms et PlugHubs. Veuillez utiliser Veramobile pour faire fonctionner les concentrateurs Vera.",
      actionText: "@:common.gotIt"
    },
    restoreStyleSettings: {
      text1: "Voulez-vous vraiment revenir aux paramètres par défaut?",
      text2:
        "Tous les paramètres de style seront réinitialisés. Vous pouvez les définir à nouveau dans les paramètres de style du tableau de bord.",
      btnTextRestore: "@:common.setDefaults",
      btnTextCancel: "@:common.cancel"
    },
    singleTemplateConfirm: {
      text1: "Voulez-vous utiliser un modèle?",
      text2: "Seul le modèle d'orientation portrait sera utilisé.",
      btnTextAccept: "Utilisez un modèle",
      btnTextCancel: "@:common.cancel"
    },
    tileTypesDialog: {
      textTitle: "Sélectionnez le type de tuile",
      textDevice: "Device",
      textTimeAndDate: "Heure & Date",
      textScene: "Scène",
      textLink: "Élément de menu Lien",
      textServiceWidget: "Widget de service",
      textURL: "Adresse URL",
      textDashboards: "Tableau de bord",
      textMode: "Mode",
      textEzloProtect: "Ezlo Protect",
      textGeneral: "Général",
      textServices: "Services"
    },
    selectMenuItemsDialog: {
      textTitle: "Sélectionner l'élément de menu",
      textDashboard: "Tableau de bord",
      textScenes: "Scènes",
      textEzloVOI: "Ezlo VOI",
      textUsers: "Utilisateurs",
      textSettings: "Paramètres",
      textMyControllers: "Mes contrôleurs",
      textDevices: "Périphériques",
      textRooms: "Chambres",
      textAlerts: "Alertes",
      textCameras: "Caméras",
      textGeofencing: "Geofencing"
    },
    moreOptionsAreComingSoon: {
      title: "Plus d'options sont à venir",
      description:
        "Nous travaillons dur pour ajouter de nouvelles fonctionnalités qui vous permettent de définir des objectifs sur une base quotidienne, hebdomadaire et annuelle.",
      actionText: "Je l'ai"
    },
    currentGoalResulstWillBeReset: {
      title: "Les résultats des objectifs actuels seront réinitialisés",
      description:
        "Afin de suivre efficacement votre nouvel objectif, les données précédemment collectées pour cet appareil seront supprimées.",
      btnTextAccept: "Confirmer",
      btnTextCancel: "Annuler"
    },
    currentGoalResulstWillBeReseted: {
      title: "Les résultats des objectifs actuels seront réinitialisés",
      description:
        "Pour commencer à suivre un objectif avec de nouveaux paramètres, les données actuellement collectées seront supprimées.",
      btnTextAccept: "Confirmer",
      btnTextCancel: "Annuler"
    },
    disableMonitoring: {
      title: "Désactiver la surveillance sur cet appareil?",
      description:
        "Il ne sera plus inclus dans vos statistiques énergétiques et tous les objectifs énergétiques qui lui sont associés seront effacés. Vous pouvez toujours réactiver cet appareil dans le future.",
      btnTextAccept: "Confirmer",
      btnTextCancel: "Annuler"
    },
    errorDialogNoEnergyData: {
      title: "Pas encore de données énergétiques pour cette période",
      description:
        "Ne vous inquiétez pas, cette période devient disponible lorsque vous obtenez plus de données pendant la journée.",
      actionText: "@:common.gotIt"
    },
    leaveWithoutChanges: {
      title: "Partir sans modifications?",
      description:
        "Toutes les modifications que vous avez apportées ne seront pas enregistrées.",
      btnTextAccept: "Quitter",
      btnTextCancel: "Annuler"
    },
    themeHasBeenChanged: {
      title: "Le thème a été changé",
      description:
        "Vous pouvez enregistrer le nouveau thème avec vos propres paramètres de style, sinon les paramètres ne seront pas enregistrés.",
      btnTextAccept: "Enregistrer comme nouveau thème",
      btnTextCancel: ""
    },
    deleteTheme: {
      title: "Supprimer un thème?",
      description:
        "Le thème sera supprimé et le thème par défaut sera appliqué à la place.",
      btnTextAccept: "@:common.delete",
      btnTextCancel: ""
    },
    tapToCustomizeASpesificTile: {
      title: "Appuyez pour personnaliser une vignette spécifique",
      description:
        "Vous pouvez modifier la couleur de l'icône, l'arrière-plan et la couleur du texte pour chaque vignette séparément.",
      btnTextAccept: "@:common.gotIt"
    },
    bothModesToOneMode: {
      title: "Vous changez de mode modèle",
      description:
        "Le modèle avec le mode d'orientation inutilisé sera supprimé. Vous pouvez à nouveau ajouter le deuxième mode dans les paramètres.",
      btnTextAccept: "@:common.gotIt",
      btnTextCancel: ""
    },
    oneModeToBothModes: {
      title: "Vous changez de mode modèle",
      description:
        "Vous pouvez utiliser le même modèle pour une nouvelle orientation ou en choisir une nouvelle.",
      btnTextAccept: "Utiliser le même modèle",
      btnTextCancel: "Sélectionnez un nouveau modèle"
    },
    portraitModeToLandscape: {
      title: "Vous changez de mode modèle",
      description:
        "Si vous modifiez l'orientation du modèle, vous devez sélectionner un nouveau modèle et rajouter les tuiles.",
      btnTextAccept: "@:common.gotIt",
      btnTextCancel: ""
    }
  },

  common: {
    next: "Prochain",
    send: "Envoyer",
    getHelp: "Obtenir de l'aide",
    call: "Appel",
    cancel: "Annuler",
    submit: "Soumettre",
    done: "Terminé",
    ok: "D'accord",
    finish: "Terminer",
    confirm: "Confirmer",
    enable: "Activer",
    add: "Ajouter",
    to: "au",
    edit: "Modifier",
    gotIt: "Je l'ai",
    about: "Sur",
    save: "Sauver",
    delete: "Supprimer",
    setDefaults: "Définir les valeurs par défaut"
  },
  validation: {
    phone: {
      invalid: "Numéro de téléphone invalide"
    },
    country: {
      invalid: "Pays invalide"
    }
  },
  offlineMode: {
    textTitle: "Hors ligne"
  },
  // already used
  PhoneCheck: {
    title: "Bienvenue à {companyName}",
    description:
      "Entrez votre numéro de téléphone pour vous inscrire ou connectez-vous à votre {companyName} profil."
  },
  signupIntro: {
    title: "Bienvenue dans MiOS Dashboard Configurator",
    description:
      "Pour commencer, connectez-vous en utilisant votre compte Vera",
    button: "S'identifier",
    signIn: "Oublié mon mot de passe?"
  },
  forgotPwdOTPIntro: {
    title: "Mot de passe oublié?",
    description:
      "Pour réinitialiser votre ancien mot de passe et en créer un nouveau, veuillez entrer sur l’écran suivant le code que nous vous enverrons par SMS.",
    next: "@:common.next"
  },
  loginByUserPhoneWelcome: {
    title: "Numéro de téléphone requis",
    description:
      "Pour vous offrir une expérience de connexion plus facile et une sécurité d’amélioration, nous passons de l’utilisation du nom d’utilisateur au numéro de téléphone.",
    next: "@:common.next"
  },
  Verification: {
    title: "Entrez {length}-digit code\n",
    description: "Nous l'avons envoyé à {phone}",
    countDownLabel: "Vous pouvez demander un nouveau code en: ",
    confirmation: "Voulez-vous utiliser le code: {code}",
    requestNewCode: "Demander un nouveau code",
    requestNewCodeSendMessage: "Nouveau code a été envoyé",
    expiredTitle: "Code expiré",
    expiredDescription: "Vous devez demander un nouveau code",
    wrongTitle: "Mauvais code",
    wrongDescription: "Vérifier le code et réessayer ou demander un nouveau"
  },
  loginByUserIntro: {
    title: "Se connecter avec nom d'utilisateur",
    forgotPasswordText: "Mot de passe oublié",
    loginButtonText: "Se connecter",
    usernamePlaceholder: "Nom d'utilisateur ou email",
    passwordPlaceholder: "Mot de passe"
  },
  loginByUserPhone: {
    title: "Entrez le numéro de téléphone\n",
    description:
      "Entrez le numéro de téléphone que vous souhaitez associer au profil et utilisez-le pour vous connecter ultérieurement.\n"
  },
  loginByPhoneIntro: {
    hintNav: "Mot de passe oublié",
    title: "Connectez-vous",
    desc:
      "Entrez le mot de passe pour vous connecter à votre compte lié à {phone}",
    pwdPlaceholder: "Mot de passe",
    btnText: "S'identifier",
    btnDisabledText: "@:common.next",
    errorText: "Mauvais mot de passe"
  },
  createPassword: {
    title: "Créer un mot de passe",
    pwdPlaceholder: "Mot de passe",
    reEnterPwdPlaceholder: "Retaper le mot de passe",
    btnText: "@:common.next",
    errorText: "Les mots de passe ne correspondent pas."
  },
  createNewPassword: {
    title: "Créer un nouveau mot de passe",
    pwdPlaceholder: "Nouveau mot de passe",
    reEnterPwdPlaceholder: "Ré-entrez le nouveau mot de passe",
    btnText: "@:common.confirm",
    errorText: "Les mots de passe ne correspondent pas.",
    snackbarText: "Nouveau mot de passe enregistré"
  },
  dashboardTemplate: {
    textTitle: "Configurons le tableau de bord",
    textTitleEditDashboard: "Modifier le tableau de bord",
    textBtnEdit: "Sauvegarder",
    textBtnTemplate: "Terminé",
    textHint: "Nom du tableau de bord",
    useAsDefault: "Utiliser par défaut",
    textDuplicate: "Tableau de bord en double",
    textDuplicateHint: "Avec tous les carreaux et le style",
    textDuplicateSuccessful: "Tableau de bord dupliqué",
    textStyle: "Paramètres du tableau de bord",
    textStyleHint: "Arrière-plan, style d'icône et plus encore.",
    textTemplate: "Utilisez un modèle",
    textTemplateHint:
      "Un modèle de portrait sera utilisé pour les deux modes: portrait et paysage.",
    textAddTemplate: "Sélectionnez un modèle",
    textEditNameDialog: {
      title: "Modifier le nom du tableau de bord",
      placeholder: "Nom du tableau de bord",
      hint: "Ex: soirées de semaine, arrosage d'été, etc.",
      btn: "Terminé"
    },
    textSnackbar: {
      duplicateSuccessful: "Tableau de bord dupliqué.",
      textDeleteSuccessful: "Tableau de bord supprimé.",
      textUpdateSuccessful: "Tableau de bord mis à jour.",
      textCreateSuccessful: "Tableau de bord créé."
    }
  },
  passwordChecks: {
    title: "Exigences de mot de passe",
    uppercaseLetter: "Lettre majuscule",
    lowercaseLetter: "Lettre minuscule",
    number: "Un nombre",
    characters: "8-16 caractères"
  },
  forgotPasswordEmailIntro: {
    title: "Mot de passe oublié?",
    description:
      "Veuillez entrer l'adresse e-mail associée à votre compte Vera et nous vous enverrons un lien de réinitialisation.",
    emailPlaceholder: "Email",
    btn: "@:common.next"
  },
  forgotPasswordEmailSend: {
    title: "Mot de passe oublié?",
    description:
      "Nous vous enverrons des instructions de récupération de mot de passe à votre adresse e-mail.",
    btn: "@:common.send"
  },
  forgotPasswordEmailReset: {
    title: "Vérifiez votre boîte de réception",
    desc:
      "Vous trouverez des instructions pour réinitialiser le mot de passe dans le courrier électronique que nous avons envoyé.",
    btn: "Vérifier la boîte de réception",
    hint:
      "Si vous ne trouvez pas d'e-mail, veuillez vérifier le dossier spam dans votre boîte de réception."
  },
  deviceState: {
    on: "Sur",
    off: "De",
    text: "Etat"
  },
  profile: {
    title: "Compte",
    name: "Nom d'utilisateur",
    email: "E-mail",
    logOut: "Se déconnecter",
    titleEditEmail: "Modifier le nom",
    btnEditEmail: "Sauvegarder",
    hintEditEmail: "Cet email est déjà pris",
    snackbarTextEditEmail: "E-mail enregistré",
    errorWhileUploadingImage: "Erreur lors du téléchargement de l'image",
    profilePhotoChanged: "La photo du profil a changé",
    hubs: "Contrôleurs"
  },
  profileCurrentPassword: {
    textTitle: "Entrer votre mot de passe actuel",
    textPWDPlaceholder: "Mot de passe actuel",
    textBtn: "Continuer",
    textError: "Mot de passe invalide entré"
  },
  profileFullName: {
    textTitle: "Nom complet",
    textPlaceholderFirstName: "Prénom",
    textPlaceholderLastName: "Nom de famille",
    textBtn: "Sauvegarder",
    textSnackbar: "Nom complet enregistré"
  },
  devices: {
    title: "Sélectionnez un appareil"
  },
  scenes: {
    title: "Sélectionnez une scène",
    finished: "Scène exécutée avec succès",
    failed: "La scène n'a pas pu être exécutée"
  },
  devicesListTabs: {
    byType: "Par type",
    byRoom: "Par chambre"
  },
  dashboardList: {
    textTitle: "Mes tableaux de bord",
    add: "Ajouter un nouveau tableau de bord",
    hubsDialog: {
      textTitle: "Sélectionnez le contrôleur pour le nouveau tableau de bord"
    },
    dafaultDashboard: "Tableau de bord par défaut"
  },
  dashboardSelect: {
    headerTitle: "Sélectionnez le tableau de bord"
  },
  newDashboard: {
    textTitle: "Sélectionnez le mode modèle",
    useOneTemplate: "Utiliser un modèle",
    useOneTemplateDescription:
      "Un modèle de portrait sera utilisé pour les deux modes: portrait et paysage.",
    textBtn: "Continuer",
    pleaseNameYourDashboard: "Entrez le nom du tableau de bord",
    dashboardNamePlaceholder: "Nom du tableau de bord",
    dashboardNameIsUsed:
      "Ce nom de tableau de bord a déjà été utilisé auparavant.",
    desc:
      "Ici, vous pouvez choisir l'orientation du modèle que vous utiliserez. Elle peut être modifiée dans la section Paramètres du tableau de bord."
  },
  templateMode: {
    textTitle: "Sélectionnez le mode modèle",
    desc:
      "Ici, vous pouvez choisir l'orientation du modèle que vous utiliserez. Elle peut être modifiée dans la section Paramètres du tableau de bord."
  },
  dashboardPromoTile: {
    type: "Tableau de bord",
    title: "Découvrez de nouveaux tableaux de bord personnalisables",
    btn: "Ajouter un tableau de bord"
  },
  dashboardSettings: {
    textHeader: "Paramètres du tableau de bord",
    textFirstGroup: "Générale",
    textFirstGroupActionFirst: "Fond de tableau de bord",
    textFirstGroupActionSecond: "Famille de polices",
    textFirstGroupActionThird: "Taille de police",
    textSecondGroup: "Autre",
    textSecondGroupActionFirst: "Réinitialiser",
    textBtn: "Terminé",
    duplicateDashboard: "Tableau de bord en double",
    duplicateDashboardDesc:
      "Avec toutes les mosaïques et les paramètres de style",
    templateMode: "Mode modèle",
    theme: "Thème",
    dashboardName: "Nom du tableau de bord",
    portrait: "Portrait",
    landscape: "Paysage",
    both: "Les deux"
  },
  styleSettingsBgImage: {
    textTitle: "Contexte du thème",
    textAddCustomImg: "Télécharger une image personnalisée",
    textPresetsTitle: "Préréglages d'image",
    textBackgroundImages: "Images d'arrière-plan",
    textBtn: "Terminé",
    textSnackbar: {
      added: "Image ajoutée avec succès"
    },
    textCameraDialog: {
      textFirst: "Prendre une photo",
      textSecond: "Choisissez parmi la bibliothèque",
      textCancel: "Annuler"
    },
    textBackgroundColor: "Couleur d'arrière-plan",
    textTileBackground: "Fond de tuile",
    textUseBackgroundImage: "Utiliser l'image d'arrière-plan",
    textUseBackgroundImageDesc:
      "L'image sera utilisée à la place de la couleur d'arrière-plan."
  },
  orientations: {
    portrait: "Orientation portrait",
    landscape: "Orientation paysage"
  },
  templateModes: {
    new: {
      headerTitle: "Sélectionnez le mode modèle",
      portrait: "Mode portrait",
      portraitDesc:
        "Utilisez le tableau de bord uniquement en orientation portrait.",
      landscape: "Mode paysage",
      landscapeDesc:
        "Utiliser le tableau de bord uniquement en orientation paysage.",
      both: "Les deux modes",
      bothDesc: "Use of dashboards in portrait and landscape orientations.",
      desc:
        "Utilisation de tableaux de bord en orientation portrait et paysage."
    },
    edit: {
      headerTitle: "Mode modèle",
      portrait: "Mode portrait",
      portraitDesc: "Portrait uniquement pour modèle",
      landscape: "Mode paysage",
      landscapeDesc: "Paysage uniquement pour modèle",
      both: "Les deux modes",
      bothDesc: "Utiliser les deux orientations pour le modèle",
      desc: "Vous pouvez choisir l'orientation du modèle."
    }
  },
  portraitTemplate: {
    title: "Sélectionnez le modèle",
    btn: "Terminé"
  },
  newLinkAddress: {
    textTitle: "Entrez l'adresse du lien",
    textLinkHint: "Par exemple: Youtube, Spotify, site Web, image, etc.",
    textBtn: "Continuer"
  },
  newLinkAddressName: {
    textTitle: "Entrez le nom de la tuile",
    textBtn: "Terminé"
  },
  fontFamily: {
    textTitle: "Famille de polices",
    textInstruction:
      "Vous pouvez sélectionner dans la liste ci-dessous la police qui sera appliquée aux tuiles.",
    textBtn: "Terminé"
  },
  fontSize: {
    textTitle: "Taille de police",
    textInstruction:
      "En déplaçant le curseur en bas de l'écran, vous pouvez sélectionner la taille de police des tuiles.",
    textBtn: "Terminé"
  },
  fontFamilies: {
    "ezlo-sans": "Ezlo Sans",
    "sf-pro-display": "SF Pro Display",
    roboto: "Roboto",
    "open-sans": "Open Sans",
    defaultFont: "Défaut",
    "sans-serif": "Sans Serif",
    monospace: "Monospace",
    serif: "Serif",
    casual: "Casual",
    avenir: "Avenir"
  },
  dashboardTemplateType: {
    createMode: {
      textTitlePortrait: "Sélectionnez un modèle",
      textTitleLantscape: "Sélectionnez le modèle de paysage",
      textBtn: "Terminé"
    },
    editMode: {
      textTitlePortrait: "Sélectionnez le modèle de portrait",
      textTitleLantscape: "Sélectionnez le modèle de paysage",
      textBtn: "Continuer"
    }
  },
  tileSettings: {
    textTitle: "Paramètres de mosaïque",
    textOnState: "On state",
    textOffState: "Off state",
    textTileBackground: "Fond de tuile",
    textFontColor: "Couleur de police",
    textIcon: "Icon",
    textIconColor: "Couleur de l'icône",
    textOther: "Autre",
    textRestoreDefaults: "Restore defaults",
    textBtn: "Terminé",
    hideTitle: "Masquer le titre",
    hideIcon: "Masquer l'icône",
    headerFirst: "Modifier",
    headerLast: "state",
    textEditTile: "Modifier la tuile"
  },
  tileStates: {
    textTitle: "États des tuiles",
    textInfo:
      "Vous pouvez modifier la vignette pour n'importe quel état de votre appareil.",
    capabilities: {
      on: "Sur",
      off: "De",
      open: "Ouverte",
      closed: "Fermée",
      cool: "Cool",
      heat: "Chaleur",
      auto: "Auto",
      armed: "Armée",
      disarmed: "Désarmé",
      tripped: "Trébuché"
    }
  },
  preview: {
    empty: "Pas de tableaux de bord"
  },
  deviceToControl: {
    textTitle: "Appareil à contrôler",
    textInputLabel: "Nom du périphérique que vous souhaitez contrôler",
    textDesc:
      "Entrez le nom exact de l'appareil ou du groupe que vous dites à Alexa lorsque vous souhaitez le contrôler. Par exemple: la lumière dans le salon",
    textBtn: "Prochain"
  },
  addDevice: {
    textTitle: "Ajouter un appareil",
    textDescTitle: "Créer un périphérique virtuel",
    textDesc:
      "Vous pouvez contrôler les appareils connectés à vos comptes Amazon Alexa ou Google Home en créant un appareil virtuel.",
    textCreate: "Créer"
  },
  deviceCapabilities: {
    textTitle: "Configurer manuellement",
    textDecsTitle: "Détectons les capacités de l'appareil",
    textDesc:
      "Votre appareil peut changer d'état pendant le processus de détection. Assurez-vous que la langue sélectionnée dans les options de détection est celle que vous utilisez avec l'assistant vocal.",
    textOptions: "Options de détection",
    textOptionsDesc: "{language}, Toutes les capacités",
    textBtn: "Lancer la détection"
  },
  virtualDevices: {
    capabilitySearching: {
      title: "Détecter les capacités...",
      description:
        "Veuillez ne pas éteindre l'appareil pendant le processus de détection"
    },
    editCapability: {
      title: "Commandes",
      done: "Terminé"
    },
    editCommand: {
      title: "Commande",
      subtitle:
        "Entrez la commande que vous souhaitez envoyer à votre assistant vocal pour appliquer l'appareil à un état spécifique.\n E.g.: Allumer.",
      test: "Tester",
      done: "Terminé"
    },
    configureCapabilities: {
      title: "Configurer les capacités"
    },
    detectionCancelDialog: {
      title: "Quittez le processus de détection?",
      sub:
        "Cela arrêtera la détection et les capacités ne seront pas configurées automatiquement. Vérifiez votre appareil car il peut rester dans un état modifié.",
      leave: "Laisse"
    },
    linkVOI: {
      title: "Aucun compte lié à Ezlo VOI™?",
      sub:
        "Vous devez lier vos comptes Amazon Alexa ou Google Voice Assistant à VOI pour ajouter des appareils virtuels.",
      leave: "Lier un compte VOI"
    },
    capabilityError: {
      title: "Désolé, nous n'avons détecté aucune capacité",
      l1: "Assurez-vous de vérifier les points suivants:",
      l2: "- L'appareil est allumé",
      l3:
        "- You entered the device name exactly as it is written in your voice assistant app",
      l4:
        "- Vous avez entré le nom de l'appareil exactement tel qu'il est écrit dans votre application d'assistant vocal",
      tryAgain: "Réessayer",
      config: "Configurer manuellement"
    },
    selectVOI: {
      title: "Sélectionnez un compte Ezlo VOI ™",
      bt: "Ajouter un nouveau compte"
    },
    setDimmer: {
      title: "Set dimmer",
      configureCustomDimmer: "Configurer un gradateur personnalisé",
      commands: "Commandes",
      commandsDescription:
        "Par défaut, nous avons généré une échelle automatique pour votre variateur entre 0 et 100%. Vous pouvez toujours modifier les valeurs min et max de l'échelle.",
      customCommandsDescription:
        "Ajoutez un point de consigne avec une commande que vous souhaitez envoyer à votre assistant vocal lorsqu'elle est appliquée.",
      defaultDimmerScale: "Échelle de gradateur par défaut",
      defaultDimmerScaleDescription: "Dim a [{min}-{max}%]",
      customDimmerScaleDescription: "Minimum 2 points de consigne requis.",
      addSetPoint: "Ajouter un point de consigne",
      done: "Terminé"
    },
    defaultDimmerScale: {
      title: "Échelle de gradateur par défaut",
      scaleValues: "Valeurs d'échelle",
      scaleValuesDescription:
        "Vous pouvez modifier les valeurs d'échelle minimale et maximale de votre gradateur.",
      min: "Min",
      max: "Max",
      done: "Terminé"
    },
    addSetPoint: {
      title: "Ajouter un point de consigne",
      titleEdit: "Modifier le point de consigne",
      inputLabel: "Commande",
      subtitle:
        "Entrez la commande que vous souhaitez envoyer à votre assistant vocal pour appliquer l'appareil à un état spécifique. Par exemple: Dim à 50%.",
      test: "Test",
      done: "Terminé"
    }
  },
  newVirtualDevice: {
    titleNew: "Nouveau périphérique virtuel",
    titleEdit: "Modifier le périphérique virtuel",
    description:
      "Utilisez les actions du widget pour tester le périphérique virtuel.",
    groupTitle: "Capacités",
    inputLabel: "Nom",
    inputHint: "Par exemple: Lampe de chevet",
    actionText: "Ajouter une capacité",
    textBtn: "Enregistrer le périphérique"
  },
  selectCapabilities: {
    titleNew: "Sélectionner des capacités",
    groupTitle: "Pris en charge par l'appareil",
    actionText: "Configurer plus de fonctionnalités",
    textBtn: "Ajouter {count} capacité"
  },
  energy: {
    title: "Énergie",
    compatibleDevicesLink:
      "Utilisez cet outil pour mieux suivre et gérer votre consommation d'énergie pour les <link>appareils compatibles<link>",
    dailyAverage: "Moyenne quotidienne",
    monthToDate: "Le mois courant",
    fromMonth: "↓{differencePercentage}% à partir de {longMonthName}",
    unknown: "Inconnu",
    climate: "Climat",
    appliance: "Appareil",
    lighting: "Éclairage",
    thermostatInLivingRoom: "Thermostat dans le salon",
    heaterInBedroom: "Chauffage dans la chambre",
    control: "Contrôle",
    thermostat: "Thermostat",
    filter: "Filter",
    create: "Create",
    reset: "Reset",
    cancel: "Cancel",
    backToLive: "Retour en direct",
    total: "Total",
    live: {
      name: "Live",
      average: "Average per day",
      total: "Retour en direct",
      warning:
        "Live view shows the real-time consumption when the app is open. Prior data is available in 10 minute increments.",
      usage: "Live usage"
    },
    day: {
      name: "Day",
      average: "Average per day",
      from: "From yesterday",
      total: "Day total",
      last: "Last day",
      goal: "Daily Goal",
      usage: "Usage by day"
    },
    week: {
      name: "Week",
      average: "Average per week",
      from: "From last week",
      total: "Week total",
      last: "Last week",
      goal: "Weekly Goal",
      usage: "Usage by week"
    },
    month: {
      name: "Month",
      average: "Average per month",
      from: "From last month",
      total: "Month total",
      last: "Last Month",
      goal: "Monthly Goal",
      usage: "Usage by month"
    }
  },
  goals: {
    header: "Buts",
    headerButton: "Créer",
    noGoalHeader: "Fixez-vous des objectifs énergétiques",
    noGoalText:
      "Suivez l'utilisation et recevez des alertes lorsque vous approchez de votre objectif de consommation.",
    noGoalButton: "Créer un objectif"
  },
  energyDetails: {
    monitoring: {
      header: "Activer la surveillance énergétique",
      text: "Collecter et afficher ses données de consommation"
    },
    details: {
      time: {
        live: "Statistiques en direct",
        day: "Statistiques du jour",
        week: "Statistiques de la semaine",
        month: "Statistiques du mois"
      },
      consumption: {
        live: "Consommer maintenant",
        total: "Totale"
      },
      ratioText: "Ratio de répartition"
    }
  },
  calendar: {
    noDate: "Aucune date n'est sélectionnée",
    today: "Aujourd'hui, ",
    month: "Ce mois-ci",
    week: "Cette semaine",
    day: "Aujourd'hui",
    live: "Aujourd'hui",
    apply: "Appliquer",
    infoText: "L'historique énergétique est limité à 2 mois"
  },
  roomType: {
    roomLight: "Lumière de la pièce",
    livingRoom: "Salon"
  },
  services: {
    title: "Énergie, {dailyAverage} kWh",
    dailyAverage: "Moyenne quotidienne {shortMonthName}",
    fromLastMonth: "↓{differencePercentage}% du mois dernier"
  },
  iconList: {
    title: "Tile Icon",
    sub: "Select an icon from the list.", // or upload your own The size of the icon should be 103*103px, up to 2MB.
    sub2: "Default Icon",
    current: "Current Icon",
    upload: "Upload custom icon",
    btn: "Done"
  },
  goals: {
    title: "Nouvel objectif",
    iWantMy: "Je veux mon",
    selectADevice: "Sélectionner un appareil",
    toBeUnder: "Pour être sous",
    enterTheLimit: "Entrez la limite",
    atTheEndOfThe: "À la fin du",
    month: "Mois",
    save: "Sauver",
    noDeviceSelected: "Aucun appareil sélectionné",
    noLimitEntered: "Aucune limite saisie",
    limitErrorText: "L'objectif ne fonctionnera pas sans limite",
    goalSaved: "Objectif enregistré",
    editGoal: "Modifier l'objectif"
  },
  dateTimeSelect: {
    time: "Heure",
    date: "Date",
    dateandtime: "Date & Heure"
  },
  iconSets: {
    textTitle: "Icônes",
    subTitle:
      "Sélectionnez l'ensemble d'icônes que vous souhaitez utiliser pour la vignette. Vous pouvez télécharger vos propres icônes.",
    v2: "Ezlo Icon v.2",
    v1: "Ezlo Icon v.1",
    custom: "Icônes personnalisées"
  },
  customIcons: {
    title: "Icônes personnalisées",
    subTitle:
      "Téléchargez vos propres icônes pour tout type de tuile. La taille de l'icône doit être de 56 * 56px, jusqu'à 2 Mo.",
    uploadCustomIcon: "Importer une icône personnalisée",
    errorImageSizeToBig:
      "La taille de l'icône doit être de 56 * 56px, jusqu'à 2 Mo.",
    IconAdded: "Icône ajoutée avec succès",
    uploadIcons: "Icônes téléchargées",
    btn: "Terminé"
  },
  houseModeSelect: {
    headerTitle: "Sélectionner le mode",
    home: "Maison",
    away: "Away",
    night: "Nuit",
    vacation: "Vacances",
    allModes: "Tous les modes"
  },
  panicTypeSelect: {
    headerTitle: "Sélectionner le type de tuile",
    medical: "Service médical",
    medicalTitle: "Médical",
    police: "Service de police",
    policeTitle: "Police",
    fire: "Service d'incendie",
    fireTitle: "Feu"
  },
  themeSettings: {
    headerTitle: "Paramètres du tableau de bord",
    theme: "Thème",
    edit: "Modifier",
    done: "Terminé",
    light: "Lumière",
    dark: "Sombre",
    createNewTheme: "Créer un nouveau thème",
    enterThemeName: "Entrez le nom du thème",
    themeName: "Nom du thème",
    textSnackbar: {
      textCreateSuccessful: "Thème créé."
    }
  },
  editTheme: {
    headerTitle: "Modifier le thème",
    newTheme: "Nouveau thème",
    themeBackground: "Arrière-plan du thème",
    tiles: "Tuiles",
    name: "Nom",
    applyForAllTiles: "Appliquer pour toutes les tuiles",
    applyForAllTilesDesc:
      "Si désactivé, vous pouvez configurer le style pour chaque vignette",
    tileBackground: "Arrière-plan de mosaïque",
    fontColor: "Couleur de la police",
    iconColor: "Couleur de l'icône",
    noAddedTiles:
      "Il n'y a pas de tuiles ajoutées, lorsque vous ajoutez au moins une tuile, vous pouvez changer son style.",
    save: "Sauver",
    themeName: {
      title: "Modifier le nom du thème",
      placeholder: "Nom du thème",
      btn: "Terminé"
    }
  }
});
