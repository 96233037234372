<template>
  <MainPage>
    <ConfirmationDialog
      icon="icons/Warnings/ic_warning_200.svg"
      :text1="translations.title"
      :text2="translations.description"
      :btnTextAccept="translations.btnTextAccept"
      :btnTextCancel="translations.btnTextCancel"
      :warning="true"
      :loading="isFetching('saveGoal')"
      @accept="accept"
      @cancel="goBack"
    />
  </MainPage>
</template>
<script>
import { createNamespacedHelpers, mapActions } from "vuex";
const { mapGetters: mapUiGetters } = createNamespacedHelpers("ui");
const {
  mapGetters: mapEnergyGetters,
  mapActions: mapEnergyActions
} = createNamespacedHelpers("energy");

export default {
  name: "GoalSaveConfirmationDialog",
  props: {
    goal: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapUiGetters(["isFetching"]),
    texts() {
      return {
        text1: this.$t(this.text1),
        text2: this.$t(this.text2),
        actionText: this.$t(this.actionText)
      };
    },
    translations() {
      return this.isFetching("saveGoal")
        ? this.$t("core.currentGoalResulstWillBeReseted")
        : this.$t("core.currentGoalResulstWillBeReset");
    }
  },
  methods: {
    ...mapEnergyActions(["saveGoal"]),
    goBack() {
      this.navigateBack();
    },
    async accept(event) {
      const Ok = await this.saveGoal(this.goal);
      if (Ok) {
        this.navigateTo("Energy", { backstackVisible: false });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~/assets/styles/variables.scss";

.information-dialog {
  height: 100%;
  padding: $padding-s $padding-xl $padding-xl $padding-xl;
  align-items: center;
  &--title {
    text-align: center;
    color: $color-black;
  }
  &--text {
    margin: $margin-s 0 0 0;
    text-align: center;
    color: $color-black;
  }
  &--button {
    grid-row: 2;
    grid-column: 1;
    margin-top: $xxxl;
    color: $color-white;
  }
}
</style>
