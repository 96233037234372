export default {
  getFavoriteDevices: (state, getters, rootState, rootGetters) => {
    return getters.getFavoriteDevicesIds
      .filter(id => {
        const item = rootGetters["devices/getDeviceById"](id);
        return typeof item !== "undefined";
      })
      .map(id => {
        const item = rootGetters["devices/getDeviceById"](id);
        const {
          icons: { default: src },
          name: title,
          room,
          reachable
        } = item;
        const caption = room && room.name;
        const status = rootGetters["devices/getDeviceStatusById"](id);
        return { ...item, src, status, title, id, caption, error: !reachable };
      });
  },
  getFavoriteDevicesIds: state => {
    return state.favorites.devices;
  },
  isFavorite: (state, getters) => deviceId => {
    return getters.getFavoriteDevicesIds.includes(deviceId);
  }
};
