const scales = {
  c: "℃",
  celsius: "℃",
  f: "℉",
  fahreneit: "℉",
  fahrenheit: "℉",
  k: "K",
  kelvin: "K",
  "": "K"
};

const getStateItem = (states, key) => {
  // the last item should be returned
  const keys = states.map(el => el.variable);
  const index = keys.lastIndexOf(key);
  return index > -1 && states[index];
};

export const deviceStatus = device => {
  let states = device.data.states;
  let status = "";

  if (["temperature", "hvac", "generic_sensor"].includes(device.category)) {
    const state = getStateItem(states, "CurrentTemperature");
    if (state) {
      let scale = {
        ...states.find(item => item.variable === "TemperatureFormat")
      };
      scale =
        (scale && scale.value && scales[scale.value.toLowerCase()]) ||
        scales[device.weatherSettings.tempFormat.toLowerCase()] ||
        "℉";
      status = `${status} ` + parseFloat(state.value).toFixed(2) + scale;
    }
  }

  if (["generic_sensor"].includes(device.category)) {
    const state = getStateItem(states, "CurrentLevel");
    if (state) {
      status = `${status}\n` + parseFloat(state.value).toFixed(2) + "%";
    }
  }

  if (["security_sensor", "camera"].includes(device.category)) {
    const tripped = getStateItem(states, "Tripped");
    const armed = getStateItem(states, "Armed");
    const armedTripped = getStateItem(states, "ArmedTripped");
    const message = getStateItem(states, "Message");

    if (tripped && tripped.value === "1") {
      status = `${status} ` + "Tripped";
    } else if (armed) {
      const st = armed.value === "0" ? "Disarmed" : "Armed";
      status = `${status} ` + st;
    } else if (armedTripped) {
      const st = armedTripped.value === "0" ? "Disarmed" : "Armed";
      status = `${status} ` + st;
    } else if (message) {
      status = `${status} ` + message.value;
    }
  }

  if (["humidity"].includes(device.category)) {
    const state = getStateItem(states, "CurrentLevel");
    if (state) {
      status = `${status} ` + parseFloat(state.value).toFixed(2) + "%";
    }
  }

  if (["generic"].includes(device.category)) {
    const state = getStateItem(states, "Status");
    if (state) {
      const st = state.value === "0" ? "Disarmed" : "Armed";
      status = `${status} ` + st;
    }
  }

  if (["light_sensor"].includes(device.category)) {
    const state = getStateItem(states, "CurrentLevel");
    if (state) {
      status = `${status} ` + state.value + "lux";
    }
  }

  // if (["interface"].includes(device.category)) {
  //   const state = getStateItem(states, "Message");
  //   if (state) {
  //     status = `${status} ` + state.value;
  //   }
  // }
  return status.trim();
};
