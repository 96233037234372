<template>
  <GridLayout class="filter" rows="*" columns="*,auto">
    <IconHTxtiBtn
      row="0"
      col="0"
      headerSize="h2"
      :showUnderline="true"
      alignText="left"
      alignUnderline="left"
      :underlineText="titleDesc"
      :text="title"
      :hover="false"
    />
    <Label
      @tap="actionTap"
      class="filter__action font-txt-16-btn"
      row="0"
      col="1"
      :text="button"
    />
  </GridLayout>
</template>

<script>
export default {
  name: "DeviceFilter",
  props: {
    title: {
      type: [String, Number],
      default: ""
    },
    titleDesc: {
      type: String,
      default: ""
    },
    button: {
      type: String,
      default: ""
    },
    buttonAlt: {
      type: String,
      default: ""
    },
    isActionActive: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    translations() {
      return {
        dailyAverage: this.$t("energy.dailyAverage")
      };
    },
    buttonText() {
      return !this.isActionActive ? this.button : this.buttonAlt;
    }
  },
  methods: {
    actionTap() {
      this.$emit("actionTap");
    }
  }
};
</script>

<style lang="scss">
@import "~/assets/styles/variables.scss";

.filter {
  &__action {
    margin-right: $xl;
    color: $color-vera;
    user-select: none;
  }
}
</style>
