<template>
  <StackLayout class="devices-groups">
    <StackLayout v-for="item in devices" :key="item.groupTitle">
      <DevicesList
        :title="getGroupTitle(item.groupTitle)"
        :items="item.groupDevices"
        @tap="$emit('tap', $event)"
      />
    </StackLayout>
    <StackLayout height="16" />
  </StackLayout>
</template>
<script>
import DevicesList from "@/components/device/DevicesList";

export default {
  name: "DevicesGroups",
  components: {
    DevicesList
  },
  props: {
    devices: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    getGroupTitle(title) {
      return title.replace("_", " ").replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    }
  }
};
</script>
<style scoped lang="scss">
@import "~/assets/styles/variables.scss";

.devices-groups {
  margin-top: $m;
}
</style>
