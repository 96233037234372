import request from "./http";
import endpoints from "./endpoints";
import apiConfig from "../../config/api";
// import stringInject from "stringinject";
import { bghttp } from "../helpers/helperLibrary";
import { parseHttpResponse } from "@/helpers/responseParser";
const { storage } = endpoints;

const storageService = {
  upload(config = {}, payload, onProgress) {
    return new Promise((resolve, reject) => {
      console.log("#### avatar UPLODADING", payload); // eslint-disable-line
      let file = payload.file.filename;
      let url = `https://${apiConfig.storageBaseUrl}/storage/storage/store`;
      var name = file.substr(file.lastIndexOf("/") + 1);
      const request = {
        url: url,
        method: "POST",
        headers: {
          MMSAuth: config.Identity,
          MMSAuthSig: config.IdentitySignature
        },
        androidAutoClearNotification: true,
        androidAutoDeleteAfterUpload: false,
        displayNotificationProgress: true,
        description: "Uploading " + name
      };
      const params = [
        { name: "PK_Account", value: payload.account.PK_Account },
        { name: "PK_User", value: payload.account.PK_User },
        { name: "Format", value: "jpg" },
        { name: "PK_StorageType", value: 3 },
        { name: "Filesize", value: payload.file.size },
        { name: "Filename", value: payload.fileName },
        { name: "Data", filename: file, mimeType: "image/*" }
      ];
      const session = bghttp.session("image-upload");
      const task = session.multipartUpload(params, request);

      if (task === undefined) {
        reject(new Error("Can not create upload task!"));
        return;
      }

      task.on("progress", e => {
        if (e.currentBytes) {
          //eslint-disable-next-line
          console.info(
            `uploaded ${e.currentBytes} / ${e.totalBytes} -> ${
              e.currentBytes ? (e.currentBytes / e.totalBytes) * 100 : 0
            }% sent`
          ); // eslint-disable-line
          if (onProgress) {
            onProgress(
              e.currentBytes ? (e.currentBytes / e.totalBytes) * 100 : 0
            );
          }
        }
      });

      task.on("error", event => {
        console.error("Upload error:", event); // eslint-disable-line
        const { eventName, responseCode } = event;
        reject({ eventName, responseCode, event });
      });

      task.on("cancelled", () => {
        console.info("Upload cancelled"); // eslint-disable-line
        reject("Aborted");
      });

      task.on("responded", event => {
        console.log("Upload responded:", event); //eslint-disable-line
        try {
          const { eventName, responseCode } = event;
          const { PK_Store, Key } = JSON.parse(event.data);
          resolve({ eventName, responseCode, PK_Store, Key, url });
        } catch (e) {
          reject(e.error ? e.error.toString() : e.error);
        }
      });

      task.on("complete", event => {
        console.log("Upload received " + event.responseCode + " code"); //eslint-disable-line
        const serverResponse = event.response;
        resolve(serverResponse);
      });
    });
  },
  uploadWeb(config = {}, payload) {
    return new Promise((resolve, reject) => {
      console.log("#### avatar UPLODADING", payload); // eslint-disable-line
      // let url = `https://${apiConfig.storageBaseUrl}:${apiConfig.storagePort}/storage/storage/store`;
      let url = `https://${apiConfig.storageBaseUrl}/storage/storage/store`;
      const headers = {
        MMSAuth: config.Identity,
        MMSAuthSig: config.IdentitySignature
      };

      const formData = new FormData();
      formData.append("PK_Account", payload.account.PK_Account);
      formData.append("PK_User", payload.account.PK_User);
      formData.append("Format", "jpg"); // payload.file.file.type
      formData.append("PK_StorageType", 3);
      formData.append("Filesize", payload.file.file.size);
      formData.append("Filename", payload.fileName);
      formData.append("Data", payload.file.file);
      formData.append("file", payload.file.file);
      fetch(url, {
        method: "POST",
        headers: headers,
        body: formData
      })
        .then(
          response => response.json() // if the response is a JSON object
        )
        .then(success => {
          // Handle the success response object
          resolve(success);
        })
        .catch(error => {
          //Handle the error response object
          reject(
            error.error
              ? error.error.toString()
              : error.message
              ? error.message
              : error.error
          );
        });
    });
  },
  async getFromKVS(config = {}, payload) {
    const headers = {
      MMSAuth: config.Identity,
      MMSAuthSig: config.IdentitySignature
    };
    const requestOptions = {
      url: storage.post,
      baseUrl: apiConfig.storageBaseUrl,
      headers,
      method: "GET",
      params: payload
    };
    const response = await request(requestOptions);
    return {
      response,
      baseUrl: requestOptions.baseUrl,
      url: requestOptions.url
    };
  },
  async requestKVS(config = {}, payload, method = "GET") {
    const {
      Identity: MMSAuth,
      IdentitySignature: MMSAuthSig,
      baseUrl
    } = config;

    const headers = {
      MMSAuth,
      MMSAuthSig
    };

    const requestOptions = {
      url: storage.get,
      baseUrl,
      headers,
      method,
      params: payload
    };

    try {
      const response = await request(requestOptions);
      const content =
        method === "GET" ? await parseHttpResponse(response, "json") : null;
      return {
        response,
        url: requestOptions.url,
        baseUrl: requestOptions.baseUrl,
        data: content ? content.data : null
      };
    } catch (error) {
      console.log("requestKVS Error:", error); // eslint-disable-line
    }
  },
  async getKeyValueFromKVS(config = {}, payload) {
    const { PK_Account } = payload;
    if (PK_Account) {
      const response = await this.requestKVS(config, payload);
      const { key } = config;
      let value = response.data.find(item => item[key]);
      value = value && JSON.parse(value[key]);
      return {
        response,
        imageUrl: `https://${apiConfig.storageBaseUrl}/storage/storage/store`,
        key,
        value
      };
    } else return undefined;
  },
  async getAvatarUrlFromKVS(config = {}, payload) {
    const {
      Identity: MMSAuth,
      IdentitySignature: MMSAuthSig,
      baseUrl,
      key
    } = config;

    const headers = {
      MMSAuth,
      MMSAuthSig
    };
    const requestOptions = {
      url: storage.get,
      baseUrl,
      headers,
      method: "GET",
      params: payload
    };

    const response = await request(requestOptions);
    const { data } = await parseHttpResponse(response, "json");
    const profilePicture = data.find(item => item[key]);
    const regex = /archive/gi;
    const avatarUrl = profilePicture
      ? profilePicture[key].replace(regex, "archive")
      : "";
    return {
      response,
      url: requestOptions.url,
      baseUrl,
      avatarUrl
    };
  },
  async saveToKVS(config = {}, payload) {
    const response = await this.requestKVS(config, payload, "PUT");
    const { key } = config;
    const value = response.data && response.data.find(item => item[key]);
    return {
      response,
      imageUrl: `https://${apiConfig.storageBaseUrl}/storage/storage/store`,
      key,
      value
    };
  }
};

export default storageService;
