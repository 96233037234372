<template>
  <Page actionBarHidden="true">
    <GridLayout rows="auto,*" cols="*" class="device-scanning-info">
      <IBtnHiBtn
        row="0"
        col="0"
        icon1Src="icons/Controls/ic_controls_arrow-left_56.svg"
        @iconTap1="goBack"
      />
      <ScrollView row="1" col="0" class="device-scanning-info__scroll">
        <StackLayout class="device-scanning-info__scroll__content">
          <GridLayout rows="*" columns="*">
            <Spinner
              class="device-scanning-info__scroll__content__percentage"
              row="0"
              col="0"
              :size="240"
            />
            <Label
              class="font-h2-24   device-scanning-info__scroll__content__percentage"
              row="0"
              col="0"
              text="75%"
            />
          </GridLayout>

          <Label
            @tap="detectionSuccess"
            class="font-h1-32 device-scanning-info__scroll__content__label"
            :text="translations.title"
            textWrap
          />
          <Label
            @tap="detectionFail"
            class="font-txt-16-par device-scanning-info__scroll__content__label"
            :text="translations.description"
            textWrap
          />
        </StackLayout>
      </ScrollView>
      <BottomUpDialog ref="dialog" row="0" col="0" rowSpan="2">
        <DetectionCancelDialog @close="quitDetection" />
      </BottomUpDialog>
    </GridLayout>
  </Page>
</template>
<script>
export default {
  name: "CapabilitySearchPage",
  computed: {
    translations() {
      return this.$t("virtualDevices.capabilitySearching");
    }
  },
  methods: {
    goBack() {
      this.$refs.dialog.show();
    },
    quitDetection() {
      this.navigateBack();
    },
    detectionSuccess() {
      this.navigateTo("SelectCapabilities");
    },
    detectionFail() {
      this.navigateTo("CapabilityErrorPage");
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~/assets/styles/variables.scss";

.device-scanning-info {
  &__scroll {
    margin-top: $xxxlll;
    vertical-align: top;
    &__content {
      justify-content: center;
      vertical-align: top;
      horizontal-align: top;
      text-align: center;
      &__label,
      &__link-label {
        margin: $margin-xs 0;
      }
      &__link-label {
        color: $color-primary;
      }
      &__percentage {
        grid-row: 1;
        grid-column: 1;
        align-self: center;
      }
    }
  }
}
</style>
