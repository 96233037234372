<template>
  <StackLayout>
    <ScrollView iosOverflowSafeArea="false">
      <StackLayout v-if="getItemsStatus">
        <Separator size="xl" />
        <StackLayout
          v-for="item in items"
          :key="item.id"
          @tap="handleSelectItem(item)"
        >
          <IconHTxtiBtn
            :icon2Src="
              item.icon
                ? item.icon
                : 'icons/Actions/ic_actions_calendar-month_56.svg'
            "
            alignText="left"
            alignUnderline="left"
            headerSize="h3"
            showUnderline
            :disabled="item.disable === undefined ? true : item.disable"
            :text="item.name"
            :underlineText="item.desc"
          />
          <Separator size="xl" />
        </StackLayout>
      </StackLayout>
    </ScrollView>
  </StackLayout>
</template>
<script>
export default {
  name: "ItemsMenu",
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    getItemsStatus() {
      return !!this.items.length;
    }
  },
  methods: {
    handleSelectItem(item) {
      this.$emit("select", item);
    }
  }
};
</script>
