export const getInitialRoom = () => {
  return {
    id: "",
    name: "",
    src: "images/Rooms/room_image.png",
    selected: false
  };
};
export const getInitialState = () => {
  return {
    loadingStates: {
      saveRoom: false
    },
    selectRoomName: {
      selectedItemId: 0
    },
    newRoom: {},
    rooms: [
      // shape of room
      // {
      //   id: "",
      //   src: "",
      //   name: "",
      //   selected: null,
      //   data: {}
      // }
    ]
  };
};

export default function() {
  return {
    roomsState: getInitialState()
  };
}
