const { VUE_APP_MODE } = process.env;
export const isEmpty = obj =>
  Object.keys(obj).length === 0 && obj.constructor === Object;

export const isChanged = (a, b) => JSON.stringify(a) === JSON.stringify(b);

export const uuidMock = () => {
  const chr4 = () =>
    Math.random()
      .toString(16)
      .slice(-4);

  return (
    chr4() +
    chr4() +
    "-" +
    chr4() +
    "-" +
    chr4() +
    "-" +
    chr4() +
    "-" +
    chr4() +
    chr4() +
    chr4()
  );
};

export const imageArchiveUrl = imageUrl => {
  const regex = /thumbnail/gi;
  return imageUrl ? imageUrl.replace(regex, "archive") : "";
};

export const imageThumbnailUrl = imageUrl => {
  const regex = /archive/gi;
  return imageUrl ? imageUrl.replace(regex, "thumbnail") : "";
};

export const colorHexA = colorHex => {
  if (colorHex) {
    if (colorHex.length < 9 || !colorHex.includes("#")) {
      return colorHex;
    }
    const alpha = colorHex.slice(1, 3);
    const color = colorHex.slice(3, 9);
    return `#${color}${alpha}`;
  }
  return "#FFFFFF";
};

export const colorAHex = colorHex => {
  if (colorHex) {
    if (colorHex.length < 9 || !colorHex.includes("#")) {
      return colorHex;
    }
    const alpha = colorHex.slice(7, 9);
    const color = colorHex.slice(1, 7);
    return `#${alpha}${color}`;
  }
  return "#FFFFFF";
};

export const rgbaTohex = rgba => {
  if (!rgba.includes("rgb")) {
    return rgba;
  }
  let a,
    rgb = rgba
      .replace(/\s/g, "")
      .match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i),
    alpha = ((rgb && rgb[4]) || "").trim(),
    hex = rgb
      ? (rgb[1] | (1 << 8)).toString(16).slice(1) +
        (rgb[2] | (1 << 8)).toString(16).slice(1) +
        (rgb[3] | (1 << 8)).toString(16).slice(1)
      : rgba;

  if (alpha !== "") {
    a = alpha;
  } else {
    a = 1;
  }
  // multiply before convert to HEX
  a = ((a * 255) | (1 << 8)).toString(16).slice(1);
  hex = `#${hex}${a}`;
  return hex;
};

export const rgbaToAHex = rgba => {
  if (!rgba.includes("rgb")) {
    return rgba;
  }
  return colorAHex(rgbaTohex(rgba));
};

export const hasTileItem = template => {
  return (
    template.children &&
    template.children.filter(item => item.children.length > 1).length > 0
  );
};

export const round = (number, decimalPlaces) => {
  const factorOfTen = Math.pow(10, decimalPlaces);
  return Math.round(number * factorOfTen) / factorOfTen;
};

export const convertArrayToObject = (array, key) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item
    };
  }, initialValue);
};

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
