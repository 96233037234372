<template>
  <MainPage>
    <StackLayout>
      <IBtnAct
        src="icons/Controls/ic_controls_arrow-left_56.svg"
        :text="textsMain.textTitle"
        alignText="right"
        @tap="handleBack"
        @actionTap="configureManually"
      />
    </StackLayout>

    <GridLayout rows="*, auto" columns="*" class="detection">
      <GridLayout rows="*, auto" columns="*">
        <StackLayout
          row="0"
          col="0"
          class="detection__descriptions"
          verticalAlignment="center"
        >
          <SvgIcon :src="src" :size="200" verticalAlignment="center" />
          <Label
            class="detection__title font-h2-24"
            :text="textsMain.textDecsTitle"
            textWrap
          />
          <Label
            class="detection__text font-txt-16-par"
            :text="textsMain.textDesc"
            textWrap
          />
        </StackLayout>
        <StackLayout row="1" col="0" class="detection__options">
          <Separator size="xl" />
          <IconHTxtiBtn
            icon2Src="icons/Controls/ic_controls_arrow-right_56.svg"
            alignText="left"
            alignUnderline="left"
            headerSize="h3"
            showUnderline
            :text="textsMain.textOptions"
            :underlineText="language"
            @tap="onTapDetectionOptions"
          />
          <Separator size="xl" />
        </StackLayout>
      </GridLayout>
      <StackLayout row="1" col="0" class="detection__btn-container">
        <ButtonFilled :text="textsMain.textBtn" @tap="handleStartDetection" />
      </StackLayout>
    </GridLayout>
  </MainPage>
</template>
<script>
export default {
  name: "DeviceCapabilities",
  data() {
    return {
      src: "icons/Smarthomes/ic_smarthomes_amazon_alexa.svg"
    };
  },
  computed: {
    textsMain() {
      // eslint-disable-next-line
      return this.$t("deviceCapabilities");
    },
    language() {
      return this.$t("deviceCapabilities.textOptionsDesc", {
        language: "English"
      });
    }
  },
  methods: {
    handleBack() {
      this.navigateBack();
    },
    configureManually() {
      console.log("handleconfigureManuallyCreate"); // eslint-disable-line
    },
    handleStartDetection() {
      this.navigateTo("CapabilitySearchPage");
      console.log("handleStartDetection"); // eslint-disable-line
    },
    onTapDetectionOptions() {
      console.log("onTapDetectionOptions"); // eslint-disable-line
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~/assets/styles/variables.scss";

.detection {
  height: 100%;
  &__btn-container {
    padding: 0 $xl $xxl $xl;
  }
  &__title {
    text-align: center;
    color: $color-black;
  }
  &__text {
    margin: $margin-s 0 0 0;
    text-align: center;
    color: $color-black;
  }
  &__options {
    margin-bottom: $l;
  }
  &__descriptions {
    padding: 0 $xl;
    align-self: center;
  }
}
</style>
