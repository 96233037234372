export default {
  isAuthenticated: state => {
    return !!(
      state.authState &&
      state.authState.Identity &&
      state.authState.jwtToken
    );
  },
  tokenExpiration: state => {
    return (
      state.authState &&
      state.authState.IdentityDecoded &&
      state.authState.IdentityDecoded["Expires"]
    );
  },
  getAuthState: state => {
    console.dir(state.authState); // eslint-disable-line
    return state.authState;
  },
  isWebView: state => state.isWebView,
  getPath: state => state.path,
  getDashboardId: state => state.dashboardId,
  getControllerId: state => state.controllerId,
  getControllerType: state => {
    return parseInt(state.controllerId) > 69999999
      ? "ezlo"
      : parseInt(state.controllerId) < 70000000
      ? "vera"
      : "no_hub";
  },
  getCloudJwtToken: (state, getters) => getters.getAuthState.jwtToken,
  getUser: state => state.user,
  userEmail: state => state.user.email,
  userName: state => state.user.username,
  resetPasswordStatus: state => state.user.isResetPassword,
  getAccountId: (state, getters) => {
    if (!getters.isAuthenticated) {
      return null;
    }
    return getters.getAuthState.IdentityDecoded["PK_Account"];
  },
  getSignupLocationName: (state, getters) => {
    const userCredentials = getters.getUser;
    return userCredentials && userCredentials.locationName;
  },
  isShareActivity: state => {
    return state.fetchingShareActivity;
  },
  isShareInactivity: state => {
    return state.fetchingShareInactivity;
  },
  getAvatarObject: (state, getters) => {
    const PK_Account = getters.getAccountId;
    return state.user.avatar.find(item => item.PK_Account === PK_Account);
  },
  getAvatarUrl: (state, getters) => {
    // `https://${baseUrl}${url}/${PK_Store}/thumbnail?Key=${Key}`;
    const PK_Account = getters.getAccountId;
    const avatar = state.user.avatar.find(
      item => item.PK_Account === PK_Account
    );
    return avatar && avatar.avatarUrl;
  },
  getUserAvatar: (state, getters) => {
    return getters.getAvatarUrl
      ? getters.getAvatarUrl
      : "~/assets/images/avatar_56.png";
  },
  getUserAvatarHost: state => state.authState.Server_Account_Alt,
  getUserAvatarHostAlt: state => state.authState.Server_Account,
  getPK_User: state => state.authState.IdentityDecoded.PK_User,
  isFetchingAvatar: state => state.fetchingAvatar,
  updateTemplate: state => state.updateTemplate,
  getKVSToken: (state, getters) => (alt = false) => {
    const { Identity, IdentitySignature } = getters.getAuthState;
    return {
      Identity,
      IdentitySignature,
      baseUrl: !alt ? getters.getUserAvatarHost : getters.getUserAvatarHostAlt
    };
  },
  hasEzloProtectSubscription: state => state.hasEzloProtectSubscription
};
