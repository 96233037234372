<template>
  <MainPage>
    <GridLayout class="sign-up" rows="auto, *" columns="*">
      <GridLayout rows="*" columns="auto,*">
        <StackLayout
          orientation="horizontal"
          @tap="goBack"
          class="locale"
          row="0"
          col="0"
        >
          <ImageCacheIt
            :src="src"
            class="sign-up__image"
            placeholder="code"
            stretch="aspectFill"
            style.zIndex="10"
          />
          <Label class="font-act-8 locale__text" :text="fetchTranslationName" />
        </StackLayout>
        <StackLayout row="0" col="1">
          <IBtnAct
            class="locale__header"
            :primary="true"
            @tap="goToForgotPassword"
            @actionTap="goToForgotPassword"
            :text="localization.signIn"
          />
        </StackLayout>
      </GridLayout>
      <IntroGetUp
        row="1"
        col="0"
        iconPath="icons/Visuals/ic_visuals_home_200.svg"
        :title="localization.title"
        :descr="localization.description"
        :btnText="localization.button"
        :policyText="policyLocalization"
        :isShowTerms="true"
        @send="onCreateProfileTap"
      />
    </GridLayout>
  </MainPage>
</template>

<script>
import IntroGetUp from "@/components/onboarding/IntroGetUp";

export default {
  name: "SignUpIntro",
  components: {
    IntroGetUp
  },
  methods: {
    goBack() {
      // this.navigateBack();
      console.log(this.$i18n.locale); // eslint-disable-line
      this.$i18n.locale = this.$i18n.locale === "fr" ? "en" : "fr";
    },
    onCreateProfileTap() {
      this.navigateTo("LoginByUserIntro");
    },
    goToForgotPassword() {
      this.navigateTo("ForgotPasswordEmailIntro");
    }
  },
  computed: {
    src() {
      return this.$i18n.locale === "fr"
        ? "~/assets/icons/Flags/fr.png"
        : "~/assets/icons/Flags/us.png";
    },
    fetchTranslationName() {
      return this.$t("name");
    },
    localization() {
      return this.$t("signupIntro");
    },
    policyLocalization() {
      return this.$t("core.policy.text", {
        companyName: "Vera"
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~/assets/styles/variables.scss";

.locale {
  padding: 0 $l 0 $l;
  height: $xxxll;
  horizontal-align: left;
  vertical-align: middle;
  &__text {
    horizontal-align: left;
    vertical-align: middle;
    padding-left: $m;
    line-height: $xxxll;
    cursor: pointer;
  }
}
.sign-up {
  height: 100%;
  &__image {
    align-self: center;
    width: $xl;
    height: $xl;
  }
}
</style>
