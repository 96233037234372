<template>
  <MainPage>
    <GridLayout rows="*, auto" columns="*">
      <KeyboardAvoidingView
        ref="keyboardAvoidingView"
        row="0"
        col="0"
        contentVerticalAlign="bottom"
        :iosTextInputKeyboardDistance="iosTextInputKeyboardDistance"
      >
        <IBtnAct
          slot="action-bar"
          src="icons/Controls/ic_controls_arrow-left_56.svg"
          @tap="onBack"
        />

        <CreatePasswordForm
          slot="content"
          :titleText="translations.title"
          :passwordPlaceholder="translations.pwdPlaceholder"
          :reEnterPasswordPlaceholder="translations.reEnterPwdPlaceholder"
          :btnText="translations.btn"
          :errorText="translations.errorText"
          :isLoading="isFetching('createNewPassword')"
          @submit="onSendNewPassword"
          @layoutChange="handleKeyboard"
        />
      </KeyboardAvoidingView>
    </GridLayout>
  </MainPage>
</template>
<script>
import CreatePasswordForm from "@/components/forms/CreatePasswordForm";
import { createNamespacedHelpers } from "vuex";
import { helpers } from "ezlo-core-ui";

const { appConst } = helpers;
const IOS_TEXT_INPUT_KEYBOARD_OFFSET = 220;
const { mapActions: mapAuthActions } = createNamespacedHelpers("auth");
const {
  mapGetters: mapUiGetters,
  mapActions: mapUiActions
} = createNamespacedHelpers("ui");

export default {
  name: "ForgotPasswordEmailNewPassword",
  components: {
    CreatePasswordForm
  },
  data() {
    return {
      areInputsVisible: false
    };
  },
  computed: {
    ...mapUiGetters(["isFetching", "hasError"]),
    iosTextInputKeyboardDistance() {
      return this.areInputsVisible
        ? appConst.IOS_TEXT_INPUT_KEYBOARD_OFFSET_DEFAULT
        : IOS_TEXT_INPUT_KEYBOARD_OFFSET;
    },
    translations() {
      const t = this.$t("createNewPassword");
      return { ...t, btn: this.$t("createNewPassword.btnText") };
    }
  },
  methods: {
    ...mapAuthActions(["createNewPassword"]),
    onBack() {
      this.navigateTo("ForgotPasswordEmailReset");
    },
    goNext() {
      this.navigateTo("Dashboard");
    },
    handleKeyboard(areInputsVisible) {
      this.areInputsVisible = areInputsVisible;
      if (!areInputsVisible) {
        const { keyboardAvoidingView } = this.$refs;
        keyboardAvoidingView.scrollToBottom();
      }
    },
    async onSendNewPassword(newPassword) {
      await this.createNewPassword(newPassword);
      if (this.hasError) return;
      this.goNext();
    }
  }
};
</script>
