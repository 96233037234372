<template>
  <StackLayout slot="content" class="create-password-form">
    <VxpLabel
      class="create-password-form__title font-h1-32"
      :text="titleText"
      :textWrap="true"
    />
    <TextInput
      ref="password"
      class="create-password-form__password"
      type="password"
      :placeholder="passwordPlaceholder"
      :maxLength="maxPasswordLength"
      :hintType="passwordErrorHintType"
      :hintText="passwordError"
      :showSoftInput="true"
      :keepSoftInput="!isPasswordValid"
      v-model="password"
      T9Disabled
      preventSpace
    />

    <TextInput
      class="create-password-form__password-re-enter"
      type="password"
      :placeholder="reEnterPasswordPlaceholder"
      :maxLength="maxPasswordLength"
      :hintType="reEnterPasswordErrorHintType"
      :hintText="reEnterPasswordError"
      v-model="reEnterPassword"
      v-show="isPasswordValid"
      @blur="onBlurReEnterPassword"
      @focus="onFocusReEnterPassword"
      @textChange="onReEnterPasswordChange"
      @returnPress="onReturnReEnterPassword"
      T9Disabled
      preventSpace
    />
    <PasswordChecks
      class="create-password-form__checks"
      :uppercaseError="uppercaseError"
      :lowercaseError="lowercaseError"
      :oneNumberError="oneNumberError"
      :charactersError="charactersError"
      :title="pwdCheccksTitleT"
      :uppercaseLetterReq="uppercaseLetterReqT"
      :lowercaseLetterReq="lowercaseLetterReqT"
      :numberReq="numberReqT"
      :charactersReq="charactersReqT"
      v-show="!isPasswordValid"
    />
    <ButtonFilled
      class="create-password-form__button"
      :loading="isLoading"
      :disabled="isNextButtonDisabled"
      :text="btnText"
      v-show="isPasswordValid"
      @tap="onSubmit"
    />
  </StackLayout>
</template>

<script>
import PasswordChecks from "@/components/onboarding/PasswordChecks";
import {
  maxLength,
  minLength,
  required,
  sameAs
} from "vuelidate/lib/validators";
import { helpers } from "ezlo-core-ui";

const { uppercase, lowercase, oneNumber, characters } = helpers.utils;

export default {
  name: "CreatePasswordForm",
  components: {
    PasswordChecks
  },
  props: {
    titleText: {
      type: String,
      default: "Title"
    },
    passwordPlaceholder: {
      type: String,
      default: "Password"
    },
    reEnterPasswordPlaceholder: {
      type: String,
      default: "Re-enter password"
    },
    btnText: {
      type: String,
      default: "Button"
    },
    errorText: {
      type: String,
      default: "Re-enter password error"
    },
    minPasswordLength: {
      type: Number,
      default: 8
    },
    maxPasswordLength: {
      type: Number,
      default: 16
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      password: "",
      reEnterPassword: "",
      reEnterPasswordShowError: false,
      pwdCheccksTitleT: this.$t("passwordChecks.title"),
      uppercaseLetterReqT: this.$t("passwordChecks.uppercaseLetter"),
      lowercaseLetterReqT: this.$t("passwordChecks.lowercaseLetter"),
      numberReqT: this.$t("passwordChecks.number"),
      charactersReqT: this.$t("passwordChecks.characters")
    };
  },
  computed: {
    isNextButtonDisabled() {
      return !this.reEnterPassword;
    },
    isPasswordValid() {
      return !this.$v.password.$invalid;
    },
    passwordError() {
      if (!this.$v.password.$model) {
        return "";
      }
      return "";
    },
    passwordErrorHintType() {
      return this.passwordError ? "error" : "info";
    },
    reEnterPasswordError() {
      if (!this.reEnterPasswordShowError) {
        return "";
      }
      let error = "";

      if (!this.$v.reEnterPassword.required) {
        error = "";
      } else if (!this.$v.reEnterPassword.sameAsPassword) {
        error = this.errorText;
      }

      return error;
    },
    reEnterPasswordErrorHintType() {
      return this.reEnterPasswordError ? "error" : "info";
    },
    uppercaseError() {
      return uppercase(this.password);
    },
    lowercaseError() {
      return lowercase(this.password);
    },
    oneNumberError() {
      return oneNumber(this.password);
    },
    charactersError() {
      const length = this.password ? this.password.length : 0;
      return (
        length >= this.minPasswordLength && length <= this.maxPasswordLength
      );
    }
  },
  methods: {
    onSubmit() {
      this.$v.$touch();
      this.reEnterPasswordShowError = true;

      if (!this.$v.password.$invalid && !this.$v.reEnterPassword.$invalid) {
        this.$emit("submit", this.password);
      }
    },
    focusReEnterPassword() {
      if (!this.isPasswordValid) return;
      const { reEnterPassword } = this.$refs;
      if (reEnterPassword) reEnterPassword.focus();
    },
    onFocusReEnterPassword() {
      this.$emit("onFocusReEnterPassword", "reEnterPassword");
      if (this.password.length === 0) {
        this.reEnterPasswordShowError = false;
      }
      this.reEnterPasswordShowError = false;
    },
    onBlurReEnterPassword() {
      this.reEnterPasswordShowError = true;
    },
    onReEnterPasswordChange() {
      this.reEnterPasswordShowError = false;
    },
    onReturnReEnterPassword(args) {
      const textField = args.object;
      if (textField.android) {
        textField.android.clearFocus();
      }
    }
  },
  validations() {
    return {
      password: {
        required,
        minLength: minLength(this.minPasswordLength),
        maxLength: maxLength(this.maxPasswordLength),
        uppercase,
        lowercase,
        oneNumber,
        characters
      },
      reEnterPassword: {
        required,
        sameAsPassword: sameAs("password")
      }
    };
  },
  watch: {
    isPasswordValid() {
      this.$emit("layoutChange", this.isPasswordValid);
    }
  }
};
</script>

<style scoped lang="scss">
@import "~/assets/styles/variables.scss";

.create-password-form {
  height: 100%;
  padding: 0 $padding-xl $padding-xl $padding-xl;
  color: $color-black;

  &__title {
    margin-bottom: $margin-xl;
  }
  &__password-re-enter {
    margin-top: $margin-xl;
    margin-bottom: $margin-xl;
  }
  &__checks {
    margin-top: $margin-m;
  }
}
</style>
