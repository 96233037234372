<template>
  <GridLayout rows="*, auto" class="d-template-type" height="100%">
    <ScrollView
      row="0"
      col="0"
      iosOverflowSafeArea="false"
      class="d-template-type__top"
    >
      <Templates
        class="d-template-type__list"
        :templates="templates"
        :selectedTemplateId="selectedTemplateId"
        @selectTemplate="handleSelectTemplate"
        :orientation="orientation"
      />
    </ScrollView>

    <StackLayout row="1" col="0" class="d-template-type__bottom">
      <ButtonFilled
        :text="texts.textBtn"
        :disabled="getButtonDisabledStatus"
        @tap="handleTapNext"
      />
    </StackLayout>
  </GridLayout>
</template>
<script>
import Templates from "@/components/dashboard/Templates";

export default {
  name: "DashboardTemplateTypeOverview",
  components: { Templates },
  props: {
    templates: {
      type: Array,
      default: () => []
    },
    selectedTemplateId: {
      type: String,
      default: ""
    },
    texts: {
      type: Object,
      default: () => ({
        textBtn: "Button"
      })
    },
    orientation: {
      type: String,
      default: ""
    }
  },
  computed: {
    getButtonDisabledStatus() {
      return !this.selectedTemplateId;
    }
  },
  methods: {
    handleSelectTemplate(template) {
      this.$emit("selectTemplate", template);
    },
    handleTapNext() {
      this.$emit("gotoNext");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~/assets/styles/variables.scss";

.d-template-type {
  padding: 0 $padding-xl;
  &__list {
    padding-top: $padding-xl;
    padding-bottom: $padding-xxxl;
    min-height: $web-min-height;
  }
  &__bottom {
    margin-bottom: $margin-xl;
  }
}
</style>
