<template>
  <StackLayout class="hub-list">
    <VxpLabel
      class="hub-list__title font-h1-32"
      :text="texts.textTitle"
      :textWrap="true"
    />

    <StackLayout class="hub-list__spinner" v-if="!getHubsFetchStatus">
      <Spinner height="100%" />
    </StackLayout>

    <StackLayout class="hub-list__wrap" v-if="getHubsFetchStatus">
      <StackLayout v-for="(item, idx) in hubsList" :key="item.id">
        <StackLayout class="hub-list__item" @tap="handleTapHub(item)">
          <Separator size="xl" />

          <IconHTxtiBtn
            headerSize="h3"
            alignText="left"
            :icon2Src="item.icon"
            :text="item.name"
          />

          <Separator size="xl" v-if="getLastItem(idx)" />
        </StackLayout>
      </StackLayout>
    </StackLayout>
  </StackLayout>
</template>

<script>
export default {
  name: "HubList",
  props: {
    hubsList: {
      type: Array,
      default: () => []
    },
    isFetching: {
      type: Boolean,
      default: false
    },
    texts: {
      type: Object,
      default: () => ({
        textTitle: "Title"
      })
    }
  },
  computed: {
    getHubsLength() {
      return this.hubsList.length;
    },
    getHubsFetchStatus() {
      return !this.isFetching && !!this.getHubsLength;
    }
  },
  methods: {
    getLastItem(index) {
      return this.getHubsLength - 1 === index;
    },
    handleTapHub(item) {
      this.$emit("onSelectHub", item);
    }
  }
};
</script>
<style scoped lang="scss">
@import "~/assets/styles/variables.scss";

.hub-list {
  &__title {
    padding: $padding-xl $padding-xl 0 $padding-xl;
    margin-bottom: $margin-xs;
    text-align: left;
  }

  &__wrap {
    margin-bottom: $margin-m;
  }

  &__title,
  &__item {
    color: $color-black;
  }
  &__spinner {
    height: $xxxxll;
  }
}
</style>
