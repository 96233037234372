import FilePicker from "@/helpers/filePicker";
const filePicker = new FilePicker();

export const selectFileByCamera = (
  { pickerType = "media", multipleSelection = false },
  _getCallbackData
) => {
  filePicker.options = {
    pickerType: pickerType || filePicker.pickerTypes.media,
    extensions: [],
    maxNumberFiles: 1,
    multipleSelection
  };

  filePicker.open();

  filePicker.on("selected", files => {
    // this.fileUploading = true;
    for (const file of files) {
      // const file = files[0];
      console.dir(file); //eslint-disable-line
      const path = URL.createObjectURL(file);
      file.relativePath = path;
      _getCallbackData(file);
    }
  });
};
