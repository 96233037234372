<template>
  <OneAction
    icon="icons/Visuals/ic_warning_visuals_connected-to-another-user_200.svg"
    :text1="titleT"
    :text2="descT"
    :action="btnT"
    @tap="close"
    :warning="true"
  />
</template>

<script>
export default {
  name: "ErrorLinkValid",
  data() {
    return {
      titleT: this.$t("core.errorDialogLinkValid.title"),
      descT: this.$t("core.errorDialogLinkValid.desc"),
      btnT: this.$t("core.errorDialogLinkValid.btn")
    };
  },
  methods: {
    close() {
      this.$emit("close");
      this.navigateTo("ForgotPasswordEmailSend");
    }
  }
};
</script>
