import { helpers } from "ezlo-core-ui";
// import { request } from "ezlo-core-ui/helpers/core/http";
import qs from "querystringify";
import apiConfig from "../../../config/api";

const { request } = helpers.core.http;
export default ({
  method = "GET",
  params = {},
  body = {},
  headers = {},
  baseUrl = apiConfig.baseUrl,
  url = "",
  protocol = "https",
  stringifyContent = true
}) => {
  url = `${protocol}://${baseUrl}${url}`;
  if (method === "GET") {
    url += qs.stringify(params, "?");
  } else if (["PUT", "POST"].includes(method)) {
    if (stringifyContent) {
      body = JSON.stringify(params);
    }
  }
  if (stringifyContent) {
    Object.assign(headers, { "Content-Type": "application/json" });
  }

  const requestOptions = { headers, method };
  if (method !== "GET") {
    requestOptions.body = body;
  }
  return request(url, requestOptions);
};
