import {
  ADD_ROOM,
  DELETE_ROOM,
  EDIT_ROOM,
  NEW_ROOM,
  RESET_STATE,
  SAVE_EDIT_ROOM,
  SET_LOADING_STATE,
  SET_ROOM,
  SET_ROOMS_STATE,
  SET_SELECTED_ROOM,
  SET_VALUE,
  UNSET_LOADING_STATE
} from "./mutationTypes";
import { getInitialState } from "./state";
import roomService from "@/services/nma.service/room";
import * as nmaService from "@/services/nma.service";

export default {
  async fetchRooms(context, payload) {
    try {
      const result = await nmaService.room.list(payload);
      context.commit(SET_ROOMS_STATE, {
        isVera: false,
        rooms: result.data,
        hub: payload
      });
    } catch (err) {
      console.log(err); // eslint-disable-line
    }
  },
  resetState({ commit }) {
    commit(RESET_STATE);
  },
  async loadRooms({ commit }) {
    try {
      commit(SET_LOADING_STATE, "loadRooms");
      const { data } = await roomService.get();
      console.log(data); // eslint-disable-line
      commit(SET_ROOMS_STATE, { rooms: data });
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(UNSET_LOADING_STATE, "loadRooms");
    }
  },
  addVeraRooms(context, payload) {
    context.commit(SET_ROOMS_STATE, { isVera: true, ...payload });
  },
  setSelectedRoom({ commit }, payload) {
    // TODO: API connection will be implement.
    commit(SET_SELECTED_ROOM, payload);
  },
  setRoom({ commit }, payload) {
    // TODO: API connection will be implement.
    commit(SET_ROOM, payload);
  },
  newRoom({ commit }, payload) {
    // preparing for new room
    commit(NEW_ROOM, payload);
  },
  addRoom({ commit }, payload) {
    // TODO: API connection will be implement.
    commit(ADD_ROOM, payload);
  },
  editRoom({ commit }, payload) {
    // It prepares to edit an existing room.
    commit(EDIT_ROOM, payload);
  },
  async saveRoom({ commit, dispatch }, payload) {
    try {
      commit(SET_LOADING_STATE, "saveRoom");
      await roomService.add(payload);
      await dispatch("rooms/loadRooms", null, { root: true });
      // TODO: subscribe on hub.room.created broadcast with id of created room
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(UNSET_LOADING_STATE, "saveRoom");
    }
  },
  async saveEditRoom({ commit, dispatch }, payload) {
    try {
      dispatch("ui/startFetching", "editRoom", { root: true });
      const result = await roomService.edit(payload);
      if (!result) {
        throw new Error("Sorry, that didn’t go through");
      }
      commit(SAVE_EDIT_ROOM, payload);
    } catch (error) {
      dispatch("ui/emitError", { error, meta: SAVE_EDIT_ROOM }, { root: true });
      throw new Error(error);
    } finally {
      dispatch("ui/stopFetching", "editRoom", { root: true });
    }
  },
  async deleteRoom({ commit, dispatch, rootGetters }, { id, newRoomId }) {
    try {
      dispatch("ui/startFetching", "deleteRoom", { root: true });
      const devices = rootGetters["devices/getDevicesByRoom"](id);
      await roomService.delete({ id });
      if (newRoomId) {
        await Promise.all(
          devices.map(async ({ id: deviceId, parentDeviceId }) => {
            if (parentDeviceId) return null;
            return await dispatch(
              "devices/changeRoom",
              { deviceId, roomId: newRoomId },
              { root: true }
            );
          })
        );
      }
    } catch (error) {
      dispatch("ui/emitError", { error, meta: DELETE_ROOM }, { root: true });
      throw new Error(error);
    } finally {
      dispatch("ui/stopFetching", "deleteRoom", { root: true });
    }
  },
  setValue({ commit }, payload) {
    commit(SET_VALUE, payload);
  },
  updateRooms({ commit }, { action, data }) {
    //handle broadcast here
    const { _id, value } = data;
    switch (action) {
      case "edited":
        console.log("# ---> Room Edited <--- #", { _id, value }); // eslint-disable-line
        break;
      case "created":
        console.log("# ---> Room Added <--- #", { _id, value }); // eslint-disable-line
        break;
      case "deleted":
        console.log("# ---> Room Removed <--- #", { _id, value }); // eslint-disable-line
        commit(DELETE_ROOM, { id: _id });
        break;
      case "reordered":
        console.log("# ---> Room Removed <--- #", { _id, value }); // eslint-disable-line
        break;
    }
    console.log("# ---> broadcast <--- #", data); // eslint-disable-line
  }
};
