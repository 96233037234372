import {
  RESET_SCENES,
  SET_SCENES,
  ADD_SELECTED_SCENE,
  REMOVE_SELECTED_SCENE,
  SET_SELECTED_SCENE,
  RESET_SELECTED_SCENE,
  SET_CLOUD_SCENES,
  START_LOADING,
  STOP_LOADING
} from "./mutationTypes";
import * as nmaService from "@/services/nma.service";
import cloudService from "@/services/cloud.service";
import legacyService from "@/services/legacy.service";
import { parseHttpResponse } from "@/helpers/responseParser";

const actions = {
  async fetchScenes(context, payload) {
    try {
      const {
        data: { scenes }
      } = await nmaService.scenes.list(payload);
      context.dispatch("setScenes", { scenes, hub: payload });
    } catch (err) {
      console.log(err); // eslint-disable-line
      context.dispatch(
        "ui/emitError",
        {
          error: err,
          meta: "fetchScenes"
        },
        {
          root: true
        }
      );
    }
  },
  async fetchCloudScenes({ commit, dispatch, rootGetters }) {
    try {
      const authJwtToken = rootGetters["auth/getCloudJwtToken"];
      const response = await cloudService.getCloudScenes({ authJwtToken });
      const { data, statusCode } = await parseHttpResponse(response, "json");
      if (statusCode !== 200) {
        throw new HttpError(data, statusCode);
      }
      commit(SET_CLOUD_SCENES, data.data);
    } catch (err) {
      console.log(err); // eslint-disable-line
      dispatch(
        "ui/emitError",
        {
          error: err,
          meta: "fetchCloudScenes"
        },
        {
          root: true
        }
      );
    }
  },
  async setScenes({ commit }, payload) {
    commit(SET_SCENES, payload);
  },
  // Async
  async resetScenes({ commit }) {
    commit(RESET_SCENES);
  },
  addSelectedScene({ commit }, payload) {
    commit(ADD_SELECTED_SCENE, payload);
  },
  removeSelectedScene({ commit }, payload) {
    commit(REMOVE_SELECTED_SCENE, payload);
  },
  resetSelectedScenes({ commit }, payload) {
    commit(RESET_SELECTED_SCENE, payload);
  },
  setSelectedScenes({ commit }, payload) {
    commit(SET_SELECTED_SCENE, payload);
  },
  addVeraScenes({ commit }, payload) {
    const options = { isVera: true, ...payload };
    commit(SET_SCENES, options);
  },
  async runSceneAsync({ dispatch, rootGetters }, { sceneId, hub, type }) {
    try {
      console.log("# ---> runSceneAsync <--- #", { sceneId, hub }); // eslint-disable-line
      if (type === "ezlo") {
        return await nmaService.scenes.run(sceneId, hub);
      } else if (type === "vera") {
        // TODO
        const relay = rootGetters["hub/getRelayToken"](hub);
        if (relay) {
          const options = {
            baseUrl: relay.Server_Relay,
            relayToken: relay.relayToken
          };
          try {
            const response = await legacyService.runScene(
              sceneId,
              hub,
              options
            );
            if (response.status === 200) {
              dispatch("startLoading", sceneId);
            } else {
              dispatch("ui/saveSnackbarErrorText", "scenes.failed", {
                root: true
              });
            }
            return response;
          } catch (error) {
            console.log(error); // eslint-disable-line
            dispatch("stopLoading", payload.scene_id);
            dispatch("ui/saveSnackbarErrorText", "scenes.failed", {
              root: true
            });
          }
        }
      }
    } catch (error) {
      console.log("# ---> runSceneAsync <--- #", error); // eslint-disable-line
      dispatch(
        "ui/emitError",
        {
          error: error,
          meta: "runSceneAsync"
        },
        {
          root: true
        }
      );
    }
  },
  updateScene({ dispatch }, payload) {
    console.log("# ---> updateScene <--- #", { payload }); // eslint-disable-line
    if (payload.status === "started") {
      dispatch("startLoading", payload.scene_id);
    } else if (payload.status === "finished") {
      dispatch("stopLoading", payload.scene_id);
      dispatch("ui/saveSnackbarText", "scenes.finished", {
        root: true
      });
    } else {
      // status = "failed", etc.
      dispatch("stopLoading", payload.scene_id);
      dispatch("ui/saveSnackbarErrorText", "scenes.failed", {
        root: true
      });
    }
  },
  startLoading({ commit }, payload) {
    commit(START_LOADING, payload);
  },
  stopLoading({ commit }, payload) {
    commit(STOP_LOADING, payload);
  }
};
export default actions;
