<template>
  <MainPage>
    <GridLayout class="device-control" rows="*" columns="*">
      <StackLayout class="device-control" row="0" col="0">
        <IBtnHiBtn
          icon1Src="icons/Controls/ic_controls_arrow-left_56.svg"
          :text="textsMain.textTitle"
          @iconTap1="handleBack"
          class="devices__header"
        />

        <Separator size="xl" />
        <IconHTxtiBtn
          @tap="selectVoiAccount"
          :icon1Src="abstractDevices.src"
          alignText="left"
          alignUnderline="left"
          headerSize="h3"
          showUnderline
          :text="abstractDevices.name"
          :underlineText="abstractDevices.eMail"
        />
        <Separator size="xl" />
        <GridLayout rows="*, auto" columns="*" class="device-control">
          <GridLayout rows="*, auto" columns="*" class="add-device__devices">
            <StackLayout row="1" col="0" class="device-control__btn-container">
              <Label
                class="device-control__input-label font-txt-16-par"
                :text="textsMain.textInputLabel"
                textWrap
              />
              <TextInput
                v-model="$v.name.$model"
                type="text"
                showSoftInput
                eraseButton
                returnKeyType="done"
                :hintText="textsMain.textDesc"
                hintTextWrap
                @returnPress="handleSubmit"
              />
            </StackLayout>
          </GridLayout>
          <StackLayout row="1" col="0" class="device-control__btn-container">
            <ButtonFilled
              :disabled="isDisabled"
              :text="textsMain.textBtn"
              @tap="handleCreate"
            />
          </StackLayout>
        </GridLayout>
      </StackLayout>
      <BottomUpDialog row="0" col="0" ref="dialog">
        <SelectVOIAccount />
      </BottomUpDialog>
    </GridLayout>
  </MainPage>
</template>
<script>
import { required } from "vuelidate/lib/validators";

export default {
  name: "DeviceToControl",
  data() {
    return {
      name: "",
      abstractDevices: {
        name: "Amazon Alexa",
        eMail: "user@gmail.com",
        src: "icons/Smarthomes/ic_smarthomes_amazon_alexa.svg"
      }
    };
  },
  computed: {
    textsMain() {
      // eslint-disable-next-line
      return this.$t("deviceToControl");
    },
    isDisabled() {
      return this.$v.name.$invalid;
    }
  },
  methods: {
    handleBack() {
      this.navigateBack();
    },
    handleCreate() {
      this.navigateTo("DeviceCapabilities");
    },
    handleSubmit() {
      console.log("handleSubmit"); // eslint-disable-line
    },
    onTapAbstractDevice() {
      console.log("onTapAbstractDevice"); // eslint-disable-line
    },
    selectVoiAccount() {
      this.$refs.dialog.show();
    }
  },
  validations() {
    return {
      name: {
        required
      }
    };
  }
};
</script>
<style lang="scss" scoped>
@import "~/assets/styles/variables.scss";

.device-control {
  height: 100%;
  &__container {
    margin: 0 $xl;
  }
  &__btn-container {
    padding: 0 $xl $xxl $xl;
  }
  &__hint {
    text-align: left;
    color: $color-grey;
  }
  &__input-label {
    margin-bottom: $s;
    text-align: left;
    color: $color-black;
  }
  &__alexaImage {
    grid-column: 2;
    justify-self: end;
    width: unit(124);
    height: unit(124);
  }
  &__googleImage {
    grid-column: 3;
    justify-self: start;
    width: unit(124);
    height: unit(124);
  }
}
</style>
