// Core
import {
  RESET_UI_STATE,
  SET_CONNECTION_TYPE,
  EMIT_ERROR,
  RESET_ERROR,
  START_FETCHING,
  STOP_FETCHING,
  SAVE_SNACKBAR_TEXT,
  SET_PAGE_LOADING
} from "./mutationTypes";

const actions = {
  setConnectionType: (context, payload) => {
    context.commit(SET_CONNECTION_TYPE, payload);
  },
  startFetching: (context, payload) => {
    context.commit(START_FETCHING, payload);
  },
  stopFetching: (context, payload) => {
    context.commit(STOP_FETCHING, payload);
  },
  emitError: (context, { type, error, meta = null, code }) => {
    context.commit(EMIT_ERROR, {
      type,
      message: error,
      error: true,
      meta,
      code
    });
  },
  resetError: context => {
    context.commit(RESET_ERROR);
  },
  saveSnackbarText: (context, payload) => {
    context.commit(SAVE_SNACKBAR_TEXT, { text: payload });
  },
  saveSnackbarErrorText: (context, payload) => {
    context.commit(SAVE_SNACKBAR_TEXT, { text: payload, type: "error" });
  },
  resetState: context => {
    context.commit(RESET_UI_STATE);
  },
  startPageLoading: context => {
    context.commit(SET_PAGE_LOADING, true);
  },
  stopPageLoading: context => {
    context.commit(SET_PAGE_LOADING, false);
  }
};

export default actions;
