<template>
  <MainPage ref="mainPage">
    <StackLayout>
      <IBtnHiBtn
        icon1Src="icons/Controls/ic_controls_arrow-left_56.svg"
        :text="goalId ? traslations.editGoal : traslations.title"
        @iconTap1="goBack"
        class="new-goal__title"
      />
    </StackLayout>
    <GridLayout rows="*, auto" columns="*" class="new-goal">
      <ScrollView row="0" col="0" iosOverflowSafeArea="false" height="100%">
        <StackLayout row="0" col="0">
          <VxpLabel
            class="font-h2-24 new-goal__group-text"
            :text="traslations.iWantMy"
            textWrap
          />
          <Separator class="new-goal__separator" size="xl" />
          <IconHTxtiBtn
            class="new-goal__item new-goal__item--green "
            :icon1Src="getDeviceIcon"
            icon2Src="icons/Controls/ic_controls_arrow-right_56.svg"
            alignText="left"
            alignUnderline="left"
            headerSize="h3"
            :text="getDeviceName"
            @tap="selectDevice"
          />
          <Separator class="new-goal__separator" size="xl" />
          <VxpLabel
            class="font-h2-24 new-goal__group-text"
            :text="traslations.toBeUnder"
            textWrap
          />
          <GridLayout rows="auto" columns="*, auto" class="new-goal__limit">
            <TextInput
              row="0"
              col="0"
              class="new-goal__input"
              v-model="$v.goal.limit.$model"
              showSoftInput
              returnKeyType="done"
              :placeholder="traslations.enterTheLimit"
              :hintType="limitErrorHintType"
              :hintText="limitErrorHintText"
            />
            <GridLayout
              row="0"
              col="1"
              rows="60, 60"
              columns="auto"
              class="new-goal__kwh"
            >
              <VxpLabel
                row="0"
                col="0"
                class="font-txt-16-par new-goal__kwh-label"
                text="kWh"
                textWrap
              />
            </GridLayout>
          </GridLayout>

          <VxpLabel
            class="font-h2-24 new-goal__group-text"
            :text="traslations.atTheEndOfThe"
            textWrap
          />
          <Separator class="new-goal__separator" size="xl" />
          <IconHTxtiBtn
            class="new-goal__item"
            icon1Src="icons/Actions/ic_actions_calendar_56.svg"
            icon2Src="icons/Controls/ic_controls_arrow-right_56.svg"
            alignText="left"
            alignUnderline="left"
            headerSize="h3"
            :text="traslations.month"
            @tap="selectMonth"
          />
          <Separator class="new-goal__separator" size="xl" />
        </StackLayout>
      </ScrollView>
      <StackLayout row="1" col="0" class="new-goal__btn-container">
        <ButtonFilled
          :text="getButtonText"
          :disabled="isDisabled"
          @tap="save"
          :loading="isFetching('saveGoal')"
        />
      </StackLayout>
    </GridLayout>
  </MainPage>
</template>
<script>
import { required, numeric } from "vuelidate/lib/validators";
import { helpers } from "ezlo-core-ui";
import { getDialogOptions } from "@/helpers/errorTypesDictionary";
import { createNamespacedHelpers, mapActions } from "vuex";
const { noWhiteSpace } = helpers.utils;

const {
  mapGetters: mapEnergyGetters,
  mapActions: mapEnergyActions
} = createNamespacedHelpers("energy");

const { mapGetters: mapUiGetters } = createNamespacedHelpers("ui");
export default {
  name: "NewGoal",
  props: {
    goalId: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      showErrorHintText: false,
      limit: "",
      goal: {}
    };
  },
  mounted() {
    this.$root.$on("_newGoalDevice", this.selectedDevice);
    this.initGoal(this.goalId);
  },
  beforeDestroy() {
    this.selectedDevice && this.$root.$off("_newGoalDevice");
  },
  watch: {
    goalId(value) {
      this.initGoal(value);
    }
  },
  computed: {
    ...mapEnergyGetters(["getCurrentGoal", "getInitialGoal", "getGoalById"]),
    ...mapUiGetters(["isFetching"]),
    traslations() {
      // eslint-disable-next-line
      return this.$t("goals");
    },
    limitErrorHintType() {
      return this.limitErrorHintText ? "error" : "info";
    },
    limitErrorHintText() {
      let error = "";
      if (this.showErrorHintText && !this.isLimitEntered) {
        error = this.traslations.limitErrorText;
      }
      return error;
    },
    isDisabled() {
      return !this.isDeviceSelected;
    },
    isDeviceSelected() {
      return !this.$v.goal.device.id.$invalid;
    },
    isLimitEntered() {
      return !this.$v.goal.limit.$invalid;
    },
    getDeviceName() {
      const { device, header } = this.goal || "";
      return device && device.id
        ? device.name
        : header || this.traslations.selectADevice;
    },
    getDeviceIcon() {
      const { device } = this.goal || "";
      return device && device.icons && device.icons.defaultSrc
        ? device.icons.defaultSrc
        : "icons/Category/ic_category_device_56.svg";
    },
    getButtonText() {
      return !this.isDeviceSelected
        ? this.traslations.noDeviceSelected
        : this.traslations.save;
    }
  },
  methods: {
    ...mapEnergyActions([
      "setNewGoal",
      "setCurrentGoalById",
      "saveGoal",
      "clearSelection"
    ]),
    goBack() {
      this.setToInitial();
      this.navigateBack();
    },
    setToInitial() {
      this.$v.$reset();
      this.goal = { ...this.getInitialGoal };
      this.showErrorHintText = false;
      this.clearSelection();
    },
    async initGoal(goalId) {
      this.goal = goalId
        ? { ...this.getGoalById(goalId) }
        : { ...this.getInitialGoal };
    },
    selectMonth() {
      this.showDialog("moreOptionsAreComingSoon");
    },
    showDialog(dialog = "defaultError") {
      const { props } = getDialogOptions(dialog);
      this.$refs.mainPage.showInformation(props);
    },
    selectDevice() {
      this.navigateTo("NewGoalDevice", {
        props: {
          device: this.device,
          backstackVisible: false
        }
      });
    },
    selectedDevice(device) {
      console.log("### newGoal selectedDevice:", device); //eslint-disable-line
      const { id, title: name, icons } = device;
      this.goal.device = { ...this.goal.device, id, name, icons };
    },
    update(goal) {
      this.navigateTo("GoalSaveConfirmationDialog", {
        props: {
          goal,
          backstackVisible: false
        }
      });
    },
    async save() {
      this.showErrorHintText = true;
      let Ok = null;
      if (!this.$v.goal.$invalid) {
        if (this.goal.id) {
          // will be updated
          this.update(this.goal);
        } else {
          Ok = await this.saveGoal(this.goal);
        }
        if (Ok) {
          this.goBack();
        }
      }
    }
  },
  validations() {
    return {
      goal: {
        limit: {
          required,
          numeric,
          minValue: 0,
          noWhiteSpace
        },
        device: {
          id: {
            required
          }
        }
      }
    };
  }
};
</script>
<style lang="scss" scoped>
@import "~/assets/styles/variables.scss";

.new-goal {
  height: 100%;
  &__title {
    background-color: #eef1f3;
    border-bottom-style: solid;
    border-bottom-color: $color-grey-a15;
    border-bottom-width: $stroke;
  }
  &__container {
    margin: $xxm $xl;
  }
  &__group-text {
    margin: $xxm $xl $ml $xl;
  }
  &__btn-container {
    padding: 0 $xl $l $xl;
  }
  &__item {
    // margin: $xl 0;
    &--green {
      /deep/ & * {
        color: $color-vera;
      }
    }
  }
  &__separator {
    margin: 0 $xs;
  }
  &__limit {
    margin: 0 $xl;
  }
  &__input {
    grid-row: 1;
    grid-column: 1;
  }
  &__kwh {
    grid-row: 1;
    grid-column: 2;
  }
  &__kwh-label {
    color: $color-grey;
    grid-row: 1;
    grid-column: 1;
    align-self: center;
  }
}
</style>
