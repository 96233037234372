<template>
  <MainPage>
    <GridLayout
      height="100%"
      rows="*"
      columns="*"
      class="dash-prev"
      :background="backgroundColor"
    >
      <ImageCacheIt
        iosOverflowSafeArea="true"
        row="0"
        col="0"
        v-if="useBackgroundImage"
        :src="backgroundImage"
        stretch="aspectFill"
      />
      <ScrollView
        row="0"
        col="0"
        height="100%"
        class="dash-prev__scroll"
        :backgroundColor="backgroundColor"
      >
        <StackLayout class="dash-prev__renderer" v-if="dashboardCount > 0">
          <TemplateRenderer
            row="0"
            :template="getTemplate"
            :designProperties="designProperties"
            :preview="true"
            @tileTap="tiletap"
          />
        </StackLayout>
        <GridLayout
          class="dash-prev__layout"
          rows="*"
          columns="*"
          height="100%"
          v-else
        >
          <Label
            row="0"
            col="0"
            class="dash-prev__no-dashboard"
            :text="translations.empty"
          />
        </GridLayout>
      </ScrollView>
      <GridLayout
        row="0"
        col="0"
        rows="64"
        columns="auto,*,auto"
        class="dash-prev__avatar"
        verticalAlignment="top"
      >
        <Avatar
          row="0"
          col="0"
          :imageSize="32"
          :src="getUserAvatar"
          :loading="isFetchingAvatar"
          @tap="handleProfile"
        />
        <GridLayout
          columns="auto,*,auto, auto"
          alignment="vertical"
          row="0"
          col="1"
          :isPassThroughParentEnabled="true"
          v-if="dashboardCount > 0"
        >
          <ButtonIcon
            class="dash-prev__avatar__left"
            opacity="0.3"
            row="0"
            col="0"
            src="icons/Controls/ic_controls_arrow-left_56.svg"
            @tap="setPrevDashboard"
          />
          <Label
            row="0"
            col="1"
            class="dash-prev__avatar__name font-h2-24"
            :text="dashboardCount > 0 ? dashboardName : '-'"
          ></Label>
          <ButtonIcon
            class="dash-prev__avatar__right"
            opacity="0.3"
            row="0"
            col="2"
            src="icons/Controls/ic_controls_arrow-right_56.svg"
            @tap="setNextDashboard"
          />
          <ButtonIcon
            v-if="showChangeOrientationButton"
            class="dash-prev__avatar__right"
            opacity="0.3"
            row="0"
            col="3"
            src="icons/Controls/ic_controls_expand_56.svg"
            @tap="isPortrait = !isPortrait"
          />
        </GridLayout>

        <ButtonIcon
          class="dash-prev__avatar__list"
          col="2"
          src="icons/Controls/ic_controls_hamburger_56.svg"
          @tap="goToDashboardsList"
        />
      </GridLayout>
    </GridLayout>
  </MainPage>
</template>
<script>
import { createNamespacedHelpers } from "vuex";
import { imageArchiveUrl, hasTileItem } from "@/helpers/utils";
import { orientation as Orientation } from "@/helpers/orientation";
import * as nmaService from "@/services/nma.service";
import { colorHexA } from "@/helpers/utils";

const {
  mapActions: mapTemplateActions,
  mapGetters: mapTemplateGetters
} = createNamespacedHelpers("template");
const {
  mapGetters: mapAuthGetters,
  mapActions: mapAuthActions
} = createNamespacedHelpers("auth");
const {
  mapGetters: mapDashboardGetters,
  mapActions: mapDashboardActions
} = createNamespacedHelpers("dashboards");
const { mapActions: mapItemsActions } = createNamespacedHelpers("items");
const {
  mapActions: mapScenesActions,
  mapGetters: mapScenesGetters
} = createNamespacedHelpers("scenes");
const { mapGetters: mapDeviceGetters } = createNamespacedHelpers("devices");
const { mapGetters: mapThemesGetters } = createNamespacedHelpers("themes");
const { VUE_APP_MODE } = process.env;

export default {
  name: "DashboardPreview",
  data() {
    return {
      currentDashIndex: this.getDefaultDashboardIndex,
      isPortrait: true,
      orientationApplier: null
    };
  },
  computed: {
    ...mapAuthGetters(["isWebView"]),
    ...mapTemplateGetters(["template", "getTemplateLandscape"]),
    ...mapAuthGetters(["getUserAvatar", "isFetchingAvatar", "updateTemplate"]),
    ...mapDeviceGetters([
      "getDevices",
      "getDeviceById",
      "getDeviceStatusItemByDeviceId"
    ]),
    ...mapScenesGetters(["getSceneById", "getScenes"]),
    ...mapDashboardGetters([
      "getDashboards",
      "getDashboardByIndex",
      "getDefaultDashboardIndex"
    ]),
    ...mapThemesGetters(["getThemeById", "getThemeOrDefault"]),
    getOrientationIcon() {
      return "icons/Controls/ic_controls_expand_56.svg";
    },
    showChangeOrientationButton() {
      return (
        VUE_APP_MODE === "web" &&
        hasTileItem(this.getTemplateLandscape) &&
        hasTileItem(this.template)
      );
    },
    getTemplate() {
      return (this.isPortrait && hasTileItem(this.template)) ||
        !hasTileItem(this.getTemplateLandscape)
        ? this.template
        : this.getTemplateLandscape;
    },
    designProperties() {
      const orientation = this.isPortrait ? "portrait" : "landscape";
      let st = null;
      if (this.defaultDashboard && this.defaultDashboard.styleSettings) {
        st = this.defaultDashboard.styleSettings;
        let theme = null;
        if (st.theme) {
          theme = this.getThemeOrDefault(st.theme[orientation].theme_id);
          st =
            theme &&
            theme.data &&
            theme.data.dashboard &&
            theme.data.dashboard["default"];
        }
      }

      return (
        st ||
        (this.defaultDashboard &&
          this.defaultDashboard.styleSettings &&
          this.defaultDashboard.styleSettings.general)
      );
    },
    useBackgroundImage() {
      // Controlled "useBackgroundImage" to match old object structure.
      return (
        this.designProperties &&
        (this.designProperties.use_background_image ||
          this.designProperties.useBackgroundImage ||
          this.designProperties.use_background_image)
      );
    },
    backgroundImage() {
      const src =
        this.designProperties &&
        (this.designProperties.background_image ||
          this.designProperties.backgroundImage ||
          this.designProperties.background_color);

      return this.useBackgroundImage ? imageArchiveUrl(src) : "";
    },
    backgroundColor() {
      return !this.useBackgroundImage && this.designProperties
        ? VUE_APP_MODE === "web"
          ? colorHexA(
              this.designProperties.backgroundColor ||
                this.designProperties.background_color
            )
          : this.designProperties.backgroundColor ||
            this.designProperties.background_color
        : "transparent";
    },
    dashboardName() {
      return this.defaultDashboard && this.defaultDashboard.name;
    },
    defaultDashboard() {
      return this.getDashboardByIndex(this.currentDashIndex);
    },
    dashboardCount() {
      return this.getDashboards.length;
    },
    translations() {
      return this.$t("preview");
    }
  },
  methods: {
    ...mapDashboardActions(["setDashboardByIndex"]),
    ...mapAuthActions(["setUpdateTemplate"]),
    ...mapItemsActions(["updateItemValueAsync"]),
    ...mapScenesActions(["runSceneAsync"]),
    handleProfile() {
      this.navigateTo("Profile");
    },
    goToDashboardsList() {
      this.setUpdateTemplate(false);
      this.navigateTo("DashboardList");
    },
    setNextDashboard() {
      this.currentDashIndex++;
      if (this.currentDashIndex === this.dashboardCount) {
        this.currentDashIndex = 0;
      }

      this.setDashboardByIndex(this.currentDashIndex);
    },
    setPrevDashboard() {
      this.currentDashIndex--;
      if (this.currentDashIndex < 0) {
        this.currentDashIndex = this.dashboardCount - 1;
      }
      this.setDashboardByIndex(this.currentDashIndex);
    },
    resetIndex(index) {
      this.currentDashIndex = index;
    },
    getNewStatusValue(status) {
      return ["On", "Armed"].includes(status) ? "false" : "true";
    },
    async tiletap(index) {
      const tile = this.getTileByIndex(index);
      if (tile.props && tile.props.type === "device") {
        const device = this.getDeviceById(tile.props.id);
        const statusItem = this.getDeviceStatusItemByDeviceId(tile.props.id);
        if (statusItem) {
          const options = {
            id: statusItem._id,
            deviceId: device.id,
            value: this.getNewStatusValue(tile.props.status),
            hub: tile.props.controller_id
          };
          try {
            const response = await this.valueSet(options);
          } catch (error) {
            console.log("#### valueSet Error: ", error); //eslint-disable-line
          }
        }
      } else if (tile.props && tile.props.type === "scene") {
        const scene = this.getSceneById(tile.props.id);
        if (scene) {
          const options = {
            sceneId: scene.id,
            hub: scene.controller_id,
            type: scene.type
          };
          try {
            const response = await this.sceneRun(options);
          } catch (error) {
            console.log("#### scneRun Error: ", error); //eslint-disable-line
          }
        }
      } else if (this.isWebView && tile.props && tile.props.type === "link") {
        this.navigateTo(`link-${tile.props.subType}`);
      }
    },
    async valueSet({ id, deviceId, value, hub }) {
      return await this.updateItemValueAsync({ _id: id, deviceId, value, hub });
    },
    async sceneRun({ sceneId, hub, type }) {
      return await this.runSceneAsync({ sceneId, hub, type });
    },
    orientationCallback(args) {
      this.isPortrait = !args.landscape;
    },
    getTileByIndex(index) {
      const template = this.getTemplate;
      return (
        (template &&
          template.children[index] &&
          template.children[index].children[0] &&
          template.children[index].children[0].children &&
          template.children[index].children[0].children[0]) ||
        {}
      );
    },
    setIndex(index) {
      this.currentDashIndex = index;
      this.setDashboardByIndex(index);
    }
  },
  watch: {
    // This section should be used to show the status changes to be made on tile.
    getDevices() {
      if (this.updateTemplate) {
        this.setDashboardByIndex(this.currentDashIndex);
      }
    },
    getScenes() {
      if (this.updateTemplate) {
        this.setDashboardByIndex(this.currentDashIndex);
      }
    }
  },
  mounted() {
    this.setUpdateTemplate(true);
    const index = this.getDefaultDashboardIndex;
    this.currentDashIndex = index;
    this.setDashboardByIndex(index);
    this.$root.$on("_resetIndex", this.resetIndex);
    this.$root.$on("setIndex", this.setIndex);

    if (VUE_APP_MODE !== "web") {
      this.isPortrait = Orientation.getOrientation() === "portrait";
      this.orientationApplier = this.orientationCallback.bind(this);
      Orientation.addOrientationApplier(this.orientationApplier);
    }
  },
  destroyed() {
    this.resetIndex && this.$root.$off("_resetIndex");
    this.setIndex && this.$root.$off("setIndex");

    if (this.orientationApplier) {
      Orientation.removeOrientationApplier(this.orientationApplier);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~/assets/styles/variables.scss";

.dash-prev {
  height: 100%;
  &__scroll {
    height: 100%;
  }
  &__renderer {
    padding: $xxxlll $padding-l $padding-xxxll $padding-l;
  }
  &__layout {
    vertical-align: center;
    horizontal-align: center;
    text-align: center;
    height: 100%;
    &__empty {
      vertical-align: center;
      horizontal-align: center;
    }
  }
  &__no-dashboard {
    align-self: center;
  }
  &__avatar {
    padding-left: $lmx;
    padding-right: $xs;
    height: $xxxxll;
    z-index: 1;
    &__list {
      justify-self: right;
    }
    &__name {
      width: 100%;
      text-align: center;
      align-self: center;
      justify-self: center;
      horizontal-align: center;
      vertical-align: center;
    }
    &__right {
      align-self: center;
      justify-self: center;
      margin-left: $lmx;
      margin-left: $xl;
    }
    &__left {
      align-self: center;
      justify-self: center;
      margin-left: $xl;
      margin-right: $xl;
    }
    background-color: rgba(255, 255, 255, 0.5);
  }
}
</style>
