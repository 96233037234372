<template>
  <StackLayout class="new-link-address" stretchLastChild="false" height="100%">
    <StackLayout class="new-link-address__input" dock="top">
      <TextInput
        v-model="$v.link.$model"
        type="text"
        showSoftInput
        returnKeyType="done"
        :hintText="translations.textLinkHint"
        @returnPress="handleSubmit"
      />
    </StackLayout>
    <StackLayout dock="bottom" class="new-link-address__footer">
      <ButtonFilled
        :text="translations.textBtn"
        :disabled="isButtonDisabled"
        @tap="handleSubmit"
      />
    </StackLayout>
  </StackLayout>
</template>

<script>
import { required } from "vuelidate/lib/validators";

export default {
  name: "NewLinkAddress",
  props: {
    initialLink: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      link: this.initialLink
    };
  },
  computed: {
    translations() {
      return this.$t("newLinkAddress"); // eslint-disable-line
    },
    isButtonDisabled() {
      return this.$v.link.$invalid;
    }
  },
  watch: {
    initialLink(value) {
      this.link = value;
    }
  },
  methods: {
    handleSubmit() {
      this.$emit("submit", this.link);
    }
  },
  validations() {
    return {
      link: {
        required,
        isUrl: value =>
          /(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/g.test(
            value
          )
      }
    };
  }
};
</script>
<style scoped lang="scss">
@import "~/assets/styles/variables.scss";
.new-link-address {
  padding: 0 $xl;
  &__footer {
    padding-bottom: $xl;
  }
}
</style>
