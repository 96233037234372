<template>
  <MainPage class="profile__scroll">
    <ScrollView @loaded="scrollLoaded" ref="scroll" class="profile__scroll">
      <GridLayout class="profile__scroll" rows="*" columns="*">
        <StackLayout row="0" col="0" class="profile profile__scroll">
          <IBtnHiBtn
            icon1Src="icons/Controls/ic_controls_arrow-left_56.svg"
            :text="textsMain.title"
            @iconTap1="onTapBack"
          />
          <ProfileOverview
            class="profile__scroll"
            :texts="textsMain"
            :userName="getUser.username"
            :email="getUser.email"
            :avatar="getUserAvatar"
            :isFetchingAvatar="isFetchingAvatar"
            :versionText="getAppVersion"
            :hubs="getHubs"
            :hubStatuses="getConnectionStatuses"
            @onTapAvatar="onTapAvatar"
            @onTapEmail="showChangeUserEmailDialog"
            @onTapLogOut="showLogoutConfirm"
            @selectedOption="avatarSelectedOption"
          />
        </StackLayout>
        <BottomUpDialog
          ref="dialogChangeEmail"
          @beforeShown="onShowChangeEmailDialog"
          @beforeHidden="onHideChangeEmailDialog"
        >
          <ChangeNameEntity
            ref="changeEmail"
            key="changeUserEmail"
            :isEmail="true"
            :isFetching="isFetching('changeName')"
            :isShowError="getErrorStatus"
            :errorCode="getErrorCode"
            :name="getUser.email"
            :titleText="textsDialog && textsDialog.title"
            :actionText="textsDialog && textsDialog.btn"
            :hintText="textsDialog && textsDialog.hint"
            :placeholderText="textsDialog && textsDialog.title"
            @change="handleChangeUserEmail"
          />
        </BottomUpDialog>

        <BottomUpDialog ref="dialogLogoutConfirm">
          <LogoutConfirm
            :isLoading="isFetching('logout')"
            @accept="handleTapLogOut"
            @close="closeLogoutConfirm"
          />
        </BottomUpDialog>
      </GridLayout>
    </ScrollView>
  </MainPage>
</template>
<script>
import { createNamespacedHelpers } from "vuex";
import { helpers } from "ezlo-core-ui";
import ProfileOverview from "@/components/settings/ProfileOverview";
const { imageSourceModule } = helpers.core;
const { VUE_APP_MODE } = process.env;

const {
  mapGetters: mapUiGetters,
  mapActions: mapUiActions
} = createNamespacedHelpers("ui");
const {
  mapActions: mapAuthActions,
  mapGetters: mapAuthGetters
} = createNamespacedHelpers("auth");
const { mapGetters: mapHubGetters } = createNamespacedHelpers("hub");
const { forceHideKeyboard } = helpers.core;

export default {
  name: "Profile",
  components: {
    ProfileOverview
  },
  data() {
    return {
      isEmailDialogOpen: false
    };
  },
  mounted() {
    this.fetchUserAvatar();
  },
  computed: {
    ...mapAuthGetters(["getUser", "getAvatarUrl", "isFetchingAvatar"]),
    ...mapUiGetters(["isFetching", "error"]),
    ...mapHubGetters(["getConnectionStatuses", "getHubs"]),
    translations() {
      return this.$t("profile");
    },
    getErrorStatus() {
      return this.error ? this.error.error : false;
    },
    getErrorCode() {
      return this.error && this.error.code;
    },
    textsMain() {
      const tLocProfile = this.$t("profile");

      return {
        title: tLocProfile.title,
        name: tLocProfile.name,
        email: tLocProfile.email,
        logOut: tLocProfile.logOut,
        hubs: tLocProfile.hubs
      };
    },
    textsDialog() {
      const tLocProfile = this.$t("profile");

      return {
        title: tLocProfile.titleEditEmail,
        btn: tLocProfile.btnEditEmail,
        hint: tLocProfile.hintEditEmail
      };
    },
    textsSnackbar() {
      const tLocProfile = this.$t("profile");
      return {
        emailSnackbarText: tLocProfile.snackbarTextEditEmail
      };
    },
    getUserAvatar() {
      const initialAvatar = "~/assets/images/avatar_56.png";
      return this.getAvatarUrl ? this.getAvatarUrl : initialAvatar;
    },
    getAppVersion() {
      return process.env.VUE_APP_VERSION || "0.0.2.16";
    }
  },
  methods: {
    ...mapAuthActions([
      "setUserAvatar",
      "logoutAsync",
      "updateUserInfoAsync",
      "fetchUserAvatar"
    ]),
    ...mapUiActions(["saveSnackbarText", "saveSnackbarErrorText"]),
    scrollLoaded() {
      if (
        this.$refs.scroll &&
        this.$refs.scroll.nativeView &&
        this.$refs.scroll.nativeView.ios
      ) {
        // for jumping fix of the dialogs with input for iOS
        // this code disables user interaction with the scollview
        // but dilaogs can scroll correctly
        this.$refs.scroll.nativeView.ios.panGestureRecognizer.minimumNumberOfTouches = 0;
        this.$refs.scroll.nativeView.ios.panGestureRecognizer.maximumNumberOfTouches = 0;
      }
    },
    async onTapAvatar(avatar) {
      if (avatar) {
        const result = await avatar.openActionDialog();
        // result: camera / image / cancel
        if (result === "cancel") {
          return;
        } else {
          avatar.initializeCamera(result, this.setAvatar);
        }
      }
    },
    async setAvatar({ file }) {
      let result = null;
      if (VUE_APP_MODE === "web") {
        result = await this.setUserAvatar(file);
      } else {
        const img = imageSourceModule.ImageSource.fromFileSync(file.filename);
        file.data = img;
        result = await this.setUserAvatar(file);
      }

      if (result) {
        this.saveSnackbarText(this.$t("profile.profilePhotoChanged")); //eslint-disable-line
      } else {
        this.saveSnackbarErrorText(this.$t("profile.errorWhileUploadingImage")); //eslint-disable-line
      }
    },
    onTapBack() {
      this.navigateBack();
    },
    avatarSelectedOption(event) {
      console.log("### avatarSelectedOption:", event); //eslint-disable-line
      this.navigateTo("ProfileCurrentPassword");
    },
    async handleTapLogOut() {
      await this.logoutAsync();

      if (this.getErrorStatus) {
        return;
      }

      await this.navigateTo("SignUpIntro", {
        clearHistory: true
      });
      this.closeLogoutConfirm();
    },
    async handleChangeUserEmail(email) {
      await this.updateUserInfoAsync({ email });

      if (this.getErrorStatus) {
        return;
      }

      this.hideChangeUserEmailDialog();
      this.saveSnackbarText(this.textsSnackbar.emailSnackbarText);
    },
    showChangeUserEmailDialog() {
      const dialog = this.$refs.dialogChangeEmail;
      if (dialog) {
        this.isEmailDialogOpen = true;
        dialog.show();
      }
    },
    hideChangeUserEmailDialog() {
      const dialog = this.$refs.dialogChangeEmail;
      if (dialog) {
        this.isEmailDialogOpen = false;
        dialog.hide();
      }
    },
    onShowChangeEmailDialog() {
      const dialog = this.$refs.changeEmail;
      if (dialog) {
        dialog.onShown();
      }
    },
    onHideChangeEmailDialog() {
      const dialog = this.$refs.changeEmail;
      if (dialog) {
        dialog.onHidden();
      }
    },
    showLogoutConfirm() {
      const dialog = this.$refs.dialogLogoutConfirm;
      if (dialog) {
        setTimeout(() => {
          if (dialog) {
            dialog.show();
          }
        }, 125);
      }
    },
    closeLogoutConfirm() {
      const dialog = this.$refs.dialogLogoutConfirm;
      if (dialog) {
        dialog.hide();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~/assets/styles/variables.scss";
.profile {
  &__scroll {
    height: 100%;
    overflow: auto;
  }
  &__navs {
    color: $color-primary;
  }
}
</style>
