<template>
  <StackLayout class="pwd-send">
    <VxpLabel
      class="pwd-send__title font-h1-32"
      :text="title"
      :textWrap="true"
    />

    <VxpLabel
      class="pwd-send__desc font-txt-16-par"
      :text="description"
      :textWrap="true"
    />

    <TextInput
      class="pwd-send__input"
      type="email"
      returnKeyType="done"
      :placeholder="emailPlaceholder"
      :maxLength="maxLength"
      :showSoftInput="true"
      :T9Disabled="true"
      :eraseButton="true"
      :preventSpace="true"
      v-model="$v.email.$model"
    />

    <ButtonFilled
      class="pwd-send__btn"
      :text="btnText"
      :disabled="isNextButtonDisabled"
      @tap="onSubmit"
    />
  </StackLayout>
</template>

<script>
import { email, required } from "vuelidate/lib/validators";
import { helpers } from "ezlo-core-ui";
const { noWhiteSpace } = helpers.utils;

export default {
  name: "PasswordSendForm",
  props: {
    title: {
      type: String,
      default: "Title"
    },
    description: {
      type: String,
      default: "Description"
    },
    emailPlaceholder: {
      type: String,
      default: "Email"
    },
    btnText: {
      type: String,
      default: "Button"
    },
    maxLength: {
      type: Number,
      default: 100
    }
  },
  data() {
    return {
      email: ""
    };
  },
  computed: {
    isNextButtonDisabled() {
      return this.$v.email.$invalid;
    }
  },
  methods: {
    onSubmit() {
      const isFormValid = !this.$v.$invalid;
      const userEmail = this.email.trim();

      isFormValid && this.$emit("submit", userEmail);
      this.email = "";
    }
  },
  validations() {
    return {
      email: {
        required,
        email,
        noWhiteSpace
      }
    };
  }
};
</script>

<style scoped lang="scss">
@import "~/assets/styles/variables.scss";

.pwd-send {
  padding-left: $padding-xl;
  padding-right: $padding-xl;
  color: $color-black;

  &__title {
    margin-bottom: $margin-s;
  }

  &__desc,
  &__input,
  &__btn {
    margin-bottom: $margin-xl;
  }
}
</style>
