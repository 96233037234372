<template>
  <MainPage ref="mainPage" @confirmationResult="confirmationResult">
    <StackLayout>
      <StackLayout left="0" top="0" width="100%">
        <IBtnHiBtn
          icon1Src="icons/Controls/ic_controls_arrow-left_56.svg"
          :text="translations.headerTitle"
          @iconTap1="goBack"
        />
      </StackLayout>
      <Separator size="xl" />
      <VxpLabel
        class="font-txt-16-par template-mode__header"
        :text="translations.desc"
        :textWrap="true"
      />
      <ThemeList
        ref="themeList"
        :items="getTemplateModes"
        :editMode="false"
        :listMode="editMode ? 'radio' : 'arrow'"
        :selectedItem="selectedItem"
        @onSelect="handleSelect"
      />
    </StackLayout>
  </MainPage>
</template>
<script>
import { sleep, isEmpty } from "@/helpers/utils";
import ThemeList from "@/pages/theme/ThemeList";
import { createNamespacedHelpers } from "vuex";
const {
  mapActions: mapTemplateActions,
  mapGetters: mapTemplateGetters
} = createNamespacedHelpers("template");

const { mapActions: mapUiActions } = createNamespacedHelpers("ui");

export default {
  name: "TemplateMode",
  components: {
    ThemeList
  },
  props: {
    editMode: {
      type: Boolean,
      default: false
    },
    currentTemplateMode: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      selectedItem: { id: this.currentTemplateMode }
    };
  },
  mounted() {
    console.log("### TemplateMode/mounted:", { editMode: this.editMode, currentTemplateMode: this.currentTemplateMode }); //eslint-disable-line
  },
  watch: {
    currentTemplateMode(newValue) {
      this.resetSelectedItem();
    }
  },
  computed: {
    ...mapTemplateGetters(["template", "getTemplateLandscape"]),
    translations() {
      const key = this.editMode ? "edit" : "new";
      return this.$t("templateModes")[key]; // eslint-disable-line
    },
    items() {
      return [
        {
          id: "portrait",
          name: this.translations.portrait,
          desc: this.translations.portraitDesc,
          icon: "icons/Controls/ic_controls_arrow-right_56.svg",
          mode: "portrait",
          disable: false,
          selected: false
        },
        {
          id: "landscape",
          name: this.translations.landscape,
          desc: this.translations.landscapeDesc,
          icon: "icons/Controls/ic_controls_arrow-right_56.svg",
          mode: "landscape",
          disable: false,
          selected: false
        },
        {
          id: "both",
          name: this.translations.both,
          desc: this.translations.bothDesc,
          icon: "icons/Controls/ic_controls_arrow-right_56.svg",
          mode: "both",
          disable: false,
          selected: false
        }
      ];
    },
    getTemplateModes() {
      return this.items.map(item => {
        return {
          ...item,
          selected: item.id === this.currentTemplateMode
        };
      });
    }
  },
  methods: {
    ...mapTemplateActions(["resetTemplateLandscape", "resetTemplatePortrait"]),
    ...mapUiActions(["emitError"]),
    goBack() {
      this.$destroy();
      this.navigateBack();
    },
    resetSelectedItem() {
      this.selectedItem = { id: this.currentTemplateMode };
    },
    async handleSelect(item) {
      this.selectedItem = item;
      if (this.editMode) {
        //todo
        if (item.id === this.currentTemplateMode) {
          return null;
        }
        await sleep(50);
        const type =
          this.currentTemplateMode === "both"
            ? "bothModesToOneMode"
            : item.id !== "both"
            ? "portraitModeToLandscape"
            : "";

        if (type) {
          this.showConfirmationDialog({ type });
        } else {
          this.changeTemplateMode();
        }
      } else {
        this.newDashboardTemplate(item);
      }
    },
    newDashboardTemplate(item) {
      this.resetTemplatePortrait();
      this.resetTemplateLandscape();
      this.goDashboardTemplate(item);
    },
    goDashboardTemplate(item) {
      let landscape = item.id === "landscape";
      if (item.id !== "both" || this.editMode) {
        if (this.editMode && item.id === "both") {
          landscape = isEmpty(this.getTemplateLandscape);
        }
        console.log("--->> ### from TemplateMode/handleSelect to  DashboardTemplateType", item); //eslint-disable-line
        this.navigateTo("DashboardTemplateType", {
          props: {
            isEditMode: this.editMode,
            isLandscape: landscape,
            isSingleTemplate: true,
            templateMode: item
          },
          backstackVisible: false
        });
      } else {
        console.log("--->> ### from TemplateMode/handleSelect to  DashboardTemplate", item); //eslint-disable-line
        this.navigateTo("DashboardTemplate", {
          props: {
            isLandscape: landscape,
            isSingleTemplate: false,
            templateMode: item
          },
          backstackVisible: false
        });
      }
    },
    showConfirmationDialog({ type = "bothModesToOneMode" }) {
      this.emitError({
        type
      });
    },
    async confirmationResult(event) {
      console.log("### --> TemplateMode confirmationResult:", { event, from: this.currentTemplateMode, to: this.$refs.themeList.selectedItem }); //eslint-disable-line
      if (event.result) {
        this.changeTemplateMode();
      } else {
        this.resetSelectedItem();
      }
    },
    changeTemplateMode() {
      const modeItem = this.$refs.themeList.selectedItem;
      console.log("### --> TemplateMode confirmationResult:", { from: this.currentTemplateMode, to: modeItem }); //eslint-disable-line
      this.goBack();
      this.$root.$emit("_changeTemplateMode", modeItem);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~/assets/styles/variables.scss";

.template-mode {
  &__header {
    margin: $margin-l $margin-xl;
  }
}
</style>
