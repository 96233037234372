<template>
  <MainPage>
    <GridLayout
      rows="auto, auto, auto, * ,auto"
      columns="*"
      class="tile-states"
    >
      <NavigationHeader
        row="0"
        col="0"
        icon1Src="icons/Controls/ic_controls_arrow-left_56.svg"
        textSize="h3"
        :text="translations.textTitle"
        @iconTap1="goBack"
      />
      <VxpLabel
        class="tile-states__info font-txt-16-par"
        row="1"
        textWrap
        :text="translations.textInfo"
      />
      <Separator class="tile-states__seperator" row="2" col="0" size="xl" />
      <ScrollView row="3">
        <WrapLayout class="tile-states__tiles">
          <!-- TODO: remove tileIcon when every state has their own icon -->
          <TileStateTile
            v-for="state in categories"
            :key="state.name"
            :capabilityName="state.name"
            :tileStyle="getTileStyle(state.name)"
            @selected="tileSelected"
            :tileLabel="tile.props.title"
          />
        </WrapLayout>
      </ScrollView>
      <StackLayout row="4" col="0" class="tile-states__button">
        <ButtonFilled
          :text="$t('common.done')"
          @tap="saveButtonClicked"
          :disabled="!!!hasDesignPropertiesChanges"
        />
      </StackLayout>
    </GridLayout>
  </MainPage>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

const {
  mapActions: mapTemplateActions,
  mapGetters: mapTemplateGetters
} = createNamespacedHelpers("template");
const { mapGetters: mapDashboardGetters } = createNamespacedHelpers(
  "dashboards"
);

export default {
  name: "TileStates",
  props: {
    dashboardId: {
      type: String,
      default: "1"
    },
    index: {
      type: [String, Number],
      default: "0"
    },
    isLandscape: {
      type: Boolean,
      default: false
    },
    categories: {
      type: Array,
      default: () => []
    },
    tile: {
      type: Object,
      default: () => ({})
    },
    category: {
      type: String,
      default: ""
    },
    subcategory: {
      type: String,
      default: ""
    },
    tileType: {
      type: String,
      default: ""
    }
  },
  mounted() {
    this.$root.$on("_tileSettingsTileChanged", this.tileChanged);
  },
  beforeDestroy() {
    this.tileChanged && this.$root.$off("_tileSettingsTileChanged");
  },
  computed: {
    ...mapTemplateGetters([
      "hasDesignPropertiesChanges",
      "getDefaultDesignProperties"
    ]),
    ...mapDashboardGetters(["getDashboardTheme"]),
    translations() {
      return this.$t("tileStates");
    }
  },
  methods: {
    ...mapTemplateActions([
      "saveDesignProperties",
      "setDesignProperties",
      "resetDesignProperties"
    ]),
    goBack() {
      this.resetDesignProperties();
      this.$destroy();
      this.navigateBack();
    },
    tileSelected(state) {
      this.navigateTo("TileSettings", {
        props: {
          dashboardId: this.dashboardId,
          tile: this.getTileStyle(state),
          tileState: state,
          tileLabel: this.tile.props?.title ? this.tile.props.title : state,
          tileIcon: this.tile.props?.src ? this.tile.props.src : "", // TODO: remove when every state has their own icon,
          categories: this.categories,
          category: this.category,
          subcategory: this.subcategory,
          tileType: this.tileType
        }
      });
    },
    saveButtonClicked() {
      const options = {
        orientation: this.isLandscape ? "landscape" : "portrait",
        index: this.index,
        dashboardId: this.dashboardId
      };
      this.saveDesignProperties(options);
      this.goBack();
    },
    getTileStyle(stateName) {
      if (
        this.hasDesignPropertiesChanges &&
        this.hasDesignPropertiesChanges[stateName]
      ) {
        // if the design is changed but not saved
        return this.hasDesignPropertiesChanges[stateName];
      } else if (this.tile.props.design_properties[stateName]) {
        // if the design is not changed
        return this.tile.props.design_properties[stateName];
      } else {
        // if the state has no design properties, TODO: get related default design
        const design =
          this.getDashboardTheme(this.dashboardId) ||
          this.getDefaultDesignProperties.general;
        return design;
      }
    },
    tileChanged(tileOption) {
      const options = {
        ...tileOption,
        orientation: this.isLandscape ? "landscape" : "portrait",
        index: this.index
      };
      this.setDesignProperties({
        ...options
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~/assets/styles/variables.scss";

.tile-states {
  color: $color-black;

  &__info {
    padding: $s $l $lmx $l;
  }
  &__seperator {
    padding: 0 $xs $xs $xs;
  }
  &__tiles {
    padding: $xs;
    height: 100%;
  }
  &__button {
    padding: $m $xl $xl $xl;
  }
}
</style>
