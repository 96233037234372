import {
  SET_HUB_LIST,
  RESET_HUB_STATE,
  SET_HUB_UUIDS,
  SET_RELAY_TOKENS,
  SET_CONNECTION_STATUS,
  RESET_CONNECTION_STATUS
} from "./mutationTypes";
import hubService from "@/services/hub.service";
import {
  controllerSelect,
  socketConnect,
  socketDisconnect,
  resetWS
} from "@/services/nma.service";
import { HUBS } from "@/helpers/errorCodes";
import { HttpError } from "@/helpers/customErrors";
import createHub from "@/helpers/createHub";
import cloudService from "@/services/cloud.service";
import { parseHttpResponse } from "@/helpers/responseParser";

const retryPollCount = {};
const retryRelayCount = {};
let fetchHubsAsync = false;
let fetchHubsAsyncFailed = false;

let response = null;
const actions = {
  // Async
  async setConnectionStatus({ commit }, payload) {
    commit(SET_CONNECTION_STATUS, payload);
  },
  async resetState({ commit, dispatch, getters, rootGetters }) {
    dispatch("unsubscribeBroadcast");
    commit(RESET_HUB_STATE);
  },
  async unsubscribeBroadcast({ commit, dispatch, getters, rootGetters }) {
    // Unsubscribe from all broadcast
    const hubs = getters.getHubUUIDs;
    if (hubs) {
      for (let i = 0; i < hubs.length; i++) {
        dispatch("broadcast/unsubscribe", hubs[i].id, { root: true });
      }
    }
    resetWS(); // resets connection object inside nma service
    commit(RESET_CONNECTION_STATUS);
  },
  async fetchHubInfoAsync({ commit, dispatch, rootGetters }, { hubSerial }) {
    try {
      dispatch("ui/startFetching", "fetchHubInfoAsync", { root: true });
      const authState = rootGetters["auth/getAuthState"];
      const options = {
        token: {
          Identity: authState.Identity,
          IdentitySignature: authState.IdentitySignature
        },
        baseUrl: !fetchHubsAsync
          ? authState.Server_Account
          : authState.Server_Account_Alt,
        hubSerial
      };
      const response = await hubService.getHub(options);
      const responseServers = await hubService.getHubServers(options);

      const { data, statusCode } = await parseHttpResponse(response);
      const {
        data: serverData,
        statusCode: serverStatusCode
      } = await parseHttpResponse(responseServers);

      if (statusCode !== 200 || serverStatusCode !== 200) {
        throw new HttpError(data, statusCode);
      }

      const hubInfo = createHub(data, serverData);
      return hubInfo;
    } catch (error) {
      console.log("fetchHubInfoAsync FAILED");//eslint-disable-line
      fetchHubsAsyncFailed = true;
    } finally {
      dispatch("ui/stopFetching", "fetchHubInfoAsync", { root: true });
    }
  },
  async fetchHubInfoFromCloud({ commit, dispatch, rootGetters }) {
    try {
      dispatch("ui/startFetching", "fetchHubInfoFromCloud", { root: true });
      const authJwtToken = rootGetters["auth/getCloudJwtToken"];

      const response = await cloudService.accessKeysAsync({
        authJwtToken
      });
      const { data, statusCode } = await parseHttpResponse(response, "json");
      if (statusCode !== 200) {
        throw new HttpError("Api cloud error or other", statusCode);
      }
      const { keys } = data.data;
      const array = [];
      const pairs = {};
      const hubsArray = [];
      Object.entries(keys).forEach(([key, value]) => {
        if (value.meta.entity.type === "controller" && value.meta.entity.id) {
          array.push(value.meta.entity.uuid);
          pairs[value.meta.entity.uuid] = value.meta.entity.id;
          hubsArray.push({
            id: value.meta.entity.id,
            uuid: value.meta.entity.uuid
          });
        }
      });

      const hubs = [...new Set(hubsArray)];
      dispatch("setHubUUIDs", hubs);
      // FETCH DEVICES FORM CLOUD - closed for now
      // const uuids = [...new Set(array)];
      // dispatch(
      //   "devices/fetchDevicesFromCloud",
      //   { uuids, pairs },
      //   { root: true }
      // );
    } catch (err) {
      console.log(err);//eslint-disable-line
    } finally {
      dispatch("ui/stopFetching", "fetchHubInfoFromCloud", { root: true });
    }
  },
  async fetchHubsAsync({ commit, dispatch, rootGetters }) {
    try {
      dispatch("ui/startFetching", "fetchHubsAsync", { root: true });

      const hubs = rootGetters["location/getHubsFromLocations"];
      if (!hubs.length) {
        const REFUSED_ERROR = 604;
        throw new HttpError(HUBS.NO_AVAILABLE_HUBS, REFUSED_ERROR);
      }
      const getFullHubs = await Promise.all(
        hubs.map(async ({ hubSerial, ...rest }) => {
          const details = await dispatch("fetchHubInfoAsync", { hubSerial });
          return {
            ...rest,
            ...details
          };
        })
      ).catch(function(err) {
        console.log("fetchHubsAsync ERROR", err.message);//eslint-disable-line
      });
      if (!fetchHubsAsyncFailed) {
        commit(SET_HUB_LIST, getFullHubs);
        // if(process.env.VUE_APP_MODE !== "web"){
        dispatch("fetchRelayTokens", getFullHubs);
        dispatch("scenes/fetchCloudScenes", null, { root: true });
        // }
        await Promise.all(
          hubs.map(async ({ hubSerial }) => {
            return dispatch("fetchHubDataAsync", { hubSerial });
          })
        );
      } else {
        if (!fetchHubsAsync) {
          fetchHubsAsync = true;
          fetchHubsAsyncFailed = false;
          console.log("fetchHubsAsync FAILED, TRYING WITH ALT SERVER.....")//eslint-disable-line
          dispatch("fetchHubsAsync", null);
        } else {
          fetchHubsAsync = false;
          console.log("fetchHubsAsync FAILED error:",error)//eslint-disable-line
          dispatch(
            "ui/emitError",
            {
              error: "error",
              meta: "fetchHubsAsync",
              code: 404
            },
            {
              root: true
            }
          );
        }
      }
    } catch (error) {
        console.log("fetchHubsAsync FAILED error:",error)//eslint-disable-line
      dispatch(
        "ui/emitError",
        {
          error: error,
          meta: "fetchHubsAsync",
          code: error.code
        },
        {
          root: true
        }
      );
    } finally {
      dispatch("ui/stopFetching", "fetchHubsAsync", { root: true });
    }
  },
  async fetchHubDataAsync(context, payload) {
    const { dispatch, getters, rootGetters } = context;
    const { hubSerial } = payload;
    const hub = getters.getHub(hubSerial);
    if (!hub) return;
    const { isOnline, isEzlo, websocketUrl: url } = hub;
    if (!isOnline || !isEzlo) {
      dispatch("setConnectionStatus", {
        id: hubSerial,
        status: "DISCONNECTED"
      });
      return;
    }
    console.log("fetchHubDataAsync:", hubSerial); // eslint-disable-line
    try {
      dispatch("ui/startFetching", "fetchHubDataAsync", { root: true });

      const { username, password } = rootGetters["auth/getUser"];
      const authState = rootGetters["auth/getAuthState"];
      // TODO need to reset current connection !!!!!!
      dispatch("setConnectionStatus", { id: hubSerial, status: "CONNECTING" });
      console.log("starting ws connection", hubSerial);// eslint-disable-line
      await socketConnect({
        url,
        MMSAuth: authState.Identity,
        MMSAuthSig: authState.IdentitySignature,
        PK_Device: hubSerial,
        onReconnect: async () => {
          console.log("ws reconnection", hubSerial);// eslint-disable-line
          await socketConnect({
            url,
            MMSAuth: authState.Identity,
            MMSAuthSig: authState.IdentitySignature,
            PK_Device: hubSerial
          });
          await context.dispatch("broadcast/subscribe", hubSerial, {
            root: true
          });
          await controllerSelect(hubSerial);
        }
      });
      dispatch("setConnectionStatus", { id: hubSerial, status: "CONNECTED" });
      await context.dispatch("broadcast/subscribe", hubSerial, { root: true });
      await controllerSelect(hubSerial);
      await dispatch("fetchInitialDataAsync", hubSerial);
    } catch (error) {
      dispatch("setConnectionStatus", {
        id: hubSerial,
        status: "DISCONNECTED"
      });
      console.log("Error: fetchHubDataAsync:", error); // eslint-disable-line
    } finally {
      dispatch("ui/stopFetching", "fetchHubDataAsync", { root: true });
    }
  },
  async fetchInitialDataAsync({ dispatch }, hubSerial) {
    const fetchDevices = dispatch("devices/fetchDevices", hubSerial, {
      root: true
    });
    const fetchRooms = dispatch("rooms/fetchRooms", hubSerial, {
      root: true
    });
    const fetchItems = dispatch("items/fetchItems", hubSerial, {
      root: true
    });
    const fetchScenes = dispatch("scenes/fetchScenes", hubSerial, {
      root: true
    });

    try {
      return Promise.all([fetchDevices, fetchRooms, fetchItems, fetchScenes]);
    } catch (e) {
      console.log("Error: fetchInitialData: ", e); // eslint-disable-line
    }
  },
  setHubUUIDs({ commit }, payload) {
    commit(SET_HUB_UUIDS, payload);
  },
  async fetchRelayInfo(
    { dispatch, rootGetters },
    { data, tryAltServer = false }
  ) {
    //reusable function to get relay token,data and start polling
    try {
      dispatch("setConnectionStatus", {
        id: data.PK_Device,
        status: "CONNECTING"
      });
      const relayToken = await dispatch("fetchRelayToken", {
        data,
        tryAltServer
      });
      if (relayToken) {
        const relayData = await dispatch("fetchRelayData", {
          relayToken,
          data,
          tryAltServer
        });
        dispatch(
          "devices/addVeraDevices",
          {
            devices: relayData.devices,
            weatherSettings: relayData.weatherSettings,
            hub: data.PK_Device
          },
          { root: true }
        );
        dispatch(
          "scenes/addVeraScenes",
          { scenes: relayData.scenes, hub: data.PK_Device },
          { root: true }
        );
        dispatch(
          "rooms/addVeraRooms",
          { rooms: relayData.rooms, hub: data.PK_Device },
          { root: true }
        );
      }
    } catch (err) {
      if (rootGetters["auth/isAuthenticated"]) {
        if (!retryRelayCount[data.PK_Device]) {
          retryRelayCount[data.PK_Device] = 0;
        }
        //we try to get relay token 3 times
        if (retryRelayCount[data.PK_Device] < 4) {
          retryRelayCount[data.PK_Device]++;
          console.log("Trying to get relay token again... Retry:", retryRelayCount[data.PK_Device]);//eslint-disable-line
          dispatch("fetchRelayInfo", { data, tryAltServer: true });
        }
        //if we fail 3 times to get the token, we say hub is offline
        else {
          retryRelayCount[data.PK_Device] = 0;
          console.log("Error getting the relay token",err);//eslint-disable-line
          dispatch("setConnectionStatus", {
            id: data.PK_Device,
            status: "DISCONNECTED"
          });
        }
      }
    }
  },
  async fetchRelayTokens({ dispatch }, payload) {
    await Promise.all(
      payload.map(async ({ data, servers }) => {
        if (data.Server_Relay && Number(data.PK_Device) < 70000000) {
          dispatch("fetchRelayInfo", { data: { ...data, servers } });
        }
      })
    );
  },
  async fetchRelayToken(
    { commit, dispatch, rootGetters },
    { data: payload, tryAltServer }
  ) {
    try {
      const authState = rootGetters["auth/getAuthState"];
      const options = {
        token: {
          Identity: authState.Identity,
          IdentitySignature: authState.IdentitySignature
        },
        baseUrl: tryAltServer
          ? payload.servers.Server_Relay_Alt
          : payload.Server_Relay
      };
      const response = await hubService.getRelayToken(options);
      const { data, statusCode } = await parseHttpResponse(response, "string");
      const relayToken = data;

      if (statusCode !== 200) {
        throw new HttpError(relayToken, statusCode);
      }
      if (relayToken) {
        commit(SET_RELAY_TOKENS, { relayToken, data: payload });
      }
      return relayToken;
    } catch (err) {
      console.log("Error getting the relay token",payload.PK_Device,err);//eslint-disable-line
      //if we fail to get relay token we try the alternative server
      if (!tryAltServer && rootGetters["auth/isAuthenticated"]) {
        dispatch("fetchRelayInfo", { data: payload, tryAltServer: true });
      }
    }
  },
  async startPolling(
    { commit, dispatch, getters, rootGetters },
    { relayToken, data }
  ) {
    try {
      const options = {
        PK_Device: data.PK_Device,
        serverRelay: data.serverRelay,
        relayToken,
        LoadTime: data.LoadTime ? data.LoadTime : "",
        DataVersion: data.DataVersion ? data.DataVersion : 1
      };
      console.log("Long polling started for hub:",data.PK_Device) //eslint-disable-line
      dispatch("setConnectionStatus", {
        id: data.PK_Device,
        status: "CONNECTED"
      });
      response = await cloudService.startPolling(options);
      const { data: respData, statusCode } = await parseHttpResponse(
        response,
        "json"
      );
      console.log("### longPolling { response / respData }: "); // eslint-disable-line
      console.dir(response); // eslint-disable-line
      console.dir(respData); // eslint-disable-line
      if (respData.scenes) {
        const sceneId = respData.scenes[0].id;
        const isLoading = rootGetters["scenes/getLoadingBySceneId"](sceneId);
        dispatch("scenes/stopLoading", sceneId, { root: true });
        if (isLoading) {
          if (response.ok) {
            dispatch("ui/saveSnackbarText", "scenes.finished", {
              root: true
            });
          } else {
            dispatch("ui/saveSnackbarErrorText", "scenes.failed", {
              root: true
            });
          }
        }
      }
      if (statusCode !== 200) {
        dispatch("setConnectionStatus", {
          id: data.PK_Device,
          status: "DISCONNECTED"
        });
        throw new HttpError(response, statusCode);
      }
      retryPollCount[data.PK_Device] = 0;
      if (respData.devices) {
        dispatch(
          "devices/updateVeraDevices",
          { devices: respData.devices, hub: data.PK_Device },
          { root: true }
        );
      }

      //TODO handle scene and room updates
      // if(respData.scenes){
      //   dispatch(
      //     "scenes/updateVeraScenes",
      //     { scenes: respData.scenes, hub: data.PK_Device },
      //     { root: true }
      //   );
      // }

      // if(respData.rooms){
      //   dispatch(
      //     "rooms/updateVeraRooms",
      //     { rooms: respData.rooms, hub: data.PK_Device },
      //     { root: true }
      //   );
      // }

      const pollOptions = {
        PK_Device: data.PK_Device,
        serverRelay: data.serverRelay,
        relayToken,
        LoadTime: respData.LoadTime ? respData.LoadTime : "",
        DataVersion: respData.DataVersion ? respData.DataVersion : ""
      };
      if (rootGetters["auth/isAuthenticated"]) {
        dispatch("startPolling", { relayToken, data: pollOptions });
      }
      return;
    } catch (error) {
      if (rootGetters["auth/isAuthenticated"]) {
        // If long polling returns 504, we try again the same request
        if (
          response &&
          response.status &&
          response.status === 504 &&
          retryPollCount[data.PK_Device] < 4
        ) {
          retryPollCount[data.PK_Device]++;
        console.log("Long polling stopped because of error 504, trying again...") //eslint-disable-line
          dispatch("startPolling", { relayToken, data });
        }
        // If we fail 3 times, we try to get relay token again
        else {
        console.log("Long polling stopped because of error:",error) //eslint-disable-line
        console.log("Trying to get relay token again ..."); //eslint-disable-line
          const hub = getters.getHub(data.PK_Device);
          dispatch("fetchRelayTokens", [hub]);
          dispatch("setConnectionStatus", {
            id: data.PK_Device,
            status: "DISCONNECTED"
          });
        }
      }
    }
  },
  async fetchRelayData(
    { commit, dispatch, rootGetters },
    { relayToken, data, tryAltServer }
  ) {
    const options = {
      PK_Device: data.PK_Device,
      serverRelay: tryAltServer
        ? data.servers.Server_Relay_Alt
        : data.Server_Relay,
      relayToken
    };
    const response = await cloudService.getHubDevices(options);
    const { data: respData, statusCode } = await parseHttpResponse(
      response,
      "json"
    );
    const pollOptions = {
      PK_Device: data.PK_Device,
      serverRelay: data.Server_Relay,
      relayToken,
      LoadTime: respData.LoadTime ? respData.LoadTime : ""
    };

    dispatch("startPolling", { relayToken, data: pollOptions });

    if (statusCode !== 200) {
      throw new HttpError(response, statusCode);
    }
    return respData;
  }
};

export default actions;
