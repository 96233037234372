<template>
  <IconTwoActions
    iconPath="icons/Visuals/ic_visuals_knock-to-unlock-active_200.svg"
    type="info"
    :text1="translations.text1"
    :text2="translations.text2"
    :btnTextAccept="translations.btnTextAccept"
    :btnTextCancel="translations.btnTextCancel"
    @accept="handleAccept"
    @cancel="handleCancel"
  />
</template>

<script>
export default {
  name: "SingleTemplateConfirm",
  computed: {
    translations() {
      // eslint-disable-next-line
      const t = this.$t("core.singleTemplateConfirm");
      return {
        text1: t.text1,
        text2: t.text2,
        btnTextAccept: t.btnTextAccept,
        // eslint-disable-next-line
        btnTextCancel: this.$t("core.singleTemplateConfirm.btnTextCancel")
      };
    }
  },
  methods: {
    handleAccept() {
      this.$emit("accept");
    },
    handleCancel() {
      this.$emit("cancel");
    }
  }
};
</script>
