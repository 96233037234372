<template>
  <GridLayout rows="auto" columns="*" class="daily-average">
    <StackLayout row="0">
      <StackLayout
        orientation="horizontal"
        :class="{ 'daily-average__disabled': disabled }"
      >
        <VxpLabel class="label font-txt-64" :text="getTitle" :textWrap="true" />
        <VxpLabel
          backgroundColor="red"
          class="label__unit font-h1-32"
          :text="getTitleDesc"
          :textWrap="true"
          height="50"
        />
      </StackLayout>

      <GridLayout rows="auto" columns="auto, *, auto">
        <StackLayout orientation="horizontal" col="0">
          <SvgIcon
            class="daily-average__icon"
            :size="16"
            v-if="showDescIcon"
            src="icons/Controls/ic_controls_live_16.svg"
          />
          <VxpLabel class="daily-average__desc font-txt-13" :text="desc1" />
        </StackLayout>

        <StackLayout row="0" col="1" />
        <StackLayout row="0" col="2" @tap="$emit('backToLive', $event)">
          <VxpLabel
            class="daily-average__desc-status font-txt-13"
            :text="desc2"
          />
        </StackLayout>
      </GridLayout>
    </StackLayout>
  </GridLayout>
</template>

<script>
export default {
  name: "DailyAverage",
  props: {
    title: {
      type: [String, Number],
      default: ""
    },
    titleDesc: {
      type: String,
      default: ""
    },
    desc1: {
      type: String,
      default: ""
    },
    desc2: {
      type: String,
      default: ""
    },
    showDescIcon: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    translations() {
      return {
        dailyAverage: this.$t("energy.dailyAverage")
      };
    },
    statusDesc() {
      const { differencePercentage, longMonthName } = this.energy;
      console.log("### DAILY:", { differencePercentage, longMonthName }); //eslint-disable-line
      return this.$t("energy.fromMonth", {
        differencePercentage,
        longMonthName
      });
    },
    getTitle() {
      const value = this.title < 1000 ? this.title : this.title / 1000 || 0;
      return this.disabled ? "--" : value.toFixed(3);
    },
    getTitleDesc() {
      // const unit =
      //   // this.title < 1000 ? (this.title < 1 ? "watt" : "watts") :
      //   "kWh";
      return this.disabled ? "" : this.titleDesc; //this.titleDesc;
    },
    disabled() {
      return Number(this.title) === 0;
    }
  }
};
</script>

<style lang="scss">
@import "~/assets/styles/variables.scss";

.daily-average {
  &__disabled {
    color: $color-grey;
  }
  &__desc {
    color: $color-grey;
  }
  &__desc-status {
    color: $color-vera;
    user-select: none;
  }
  &__icon {
    margin-right: $s;
  }
  .label {
    vertical-align: bottom;
    &__unit {
      align-self: flex-end;
      padding-bottom: $s;
      padding-left: $m;
    }
  }
}
</style>
