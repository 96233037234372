<template>
  <GridLayout rows="*" columns="*" class="change-name-entity">
    <StackLayout
      row="0"
      col="0"
      class="change-name-entity__wrapper"
      :iosOverflowSafeArea="false"
    >
      <StackLayout class="change-name-entity__close-button">
        <SvgIcon src="icons/Controls/ic_controls_close_56.svg" @tap="cancel" />
      </StackLayout>
      <VxpLabel
        class="change-name-entity__title font-h1-32"
        :text="titleText"
      />
      <VxpLabel
        ref="label"
        v-if="placeholderText"
        class="font-txt-13 change-name-entity__placeholder-label"
        :text="placeholderText"
      />
      <TextInput
        ref="nameInput"
        v-show="visibleInput"
        class="change-name-entity__input"
        keyboardType="text"
        :type="getTypeOfInput"
        :hintType="errorHintType"
        :hintText="errorHintText"
        v-model="tempName"
        eraseButton
        :keepSoftInput="!tempName"
        :maxLength="maxLength"
        @returnPress="handleSubmit"
        T9Disabled
      />
      <ButtonFilled
        class="change-name-entity__button"
        :loading="isFetching"
        :disabled="!isFormValid"
        :text="actionText"
        @tap="handleSubmit"
      />
    </StackLayout>
  </GridLayout>
</template>
<script>
import { helpers } from "ezlo-core-ui";
import { email, required } from "vuelidate/lib/validators";

const { forceHideKeyboard } = helpers.core;
const { isEmptyString } = helpers.utils;
const { VUE_APP_MODE } = process.env;

export default {
  name: "ChangeNameEntity",
  props: {
    name: {
      type: String,
      default: ""
    },
    isFetching: {
      type: Boolean,
      default: false
    },
    isShowError: {
      type: Boolean,
      default: false
    },
    isEmail: {
      type: Boolean,
      default: false
    },
    titleText: {
      type: String,
      default: "Change Name"
    },
    actionText: {
      type: String,
      default: "Done"
    },
    placeholderText: {
      type: String,
      default: "Placeholder"
    },
    hintText: {
      type: String,
      default: ""
    },
    errorCode: {
      type: [String, Number],
      default: ""
    },
    maxLength: {
      type: Number,
      default: 2048
    }
  },
  data() {
    return {
      tempName: this.name,
      tempNameHasBeenClicked: false,
      visibleInput: VUE_APP_MODE === "web"
    };
  },
  mounted() {
    this.setFocus();
  },
  computed: {
    getTypeOfInput() {
      return this.isEmail ? "email" : "text";
    },
    isFormValid() {
      return !this.$v.tempName.$invalid;
    },
    isError403() {
      return Number(this.errorCode) === Number(403);
    },
    errorHintText() {
      const tempNameV = this.$v.tempName.$model;
      let error = "";
      if (this.isShowError && !this.tempNameHasBeenClicked) {
        error = this.hintText;
      }
      const checkEmailErrors = this.isEmail && this.isError403;
      return checkEmailErrors && tempNameV && this.isShowError ? error : "";
    },
    errorHintType() {
      return this.isEmail && this.errorHintText ? "error" : "info";
    }
  },
  methods: {
    focus(moveCursorToEnd) {
      this.$refs.nameInput.focus(moveCursorToEnd);
    },
    clearFocus() {
      this.$refs.nameInput.clearFocus();
    },
    handleSubmit() {
      if (!this.isFormValid) {
        return;
      }
      this.$emit("change", this.tempName);
      this.tempNameHasBeenClicked = false;
    },
    onShown() {
      // to reset initial value
      this.visibleInput = true;
      this.tempName = this.name;
      this.$nextTick(() => {
        this.focus(true);
      });
    },
    onHidden() {
      this.visibleInput = false;
      this.clearFocus();
      forceHideKeyboard();
    },
    clearText() {
      this.tempName = "";
    },
    cancel(event) {
      this.$emit("close", {
        event
      });
    },
    setFocus() {
      // textInput/input
      if (VUE_APP_MODE === "web") {
        this.$refs.nameInput.$refs.input.$el.focus();
      }
    }
  },
  validations() {
    return {
      tempName: {
        nonEmpty: value => !isEmptyString(value),
        ...(this.isEmail && { required }),
        ...(this.isEmail && { email })
      }
    };
  },
  watch: {
    tempName(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.tempNameHasBeenClicked = true;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~/assets/styles/typography.scss";
@import "~/assets/styles/variables.scss";
.change-name-entity {
  height: 100%;
  &__wrapper {
    padding: $padding-m $padding-xl 0;
  }
  &__title {
    color: $color-black;
    margin-bottom: $margin-xl;
  }
  &__input {
    margin-bottom: $margin-xl;
  }
  &__button {
    margin-bottom: $margin-xl;
  }
  &__close-button {
    align-self: start;
    margin-left: -$margin-l;
  }
  &__placeholder-label {
    color: $color-grey;
  }
}
</style>
