<template>
  <StackLayout class="energy-goals" width="100%">
    <GridLayout
      class="energy-goals__header"
      rows="auto"
      columns="auto, *, auto"
      flexDirection="row"
      alignContent="space-between"
    >
      <VxpLabel
        class="font-h2-24"
        :text="translations.header"
        row="0"
        col="0"
      />
      <VxpLabel />
      <VxpLabel
        v-if="goals.length > 0"
        class="font-txt-16-par energy-goals__header__btn"
        :text="translations.headerButton"
        row="0"
        col="2"
        @tap="goToCreateGoal"
      />
    </GridLayout>
    <StackLayout class="energy-goals__body">
      <StackLayout
        v-if="goals.length === 0 && !loading"
        class="energy-goals__body__item no-goal"
      >
        <ImageCacheIt
          class="energy-goals__body__item__img"
          src="~/assets/images/Energy/no-energy-goal-header.png"
          stretch="aspectFill"
        />
        <StackLayout class="energy-goals__body__item__desc">
          <VxpLabel
            class="font-h2-24"
            :textWrap="true"
            :text="translations.noGoalHeader"
          />
          <VxpLabel
            class="font-txt-16-par energy-goals__body__item__desc__text"
            :text="translations.noGoalText"
            :textWrap="true"
          />
          <ButtonFilledSmall
            :text="translations.noGoalButton"
            @tap="goToCreateGoal"
          />
        </StackLayout>
      </StackLayout>
      <StackLayout :key="goal.id" v-for="goal in goals">
        <ProgressiveCard
          v-if="!loading"
          class="energy-goals__body__item"
          :icon="goal.icon"
          :header="goal.header"
          :subHeader="goal.subHeader"
          :percentage="goal.percentage"
          :description="goal.description"
          :descriptionColor="goal.descriptionColor"
          :subDescription="goal.subDescription"
          :barColors="goal.barColors"
          @tap="goToGoalDetails(goal.id)"
        />
        <StackLayout v-else class="card-skeleton__background">
          <RowPlaceholder class="card-skeleton__row" />
          <AltCardPlaceholder class="card-skeleton" />
        </StackLayout>
      </StackLayout>
    </StackLayout>
  </StackLayout>
</template>
<script>
export default {
  name: "EnergyGoals",
  props: {
    goals: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    goToGoalDetails(goalId) {
      this.navigateTo("NewGoal", {
        props: {
          goalId
        }
      });
    },
    goToCreateGoal() {
      if (!this.loading) {
        this.navigateTo("NewGoal", {
          props: {
            goalId: ""
          }
        });
      }
    }
  },
  computed: {
    translations() {
      return this.$t("energy").goals;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~/assets/styles/variables.scss";
.energy-goals {
  &__header {
    padding-bottom: $lmx;
    &__btn {
      color: #00b491;
      cursor: pointer;
    }
  }
  &__body {
    &__item {
      margin-bottom: $l;
      cursor: pointer;
      &__img {
        border-radius: $l $l 0 0;
      }
      &.no-goal {
        border-radius: $l;
        border-width: $stroke;
        border-style: solid;
        border-color: $color-grey-a25;
      }
      &__desc {
        padding: $m $l $l $l;
        &__text {
          padding: $s 0 $m 0;
        }
      }
    }
  }
}
.row-skeleton {
  padding: $m 0 !important;
}
.card-skeleton {
  width: auto !important;
  background-color: $color-white;
  &__background {
    margin-bottom: $padding-xl;
    border-radius: $l;
    background-color: $color-grey-a15;
  }
  &__row {
  }
  // /deep/ & .placeholder{
  //   width : auto!important;
  // }
}
</style>
