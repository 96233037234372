<template>
  <GridLayout class="mode-selector" :rows="height" columns="*,*,*,*">
    <StackLayout
      @tap="modeTap(index)"
      row="0"
      :col="`${index}`"
      class="mode-selector__mode"
      :class="{ 'mode-selector__mode--selected': index === activeIndex }"
      :key="index"
      v-for="(item, index) in modes"
    >
      <Label
        class="mode-selector__mode__label  font-txt-14-btn"
        :class="{ 'mode-selector__mode__label--web': appMode }"
        :text="item"
      />
    </StackLayout>
  </GridLayout>
</template>

<script>
const { VUE_APP_MODE } = process.env;

export default {
  name: "ModeSelector",
  props: {
    modes: {
      type: Array,
      default: () => []
    },
    height: {
      type: [String, Number],
      default: "32"
    },
    activeIndex: {
      type: Number,
      default: 0
    }
  },
  computed: {
    translations() {
      return {
        dailyAverage: this.$t("")
      };
    },
    statusDesc() {
      const { differencePercentage, longMonthName } = this.energy;
      console.log("### DAILY:", { differencePercentage, longMonthName }); //eslint-disable-line
      return this.$t("energy.fromMonth", {
        differencePercentage,
        longMonthName
      });
    },
    appMode() {
      return VUE_APP_MODE === "web";
    }
  },
  methods: {
    modeTap(i) {
      this.$emit("modeTap", i);
    }
  }
};
</script>

<style lang="scss">
@import "~/assets/styles/variables.scss";

.mode-selector {
  border-radius: $s;
  padding: $xxs;
  background: $color-grey-a15;
  margin: 0 $xl;
  &__mode {
    cursor: pointer;
    border-radius: $s;
    vertical-align: middle;
    &--selected {
      background: $color-white;
      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12),
        0px 3px 1px rgba(0, 0, 0, 0.04);
    }
    &__label {
      height: 100%;
      horizontal-align: center;
      justify-self: center;
      align-self: center;
      &--web {
        line-height: 32px !important;
      }
    }
  }
}
</style>
