<template>
  <MainPage>
    <GridLayout rows="*" columns="*">
      <StackLayout
        row="0"
        col="0"
        verticalAlignment="center"
        horizontalAlignment="center"
      >
        <Spinner v-show="isFetching('forgotPasswordEmailCheck')" />
      </StackLayout>
    </GridLayout>
  </MainPage>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const {
  mapGetters: mapUiGetters,
  mapActions: mapUiActions
} = createNamespacedHelpers("ui");
const { mapActions: mapAuthActions } = createNamespacedHelpers("auth");

export default {
  name: "ForgotPasswordEmailCheck",
  props: { email: {}, token: {} },
  mounted() {
    setTimeout(this.checkToken);
  },
  computed: {
    ...mapUiGetters(["isFetching", "hasError"])
  },
  methods: {
    ...mapAuthActions(["forgotPasswordEmailCheck"]),
    async checkToken() {
      const { token, email } = this;
      await this.forgotPasswordEmailCheck(token, email);
      if (this.hasError) {
        return;
      }
      this.navigateTo("ForgotPasswordEmailNewPassword");
    }
  }
};
</script>

<style lang="scss" scoped></style>
