<template>
  <MainPage>
    <GridLayout height="100%" rows="*, auto" columns="*">
      <StackLayout row="0" col="0">
        <IBtnHiBtn
          icon1Src="icons/Controls/ic_controls_arrow-left_56.svg"
          :text="translations.textHeader"
          @iconTap1="handleGoBack"
        />
        <StackLayout row="1">
          <ItemsMenu :items="menuItems" @select="handleSelect" />
        </StackLayout>
      </StackLayout>
      <BottomUpDialog
        @beforeShown="handleBeforeShownDialogChangeName"
        @beforeHidden="handleBeforeHiddenDialogChangeName"
        class="dialog"
        ref="dialog"
        row="0"
        col="0"
      >
        <component
          ref="component"
          :is="dialog.component"
          v-bind="dialog.data"
          @close="closeDialog"
          @change="handleRename"
        />
      </BottomUpDialog>
    </GridLayout>
  </MainPage>
</template>
<script>
import { createNamespacedHelpers } from "vuex";
import ItemsMenu from "@/components/ui/ItemsMenu";

const {
  mapGetters: mapUiGetters,
  mapActions: mapUiActions
} = createNamespacedHelpers("ui");

const {
  mapActions: mapDashboardActions,
  mapGetters: mapDashboardGetters
} = createNamespacedHelpers("dashboards");

const {
  mapActions: mapTemplateActions,
  mapGetters: mapTemplateGetters
} = createNamespacedHelpers("template");

const { mapGetters: mapThemesGetters } = createNamespacedHelpers("themes");

export default {
  name: "DashboardSettings",
  components: {
    ItemsMenu
  },
  props: {
    dashboardId: {
      type: String,
      required: true,
      default: "1"
    },
    isLandscape: {
      type: Boolean,
      default: false
    },
    editMode: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      dialog: {
        component: "",
        data: {}
      },
      dashboardName: "",
      backgroundChanges: null,
      maxLength: 248
    };
  },
  computed: {
    ...mapUiGetters(["hasError"]),
    ...mapDashboardGetters([
      "getDashboardChanged",
      "getDashboardSettingsChanged",
      "hasDashboardSettingsChanges"
    ]),
    ...mapTemplateGetters(["getCurrentTemplateMode"]),
    ...mapThemesGetters(["getSelectedTheme"]),
    getDashboardSettings() {
      return this.getDashboardSettingsChanged(this.dashboardId);
    },
    translations() {
      return this.$t("dashboardSettings");
    },
    dialogTranslations() {
      return {
        editName: this.$t("dashboardTemplate").textEditNameDialog
      };
    },
    menuItems() {
      return [
        {
          id: "1",
          name: this.getDashboardName,
          desc: this.translations.dashboardName,
          icon: "icons/Controls/ic_controls_edit_56.svg",
          method: this.rename,
          disable: false,
          visible: this.editMode
        },
        {
          id: "2",
          name: this.translations.duplicateDashboard,
          desc: this.translations.duplicateDashboardDesc,
          icon: "icons/Controls/ic_controls_copy_56.svg",
          method: this.duplicateDashboard,
          disable: false,
          visible: this.editMode
        },
        {
          id: "3",
          name: this.translations.templateMode,
          desc: this.translations[this.getCurrentTemplateMode],
          icon: "icons/Controls/ic_controls_arrow-right_56.svg",
          method: this.templateMode,
          disable: false,
          visible: this.editMode
        },
        {
          id: "4",
          name: this.translations.theme,
          desc: this.getSelectedTheme.name,
          icon: "icons/Controls/ic_controls_arrow-right_56.svg",
          method: this.theme,
          disable: false,
          visible: true
        }
      ].filter(item => item.visible);
    },
    getDashboard() {
      return this.getDashboardChanged(this.dashboardId);
    },
    getDashboardName() {
      return (this.getDashboard && this.getDashboard.name) || "";
    },
    getSnackbarTexts() {
      return {
        duplicate: this.translations.textDuplicateSuccessful
      };
    }
  },
  methods: {
    ...mapUiActions(["saveSnackbarText"]),
    ...mapDashboardActions([
      "renameDashboard",
      "cancelEditDashboard",
      "updateDashboardAsync",
      "removeDashboardAsync",
      "createDuplicateDashItemAsync",
      "setAsDefaultDashboard"
    ]),
    ...mapTemplateActions(["saveToTemplateBuffer"]),
    handleGoBack() {
      this.navigateBack();
    },
    handleSelect(item) {
      console.log("### dashboardSettings / handleSelect:", item); // eslint-disable-line
      item.method();
    },
    async duplicateDashboard() {
      console.log("### duplicateDashboard"); // eslint-disable-line
      await this.createDuplicateDashItemAsync({
        dashboardId: this.dashboardId
      });

      if (this.hasError) {
        return;
      }
      this.navigateBack();
      setTimeout(() => {
        this.saveSnackbarText(this.getSnackbarTexts.duplicate);
      }, 150);
    },
    templateMode() {
      // In edit mode, the current template will be saved in the templateBuffer.
      console.log("### templateMode"); // eslint-disable-line
      if (this.editMode) {
        this.saveToTemplateBuffer();
      }
      this.navigateTo("TemplateMode", {
        props: {
          editMode: this.editMode,
          currentTemplateMode: this.getCurrentTemplateMode
        }
      });
    },
    currentTemplateMode() {},
    theme() {
      this.navigateTo("ThemeSettings", {
        props: {
          dashboardId: this.dashboardId,
          isLandscape: this.isLandscape
        }
      });
    },
    rename() {
      console.log("### renameDashboard"); // eslint-disable-line
      this.showChangeNameDialog();
    },
    showChangeNameDialog() {
      this.dialog = {
        component: "ChangeNameEntity",
        data: {
          name: this.getDashboardName,
          titleText: this.dialogTranslations.editName.title,
          placeholderText: this.dialogTranslations.editName.placeholder,
          hintText: this.dialogTranslations.editName.hint,
          actionText: this.dialogTranslations.editName.btn,
          maxLength: 64
        }
      };
      this.showDialog();
    },
    showDialog() {
      const dialog = this.$refs.dialog;
      dialog && dialog.show();
    },
    closeDialog() {
      const dialog = this.$refs.dialog;
      dialog && dialog.hide();
    },
    handleBeforeShownDialogChangeName() {
      if (this.dialog.component === "ChangeNameEntity") {
        setTimeout(() => {
          const dialog = this.$refs.component;
          dialog && dialog.onShown();
        }, 25);
      }
    },
    handleBeforeHiddenDialogChangeName() {
      if (this.dialog.component === "ChangeNameEntity") {
        setTimeout(() => {
          const dialog = this.$refs.component;
          dialog && dialog.onHidden();
        }, 25);
      }
    },
    handleRename(name) {
      this.renameDashboard({ id: this.dashboardId, name });
      this.closeDialog();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~/assets/styles/variables.scss";

.dashboard-settings {
  &__input {
    margin: 0 $margin-xl;
  }
}
</style>
