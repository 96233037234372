<template>
  <StackLayout slot="content" class="full-name" height="100%">
    <VxpLabel
      class="full-name__title font-h1-32"
      :text="titleText"
      :textWrap="true"
    />

    <TextInput
      type="text"
      class="full-name__first-name"
      returnKeyType="next"
      :placeholder="firstFieldPlaceholderText"
      :showSoftInput="true"
      :eraseButton="true"
      v-model="$v.firstField.$model"
      T9Disabled
    />

    <TextInput
      ref="lastField"
      type="text"
      class="full-name__last-name"
      returnKeyType="next"
      :placeholder="lastFieldPlaceholderText"
      :showSoftInput="true"
      :eraseButton="true"
      v-model="$v.lastField.$model"
      @returnPress="handleTapSubmit"
      T9Disabled
    />

    <ButtonFilled
      class="full-name__btn"
      :text="btnText"
      :loading="isLoading"
      :disabled="isNextButtonDisabled"
      @tap="handleTapSubmit"
    />
  </StackLayout>
</template>

<script>
import { minLength, required } from "vuelidate/lib/validators";
import { helpers } from "ezlo-core-ui";

const { clearFocus, forceHideKeyboard } = helpers.core;

export default {
  name: "FullNameForm",
  props: {
    titleText: {
      type: String,
      default: "Title"
    },
    firstFieldPlaceholderText: {
      type: String,
      default: "Placeholder first field"
    },
    lastFieldPlaceholderText: {
      type: String,
      default: "Placeholder second field"
    },
    btnText: {
      type: String,
      default: "Button"
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    firstFieldProp: {
      type: String,
      default: ""
    },
    lastFieldProp: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      firstField: this.firstFieldProp || "",
      lastField: this.lastFieldProp || "",
      minValueLength: 1
    };
  },
  computed: {
    isNextButtonDisabled() {
      const firstNameValid = this.$v.firstField.$invalid;
      const lastNameValid = this.$v.lastField.$invalid;

      return firstNameValid || lastNameValid;
    }
  },
  methods: {
    clearFocus,
    handleTapSubmit() {
      const isFormValid = !this.$v.$invalid;
      if (isFormValid) {
        const data = {
          firstField: this.firstField,
          lastField: this.lastField
        };
        forceHideKeyboard();
        this.$emit("submit", data);
      }
    }
  },
  validations() {
    return {
      firstField: {
        required,
        minLength: minLength(this.minValueLength)
      },
      lastField: {
        required,
        minLength: minLength(this.minValueLength)
      }
    };
  }
};
</script>

<style scoped lang="scss">
@import "~/assets/styles/variables.scss";

.full-name {
  height: 100%;
  color: $color-black;
  padding-left: $padding-xl;
  padding-right: $padding-xl;
  vertical-align: top;

  &__title {
    vertical-align: bottom;
    color: $color-black;
  }
  &__title,
  &__first-name,
  &__last-name,
  &__btn {
    margin-bottom: $margin-xl;
  }
}
</style>
