<template>
  <input
    ref="slider"
    type="range"
    class="slider"
    :style="getStyle"
    :min="minValue"
    :max="maxValue"
    :value="value"
  />
</template>
<script>
import { locals } from "@/helpers/variables";
export default {
  name: "RangeSlider",
  model: {
    prop: "value",
    event: "valueChange"
  },
  props: {
    value: {
      type: [String, Number],
      default: 0
    },
    width: {
      type: [String, Number],
      default: "100%"
    },
    height: {
      type: [String, Number],
      default: "100%"
    },
    minValue: {
      type: [String, Number],
      default: 0
    },
    maxValue: {
      type: [String, Number],
      default: 100
    },
    color: {
      type: String,
      default: locals.colorPrimary
    },
    backgroundColor: {
      type: String,
      default: "green"
    }
  },
  data() {
    return {
      sliderRef: null
    };
  },
  mounted() {
    this.sliderRef = this.$refs.slider;
    const self = this;
    this.sliderRef.oninput = function() {
      self.valueChange(this.value);
    };
  },
  computed: {
    getStyle() {
      return { width: `${this.widh}` };
    }
  },
  methods: {
    valueChange(value) {
      console.log("RangeSlider:", { value }) //eslint-disable-line
      this.$emit("valueChange", value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~/assets/styles/variables.scss";
.slider {
  -webkit-appearance: none;
  height: $ml;
  border-radius: $s;
  background: $color-black-045;
  outline: none;
  opacity: 0.4;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 0.6;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: $xxm;
  height: $xxm;
  background: $color-white;
  cursor: pointer;
  box-shadow: 0px $xs $s rgba(144, 160, 175, 0.7);
  border-radius: $xll;
}

.slider::-moz-range-thumb {
  width: $xxm;
  height: $xxm;
  background: $color-white;
  cursor: pointer;
  box-shadow: 0px $xs $s rgba(144, 160, 175, 0.7);
  border-radius: $xll;
}
</style>
