<template>
  <GridLayout
    rows="*, auto, auto, auto, auto, auto"
    columns="*"
    horizontalAlignment="center"
    class="pwd-reset"
  >
    <StackLayout row="0" col="0" class="pwd-reset__image">
      <SvgIcon :src="iconSrc" :size="iconSize" />
    </StackLayout>

    <VxpLabel
      row="1"
      col="0"
      class="pwd-reset__title font-h1-32"
      :text="title"
      :textWrap="true"
    />

    <VxpLabel
      row="2"
      col="0"
      class="pwd-reset__desc font-txt-16-par"
      :text="desc"
      :textWrap="true"
    />

    <ButtonFilled
      row="4"
      col="0"
      class="pwd-reset__btn"
      :text="btn"
      @tap="$emit('tap', $event)"
      v-if="!disabled"
    />

    <VxpLabel
      row="5"
      col="0"
      class="pwd-reset__hint font-txt-13"
      :text="hint"
      :textWrap="true"
    />
  </GridLayout>
</template>

<script>
export default {
  name: "PasswordResetSuccess",
  props: {
    iconSrc: {
      type: String,
      default: "icons/Visuals/ic_visuals_mail_200.svg"
    },
    iconSize: {
      type: Number,
      default: 200
    },
    title: {
      type: String,
      default: "Title"
    },
    desc: {
      type: String,
      default: "Description"
    },
    btn: {
      type: String,
      default: "Button"
    },
    hint: {
      type: String,
      default: "Some hint"
    }
  },
  computed: {
    disabled() {
      return process.env.VUE_APP_MODE === "web";
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~/assets/styles/variables.scss";

.pwd-reset {
  height: 100%;
  padding: $padding-xl;

  &__image {
    vertical-align: bottom;
    horizontal-align: center; //nativescript only attribute
    align-items: center;
  }

  &__email {
    margin-bottom: $margin-xxxl;
  }

  &__btn {
    margin-bottom: $margin-xl;
  }
  &__hint {
    color: $color-grey;
  }
  &__title,
  &__desc {
    margin-bottom: $margin-s;
  }

  &__title,
  &__desc,
  &__email,
  &__hint {
    text-align: center;
  }

  &__title,
  &__desc,
  &__email {
    color: $color-black;
  }
}
</style>
