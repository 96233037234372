<template>
  <MainPage>
    <GridLayout rows="*" columns="*">
      <StackLayout class="devices" row="0" col="0">
        <IBtnHiBtn
          icon1Src="icons/Controls/ic_controls_arrow-left_56.svg"
          :text="textsMain.title"
          @iconTap1="handleBack"
          class="devices__header"
        />
        <DevicesOverview
          class="devices"
          :texts="textsMain"
          :devices="devices"
          :groups="groups"
          @onTapDevice="handleTapDevice"
          @selectGroupBy="handleSelectGroupBy"
        />
      </StackLayout>
    </GridLayout>
  </MainPage>
</template>

<script>
import DevicesOverview from "@/components/device/DevicesOverview";
import { createNamespacedHelpers } from "vuex";
const {
  mapGetters: mapDeviceGetters,
  mapActions: mapDeviceActions
} = createNamespacedHelpers("devices");

const { mapGetters: mapAuthGetters } = createNamespacedHelpers("auth");
const { mapActions: mapTemplateActions } = createNamespacedHelpers("template"); // to be deleted
export default {
  name: "Devices",
  props: {
    index: {
      type: [Number, String],
      default: 0
    },
    isLandscape: {
      type: Boolean,
      default: false
    },
    dashboardId: {
      type: String,
      default: ""
    }
  },
  components: {
    DevicesOverview
  },
  data() {
    return {
      groups: ["byType", "byRoom"],
      groupBy: "byType"
    };
  },
  computed: {
    ...mapAuthGetters(["isWebView", "getControllerId"]),
    ...mapDeviceGetters(["getDevicesByFullRoom", "getDevicesGroupByCategory"]),
    orientation() {
      return this.isLandscape ? "landscape" : "portrait";
    },
    textsMain() {
      // eslint-disable-next-line
      return this.$t("devices");
    },
    devices() {
      switch (this.groupBy) {
        case "byRoom":
          return this.getDevicesByFullRoom(
            this.isLandscape ? "landscape" : "portrait",
            this.getControllerId
          );
        default:
          return this.getDevicesGroupByCategory(
            this.isLandscape ? "landscape" : "portrait",
            this.getControllerId
          );
      }
    }
  },
  methods: {
    ...mapTemplateActions(["setTile"]),
    ...mapDeviceActions(["addSelectedDevice"]),
    handleBack() {
      this.navigateBack();
    },
    handleTapDevice(item) {
      this.addSelectedDevice({ id: item.id, isLandscape: this.isLandscape });
      this.setTile({
        index: this.index,
        dashboardId: this.dashboardId,
        type: "device",
        item,
        orientation: this.orientation
      });
      this.handleBack();
    },
    handleSelectGroupBy(groupBy) {
      this.groupBy = groupBy;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~/assets/styles/variables.scss";

.devices {
  overflow-y: auto;
  &__header {
    min-height: $xxxll;
  }
}
</style>
