<template>
  <MainPage>
    <StackLayout>
      <IBtnHiBtn
        icon1Src="icons/Controls/ic_controls_arrow-left_56.svg"
        :text="translations.title"
        @iconTap1="handleBack"
        class="configure-capability"
      />
    </StackLayout>
    <Separator size="xl" />
    <GridLayout rows="*, auto" columns="*" class="device-control" height="100%">
      <ScrollView row="0" col="0" class="device-control__scroll" height="100%">
        <StackLayout height="100%">
          <StackLayout
            @tap="editCapability(item)"
            v-for="capability in getCapabilityList"
            :key="capability.id"
          >
            <IconHTxtiBtn
              class="device-control__scroll__content"
              icon2Src="icons/Controls/ic_controls_arrow-right_56.svg"
              alignText="left"
              alignUnderline="left"
              headerSize="h3"
              :disabled="true"
              :text="capability.name"
            />
            <Separator size="xl" />
          </StackLayout>
        </StackLayout>
      </ScrollView>
    </GridLayout>
  </MainPage>
</template>
<script>
import { required } from "vuelidate/lib/validators";

export default {
  name: "ConfigureCapabilityPage",
  props: {
    capabilityId: {
      type: String,
      default: "0"
    }
  },
  data() {
    return {
      name: "",
      abstractDevices: {
        name: "Amazon Alexa",
        eMail: "user@gmail.com",
        src: "icons/Smarthomes/ic_smarthomes_amazon_alexa.svg"
      }
    };
  },
  computed: {
    getCapabilityList() {
      return [
        {
          id: "0",
          name: "Turn On/Off"
        },
        {
          id: "1",
          name: "Set Dimmer"
        }
      ];
    },
    translations() {
      // eslint-disable-next-line
      return this.$t("virtualDevices.configureCapabilities");
    },
    capabilityName() {
      return "Turn on/off";
    },
    isDisabled() {
      return this.$v.name.$invalid;
    }
  },
  methods: {
    handleBack() {
      this.navigateBack();
    },
    editCapability(item) {
      switch (item.id) {
        case 0:
          this.navigateTo("EditCapabilityPage");
          break;
        case 1:
          this.navigateTo("SetDimmerPage");
          break;
        default:
          break;
      }
    }
  },
  validations() {
    return {
      name: {
        required
      }
    };
  }
};
</script>
<style lang="scss" scoped>
@import "~/assets/styles/variables.scss";

.configure-capability {
  /deep/ & * {
    color: $color-grey-a75;
  }
}
.device-control {
  height: 100%;
  &__scroll {
    height: 100%;

    &__label {
      padding: $xl $xl 0 $xl;
    }
    &__content {
      padding: 0 $xl;
    }
  }
  &__container {
    margin: 0 $xl;
  }
  &__btn-container {
    padding: 0 $xl $xxl $xl;
  }
  &__hint {
    text-align: left;
    color: $color-grey;
  }
  &__input-label {
    margin-bottom: $s;
    text-align: left;
    color: $color-black;
  }
  &__alexaImage {
    grid-column: 2;
    justify-self: end;
    width: unit(124);
    height: unit(124);
  }
  &__googleImage {
    grid-column: 3;
    justify-self: start;
    width: unit(124);
    height: unit(124);
  }
}
</style>
