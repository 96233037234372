<template>
  <MainPage class="cap-error">
    <GridLayout rows="auto,*,auto" columns="*">
      <IBtnHiBtn
        row="0"
        col="0"
        icon1Src="icons/Controls/ic_controls_arrow-left_56.svg"
        @iconTap1="handleBack"
        class="devices__header"
      />
      <ScrollView row="1" col="0" class="cap-error__scroll" height="100%">
        <StackLayout class="cap-error__content">
          <SvgIcon
            src="icons/Visuals/ic_warning_visuals_not-responding_200.svg"
            :size="200"
            verticalAlignment="center"
          />
          <Label
            class="cap-error__title font-h1-32"
            :text="translations.title"
            :textWrap="true"
          />
          <Label
            class="cap-error__description__1 font-txt-16-par"
            :text="translations.l1"
            :textWrap="true"
          />
          <Label
            class="cap-error__description__2 font-txt-16-par"
            :text="translations.l2"
            :textWrap="true"
          />
          <Label
            class="cap-error__description font-txt-16-par"
            :text="translations.l3"
            :textWrap="true"
          />
          <Label
            class="cap-error__description font-txt-16-par"
            :text="translations.l4"
            :textWrap="true"
          />
        </StackLayout>
      </ScrollView>
      <StackLayout row="2" col="0" class="cap-error__buttons">
        <ButtonFilled
          class="cap-error__button-accept"
          :text="translations.tryAgain"
          @tap="tryAgain"
        />
        <ButtonFilled
          class="cap-error__button-cancel"
          :warning="true"
          :normal="true"
          :text="translations.config"
          @tap="$emit('cancel', $event)"
        />
      </StackLayout>
    </GridLayout>
  </MainPage>
</template>

<script>
export default {
  name: "CapabilityErrorPage",
  computed: {
    translations() {
      return this.$t("virtualDevices.capabilityError");
    }
  },
  methods: {
    handleBack() {
      this.navigateBack();
    },
    tryAgain() {
      this.navigateBack();
    }
  }
};
</script>

<style scoped lang="scss">
@import "~/assets/styles/variables.scss";

.cap-error {
  &__scroll {
    height: 100%;
  }
  &__title {
    color: $color-black;
    text-align: center;
  }
  &__description {
    margin: $padding-s 0 0 0;
    color: $color-black;
    text-align: left;
    &__1 {
      margin: $m 0 0 0;
    }
    &__2 {
      margin: $xl 0 0 0;
    }
  }
  &__buttons {
    padding: $xl;
  }
  &__button-accept {
    margin-bottom: $margin-m;
  }

  &__image {
    margin-top: $margin-lmx;
  }
  &__content {
    padding: 0 $xl $xl $xl;
  }
}

.warning {
  .cap-error {
    &__title {
      color: $color-warning;
    }
  }
}
.warning2 {
  .cap-error {
    &__title {
      color: $color-black;
    }
  }
}
</style>
