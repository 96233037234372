<template>
  <GridLayout rows="auto,auto,*,auto,auto" columns="*" class="select-voi">
    <Label
      class="font-h2-24 select-voi__label"
      row="0"
      col="0"
      :text="translations.title"
      textWrap
    />
    <Separator row="1" col="0" size="xl" />
    <ScrollView row="2" col="0">
      <RadioGroup layout="StackLayout">
        <RadioHTxt
          v-for="item in getAccounts"
          :key="item.id"
          :text="item.name"
          :subTitle="item.email"
          :radioInitiallySelected="true"
          :radioValue="item.id"
          radioPosition="left"
        />
      </RadioGroup>
    </ScrollView>

    <IBtnHiBtn
      row="3"
      col="0"
      class="d-overview__add-button"
      icon1Src="icons/Controls/ic_controls_plus_primary_56.svg"
      :text="translations.bt"
      :rightButtonShow="false"
      @tap="addNewAccount"
      headerSize="h3"
    />
    <Separator row="4" col="0" size="xl" />
  </GridLayout>
</template>

<script>
export default {
  name: "SelectVOIAccount",
  computed: {
    translations() {
      return this.$t("virtualDevices.selectVOI");
    },
    getAccounts() {
      return [
        {
          id: "alexa0192031923",
          name: "Google Home",
          email: "user@gmail.com"
        },
        {
          id: "google1231241231523",
          name: "Amazon Alexa",
          email: "user@gmail.com"
        }
      ];
    },
    currentAccount() {
      return 0;
    }
  },
  methods: {
    addNewAccount() {
          console.log("add");//eslint-disable-line
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~/assets/styles/variables.scss";

.select-voi {
  &__label {
    padding: $xl $xl $m;
  }
}
</style>
