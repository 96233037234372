<template>
  <MainPage>
    <GridLayout height="100%" rows="auto,*" columns="*">
      <StackLayout row="0" col="0">
        <IBtnHiBtn
          icon1Src="icons/Controls/ic_controls_arrow-left_56.svg"
          @iconTap1="navigateBack"
          :text="translations.textTitle"
        />
      </StackLayout>
      <StackLayout row="1" col="0">
        <Separator size="xl" />
        <StackLayout v-for="tile in getTileTypes" :key="tile.group">
          <VxpLabel
            v-if="isGroupNameVisible(tile.items)"
            class="tile-types__group-title font-h2-24"
            :text="$t(tile.group)"
            :textWrap="true"
          />
          <TileTypesList
            :items="tile.items"
            @selectTileType="handleSelectTileType"
          />
        </StackLayout>
      </StackLayout>
    </GridLayout>
  </MainPage>
</template>

<script>
import TileTypesList from "@/components/dashboard/TileTypesList";
import { createNamespacedHelpers } from "vuex";
const { mapGetters: mapTemplateGetters } = createNamespacedHelpers("template");

export default {
  name: "TileTypes",
  components: {
    TileTypesList
  },
  props: {
    index: {
      type: [Number, String],
      required: true
    },
    isLandscape: {
      type: Boolean,
      default: false
    },
    dashboardId: {
      type: String,
      default: ""
    }
  },
  computed: {
    ...mapTemplateGetters(["getTileTypes"]),
    translations() {
      return this.$t("core.tileTypesDialog"); // eslint-disable-line
    }
  },
  methods: {
    isGroupNameVisible(items) {
      return items.filter(item => item.visible || item.visible === undefined)
        .length;
    },
    handleSelectTileType(tileType) {
      switch (tileType) {
        case "device":
          this.navigateTo("Devices", {
            props: {
              index: this.index,
              dashboardId: this.dashboardId,
              isLandscape: this.isLandscape
            }
          });
          break;
        case "url":
          this.navigateTo("NewLinkTile", {
            props: {
              index: this.index,
              dashboardId: this.dashboardId,
              isLandscape: this.isLandscape
            }
          });
          break;
        case "scene":
          this.navigateTo("Scenes", {
            props: {
              index: this.index,
              dashboardId: this.dashboardId,
              isLandscape: this.isLandscape
            }
          });
          break;
        case "link":
          this.navigateTo("SelectMenuItems", {
            props: {
              index: this.index,
              dashboardId: this.dashboardId,
              isLandscape: this.isLandscape
            }
          });
          break;
        case "dashboard":
          this.navigateTo("DashboardSelect", {
            props: {
              index: this.index,
              dashboardId: this.dashboardId,
              isLandscape: this.isLandscape
            }
          });
          break;
        case "datetime":
          this.navigateTo("DateTimeSelect", {
            props: {
              index: this.index,
              dashboardId: this.dashboardId,
              isLandscape: this.isLandscape
            }
          });
          break;
        case "mode":
          this.navigateTo("HouseModeSelect", {
            props: {
              index: this.index,
              dashboardId: this.dashboardId,
              isLandscape: this.isLandscape
            }
          });
          break;
        case "panic":
          this.navigateTo("PanicTypeSelect", {
            props: {
              index: this.index,
              dashboardId: this.dashboardId,
              isLandscape: this.isLandscape
            }
          });
          break;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~/assets/styles/variables.scss";

.tile-types {
  &__group-title {
    color: $color-black;
    margin: $lmx $xl $xs $lmx;
  }
}
</style>
