export const getCreatedDashboards = (data = {}) => {
  const isFull = Object.keys(data).length;
  const getNewDashboards = items => {
    const newItems = Object.entries(items);

    return newItems
      .map(item => ({
        id: item[0],
        ...item[1]
      }))
      .map(({ data, ...rest }) => ({
        ...rest,
        ...data
      }));
  };

  return isFull ? getNewDashboards(data) : [];
};
