import request from "./http";
import endpoints from "./endpoints";
import stringInject from "stringinject";
import sha1 from "sha1";
import apiConfig from "../../config/api";

const { cloud, dashboards } = endpoints;
const { cloudApiFullUrl, cloudXAppToken } = apiConfig;

const dashboardsApi = {
  getDashboardsApi: async ({ authJwtToken = "" }) => {
    const url = cloud.request;
    const headers = {
      Authorization: `Bearer ${authJwtToken}`,
      "X-App": cloudXAppToken
    };
    const params = {
      call: dashboards.get,
      params: {
        data: 1
      }
    };

    return await request({
      method: "POST",
      baseUrl: cloudApiFullUrl(),
      url,
      headers,
      params
    });
  },
  updateDashboardApi: async ({ authJwtToken = "", options }) => {
    const {
      id,
      name,
      entity_type,
      entity_uuid,
      entity_id,
      group_type,
      last_modified, // do not change
      ...rest
    } = options;
    const url = cloud.request;
    const headers = {
      Authorization: `Bearer ${authJwtToken}`,
      "X-App": cloudXAppToken
    };
    const params = {
      call: dashboards.put,
      params: {
        uuid: id,
        name,
        entity_type,
        entity_uuid,
        data: {
          entity_id,
          group_type,
          ...rest
        }
      }
    };

    return await request({
      method: "POST",
      baseUrl: cloudApiFullUrl(),
      url,
      headers,
      params
    });
  },
  deleteDashboardApi: async ({ authJwtToken = "", options }) => {
    const id = options.id;
    const url = cloud.request;
    const headers = {
      Authorization: `Bearer ${authJwtToken}`,
      "X-App": cloudXAppToken
    };
    const params = {
      call: dashboards.delete,
      params: {
        uuid: id,
        delete: 1
      }
    };
    return await request({
      method: "POST",
      baseUrl: cloudApiFullUrl(),
      url,
      headers,
      params
    });
  },
  createDashboardApi: async ({ authJwtToken = "", options }) => {
    const {
      name,
      entity_type,
      entity_uuid,
      entity_id,
      group_type,
      ...rest
    } = options;
    const url = cloud.request;
    const headers = {
      Authorization: `Bearer ${authJwtToken}`,
      "X-App": cloudXAppToken
    };
    // params are not used in the current implmentation
    const params = {
      call: dashboards.post,
      params: {
        name,
        entity_type,
        entity_uuid,
        data: {
          group_type,
          entity_id,
          ...rest
        }
      }
    };

    return await request({
      method: "POST",
      baseUrl: cloudApiFullUrl(),
      url,
      headers,
      params
    });
  }
};

export default dashboardsApi;

// {
//   version: 1,
//   name: "Efe and Me",
//   desc: "",
//   id: "0",
//   template: {
//     portrait: "0",
//     landscape: "1"
//   },
//   styleSettings: {
//     fontFamily: "ezlo-sans",
//     general: {
//       backgroundColor: "#ffffff",
//       backgroundImage: null
//     },
//     tileOn: {
//       backgroundColor: "#ffffff",
//       iconColor: "#000000",
//       textColor: "#000000"
//     },
//     tileOff: {
//       backgroundColor: "#ffffff",
//       iconColor: "#000000",
//       textColor: "#000000"
//     }
//   },
//   template_data: {
//     portrait: {
//       design_properties: {
//         background_image: "",
//         background_color: ""
//       },
//       dashboard_elements: [
//         {
//           cell_id: "0",
//           title: "cell title",
//           target_entity: {
//             object_id: "ZAD8955A",
//             type: "device",
//             parent_entity: "controllerId",
//             parent_entity_type: "controller"
//           },
//           design_properties: {
//             background_image:
//               "https://vera-us-oem-storage25.mios.com/storage/storage/store/891957135/thumbnail?Key=1398395163",
//             background_color: "#00b491",
//             on: {
//               background_image: "", //<image object, optional>
//               background_color: "#00b491", //<background color, optional field>
//               font_color: "#ffffff",
//               icon: "",
//               icon_color: "#ffffff"
//             },
//             off: {
//               background_image: "", //<image object, optional>
//               background_color: "#ffffff", //<background color, optional field>
//               font_color: "#918F8F",
//               icon: "",
//               icon_color: "#ffffff"
//             }
//           }
//         }
//       ]
//     },
//     landscape: {
//       design_properties: {
//         background_image: "",
//         background_color: ""
//       },
//       dashboard_elements: [
//         {
//           cell_id: "1",
//           title: "cell title",
//           target_entity: {
//             object_id: "Z7C8E07EF",
//             type: "device",
//             parent_entity: "controllerId",
//             parent_entity_type: "controller"
//           },
//           design_properties: {
//             background_image:
//               "https://vera-us-oem-storage25.mios.com/storage/storage/store/891957135/thumbnail?Key=1398395163",
//             background_color: "#00b491",
//             on: {
//               background_image: "", //<image object, optional>
//               background_color: "#00b491", //<background color, optional field>
//               font_color: "#ffffff",
//               icon: "",
//               icon_color: "#ffffff"
//             },
//             off: {
//               background_image: "", //<image object, optional>
//               background_color: "#00b491", //<background color, optional field>
//               font_color: "#918F8F",
//               icon: "",
//               icon_color: "#ffffff"
//             }
//           }
//         }
//       ]
//     }
//   }
// },
