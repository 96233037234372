<template>
  <GridLayout rows="*,auto" class="font-family-overview" height="100%">
    <StackLayout row="0">
      <ScrollView height="100%" iosOverflowSafeArea="false">
        <StackLayout>
          <StackLayout class="font-family-overview__block">
            <VxpLabel
              class="font-txt-16-par font-family-overview--text"
              :text="texts.textInstruction"
              textWrap
            />
          </StackLayout>
          <StackLayout class="font-family-overview__block">
            <TileItem
              width="103"
              height="103"
              horizontalAlignment="left"
              titleTextWrap
              :title="tileItem.title"
              :srcPng="tileItem.srcPng"
              :fontFamily="fontFamily"
              :fontSize="fontSize"
            />
          </StackLayout>
          <Separator size="xl" />
          <RadioGroup
            layout="StackLayout"
            @selectionChanged="onSelectionFontFamily"
          >
            <RadioItem
              v-for="item in fontFamilies"
              :key="item.id"
              :text="
                `${translations[item.name]}${
                  item.default ? ` (${translations.defaultFont})` : ''
                }`
              "
              :radioInitiallySelected="item.id === fontFamily"
              :radioValue="item.id"
              class="font-family-overview__font font-family-overview--text"
              :class="item.default ? `${item.id}-medium` : item.id"
            />
          </RadioGroup>
        </StackLayout>
      </ScrollView>
    </StackLayout>
    <StackLayout row="1" class="font-family-overview__block">
      <ButtonFilled
        :disabled="isDisabled"
        :text="texts.textBtn"
        @tap="handleSubmit"
      />
    </StackLayout>
  </GridLayout>
</template>
<script>
export default {
  name: "FontFamilyOverview",
  props: {
    styleSettings: {
      type: Object,
      required: true,
      default: () => ({})
    },
    fontFamilies: {
      type: Array,
      default: () => []
    },
    tileItem: {
      type: Object,
      default: () => ({
        title: "VistaCam 1000",
        srcPng: "~/assets/images/Devices/DefaultCameraBack.png"
      })
    },
    texts: {
      type: Object,
      default: () => ({
        textInstruction:
          "You can select from the list below the font that will be applied to the tiles.",
        textBtn: "Done"
      })
    }
  },
  data() {
    const { fontFamily, fontSize } = this.styleSettings;
    return {
      fontFamily: fontFamily,
      fontSize: fontSize
    };
  },
  computed: {
    translations() {
      return this.$t("fontFamilies"); // eslint-disable-line
    },
    isDisabled() {
      const { fontFamily } = this.styleSettings;
      return this.fontFamily === fontFamily;
    }
  },
  methods: {
    handleSubmit() {
      this.$emit("submit", this.fontFamily);
    },
    onSelectionFontFamily(e) {
      this.fontFamily = e.radioValue;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~/assets/styles/base.scss";

.font-family-overview {
  padding-top: $xl;
  &__block {
    margin-bottom: $xl;
    padding: 0 $xl;
    width: $tile-height;
    height: $tile-height;
  }
  &__font {
    font-size: unit(20);
    line-height: getLineHeight(24);
  }
  &--text {
    color: $color-black;
  }
}
</style>
