import { base64ToString } from "@/helpers/crypto";

export const getIdentityDecoded = (value = "") => {
  // IdentityDecoded model:
  // "PK_User": 14070622, the user ID
  // "PK_Account": 12819612,  the account ID
  // "PK_AccountType": 5, the account type
  // "PK_PermissionRole": 10,
  // "Expires": 1577434517, UNIX timestamp
  // "Generated": 1577348117, UNIX timestamp
  // "Username": "maxnp", the name of the user for which the Identity is signed
  // "PK_Server_Auth": 1, the auth server ID
  // "PK_Oem_User": "", ID of the user in the external OEM system (if any)
  // "PermissionsEnabled": [1,2],
  // "PermissionsDisabled": [],
  // "Version": 2,
  // "PK_AccountChild": 0,
  // "PK_Account_Parent": 2375,
  // "PK_Account_Parent2": 0,
  // "PK_Account_Parent3": 0,
  // "PK_Account_Parent4": 0,
  // "PK_App": 0,
  // "PK_Oem": 1,
  // "PK_Server_Account": 5,
  // "PK_Server_Event": 13,
  // "Server_Auth": "mms1.qa.mios.com",
  // "Seq": 72,
  // "IsNewAccount": 1

  const identityDecodedStr = base64ToString(value);
  const IdentityDecoded = JSON.parse(identityDecodedStr);
  if (!IdentityDecoded) {
    throw new Error("Identity string couldn't parsed");
  }
  return IdentityDecoded;
};
