<template>
  <MainPage height="100%">
    <StackLayout>
      <IBtnHiBtn
        icon1Src="icons/Controls/ic_controls_arrow-left_56.svg"
        :text="translations.title"
        @iconTap1="handleBack"
        class="detect-opt__header"
      />
    </StackLayout>

    <Label
      class="detect-opt__title container font-h2-24"
      :text="translations.header"
      textWrap
    />
    <Label
      class="detect-opt__text container font-txt-16-par"
      :text="translations.sub"
      textWrap
    />

    <StackLayout>
      <Separator size="xl" />
      <IconHTxtiBtn
        :icon1Src="currentLanguage.src"
        headerSize="h3"
        :icon1Size="40"
        :text="currentLanguage.name"
        alignText="left"
        :hover="false"
      />
      <Separator size="xl" />
    </StackLayout>
    <Label
      class="detect-opt__title container font-h2-24"
      :text="translations.detect"
      textWrap
    />
    <Label
      class="detect-opt__text container font-txt-16-par"
      :text="translations.capabilities"
      textWrap
    />
    <Separator size="xl" />

    <ScrollView>
      <StackLayout>
        <StackLayout @tap="selectAll">
          <CheckHTxtiBtn
            alignText="left"
            headerSize="h3"
            :checked="selectAllChecked"
            :text="translations.all"
          />
        </StackLayout>
        <Separator size="xl" />
        <StackLayout
          v-for="item in capabilitiyList"
          :key="item.id"
          @tap="selectCapabilities(item)"
        >
          <CheckHTxtiBtn
            alignText="left"
            headerSize="h3"
            :checked="item.checked"
            :text="item.name"
          />
          <Separator size="xl" />
        </StackLayout>
      </StackLayout>
    </ScrollView>
  </MainPage>
</template>
<script>
import { required } from "vuelidate/lib/validators";

export default {
  name: "detect-optOptions",
  data() {
    return {
      selectedCapabilities: [],
      selectAllChecked: false,
      capabilities: [
        {
          id: "1",
          name: "Turn on/off",
          checked: true
        },
        {
          id: "2",
          name: "Set Dimmer",
          checked: true
        },
        {
          id: "3",
          name: "Set Dimmer2",
          checked: true
        }
      ]
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.selectedCapabilities = this.getSelectedCapabilities;
    });
  },
  computed: {
    languages() {
      return [
        {
          name: "English",
          src: "icons/Flags/us.svg"
        }
      ];
    },
    currentLanguage() {
      return {
        name: "English",
        src: "icons/Flags/us.svg"
      };
    },
    capabilitiyList() {
      return this.capabilities;
    },
    getSelectedCapabilities() {
      return this.capabilities.filter(item => item.checked);
    },
    selectedCapabilitiesCount() {
      // TODO
      return this.selectedCapabilities.length || "";
    },
    translations() {
      // eslint-disable-next-line
      return this.$t("virtualDevices.detectionOptions");
    },
    isDisabled() {
      return this.selectedCapabilitiesCount === "";
    }
  },
  methods: {
    selectAll() {
      this.selectAllChecked = !this.selectAllChecked;
      this.capabilities.forEach(item => {
        if (item.checked !== this.selectAllChecked) {
          item.checked = this.selectAllChecked;
        }
      });
        console.log("select All")//eslint-disable-line
    },
    handleBack() {
      this.navigateBack();
    },
    handleCreate() {
      this.navigateTo("NewVirtualDevice");
      console.log("handleCreate"); // eslint-disable-line
    },
    handleSubmit() {
      console.log("handleSubmit"); // eslint-disable-line
    },
    onTapCapabilities(item) {
      this.navigateTo("EditCapabilityPage");
      console.log("onTapCapabilities", item); // eslint-disable-line
    },
    selectCapabilities(capability) {
      capability.checked = !capability.checked;
      console.log("selectCapabilities",  capability ); // eslint-disable-line
      if (capability.checked) {
        this.selectedCapabilities.push(capability);
      } else {
        this.selectedCapabilities = this.selectedCapabilities.filter(
          item => item.id !== capability.id
        );
      }
    },
    updateCapability(item, checked) {
      item.checked = checked;
    },
    configureMoreCapabilities() {
      this.navigateTo("ConfigureCapabilityPage");
      console.log("configureMoreCapabilities"); //eslint-disable-line
    }
  },
  validations() {
    return {
      name: {
        required
      }
    };
  }
};
</script>
<style lang="scss" scoped>
@import "~/assets/styles/variables.scss";

.container {
  margin: 0 $xl;
}

.detect-opt {
  user-select: none;
  height: 100%;

  &__title {
    overflow: visible;
    margin-top: $xl;
  }
  &__text {
    overflow: visible;
  }
  &__btn-container {
    padding: 0 $xl $xxl $xl;
  }
  &__hint {
    text-align: left;
    color: $color-grey;
  }
  &__input-label {
    margin-bottom: $s;
    text-align: left;
    color: $color-black;
  }
  &__alexaImage {
    grid-column: 2;
    justify-self: end;
    width: unit(124);
    height: unit(124);
  }
  &__googleImage {
    grid-column: 3;
    justify-self: start;
    width: unit(124);
    height: unit(124);
  }
  &__group-title {
    color: $color-black;
    margin: $lmx $xl $xs $lmx;
  }
  &__more-capabilities {
    &:hover {
      cursor: pointer;
      background-color: $color-grey-a15;
    }
  }
  &__header {
    /deep/ & * {
      color: $color-grey;
    }
  }
}
</style>
