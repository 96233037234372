<template>
  <MainPage>
    <StackLayout>
      <IBtnHiBtn
        icon1Src="icons/Controls/ic_controls_arrow-left_56.svg"
        :text="traslations.iWantMy"
        @iconTap1="goBack"
        class="new-goal__title"
      />
    </StackLayout>
    <GridLayout rows="*, auto" columns="*" class="new-goal">
      <StackLayout row="0" col="0">
        <ScrollView height="100%" class="mode-devices__scroll">
          <GoalDevices :devices="devices" @tap="select"></GoalDevices>
        </ScrollView>
        <StackLayout height="16" />
      </StackLayout>
    </GridLayout>
  </MainPage>
</template>
<script>
import { createNamespacedHelpers } from "vuex";
const {
  mapGetters: mapEnergyGetters,
  mapActions: mapEnergyActions
} = createNamespacedHelpers("energy");

import GoalDevices from "./GoalDevices";
export default {
  name: "NewGoalDevice",
  components: {
    GoalDevices
  },
  props: {
    device: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      selectedDevice: ""
    };
  },
  computed: {
    ...mapEnergyGetters(["getDeviceGroups"]),
    traslations() {
      // eslint-disable-next-line
      return this.$t("goals");
    },
    devices() {
      return this.getDeviceGroups;
    }
  },
  methods: {
    ...mapEnergyActions(["selectDevice"]),
    goBack() {
      this.navigateBack();
    },
    async select(device) {
      await this.selectDevice(device.id);
      this.selectedDevice = device;
      this.$root.$emit("_newGoalDevice", this.selectedDevice);
      setTimeout(() => {
        this.goBack();
      }, 100);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~/assets/styles/variables.scss";

.new-goal {
  height: 100%;
  &__title {
    background-color: #eef1f3;
    border-bottom-style: solid;
    border-bottom-color: $color-grey-a15;
    border-bottom-width: $stroke;
  }
  &__container {
    margin: $xxm $xl;
  }
  &__group-text {
    margin: $xxm $xl $ml $xl;
  }
  &__btn-container {
    padding: 0 $xl $l $xl;
  }
  &__item {
    // margin: $xl 0;
    &--green {
      /deep/ & * {
        color: $color-vera;
      }
    }
  }
  &__separator {
    margin: 0 $xs;
  }
  &__limit {
    margin: 0 $xl;
  }
  &__input {
    grid-row: 1;
    grid-column: 1;
  }
  &__kwh {
    grid-row: 1;
    grid-column: 2;
  }
  &__kwh-label {
    color: $color-grey;
    grid-row: 1;
    grid-column: 1;
    align-self: center;
  }
}
</style>
