<template>
  <GridLayout
    height="100%"
    rows="auto, *, auto"
    columns="*"
    class="name-input-form"
  >
    <StackLayout row="0" col="0" class="name-input-form name-input-form__input">
      <VxpLabel
        ref="label"
        v-if="label"
        class="font-txt-13 name-input-form__placeholder-label"
        :text="label"
      />
      <TextInput
        ref="textInput"
        keyboardType="text"
        v-model="formName"
        showSoftInput
        eraseButton
        keepSoftInput
        :maxLength="maxLength"
        @returnPress="finish"
        @textChange="textChange"
        T9Disabled
      />
    </StackLayout>
    <StackLayout row="1" col="0" />
    <StackLayout
      row="2"
      col="0"
      class="name-input-form name-input-form__bottom"
    >
      <ButtonFilled
        :text="btnText"
        :disabled="!isFormValid"
        @tap="finish"
        :loading="loading"
      />
    </StackLayout>
  </GridLayout>
</template>
<script>
import { helpers } from "ezlo-core-ui";
const { forceHideKeyboard } = helpers.core;

export default {
  name: "NameInputForm",
  props: {
    name: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    btnText: {
      type: String,
      default: "Button"
    },
    isFormValid: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    maxLength: {
      type: Number,
      default: 64
    }
  },
  data() {
    return {
      formName: this.name
    };
  },
  watch: {
    name(value) {
      this.formName = value;
    }
  },
  mounted() {
    this.setFocus();
  },
  methods: {
    finish() {
      this.$emit("finish", { text: this.formName });
    },
    textChange(event) {
      this.$emit("textChange", { text: event });
    },
    setFocus() {
      // textInput/input
      this.$refs.textInput.$refs.input.$el.focus();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~/assets/styles/variables.scss";

.name-input-form {
  height: 100%;

  &__input {
    margin: $margin-xs $margin-xl $margin-xl;
  }

  &__bottom {
    margin: 0 $padding-xl $padding-xl;
  }

  &__placeholder-label {
    color: $color-grey;
  }
}
</style>
