<template>
  <MainPage>
    <GridLayout height="100%" rows="auto, *" class="climate">
      <StackLayout row="0">
        <IBtnAct
          src="icons/Controls/ic_controls_arrow-left_56.svg"
          @tap="handleBack"
        />
        <VxpLabel
          class="climate__title font-h1-32"
          :text="translations.title"
          :textWrap="true"
        />
      </StackLayout>
      <ScrollView row="1" iosOverflowSafeArea="false" height="100%">
        <StackLayout>
          <StackLayout>
            <VxpLabel
              class="climate__title font-txt-16-par"
              :text="period"
              :textWrap="true"
            />
            <Separator size="xl" />
          </StackLayout>
          <ClimateOverview :energy="energy" @tap="onTap" />
        </StackLayout>
      </ScrollView>
    </GridLayout>
  </MainPage>
</template>
<script>
import ClimateOverview from "./ClimateOverview";

export default {
  name: "Climate",
  components: { ClimateOverview },
  props: {
    energy: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      calendarIcon: "icons/Actions/ic_actions_calendar_56.svg"
    };
  },
  computed: {
    period() {
      return `${this.energy.longMonthName}, ${this.energy.year}`;
    },
    translations() {
      return {
        title: this.$t("energy.climate")
      };
    }
  },
  methods: {
    handleBack() {
      this.navigateBack();
    },
    onSelect(index, item) {
      this.$emit("selectedIndexChanged", { index, scene: item });
    },
    onTap(room) {
      this.navigateTo("ConsumptionDetails", {
        props: {
          energy: this.energy
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~/assets/styles/base.scss";

.climate {
  color: $color-black;
  &__content {
    padding: 0 0 $padding-xl $padding-xl;
  }
  &__title {
    padding: 0 0 $s $padding-xl;
  }
  &__period {
    padding: 0 0 $s $padding-xl;
  }
  &__item-container {
    padding: $padding-xl;
    &__item {
      margin-bottom: $margin-s;
    }
  }
}
</style>
