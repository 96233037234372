<template>
  <MainPage>
    <StackLayout>
      <IBtnHiBtn
        icon1Src="icons/Controls/ic_controls_arrow-left_56.svg"
        @iconTap1="goBack"
        :text="translations.textTitle"
      />
      <Separator size="xl" />
    </StackLayout>
    <NameInputForm
      :name="nameText"
      :btnText="translations.finish"
      :label="translations.label"
      :isFormValid="isFormValid"
      :loading="false"
      @textChange="textChange"
      @finish="finish"
    />
  </MainPage>
</template>
<script>
import platform from "vuexp/src/platform";
import NameInputForm from "@/components/forms/NameInputForm";

export default {
  name: "DashboardName",
  components: {
    NameInputForm
  },
  props: {
    name: {
      type: String,
      default: ""
    },
    formValid: {
      type: Boolean,
      default: false
    },
    finishCallback: {
      type: Function
    },
    validationCallback: {
      type: Function
    }
  },
  data() {
    return {
      nameText: this.name
    };
  },
  watch: {
    name(value) {
      this.nameText = value;
    }
  },
  computed: {
    translations() {
      return {
        finish: this.$t("common.save"),
        textTitle: this.$t("themeSettings.enterThemeName"),
        label: this.$t("themeSettings.themeName"),
        textSnackbar: this.$t("themeSettings.textSnackbar")
      };
    },
    isAndroid() {
      return platform.platform === "android";
    },
    isFormValid() {
      return !this.validationCallback
        ? this.nameText.length > 0
        : this.formValid;
    }
  },
  methods: {
    goBack() {
      this.navigateBack();
      this.$destroy();
    },
    textChange(event) {
      this.nameText = event.text;
      this.validationCallback && this.validationCallback(event);
    },
    async finish(event) {
      if (!this.isFormValid) return;
      this.finishCallback && this.finishCallback(event);
      this.goBack();
    }
  }
};
</script>
