export default {
  getScenes: (state, getters) => {
    return state.scenes.map(scene => ({
      ...scene,
      loading: getters.getLoadingBySceneId(scene.id)
    }));
  },
  getSceneById: state => id => state.scenes.find(scene => scene.id === id),
  getScenesByRoomId: state => roomId => {
    return state.scenes.filter(scene => scene.parent_id === roomId);
  },
  getScenesNoRoom: (state, getters, rootState, rootGetters) => {
    const rooms = rootGetters["rooms/getRooms"].map(room => room.id);
    return getters.getScenes.filter(
      scene => !scene.parent_id || !rooms.includes(scene.parent_id)
    );
  },
  getScenesByFullRoom: (state, getters, rootState, rootGetters) => (
    orientation,
    controllerId = null
  ) => {
    const rooms = rootGetters["rooms/getRooms"];
    const groups = rooms.reduce((res, roomItem) => {
      const scenes = getters.getScenesByRoomId(roomItem.id);
      const scenesRoom = scenes.filter(scene => {
        const isSceneNotAdded = !state.selectedScenes[orientation].includes(
          scene.id
        );
        if (controllerId) {
          if (controllerId === "no_hub") {
            // TODO list cloud scenes only here
            return isSceneNotAdded && scene.type === "cloud";
          } else if (parseInt(controllerId) > 69999999) {
            return (
              isSceneNotAdded &&
              (scene.controller_id === controllerId || scene.type === "cloud")
            );
          } else {
            return isSceneNotAdded && scene.controller_id === controllerId;
          }
        }
        return isSceneNotAdded;
      });

      const roomHasScenes = Array.isArray(scenesRoom) && scenesRoom.length;
      if (!roomHasScenes) return res;
      const groupOptions = scenesRoom.map(scene => ({
        title: scene.name,
        icons: {
          defaultSrc: "icons/Tabbar/ic_tabbar_scenes_56.svg"
        },
        ...scene
      }));
      res.push({
        groupId: roomItem.id,
        groupTitle: roomItem.name,
        loading: getters.getLoadingBySceneId(groupOptions.id),
        groupDevices: groupOptions,
        groupOptions: groupOptions
      });

      return res;
    }, []);
    const noRoomScenes = getters.getScenesNoRoom
      .filter(scene => {
        const isSceneNotAdded = !state.selectedScenes[orientation].includes(
          scene.id
        );
        if (controllerId) {
          if (controllerId === "no_hub") {
            // TODO list cloud scenes only here
            return isSceneNotAdded && scene.type === "cloud";
          } else if (parseInt(controllerId) > 69999999) {
            return (
              isSceneNotAdded &&
              (scene.controller_id === controllerId || scene.type === "cloud")
            );
          } else {
            return isSceneNotAdded && scene.controller_id === controllerId;
          }
        }
        return isSceneNotAdded;
      })
      .map(scene => ({
        title: scene.name,
        icons: {
          defaultSrc: "icons/Tabbar/ic_tabbar_scenes_56.svg"
        },
        ...scene
      }));
    if (noRoomScenes.length) {
      groups.push({
        groupId: "No rooms",
        groupTitle: "No rooms",
        groupDevices: noRoomScenes,
        loading: getters.getLoadingBySceneId(noRoomScenes.id),
        groupOptions: noRoomScenes
      });
    }
    return groups;
  },
  getLoadingBySceneId: state => sceneId =>
    state.loadingItems.indexOf(sceneId) !== -1
};
