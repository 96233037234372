import Vue from "vue";
import {
  SET_THEMES,
  SET_TEMP_THEME,
  SET_TEMP_CHANGED,
  RESET_THEMES,
  RESET_TEMP_THEME,
  ADD_THEME,
  REMOVE_THEME,
  SELECT_THEME
} from "./mutationsTypes";

export default {
  [SET_THEMES](state, payload) {
    Vue.set(state, "themes", payload);
  },
  [SET_TEMP_THEME](state, payload) {
    Vue.set(state, "tempTheme", payload);
  },
  [SET_TEMP_CHANGED](state, payload = true) {
    Vue.set(state, "hasThemeChanged", payload);
  },
  [RESET_THEMES](state) {
    Vue.set(state, "themes", []);
  },
  [RESET_TEMP_THEME](state) {
    Vue.set(state, "tempTheme", {});
  },
  [ADD_THEME](state, payload) {
    Vue.set(state, "themes", [...state.themes, payload]);
  },
  [REMOVE_THEME](state, payload) {
    Vue.set(
      state,
      "themes",
      state.themes.filter(item => item.uuid !== payload.uuid)
    );
  },
  [SELECT_THEME](state, payload) {
    // clear previous selected theme: selected = false
    const { uuid } = payload;
    const themes = state.themes;
    Object.keys(themes).forEach((element, index) => {
      themes[element].data.selected = themes[element].uuid === uuid;
    });
    Vue.set(state, "themes", themes);

    const defaultThemes = state.defaultThemes;
    Object.keys(defaultThemes).forEach(element => {
      defaultThemes[element].data.selected =
        defaultThemes[element].uuid === uuid;
    });
    Vue.set(state, "defaultThemes", defaultThemes);
  }
};
