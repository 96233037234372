<template>
  <apexchart
    ref="chart"
    type="bar"
    :width="width"
    :height="height"
    :options="options"
    :series="series"
  ></apexchart>
</template>

<script>
export default {
  name: "Chart",
  props: {
    width: {
      type: String,
      default: "100%"
    },
    height: {
      type: String,
      default: "230"
    },
    series: {
      type: Array,
      default: () => []
    },
    seriesVisibility: {
      type: Array,
      default: () => []
    },
    options: {
      type: Object,
      default: () => ({
        chart: {
          type: "bar",
          height: 230,
          stacked: true,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          },
          events: {}
        },
        stroke: {
          width: 0,
          curve: "smooth"
        },
        dataLabels: {
          enabled: false
        },
        states: {
          normal: {
            filter: {
              type: "none",
              value: 0
            }
          },
          hover: {
            filter: {
              type: "lighten",
              value: 0.15
            }
          },
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: "darken",
              value: 0.35
            }
          }
        },
        noData: {
          text: "Loading..."
        },
        xaxis: {
          type: "category",
          labels: {
            rotate: 0
          },
          categories: []
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          labels: {
            show: true
          }
        },
        legend: {
          show: false
        },
        fill: {
          opacity: 1
        }
      })
    }
  },
  data() {
    return {
      chart: null
    };
  },
  mounted() {
    this.chart = this.$refs.chart;
    this.init();
  },
  watch: {
    seriesVisibility(value) {
      setTimeout(() => {
        this.setSeriesVisibility();
      }, 0);
    }
  },
  computed: {
    getChartRef() {
      return this.$refs.chart;
    }
  },
  methods: {
    init() {
      if (this.chart) {
        this.updateOptions({
          colors: this.colors
        });

        if (!this.options?.chart?.events?.dataPointSelection) {
          this.updateOptions({
            chart: {
              events: {
                dataPointSelection: this.dataPointSelection
              }
            }
          });
        }
        this.setSeriesVisibility();
      }
    },
    setSeriesVisibility() {
      if (this.seriesVisibility.length === 0) {
        this.chart.resetSeries();
      } else {
        this.seriesVisibility.forEach(element => {
          if (element.visible) {
            this.chart.showSeries(element.name);
          } else {
            this.chart.hideSeries(element.name);
          }
        });
      }
    },
    updateOptions(options) {
      if (this.chart) {
        this.chart.updateOptions(options);
      }
    },
    toggleSeries(SeriesName) {
      this.$refs.chart.toggleSeries(SeriesName);
    },
    dataPointSelection(event, chartContext, config) {
      console.log("##### dataPointSelection", { event, chartContext, config }); // eslint-disable-line
      this.$emit("dataPointSelection", event, chartContext, config);
    }
  }
};
</script>
