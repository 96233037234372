<template>
  <StackLayout class="tile" orientation="vertical">
    <VxpLabel
      class="tile__capability font-h3-20"
      :text="getCapabilityName(capabilityName)"
    />
    <StackLayout class="tile__item" @tap="tileSelected">
      <TileItem
        :title="tileLabel"
        :backgroundImage="tileStyle.background_image"
        :src="tileStyle.icon"
        :backgroundColorHex="tileStyle.background_color"
        :colorHex="tileStyle.font_color"
        :iconColorHex="tileStyle.icon_color"
        :fontFamily="tileStyle.font_family"
        :fontSize="tileStyle.font_size"
        :hideTitle="tileStyle.hideTitle"
        :hideIcon="tileStyle.hideIcon"
      />
    </StackLayout>
  </StackLayout>
</template>

<script>
export default {
  name: "TileStateTile",
  props: {
    capabilityName: {
      type: String,
      default: ""
    },
    tileStyle: {
      type: Object,
      required: true
    },
    tileLabel: {
      type: String,
      default: ""
    }
  },
  methods: {
    getCapabilityName(state) {
      return this.$t("tileStates").capabilities[state];
    },
    tileSelected() {
      this.$emit("selected", this.capabilityName);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~/assets/styles/variables.scss";

.tile {
  padding: $s $ml;
  &__capability {
    padding-left: $s;
    padding-bottom: $m;
    padding-right: $xxs;
  }
  &__item {
    width: $tile-height;
    height: $tile-height;
  }
}
</style>
