<template>
  <MainPage>
    <StackLayout>
      <StackLayout left="0" top="0" width="100%">
        <IBtnHiBtn
          icon1Src="icons/Controls/ic_controls_arrow-left_56.svg"
          :text="translations.headerTitle"
          @iconTap1="navigateBack"
        />
      </StackLayout>
      <DashboardSelectOverview
        :dashboards="menuItems"
        @onTapSelectDashboard="handleDateTimeSelect"
      />
    </StackLayout>
  </MainPage>
</template>
<script>
import { createNamespacedHelpers } from "vuex";
import DashboardSelectOverview from "@/components/dashboard/DashboardSelectOverview";
import { disable } from "@nativescript/core/trace/trace";

const {
  mapGetters: mapTemplateGetters,
  mapActions: mapTemplateActions
} = createNamespacedHelpers("template");

export default {
  name: "PanicTypeSelect",
  props: {
    index: {
      type: Number
    },
    dashboardId: {
      type: String
    },
    isLandscape: {
      type: Boolean,
      default: false
    }
  },
  components: {
    DashboardSelectOverview
  },
  computed: {
    ...mapTemplateGetters([
      "getUsedLinkItemsInSelectedTeplate",
      "getPanicTypes"
    ]),
    orientation() {
      return this.isLandscape ? "landscape" : "portrait";
    },
    translations() {
      return this.$t("panicTypeSelect"); // eslint-disable-line
    },
    items() {
      return this.getPanicTypes.map(panic => {
        return {
          ...panic,
          name: this.translations[panic.name],
          title: this.translations[panic.title]
        };
      });
    },
    menuItems() {
      const usedItems = this.getUsedLinkItemsInSelectedTeplate({
        orientation: this.orientation,
        type: "panic"
      });
      return this.items.filter(item => !usedItems.includes(item.subType));
    }
  },
  methods: {
    ...mapTemplateActions(["setTile"]),
    handleDateTimeSelect(item) {
      console.log("handleDashboardSelect"); //eslint-disable-line
      item.title = item.title;
      item.icon = item.subTypeIcon;
      this.setTile({
        index: this.index,
        dashboardId: this.dashboardId,
        type: "panic",
        subType: item.subType,
        icon: item.subTypeIcon,
        orientation: this.orientation,
        item
      });
      this.navigateBack();
    }
  }
};
</script>
