<template>
  <MainPage>
    <GridLayout class="cp" height="100%" rows="auto, *, auto" columns="*">
      <StackLayout class="cp" row="0" col="0">
        <IBtnHiBtn
          icon1Src="icons/Controls/ic_controls_arrow-left_56.svg"
          :text="title"
          @iconTap1="handleGoBack"
        />
        <Separator size="xl" />
      </StackLayout>
      <ScrollView row="1" col="0" height="100%">
        <GridLayout class="cp_container" rows="*" columns="*" height="100%">
          <ColorPicker
            row="1"
            col="0"
            class="cp__color-picker"
            v-model="color"
            @input="changeColor"
          />
        </GridLayout>
      </ScrollView>
      <StackLayout row="2" col="0" class="cp__btn">
        <ButtonFilled
          :disabled="isDisabled"
          :text="textDoneBtn"
          @tap="handleDone"
        />
      </StackLayout>
    </GridLayout>
  </MainPage>
</template>
<script>
import { helpers } from "ezlo-core-ui";
import { rgbaToAHex, rgbaTohex } from "@/helpers/utils";

export default {
  name: "ColorSelect",
  props: {
    title: {
      type: String,
      default: "Title"
    },
    colorHex: {
      type: String,
      default: ""
    },
    callbackChangeColor: {
      type: Function
    },
    callbackDone: {
      type: Function
    }
  },
  data() {
    return {
      color: this.colorHex
    };
  },
  watch: {
    colorHex(newValue) {
      this.color = newValue;
    }
  },
  computed: {
    textDoneBtn() {
      return this.$t("common").done;
    },
    isDisabled() {
      return this.colorHex.toUpperCase() === this.color.toUpperCase();
    }
  },
  methods: {
    handleGoBack() {
      this.navigateBack();
    },
    async handleDone() {
      this.$root.$emit("_changeColor", rgbaToAHex(this.color));
      this.$root.$emit("_colorSelect", rgbaToAHex(this.color));
      this.callbackDone &&
        this.callbackDone({
          color: this.color,
          hexA: rgbaTohex(this.color),
          aHex: rgbaToAHex(this.color)
        });
      this.$destroy();
      this.navigateBack();
    },
    changeColor(value) {
      this.$root.$emit("changeColor", rgbaToAHex(value));
      this.callbackChangeColor &&
        this.callbackChangeColor({
          color: value,
          hexA: rgbaTohex(value),
          aHex: rgbaToAHex(value)
        });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~/assets/styles/base.scss";
.cp {
  height: 100%;

  &__container {
    height: 100%;
  }
  &__color-picker {
    align-self: center;
  }
  &__btn {
    margin: $margin-xl;
  }
}
</style>
