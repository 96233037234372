<template>
  <Page actionBarHidden="true" @loaded="$emit('loaded', $event)">
    <GridLayout rows="*" columns="*" height="100%">
      <StackLayout row="0" col="0" height="100%" class="slot">
        <slot />
      </StackLayout>
      <BottomUpDialog row="0" col="0" ref="errorDialog">
        <component
          v-if="errorComponent"
          :is="errorComponent.component"
          v-bind="errorComponent.props"
          @close="hideErrorDialog"
        />
      </BottomUpDialog>
      <Snackbar
        row="0"
        col="0"
        ref="snackbar"
        :lingerTime="3000"
        @onHidden="handleHideSnackbar"
      >
        <StackLayout padding="25" @loaded="handleSnackbarLoaded">
          <VxpLabel
            class="snackbar font-txt-16-par"
            :class="getSnackbarType && `snackbar--${getSnackbarType}`"
            :text="snackbarText"
          />
        </StackLayout>
      </Snackbar>
      <StackLayout
        row="0"
        col="0"
        height="100%"
        isPassThroughParentEnabled="true"
        v-if="!isWeb"
      >
        <OfflineLock />
      </StackLayout>
      <StackLayout
        row="0"
        col="0"
        class="spinner"
        v-if="isPageLoading"
        isPassThroughParentEnabled="false"
        @tap="() => {}"
      >
        <Spinner height="100%" />
      </StackLayout>
    </GridLayout>
  </Page>
</template>

<script>
import { helpers } from "ezlo-core-ui";
import { getErrorComponent } from "@/helpers/errorTypesDictionary";
import { createNamespacedHelpers } from "vuex";
import { getDialogOptions } from "@/helpers/errorTypesDictionary";
const { VUE_APP_MODE } = process.env;
const {
  mapGetters: mapUiGetters,
  mapActions: mapUiActions
} = createNamespacedHelpers("ui");

export default {
  name: "MainPage",
  computed: {
    ...mapUiGetters([
      "error",
      "getSnackbarText",
      "getSnackbarType",
      "isPageLoading",
      "offline"
    ]),
    errorComponent() {
      if (this.offline) return undefined;
      return getErrorComponent(this.error);
    },
    isWeb() {
      return VUE_APP_MODE === "web";
    },
    snackbarText() {
      return this.$t(this.getSnackbarText);
    }
  },
  data() {
    return {
      unwatchErrorComponent: null,
      unwatchSnackbarText: null
    };
  },
  mounted() {
    this.$root.$on("_confirmationDialog", this.confirmationResult);
  },
  beforeDestroy() {
    this.confirmationResult && this.$root.$off("_confirmationDialog");
  },
  methods: {
    ...mapUiActions(["resetError", "saveSnackbarText"]),
    confirmationResult(result) {
      if (result) {
        console.log("### ---> MainPage/resultOfConfirmationDialog:", result); //eslint-disable-line
        this.$emit("confirmationResult", result);
      }
    },
    showDialog(name) {
      const dialog = this.$refs[name];
      if (dialog) {
        dialog.show();
      }
    },
    hideDialog(name) {
      const dialog = this.$refs[name];
      if (dialog) {
        dialog.hide();
      }
    },
    showErrorDialog() {
      helpers.core.forceHideKeyboard();
      this.showDialog("errorDialog");
    },
    hideErrorDialog(event) {
      this.hideDialog("errorDialog");
      this.confirmationResult(event);
      this.resetError();
    },
    showSnackbar() {
      const { snackbar } = this.$refs;
      snackbar && snackbar.show();
    },
    hideSnackbar() {
      const { snackbar } = this.$refs;
      snackbar && snackbar.hide();
    },
    handleHideSnackbar() {
      this.saveSnackbarText("");
    },
    handleSnackbarLoaded() {
      if (!this.getSnackbarText) return;

      setTimeout(() => {
        this.showSnackbar();
      }, 500);
    },
    showInformation(props) {
      this.navigateTo("InformationDialog", {
        props
      });
    },
    showError(props) {
      if (!props) {
        props = getDialogOptions("defaultError").props;
      }
      this.navigateTo("ErrorDialog", {
        props
      });
    },
    showConfirmationDialog(props) {
      console.log("### ---> showConfirmationDialog:", { props, name: this.$options.name }); // eslint-disable-line
      this.navigateTo("Confirmation", {
        props
      });
    }
  },
  created() {
    this.unwatchErrorComponent = this.$watch("errorComponent", value => {
      if (value) {
        this.showErrorDialog();
      } else {
        this.hideErrorDialog();
      }
    });

    this.unwatchSnackbarText = this.$watch("getSnackbarText", value => {
      if (value) {
        this.showSnackbar();
      } else {
        this.hideSnackbar();
      }
    });
  },
  destroyed() {
    this.hideErrorDialog();
    if (this.unwatchErrorComponent) {
      this.unwatchErrorComponent();
      this.unwatchErrorComponent = undefined;
    }
    if (this.unwatchSnackbarText) {
      this.unwatchSnackbarText();
      this.unwatchSnackbarText = undefined;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~/assets/styles/variables.scss";
.slot {
  overflow-y: auto;
}
.snackbar {
  color: $color-black;
  &--error {
    color: $color-warning;
  }
}
.spinner {
  background-color: $color-grey-a75;
}
</style>
