import { countries } from "@/helpers/countries";
const phonePattern = /^\(?([0-9]{0,3})\)?[-. ]?([0-9]{0,3})[-. ]?([0-9]{0,2})[-. ]?([0-9]{0,2})$/;
const phoneParser = "+1 $1 $2 $3 $4";
// string utils
export const getMajorVersionNo = versionStr =>
  typeof versionStr === "string" && parseInt(versionStr.split(".")[0]);

export const getAtomSerialFromSSID = ssidStr =>
  typeof ssidStr === "string" && ssidStr.split("_").pop();

export const formatPhoneNumber = (phoneNumber, CountryCode = "US") => {
  const country = countries.filter(item => item.CountryCode === CountryCode);
  return phoneNumber
    .replace(
      country.phonePattern || phonePattern,
      countries.phoneParser || phoneParser
    )
    .trim();
};

export const capitalizeFirstChar = string =>
  string.charAt(0).toUpperCase() + string.slice(1);
