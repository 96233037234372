import request from "./http";
import endpoints from "./endpoints";
import stringInject from "stringinject";
import sha1 from "sha1";
import apiConfig from "../../config/api";
import { isValidEmail } from "../helpers/inputCheckers";

const { auth, cloud } = endpoints;
const {
  salt,
  cloudBaseUrl,
  pkOem: PK_Oem,
  tokenVersion: TokenVersion,
  cloudApiFullUrl,
  cloudXAppToken
} = apiConfig;
const getSHA1Password = (username, password) =>
  sha1(`${username}${password}${salt}`);

const Auth = {
  async signup(params) {
    const { password, email, username } = params;
    const { pkOem } = apiConfig;
    return await request({
      url: auth.username.post,
      method: "POST",
      params: {
        Password: password,
        Email: email,
        PK_Oem: pkOem,
        Username: username.toLowerCase()
      }
    });
  },
  async login(username, password) {
    if (isValidEmail(username)) {
      // login with e-mail
      return await this.loginWithEmail(username, password);
    } else {
      // login with user name
      return await this.loginWithUsername(username, password);
    }
  },
  async loginWithUsername(username, password) {
    const SHA1Password = getSHA1Password(username, password);
    const params = {
      SHA1Password,
      PK_Oem,
      TokenVersion
    };
    const url = stringInject(auth.username.get, { username });
    const requestOptions = {
      method: "GET",
      url,
      params
    };
    return await request(requestOptions);
  },
  async loginWithEmail(email, Password) {
    const params = {
      Password,
      PK_Oem,
      TokenVersion
    };
    const url = stringInject(auth.email.login.get, { email });
    const requestOptions = {
      method: "GET",
      url,
      params
    };

    return await request(requestOptions);
  },
  async refreshToken(userId, config = {}) {
    const url = stringInject(auth.username.refreshToken, { PK_User: userId });
    const headers = {
      MMSAuth: config.token.Identity,
      MMSAuthSig: config.token.IdentitySignature
    };
    const params = {
      PK_Oem,
      TokenVersion
    };
    const requestOptions = {
      method: "GET",
      url,
      headers,
      params
    };
    return await request(requestOptions);
  },
  async logout(userId, config = {}) {
    const url = stringInject(auth.username.logout, { PK_User: userId });
    const headers = {
      MMSAuth: config.token.Identity,
      MMSAuthSig: config.token.IdentitySignature
    };
    const requestOptions = {
      method: "POST",
      url,
      headers
    };
    return await request(requestOptions);
  },
  async updatePassword(username, oldPassword, newPassword, config = {}) {
    const SHA1Password = getSHA1Password(username, oldPassword);
    const url = stringInject(auth.username.updatePassword.put, { username });
    const headers = {
      MMSAuth: config.token.Identity,
      MMSAuthSig: config.token.IdentitySignature
    };
    const params = {
      PK_Oem,
      SHA1Password,
      NewPassword: newPassword
    };
    const requestOptions = {
      method: "PUT",
      url,
      headers,
      params
    };
    return await request(requestOptions);
  },
  async resetPassword(email) {
    const url = stringInject(auth.email.passwordReset.put, { email });
    const requestOptions = {
      method: "PUT",
      url,
      params: { PK_Oem }
    };
    return await request(requestOptions);
  },
  async getUserInfo(userId, config = {}) {
    const url = stringInject(auth.userInfo.get, { PK_User: userId });
    const headers = {
      MMSAuth: config.token.Identity,
      MMSAuthSig: config.token.IdentitySignature
    };
    const params = {
      PK_Oem
    };
    const requestOptions = {
      method: "GET",
      url,
      headers,
      baseUrl: config.baseUrl,
      params
    };
    return await request(requestOptions);
  },
  async updateUserInfo(userId, userData = {}, config = {}) {
    const { firstName, lastName, email, phoneNumber } = userData;
    const url = stringInject(auth.userInfo.put, { PK_User: userId });

    const headers = {
      MMSAuth: config.token.Identity,
      MMSAuthSig: config.token.IdentitySignature
    };
    const params = {
      PK_Oem,
      FirstName: firstName,
      Name: lastName,
      Email: email,
      Phone: {
        Phone: phoneNumber
      }
    };
    const requestOptions = {
      method: "PUT",
      url,
      headers,
      baseUrl: config.baseUrl,
      params
    };
    return await request(requestOptions);
  },
  async cloudJwtTokenApi({ mmsAuth, mmsAuthSig }) {
    const url = cloud.jwtToken;
    const headers = {
      MMSAuth: mmsAuth,
      MMSAuthSig: mmsAuthSig
    };
    return await request({
      method: "GET",
      baseUrl: cloudBaseUrl,
      url,
      headers
    });
  },
  async cloudAccessTokensApi({ jwtToken = "" }) {
    const url = cloud.request;
    const headers = {
      Authorization: `Bearer ${jwtToken}`,
      "X-App": cloudXAppToken
    };
    const params = {
      call: cloud.accessTokens
    };

    return await request({
      method: "POST",
      baseUrl: cloudApiFullUrl(),
      url,
      headers,
      params
    });
  },
  async getServers(config) {
    const url = auth.config.get;
    const headers = {
      MMSAuth: config.token.Identity,
      MMSAuthSig: config.token.IdentitySignature
    };
    const { pkOem } = apiConfig;
    const requestOptions = {
      method: "GET",
      url,
      headers,
      params: {
        Server: 1,
        PK_Oem: pkOem
      }
    };
    return await request(requestOptions);
  },
  async getEzloProtect(config = {}) {
    const url = auth.services.get;
    const headers = {
      MMSAuth: config.token.Identity,
      MMSAuthSig: config.token.IdentitySignature
    };
    const requestOptions = {
      method: "GET",
      url,
      headers
    };
    if (config.baseUrl) {
      requestOptions["baseUrl"] = config.baseUrl;
    }
    return await request(requestOptions);
  }
};

export default Auth;
